import React, { Component } from 'react';
import { imagesArr } from "../../../../assets/images";

class hittingMetrics extends Component {

    render() {
        const { hMetrics_cols, hMetrics_data } = this.props;
        return (
            <>
                <div className="w-50">
                    <div className="gamesummary-table">
                        <div className="table-responsive-xl">
                            <table className="table tabledashed">
                                <thead>
                                    <tr>
                                        <th colSpan="3" className="one-child text-center">
                                            Hitting Metrics
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        {
                                            hMetrics_cols &&
                                            hMetrics_cols.map((th, i) => {
                                                return (
                                                    <td className="tablerowgray fw-500" key={i}>
                                                        {th}
                                                    </td>
                                                )
                                            })
                                        }
                                    </tr>

                                    {
                                        hMetrics_data &&
                                        hMetrics_data.map((td, i) => {
                                            return (
                                                <tr key={i}>
                                                    {hMetrics_cols &&
                                                        hMetrics_cols.map((th, j) => {
                                                            if (td[hMetrics_cols[1]] !== "Batter Team") {
                                                                return <td className="table-rowwhite">
                                                                    {
                                                                        j === 0 &&
                                                                        (td[hMetrics_cols[0]] > td[hMetrics_cols[2]]) &&
                                                                        ((td[hMetrics_cols[1]] !== "GB %" && td[hMetrics_cols[1]] !== "LD %" && td[hMetrics_cols[1]] !== "FB %" && td[hMetrics_cols[1]] !== "PU %") && <img src={imagesArr.green_up} alt="green_up" />)
                                                                    }
                                                                    {
                                                                        j === 0 &&
                                                                        (td[hMetrics_cols[0]] < td[hMetrics_cols[2]]) &&
                                                                        ((td[hMetrics_cols[1]] !== "GB %" && td[hMetrics_cols[1]] !== "LD %" && td[hMetrics_cols[1]] !== "FB %" && td[hMetrics_cols[1]] !== "PU %") && <img src={imagesArr.red_down} alt="red_down" />)
                                                                    }
                                                                    {
                                                                        j === 2 &&
                                                                        (td[hMetrics_cols[2]] > td[hMetrics_cols[0]]) &&
                                                                        ((td[hMetrics_cols[1]] !== "GB %" && td[hMetrics_cols[1]] !== "LD %" && td[hMetrics_cols[1]] !== "FB %" && td[hMetrics_cols[1]] !== "PU %") && <img src={imagesArr.green_up} alt="green_up" />)
                                                                    }
                                                                    {
                                                                        j === 2 &&
                                                                        (td[hMetrics_cols[2]] < td[hMetrics_cols[0]]) &&
                                                                        ((td[hMetrics_cols[1]] !== "GB %" && td[hMetrics_cols[1]] !== "LD %" && td[hMetrics_cols[1]] !== "FB %" && td[hMetrics_cols[1]] !== "PU %") && <img src={imagesArr.red_down} alt="red_down" />)
                                                                    }
                                                                    {td[th]}
                                                                </td>;
                                                            }
                                                        })}
                                                </tr>
                                            );
                                        })
                                    }

                                    {
                                        hMetrics_data.length === 0 &&
                                        <tr>
                                            <td className="table-rowwhite text-center" colSpan="3">
                                                There's no info yet!
                                            </td>
                                        </tr>
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default hittingMetrics;
