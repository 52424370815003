import { Table } from "antd";

function printTable({ columns, dataSource }) {
    const numberOfTables = Math.ceil(dataSource.length / 10);
    const numberOfRows = Math.round(dataSource.length / numberOfTables);
    const createRowKey = (record, index) =>
        `${record.game_pitch}-${record.batter}-${index}`;
    const formattedTableData = [];
    for (let i = 0; i < numberOfTables; i += 1) {
        formattedTableData.push(
            dataSource.slice(i * numberOfRows, (i + 1) * numberOfRows),
        );
    }
    return formattedTableData.map((table, index) => {
        return (
            <>
                <div key={index} id={`ant-table-${index}`} className="print--table--wrp">
                    {/* <h3>{`Table Part ${index + 1}`}</h3> */}
                    {/* <h3>Pitch Log</h3> */}
                    <Table columns={columns} dataSource={table} pagination={false} />
                </div>
            </>

        )
    });
}
export default printTable;