import React, { Component, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getDevelopmentSummary } from "../../../../store/actions";
import NoInfo from "../../../profiledashboard/commonChartSettings/noInfo";
import { NoEvent } from "../noInfo";
import SummaryTable from "./summryTable";

function Summary({ developA, developB }) {
  const dispatch = useDispatch();
  const [groupA, setGroupA] = useState({
    battingMaxList: [],
    battingAvgList: [],
    hittingList: [],
    pitchingMaxList: [],
    pitchingAvgList: [],
    pitcherList: [],
  });

  const [groupB, setGroupB] = useState({
    battingMaxList: [],
    battingAvgList: [],
    hittingList: [],
    pitchingMaxList: [],
    pitchingAvgList: [],
    pitcherList: [],
  });

  useEffect(() => {
    if (developA) {
      getSummary("groupA", developA);
    }
    if (developB) {
      getSummary("groupB", developB);
    }
  }, [developA, developB]);

  const getSummary = (group, req) => {
    if (group === "groupA") {
      dispatch(
        getDevelopmentSummary(req, (res) => {
          setGroupA({
            battingMaxList:
              res.data.result.batting_calc_max_rows.length != 0
                ? res.data.result.batting_calc_max_rows
                : [],
            battingAvgList:
              res.data.result.batting_calc_avg_rows.length != 0
                ? res.data.result.batting_calc_avg_rows
                : [],
            hittingList:
              res.data.result.hitter_contact_type.length != 0
                ? res.data.result.hitter_contact_type
                : [],
            pitchingMaxList:
              res.data.result.pitching_calc_max_rows.length != 0
                ? res.data.result.pitching_calc_max_rows
                : [],
            pitchingAvgList:
              res.data.result.pitching_calc_avg_rows.length != 0
                ? res.data.result.pitching_calc_avg_rows
                : [],
            pitcherList:
              res.data.result.pitcher_contact_type.length != 0
                ? res.data.result.pitcher_contact_type
                : [],
          });
        })
      );
    }
    if (group === "groupB") {
      dispatch(
        getDevelopmentSummary(req, (res) => {
          setGroupB({
            battingMaxList:
              res.data.result.batting_calc_max_rows.length != 0
                ? res.data.result.batting_calc_max_rows
                : [],
            battingAvgList:
              res.data.result.batting_calc_avg_rows.length != 0
                ? res.data.result.batting_calc_avg_rows
                : [],
            hittingList:
              res.data.result.hitter_contact_type.length != 0
                ? res.data.result.hitter_contact_type
                : [],
            pitchingMaxList:
              res.data.result.pitching_calc_max_rows.length != 0
                ? res.data.result.pitching_calc_max_rows
                : [],
            pitchingAvgList:
              res.data.result.pitching_calc_avg_rows.length != 0
                ? res.data.result.pitching_calc_avg_rows
                : [],
            pitcherList:
              res.data.result.pitcher_contact_type.length != 0
                ? res.data.result.pitcher_contact_type
                : [],
          });
        })
      );
    }
  };
  return (
    <>
      {groupA.battingMaxList.length !== 0 ||
      groupA.battingAvgList.length !== 0 ||
      groupA.pitchingMaxList.length !== 0 ||
      groupA.pitchingAvgList.length !== 0 ||
      groupA.hittingList.length !== 0 ||
      groupA.pitcherList.length !== 0 ||
      groupB.battingMaxList.length !== 0 ||
      groupB.battingAvgList.length !== 0 ||
      groupB.pitchingMaxList.length !== 0 ||
      groupB.pitchingAvgList.length !== 0 ||
      groupB.hittingList.length !== 0 ||
      groupB.pitcherList.length !== 0 ? (
        <SummaryTable
          battingMaxA={groupA.battingMaxList}
          battingMaxB={groupB.battingMaxList}
          battingAvgA={groupA.battingAvgList}
          battingAvgB={groupB.battingAvgList}
          pitchingMaxA={groupA.pitchingMaxList}
          pitchingMaxB={groupB.pitchingMaxList}
          pitchingAvgA={groupA.pitchingAvgList}
          pitchingAvgB={groupB.pitchingAvgList}
          hittingListA={groupA.hittingList}
          hittingListB={groupB.hittingList}
          pitcherListA={groupA.pitcherList}
          pitcherListB={groupB.pitcherList}
        />
      ) : (
        <div className="tabviewbtn-nodeta" >There's no info yet!</div>
      )}
    </>
  );
}

export default Summary;
