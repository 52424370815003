import React, { Component } from 'react';
// import { createColoredColumnObjTable } from "../../../shared/function";
import {
    createColoredColumnObj,
    stringValueSort,
    getSortedValues,
    createColoredColumnObjCustomScale,
    createPercentageColumnObj,
    createColoredColumnObjCustomScaleForALA,
  } from "../../../shared/function";
import { Table } from "antd";
import { constant } from '../../../shared/constant';

function BattedBallProfile({battedTable , type}) {
    var allRows = [];
    if (type === "hitterSummary") {  
        battedTable && battedTable.length !== 0 &&
        battedTable.map((list, i) =>
         allRows.push({
           key: i,
           name: list.full_name ? list.full_name : "",
           avg_ev: list.avg_ev && list.avg_ev ? Number(list.avg_ev.toFixed(1)) : "—",
           peak_ev: list.peak_ev && list.peak_ev ? Number(list.peak_ev.toFixed(1)) : "—",
           hard_hit: list.hard_hit && list.hard_hit ? Number(list.hard_hit.toFixed(1)) : "—",
           damage: list.damage && list.damage ? Number(list.damage.toFixed(1)) : "—",
           avg_la: list.avg_la && list.avg_la ? Number(list.avg_la.toFixed(1)) : "—",
           gb: list.gb && list.gb ? Number(list.gb.toFixed(1)) : "—",
           ld: list.ld && list.ld ? Number(list.ld.toFixed(1)) : "—",
           fb: list.fb && list.fb ? Number(list.fb.toFixed(1)) : "—",
           pu: list.pu && list.pu ? Number(list.pu.toFixed(1)) : "—",
           peak_distance: list.peak_distance && list.peak_distance ? Number(list.peak_distance.toFixed(1)) : "—", 
           pull_perc : list.pull_perc && list.pull_perc ? Number(list.pull_perc.toFixed(1)) : "—",
           avg_ev_pull: list.avg_ev_pull && list.avg_ev_pull ? Number(list.avg_ev_pull.toFixed(1)) : "—",
           avg_la_pull: list.avg_la_pull && list.avg_la_pull ? Number(list.avg_la_pull.toFixed(1)) : "—",
           avg_distance_pull: list.avg_distance_pull && list.avg_distance_pull ? Number(list.avg_distance_pull.toFixed(1)) : "—",
           middle_perc: list.middle_perc && list.middle_perc ? Number(list.middle_perc.toFixed(1)) : '—',
           avg_ev_middle: list.avg_ev_middle && list.avg_ev_middle ? Number(list.avg_ev_middle.toFixed(1)) : "—",
           avg_la_middle: list.avg_la_middle && list.avg_la_middle ? Number(list.avg_la_middle.toFixed(1)) : "—",
           avg_distance_middle: list.avg_distance_middle && list.avg_distance_middle ? Number(list.avg_distance_middle.toFixed(1)) : "—",
           oppo_perc: list.oppo_perc && list.oppo_perc ? Number(list.oppo_perc.toFixed(1)) : "—",
           avg_ev_oppo: list.avg_ev_oppo && list.avg_ev_oppo ? Number(list.avg_ev_oppo.toFixed(1)) : "—",
           avg_la_oppo: list.avg_la_oppo && list.avg_la_oppo ? Number(list.avg_la_oppo.toFixed(1)) : "—",
           avg_distance_oppo: list.avg_distance_oppo && list.avg_distance_oppo ? Number(list.avg_distance_oppo.toFixed(1)) : "—",
         }))
    } else {
      battedTable && battedTable.length !== 0 &&
      battedTable.map((battedTable, i) =>
        allRows.push({
            name: battedTable.full_name ? battedTable.full_name : "",
            avg_ev: battedTable.avg_ev && battedTable.avg_ev ? Number(battedTable.avg_ev.toFixed(1)) : "—",
            peak_ev: battedTable.peak_ev && battedTable.peak_ev ? Number(battedTable.peak_ev.toFixed(1)) : "—",
            hard_hit: battedTable.hard_hit && battedTable.hard_hit ? Number(battedTable.hard_hit.toFixed(1)) : "—",
            damage: battedTable.damage && battedTable.damage ? Number(battedTable.damage.toFixed(1)) : "—",
            avg_la: battedTable.avg_la && battedTable.avg_la ? Number(battedTable.avg_la.toFixed(1)) : "—",
            gb: battedTable.gb && battedTable.gb ? Number(battedTable.gb.toFixed(1)) : "—",
            ld: battedTable.ld && battedTable.ld ? Number(battedTable.ld.toFixed(1)) : "—",
            fb: battedTable.fb && battedTable.fb ? Number(battedTable.fb.toFixed(1)) : "—",
            pu: battedTable.pu && battedTable.pu ? Number(battedTable.pu.toFixed(1)) : "—",
            peak_distance: battedTable.peak_distance && battedTable.peak_distance ? Number(battedTable.peak_distance.toFixed(1)) : "—", 
            pull_perc : battedTable.pull_perc && battedTable.pull_perc ? Number(battedTable.pull_perc.toFixed(1)) : "—",
            avg_ev_pull: battedTable.avg_ev_pull && battedTable.avg_ev_pull ? Number(battedTable.avg_ev_pull.toFixed(1)) : "—",
            avg_la_pull: battedTable.avg_la_pull && battedTable.avg_la_pull ? Number(battedTable.avg_la_pull.toFixed(1)) : "—",
            avg_distance_pull: battedTable.avg_distance_pull && battedTable.avg_distance_pull ? Number(battedTable.avg_distance_pull.toFixed(1)) : "—",
            middle_perc: battedTable.middle_perc && battedTable.middle_perc ? Number(battedTable.middle_perc.toFixed(1)) : '—',
            avg_ev_middle: battedTable.avg_ev_middle && battedTable.avg_ev_middle ? Number(battedTable.avg_ev_middle.toFixed(1)) : "—",
            avg_la_middle: battedTable.avg_la_middle && battedTable.avg_la_middle ? Number(battedTable.avg_la_middle.toFixed(1)) : "—",
            avg_distance_middle: battedTable.avg_distance_middle && battedTable.avg_distance_middle ? Number(battedTable.avg_distance_middle.toFixed(1)) : "—",
            oppo_perc: battedTable.oppo_perc && battedTable.oppo_perc ? Number(battedTable.oppo_perc.toFixed(1)) : "—",
            avg_ev_oppo: battedTable.avg_ev_oppo && battedTable.avg_ev_oppo ? Number(battedTable.avg_ev_oppo.toFixed(1)) : "—",
            avg_la_oppo: battedTable.avg_la_oppo && battedTable.avg_la_oppo ? Number(battedTable.avg_la_oppo.toFixed(1)) : "—",
            avg_distance_oppo: battedTable.avg_distance_oppo && battedTable.avg_distance_oppo ? Number(battedTable.avg_distance_oppo.toFixed(1)) : "—"
        }))
    }

    const columns1 = [
        {
            title: 'Batted Ball Summary',
            children: [
                {
                    title: "Name",
                    fixed: "left",
                    dataIndex: "name",
                    sorter: (a, b) => stringValueSort(a.name, b.name),
                    sortDirections: ['descend', 'ascend'],
                    className: "tablegraybp",
                    render(text,record) {
                      return {
                        props: {
                          style: { background: record.name === "Session Average" ? "silver" : "" }
                        },
                        children: <div>{text}</div>
                      };
                    }
                },
                createColoredColumnObjCustomScale("Avg EV", "avg_ev", allRows, false, {
                     sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "avg_ev"),
                     sortDirections: ['descend', 'ascend'],
                     minRange: constant.softball ? 35 : 70,
                     className: "tablegraybp",
                }),
                createColoredColumnObjCustomScale("Peak EV", "peak_ev", allRows, false, {
                     sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "peak_ev"),
                    sortDirections: ['descend', 'ascend'],
                    minRange: constant.softball ? 35 : 70,
                    className: "tablegraybp",
                }),
                createPercentageColumnObj('Hard Hit %', 'hard_hit', allRows, true, {
                  className: "tablegraybp",
                  sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "hard_hit"),
                  sortDirections: ['descend', 'ascend'],
                }),
                createPercentageColumnObj('Damage %', 'damage', allRows, true, {
                  className: "tablegraybp",
                  sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "damage"),
                  sortDirections: ['descend', 'ascend'],
                }),
                {
                  title: "Avg LA",
                  dataIndex: "avg_la",
                  sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "avg_la"),
                  sortDirections: ['descend', 'ascend'],
                  className: "tablegraybp",
                  render(text,record) {
                    return {
                      props: {
                        style: { background: record.name === "Session Average" ? "silver" : "" }
                      },
                      children: <div>{text}</div>
                    };
                  }
                },
                createPercentageColumnObj('GB %', 'gb', allRows, true, {
                  className: "tablegraybp",
                  sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "gb"),
                  sortDirections: ['descend', 'ascend'],
                }),
                createPercentageColumnObj('LD %', 'ld', allRows, true, {
                  className: "tablegraybp",
                  sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "ld"),
                  sortDirections: ['descend', 'ascend'],
                }),
                createPercentageColumnObj('FB %', 'fb', allRows, true, {
                  className: "tablegraybp",
                  sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "fb"),
                  sortDirections: ['descend', 'ascend'],
                }),
                createPercentageColumnObj('PU %', 'pu', allRows, true, {
                  className: "tablegraybp",
                  sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "pu"),
                  sortDirections: ['descend', 'ascend'],
                }),
                // createColoredColumnObj('Peak Dist', 'peak_distance', allRows, false, {
                //     sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "peak_distance"),
                //     sortDirections: ['descend', 'ascend'],
                //     className: "tablegraybp",
                // }),
            ],
        }
      ]

      const columns = [
        {
            title: 'Batted Ball Profile By Field',
            children: [
                {
                  title: "Name",
                  fixed: "left",
                  dataIndex: "name",
                  sorter: (a, b) => stringValueSort(a.name, b.name),
                  sortDirections: ['descend', 'ascend'],
                  className: "tablegraybp",
                  render(text,record) {
                    return {
                      props: {
                        style: { background: record.name === "Session Average" ? "silver" : "" }
                      },
                      children: <div>{text}</div>
                    };
                  }
                },
                createColoredColumnObjCustomScale('Pull %', 'pull_perc', allRows, true, {
                    sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "pull_perc"),
                    sortDirections: ['descend', 'ascend'],
                    minRange: 0,
                    className: "tablegraybp",
                }),
                // {
                //     title: "AEV Pull",
                //     dataIndex: "avg_ev_pull",
                //     sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "avg_ev_pull"),
                //     sortDirections: ['descend', 'ascend'],
                // },
                createColoredColumnObjCustomScale("Mid %", "middle_perc", allRows, true, {
                    sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "middle_perc"),
                    sortDirections: ['descend', 'ascend'],
                    minRange: 0,
                    className: "tablegraybp",
                }),
                createColoredColumnObjCustomScale('Oppo %', 'oppo_perc', allRows, true, {
                    sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "oppo_perc"),
                    sortDirections: ['descend', 'ascend'],
                    minRange: 0,
                    className: "tablegraybp",
                }),
                createColoredColumnObjCustomScale('AEV Pull', 'avg_ev_pull', allRows, false, {
                    sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "avg_ev_pull"),
                    sortDirections: ['descend', 'ascend'],
                    minRange: constant.softball ? 25 : 70,
                    className: "tablegraybp",
                }),
                createColoredColumnObjCustomScale("AEV Mid", "avg_ev_middle", allRows, false, {
                    sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "avg_ev_middle"),
                    sortDirections: ['descend', 'ascend'],
                    minRange: constant.softball ? 25 : 70,
                    className: "tablegraybp",
                }),
                createColoredColumnObjCustomScale('AEV Oppo', 'avg_ev_oppo', allRows, false, {
                    sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "avg_ev_oppo"),
                    sortDirections: ['descend', 'ascend'],
                    minRange: constant.softball ? 25 : 70,
                    className: "tablegraybp",
                }),
                createColoredColumnObjCustomScaleForALA('ALA Pull', 'avg_la_pull', allRows, false, {
                  sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "avg_la_pull"),
                  sortDirections: ['descend', 'ascend'],
                  minRange: 0,
                  className: "tablegraybp",
              }),
              createColoredColumnObjCustomScaleForALA("ALA Mid", "avg_la_middle", allRows, false, {
                  sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "avg_la_middle"),
                  sortDirections: ['descend', 'ascend'],
                  minRange: 0,
                  className: "tablegraybp",
              }),
              createColoredColumnObjCustomScaleForALA('ALA Oppo', 'avg_la_oppo', allRows, false, {
                  sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "avg_la_oppo"),
                  sortDirections: ['descend', 'ascend'],
                  minRange: 0,
                  className: "tablegraybp",
              }),
                // {
                //     title: "Avg Dist Pull",
                //     dataIndex: "avg_distance_pull",
                //     sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "avg_distance_pull"),
                //     sortDirections: ['descend', 'ascend'],
                // },
                // createColoredColumnObj("Avg Dist Pull", "avg_distance_pull", allRows, false, {
                //     sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "avg_distance_pull"),
                //     sortDirections: ['descend', 'ascend'],
                //     className: "tablegraybp",
                // }),
                // {
                //     title: "AEV Middle",
                //     dataIndex: "avg_ev_middle",
                //     sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "avg_ev_middle"),
                //     sortDirections: ['descend', 'ascend'],
                // },
                // createColoredColumnObj('Avg Dist Middle', 'avg_distance_middle', allRows, false, {
                //     sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "avg_distance_middle"),
                //     sortDirections: ['descend', 'ascend'],
                //     className: "tablegraybp",
                // }),
                // {
                //     title: "Avg Dist Middle",
                //     dataIndex: "avg_distance_middle",
                //     sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "avg_distance_middle"),
                //     sortDirections: ['descend', 'ascend'],
                // },
                // {
                //     title: "AEV Oppo",
                //     dataIndex: "avg_ev_oppo",
                //     sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "avg_ev_oppo"),
                //     sortDirections: ['descend', 'ascend'],
                // },
                // createColoredColumnObj('Avg Dist Oppo', 'avg_distance_oppo', allRows, false, {
                //     sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "avg_distance_oppo"),
                //     sortDirections: ['descend', 'ascend'],
                //     className: "tablegraybp",
                // }),
                // {
                //     title: "Avg Dist Oppo",
                //     dataIndex: "avg_distance_oppo",
                //     sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "avg_distance_oppo"),
                //     sortDirections: ['descend', 'ascend'],
                // },
            ],
        }
    ];

    // Batting Practice Player Table Header
    const columnPlayer1 = [
      {
        title: 'Batted Ball Summary',
        children: [
          {
            title: 'Name',
            fixed: "left",
            dataIndex: 'name',
            key: 'name',
            className: "tablegraybp",
            render(text,record) {
              return {
                props: {
                  style: { background: record.name === "Session Average" ? "silver" : "" }
                },
                children: <div>{text}</div>
              };
            }
          },
          {
            title: 'Avg EV',
            dataIndex: 'avg_ev',
            key: 'avg_ev',
            className: "tablegraybp",
            render(text,record) {
              return {
                props: {
                  style: { background: record.name === "Session Average" ? "silver" : "" }
                },
                children: <div>{text}</div>
              };
            }
          },
          {
            title: 'Peak EV',
            dataIndex: 'peak_ev',
            key: 'peak_ev',
            className: "tablegraybp",
            render(text,record) {
              return {
                props: {
                  style: { background: record.name === "Session Average" ? "silver" : "" }
                },
                children: <div>{text}</div>
              };
            }
          },
          createPercentageColumnObj('Hard Hit %', 'hard_hit', allRows, true, {
            className: "tablegraybp",
          }),
          createPercentageColumnObj('Damage %', 'damage', allRows, true, {
            className: "tablegraybp",
          }),
          {
            title: 'Avg LA',
            dataIndex: 'avg_la',
            key: 'avg_la',
            className: "tablegraybp",
            render(text,record) {
              return {
                props: {
                  style: { background: record.name === "Session Average" ? "silver" : "" }
                },
                children: <div>{text}</div>
              };
            }
          },
          createPercentageColumnObj('GB %', 'gb', allRows, true, {
            className: "tablegraybp",
          }),
          createPercentageColumnObj('LD %', 'ld', allRows, true, {
            className: "tablegraybp",
          }),
          createPercentageColumnObj('FB %', 'fb', allRows, true, {
            className: "tablegraybp",
          }),
          createPercentageColumnObj('PU %', 'pu', allRows, true, {
            className: "tablegraybp",
          }),
          // {
          //   title: 'Peak Dist',
          //   dataIndex: 'peak_distance',
          //   key: 'peak_distance',
          //   className: "tablegraybp",
          // },
        ],
      }
    ]

    const columnPlayer = [
      {
        title: 'Batted Ball Profile By Field',
        children: [
          {
            title: 'Name',
            fixed: "left",
            dataIndex: 'name',
            key: 'name',
            className: "tablegraybp",
            render(text,record) {
              return {
                props: {
                  style: { background: record.name === "Session Average" ? "silver" : "" }
                },
                children: <div>{text}</div>
              };
            }
          },
          createPercentageColumnObj('Pull %', 'pull_perc', allRows, true, {
            className: "tablegraybp",
          }),
          createPercentageColumnObj('Mid %', 'middle_perc', allRows, true, {
            className: "tablegraybp",
          }),
          createPercentageColumnObj('Oppo %', 'oppo_perc', allRows, true, {
            className: "tablegraybp",
          }),
          {
            title: 'AEV Pull',
            dataIndex: 'avg_ev_pull',
            key: 'avg_ev_pull',
            className: "tablegraybp",
            render(text,record) {
              return {
                props: {
                  style: { background: record.name === "Session Average" ? "silver" : "" }
                },
                children: <div>{text}</div>
              };
            }
          },
          {
            title: 'AEV Mid',
            dataIndex: 'avg_ev_middle',
            key: 'avg_ev_middle',
            className: "tablegraybp",
            render(text,record) {
              return {
                props: {
                  style: { background: record.name === "Session Average" ? "silver" : "" }
                },
                children: <div>{text}</div>
              };
            }
          },
          {
            title: 'AEV Oppo',
            dataIndex: 'avg_ev_oppo',
            key: 'avg_ev_oppo',
            className: "tablegraybp",
            render(text,record) {
              return {
                props: {
                  style: { background: record.name === "Session Average" ? "silver" : "" }
                },
                children: <div>{text}</div>
              };
            }
          },
          {
            title: 'ALA Pull',
            dataIndex: 'avg_la_pull',
            key: 'avg_la_pull',
            className: "tablegraybp",
            render(text,record) {
              return {
                props: {
                  style: { background: record.name === "Session Average" ? "silver" : "" }
                },
                children: <div>{text}</div>
              };
            }
          },
          {
            title: 'ALA Mid',
            dataIndex: 'avg_la_middle',
            key: 'avg_la_middle',
            className: "tablegraybp",
            render(text,record) {
              return {
                props: {
                  style: { background: record.name === "Session Average" ? "silver" : "" }
                },
                children: <div>{text}</div>
              };
            }
          },
          {
            title: 'ALA Oppo',
            dataIndex: 'avg_la_oppo',
            key: 'avg_la_oppo',
            className: "tablegraybp",
            render(text,record) {
              return {
                props: {
                  style: { background: record.name === "Session Average" ? "silver" : "" }
                },
                children: <div>{text}</div>
              };
            }
          },
          // {
          //   title: 'Avg Dist Pull',
          //   dataIndex: 'avg_distance_pull',
          //   key: 'avg_distance_pull',
          //   className: "tablegraybp",
          // },
          // {
          //   title: 'Avg Dist Middle',
          //   dataIndex: 'avg_distance_middle',
          //   key: 'avg_distance_middle',
          //   className: "tablegraybp",
          // },
          // {
          //   title: 'Avg Dist Oppo',
          //   dataIndex: 'avg_distance_oppo',
          //   key: 'avg_distance_oppo',
          //   className: "tablegraybp",
          // },
        ],
      },
    ];

    const data = allRows;

    const onChange = (filters, sorter, extra) => {
        // console.log('params', filters, sorter, extra);
    };
    return (
        <>
            {/* <div className="table-responsive-xl">
                <table className="table tabledashed">
                    <thead>
                        <tr>
                            <th colSpan="5" className="text-center">Batted Ball Profile</th>
                            <th colSpan="12" className="text-center">Batted Ball Profile By Field</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="tablerowgray fw-500">Name</td>
                            <td className="tablerowgray fw-500">Avg EV</td>
                            <td className="tablerowgray fw-500">Peak EV</td>
                            <td className="tablerowgray fw-500">Avg LA</td>
                            <td className="tablerowgray fw-500">Peak Dist</td>
                            <td className="tablerowgray fw-500">Pull %</td>
                            <td className="tablerowgray fw-500">AEV Pull</td>
                            <td className="tablerowgray fw-500">ALA Pull</td>
                            <td className="tablerowgray fw-500">Avg Dist Pull</td>
                            <td className="tablerowgray fw-500">Middle %</td>
                            <td className="tablerowgray fw-500">AEV Middle</td>
                            <td className="tablerowgray fw-500">ALA Middle</td>
                            <td className="tablerowgray fw-500">Avg Dist Middle</td>
                            <td className="tablerowgray fw-500">Oppo %</td>
                            <td className="tablerowgray fw-500">AEV Oppo</td>
                            <td className="tablerowgray fw-500">ALA Oppo</td>
                            <td className="tablerowgray fw-500">Avg Dist Oppo</td>
                        </tr>
                        {type === "hitterSummary" ? battedTable.length !== 0 ? (
                            battedTable.map((data, i) => {
                                return (
                                    <tr key={i}>
                                        <td className="table-rowwhite">{data.full_name ? data.full_name : '—'}</td>
                                        <td className="table-rowwhite" style={createColoredColumnObjTable('avg_ev', battedTable, data)}>{data.avg_ev && data.avg_ev ? Number(data.avg_ev.toFixed(1)) : '—'}</td>
                                        <td className="table-rowwhite" style={createColoredColumnObjTable('peak_ev', battedTable, data)}>{data.peak_ev && data.peak_ev ? Number(data.peak_ev.toFixed(1)) : '—'}</td>
                                        <td className="table-rowwhite">{data.avg_la && data.avg_la ? Number(data.avg_la.toFixed(1)) : '—'}</td>
                                        <td className="table-rowwhite" style={createColoredColumnObjTable('peak_distance', battedTable, data)}>{data.peak_distance && data.peak_distance ? Number(data.peak_distance.toFixed(1)) : '—'}</td>
                                        <td className="table-rowwhite" style={createColoredColumnObjTable('pull_perc', battedTable, data)}>{data.pull_perc && data.pull_perc ? Number(data.pull_perc.toFixed(1)) + "%" : "0%"}</td>
                                        <td className="table-rowwhite">{data.avg_ev_pull && data.avg_ev_pull ? Number(data.avg_ev_pull.toFixed(1)) : '—'}</td>
                                        <td className="table-rowwhite">{data.avg_la_pull && data.avg_la_pull ? Number(data.avg_la_pull.toFixed(1)) : '—'}</td>
                                        <td className="table-rowwhite">{data.avg_distance_pull && data.avg_distance_pull ? Number(data.avg_distance_pull.toFixed(1)) : '—'}</td>
                                        <td className="table-rowwhite" style={createColoredColumnObjTable('middle_perc', battedTable, data)}>{data.middle_perc && data.middle_perc ? Number(data.middle_perc.toFixed(1)) + "%" : "0%"}</td>
                                        <td className="table-rowwhite">{data.avg_ev_middle && data.avg_ev_middle ? Number(data.avg_ev_middle.toFixed(1)) : '—'}</td>
                                        <td className="table-rowwhite">{data.avg_la_middle && data.avg_la_middle ? Number(data.avg_la_middle.toFixed(1)) : '—'}</td>
                                        <td className="table-rowwhite">{data.avg_distance_middle && data.avg_distance_middle ? Number(data.avg_distance_middle.toFixed(1)) : '—'}</td>
                                        <td className="table-rowwhite" style={createColoredColumnObjTable('oppo_perc', battedTable, data)}>{data.oppo_perc && data.oppo_perc ? Number(data.oppo_perc.toFixed(1)) + "%" : "0%"}</td>
                                        <td className="table-rowwhite">{data.avg_ev_oppo && data.avg_ev_oppo ? Number(data.avg_ev_oppo.toFixed(1)) : '—'}</td>
                                        <td className="table-rowwhite">{data.avg_la_oppo && data.avg_la_oppo ? Number(data.avg_la_oppo.toFixed(1)) : '—'}</td>
                                        <td className="table-rowwhite">{data.avg_distance_oppo && data.avg_distance_oppo ? Number(data.avg_distance_oppo.toFixed(1)) : '—'}</td>
                                    </tr>
                                )
                            })
                        ) : (
                                <tr>
                                    <td className="table-rowwhite text-center" colSpan="17">
                                        There's no info yet!
                                        </td>
                                </tr>
                            ) :
                            (
                                <tr>
                                    <td className="table-rowwhite">{battedTable.full_name ? battedTable.full_name : '—'}</td>
                                    <td className="table-rowwhite">{battedTable.avg_ev && battedTable.avg_ev ? Number(battedTable.avg_ev.toFixed(1)) : '—'}</td>
                                    <td className="table-rowwhite">{battedTable.peak_ev && battedTable.peak_ev ? Number(battedTable.peak_ev.toFixed(1)) : '—'}</td>
                                    <td className="table-rowwhite">{battedTable.avg_la && battedTable.avg_la ? Number(battedTable.avg_la.toFixed(1)) : '—'}</td>
                                    <td className="table-rowwhite">{battedTable.peak_distance && battedTable.peak_distance ? Number(battedTable.peak_distance.toFixed(1)) : '—'}</td>
                                    <td className="table-rowwhite">{battedTable.pull_perc && battedTable.pull_perc ? Number(battedTable.pull_perc.toFixed(1)) + "%" : "0%"}</td>
                                    <td className="table-rowwhite">{battedTable.avg_ev_pull && battedTable.avg_ev_pull ? Number(battedTable.avg_ev_pull.toFixed(1)) : '—'}</td>
                                    <td className="table-rowwhite">{battedTable.avg_la_pull && battedTable.avg_la_pull ? Number(battedTable.avg_la_pull.toFixed(1)) : '—'}</td>
                                    <td className="table-rowwhite">{battedTable.avg_distance_pull && battedTable.avg_distance_pull ? Number(battedTable.avg_distance_pull.toFixed(1)) : '—'}</td>
                                    <td className="table-rowwhite">{battedTable.middle_perc && battedTable.middle_perc ? Number(battedTable.middle_perc.toFixed(1)) + "%" : "0%"}</td>
                                    <td className="table-rowwhite">{battedTable.avg_ev_middle && battedTable.avg_ev_middle ? Number(battedTable.avg_ev_middle.toFixed(1)) : '—'}</td>
                                    <td className="table-rowwhite">{battedTable.avg_la_middle && battedTable.avg_la_middle ? Number(battedTable.avg_la_middle.toFixed(1)) : '—'}</td>
                                    <td className="table-rowwhite">{battedTable.avg_distance_middle && battedTable.avg_distance_middle ? Number(battedTable.avg_distance_middle.toFixed(1)) : '—'}</td>
                                    <td className="table-rowwhite">{battedTable.oppo_perc && battedTable.oppo_perc ? Number(battedTable.oppo_perc.toFixed(1)) + "%" : "0%"}</td>
                                    <td className="table-rowwhite">{battedTable.avg_ev_oppo && battedTable.avg_ev_oppo ? Number(battedTable.avg_ev_oppo.toFixed(1)) : '—'}</td>
                                    <td className="table-rowwhite">{battedTable.avg_la_oppo && battedTable.avg_la_oppo ? Number(battedTable.avg_la_oppo.toFixed(1)) : '—'}</td>
                                    <td className="table-rowwhite">{battedTable.avg_distance_oppo && battedTable.avg_distance_oppo ? Number(battedTable.avg_distance_oppo.toFixed(1)) : '—'}</td>
                                </tr>
                            )}
                    </tbody>
                </table>
            </div> */}
            <div className="table-responsive-xl">
                <table className="table with-border">
                    <Table
                    columns={type === "hitterSummary" ? columns1 : columnPlayer1 }
                    dataSource={data}
                    onChange={onChange}
                    pagination={false}
                    />
                </table>
            </div>
            <div className="table-responsive-xl">
                <table className="table with-border">
                    <Table
                    columns={type === "hitterSummary" ? columns : columnPlayer }
                    dataSource={data}
                    onChange={onChange}
                    pagination={false}
                    />
                </table>
            </div>
        </>
    );
}

export default BattedBallProfile;