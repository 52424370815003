import React, { Component } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Streamgraph from 'highcharts/modules/streamgraph.js';
import BasicSpray from '../../../shared/SprayChartd3'
import * as d3 from 'd3';
// import StrikeZoneChart from "./component/strikeZoneChart";
import StrikeZoneChart from "../Chart/strikeZoneChart";
import SpyayChart from "./component/spyayChart";
import ContourGraphChart from "./component/contourGraphChart";
import { constant } from '../../../shared/constant';

Streamgraph(Highcharts);
class platediscipline extends Component {

    componentDidMount = () => {
        this.renderChart()
    }

    renderChart = () => {

        const selection = d3.select('#groundChart');
        if (!selection.empty()) {
            const sprayChart = new BasicSpray(selection, constant.softball);
            const data = [
                {
                    "dr": 27.231,
                    "ds": 279.313
                }, {
                    "dr": 13.221,
                    "ds": 289.428
                }, {
                    "dr": 25.65,
                    "ds": 100.5
                }
            ]
            sprayChart.update(data);
        }
    }

    renderTable = () => {
        let plate_discipline_table_Data = [ ...(this.props.filterRes && this.props.filterRes.plate_discipline_table_data_pitch_query || []), ...(this.props.filterRes && this.props.filterRes.plate_discipline_table_data_query || [])]
        return (
            plate_discipline_table_Data.length !== 0 &&
            plate_discipline_table_Data.map((list, i) => {

                let rowColor = ""
                if (list.season === "Player") {
                    rowColor="table-rowoasis"
                } else if (list.season === "D1 Average" || list.season === "Indy Ball") {
                    rowColor="table-rowgrey80"
                } else if (list.season === "Team Average") {
                    rowColor="table-rowwhitesmoke"
                } else {
                    rowColor="table-rowwhite"
                }

                return (
                    <tr key={i}>
                        <td className={`${rowColor}`} style={{color: constant.pitchTypeColor[list._id]}}>{list.hasOwnProperty('_id') ? list._id ? list._id : "—" : list.season !== null ? list.season : "—"}</td>
                        <td className={`${rowColor}`}>{list.count !== null ? list.count : "—"}</td>
                        <td className={`${rowColor}`}>{list.swing ? list.swing.toString().includes(".") ? list.swing.toFixed(1) + "%" : list.swing + "%": "—"}</td>
                        <td className={`${rowColor}`}>{list.swinging_strike ? list.swinging_strike.toString().includes(".") ? list.swinging_strike.toFixed(1) + "%" : list.swinging_strike + "%": "—"}</td>
                        <td className={`${rowColor}`}>{list.whiff ? list.whiff.toString().includes(".") ? list.whiff.toFixed(1) + "%" : list.whiff + "%": "—"}</td>
                        <td className={`${rowColor}`}>{list.zone_swing ? list.zone_swing.toString().includes(".") ? list.zone_swing.toFixed(1) + "%" : list.zone_swing + "%": "—"}</td>
                        <td className={`${rowColor}`}>{list.zone_contact ? list.zone_contact.toString().includes(".") ? list.zone_contact.toFixed(1) + "%" : list.zone_contact + "%": "—"}</td>
                        <td className={`${rowColor}`}>{list.chase_swing ? list.chase_swing.toString().includes(".") ? list.chase_swing.toFixed(1) + "%" : list.chase_swing + "%": "—"}</td>
                        <td className={`${rowColor}`}>{list.chase_contact ? list.chase_contact.toString().includes(".") ? list.chase_contact.toFixed(1) + "%" : list.chase_contact + "%": "—"}</td>
                        <td className={`${rowColor}`}>{list.egde ? list.egde.toString().includes(".") ? list.egde.toFixed(1) + "%" : list.egde + "%": "—"}</td>
                        <td className={`${rowColor}`}>{list.strike ? list.strike.toString().includes(".") ? list.strike.toFixed(1) + "%" : list.strike + "%": "—"}</td>
                    </tr>
                )
            })
        )
    }

    render() {
        return (
            <>
                <div className="d-flex">
                    <div className="w-100">
                        <div className="container-fluid">
                            <div className="common-charts">
                                <div className={`row ${!this.props.isPrinting ? 'singleDiv' : 'breakDiv'}`}>
                                    <div className="w-33">
                                        {
                                            this.props.filterRes &&
                                            <StrikeZoneChart
                                                chartData={this.props.filterRes.plate_discipline_scatter_plot}
                                                setScatterAxis={this.props.setScatterAxis}
                                                onResetScatterFilter={this.props.applyFilter}
                                                oldData={this.props.oldData}
                                                cameraAngle={["Strike Zone", "Catcher", "Home Top", "Home 3B Side", "Home 1B Side", "Press Box"]}
                                                visualType="Pitching"
                                                eventProfileData={this.props.eventProfileData}
                                                sendReq={this.props?.sendReq}
                                                tabslist={this.props?.tabslist}
                                                profilepage={true}
                                                isNotDownloadImgLoader={this.props.isNotDownloadImgLoader}
                                                isDownloadImgLoader={this.props.isDownloadImgLoader}
                                                pitchrIframeFlag={this.props.pitchrIframeFlag}
                                                closePitchrIframe={this.props.closePitchrIframe} />
                                        }
                                    </div>
                                    <div className="w-33">
                                        {
                                            this.props.filterRes &&
                                            <SpyayChart
                                                chartData={this.props.filterRes.plate_discipline_spray_chart_series}
                                                cameraAngle={["Press Box", "Spray Chart", "First Base", "Third Base"]}
                                                visualType="Hitting"
                                                eventProfileData={this.props.eventProfileData}
                                                sendReq={this.props?.sendReq}
                                                tabslist={this.props?.tabslist}
                                                profilepage={true}
                                                isNotDownloadImgLoader={this.props.isNotDownloadImgLoader}
                                                isDownloadImgLoader={this.props.isDownloadImgLoader}
                                                pitchrIframeFlag={this.props.pitchrIframeFlag}
                                                closePitchrIframe={this.props.closePitchrIframe} />
                                        }
                                    </div>
                                    <div className="w-33">
                                        {
                                            this.props.filterRes &&
                                            <ContourGraphChart
                                                chartData={this.props.filterRes.contour_graph_data}
                                                contour_graph={this.props.contour_graph}
                                                onChangeEvent={this.props.onChangeEvent}
                                                cameraAngle={["Strike Zone", "Catcher", "Home Top", "Home 3B Side", "Home 1B Side", "Press Box"]}
                                                visualType={localStorage.getItem("activeType") === "Pitching" ? "Pitching" : "Both"}
                                                eventProfileData={this.props.eventProfileData}
                                                sendReq={this.props?.sendReq}
                                                tabslist={this.props?.tabslist}
                                                profilepage={true}
                                                isNotDownloadImgLoader={this.props.isNotDownloadImgLoader}
                                                isDownloadImgLoader={this.props.isDownloadImgLoader}
                                                pitchrIframeFlag={this.props.pitchrIframeFlag}
                                                closePitchrIframe={this.props.closePitchrIframe} />
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive-xl">
                                <table className="table tabledashed">
                                    <thead>
                                        <tr>
                                            <th colSpan="11" className="one-child text-center">Plate Discipline</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="tablerowgray fw-500">Type</td>
                                            <td className="tablerowgray fw-500">#</td>
                                            <td className="tablerowgray fw-500">Swing %</td>
                                            <td className="tablerowgray fw-500">Swinging Strike %</td>
                                            <td className="tablerowgray fw-500">Whiff %</td>
                                            {/* <td className="tablerowgray fw-500">Zone %</td> */}
                                            <td className="tablerowgray fw-500">Zone Swing %</td>
                                            <td className="tablerowgray fw-500">Zone Contact %</td>
                                            {/* <td className="tablerowgray fw-500">Chase %</td> */}
                                            <td className="tablerowgray fw-500">Chase Swing %</td>
                                            <td className="tablerowgray fw-500">Chase Contact %</td>
                                            <td className="tablerowgray fw-500">Edge %</td>
                                            <td className="tablerowgray fw-500">Strike %</td>
                                        </tr>
                                        {this.renderTable()}
                                        {/* {
                                            this.props.filterRes &&
                                            this.props.filterRes.plate_discipline_table_data_query &&
                                            this.props.filterRes.plate_discipline_table_data_query.length !== 0 &&
                                            <tr>
                                                <td className="table-rowwhite">D1 Average</td>
                                                <td className="table-rowwhite">—</td>
                                                <td className="table-rowwhite">—</td>
                                                <td className="table-rowwhite">—</td>
                                                <td className="table-rowwhite">—</td>
                                                <td className="table-rowwhite">—</td>
                                                <td className="table-rowwhite">—</td>
                                                <td className="table-rowwhite">—</td>
                                                <td className="table-rowwhite">—</td>
                                                <td className="table-rowwhite">—</td>
                                            </tr>
                                        } */}
                                        {
                                            this.props.filterRes &&
                                            this.props.filterRes.plate_discipline_table_data_query &&
                                            this.props.filterRes.plate_discipline_table_data_query.length === 0 &&
                                            <tr>
                                                <td colSpan="11" className="text-center table-rowwhite">There's is no Info yet!</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default platediscipline;
