import { useEffect, useState } from "react";
import { battingSummaryformMaxAndAvg,pitcherSummaryformMaxAndAvg ,formateForContactType} from "../../../../shared/function";
import SummaryTableList from './summryTableList';

function SummaryTable({ title, battingMaxA, battingMaxB, battingAvgA, battingAvgB, pitchingMaxA, pitchingMaxB, pitchingAvgA, pitchingAvgB ,hittingListA ,hittingListB , pitcherListA ,pitcherListB }) {
    const [state, setstate] = useState({
        battingMax : {},
        battingAvg: {},
        pitchingMax : {},
        pitchingAvg: {},
        hittingList: {},
        pitcherList: {},
    })

    useEffect(() => {
      setstate({
          battingMax: battingSummaryformMaxAndAvg(battingMaxA && battingMaxA.length !== 0 ? battingMaxA : [], battingMaxB && battingMaxB.length !== 0 ? battingMaxB : []),
          battingAvg: battingSummaryformMaxAndAvg(battingAvgA && battingAvgA.length !== 0 ? battingAvgA : [], battingAvgB && battingAvgB.length !== 0 ? battingAvgB : []),
          pitchingMax: pitcherSummaryformMaxAndAvg(pitchingMaxA && pitchingMaxA.length !== 0 ? pitchingMaxA : [], pitchingMaxB && pitchingMaxB.length !== 0 ? pitchingMaxB : []),
          pitchingAvg: pitcherSummaryformMaxAndAvg(pitchingAvgA && pitchingAvgA.length !== 0 ? pitchingAvgA : [], pitchingAvgB && pitchingAvgB.length !== 0 ? pitchingAvgB : []),
          hittingList: formateForContactType(hittingListA && hittingListA.length !== 0 ? hittingListA : [], hittingListB && hittingListB.length !== 0 ? hittingListB : []),
          pitcherList: formateForContactType(pitcherListA && pitcherListA.length !== 0 ? pitcherListA : [], pitcherListB && pitcherListB.length !== 0 ? pitcherListB : []),
      })
    }, [battingMaxA, battingMaxB, battingAvgA, battingAvgB, pitchingMaxA, pitchingMaxB, pitchingAvgA, pitchingAvgB, hittingListA, hittingListB, pitcherListA, pitcherListB])

    return (
        <>
            {Object.keys(state.pitchingMax).length != 0 &&
                <>
                    <h3>Pitcher Maximums</h3>
                    <div className="table-responsive-xl">
                        <table className="table tabledashed">
                            <thead>
                                <tr>
                                    <th>Titles</th>
                                    <th>Group A</th>
                                    <th>Group B</th>
                                    <th>Difference</th>
                                </tr>
                            </thead>
                            <tbody>
                                <SummaryTableList data={state.pitchingMax} type="pitcher" />
                            </tbody>
                        </table>
                    </div>
                </>}
            {Object.keys(state.pitchingAvg).length != 0 &&
                <> <h3>Pitcher Averages</h3>
                    <div className="table-responsive-xl">
                        <table className="table tabledashed">
                            <thead>
                                <tr>
                                    <th>Titles</th>
                                    <th>Group A</th>
                                    <th>Group B</th>
                                    <th>Difference</th>
                                </tr>
                            </thead>
                            <tbody>
                                <SummaryTableList data={state.pitchingAvg} type="pitcher" />
                            </tbody>
                        </table>
                    </div>
                </>}
            {Object.keys(state.pitcherList).length != 0 &&
                <>
                    <h3>Pitcher Contact Type</h3>
                    <div className="table-responsive-xl">
                        <table className="table tabledashed">
                            <thead>
                                <tr>
                                    <th>Titles</th>
                                    <th>Group A</th>
                                    <th>Group B</th>
                                    <th>Difference</th>
                                </tr>
                            </thead>
                            <tbody>
                                <SummaryTableList data={state.pitcherList} type="pitcher" subType="contact" />
                            </tbody>
                        </table>
                    </div>
                </>}
            {Object.keys(state.battingMax).length != 0 &&
                <>
                    <h3>Hitter Maximums</h3>
                    <div className="table-responsive-xl">
                        <table className="table tabledashed">
                            <thead>
                                <tr>
                                    <th>Titles</th>
                                    <th>Group A</th>
                                    <th>Group B</th>
                                    <th>Difference</th>
                                </tr>
                            </thead>
                            <tbody>
                                <SummaryTableList data={state.battingMax} type="batting" />
                            </tbody>
                        </table>
                    </div>
                </>}
            {Object.keys(state.battingAvg).length != 0 &&
                <>
                    <h3>Hitter Averages</h3>
                    <div className="table-responsive-xl">
                        <table className="table tabledashed">
                            <thead>
                                <tr>
                                    <th>Titles</th>
                                    <th>Group A</th>
                                    <th>Group B</th>
                                    <th>Difference</th>
                                </tr>
                            </thead>
                            <tbody>
                                <SummaryTableList data={state.battingAvg} type="batting" />
                            </tbody>
                        </table>
                    </div>
                </>}
            {Object.keys(state.hittingList).length != 0 &&
                <>
                    <h3>Hitter Contact Type</h3>
                    <div className="table-responsive-xl">
                        <table className="table tabledashed">
                            <thead>
                                <tr>
                                    <th>Titles</th>
                                    <th>Group A</th>
                                    <th>Group B</th>
                                    <th>Difference</th>
                                </tr>
                            </thead>
                            <tbody>
                                <SummaryTableList data={state.hittingList} type="batting" subType="contact" />
                            </tbody>
                        </table>
                    </div>
                </>}

        </>
    )
}

export default SummaryTable;