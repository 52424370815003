import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import { filterDotChartWithFilter } from "../../../../shared/function";
import { constant } from "../../../../shared/constant";
import BallrWebGL from "../../../../view/webGL/BallrWebGL";
import domtoimage from 'dom-to-image';
import PrintImagePopup from "../../../../shared/PrintImagePopup";
import { downloadChartPrintImg } from "../../../../shared/printReportUtils";
import moment from "moment";

function PitchersMainChart({ chartData, metricHandleChange, spline_chart, id, printAll, eventName,eventProfileData, pitcherDefault, profilepage, isNotDownloadImgLoader, isDownloadImgLoader }) {
  const [isChartImg, setIsChartImg] = useState(false);
  const [clickFlag, setClickFlag] = useState(false);
  const [ballrFlag, setBallrFlag] = useState(false);
  const [tooltipData, setTooltipData] = useState(null);
  const [state, setState] = useState({
    chart: {
      // type: "spline",
      borderWidth: 0,
      marginTop: 90,
      borderColor: "#D8D8D8",
      borderRadius: "8px",
    },
    title: {
      text: "",
      style: {
        color: "#1B1B1B",
        fontFamily: "Poppins, sans-serif",
        fontSize: "15px",
        fontWeight: "600",
      },
    },
    xAxis: {
      endOnTick: false,
      maxPadding: 0,
      gridLineWidth: 1,
      allowDecimals: false,
      title: {
        text: "Pitch Number",
        style: {
          color: "#777F8F",
          fontFamily: "Poppins, sans-serif",
          fontSize: "14px",
          fontWeight: "500",
        },
      },
    },
    yAxis: {
      endOnTick: false,
      maxPadding: 0,
      gridLineWidth: 1,
      title: {
        text: "Velocity (mph)",
        style: {
          color: "#777F8F",
          fontFamily: "Poppins, sans-serif",
          fontSize: "14px",
          fontWeight: "500",
        },
      },
      labels: {
        formatter: function () {
          return this.value + "";
        },
      },
    },
    legend: {
      layout: "horizontal",
      align: "top",
      verticalAlign: "top",
      y: 10,
    },
    credits: { enabled: false },
    plotOptions: {
      spline: {
        marker: {
          radius: 0,
          lineColor: "#666666",
          lineWidth: 1,
        },
      },
      series: {
        animation: {
          duration: printAll ? 0 : 2000,
        },
        marker: {
          enabled: false
        }
      }
    },
    tooltip: {
      useHTML: true,
      hideDelay: 500,
      // <span style="font-weight:bold;">${this.point.options && this.point.options.velo ? " : " + this.point.options.velo.toFixed(1) + " mph" : "-"}</span><br>
      formatter: function () {
        setTooltipData(this?.point);
        setBallrFlag(true)
        let tooltip = `<span style="color: ${this.point.color
          }; font-weight:bold;">${this.point.options.pitch_type ? this.point.options.pitch_type : "-"
          }</span><br>
          <span style="font-weight:bold;">Date: ${this.point.options && this.point.options.date
            ? moment(this.point.options.date).format('MM/DD/YYYY')
            : "-"
          }</span><br>
          <span style="font-weight:bold;">Batter Team: ${this.point.options && this.point.options.batter_team
            ? this.point.options.batter_team
            : "-"
          }</span><br>
          <span style="font-weight:bold;">Pitcher: ${this.point.options.pitcher ? this.point.options.pitcher : "-"
          }</span><br>
          <span style="font-weight:bold;">Batter: ${this.point.options.batter ? this.point.options.batter : "-"
          }</span><br>
          <span style="font-weight:bold;">Play Result: ${this.point.options.pitch_call ? this.point.options.pitch_call : "-"
          }</span><br>
          <span style="font-weight:bold;">Velocity: ${this.point.options && this.point.options.velo
            ? this.point.options.velo.toFixed(1)
            : "-"
          }</span><br>
          <span style="font-weight:bold;">Spin Rate: ${this.point.options && this.point.options.spin_rate
            ? Math.round(this.point.options.spin_rate)
            : "-"
          }</span><br>
          <span style="font-weight:bold;">Tilt: ${this.point.options.spin_tilt ? this.point.options.spin_tilt : "-"
          }</span><br>
          <span style="font-weight:bold;">Axis: ${this.point.options && this.point.options.spin_axis
            ? Math.round(this.point.options.spin_axis)
            : "-"
          }</span><br>
          <span style="font-weight:bold;">Efficiency: ${this.point.options && this.point.options.efficiency
            ? Math.round(this.point.options.efficiency)
            : "-"
          }</span><br>
          <span style="font-weight:bold;">Vert Break: ${this.point.options && this.point.options.v_break
            ? this.point.options.v_break.toFixed(1)
            : "-"
          }</span><br>
          <span style="font-weight:bold;">Ind V Break: ${this.point.options && this.point.options.ind_v_break
            ? this.point.options.ind_v_break.toFixed(1)
            : "-"
          }</span><br>
          <span style="font-weight:bold;">H Break: ${this.point.options && this.point.options.h_break
            ? this.point.options.h_break.toFixed(1)
            : "-"
          }</span><br>
          <span style="font-weight:bold;">Rel Height: ${this.point.options && this.point.options.release_height
            ? this.point.options.release_height.toFixed(1)
            : "-"
          }</span><br>
          <span style="font-weight:bold;">Rel Side: ${this.point.options && this.point.options.release_side
            ? this.point.options.release_side.toFixed(1)
            : "-"
          }</span><br>
          <span style="font-weight:bold;">Extension: ${this.point.options && this.point.options.ext
            ? this.point.options.ext.toFixed(1)
            : "-"
          }</span><br>
          <span style="font-weight:bold;">Exit Velocity: ${this.point.options && this.point.options.exit_velocity
            ? this.point.options.exit_velocity.toFixed(1)
            : "-"
          }</span><br>
          <span style="font-weight:bold;">Launch Angle: ${this.point.options && this.point.options.launch_angle
            ? this.point.options.launch_angle.toFixed(1)
            : "-"
          }</span><br>
          <span style="font-weight:bold;">Distance: ${this.point.options && this.point.options.distance
            ? Math.round(this.point.options.distance)
            : "-"
          }</span>
        </span>`;
        return tooltip;
      },
      style: {
        pointerEvents: 'auto'
      }
    },
    series: [],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
        },
      ],
    },
  });

  useEffect(() => {
    if (chartData) {
      // increment();
      if (spline_chart) {
        state.yAxis.title = {
          text: constant.yaxisSplineChart[spline_chart],
          style: {
            color: "#777F8F",
            fontFamily: "Poppins, sans-serif",
            fontSize: "14px",
            fontWeight: "500",
          },
        }
      }
      if (chartData.length !== 0) {
        const chartPoint = filterDotChartWithFilter(chartData, spline_chart);
        state.series = chartPoint.filter(e => {return e});
        Highcharts.chart(id, state);
        // decrement();
      } else {
        state.series = [{showInLegend: false}];
        Highcharts.chart(id, state);
        // decrement();
      }
    }
  }, [chartData, spline_chart]);

  const chartImage = (id) => {
    isDownloadImgLoader();
    setClickFlag(true)
    if (document.getElementById(`img${id}`)) {
      document.getElementById(`img${id}`).remove()
    }
    setIsChartImg(true)
    downloadChartPrintImg(id, setIsChartImg, setClickFlag, isNotDownloadImgLoader)
  }

  return (
    <>
      <div className={`ground-chart ${ballrFlag ? "chart-ifream" : ""}`}>
        <div className="select-box select-box-rolling">
          <select name="spline_chart" className="custom-select" value={spline_chart} onChange={metricHandleChange} style={{ maxWidth: "250px" }}>
            <option value="velocity">Velocity</option>
            <option value="spin_rate">Spin Rate</option>
            <option value="vertical_break">V Break</option>
            <option value="induced_vertical_break">Ind V Break</option>
            <option value="horizontal_break">H Break</option>
            <option value="efficiency">Efficiency</option>
            <option value="vert_appr_angle">VAA</option>
            <option value="release_height">Rel Height</option>
            <option value="release_side">Rel Side</option>
            <option value="extension">Extension</option>
          </select>
        </div>
        <div className="ifream-box ifream-rolling">
          {ballrFlag && <BallrWebGL tooltipData={tooltipData} id={id} index={tooltipData?._id} />}
        </div>
        <div style={{ height: "500px" }} id={id} />
        {/* <button className="btn blue btn-view" onClick={() => chartImage(id)} disabled={clickFlag} >Download</button> */}
        <div class="chartdownload" onClick={() => chartImage(id)} disabled={clickFlag}><a class="btn white"><i class="fa fa-download" aria-hidden="true"></i></a></div>
      </div>

      {/* chart visual image download */}
      {isChartImg && <PrintImagePopup
        eventProfileData={eventProfileData}
        id={id}
        eventName={eventName}
        pitcherDefault={pitcherDefault}
        profilepage={profilepage} />}
    </>
  );
}

export default PitchersMainChart;
