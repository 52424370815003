import React, { useEffect, useState } from "react";
import { constant } from "../../shared/constant";
import PrintImagePopup from "../../shared/PrintImagePopup";
import { downloadChartPrintImg } from "../../shared/printReportUtils";
import ContourGraph from "../../view/profiledashboard/commonChartSettings/ContourGraph";

function ContourGraphChart({ chartData, id, eventProfileData, sendReq, tabslist, profilepage, isNotDownloadImgLoader, isDownloadImgLoader, profile }) {
  const [isChartImg, setIsChartImg] = useState(false);
  const [clickFlag, setClickFlag] = useState(false);
  const [breckM, setBreckM] = useState({
    chartHeight: 444,
    width: "100%",
  })

  useEffect(() => {
    if (chartData) {

      // start remove child of svg chart if it's availavble
      var div = document.getElementById(id);
      var elements = div.getElementsByTagName('svg');
      while (elements[0])
        elements[0].parentNode.removeChild(elements[0])
      // end

      const chartWidth =
        typeof breckM.width === 'string'
          ? (document.getElementById(id).offsetWidth > 64) ? document.getElementById(id).offsetWidth : 600
          : breckM.width;

      const xAxisDomain = [-2.5, 2.5];
      const xAxisTicksRequired = 5;
      const yAxisTicksRequired = 5;
      const yAxisDomain = [0, 5];
      const colorRange = ['#93b3ed', 'white', 'red'];
      const boxDimentions = constant.softball
        ? [
          [-0.9, 1.25],
          [-0.9, 3],
          [0.9, 3],
          [0.9, 1.25],
          [-0.9, 1.25],
        ]
        : [
          [-0.9, 1.5],
          [-0.9, 3.5],
          [0.9, 3.5],
          [0.9, 1.5],
          [-0.9, 1.5],
        ];

      if (chartData.length !== 0) {
        const squareBox = boxDimentions;
        const pentagonBox = profile === "profile" ?
          [
            [-0.9, 0.4],
            [0.9, 0.4],
            [0.9, 0.2],
            [0, 0],
            [-0.9, 0.2],
            [-0.9, 0.4]
          ] : [
            [-0.9, 0.05],
            [-0.9, 0.4],
            [0, 0.8],
            [0.9, 0.4],
            [0.9, 0.05],
            [-0.9, 0.05],
          ]

        const contourGrapghDataXformer = chartData.map(obj => ({
          y: obj.height_at_plate,
          x: obj.side_at_plate,
        }));
        ContourGraph(
          `#${id}`,
          contourGrapghDataXformer,
          chartWidth,
          breckM.chartHeight,
          xAxisDomain,
          yAxisDomain,
          squareBox,
          pentagonBox,
          colorRange,
          xAxisTicksRequired,
          yAxisTicksRequired,
        );
      } else {
        ContourGraph(
          `#${id}`,
          [],
          chartWidth,
          breckM.chartHeight,
          xAxisDomain,
          yAxisDomain,
          [],
          colorRange,
          xAxisTicksRequired,
          yAxisTicksRequired,
        );
      }
    }
  }, [chartData]);

  const chartImage = (id) => {
    isDownloadImgLoader();
    setClickFlag(true)
    if (document.getElementById(`img${id}`)) {
      document.getElementById(`img${id}`).remove()
    }
    setIsChartImg(true)
    downloadChartPrintImg(id, setIsChartImg, setClickFlag, isNotDownloadImgLoader)
  }

  return (
    <>
      <div className="ground-chart">
        <div className="legends graph-select text-left">
          <div className="text-left d-inline">
            <h3 className="mb-0">Called Strikes</h3>
          </div>
        </div>
        <div style={{ height: "444px" }} id={id} />
        <div class="chartdownload" onClick={() => chartImage(id)} disabled={clickFlag}><a class="btn white"><i class="fa fa-download" aria-hidden="true"></i></a></div>
      </div>

      {/* chart visual image download popup */}
      {isChartImg && <PrintImagePopup
        eventProfileData={eventProfileData}
        title="Called Strikes"
        id={id}
        sendReq={sendReq}
        tabslist={tabslist}
        profilepage={profilepage} />}
    </>
  );
}

ContourGraphChart.propTypes = {};

export default ContourGraphChart;
