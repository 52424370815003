import React, { useEffect, useState } from "react";
import Highcharts, { chart } from "highcharts";
import { strikeZoneChartWithRedBox, filterByAxis } from "../../../shared/function";
import { imagesArr } from "../../../assets/images";
import BallrWebGL from "../../webGL/BallrWebGL";
import Toggle from "../../../shared/Toggle";
import PitchRWebGL from "../../webGL/PitchRWebGL";
import domtoimage from 'dom-to-image';
import PrintImagePopup from "../../../shared/PrintImagePopup";
import { downloadChartPrintImg } from "../../../shared/printReportUtils";
import use3DView from "../../../shared/use3DView";

function StrikeZoneChart({ chartData, setScatterAxis, onResetScatterFilter, oldData, cameraAngle, visualType, eventProfileData, sendReq, tabslist, profilepage, isNotDownloadImgLoader, isDownloadImgLoader, closePitchrIframe, pitchrIframeFlag }) {
  const [data3D, setData3D, toggle, setToggle, onClickToggleButton, flag] = use3DView("strikeZoneChart");
  const [isChartImg, setIsChartImg] = useState(false);
  const [clickFlag, setClickFlag] = useState(false);
  const [selectionMode, setSelectionMode] = useState(false)
  const [ballrFlag, setBallrFlag] = useState(false);
  const [tooltipData, setTooltipData] = useState(null);
  const [state, setState] = useState({
    chart: {
      type: "scatter",
      marginTop: 100,
      events: {
        selection: e => {
          e.preventDefault();
          setSelectionMode(true);
          setScatterAxis(e);
        },
      },
      zoomType: 'xy'
    },
    title: {
      text: "",
    },
    subtitle: {
      text: "",
    },
    xAxis: {
      // tickInterval: 1,
      title: {
        enabled: true,
        // text: "Side at Plate (ft)",
        text: "",
        style: {
          color: "#777F8F",
          fontFamily: "Poppins, sans-serif",
          fontSize: "14px",
          fontWeight: "500",
          textOverflow: 'none'
        },
      },
      min: -2.5,
      max: 2.5,
      tickInterval: 1
    },
    yAxis: {
      title: {
        enabled: true,
        // text: "Height at Plate (ft)",
        text: "",
        style: {
          color: "#777F8F",
          fontFamily: "Poppins, sans-serif",
          fontSize: "14px",
          fontWeight: "500",
        },
      },
      // allowDecimals: false,
      min: 0,
      max: 5,
    },
    legend: {
      layout: "horizontal",
      align: "center",
      verticalAlign: "top",
      floating: true,
      // y: 4,
    },
    credits: { enabled: false },
    exporting: { enabled: false },
    plotOptions: {
      series: {
        stickyTracking: false,
        states: {
          inactive: {
            opacity: 1,
          },
        }
      },
    },
    tooltip: {
      useHTML: true,
      hideDelay: 500,
      formatter: function () {
        setTooltipData(this?.point);
        // ballrFlag && refview.current.GiveKeyToiframe(this?.point);
        setBallrFlag(true)
        if (this.point.series.name === "Strikezone") return false;
        if (this.point.series.name === "Stadium") return false;
        let tooltip = `<span style="color: ${this.point.color
          }; font-weight:bold;">${this.point.options.pitch_type}</span><br>
        <span style="font-weight:bold;">Pitch No: ${this.point.options.pitch_number
            ? this.point.options.pitch_number
            : "-"
          }</span><br>
        <span style="font-weight:bold;">Pitcher: ${this.point.options.pitcher ? this.point.options.pitcher : "-"
          }</span><br>
        <span style="font-weight:bold;">Batter: ${this.point.options.batter ? this.point.options.batter : "-"
          }</span><br>
        <span style="font-weight:bold;">Play Result: ${this.point.options.pitch_call ? this.point.options.pitch_call : "-"
          }</span><br>
        <span style="font-weight:bold;">Velocity: ${this.point.options && this.point.options.velocity
            ? this.point.options.velocity.toFixed(1)
            : "-"
          }</span><br>
        <span style="font-weight:bold;">Spin Rate: ${this.point.options && this.point.options.spin_rate
            ? Math.round(this.point.options.spin_rate)
            : "-"
          }</span><br>
        <span style="font-weight:bold;">Tilt: ${this.point.options && this.point.options.tilt
            ? this.point.options.tilt
            : "-"
          }</span><br>
        <span style="font-weight:bold;">Axis: ${this.point.options && this.point.options.spin_axis
            ? Math.round(this.point.options.spin_axis)
            : "-"
          }</span><br>
        <span style="font-weight:bold;">Efficiency: ${this.point.options && this.point.options.efficiency
            ? Math.round(this.point.options.efficiency)
            : "-"
          }</span><br>
        <span style="font-weight:bold;">Vert Break: ${this.point.options && this.point.options.v_break
            ? this.point.options.v_break.toFixed(1)
            : "-"
          }</span><br>
        <span style="font-weight:bold;">Ind V Break: ${this.point.options && this.point.options.ind_v_break
            ? this.point.options.ind_v_break.toFixed(1)
            : "-"
          }</span><br>
        <span style="font-weight:bold;">H Break: ${this.point.options && this.point.options.h_break
            ? this.point.options.h_break.toFixed(1)
            : "-"
          }</span><br>
          <span style="font-weight:bold;">V Approach: ${this.point.options && this.point.options.vert_appr_angle
            ? this.point.options.vert_appr_angle.toFixed(1)
            : "-"
          }</span><br>
        <span style="font-weight:bold;">Rel Height: ${this.point.options && this.point.options.release_height
            ? this.point.options.release_height.toFixed(1)
            : "-"
          }</span><br>
        <span style="font-weight:bold;">Rel Side: ${this.point.options && this.point.options.release_side
            ? this.point.options.release_side.toFixed(1)
            : "-"
          }</span><br>
        <span style="font-weight:bold;">Extension: ${this.point.options && this.point.options.extension
            ? this.point.options.extension.toFixed(1)
            : "-"
          }</span><br>
        <span style="font-weight:bold;">Exit Velocity: ${this.point.options && this.point.options.exit_velocity
            ? this.point.options.exit_velocity.toFixed(1)
            : "-"
          }</span><br>
        <span style="font-weight:bold;">Launch Angle: ${this.point.options && this.point.options.launch_angle
            ? this.point.options.launch_angle.toFixed(1)
            : "-"
          }</span><br>
        <span style="font-weight:bold;">Distance: ${this.point.options && this.point.options.distance
            ? Math.round(this.point.options.distance)
            : "-"
          }</span>
        <!-- <div style="color: red" onmouseover="StrikeLoadBallR()">BallR</div> -->
        </span>`;
        return tooltip;
      },
      style: {
        pointerEvents: 'auto'
      }
    },
    series: [],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
        },
      ],
    },
  });

  useEffect(() => {
    if (toggle.chartToggle === true) {
      if (chartData) {
        if (chartData.length !== 0) {
          const seriesData = strikeZoneChartWithRedBox(filterByAxis(chartData, state.yAxis, state.xAxis), selectionMode, oldData) ? strikeZoneChartWithRedBox(filterByAxis(chartData, state.yAxis, state.xAxis), selectionMode, oldData) : strikeZoneChartWithRedBox(chartData);
          setSelectionMode(false)
          state.series = seriesData;
          // const name = state.series.map((obj) => obj.name);
          // if (selectionMode && name.length >= 10) {
          //   // state.yAxis = {...state.yAxis, min: 0, max: 6}
          //   // state.chart = {...state.chart, marginTop: 170, height: 435}
          // } else {
          //   state.yAxis = {...state.yAxis, min: 0, max: 5}
          //   state.chart = {...state.chart, marginTop: 100}
          // }
          Highcharts.chart("strikeZoneChart", state);
        } else {
          state.series = [{ showInLegend: false }];
          Highcharts.chart("strikeZoneChart", state);
        }
      }
    } else {
      if (chartData && chartData.length !== 0) {
        let arr = chartData.map(x => x?._id) || []
        setData3D(arr)
      }
    }
  }, [chartData, toggle]);

  useEffect(() => {
    if (pitchrIframeFlag === "yes") {
      setData3D(null)
      setToggle({ chartToggle: true });
      closePitchrIframe();
    }
  }, [pitchrIframeFlag])

  const chartImage = (id) => {
    isDownloadImgLoader();
    setClickFlag(true)
    if (document.getElementById(`img${id}`)) {
      document.getElementById(`img${id}`).remove()
    }
    setIsChartImg(true)
    downloadChartPrintImg(id, setIsChartImg, setClickFlag, isNotDownloadImgLoader)
  }

  return (
    <>
      <div className={`ground-chart ${ballrFlag ? "chart-ifream" : ""}`}>
        {chartData && chartData.length !== 0 ?
          <Toggle
            right={window.innerWidth < 575 ? "5px" : toggle.chartToggle === true ? "40px" : "17px"}
            top={window.innerWidth < 575 ? toggle.chartToggle === true ? "27px" : "10px" : "10px"}
            toggle={toggle}
            onClickToggleButton={onClickToggleButton} />
          : ""}
        <div style={{ display: toggle.chartToggle === true ? 'block' : 'none' }}>
          <div className="legends graph-select text-left">
            <div className="text-center w-100">
              {/* <h3 className="mb-0">Strike Zone</h3> */}
              <p>Click and drag box to select pitches</p>
            </div>
            <div className="refresh" onClick={() => onResetScatterFilter()}>
              <img
                src={imagesArr.refresh}
                alt="refresh"
              />
            </div>
          </div>
          <div className="ifream-box">
            {ballrFlag && <BallrWebGL tooltipData={tooltipData} id={"strikeZoneballr"} index={tooltipData?._id} />}
          </div>
          <div style={{ height: "500px", width: "400px", margin: "0 auto" }} id="strikeZoneChart" />
          {/* <button className="btn blue btn-view" onClick={() => chartImage("strikeZoneChart")} disabled={clickFlag} >Download</button> */}
          <div class="chartdownload" onClick={() => chartImage("strikeZoneChart")} disabled={clickFlag}><a class="btn white"><i class="fa fa-download" aria-hidden="true"></i></a></div>
        </div>
        {flag?.["iframe_strikeZoneChart"] === true && <div id="pitchR-iframe" className="Chart-iframe" style={{ display: toggle.chartToggle === false ? 'block' : 'none' }}>
          <PitchRWebGL data={data3D} id="strikeZoneChart" cameraAngle={cameraAngle} visualType={visualType} Toggle={toggle.chartToggle} />
        </div>}
      </div>

      {/* chart visual image download */}
      {isChartImg && <PrintImagePopup
        eventProfileData={eventProfileData}
        id="strikeZoneChart"
        sendReq={sendReq}
        tabslist={tabslist}
        profilepage={profilepage} />}
    </>
  );
}

export default StrikeZoneChart;
