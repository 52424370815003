import React, { Component } from 'react';
import { createColoredColumnObjTable } from "../../../shared/function";

function Hittersummary({ hardestTable }) {
        return (
            <>
                <div className="w-50">
                    <div className="hittersummary-table">
                        <div className="table-responsive-xl">
                            <table className="table tabledashed">
                                <thead>
                                    <tr>
                                        <th colSpan="4" className="one-child text-center">Hardest</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="tablerowgray fw-500">Batter Name</td>
                                        <td className="tablerowgray fw-500">EV</td>
                                        <td className="tablerowgray fw-500">LA</td>
                                        <td className="tablerowgray fw-500">Distance</td>
                                    </tr>
                                    
                                    {hardestTable.length !== 0 ? (
                                        hardestTable.map((data, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td className="table-rowwhite">{data.batter ? data.batter :"-" }</td>
                                                    <td className="table-redlight" style={createColoredColumnObjTable('exit_velocity', hardestTable, data)}>{data.exit_velocity && data.exit_velocity ? Number(data.exit_velocity.toFixed(1)) : "-"}</td>
                                                    <td className="table-rowwhite">{data.launch_angle && data.launch_angle ? Number(data.launch_angle.toFixed(1)) : "-"}</td>
                                                    <td className="table-rowwhite">{data.distance && data.distance ? Math.round(Number(data.distance)) : "-"}</td>
                                                </tr>
                                            )
                                        })
                                    ) : (
                                            <tr>
                                                <td className="table-rowwhite text-center" colSpan="4">
                                                    There's no info yet!
                                                </td>
                                            </tr>
                                    )}
                                    {/* <tr>
                                        <td className="table-rowwhite">Voller, Erik</td>
                                        <td className="table-redlight">104.5</td>
                                        <td className="table-rowwhite">104.5</td>
                                        <td className="table-rowwhite">104.5</td>
                                    </tr>
                                    <tr>
                                        <td className="table-rowwhite">Gonzales, Johnny</td>
                                        <td className="bg-red">102.8</td>
                                        <td className="table-rowwhite">102.8</td>
                                        <td className="table-rowwhite">102.8</td>
                                    </tr>
                                    <tr>
                                        <td className="table-rowwhite">Jones, Blayne</td>
                                        <td className="bg-red">101.6</td>
                                        <td className="table-rowwhite">101.6</td>
                                        <td className="table-rowwhite">101.6</td>
                                    </tr>
                                    <tr>
                                        <td className="table-rowwhite">Glenn, Jackson</td>
                                        <td className="table-redlight">102.8</td>
                                        <td className="table-rowwhite">102.8</td>
                                        <td className="table-rowwhite">102.8</td>
                                    </tr>
                                    <tr>
                                        <td className="table-rowwhite">Vansau, Hunter</td>
                                        <td className="table-redlight">101.6</td>
                                        <td className="table-rowwhite">101.6</td>
                                        <td className="table-rowwhite">101.6</td>
                                    </tr> */}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                                                
                                   
            </>
        );
}

export default Hittersummary;