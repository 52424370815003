import React, { Component } from 'react';
import { imagesArr } from "../../../../assets/images";
import { constant } from '../../../../shared/constant';

class pitchingMetrics extends Component {
    render() {

        const { pMetrics_cols, pMetrics_data } = this.props;

        return (
            <>
                <div className="w-50">
                    <div className="gamesummary-table m-0">
                        <div className="table-responsive-xl">
                            <table className="table tabledashed">
                                <thead>
                                    <tr>
                                        <th colSpan="3" className="one-child text-center">
                                            Pitching Metrics
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        {
                                            pMetrics_cols &&
                                            pMetrics_cols.map((th, i) => {
                                                return (
                                                    <td className="tablerowgray fw-500" key={i}>
                                                        {th}
                                                    </td>
                                                )
                                            })
                                        }
                                    </tr>
                                    {
                                        pMetrics_data &&
                                        pMetrics_data.map((td, i) => {
                                            return (
                                                <tr key={i}>
                                                    {pMetrics_cols &&
                                                        pMetrics_cols.map((th, j) => {
                                                            if (constant.softball) {
                                                                if (td[pMetrics_cols[1]] !== "Avg FB Velo") {
                                                                    return <td className="table-rowwhite">
                                                                    {
                                                                        j === 0 &&
                                                                        (td[pMetrics_cols[0]] > td[pMetrics_cols[2]]) &&
                                                                        <img src={imagesArr.green_up} alt="green_up" />
                                                                    }
                                                                    {
                                                                        j === 0 &&
                                                                        (td[pMetrics_cols[0]] < td[pMetrics_cols[2]]) &&
                                                                        <img src={imagesArr.red_down} alt="red_down" />
                                                                    }
                                                                    {
                                                                        j === 2 &&
                                                                        (td[pMetrics_cols[2]] > td[pMetrics_cols[0]]) &&
                                                                        <img src={imagesArr.green_up} alt="green_up" />
                                                                    }
                                                                    {
                                                                        j === 2 &&
                                                                        (td[pMetrics_cols[2]] < td[pMetrics_cols[0]]) &&
                                                                        <img src={imagesArr.red_down} alt="red_down" />
                                                                    }
                                                                    {td[th]}
                                                                </td>;
                                                                }
                                                            } else {
                                                                return <td className="table-rowwhite">
                                                                    {
                                                                        j === 0 &&
                                                                        (td[pMetrics_cols[0]] > td[pMetrics_cols[2]]) &&
                                                                        <img src={imagesArr.green_up} alt="green_up" />
                                                                    }
                                                                    {
                                                                        j === 0 &&
                                                                        (td[pMetrics_cols[0]] < td[pMetrics_cols[2]]) &&
                                                                        <img src={imagesArr.red_down} alt="red_down" />
                                                                    }
                                                                    {
                                                                        j === 2 &&
                                                                        (td[pMetrics_cols[2]] > td[pMetrics_cols[0]]) &&
                                                                        <img src={imagesArr.green_up} alt="green_up" />
                                                                    }
                                                                    {
                                                                        j === 2 &&
                                                                        (td[pMetrics_cols[2]] < td[pMetrics_cols[0]]) &&
                                                                        <img src={imagesArr.red_down} alt="red_down" />
                                                                    }
                                                                    {td[th]}
                                                                </td>;
                                                            }
                                                        })}
                                                </tr>
                                            );
                                        })
                                    }

                                    {
                                        pMetrics_data.length === 0 &&
                                        <tr>
                                            <td className="table-rowwhite text-center" colSpan="3">
                                                There's no info yet!
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default pitchingMetrics;
