import React, { useEffect, useState } from "react";
import { constant } from "../../../../shared/constant";
import PrintImagePopup from "../../../../shared/PrintImagePopup";
import { downloadChartPrintImg } from "../../../../shared/printReportUtils";
import Toggle from "../../../../shared/Toggle";
import PitchRWebGL from "../../../webGL/PitchRWebGL";
import ContourGraph from '../../commonChartSettings/ContourGraph';
import domtoimage from 'dom-to-image';
import use3DView from "../../../../shared/use3DView";

function ContourGraphChart({ chartData, contour_graph, onChangeEvent, cameraAngle, visualType, eventProfileData, sendReq, tabslist, profilepage, isNotDownloadImgLoader, isDownloadImgLoader, closePitchrIframe, pitchrIframeFlag }) {
  const [data3D, setData3D, toggle, setToggle, onClickToggleButton, flag] = use3DView("contourGraphChart");
  const [isChartImg, setIsChartImg] = useState(false);
  const [clickFlag, setClickFlag] = useState(false);
  const [breckM, setBreckM] = useState({
    chartHeight: 417,
    width: "100%",
  })

  useEffect(() => {
    if (toggle.chartToggle === true) {
      if (chartData) {
        // start remove child of svg chart if it's availavble
        var div = document.getElementById('contourGraphChart');
        var elements = div.getElementsByTagName('svg');
        while (elements[0])
          elements[0].parentNode.removeChild(elements[0])
        // end
        const chartWidth =
          typeof breckM.width === 'string'
            ? document.getElementById("contourGraphChart").offsetWidth
            : breckM.width;
        const xAxisDomain = [-2.5, 2.5];
        const xAxisTicksRequired = 5;
        const yAxisTicksRequired = 5;
        const yAxisDomain = [0, 5];
        const colorRange = ['#93b3ed', 'white', 'red'];
        const boxDimentions = constant.softball
          ? [
            [-0.9, 1.25],
            [-0.9, 3],
            [0.9, 3],
            [0.9, 1.25],
            [-0.9, 1.25],
          ]
          : [
            [-0.9, 1.5],
            [-0.9, 3.5],
            [0.9, 3.5],
            [0.9, 1.5],
            [-0.9, 1.5],
          ];

        if (chartData.length !== 0) {
          const squareBox = boxDimentions;
          const pentagonBox = [
            [-0.9, 0.05],
            [-0.9, 0.4],
            [0, 0.8],
            [0.9, 0.4],
            [0.9, 0.05],
            [-0.9, 0.05],
          ]

          const contourGrapghDataXformer = chartData.map(obj => ({
            y: obj.height_at_plate,
            x: obj.side_at_plate,
          }));
          ContourGraph(
            '#contourGraphChart',
            contourGrapghDataXformer,
            chartWidth,
            breckM.chartHeight,
            xAxisDomain,
            yAxisDomain,
            squareBox,
            pentagonBox,
            colorRange,
            xAxisTicksRequired,
            yAxisTicksRequired,
          );
        } else {
          ContourGraph(
            '#contourGraphChart',
            [],
            chartWidth,
            breckM.chartHeight,
            xAxisDomain,
            yAxisDomain,
            [],
            colorRange,
            xAxisTicksRequired,
            yAxisTicksRequired,
          );
        }
      }
    } else {
      if (chartData && chartData.length !== 0) {
        let arr = chartData.map(x => x?._id) || []
        setData3D(arr)
      }
    }
  }, [chartData, toggle]);

  useEffect(() => {
    if (pitchrIframeFlag === "yes") {
      setData3D(null)
      setToggle({ chartToggle: true });
      closePitchrIframe();
    }
  }, [pitchrIframeFlag])

  const chartImage = (id) => {
    isDownloadImgLoader();
    setClickFlag(true)
    if (document.getElementById(`img${id}`)) {
      document.getElementById(`img${id}`).remove()
    }
    setIsChartImg(true)
    downloadChartPrintImg(id, setIsChartImg, setClickFlag, isNotDownloadImgLoader)
  }

  return (
    <>
      <div className="ground-chart">
        {chartData && chartData.length !== 0 ? <Toggle right={window.innerWidth < 575 ? "17px" : toggle.chartToggle === true ? "160px" : "17px"} toggle={toggle} onClickToggleButton={onClickToggleButton} /> : ""}
        <div style={{ display: toggle.chartToggle === true ? 'block' : 'none' }}>
          <div className="legends graph-select text-left">
            <div className="text-left d-inline">
              <h3 className="mb-0">Contour Graph</h3>
              <p>Pitch View</p>
            </div>
            <div className="select-box ">
              <select name="contour_graph" value={contour_graph} onChange={onChangeEvent} className="custom-select">
                <option defaultValue value="All Pitches" >All Pitches</option>
                <option value="Swings">Swings</option>
                <option value="Swing and Miss">Swing and Miss</option>
                <option value="In Play">In Play</option>
              </select>
            </div>
          </div>
          <div id="contourGraphChart" />
          {/* <button className="btn blue btn-view" onClick={() => chartImage("contourGraphChart")} disabled={clickFlag} >Download</button> */}
          <div class="chartdownload" onClick={() => chartImage("contourGraphChart")} disabled={clickFlag}><a class="btn white"><i class="fa fa-download" aria-hidden="true"></i></a></div>
        </div>
        {flag?.["iframe_contourGraphChart"] === true && <div id="pitchR-iframe" className="Chart-iframe" style={{ display: toggle.chartToggle === false ? 'block' : 'none' }}>
          <PitchRWebGL data={data3D} id="contourGraphChart" cameraAngle={cameraAngle} visualType={visualType} Toggle={toggle.chartToggle} />
        </div>}
      </div>

      {/* chart visual image download popup */}
      {isChartImg && <PrintImagePopup
        title="Contour Graph"
        eventProfileData={eventProfileData}
        id="contourGraphChart"
        sendReq={sendReq}
        tabslist={tabslist}
        profilepage={profilepage} />}
    </>
  );
}

ContourGraphChart.propTypes = {};

export default ContourGraphChart;
