import React, { Component } from "react";
import Header from "../../containers/header";
import Footer from "../../containers/footer";
import { imagesArr } from "../../assets/images";
import Pitcherssummarysidebar from "../../containers/pitcherssummarysidebar";
import Teamsummary from "../teamsummary/teamsummary";
import Development from "../development/development";
import { getPitcherSummaryData } from "../../store/actions";
import { connect } from "react-redux";
import Spierscarson from "./component/spierscarson";
import { printToPDFMultiple } from "../../shared/printReportUtils";
import { groupArrayOfObjects } from "../../shared/function";
import PitcherSummaryTable from "./component/pitcherSummary";
import Loader from "../../shared/loader";
let groupedPitcher,
  groupedScatter,
  groupedRelease,
  groupedBreak,
  groupedBreakLong,
  groupedDot,
  groupedDotLong,
  groupedPolar,
  groupedSide,
  groupedBullpen1,
  groupedBullpen2;
class pitcherssummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: "",
      pitcherList: {},
      scatterList: {},
      releaseList: {},
      breakList: {},
      breakListLong: {},
      dotchart: {},
      dotchartLong: {},
      polarList: {},
      sideReleaseList: {},
      firstBullpenList: {},
      secondBullpenList: {},
      isPitcherSummary: true,
      keyName: null,
      isPrint: false,
      printAll: false,
      backUP: null,
      ballrIframeFlag: "",
      pitchrIframeFlag: "",
      isDownloadImg: false
    };
  }

  componentDidMount() {
    let sorceid = document.location.href.split("/")[4];
    if (sorceid === undefined) window.location.href = "/";
    else this.summaryData();
  }

  // fetch summary data
  summaryData = () => {
    let data = {
      sourceId: document.location.href.split("/")[4],
      tab: "pitcher_summary",
    };
    this.props.fetchSummaryData(data, (res) => {
      groupedPitcher = groupArrayOfObjects(
        res.data.result.pitcher_summary_table_data,
        "pitcher_name"
      );
      groupedScatter = groupArrayOfObjects(
        res.data.result.filterable_scatter_plot_data,
        "pitcher"
      );
      groupedRelease = groupArrayOfObjects(
        res.data.result.scatter_plot_data,
        "pitcher"
      );
      groupedBreak = groupArrayOfObjects(
        res.data.result.break_movement,
        "pitcher"
      );
      groupedBreakLong = groupArrayOfObjects(
        res.data.result.break_movement_long,
        "pitcher"
      );
      groupedDot = groupArrayOfObjects(
        res.data.result.dot_chart_data,
        "pitcher"
      );
      groupedDotLong = groupArrayOfObjects(
        res.data.result.dot_chart_data_long,
        "pitcher"
      );
      groupedPolar = groupArrayOfObjects(
        res.data.result.polar_chart_data,
        "pitcher"
      );
      groupedSide = groupArrayOfObjects(
        res.data.result.break_movement_side_release,
        "pitcher"
      );
      groupedBullpen1 = groupArrayOfObjects(
        res.data.result.first_bullpen_table_data,
        "pitcher_name"
      );
      groupedBullpen2 = groupArrayOfObjects(
        res.data.result.second_bullpen_table_data,
        "pitcher_name"
      );
      this.setState({
        pitcherList: groupedPitcher,
        scatterList: groupedScatter,
        releaseList: groupedRelease,
        breakList: groupedBreak,
        breakListLong: groupedBreakLong,
        dotchart: groupedDot,
        dotchartLong: groupedDotLong,
        polarList: groupedPolar,
        sideReleaseList: groupedSide,
        firstBullpenList: groupedBullpen1,
        secondBullpenList: groupedBullpen2,
        keyName: "pitcherssummary",
        backUP: res.data.result,
        eventName: res.data?.eventDetails?.event?.event_name
      });
    });
  };

  handleCallback = (index) => {
    this.setState({
      tabs: index,
    });
  };

  handleSidbar = (keyName, isPitcher) => {
    this.setState((prevState) => ({
      ...prevState,
      keyName: keyName,
      isPitcherSummary: isPitcher,
      ballrIframeFlag: "yes",
      pitchrIframeFlag: "yes"
    }));
  };

  print = async () => {
    this.setState({ isPrint: true });
    setTimeout(async () => {
      await printToPDFMultiple(`bullPensPlayer`, ["pitcherTablePrintArea"]);
      this.setState({ isPrint: false });
    }, 2000);
  };
  printChart = async () => {
    this.setState({
      isPrint: true,
      scatterList: Object.assign(
        [],
        groupArrayOfObjects(
          this.state.backUP.filterable_scatter_plot_data,
          "pitcher"
        )
      ),
      releaseList: Object.assign(
        [],
        groupArrayOfObjects(this.state.backUP.scatter_plot_data, "pitcher")
      ),
      breakList: Object.assign(
        [],
        groupArrayOfObjects(this.state.backUP.break_movement, "pitcher")
      ),
    });
    setTimeout(async () => {
      await printToPDFMultiple(`bullPensPlayer`, ["bullpenSummaryChart"]);
      this.setState({
        isPrint: false,
        scatterList: Object.assign([], groupedScatter),
        releaseList: Object.assign([], groupedRelease),
        breakList: Object.assign([], groupedBreak),
      });
    }, 2000);
  };

  printAll = () => {
    this.setState({ printAll: true });
    setTimeout(async () => {
      await printToPDFMultiple(`bullPensEvent`, [
        "pitcherTablePrintArea",
        ...Object.keys(this.state.pitcherList).map(
          (data, index) => `bullpen-Chart${index}`
        ),
      ]);
      this.setState({ printAll: false });
    }, 2000);
  };

  setLoader = (flg) => {
    this.props.startLoader(flg);
  };

  getActive = () => {
    if (this.state.printAll) {
      return (
        <>
          {this.state.printAll && <Loader />}
          <PitcherSummaryTable
            print={() => this.print()}
            printAll={() => this.printAll()}
            isprintAll={this.state.printAll}
            pitcherList={this.state.pitcherList}
          />
          {Object.keys(this.state.pitcherList).length !== 0 &&
            Object.keys(this.state.pitcherList).map((keyName, index) => {
              return (
                <>
                  <div id={`bullpen-Chart${index}`}>
                    <section className="pb-0">
                      <div className="container-fluid">
                        <div className="common-title">
                          <h2>{keyName}</h2>
                          {!this.state.printAll && (
                            <div className="dropdown">
                              <span className="printer">
                                <img src={imagesArr.printer} alt="print" />
                              </span>
                              <div className="dropdown-content">
                                <ul>
                                  <li onClick={() => this.printChart()}>
                                    Print
                                  </li>
                                  <li onClick={() => this.printAll()}>
                                    Print All
                                  </li>
                                </ul>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <Spierscarson
                        keyName={keyName}
                        pitcherList={this.state.pitcherList}
                        scatterList={this.state.scatterList}
                        firstBullpenList={this.state.firstBullpenList}
                        secondBullpenList={this.state.secondBullpenList}
                        releaseList={this.state.releaseList}
                        breakList={this.state.breakList}
                        breakListLong={this.state.breakListLong}
                        dotchart={this.state.dotchart}
                        dotchartLong={this.state.dotchartLong}
                        polarList={this.state.polarList}
                        sideReleaseList={this.state.sideReleaseList}
                        setLoader={this.setLoader}
                        index={index}
                        isprint={this.state.isPrint}
                        isprintAll={this.state.printAll}
                        ballrIframeFlag={this.state.ballrIframeFlag}
                        pitchrIframeFlag={this.state.pitchrIframeFlag}
                      />
                    </section>
                  </div>
                </>
              );
            })}
        </>
      );
    }
    if (this.state.isPitcherSummary) {
      return (
        <>
          {this.state.isPrint && <Loader />}
          <PitcherSummaryTable
            print={() => this.print()}
            printAll={() => this.printAll()}
            pitcherList={this.state.pitcherList}
          />
        </>
      );
    } else {
      return (
        <>
          {this.state.isPrint && <Loader />}
          {this.state.isDownloadImg && <Loader text={true}/>}
          <div id="bullpenSummaryChart">
            <section className="pb-0">
              <div className="container-fluid">
                <div className="common-title">
                  <h2>{this.state.keyName}</h2>
                  {!this.state.isPrint && (
                    <div className="dropdown">
                      <span className="printer">
                        <img src={imagesArr.printer} alt="print" />
                      </span>
                      <div className="dropdown-content">
                        <ul>
                          <li onClick={() => this.printChart()}>Print</li>
                          <li onClick={() => this.printAll()}>Print All</li>
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <Spierscarson
                keyName={this.state.keyName}
                pitcherList={this.state.pitcherList}
                scatterList={this.state.scatterList}
                firstBullpenList={this.state.firstBullpenList}
                secondBullpenList={this.state.secondBullpenList}
                releaseList={this.state.releaseList}
                breakList={this.state.breakList}
                breakListLong={this.state.breakListLong}
                dotchart={this.state.dotchart}
                dotchartLong={this.state.dotchartLong}
                polarList={this.state.polarList}
                sideReleaseList={this.state.sideReleaseList}
                setLoader={this.setLoader}
                isprint={this.state.isPrint}
                isprintAll={this.state.printAll}
                ballrIframeFlag={this.state.ballrIframeFlag}
                closeBallrIframe={() => this.setState((prevState) => ({ ...prevState, ballrIframeFlag: "" }))}
                pitchrIframeFlag={this.state.pitchrIframeFlag}
                closePitchrIframe={() => this.setState((prevState) => ({ ...prevState, pitchrIframeFlag: "" }))}
                isNotDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: false }))}
                isDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: true }))}
                eventName={this.state.eventName}
              />
            </section>
          </div>
        </>
      );
    }
  };

  render() {
    const tabs = this.state.tabs;
    return (
      <>
        <div className="d-flex pitchers-summary">
          {/* {
                        tabs !== 0 && tabs !== 1 && Object.keys(this.state.pitcherList).length !== 0 && <Pitcherssummarysidebar changeside={this.handleSidbar} sideData={this.state.pitcherList} keyName={this.state.keyName} />
                    } */}
          <div
            className={`page-content-wrapper ${
              tabs === 0 || tabs === 1 ? "tabactive" : ""
            }`}
          >
            <Header parentCallback={this.handleCallback} />
            {tabs === 0 && <Teamsummary />}
            {tabs === 1 && <Development />}
            {tabs !== 0 && tabs !== 1 && (
              <>
                <div className="main-div">
                  <div className="sidebar-content">
                    {Object.keys(this.state.pitcherList).length !== 0 && (
                      <Pitcherssummarysidebar
                        changeside={this.handleSidbar}
                        sideData={this.state.pitcherList}
                        keyName={this.state.keyName}
                      />
                    )}
                    <div className="content">{this.getActive()}</div>
                  </div>
                </div>
              </>
            )}
          </div>
          {/* <Footer /> */}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    bullpenSummaryData: state.bullpenSummaryData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchSummaryData: (data, next) => dispatch(getPitcherSummaryData(data, next)),
  startLoader: (data) => dispatch({ type: "LOADING", payload: data }),
});
export default connect(mapStateToProps, mapDispatchToProps)(pitcherssummary);
