import { Component } from "react";
import { connect } from "react-redux";
import { printToPDFMultiple } from "../../shared/printReportUtils";
import Gamesummary from "./gameSummary/index";
import PitchLog from "./pitchersLog/index";
import Homepitchers from "./homePitchers/index";
import Awaypitchers from "./awayPitchers/index";
import Homebatters from "./homeBatters/index";
import Awaybatters from "./awayBatters/index";
import Umpireanalysis from "./umpireAnalysis/index";
import Gamesummarysidebar from "../../containers/gamesummarysidebar";
import Teamsummary from "../teamsummary/teamsummary";
import Development from "../development/development";
import Header from "../../containers/header";
import Loader from "../../shared/loader";
import { getGameSummaryData } from "../../store/actions";


class Game extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "",
      printAll: false,
      tabs: "",
      dataCount: 0,
      homeHitterFilter: [],
      homePitcherFilter: [],
      awayHitterFilter: [],
      awayPitcherFilter: [],
      mount: false,
      isStopPrint : false 
    };
  }

  increment = () => {
    this.setState(prevState => {
      return { dataCount: prevState.dataCount + 1 }
    })
  }

  decrement = () => {
    this.setState(prevState => {
      return { dataCount: prevState.dataCount - 1 }
    }, async () => {
      if (this.state.dataCount === 0 && !this.state.isStopPrint && this.state.printAll) {
        await this.setState({ dataCount: 0, isStopPrint: true })
        setTimeout(() => {
          this.printAllPage()
        }, 3000);
      }
    })
  }

  // reduceDataCount = async () => await this.setState({ dataCount: this.state.dataCount - 1 },async ()=>{
  //   if (this.state.dataCount === 0 && !this.state.isStopPrint) {
  //     await this.setState({ dataCount: 0, isStopPrint : true })
  //     setTimeout(() => {
  //       this.printAllPage()
  //     }, 3000);
  //   }
  // })

  componentDidMount = () => {
    let data = {
      sourceId: document.location.href.split("/")[4],
      tab: "summary",
    }
    this.props.fetchSummaryData(data, (res) => {
      this.setState({
        homeHitterFilter: res.data.result.home_hitters_names,
        homePitcherFilter: res.data.result.home_pitcher_names,
        awayHitterFilter: res.data.result.away_hitters_names,
        awayPitcherFilter: res.data.result.away_pitcher_names,
        eventName: res.data?.eventDetails?.event?.event_name,
        mount: true
      });
    });
  }

  // componentDidUpdate = async () => {
  //   if (this.state.dataCount === 0) {
  //     await this.setState({ dataCount: 7 })
  //     this.printAllPage()
  //   }
  // }

  printAllPage = async () => {
    await printToPDFMultiple('gameType', [
      // 'gameSummaryPrint',
      // 'pitchLogSummaryPrint',
      // "homePitcherSummaryPrint",
      // 'awayPitcherSummaryPrint',
      // 'ChartPrintAreaHitter',
      // 'ChartPrintAreaAwayBatter',
      // 'umpireSummaryPrint',
      'tablePrintArea',
      'homePitchersMainChartPrintArea',
      'releaseStrikeChartPrintArea',
      'GameStrikeChartPrintArea',
      'sequencingChartPrintArea',
      'movementChartPrintArea',
      'homePitcherTable',
      'tablePrintArea',
      'awayPitchersMainChartPrintArea',
      'releaseStrikeChartPrintArea',
      'GameStrikeChartPrintArea',
      'sequencingChartPrintArea',
      'movementChartPrintArea',
      'awayPitchTable',
      'tablePrintAreaHomeBatter',
      // ...JSON.parse(localStorage.getItem("homehitterarr")),
      'tablePrintAreaAwayHitter',
      // ...JSON.parse(localStorage.getItem("awayhitterarr"))
    ]);
    this.setState({ printAll: false })
  }


  printAll = async () => {
    this.setState({ printAll: true, dataCount: 0, isStopPrint: false })
  }

  handleCallback = (index) => {
    this.setState({
      tabs: index,
    });
  };

  getActiveTab = () => {
    var pathArray = window.location.pathname.split('/');
    if (!this.state.activeTab) {
      this.setState({
        activeTab: pathArray[1]
      });
    }
    if(!this.state.mount) return null
    if (this.state.printAll) {
      return (
        <>
          <Loader />
          {/* <div id="gameSummaryPrint">
            <Gamesummary printAll={this.printAll} isPrint={this.state.printAll} reduceDataCount={this.reduceDataCount} />
          </div> */}
          {/* <div id="pitchLogSummaryPrint">
            <PitchLog printAll={this.printAll} isPrint={this.state.printAll} reduceDataCount={this.reduceDataCount} />
          </div> */}
          <div id="homePitcherSummaryPrint">
            <Homepitchers printAll={this.printAll} isPrint={this.state.printAll} increment={this.increment} decrement={this.decrement} isStopPrint={this.state.isStopPrint} homePitcherFilter={this.state.homePitcherFilter} />
          </div>
          <div id="awayPitcherSummaryPrint">
            <Awaypitchers printAll={this.printAll} isPrint={this.state.printAll} increment={this.increment} decrement={this.decrement} isStopPrint={this.state.isStopPrint} awayPitcherFilter={this.state.awayPitcherFilter} />
          </div>
          <Homebatters printAll={this.printAll} isPrint={this.state.printAll} increment={this.increment} decrement={this.decrement} isStopPrint={this.state.isStopPrint} homeHitterFilter={this.state.homeHitterFilter} />
          <Awaybatters printAll={this.printAll} isPrint={this.state.printAll} increment={this.increment} decrement={this.decrement} isStopPrint={this.state.isStopPrint} awayHitterFilter={this.state.awayHitterFilter} />
          {/* <div id="umpireSummaryPrint">
            <Umpireanalysis printAll={this.printAll} isPrint={this.state.printAll} reduceDataCount={this.reduceDataCount} />
          </div> */}
        </>
      );

    }

    switch (this.state.activeTab) {
      case "gamesummary":
        return <Gamesummary printAll={this.printAll} isPrint={this.state.printAll} />;
      case "pitchlog":
        return <PitchLog printAll={this.printAll} isPrint={this.state.printAll} />;
      case "homepitchers":
        return <Homepitchers printAll={this.printAll} increment={this.increment} decrement={this.decrement} isPrint={this.state.printAll} homePitcherFilter={this.state.homePitcherFilter} eventName={this.state.eventName} />;
      case "awaypitchers":
        return <Awaypitchers printAll={this.printAll} increment={this.increment} decrement={this.decrement} isPrint={this.state.printAll} awayPitcherFilter={this.state.awayPitcherFilter} eventName={this.state.eventName} />;
      case "homebatters":
        return <Homebatters printAll={this.printAll} increment={this.increment} decrement={this.decrement} isPrint={this.state.printAll} homeHitterFilter={this.state.homeHitterFilter} eventName={this.state.eventName} />;
      case "awaybatters":
        return <Awaybatters printAll={this.printAll} increment={this.increment} decrement={this.decrement} isPrint={this.state.printAll} awayHitterFilter={this.state.awayHitterFilter} eventName={this.state.eventName} />;
      case "umpireanylysis":
        return <Umpireanalysis printAll={this.printAll} isPrint={this.state.printAll} eventName={this.state.eventName} />;
      default:
        return '';
    }
  }

  render() {
    const tabs = this.state.tabs;

    return (
      <div className="d-flex gamesummary-sidebar">
        {/* <Gamesummarysidebar /> */}
        <div
          className={`page-content-wrapper ${tabs === 0 || tabs === 1 ? "tabactive" : ""
            }`}
        >
          <Header parentCallback={this.handleCallback} />
          {tabs === 0 && <Teamsummary />}
          {tabs === 1 && <Development />}
          {tabs !== 0 && tabs !== 1 && (
            <>
              <div className="main-div">
                <div className="sidebar-content">
                  <Gamesummarysidebar />
                  <div className="content">{this.getActiveTab()}</div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }

}
const mapDispatchToProps = (dispatch) => ({
  fetchSummaryData: (data, next) => dispatch(getGameSummaryData(data, next)),
  startLoader: (data, next) => dispatch({ type: "LOADING", payload: data }, next(data)),
});
export default connect(null, mapDispatchToProps)(Game);

