import { useEffect } from "react";
import { constant } from "../../../../../shared/constant";

function ReleasePointTable({ data }) {


  return (
    <>
      <div className="table-responsive-xl">
        <table className="table tabledashed">
          <thead>
            <tr>
              <th>Titles</th>
              <th>Group A</th>
              <th>Group B</th>
              <th>Difference</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(data).map((keyName, i) => {
              return (
                <>
                  <tr>
                    <td className="tablerowgray">Pitch Type</td>
                    <td className="tablerowgray" style={{ color: constant.pitchTypeColor[data[keyName].groupA.type] }}>{data[keyName].groupA.type}</td>
                    <td className="tablerowgray" style={{ color: constant.pitchTypeColor[data[keyName].groupB.type] }}>{data[keyName].groupB.type}</td>
                    <td className="tablerowgray">-</td>
                  </tr>
                  <tr>
                    <td className="table-rowwhite">Dispersion</td>
                    <td className="table-rowwhite">{data[keyName].groupA.dispersion}</td>
                    <td className="table-rowwhite">{data[keyName].groupB.dispersion}</td>
                    <td className="table-rowwhite">{data[keyName].differ.dispersion}</td>
                  </tr>
                </>
              )
            })}


          </tbody>
        </table>
      </div>
    </>
  );
}
export default ReleasePointTable;