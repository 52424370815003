import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { plotChartData } from "../../shared/function";
import { constant } from "../../shared/constant";
import maxBy from "lodash/fp/maxBy";
import minBy from "lodash/fp/minBy";
import domtoimage from 'dom-to-image';
import PrintImagePopup from "../../shared/PrintImagePopup";
import { downloadChartPrintImg } from "../../shared/printReportUtils";

function PlotChart({ chartData, id, metricHandleChange, plot_x, plot_y, type, eventProfileData, sendReq, tabslist, profilepage, pitcherDefault, isNotDownloadImgLoader, isDownloadImgLoader, eventName }) {
    const [isChartImg, setIsChartImg] = useState(false);
    const [clickFlag, setClickFlag] = useState(false);
    const [state, setState] = useState({
        chart: {
            type: "scatter",
            borderWidth: 0,
            marginTop: 60,
            borderColor: "#D8D8D8",
            borderRadius: "8px",
        },
        title: {
            text: "",
        },
        xAxis: {
            gridLineWidth: 1,
            title: {
                enabled: true,
                text: constant.yaxisSplineChart[plot_x],
                style: {
                    color: "#777F8F",
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "14px",
                    fontWeight: "500",
                },
            },
            max: chartData.length ? maxBy(plot_x, chartData)?.plot_x : undefined,
            min: chartData.length ? minBy(plot_x, chartData)?.plot_x : undefined
        },
        yAxis: {
            title: {
                enabled: true,
                text: constant.yaxisSplineChart[plot_y],
                style: {
                    color: "#777F8F",
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "14px",
                    fontWeight: "500",
                },
            }
        },
        legend: {
            layout: "horizontal",
            align: "top",
            verticalAlign: "top",
            // y: 35,
        },
        credits: { enabled: false },
        plotOptions: {
            series: {
                stickyTracking: false,
                states: {
                    inactive: {
                        opacity: 1
                    }
                }
            }

        },
        tooltip: {
            formatter: function () {
                let tooltip = `<span>
          <span>Batter name: ${this.point.options && this.point.options.batter_name ? this.point.options.batter_name : "-"}</span><br>
          <span>Bat Speed: ${this.point.options && this.point.options.bat_speed ? Math.round(this.point.options.bat_speed) : "-"}</span><br>
          <span>Rotational Acceleration: ${this.point.options && this.point.options.rotational_acceleration ? this.point.options.rotational_acceleration.toFixed(1) : "-"}</span><br>
          <span>On Plane Efficiency: ${this.point.options && this.point.options.on_plane_efficiency ? Math.round(this.point.options.on_plane_efficiency) : "-"}</span><br>
          <span>Attack Angle: ${this.point.options && this.point.options.attack_angle ? Math.round(this.point.options.attack_angle) : "-"}</span><br>
          <span>Early Connection: ${this.point.options && this.point.options.early_connection ? Math.round(this.point.options.early_connection) : "-"}</span><br>
          <span>Connection at Impact: ${this.point.options && this.point.options.connection_at_impact ? Math.round(this.point.options.connection_at_impact) : "-"}</span><br>
          <span>Vertical Bat Angle: ${this.point.options && this.point.options.vertical_bat_angle ? Math.round(this.point.options.vertical_bat_angle) : "-"}</span><br>
          <span>Power: ${this.point.options && this.point.options.power ? this.point.options.power.toFixed(2) : "-"}</span><br>
          <span>Time to Contact: ${this.point.options && this.point.options.time_to_contact ? this.point.options.time_to_contact.toFixed(2) : "-"}</span><br>
          <span>Peak Hand Speed: ${this.point.options && this.point.options.peak_hand_speed ? this.point.options.peak_hand_speed.toFixed(1) : "-"}</span><br>
        </span>`;
                return tooltip;
            },
        },
        series: [],
        responsive: {
            rules: [
                {
                    condition: {
                        maxWidth: 500,
                    },
                },
            ],
        },
    });

    useEffect(() => {
        if (chartData) {
            if (plot_x) {
                state.xAxis = {
                    gridLineWidth: 1,
                    title: {
                        enabled: true,
                        text: constant.yaxisSplineChart[plot_x],
                        style: {
                            color: "#777F8F",
                            fontFamily: "Poppins, sans-serif",
                            fontSize: "14px",
                            fontWeight: "500",
                        },
                    },
                    max: chartData.length ? maxBy(plot_x, chartData)?.plot_x : undefined,
                    min: chartData.length ? minBy(plot_x, chartData)?.plot_x : undefined
                }
            }
            if (plot_y) {
                state.yAxis.title = {
                    enabled: true,
                    text: constant.yaxisSplineChart[plot_y],
                    style: {
                        color: "#777F8F",
                        fontFamily: "Poppins, sans-serif",
                        fontSize: "14px",
                        fontWeight: "500",
                    }
                }
            }
            if (chartData.length !== 0) {
                const seriesData = plotChartData(chartData, plot_x, plot_y);
                state.series = seriesData;
                if (state.series) {
                    state.series[0].showInLegend = false
                }
                Highcharts.chart(id, state);
            } else {
                state.series = [{ showInLegend: false }];
                Highcharts.chart(id, state);
            }
        }
    }, [chartData, plot_x, plot_y]);

    const chartImage = (id) => {
        isDownloadImgLoader();
        setClickFlag(true)
        if (document.getElementById(`img${id}`)) {
            document.getElementById(`img${id}`).remove()
        }
        setIsChartImg(true)
        downloadChartPrintImg(id, setIsChartImg, setClickFlag, isNotDownloadImgLoader)
    }

    return (
        <>
            <div className="select-group select-group-white">
                <div className="select-box">
                    <select name={`${type === "poltFirst" ? "plot_f_x" : type === "poltSecond" ? "plot_s_x" : "plot_t_x"}`} className="custom-select" value={plot_x} onChange={metricHandleChange} style={{ maxWidth: "250px" }}>
                        <option value="bat_speed">Bat Speed</option>
                        {/* <option value="plane">Plane</option>
                    <option value="connection">Connection</option>
                    <option value="rotation">Rotation</option> */}
                        <option value="rotational_acceleration">Rotational Acceleration</option>
                        <option value="on_plane_efficiency">On Plane Efficiency</option>
                        <option value="attack_angle">Attack Angle</option>
                        <option value="early_connection">Early Connection</option>
                        <option value="connection_at_impact">Connection at Impact</option>
                        <option value="vertical_bat_angle">Vertical Bat Angle</option>
                        <option value="power">Power</option>
                        <option value="time_to_contact">Time to Contact</option>
                        <option value="peak_hand_speed">Peak Hand Speed</option>
                    </select>
                </div>
                <div className="select-box">
                    <select name={`${type === "poltFirst" ? "plot_f_y" : type === "poltSecond" ? "plot_s_y" : "plot_t_y"}`} className="custom-select" value={plot_y} onChange={metricHandleChange} style={{ maxWidth: "250px" }}>
                        <option value="bat_speed">Bat Speed</option>
                        {/* <option value="plane">Plane</option>
                    <option value="connection">Connection</option>
                    <option value="rotation">Rotation</option> */}
                        <option value="rotational_acceleration">Rotational Acceleration</option>
                        <option value="on_plane_efficiency">On Plane Efficiency</option>
                        <option value="attack_angle">Attack Angle</option>
                        <option value="early_connection">Early Connection</option>
                        <option value="connection_at_impact">Connection at Impact</option>
                        <option value="vertical_bat_angle">Vertical Bat Angle</option>
                        <option value="power">Power</option>
                        <option value="time_to_contact">Time to Contact</option>
                        <option value="peak_hand_speed">Peak Hand Speed</option>
                    </select>
                </div>
            </div>
            <div style={{ height: "415px" }} id={id} />
            {/* <button className="btn blue btn-view" onClick={() => chartImage(id)} disabled={clickFlag} >Download</button> */}
            <div class="chartdownload" onClick={() => chartImage(id)} disabled={clickFlag}><a class="btn white"><i class="fa fa-download" aria-hidden="true"></i></a></div>

            {/* chart visual image download */}
            {isChartImg && <PrintImagePopup
                eventProfileData={eventProfileData}
                id={id}
                sendReq={sendReq}
                tabslist={tabslist}
                eventName={eventName}
                pitcherDefault={pitcherDefault}
                profilepage={profilepage} />}
        </>
    );
}

export default PlotChart;