import React, { useReducer, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { constant } from '../../../shared/constant'
import { getInsightData } from '../../../store/actions';
import ReactTooltip from 'react-tooltip';
import { imagesArr } from '../../../assets/images';


const initialState = {
    filters: {
        event_type: "All",
        pitch_type: "All",
        hand: "All",
    },
    hitterData: [],
    pitcherData: [],
    hitterKey: ['exit_velocity', 'peak_ev', 'ev_in_air', 'ev_on_gb', 'hard_hit', 'damage', 'gb', 'swing', 'swstr', 'whiff', 'iz_whiff', 'contact', 'zone_swing', 'zone_contact', 'chase_swing', 'chase_contact', 'impact_zone'],
    pitcherKey: ['exit_velocity_against', 'ev_in_air', 'ev_on_gb', 'gb', '1pk', 'strike', 'zone', 'edge', 'swstr', 'whiff', 'iz_whiff', 'contact', 'chase_swing', 'csw', 'put_away', '2K', 'impact_zone']
};
const reducer = (state, payload) => ({ ...state, ...payload });

const TeamInsights = () => {
    const [insights, setInsights] = useReducer(reducer, initialState);
    const { filters, hitterData, pitcherData, hitterKey, pitcherKey } = insights;
    const dispatch = useDispatch();

    useEffect(() => {
        getInsightData(filters, (res) => {
            renderTable(res);
        })(dispatch)
    }, [])

    const renderTable = (res) => {
        let hitterArr = [];
        let pitcherArr = [];
        res.hitter.length !== 0 &&
            hitterKey.map((key) => {
                hitterArr.push({
                    _id: key === "exit_velocity" ? "Exit Velocity" : key === "peak_ev" ? "Peak EV" : key === "ev_in_air" ? "EV in Air" : key === "ev_on_gb" ? "EV on GB" : key === "hard_hit" ? "Hard Hit%" :
                        key === "damage" ? "Damage%" : key === "gb" ? "GB%" : key === "swing" ? "Swing%" : key === "swstr" ? "SwStr%" : key === "whiff" ? "Whiff%" : key === "iz_whiff" ? "IZ Whiff%"
                            : key === "contact" ? "Contact%" : key === "zone_swing" ? "Z Swing%" : key === "zone_contact" ? "Z Cont%" : key === "chase_swing" ? "O Swing%" : key === "chase_contact" ? "O Cont%" : "Impact Zone%",
                    season: res.hitter.find((i) => i._id === "current_season") ? res.hitter.find((i) => i._id === "current_season")[key] != 0 ? res.hitter.find((i) => i._id === "current_season")[key] : "0" : "",
                    past_month: res.hitter.find((i) => i._id === "past_month") ? res.hitter.find((i) => i._id === "past_month")[key] != 0 ? res.hitter.find((i) => i._id === "past_month")[key] : "0" : "",
                    past_week: res.hitter.find((i) => i._id === "past_week") ? res.hitter.find((i) => i._id === "past_week")[key] != 0 ? res.hitter.find((i) => i._id === "past_week")[key] : "0" : "",
                    season_leader: res.hitter.find((i) => i._id === "season_leader") ? res.hitter.find((i) => i._id === "season_leader")[key] : "",
                })
            })
        res.pitcher.length !== 0 &&
            pitcherKey.map((key) => {
                pitcherArr.push({
                    _id: key === "exit_velocity_against" ? "EV Against" : key === "ev_in_air" ? "EV in Air" : key === "ev_on_gb" ? "EV on GB" : key === "gb" ? "GB%" : key === "1pk" ? "First Pitch K%" :
                        key === "strike" ? "Strike%" : key === "zone" ? "Zone%" : key === "edge" ? "Edge%" : key === "swstr" ? "SwStr%" : key === "whiff" ? "Whiff%"
                            : key === "iz_whiff" ? "IZ Whiff%" : key === "contact" ? "Contact%" : key === "chase_swing" ? "Chase%" : key === "csw" ? "CSW%" : key === "put_away" ? "Put Away%" : key === "2K" ? "Race to 2K%" : "Impact Zone%",
                    season: res.pitcher.find((i) => i._id === "current_season") ? res.pitcher.find((i) => i._id === "current_season")[key] != 0 ? res.pitcher.find((i) => i._id === "current_season")[key] : "0" : "",
                    past_month: res.pitcher.find((i) => i._id === "past_month") ? res.pitcher.find((i) => i._id === "past_month")[key] != 0 ? res.pitcher.find((i) => i._id === "past_month")[key] : "0" : "",
                    past_week: res.pitcher.find((i) => i._id === "past_week") ? res.pitcher.find((i) => i._id === "past_week")[key] != 0 ? res.pitcher.find((i) => i._id === "past_week")[key] : "0" : "",
                    season_leader: res.pitcher.find((i) => i._id === "season_leader") ? res.pitcher.find((i) => i._id === "season_leader")[key] : "",
                })
            })
        setInsights({
            // ...insights,
            hitterData: hitterArr,
            pitcherData: pitcherArr
        })
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInsights({
            ...insights,
            filters: {
                ...filters,
                [name]: value
            }
        })
        let data = {
            event_type: name === "event_type" ? value : filters.event_type,
            pitch_type: name === "pitch_type" ? value : filters.pitch_type,
            hand: name === "hand" ? value : filters.hand,
        }
        getInsightData(data, (res) => {
            renderTable(res);
        })(dispatch)
    }

    const renderHitterTable = () => {
        return (
            hitterData && hitterData.map((item) => {
                return (
                    <tr>
                        <td data-tip={constant.teamInsightsTooltip[item._id]}>
                            <ReactTooltip place="top" type="dark" effect="solid" offset={{ top: -20, left: 0 }} />{item._id}
                        </td>
                        <td>{item.season ? item.season != 0 ? item.season.toString().includes(".") ? (item._id === "Exit Velocity" || item._id === "Peak EV" || item._id === "EV in Air" || item._id === "EV on GB") ? item.season.toFixed(1) : item.season.toFixed(1) + "%" :
                            (item._id === "Exit Velocity" || item._id === "Peak EV" || item._id === "EV in Air" || item._id === "EV on GB") ? item.season : item.season + "%" : "0" : "-"}</td>
                        <td
                            style={{
                                color: item.past_month && item.season ? item._id === "Peak EV" ? "#1B1B1B" : (item._id === "Exit Velocity" || item._id === "EV in Air" || item._id === "EV on GB" || item._id === "Hard Hit%" || item._id === "Damage%" || item._id === "Swing%"
                                    || item._id === "Contact%" || item._id === "Z Swing%" || item._id === "Z Cont%" || item._id === "O Cont%" || item._id === "Impact Zone%") ?
                                    (item.season < item.past_month ? "#38761D" : item.season == item.past_month ? "#1B1B1B" : "#990000") : (item.season > item.past_month ? "#38761D" : item.season == item.past_month ? "#1B1B1B" : "#990000") : "#1B1B1B"
                            }}>{item.past_month ? item.past_month != 0 ? item.past_month.toString().includes(".") ? (item._id === "Exit Velocity" || item._id === "Peak EV" || item._id === "EV in Air" || item._id === "EV on GB") ? item.past_month.toFixed(1) : item.past_month.toFixed(1) + "%" :
                                (item._id === "Exit Velocity" || item._id === "Peak EV" || item._id === "EV in Air" || item._id === "EV on GB") ? item.past_month : item.past_month + "%" : "0" : "-"}</td>
                        <td
                            style={{
                                color: item.past_week && item.season ? item._id === "Peak EV" ? "#1B1B1B" : (item._id === "Exit Velocity" || item._id === "EV in Air" || item._id === "EV on GB" || item._id === "Hard Hit%" || item._id === "Damage%" || item._id === "Swing%"
                                    || item._id === "Contact%" || item._id === "Z Swing%" || item._id === "Z Cont%" || item._id === "O Cont%" || item._id === "Impact Zone%") ?
                                    (item.season < item.past_week ? "#38761D" : item.season == item.past_week ? "#1B1B1B" : "#990000") : (item.season > item.past_week ? "#38761D" : item.season == item.past_week ? "#1B1B1B" : "#990000") : "#1B1B1B"
                            }}>{item.past_week ? item.past_week != 0 ? item.past_week.toString().includes(".") ? (item._id === "Exit Velocity" || item._id === "Peak EV" || item._id === "EV in Air" || item._id === "EV on GB") ? item.past_week.toFixed(1) : item.past_week.toFixed(1) + "%" :
                                (item._id === "Exit Velocity" || item._id === "Peak EV" || item._id === "EV in Air" || item._id === "EV on GB") ? item.past_week : item.past_week + "%" : "0" : "-"}</td>
                        <td>{item.season_leader ? item.season_leader?._id ? item.season_leader?._id : "-" : "-"}</td>
                        <td>{item.season_leader ? item.season_leader?.value ? item.season_leader?.value != 0 ? item.season_leader?.value.toString().includes(".") ? (item._id === "Exit Velocity" || item._id === "Peak EV" || item._id === "EV in Air" || item._id === "EV on GB") ? item.season_leader?.value.toFixed(1) :
                            item.season_leader?.value.toFixed(1) + "%" : (item._id === "Exit Velocity" || item._id === "Peak EV" || item._id === "EV in Air" || item._id === "EV on GB") ? item.season_leader?.value : item.season_leader?.value + "%" : "0" : "-" : '-'}</td>
                    </tr>
                )
            })
        )
    }

    const renderPitcherTable = () => {
       

        return (
            pitcherData && pitcherData.map((item) => {
                let tooltipImg = item._id === "Zone%" ? "zoneImage" : item._id === "Edge%" ? "edgeImage" : "";
                return (
                    <tr>
                        {item._id === "Zone%" || item._id === "Edge%" ?  
                        <td data-tip="" data-for={tooltipImg}><ReactTooltip place="top" type="light" className='tooltip-pitch' effect="solid" offset={{ top: -20, left: 0 }} id={tooltipImg}><img className='' src={item._id === "Zone%" ? imagesArr.zone : imagesArr.edge}></img> </ReactTooltip>{item._id}</td>:
                            <td data-tip={constant.teamInsightsTooltip[item._id]}><ReactTooltip place="top" type="dark" effect="solid" offset={{ top: -20, left: 0 }} />{item._id}</td>
                        }
                        
                        <td>{item.season ? item.season != 0 ? item.season.toString().includes(".") ? (item._id === "EV Against" || item._id === "EV in Air" || item._id === "EV on GB") ? item.season.toFixed(1) : item.season.toFixed(1) + "%" :
                            (item._id === "EV Against" || item._id === "EV in Air" || item._id === "EV on GB") ? item.season : item.season + "%" : "0" : "-"}</td>
                        <td
                            style={{
                                color: item.past_month && item.season ? (item._id === "GB%" || item._id === "First Pitch K%" || item._id === "Strike%" || item._id === "Zone%" || item._id === "Edge%" || item._id === "SwStr%"
                                    || item._id === "Whiff%" || item._id === "IZ Whiff%" || item._id === "Chase%" || item._id === "CSW%" || item._id === "Put Away%" || item._id === "Race to 2K%") ?
                                    (item.season < item.past_month ? "#38761D" : item.season == item.past_month ? "#1B1B1B" : "#990000") : (item.season > item.past_month ? "#38761D" : item.season == item.past_month ? "#1B1B1B": "#990000") : "#1B1B1B"
                            }}>{item.past_month ? item.past_month != 0 ? item.past_month.toString().includes(".") ? (item._id === "EV Against" || item._id === "EV in Air" || item._id === "EV on GB") ? item.past_month.toFixed(1) : item.past_month.toFixed(1) + "%" :
                                (item._id === "EV Against" || item._id === "EV in Air" || item._id === "EV on GB") ? item.past_month : item.past_month + "%" : "0" : "-"}</td>
                        <td style={{
                            color: item.past_week && item.season ? (item._id === "GB%" || item._id === "First Pitch K%" || item._id === "Strike%" || item._id === "Zone%" || item._id === "Edge%" || item._id === "SwStr%"
                                || item._id === "Whiff%" || item._id === "IZ Whiff%" || item._id === "Chase%" || item._id === "CSW%" || item._id === "Put Away%" || item._id === "Race to 2K%") ?
                                (item.season < item.past_week ? "#38761D" : item.season == item.past_week ? "#1B1B1B" :"#990000") : (item.season > item.past_week ? "#38761D" : item.season == item.past_week ? "#1B1B1B" :"#990000") : "#1B1B1B"
                        }}>{item.past_week ? item.past_week != 0 ? item.past_week.toString().includes(".") ? (item._id === "EV Against" || item._id === "EV in Air" || item._id === "EV on GB") ? item.past_week.toFixed(1) : item.past_week.toFixed(1) + "%" :
                            (item._id === "EV Against" || item._id === "EV in Air" || item._id === "EV on GB") ? item.past_week : item.past_week + "%" : "0" : "-"}</td>
                        <td>{item.season_leader ? item.season_leader?._id ? item.season_leader?._id : "-" : "-"}</td>
                        <td>{item.season_leader ? item.season_leader?.value ? item.season_leader?.value != 0 ? item.season_leader?.value.toString().includes(".") ? (item._id === "EV Against" || item._id === "EV in Air" || item._id === "EV on GB") ? item.season_leader?.value.toFixed(1) :
                            item.season_leader?.value.toFixed(1) + "%" : (item._id === "EV Against" || item._id === "EV in Air" || item._id === "EV on GB") ? item.season_leader?.value : item.season_leader?.value + "%" : "0" : "-" : '-'}</td>
                    </tr>
                )
            })
        )
    }

    return (
        <>
            {/* <h3 className='my-1 text-center'>Team Insights</h3> */}
            <div className="select_box row">
                <div className="select_box_inner">
                    <select
                        name="event_type"
                        className="custom-select"
                        value={filters.event_type}
                        onChange={handleChange}
                    >
                        <option value="All">Event Type</option>
                        <option value="Game">Game</option>
                        <option value="Scrimmage">Scrimmage</option>
                        <option value="BP">BP</option>
                        <option value="Bullpen">Bullpen</option>
                    </select>
                </div>

                <div className="select_box_inner">
                    <select
                        name="pitch_type"
                        value={filters.pitch_type}
                        className="custom-select"
                        onChange={handleChange}
                    >
                        {constant.softball ?
                            <>
                                <option value="All">Pitch Type</option>
                                <option value="Fastball">Fastball</option>
                                <option value="Drop Ball">Drop Ball</option>
                                <option value="Rise Ball">Rise Ball</option>
                                <option value="Curveball">Curveball</option>
                                <option value="Screw Ball">Screw Ball</option>
                                <option value="Changeup">Changeup</option>
                                <option value="Drop Curve">Drop Curve</option>
                            </> :
                            <>
                                <option value="All">Pitch Type</option>
                                <option value="Fastballs">Fastballs</option>
                                <option value="Breaking">Breaking</option>
                                <option value="Offspeed">Offspeed</option>
                                <option value="Fastball">Fastball</option>
                                <option value="Sinker">Sinker</option>
                                <option value="Cutter">Cutter</option>
                                <option value="Curveball">Curveball</option>
                                <option value="Slider">Slider</option>
                                <option value="Changeup">Changeup</option>
                                <option value="Splitter">Splitter</option>
                                <option value="Knuckleball">Knuckleball</option>
                            </>}
                    </select>
                </div>

                <div className="select_box_inner">
                    <select
                        name="hand"
                        value={filters.hand}
                        className="custom-select"
                        onChange={handleChange}
                    >
                        <option value="All">Handedness</option>
                        <option value="Right">Vs Right</option>
                        <option value="Left">Vs Left</option>
                    </select>
                </div>
            </div>
            <div className='d-flex team_insights_sec'>
                <div>
                    <table className="table tabledashed">
                  
                        <thead>
                            <tr>
                                <th colSpan="4" className="text-center">Team Trends</th>
                                <th colSpan="2" className="text-center pitchTable">Player Trends</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="tablerowgray fw-500" >Hitter Metrics</td>
                                <td className="tablerowgray fw-500" >Season</td>
                                <td className="tablerowgray fw-500" >Past Month</td>
                                <td className="tablerowgray fw-500" >Past Week</td>
                                <td className="tablerowgray fw-500" >Season Leader</td>
                                <td className="tablerowgray fw-500" >Metric</td>
                            </tr>
                            {renderHitterTable()}
                            {
                                hitterData && hitterData.length === 0 &&
                                <tr>
                                    <td colSpan="6" className="text-center table-rowwhite">There's is no Info yet!</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
                <div>
                    <table className="table tabledashed">
                        <thead>
                            <tr>
                                <th colSpan="4" className="text-center">Team Trends</th>
                                <th colSpan="2" className="text-center pitchTable">Player Trends</th>
                            </tr>
                        </thead>
                        <tbody>
          
                            <tr>
                                <td className="tablerowgray fw-500" >Pitcher Metrics</td>
                                <td className="tablerowgray fw-500" >Season</td>
                                <td className="tablerowgray fw-500" >Past Month</td>
                                <td className="tablerowgray fw-500" >Past Week</td>
                                <td className="tablerowgray fw-500" >Season Leader</td>
                                <td className="tablerowgray fw-500" >Metric</td>
                            </tr>
                            {renderPitcherTable()}
                            {
                                pitcherData && pitcherData.length === 0 &&
                                <tr>
                                    <td colSpan="6" className="text-center table-rowwhite">There's is no Info yet!</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default TeamInsights
