import React, { Component } from 'react';

class hittingMetrics extends Component {

    render() {
        const { hMetrics_data } = this.props;
        return (
            <>
                <div className="w-50">
                    <div className="gamesummary-table">
                        <div className="table-responsive-xl">
                            <table className="table tabledashed">
                                <thead>
                                    <tr>
                                        <th colSpan="2" className="one-child text-center">
                                            Hitting Metrics
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        hMetrics_data && hMetrics_data.length !== 0 ?
                                            hMetrics_data.map((td, i) => {
                                                if (td.key !== "Batter Team") {
                                                    return (
                                                        <tr>
                                                            <td className="table-rowwhite">{td.key}</td>
                                                            <td className="table-rowwhite">{td.value}</td>
                                                        </tr>
                                                    );
                                                }
                                            })
                                            :
                                            <tr>
                                                <td className="table-rowwhite text-center" colSpan="2">
                                                    There's no info yet!
                                                </td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default hittingMetrics;
