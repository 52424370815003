import React, { Component } from 'react'
import AllPitchStrikeZoneChart from "./component/StrikeZoneChart";
import SwingsStrikeZoneChart from "./component/StrikeZoneChart";
import WhiffsZoneChart from "./component/StrikeZoneChart";
import IndividualStrikeZoneChart from "./component/StrikeZoneIndividualChart";
import { constant } from '../../../shared/constant';
import PitchersMainChart from './component/PithchersMainChart';
import HomePitcherPitchSequencingChart from "./../../../components/Charts/pitchSequencingChart";
import HomePitcherCountSequencingChart from "./../../../components/Charts/pitchSequencingChart";
import HomePitcherResultSequencingChart from "./../../../components/Charts/pitchSequencingChart";
import ReactTooltip from 'react-tooltip';
import { strikeZoneChartWithRedBox } from '../../../shared/function';
import { imagesArr } from '../../../assets/images';

class pitcharsenal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            strikeZone_1: "all_pitches",
            strikeZone_2: "swing",
            strikeZone_3: "whiff",
            coloredBy: "all_one_color",
            y_axis: "count",
            spline_chart: "velocity",
            //baseball individual
            strikeZoneB_fb:"all_pitches",
            strikeZoneB_si:"all_pitches",
            strikeZoneB_cu:"all_pitches",
            strikeZoneB_cb:"all_pitches",
            strikeZoneB_sl:"all_pitches",
            strikeZoneB_ch:"all_pitches",
            strikeZoneB_sp:"all_pitches",
            strikeZoneB_kb:"all_pitches",
            //softball individual
            strikeZoneS_fb:"all_pitches",
            strikeZoneS_db:"all_pitches",
            strikeZoneS_rb:"all_pitches",
            strikeZoneS_cb:"all_pitches",
            strikeZoneS_sb:"all_pitches",
            strikeZoneS_ch:"all_pitches",
            strikeZoneS_dc:"all_pitches",
        }
    }

    HandleChange = (e) => {
        const { name, value } = e.target
        this.setState({
            [name]: value
        })
    }

    // metric change y axis of rolling line chart
    metricHandleChange = (e) => {
        const { name, value } = e.target
        this.setState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    renderTable = () => {
        return (
            this.props.filterRes &&
            this.props.filterRes.first_summary_table &&
            this.props.filterRes.first_summary_table.map((list, i) => {
                let rowColor = ""
                if (list._id === "Total") {
                    rowColor = "table-rowoasis"
                } else if (list._id === "D1 Avg" || list._id === "Indy Ball") {
                    rowColor = "table-rowgrey80"
                } else if (list._id === "Team Avg") {
                    rowColor = "table-rowwhitesmoke"
                } else {
                    rowColor = "table-rowwhite"
                }
                return (
                    <tr>
                        <td className={`${rowColor}`} style={{color: constant.pitchTypeColor[list._id]}}>{list._id !== null ? list._id : "—"}</td>
                        <td className={`${rowColor}`}>{list._id === "D1 Avg" || list._id === "Indy Ball" || list._id === "Team Avg" ? " " : list["#"] ? list["#"] : list["#"] === 0 ? "0" : "—"}</td>
                        <td className={`${rowColor}`}>{list._id === "D1 Avg" || list._id === "Indy Ball" || list._id === "Team Avg" ? " " : list.perc ? list.perc.toString().includes(".") ? list.perc.toFixed(1) + "%" : list.perc + "%" : list.perc === 0 ? "0%" : "—"}</td>
                        <td className={`${rowColor}`}>{list._id === "D1 Avg" || list._id === "Indy Ball" || list._id === "Team Avg" ? " " : list.rhp ? list.rhp.toString().includes(".") ? list.rhp.toFixed(1) + "%" : list.rhp + "%" :list.rhp === 0 ?  "0%" :"—"}</td>
                        <td className={`${rowColor}`}>{list._id === "D1 Avg" || list._id === "Indy Ball" || list._id === "Team Avg" ? " " : list.lhp ? list.lhp.toString().includes(".") ? list.lhp.toFixed(1) + "%" : list.lhp + "%":list.lhp === 0 ?  "0%" :"—"}</td>
                        <td className={`${rowColor}`}>{list._id === "D1 Avg" || list._id === "Indy Ball" || list._id === "Team Avg" ? " " : list.velocity ? list.velocity.toString().includes(".") ? list.velocity.toFixed(1) : list.velocity : list.velocity === 0 ? "0%" : "—"}</td>
                        <td className={`${rowColor}`}>{list._id === "D1 Avg" || list._id === "Indy Ball" || list._id === "Team Avg" ? " " : list.peak_velocity ? list.peak_velocity.toString().includes(".") ? list.peak_velocity.toFixed(1) : list.peak_velocity : list.peak_velocity === 0 ? "0%" : "—"}</td>
                        <td className={`${rowColor}`}>{list["1pk"] ? list["1pk"].toString().includes(".") ? list["1pk"].toFixed(1) + "%" : list["1pk"] + "%" :list["1pk"] === 0 ?  "0%" :"—"}</td>
                        <td className={`${rowColor}`}>{list.strike ? list.strike.toString().includes(".") ? list.strike.toFixed(1) + "%" : list.strike + "%" :list.strike === 0 ?  "0%" :"—"}</td>
                        <td className={`${rowColor}`}>{list.zone ? list.zone.toString().includes(".") ? list.zone.toFixed(1) + "%" : list.zone + "%" :list.zone === 0 ?  "0%" :"—"}</td>
                        <td className={`${rowColor}`}>{list.edge ? list.edge.toString().includes(".") ? list.edge.toFixed(1) + "%" : list.edge + "%" :list.edge === 0 ?  "0%" :"—"}</td>
                        <td className={`${rowColor}`}>{list.csw ? list.csw.toString().includes(".") ? list.csw.toFixed(1) + "%" : list.csw + "%" :list.csw === 0 ?  "0%" :"—"}</td>
                        <td className={`${rowColor}`}>{list.swstr ? list.swstr.toString().includes(".") ? list.swstr.toFixed(1) + "%" : list.swstr + "%" : list.swstr === 0 ?  "0%" :"—"}</td>
                        <td className={`${rowColor}`}>{list.whiff ? list.whiff.toString().includes(".") ? list.whiff.toFixed(1) + "%" : list.whiff + "%" : list.whiff === 0 ?  "0%" :"—"}</td>
                        <td className={`${rowColor}`}>{list.iz_whiff ? list.iz_whiff.toString().includes(".") ? list.iz_whiff.toFixed(1) + "%" : list.iz_whiff + "%" : list.iz_whiff === 0 ?  "0%" :"—"}</td>
                        <td className={`${rowColor}`}>{list.chase ? list.chase.toString().includes(".") ? list.chase.toFixed(1) + "%" : list.chase + "%" : list.chase === 0 ?  "0%" :"—"}</td>
                        <td className={`${rowColor}`}>{list.foul ? list.foul.toString().includes(".") ? list.foul.toFixed(1) + "%" : list.foul + "%" : list.foul === 0 ?  "0%" :"—"}</td>
                        <td className={`${rowColor}`}>{list.bip ? list.bip.toString().includes(".") ? list.bip.toFixed(1) + "%" : list.bip + "%" : list.bip === 0 ?  "0%" :"—"}</td>
                        {/* <td className="table-rowwhite">{this.getLaunchAngle(list._id) ? this.getLaunchAngle(list._id).avg_la_total ? this.getLaunchAngle(list._id).avg_la_total.toString().includes(".") ? this.getLaunchAngle(list._id).avg_la_total.toFixed(1) : this.getLaunchAngle(list._id).avg_la_total : "—" : "—"}</td> */}
                    </tr>
                )
            })
        )
    }

    renderSummaryTable = () => {
        let tableData = this.props.filterRes.lhh_rhh_table_data || []
        tableData = tableData.map((item) => {
            return ({
                _id: item._id,
                ["1pk_" + item.batter_handedness]: item["1pk"],
                ["strike_" + item.batter_handedness]: item["strike"],
                ["zone_" + item.batter_handedness]: item["zone"],
                ["csw_" + item.batter_handedness]: item["csw"],
                ["swstr_" + item.batter_handedness]: item["swstr"],
                ["whiff_" + item.batter_handedness]: item["whiff"],
                ["iz_whiff_" + item.batter_handedness]: item["iz_whiff"],
                ["chase_" + item.batter_handedness]: item["chase"],
            })
        })

        let tables = tableData.reduce((acc, curr) => {
            if (curr._id) {
                const currentItems = acc[curr._id];
                return {
                    ...acc,
                    [curr._id]: currentItems ? [...currentItems, curr] : [curr]
                };
            }
            return acc;
        }, {});

        tables = Object.keys(tables).map((item) => {
            let tablesKey = {};
            tables[item].map((e) => {
                tablesKey = { ...tablesKey, ...e }
            })
            return tablesKey
        })

        return (
            tables &&
            tables.map((list, i) => {
                let rowColor = ""
                if (list._id === "Total") {
                    rowColor = "table-rowoasis"
                } else if (list._id === "D1 Avg" || list._id === "Indy Ball") {
                    rowColor = "table-rowgrey80"
                } else if (list._id === "Team Avg") {
                    rowColor = "table-rowwhitesmoke"
                } else {
                    rowColor = "table-rowwhite"
                }
                return (
                    <tr>
                        <td className={`${rowColor}`} style={{ color: constant.pitchTypeColor[list._id] }}>{list._id !== null ? list._id : "—"}</td>
                        <td className={`${rowColor}`}>{list["1pk_RHH"] ? list["1pk_RHH"].toString().includes(".") ? list["1pk_RHH"].toFixed(1) + "%" : list["1pk_RHH"] + "%" : list["1pk_RHH"] === 0 ?  "0%" : "—"}</td>
                        <td className={`${rowColor}`}>{list.strike_RHH ? list.strike_RHH.toString().includes(".") ? list.strike_RHH.toFixed(1) + "%" : list.strike_RHH + "%" : list.strike_RHH === 0 ?  "0%" :"—"}</td>
                        <td className={`${rowColor}`}>{list.zone_RHH ? list.zone_RHH.toString().includes(".") ? list.zone_RHH.toFixed(1) + "%" : list.zone_RHH + "%" : list.zone_RHH === 0 ?  "0%" :"—"}</td>
                        <td className={`${rowColor}`}>{list.csw_RHH ? list.csw_RHH.toString().includes(".") ? list.csw_RHH.toFixed(1) + "%" : list.csw_RHH + "%" : list.csw_RHH === 0 ?  "0%" :"—"}</td>
                        <td className={`${rowColor}`}>{list.swstr_RHH ? list.swstr_RHH.toString().includes(".") ? list.swstr_RHH.toFixed(1) + "%" : list.swstr_RHH + "%" : list.swstr_RHH === 0 ?  "0%" :"—"}</td>
                        <td className={`${rowColor}`}>{list.whiff_RHH ? list.whiff_RHH.toString().includes(".") ? list.whiff_RHH.toFixed(1) + "%" : list.whiff_RHH + "%" : list.whiff_RHH === 0 ?  "0%" :"—"}</td>
                        <td className={`${rowColor}`}>{list.iz_whiff_RHH ? list.iz_whiff_RHH.toString().includes(".") ? list.iz_whiff_RHH.toFixed(1) + "%" : list.iz_whiff_RHH + "%" : list.iz_whiff_RHH === 0 ?  "0%" :"—"}</td>
                        <td className={`${rowColor}`}>{list.chase_RHH ? list.chase_RHH.toString().includes(".") ? list.chase_RHH.toFixed(1) + "%" : list.chase_RHH + "%" : list.chase_RHH === 0 ?  "0%" :"—"}</td>
                        <td className={`${rowColor}`}>{list["1pk_LHH"] ? list["1pk_LHH"].toString().includes(".") ? list["1pk_LHH"].toFixed(1) + "%" : list["1pk_LHH"] + "%" : list["1pk_LHH"] === 0 ?  "0%" :"—"}</td>
                        <td className={`${rowColor}`}>{list.strike_LHH ? list.strike_LHH.toString().includes(".") ? list.strike_LHH.toFixed(1) + "%" : list.strike_LHH + "%" : list.strike_LHH === 0 ?  "0%" :"—"}</td>
                        <td className={`${rowColor}`}>{list.zone_LHH ? list.zone_LHH.toString().includes(".") ? list.zone_LHH.toFixed(1) + "%" : list.zone_LHH + "%" : list.zone_LHH === 0 ?  "0%" :"—"}</td>
                        <td className={`${rowColor}`}>{list.csw_LHH ? list.csw_LHH.toString().includes(".") ? list.csw_LHH.toFixed(1) + "%" : list.csw_LHH + "%" : list.csw_LHH === 0 ?  "0%" :"—"}</td>
                        <td className={`${rowColor}`}>{list.swstr_LHH ? list.swstr_LHH.toString().includes(".") ? list.swstr_LHH.toFixed(1) + "%" : list.swstr_LHH + "%" : list.swstr_LHH === 0 ?  "0%" :"—"}</td>
                        <td className={`${rowColor}`}>{list.whiff_LHH ? list.whiff_LHH.toString().includes(".") ? list.whiff_LHH.toFixed(1) + "%" : list.whiff_LHH + "%" : list.whiff_LHH === 0 ?  "0%" :"—"}</td>
                        <td className={`${rowColor}`}>{list.iz_whiff_LHH ? list.iz_whiff_LHH.toString().includes(".") ? list.iz_whiff_LHH.toFixed(1) + "%" : list.iz_whiff_LHH + "%" : list.iz_whiff_LHH === 0 ?  "0%" :"—"}</td>
                        <td className={`${rowColor}`}>{list.chase_LHH ? list.chase_LHH.toString().includes(".") ? list.chase_LHH.toFixed(1) + "%" : list.chase_LHH + "%" : list.chase_LHH === 0 ?  "0%" :"—"}</td>
                        {/* <td className="table-rowwhite">{this.getLaunchAngle(list._id) ? this.getLaunchAngle(list._id).avg_la_total ? this.getLaunchAngle(list._id).avg_la_total.toString().includes(".") ? this.getLaunchAngle(list._id).avg_la_total.toFixed(1) : this.getLaunchAngle(list._id).avg_la_total : "—" : "—"}</td> */}
                    </tr>
                )
            })
        )
    }

    renderCountSummaryTable = () => {
        let tableData = this.props.filterRes.lhh_rhh_table_data || []
        tableData = tableData.map((item) => {
            return ({
                _id: item._id,
                ["first_pitch_" + item.batter_handedness]: item["first_pitch"],
                ["batter_ahead_" + item.batter_handedness]: item["batter_ahead"],
                ["even_" + item.batter_handedness]: item["even"],
                ["pitcher_ahead_" + item.batter_handedness]: item["pitcher_ahead"],
                ["two_strikes_" + item.batter_handedness]: item["two_strikes"],
                ["count_" + item.batter_handedness]: item["perc"],
            })
        })

        let tables = tableData.reduce((acc, curr) => {
            if (curr._id) {
                const currentItems = acc[curr._id];
                return {
                    ...acc,
                    [curr._id]: currentItems ? [...currentItems, curr] : [curr]
                };
            }
            return acc;
        }, {});

        tables = Object.keys(tables).map((item) => {
            let tablesKey = {};
            tables[item].map((e) => {
                tablesKey = { ...tablesKey, ...e }
            })
            return tablesKey
        })

        return (
            tables &&
            tables.map((list, i) => {
                let rowColor = ""
                if (list._id === "Total") {
                    rowColor = "table-rowoasis"
                } else if (list._id === "D1 Avg" || list._id === "Indy Ball") {
                    rowColor = "table-rowgrey80"
                } else if (list._id === "Team Avg") {
                    rowColor = "table-rowwhitesmoke"
                } else {
                    rowColor = "table-rowwhite"
                }
                return (
                    <tr>
                        <td className={`${rowColor}`} style={{ color: constant.pitchTypeColor[list._id] }}>{list._id !== null ? list._id : "—"}</td>
                        <td className={`${rowColor}`}>{list.count_RHH ? list.count_RHH.toString().includes(".") ? list.count_RHH.toFixed(1) + "%" : list.count_RHH + "%" : list.count_RHH === 0 ?  "0%" :"—"}</td>
                        <td className={`${rowColor}`}>{list.first_pitch_RHH ? list.first_pitch_RHH.toString().includes(".") ? list.first_pitch_RHH.toFixed(1) + "%" : list.first_pitch_RHH + "%" : list.first_pitch_RHH === 0 ?  "0%" :"—"}</td>
                        <td className={`${rowColor}`}>{list.batter_ahead_RHH ? list.batter_ahead_RHH.toString().includes(".") ? list.batter_ahead_RHH.toFixed(1) + "%" : list.batter_ahead_RHH + "%" : list.batter_ahead_RHH === 0 ?  "0%" :"—"}</td>
                        <td className={`${rowColor}`}>{list.even_RHH ? list.even_RHH.toString().includes(".") ? list.even_RHH.toFixed(1) + "%" : list.even_RHH + "%" : list.even_RHH === 0 ?  "0%" :"—"}</td>
                        <td className={`${rowColor}`}>{list.pitcher_ahead_RHH ? list.pitcher_ahead_RHH.toString().includes(".") ? list.pitcher_ahead_RHH.toFixed(1) + "%" : list.pitcher_ahead_RHH + "%" : list.pitcher_ahead_RHH === 0 ?  "0%" :"—"}</td>
                        <td className={`${rowColor}`}>{list.two_strikes_RHH ? list.two_strikes_RHH.toString().includes(".") ? list.two_strikes_RHH.toFixed(1) + "%" : list.two_strikes_RHH + "%" : list.two_strikes_RHH === 0 ?  "0%" :"—"}</td>
                        <td className={`${rowColor}`}>{list.count_LHH ? list.count_LHH.toString().includes(".") ? list.count_LHH.toFixed(1) + "%" : list.count_LHH + "%" : list.count_LHH === 0 ?  "0%" :"—"}</td>
                        <td className={`${rowColor}`}>{list.first_pitch_LHH ? list.first_pitch_LHH.toString().includes(".") ? list.first_pitch_LHH.toFixed(1) + "%" : list.first_pitch_LHH + "%" : list.first_pitch_LHH === 0 ?  "0%" :"—"}</td>
                        <td className={`${rowColor}`}>{list.batter_ahead_LHH ? list.batter_ahead_LHH.toString().includes(".") ? list.batter_ahead_LHH.toFixed(1) + "%" : list.batter_ahead_LHH + "%" : list.batter_ahead_LHH === 0 ?  "0%" :"—"}</td>
                        <td className={`${rowColor}`}>{list.even_LHH ? list.even_LHH.toString().includes(".") ? list.even_LHH.toFixed(1) + "%" : list.even_LHH + "%" : list.even_LHH === 0 ?  "0%" :"—"}</td>
                        <td className={`${rowColor}`}>{list.pitcher_ahead_LHH ? list.pitcher_ahead_LHH.toString().includes(".") ? list.pitcher_ahead_LHH.toFixed(1) + "%" : list.pitcher_ahead_LHH + "%" : list.pitcher_ahead_LHH === 0 ?  "0%" :"—"}</td>
                        <td className={`${rowColor}`}>{list.two_strikes_LHH ? list.two_strikes_LHH.toString().includes(".") ? list.two_strikes_LHH.toFixed(1) + "%" : list.two_strikes_LHH + "%" : list.two_strikes_LHH === 0 ?  "0%" :"—"}</td>
                    </tr>
                )
            })
        )
    }

    renderPlateDisciplineTable = () => {
        return (
            this.props.filterRes &&
            this.props.filterRes.first_summary_table &&
            this.props.filterRes.first_summary_table.map((list, i) => {
                let rowColor = ""
                if (list._id === "Total") {
                    rowColor = "table-rowoasis"
                } else if (list._id === "D1 Avg" || list._id === "Indy Ball") {
                    rowColor = "table-rowgrey80"
                } else if (list._id === "Team Avg") {
                    rowColor = "table-rowwhitesmoke"
                } else {
                    rowColor = "table-rowwhite"
                }
                return (
                    <tr>
                        <td className={`${rowColor}`} style={{ color: constant.pitchTypeColor[list._id] }}>{list._id !== null ? list._id : "—"}</td>
                        <td className={`${rowColor}`}>{list._id === "D1 Avg" || list._id === "Team Avg" ? " " : list['#'] !== null ? list['#'] : list['#'] === 0 ? "0" : "—"}</td>
                        <td className={`${rowColor}`}>{list._id === "D1 Avg" || list._id === "Team Avg" ? " " : list.perc ? list.perc.toString().includes(".") ? list.perc.toFixed(1) + "%" : list.perc + "%" : list.perc === 0 ? "0%" : "—"}</td>
                        <td className={`${rowColor}`}>{list.inside ? list.inside.toString().includes(".") ? list.inside.toFixed(1) + "%" : list.inside + "%" : list.inside === 0 ? "0%" : "—"}</td>
                        <td className={`${rowColor}`}>{list.low ? list.low.toString().includes(".") ? list.low.toFixed(1) + "%" : list.low + "%" : list.low === 0 ? "0%" : "—"}</td>
                        <td className={`${rowColor}`}>{list.high ? list.high.toString().includes(".") ? list.high.toFixed(1) + "%" : list.high + "%" : list.high === 0 ? "0%" : "—"}</td>
                        <td className={`${rowColor}`}>{list.edge ? list.edge.toString().includes(".") ? list.edge.toFixed(1) + "%" : list.edge + "%" : list.edge === 0 ? "0%" : "—"}</td>
                        <td className={`${rowColor}`}>{list['1pk'] ? list['1pk'].toString().includes(".") ? list['1pk'].toFixed(1) + "%" : list['1pk'] + "%" : list['1pk'] === 0 ? "0%" : "—"}</td>
                        <td className={`${rowColor}`}>{list.strike ? list.strike.toString().includes(".") ? list.strike.toFixed(1) + "%" : list.strike + "%" : list.strike === 0 ? "0%" : "—"}</td>
                        <td className={`${rowColor}`}>{list.contact ? list.contact.toString().includes(".") ? list.contact.toFixed(1) + "%" : list.contact + "%" : list.contact === 0 ? "0%" : "—"}</td>
                        <td className={`${rowColor}`}>{list.zone ? list.zone.toString().includes(".") ? list.zone.toFixed(1) + "%" : list.zone + "%" : list.zone === 0 ? "0%" : "—"}</td>
                        <td className={`${rowColor}`}>{list.zone_swing ? list.zone_swing.toString().includes(".") ? list.zone_swing.toFixed(1) + "%" : list.zone_swing + "%" : list.zone_swing === 0 ? "0%" : "—"}</td>
                        <td className={`${rowColor}`}>{list.zone_contact ? list.zone_contact.toString().includes(".") ? list.zone_contact.toFixed(1) + "%" : list.zone_contact + "%" : list.zone_contact === 0 ? "0%" : "—"}</td>
                        <td className={`${rowColor}`}>{list.chase_swing ? list.chase_swing.toString().includes(".") ? list.chase_swing.toFixed(1) + "%" : list.chase_swing + "%" : list.chase_swing === 0 ? "0%" : "—"}</td>
                        <td className={`${rowColor}`}>{list.chase_contact ? list.chase_contact.toString().includes(".") ? list.chase_contact.toFixed(1) + "%" : list.chase_contact + "%" : list.chase_contact === 0 ? "0%" : "—"}</td>
                    </tr>
                )
            })
        )
    }
    
    renderMatrixSummaryTable = () => {
        return (
            this.props.filterRes &&
            this.props.filterRes.matrics_summary_table_data &&
            this.props.filterRes.matrics_summary_table_data.map((list, i) => {
                return (
                    <tr>
                        <td className="table-rowwhite" style={{ color: constant.pitchTypeColor[list._id] }}>{list._id !== null ? list._id : "—"}</td>
                        <td className="table-rowwhite">{list.count !== null ? list.count : list.count === 0 ? "0" : "—"}</td>
                        <td className="table-rowwhite">{list.velocity ? list.velocity.toString().includes(".") ? list.velocity.toFixed(1) : list.velocity : list.velocity === 0 ? "0" : "—"}</td>
                        <td className="table-rowwhite">{list.spin_rate ? Math.round(list.spin_rate) : list.spin_rate === 0 ? "0" : "—"}</td>
                        <td className="table-rowwhite">{list.efficiency ? Math.round(list.efficiency) + '%' : list.efficiency === 0 ? "0%" : "—"}</td>
                        <td className="table-rowwhite">{list.tilt ? list.tilt.toString().includes(".") ? list.tilt.toFixed(1) : list.tilt : list.tilt === 0 ? "0" : "—"}</td>
                        <td className="table-rowwhite">{list.spin_axis ? Math.round(list.spin_axis) : list.spin_axis === 0 ? "0" : "—"}</td>
                        <td className="table-rowwhite">{list.vertical_break ? list.vertical_break.toString().includes(".") ? list.vertical_break.toFixed(1) : list.vertical_break : list.vertical_break === 0 ? "0" : "—"}</td>
                        <td className="table-rowwhite">{list.induced_vertical_break ? list.induced_vertical_break.toString().includes(".") ? list.induced_vertical_break.toFixed(1) : list.induced_vertical_break : list.induced_vertical_break === 0 ? "0" : "—"}</td>
                        <td className="table-rowwhite">{list.horizontal_break ? list.horizontal_break.toString().includes(".") ? list.horizontal_break.toFixed(1) : list.horizontal_break : list.horizontal_break === 0 ? "0" : "—"}</td>
                        <td className="table-rowwhite">{list.vertical_approach_angle ? list.vertical_approach_angle.toString().includes(".") ? list.vertical_approach_angle.toFixed(1) : list.vertical_approach_angle : list.vertical_approach_angle === 0 ? "0" : "—"}</td>
                        <td className="table-rowwhite">{list.horz_approach_angle ? list.horz_approach_angle.toString().includes(".") ? list.horz_approach_angle.toFixed(1) : list.horz_approach_angle : list.horz_approach_angle === 0 ? "0" : "—"}</td>
                    </tr>
                )
            })
        )
    }

    render() {
        return (
            <>
                <div className="d-flex">
                    <div className="w-100">
                        <div className="container-fluid">

                            {/* Top Table */}
                            <div className="table-responsive-xl">
                                <ReactTooltip place="top" type="light" className='tooltip-pitch' effect="solid" offset={{ top: -20, left: 0 }} id="zoneImage"><img className='' src={imagesArr.zone}></img> </ReactTooltip>
                                <ReactTooltip place="top" type="light" className='tooltip-pitch' effect="solid" offset={{ top: -20, left: 0 }} id="edgeImage"><img className='' src={imagesArr.edge}></img> </ReactTooltip>
                                <table className="table tabledashed">
                                    <thead>
                                        <tr>
                                            <th className="text-center pitch-outcomes">Type</th>
                                            <th className="text-center">#</th>
                                            <th className="text-center">%</th>
                                            <th className="text-center" data-tip="% vs RHH"><ReactTooltip place="top" type="dark" effect="solid" offset={{ top: -20, left: 0 }} />%RHH</th>
                                            <th className="text-center" data-tip="% vs LHH">%LHH</th>
                                            <th className="text-center">Velo</th>
                                            <th className="text-center">Peak</th>
                                            <th className="text-center" data-tip="1st Pitch Strike %">1PK %</th>
                                            <th className="text-center" data-tip="Called K, Swinging K, and Contact">Strike %</th>
                                            <th className="text-center " data-tip="" data-for="zoneImage">Zone %</th>
                                            <th className="text-center " data-tip="" data-for="edgeImage">Edge %</th>
                                            <th className="text-center" data-tip="Called Strikes + Whiffs / All Pitches">CSW %</th>
                                            <th className="text-center" data-tip="Whiffs / All Pitches">SwStr %</th>
                                            <th className="text-center" data-tip="Whiffs / All Swings">Whiff %</th>
                                            <th className="text-center" data-tip="Whiffs In Zone / All Swings In Zone">IZ Whiff %</th>
                                            <th className="text-center" data-tip="% of Swings Out of Zone">Chase %</th>
                                            <th className="text-center" data-tip="Foul Balls / All Swings" >Foul %</th>
                                            <th className="text-center pitchTable" data-tip=" Balls In Play / All Swings">BIP %</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.renderTable()}    
                                        {
                                            this.props.filterRes &&
                                            this.props.filterRes.first_summary_table &&
                                            this.props.filterRes.first_summary_table.length === 0 &&
                                            <tr>
                                                <td colSpan="17" className="text-center table-rowwhite">There's is no Info yet!</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>

                            {/*3 Strike Zone Visuals */}
                            <div className="common-charts">
                                <div className={`row ${!this.props.isPrinting ? 'singleDiv' : 'breakDiv'}`}>
                                    <div className="w-33">
                                        {
                                            this.props.filterRes &&
                                            <AllPitchStrikeZoneChart
                                                chartData={this.props.filterRes.strike_zone_chart}
                                                setScatterAxis={this.props.setScatterAxis}
                                                onResetScatterFilter={this.props.applyFilter}
                                                oldData={this.props.oldData}
                                                id="allPitchStrikeZone"
                                                contourData={this.props.filterRes.strike_zone_chart}
                                                contourId="allPitchCountour"
                                                contour_graph={this.props.contour_graph}
                                                chartName={this.state.strikeZone_1}
                                                onChangeEvent={this.HandleChange}
                                                cameraAngle={["Strike Zone", "Catcher", "Home Top", "Home 3B Side", "Home 1B Side", "Press Box"]}
                                                visualType="Pitching"
                                                eventProfileData={this.props.eventProfileData}
                                                sendReq={this.props?.sendReq}
                                                tabslist={this.props?.tabslist}
                                                profilepage={true}
                                                isNotDownloadImgLoader={this.props.isNotDownloadImgLoader}
                                                isDownloadImgLoader={this.props.isDownloadImgLoader}
                                                pitchrIframeFlag={this.props.pitchrIframeFlag}
                                                closePitchrIframe={this.props.closePitchrIframe} />
                                        }
                                    </div>
                                    
                                    <div className="w-33">
                                        {
                                            this.props.filterRes &&
                                            <SwingsStrikeZoneChart
                                                chartData={this.props.filterRes.strike_zone_chart}
                                                setScatterAxis={this.props.setScatterAxis}
                                                onResetScatterFilter={this.props.applyFilter}
                                                oldData={this.props.oldData}
                                                id="swingsStrikeZone"
                                                contourData={this.props.filterRes.strike_zone_chart}
                                                contourId="swingsCountour"
                                                contour_graph={this.props.contour_graph}
                                                chartName={this.state.strikeZone_2}
                                                onChangeEvent={this.HandleChange}
                                                cameraAngle={["Strike Zone", "Catcher", "Home Top", "Home 3B Side", "Home 1B Side", "Press Box"]}
                                                visualType="Pitching"
                                                eventProfileData={this.props.eventProfileData}
                                                sendReq={this.props?.sendReq}
                                                tabslist={this.props?.tabslist}
                                                profilepage={true}
                                                isNotDownloadImgLoader={this.props.isNotDownloadImgLoader}
                                                isDownloadImgLoader={this.props.isDownloadImgLoader}
                                                pitchrIframeFlag={this.props.pitchrIframeFlag}
                                                closePitchrIframe={this.props.closePitchrIframe} />
                                        }
                                    </div>
                                    <div className="w-33">
                                        {
                                            this.props.filterRes &&
                                            <WhiffsZoneChart
                                                chartData={this.props.filterRes.strike_zone_chart}
                                                setScatterAxis={this.props.setScatterAxis}
                                                onResetScatterFilter={this.props.applyFilter}
                                                oldData={this.props.oldData}
                                                id="whiffsZoneChart"
                                                contourData={this.props.filterRes.strike_zone_chart}
                                                contourId="whiffsCountour"
                                                contour_graph={this.props.contour_graph}
                                                chartName={this.state.strikeZone_3}
                                                onChangeEvent={this.HandleChange}
                                                cameraAngle={["Strike Zone", "Catcher", "Home Top", "Home 3B Side", "Home 1B Side", "Press Box"]}
                                                visualType="Pitching"
                                                eventProfileData={this.props.eventProfileData}
                                                sendReq={this.props?.sendReq}
                                                tabslist={this.props?.tabslist}
                                                profilepage={true}
                                                isNotDownloadImgLoader={this.props.isNotDownloadImgLoader}
                                                isDownloadImgLoader={this.props.isDownloadImgLoader}
                                                pitchrIframeFlag={this.props.pitchrIframeFlag}
                                                closePitchrIframe={this.props.closePitchrIframe} />
                                        }
                                    </div>
                                </div>
                            </div>

                            {/* Vs RHH vs LHH Summary Table */}

                            <div className="table-responsive-xl">
                                <table className="table tabledashed">
                                    <thead>
                                        <tr>
                                            <th colSpan="9" className="text-center">vs RHH</th>
                                            <th colSpan="8" className="text-center pitchTable">vs LHH</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="tablerowgray fw-500">Type</td>
                                            <td className="tablerowgray fw-500" data-tip="1st Pitch Strike %"><ReactTooltip place="top" type="dark" effect="solid" offset={{ top: -20, left: 0 }} />1PK%</td>
                                            <td className="tablerowgray fw-500" data-tip="Called K, Swinging K, and Contact">Strike%</td>
                                            <td className="tablerowgray fw-500" data-tip="" data-for="zoneImage">Zone%</td>
                                            <td className="tablerowgray fw-500" data-tip="Called Strikes + Whiffs / All Pitches">CSW%</td>
                                            <td className="tablerowgray fw-500" data-tip="Whiffs / All Pitches">SwStr%</td>
                                            <td className="tablerowgray fw-500" data-tip="Whiffs / All Swings">Whiff%</td>
                                            <td className="tablerowgray fw-500" data-tip="Whiffs In Zone / All Swings In Zone">IZ Whiff %</td>
                                            <td className="tablerowgray fw-500" data-tip="% of Swings Out of Zone">Chase %</td>
                                            <td className="tablerowgray fw-500" data-tip="1st Pitch Strike %">1PK%</td>
                                            <td className="tablerowgray fw-500" data-tip="Called K, Swinging K, and Contact">Strike%</td>
                                            <td className="tablerowgray fw-500" data-tip="" data-for="zoneImage">Zone%</td>
                                            <td className="tablerowgray fw-500" data-tip="Called Strikes + Whiffs / All Pitches">CSW%</td>
                                            <td className="tablerowgray fw-500" data-tip="Whiffs / All Pitches">SwStr%</td>
                                            <td className="tablerowgray fw-500" data-tip="Whiffs / All Swings">Whiff%</td>
                                            <td className="tablerowgray fw-500" data-tip="Whiffs In Zone / All Swings In Zone">IZ Whiff %</td>
                                            <td className="tablerowgray fw-500" data-tip="% of Swings Out of Zone">Chase %</td>
                                        </tr>
                                        {this.renderSummaryTable()}
                                        {
                                            this.props.filterRes &&
                                            this.props.filterRes.lhh_rhh_table_data &&
                                            this.props.filterRes.lhh_rhh_table_data.length === 0 &&
                                            <tr>
                                                <td colSpan="17" className="text-center table-rowwhite">There's is no Info yet!</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>

                            {/* Trend Visual */}

                            <div className="common-charts" id="homePitchersMainChartPrintArea">
                                <PitchersMainChart id="homePitcherChart"
                                    printAll={this.props.isPrint}
                                    chartData={this.props.filterRes.pitcher_spline_chart_data}
                                    metricHandleChange={this.metricHandleChange}
                                    spline_chart={this.state.spline_chart}
                                    eventProfileData={this.props.eventProfileData}
                                    eventName={this.props.eventName}
                                    pitcherDefault={this.props.pitcherDefault}
                                    sendReq={this.props?.sendReq}
                                    tabslist={this.props?.tabslist}
                                    profilepage={true}
                                    isNotDownloadImgLoader={this.props.isNotDownloadImgLoader}
                                    isDownloadImgLoader={this.props.isDownloadImgLoader}
                                />
                            </div>

                            {/* Count Summary Table */}

                            <div className="table-responsive-xl">
                                <table className="table tabledashed">
                                    <thead>
                                        <tr>
                                            <th colSpan="7" className="text-center">vs RHH</th>
                                            <th colSpan="6" className="text-center pitchTable">vs LHH</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="tablerowgray fw-500">Type</td>
                                            <td className="tablerowgray fw-500">All Counts</td>
                                            <td className="tablerowgray fw-500">1st Pitch</td>
                                            <td className="tablerowgray fw-500">Batter Ahead</td>
                                            <td className="tablerowgray fw-500">Even</td>
                                            <td className="tablerowgray fw-500">Pitcher Ahead</td>
                                            <td className="tablerowgray fw-500">2 Strikes</td>
                                            <td className="tablerowgray fw-500">All Counts</td>
                                            <td className="tablerowgray fw-500">1st Pitch</td>
                                            <td className="tablerowgray fw-500">Batter Ahead</td>
                                            <td className="tablerowgray fw-500">Even</td>
                                            <td className="tablerowgray fw-500">Pitcher Ahead</td>
                                            <td className="tablerowgray fw-500">2 Strikes</td>
                                        </tr>
                                        {this.renderCountSummaryTable()}
                                        {
                                            this.props.filterRes &&
                                            this.props.filterRes.lhh_rhh_table_data &&
                                            this.props.filterRes.lhh_rhh_table_data.length === 0 &&
                                            <tr>
                                                <td colSpan="13" className="text-center table-rowwhite">There's is no Info yet!</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>

                            {/* Sequencing Visuals */}

                            <div className="container-fluid" id="sequencingChartPrintArea">
                                <div className="common-charts">
                                <div className={`row ${!this.props.isPrinting ? 'singleDiv' : 'breakDiv'}`}>
                                        <div className="w-33">
                                            <HomePitcherPitchSequencingChart
                                                chartData={this.props.filterRes.pitch_sequence_chart_data}
                                                id="PitchSequencingChartHomePitcher"
                                                showTitle={true}
                                                type="pitcherProfile"
                                                increment={this.props.filterRes.increment}
                                                decrement={this.props.filterRes.decrement}
                                                eventName={this.props.filterRes.eventName}
                                                // pitcherDefault={this.state.pitcherDefault}
                                                eventProfileData={this.props.eventProfileData}
                                                sendReq={this.props?.sendReq}
                                                tabslist={this.props?.tabslist}
                                                profilepage={true}
                                                isNotDownloadImgLoader={this.props.isNotDownloadImgLoader}
                                                isDownloadImgLoader={this.props.isDownloadImgLoader}
                                            />
                                        </div>
                                        <div className="w-33">
                                            <HomePitcherCountSequencingChart
                                                chartData={this.props.filterRes.count_sequencing_chart_data}
                                                title="Count"
                                                id="countSequencingChartHomePitcher"
                                                showTitle={true}
                                                type="pitcherProfile"
                                                increment={this.props.filterRes.increment}
                                                decrement={this.props.filterRes.decrement}
                                                eventName={this.props.filterRes.eventName}
                                                // pitcherDefault={this.state.pitcherDefault}
                                                eventProfileData={this.props.eventProfileData}
                                                sendReq={this.props?.sendReq}
                                                tabslist={this.props?.tabslist}
                                                profilepage={true}
                                                isNotDownloadImgLoader={this.props.isNotDownloadImgLoader}
                                                isDownloadImgLoader={this.props.isDownloadImgLoader}
                                            />
                                        </div>
                                        <div className="w-33">
                                            <HomePitcherResultSequencingChart
                                                chartData={this.props.filterRes.result_sequencing_chart_data}
                                                title="Result"
                                                id="resultSequencingChartHomePitcher"
                                                showTitle={true}
                                                type="pitcherProfile"
                                                increment={this.props.filterRes.increment}
                                                decrement={this.props.filterRes.decrement}
                                                eventName={this.props.filterRes.eventName}
                                                // pitcherDefault={this.state.pitcherDefault}
                                                eventProfileData={this.props.eventProfileData}
                                                sendReq={this.props?.sendReq}
                                                tabslist={this.props?.tabslist}
                                                profilepage={true}
                                                isNotDownloadImgLoader={this.props.isNotDownloadImgLoader}
                                                isDownloadImgLoader={this.props.isDownloadImgLoader}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Plate Discipline Summary table (below sequencing charts) */}

                            <div className="table-responsive-xl">
                                <ReactTooltip place="top" type="dark" effect="solid" offset={{ top: -20, left: 0 }} />
                                <ReactTooltip place="top" type="light" className='tooltip-pitch' effect="solid" offset={{ top: -20, left: 0 }} id="insideImage"><img className='' src={imagesArr.inside}></img> </ReactTooltip>
                                <ReactTooltip place="top" type="light" className='tooltip-pitch' effect="solid" offset={{ top: -20, left: 0 }} id="lowImage"><img className='' src={imagesArr.low}></img> </ReactTooltip>
                                <ReactTooltip place="top" type="light" className='tooltip-pitch' effect="solid" offset={{ top: -20, left: 0 }} id="highImage"><img className='' src={imagesArr.high}></img> </ReactTooltip>
                                <ReactTooltip place="top" type="light" className='tooltip-pitch' effect="solid" offset={{ top: -20, left: 0 }} id="edgeImage"><img className='' src={imagesArr.edge}></img> </ReactTooltip>
                                <table className="table tabledashed">
                                    <thead>
                                        <tr>
                                            <th className="text-center pitch-outcomes">Type</th>
                                            <th className="text-center">#</th>
                                            <th className="text-center">%</th>
                                            <th className="text-center" data-tip="" data-for="insideImage">Inside%</th>
                                            <th className="text-center" data-tip="" data-for="lowImage">Low%</th>
                                            <th className="text-center" data-tip="" data-for="highImage">High%</th>
                                            <th className="text-center" data-tip="" data-for="edgeImage">Edge%</th>
                                            <th className="text-center" data-tip="1st Pitch Strike %">1PK%</th>
                                            <th className="text-center" data-tip="Called K, Swinging K, and Contact">Stike%</th>
                                            <th className="text-center" data-tip="Contact / All Swings">Contact%</th>
                                            <th className="text-center" data-tip="" data-for="zoneImage">Zone%</th>
                                            <th className="text-center">Z Swing%</th>
                                            <th className="text-center">Z Cont %</th>
                                            <th className="text-center" data-tip="Chase%">O Swing%</th>
                                            <th className="text-center pitchTable">O Cont %</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.renderPlateDisciplineTable()}
                                        {
                                            this.props.filterRes &&
                                            this.props.filterRes.first_summary_table &&
                                            this.props.filterRes.first_summary_table.length === 0 &&
                                            <tr>
                                                <td colSpan="15" className="text-center table-rowwhite">There's is no Info yet!</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>

                            {/* Pitch Type Strike Zones */}
                            <div className="common-charts">
                                <div className={`row ${!this.props.isPrinting ? 'singleDiv' : 'breakDiv'}`}>
                                    {this.props.filterRes && strikeZoneChartWithRedBox(this.props.filterRes.strike_zone_chart).filter((item) => item.hasOwnProperty('showInLegend') === false).map((item) => {
                                        return (
                                            <div className="w-33">
                                                {
                                                    constant.softball ?
                                                    <IndividualStrikeZoneChart
                                                        chartData={this.props.filterRes.strike_zone_chart}
                                                        id={`strikeZone_${item.name}`}
                                                        chartKey={item.name}
                                                        contourChartKey={item.name === 'FB' ? "Fastball" : item.name === 'DB' ? "Drop Ball" : item.name === 'RB' ? "Rise Ball" : item.name === 'CB' ? "Curveball" :
                                                            item.name === 'SB' ? "Screw Ball" : item.name==='CH'? "Changeup":"Drop Curve"}
                                                        contourData={this.props.filterRes.strike_zone_chart}
                                                        contourId={`countour_${item.name}`}
                                                        contour_graph={this.props.contour_graph}
                                                        chartName={item.name === 'FB' ? this.state.strikeZoneS_fb :item.name === 'DB' ? this.state.strikeZoneS_db : item.name === 'RB' ? this.state.strikeZoneS_rb : item.name === 'CB' ? this.state.strikeZoneS_cb : 
                                                        item.name === 'SB' ? this.state.strikeZoneS_sb : item.name === 'CH' ? this.state.strikeZoneS_ch : this.state.strikeZoneS_dc}
                                                        onChangeEvent={this.HandleChange}
                                                        cameraAngle={["Strike Zone", "Catcher", "Home Top", "Home 3B Side", "Home 1B Side", "Press Box"]}
                                                        visualType="Pitching"
                                                        eventProfileData={this.props.eventProfileData}
                                                        sendReq={this.props?.sendReq}
                                                        tabslist={this.props?.tabslist}
                                                        profilepage={true}
                                                        isNotDownloadImgLoader={this.props.isNotDownloadImgLoader}
                                                        isDownloadImgLoader={this.props.isDownloadImgLoader}
                                                        pitchrIframeFlag={this.props.pitchrIframeFlag}
                                                        closePitchrIframe={this.props.closePitchrIframe} />
                                                        :
                                                        <IndividualStrikeZoneChart
                                                        chartData={this.props.filterRes.strike_zone_chart}
                                                        id={`strikeZone_${item.name}`}
                                                        chartKey={item.name}
                                                        contourChartKey={item.name === 'FB' ? "Fastball" : item.name === 'SI' ? "Sinker" : item.name === 'CT' ? "Cutter" : item.name === 'CB' ? "Curveball" :
                                                            item.name === 'CH' ? "Changeup" : item.name==='SL'? "Slider":item.name==='SP'? "Splitter":"Knuckleball"}
                                                        contourData={this.props.filterRes.strike_zone_chart}
                                                        contourId={`countour_${item.name}`}
                                                        contour_graph={this.props.contour_graph}
                                                        chartName={item.name === 'FB' ? this.state.strikeZoneB_fb :item.name === 'SI' ? this.state.strikeZoneB_si : item.name === 'CT' ? this.state.strikeZoneB_cu : item.name === 'CB' ? this.state.strikeZoneB_cb : 
                                                        item.name === 'CH' ? this.state.strikeZoneB_ch : item.name === 'SL' ? this.state.strikeZoneB_sl : item.name === 'SP' ? this.state.strikeZoneB_sp : this.state.strikeZoneB_kb}
                                                        onChangeEvent={this.HandleChange}
                                                        cameraAngle={["Strike Zone", "Catcher", "Home Top", "Home 3B Side", "Home 1B Side", "Press Box"]}
                                                        visualType="Pitching"
                                                        eventProfileData={this.props.eventProfileData}
                                                        sendReq={this.props?.sendReq}
                                                        tabslist={this.props?.tabslist}
                                                        profilepage={true}
                                                        isNotDownloadImgLoader={this.props.isNotDownloadImgLoader}
                                                        isDownloadImgLoader={this.props.isDownloadImgLoader}
                                                        pitchrIframeFlag={this.props.pitchrIframeFlag}
                                                        closePitchrIframe={this.props.closePitchrIframe} />
                                                }
                                            </div>
                                        )}
                                    )}
                                </div>
                                </div>

                            {/* Break and Movement Table */}

                            <div className="table-responsive-xl">
                                <table className="table tabledashed">
                                    <thead>
                                        <tr>
                                            <th colSpan="5" className="text-center"></th>
                                            <th colSpan="2" className="text-center">Spin Direction</th>
                                            <th colSpan="3" className="text-center">Break and Movement</th>
                                            <th colSpan="2" className="text-center pitchTable">Approach Angle</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="tablerowgray fw-500">Pitch Type</td>
                                            <td className="tablerowgray fw-500">#</td>
                                            <td className="tablerowgray fw-500">Velo</td>
                                            <td className="tablerowgray fw-500">Spin</td>
                                            <td className="tablerowgray fw-500">Efficiency</td>
                                            <td className="tablerowgray fw-500">Tilt</td>
                                            <td className="tablerowgray fw-500">Axis</td>
                                            <td className="tablerowgray fw-500">V Break</td>
                                            <td className="tablerowgray fw-500">Induced V</td>
                                            <td className="tablerowgray fw-500">H Break</td>
                                            <td className="tablerowgray fw-500">V Approach</td>
                                            <td className="tablerowgray fw-500">H Approach</td>
                                        </tr>
                                        {this.renderMatrixSummaryTable()}
                                        {
                                            this.props.filterRes &&
                                            this.props.filterRes.matrics_summary_table_data &&
                                            this.props.filterRes.matrics_summary_table_data.length === 0 &&
                                            <tr>
                                                <td colSpan="12" className="text-center table-rowwhite">There's is no Info yet!</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default pitcharsenal;
