import React, { Component } from 'react';

class tabPanel extends Component {
    render() {
        const { tabslist, filterIndex, profileList } = this.props;
        return (
            <div className="tabshead">
                <ul className="tabviewbtn d-flex">
                    {localStorage.getItem("activeType") === "Pitching" && (
                        <li
                            className={`${tabslist === 0 ? "active" : ""}`}
                            onClick={() => this.props.handleTabs(0)}
                        >
                            <span className="btnhead">Pitch Outcomes</span>
                        </li>
                    )}
                    <li
                        className={`${tabslist === 1 ? "active" : ""}`}
                        onClick={() => this.props.handleTabs(1)}
                    >
                        <span className="btnhead">Batted Ball</span>
                    </li>
                    {localStorage.getItem("activeType") !== "Pitching" && (
                        <li
                            className={`${tabslist === 2 ? "active" : ""}`}
                            onClick={() => this.props.handleTabs(2)}
                        >
                            <span className="btnhead">Plate Discipline</span>
                        </li>
                    )}
                    {localStorage.getItem("activeType") === "Pitching" && (
                        <>
                            <li
                                className={`${tabslist === 3 ? "active" : ""}`}
                                onClick={() => this.props.handleTabs(3)}
                            >
                                <span className="btnhead">Break & Movement</span>
                            </li>
                            <li
                                className={`${tabslist === 4 ? "active" : ""}`}
                                onClick={() => this.props.handleTabs(4)}
                            >
                                <span className="btnhead">Release Point</span>
                            </li>
                        </>
                    )}
                    <li
                        className={`${tabslist === 8 ? "active" : ""}`}
                        onClick={() => this.props.handleTabs(8)}
                    >
                        <span className="btnhead">Heat Maps</span>
                    </li>
                    {localStorage.getItem("activeType") !== "Pitching" && (
                        <li
                            className={`${tabslist === 7 ? "active" : ""}`}
                            onClick={() => this.props.handleTabs(7)}
                        >
                            <span className="btnhead">Point of Contact</span>
                        </li>
                    )}
                    {localStorage.getItem("activeType") !== "Pitching" && (
                        <li
                            className={`${tabslist === 6 ? "active" : ""}`}
                            onClick={() => this.props.handleTabs(6)}
                        >
                            <span className="btnhead">Blast Motion</span>
                        </li>
                    )}
                    {localStorage.getItem("activeType") !== "Pitching" && (
                        <li
                            className={`${tabslist === 9 ? "active" : ""}`}
                            onClick={() => this.props.handleTabs(9)}
                        >
                            <span className="btnhead">Catching</span>
                        </li>
                    )}
                    <li
                        className={`${tabslist === 5 ? "active" : ""}`}
                        onClick={() => this.props.handleTabs(5)}
                    >
                        <span className="btnhead">Plan</span>
                    </li>
                </ul>

                <div className="lefe-right-page">
                    <span
                        className={`btn blue  mr-15 ${filterIndex === 0 && "disable"
                            }`}
                        onClick={() =>
                            filterIndex === 0
                                ? ""
                                : this.props.handlePagination("prev")
                        }
                    >
                        {" "}
                        <i className="fas fa-angle-left"></i>{" "}
                    </span>
                    <span
                        className={`btn blue ${profileList.length - 1 ===
                            filterIndex && "disable"
                            } `}
                        onClick={() =>
                            profileList.length - 1 ===
                                filterIndex
                                ? ""
                                : this.props.handlePagination("next")
                        }
                    >
                        {" "}
                        <i className="fas fa-angle-right"></i>{" "}
                    </span>
                </div>
            </div>
        );
    }
}

export default tabPanel;
