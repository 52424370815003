import PitcherTable from './pitcherTable';
import SecondBullPenTable from './secondBullpenTable';
import PitcherReleasePointChart from './../../../components/Charts/releasePointChart';
import PitcherBreakMovementChart from './../../../components/Charts/breakMovementChart';
import PitcherSideReleaseChart from './../../../components/Charts/sideReleaseChart';
import PitcherStrikeZoneChart from './../../../components/Charts/strikeZoneChart';
import { xformBreakMovementChart } from '../../../shared/function';
import PolarChart from '../../../components/Charts/PolarChart';

function Spierscarson({ keyName, secondBullpenList, pitcherList, scatterList, releaseList, breakList, breakListLong, dotchart, 
  dotchartLong, polarList, sideReleaseList, firstBullpenList, setLoader, index, isprint, isprintAll, ballrIframeFlag, closeBallrIframe,
  pitchrIframeFlag, closePitchrIframe, isNotDownloadImgLoader, isDownloadImgLoader, eventName }) {

  const bubbleChartDataShort =
    breakList[keyName] ? breakList[keyName].length !== 0
      ? xformBreakMovementChart(breakList[keyName], 'pitch_type')
      : [] : [];

  const bubbleChartDataLong =
    breakListLong[keyName] ? breakListLong[keyName].length !== 0
      ? xformBreakMovementChart(breakListLong[keyName], 'pitch_type')
      : [] : [];

  const dotChartDataShort =
    dotchart[keyName] && dotchart[keyName].length !== 0
      ? xformBreakMovementChart(dotchart[keyName], 'pitch_type')
      : [];

  const dotChartDataLong =
    dotchartLong[keyName] && dotchartLong[keyName].length !== 0
      ? xformBreakMovementChart(dotchartLong[keyName], 'pitch_type')
      : [];

  return (
    <>
      <div className="container-fluid" id="pitcherChartPrintArea">
        <div className="common-charts">
          {/* <div className="row"> */}
          <div className={`row ${!(isprint || isprintAll) ? 'singleDiv' : 'breakDiv'}`}>
            <div className="w-33">
              <PitcherStrikeZoneChart
                chartData={scatterList[keyName] || []}
                index={index}
                id={`strikeZoneChart${index || ""}`}
                showTitle={true}
                ballrIframeFlag={ballrIframeFlag}
                closeBallrIframe={closeBallrIframe}
                cameraAngle={["Strike Zone", "Catcher", "Home Top", "Home 3B Side", "Home 1B Side", "Press Box"]}
                visualType="Pitching"
                pitchrIframeFlag={pitchrIframeFlag}
                closePitchrIframe={closePitchrIframe}
                pitcherDefault={keyName}
                profilepage={false}
                eventName={eventName}
                isNotDownloadImgLoader={isNotDownloadImgLoader}
                isDownloadImgLoader={isDownloadImgLoader} />
            </div>
            <div className="w-33">
              <PitcherReleasePointChart
                chartData={releaseList[keyName] || []}
                index={index}
                id={`releasePointChart${index}`}
                showTitle={true}
                ballrIframeFlag={ballrIframeFlag}
                closeBallrIframe={closeBallrIframe}
                cameraAngle={["Pitcher", "Mound Top", "Mound 1B Side", "Mound 3B Side", "First Base", "Third Base"]}
                visualType="Release"
                pitchrIframeFlag={pitchrIframeFlag}
                closePitchrIframe={closePitchrIframe}
                pitcherDefault={keyName}
                profilepage={false}
                eventName={eventName}
                isNotDownloadImgLoader={isNotDownloadImgLoader}
                isDownloadImgLoader={isDownloadImgLoader} />
            </div>
            <div className="w-33">
              <PitcherSideReleaseChart
                chartData={sideReleaseList[keyName] || []}
                index={index}
                id={`sideReleaseChart${index}`}
                showTitle={true}
                ballrIframeFlag={ballrIframeFlag}
                closeBallrIframe={closeBallrIframe}
                cameraAngle={["Mound 3B Side", "Mound 1B Side", "Mound Top", "Pitcher", "Third Base", "First Base"]}
                visualType="Release"
                pitchrIframeFlag={pitchrIframeFlag}
                closePitchrIframe={closePitchrIframe}
                pitcherDefault={keyName}
                profilepage={false}
                eventName={eventName}
                isNotDownloadImgLoader={isNotDownloadImgLoader}
                isDownloadImgLoader={isDownloadImgLoader} />
            </div>
          </div>
        </div>
        <div className="common-charts">
          <div className={`row ${!(isprint || isprintAll) ? 'singleDiv' : 'breakDiv'}`}>
            <div className="w-33">
              <PitcherBreakMovementChart
                id={`longBreakMovementChart${index || ""}`}
                form="Long-Form"
                top={55}
                bottom={15}
                className="breakMovementChart"
                bubbleChartData={bubbleChartDataLong}
                dotChartData={dotChartDataLong}
                ballrIframeFlag={ballrIframeFlag}
                closeBallrIframe={closeBallrIframe}
                pitcherDefault={keyName}
                profilepage={false}
                eventName={eventName}
                isNotDownloadImgLoader={isNotDownloadImgLoader}
                isDownloadImgLoader={isDownloadImgLoader} />
            </div>
            <div className="w-33">
              <PitcherBreakMovementChart
                id={`shortBreakMovementChart${index || ""}`}
                form="Short-Form"
                top={30}
                bottom={55}
                className="breakMovementChart"
                bubbleChartData={bubbleChartDataShort}
                dotChartData={dotChartDataShort}
                ballrIframeFlag={ballrIframeFlag}
                closeBallrIframe={closeBallrIframe}
                pitcherDefault={keyName}
                profilepage={false}
                eventName={eventName}
                isNotDownloadImgLoader={isNotDownloadImgLoader}
                isDownloadImgLoader={isDownloadImgLoader} />
            </div>
            <div className="w-33">
              <PolarChart
                chartData={polarList[keyName] || []}
                index={index}
                id={`polarChart${index}`}
                ballrIframeFlag={ballrIframeFlag}
                closeBallrIframe={closeBallrIframe}
                pitcherDefault={keyName}
                profilepage={false}
                eventName={eventName}
                isNotDownloadImgLoader={isNotDownloadImgLoader}
                isDownloadImgLoader={isDownloadImgLoader} />
            </div>
            {/* <div className="w-33 common-charts mb-0">
                  <div className="ground-chart">
                    <div className="legends graph-select text-left">
                      <div className="text-left d-inline">
                        <h3 className="mb-0">Break and Movement</h3>
                      </div>
                      <div className="select-box ">
                        <select
                          name="play_result"
                          className="custom-select"
                          value={chartType}
                          onChange={(e) => setChartType(e.target.value)}
                        >
                          <option value="Clusters">Clusters</option>
                          <option value="Pitches">Pitches</option>
                        </select>
                      </div>
                      <div className="select-box ">
                        <select
                          name="play_result"
                          className="custom-select"
                          value={form}
                          onChange={(e) => setForm(e.target.value)}
                        >
                          <option value="Short-Form">Short-Form</option>
                          <option value="Long-Form">Long-Form</option>
                        </select>
                      </div>
                    </div>
                    {breakList[keyName].length !== 0 ? (
                    <div id={`breakMovementChart${index || ""}`} className="breakMovementChart" />
                     ) : (
                      <NoInfo />
                    )} 
                  </div>
              </div>     */}
          </div>
        </div>
      </div>
      {Object.keys(firstBullpenList).length !== 0 && (
        <PitcherTable
          tableData={firstBullpenList[keyName]}
          type="spicerscarson"
        />
      )}

      {Object.keys(secondBullpenList).length !== 0 && (
        <SecondBullPenTable tableData={secondBullpenList[keyName]} />
      )}
    </>
  );
}

export default Spierscarson;