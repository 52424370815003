
import logo from './logo/BCTeam.png';
import softball_logo from './logo/SCTeam.png';
import bc_print from './logo/bc_print.png';
import sc_print from './logo/sc_print.png';
import iconfilter from './common/icon-filter.svg';
import iconclose from './common/icon-close.svg';
import icontable from './common/icontable.png';
import rightarrow from './common/rightarrow.svg';
import printer from './common/printer.svg';
import search from './common/search.svg';
import tablefilter from './common/tablefilter.svg';
import major_logo from './dashborad/major_logo.png';
import green_up from './common/green_up.svg';
import red_down from './common/red_down.svg';
import tablefilter_black from './common/tablefilter_black.svg';
import calendar from './common/calendar.svg';
import defaultTeamLogo from './common/defaultTeamLogo.jpg';

import playerprofile from './profile/playerprofile.png';

import editicon from './common/editicon.svg';
import yt from './device/yt.png';
import yakkertech from './device/Yakkertech.png';
import flightScope from './device/fs.png';
import trackMan from './device/tm.png';
import Blast from './device/Blast.png';
import rapsodo from './device/rapsodo.png';
import Hittrax from './device/Hittrax.jpeg';
import Savant from './device/Savant.png';
import refresh from './common/refresh.svg';
import nograph from './common/nograph.png';
import Ball from './common/Ball.svg';
import CalledStrike from './common/CalledStrike.svg';
import HalffilledCircle from './common/HalffilledCircle.svg';
import Quarterfilledcircle from './common/Quarterfilledcircle.svg';
import Sideways from './common/Sideways.svg';
import triple from './common/triple.svg';
import close from './common/close.svg';
import toggle_icon from './common/toggle_icon.png'
import chartDownload from './common/chartDownload.png'
import zone from './common/zone.png'
import edge from './common/edge.png'
import low from './common/low.png'
import inside from './common/inside.png'
import high from './common/high.png'
import pText from './common/pText.png'
import cText from './common/ctext.png'

export const imagesArr = {
    logo, softball_logo, iconfilter, iconclose, icontable, rightarrow, printer, major_logo, search, tablefilter, green_up, red_down, tablefilter_black,
    playerprofile, bc_print, sc_print,
    editicon, calendar, defaultTeamLogo,
    yt, yakkertech, flightScope, trackMan, Blast, rapsodo, Hittrax, Savant, refresh, nograph,
    Ball, CalledStrike, HalffilledCircle, Quarterfilledcircle, Sideways, triple, toggle_icon, chartDownload,zone,edge,low,inside,high,pText,cText
}
