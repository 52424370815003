import axios from "axios";
import { constant } from "./constant.js";
import Cookies from 'js-cookie';

const api = axios.create();

const statusCheck = {
    validateStatus: (status) => {
        if (status === 401) {
            // window.location.reload(true)
        } else {
            return true;
        }
    }
}

function onError(response) {
    return response;
}

function onSuccess(response) {
    return response;
}



api.defaults.baseURL = constant.API_URL;
api.defaults.headers.common['x-auth-token'] = Cookies.get('bcSession', { domain: process.env.REACT_APP_COOKIE_DOMAIN }); // 4e5613a8215d6f50d4fbb9e351d2c56f
// api.defaults.headers.common['x-auth-token'] = "4e5613a8215d6f50d4fbb9e351d2c56f"; // 4e5613a8215d6f50d4fbb9e351d2c56f
api.defaults.headers.post["Content-Type"] = "application/json";
api.defaults.headers.post["Accept"] = "application/json";

console.log("from service:---", Cookies.get('bcSession', { domain: process.env.REACT_APP_COOKIE_DOMAIN }));

export const dashboardService = {
    BCTeamDashboardTeamData: data => api.post("bcteam/dashboard/myteam", data, statusCheck).then(onSuccess, onError),
    // BCTeamGameData: data => api.post("bcteam/event/game/data", data, statusCheck).then(onSuccess, onError),
    BCTeamGameData: data => api.post("bcteam/event/game/v2/data", data, statusCheck).then(onSuccess, onError),
    BCTeamBlastData: data => api.post("bcteam/event/blast/data", data, statusCheck).then(onSuccess, onError),
    BCTeamScrimmageData: data => api.post("bcteam/event/scrimmage/data", data, statusCheck).then(onSuccess, onError),
    BCTeamSummaryData: data => api.post("bcteam/team-summary", data, statusCheck).then(onSuccess, onError),
    BCHitterSummaryData: data => api.post("bcteam/event/bp/data", data, statusCheck).then(onSuccess, onError),
    BCPitcherSummaryData: data => api.post("bcteam/event/bullpen/data", data, statusCheck).then(onSuccess, onError),
    BCDevelopment: data => api.post("bcteam/development", data, statusCheck).then(onSuccess, onError),
    BCDevelopmentSearch: data => api.post("bcteam/development/player-search", data, statusCheck).then(onSuccess, onError),
    BCDevelopmentplayerData: data => api.post("bcteam/development/player-data", data, statusCheck).then(onSuccess, onError),
    BCTeamProfileDashboard: data => api.post("bcteam/development/player-info", data, statusCheck).then(onSuccess, onError),
    // BCTeamProfileDashboard: data => api.post("bcteam/development/player-search", data, statusCheck).then(onSuccess, onError),
    BCTeamProfileFilter: (data, uid) => api.post(`bcteam/profile/bc-profile/${uid}`, data, statusCheck).then(onSuccess, onError),
    BCTeamProfileEvent: data => api.post("bcteam/development/player-data", data, statusCheck).then(onSuccess, onError),
    BCTeamAllPlayer: data => api.post("bcteam/development/player-search", data, statusCheck).then(onSuccess, onError),
    BCTeamPlanData: data => api.post("bcteam/profile/plan/save", data, statusCheck).then(onSuccess, onError),
    BCTeamGetPlanData: data => api.post("bcteam/profile/plan/get", data, statusCheck).then(onSuccess, onError),
    BCTeamUser: () => api.get("bcteam/user/verfiy/token", statusCheck).then(onSuccess, onError),
    InsightData: (data) => api.post("/bcteam/dashboard/teamInsights", data, statusCheck).then(onSuccess, onError),
};
