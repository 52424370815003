import { dashboardService } from "../shared/services";

// for load dashboard data
export function getDashboardData(data, next) {
  return (dispatch) => {
    dispatch({ type: "LOADING", payload: true });

    dashboardService
      .BCTeamDashboardTeamData(data)
      .then((res) => {
        // dispatch({ type: "LOADING", payload: false });
        dispatch({ type: "DASHBOARD_TEAM_DATA", payload: res.data.result });
        next(res.data.result);
      })
      .catch((e) => {});
  };
}


// for load Game Summary data when click on dame type box
export function getGameSummaryData(data, next) {
  return (dispatch) => {
      dispatch({ type: "LOADING", payload: true });
    // if(data.printflag === false) {
    // } else {
    //   dispatch({ type: "LOADING", payload: false });
    // }
      
    dashboardService
      .BCTeamGameData(data)
      .then((res) => {
        dispatch({ type: "LOADING", payload: false });
        dispatch({ type: "GAME_SUMMARY_DATA", payload: res.data.result });
        next(res.data.result);
      })
      .catch((e) => {});
  };
}

// for load blast device data when click on blast device box
export function getBlastData(data, next) {
  return (dispatch) => {
    dispatch({ type: "LOADING", payload: true });

    dashboardService
      .BCTeamBlastData(data)
      .then((res) => {
        dispatch({ type: "LOADING", payload: false });
        dispatch({ type: "BLAST_SUMMARY_DATA", payload: res.data.result });
        next(res.data.result);
      })
      .catch((e) => { });
  };
}

// for load Scrimmage Summary data click on Scrimmage event
export function getScrimmageSummaryData(data, next) {
  return (dispatch) => {
      dispatch({ type: "LOADING", payload: true });
    // if(data.printflag === false) {
    // } else {
    //   dispatch({ type: "LOADING", payload: false });
    // }

    dashboardService
      .BCTeamScrimmageData(data)
      .then((res) => {
        dispatch({ type: "LOADING", payload: false });
        dispatch({ type: "SCRIMMAGE_SUMMARY_DATA", payload: res.data.result });
        next(res.data.result);
      })
      .catch((e) => {});
  };
}

// for load team Summary data when click on dame type box
export function getTeamSummaryData(data, next) {
  return (dispatch) => {
    dispatch({ type: "LOADING", payload: true });

    dashboardService
      .BCTeamSummaryData(data)
      .then((res) => {
        dispatch({ type: "LOADING", payload: false });
        dispatch({ type: "TEAM_SUMMARY_DATA", payload: res.data.result });
        next(res.data.result);
      })
      .catch((e) => {});
  };
}

// for load Hitter Summary data when click on dame type box
export function getHitterSummaryData(data, next) {
  return (dispatch) => {
    dispatch({ type: "LOADING", payload: true });

    dashboardService
      .BCHitterSummaryData(data)
      .then((res) => {
        dispatch({ type: "LOADING", payload: false });
        dispatch({ type: "HITTER_SUMMARY_DATA", payload: res.data.result });
        next(res.data.result);
      })
      .catch((e) => {});
  };
}

// for load pitcher Summary data when click on dame type box
export function getPitcherSummaryData(data, next) {
  return (dispatch) => {
    dispatch({ type: "LOADING", payload: true });

    dashboardService
      .BCPitcherSummaryData(data)
      .then((res) => {
        dispatch({ type: "LOADING", payload: false });
        dispatch({ type: "PITCHER_SUMMARY_DATA", payload: res.data.result });
        next(res.data.result);
      })
      .catch((e) => {});
  };
}

// for load profile dashboard data when click on main dashbopard sidebar
export function getProfileData(data, next) {
  return (dispatch) => {
    dispatch({ type: "LOADING", payload: true });

    dashboardService
      .BCTeamProfileDashboard(data)
      .then((res) => {
        // dispatch({ type: "LOADING", payload: false });
        dispatch({ type: "PROFILE_DASHBOARD_DATA", payload: res.data.result });
        next(res.data.result);
      })
      .catch((e) => {});
  };
}

// for load profile filter data
export function getFilterData(data, uid, next) {
  return (dispatch) => {
    dispatch({ type: "LOADING", payload: true });

    dashboardService
      .BCTeamProfileFilter(data, uid)
      .then((res) => {
        // dispatch({ type: "LOADING", payload: false });
        dispatch({ type: "PROFILE_FILTER_DATA", payload: res.data.result });
        next(res.data.result);
      })
      .catch((e) => {});
  };
}

// for load profile filter data
export function getEventData(data, next) {
  return (dispatch) => {
    dispatch({ type: "LOADING", payload: true });

    dashboardService
      .BCTeamProfileEvent(data)
      .then((res) => {
        // dispatch({ type: "LOADING", payload: false });
        dispatch({ type: "PROFILE_EVENTS_DATA", payload: res.data.result });
        next(res.data.result);
      })
      .catch((e) => {});
  };
}

// for load all profile data
export function getAllPlayerData(data, next) {
  return (dispatch) => {
    // dispatch({ type: "LOADING", payload: true });

    dashboardService
      .BCTeamAllPlayer(data)
      .then((res) => {
        // dispatch({ type: "LOADING", payload: false });
        dispatch({ type: "ALL_PLAYER_DATA", payload: res.data.result });
        next(res.data.result);
      })
      .catch((e) => {});
  };
}

// for load development Summary data when click on dame type box
export function getDevelopmentSummary(data, next) {
  return (dispatch) => {
    dispatch({ type: "LOADING", payload: true });

    dashboardService
      .BCDevelopment(data)
      .then((res) => {
        dispatch({ type: "LOADING", payload: false });
        dispatch({
          type: "DEVELOPMENT_SUMMARY_DATA",
          payload: res.data.result,
        });
        next(res.data.result);
      })
      .catch((e) => {});
  };
}

// for load delevopment data when click on dame type box
export function getDevelopmentSearch(data, next) {
  return (dispatch) => {
    dispatch({ type: "LOADING", payload: true });

    dashboardService
      .BCDevelopmentSearch(data)
      .then((res) => {
        dispatch({ type: "LOADING", payload: false });
        dispatch({ type: "DEVELOPMENT_SEARCH_DATA", payload: res.data.result });
        next(res.data.result);
      })
      .catch((e) => {});
  };
}

// for load delevopment player data when click on dame type box
export function getDevelopmentPlayerData(data, next) {
  return (dispatch) => {
    dispatch({ type: "LOADING", payload: true });

    dashboardService
      .BCDevelopmentplayerData(data)
      .then((res) => {
        dispatch({ type: "LOADING", payload: false });
        dispatch({ type: "DEVELOPMENT_PLAYER_DATA", payload: res.data.result });
        next(res.data.result);
      })
      .catch((e) => {});
  };
}

export function setPlanData(data, next) {
  return (dispatch) => {
    dispatch({ type: "LOADING", payload: true });

    dashboardService
      .BCTeamPlanData(data)
      .then((res) => {
        dispatch({ type: "LOADING", payload: false });
        dispatch({ type: "PLAN_PLAYER_DATA", payload: res.data.result });
        next(res.data.result);
      })
      .catch((e) => {});
  };
}

export function getPlanData(data, next) {
  return (dispatch) => {
    dispatch({ type: "LOADING", payload: true });

    dashboardService
      .BCTeamGetPlanData(data)
      .then((res) => {
        dispatch({ type: "LOADING", payload: false });
        dispatch({ type: "GET_PLAN_PLAYER_DATA", payload: res.data.result });
        next(res.data.result);
      })
      .catch((e) => {});
  };
}

// for user role
export function getUserRoleData(next) {
  return (dispatch) => {
    dispatch({ type: "LOADING", payload: true });

    dashboardService
      .BCTeamUser()
      .then((res) => {
        // dispatch({ type: "LOADING", payload: false });
        dispatch({ type: "USER_ROLE", payload: res.data.result });
        next(res.data.result);
      })
      .catch((e) => {});
  };
}


// for insights data
export function getInsightData(data,next) {
  return (dispatch) => {
    dispatch({ type: "LOADING", payload: true });

    dashboardService
      .InsightData(data)
      .then((res) => {
        dispatch({ type: "LOADING", payload: false });
        dispatch({ type: "INSIGHTS_DATA", payload: res.data.result });
        next(res.data.result);
      })
      .catch((e) => {});
  };
}
