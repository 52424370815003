import { constant } from "./constant";
import * as d3 from "d3";
import range from "lodash/fp/range";
import find from "lodash/fp/find";
import minBy from "lodash/fp/minBy";
import maxBy from "lodash/fp/maxBy";
import uniqBy from "lodash/fp/uniqBy";
import filter from "lodash/fp/filter";
import flatten from "lodash/fp/flatten";
import round from "lodash/round";
import Null from "lodash/isNull";

import AntdPercentageColumn from "../components/AntdPercentageColumn";
import { colors, pitchTypeOrder } from "./comman";
import { toast } from "react-toastify";
import moment from "moment";
import "react-toastify/dist/ReactToastify.css";

// remove null value in array
export const removeNullEntryFromArrayOfObjects = (data) => {
  return data && data.filter((item) => item !== null);
};

// set unique value in array
export const uniqueEntriesFromArray = (data, entryName) => [
  ...new Set(data && data.map((obj) => obj[entryName])),
];

// check value is null or not
function isNull() {
  for (var i = 0; i < arguments.length; i++) {
    if (
      typeof arguments[i] !== "undefined" &&
      arguments[i] !== undefined &&
      arguments[i] != null &&
      arguments[i] != NaN &&
      arguments[i]
    )
      return arguments[i];
  }
}

// filter plot null values
export const filterScatterPlotNullValues = (data) => {
  return data && data.filter((obj) => !Null(obj.x) && !Null(obj.y));
};

export const betweenAndEqual = (x, min, max) => x >= min && x <= max;

export const getActiveTabData = (activeTab, data) => {
  switch (activeTab) {
    case 0:
      return data.strike_zone_chart;
    case 1:
      return data.batted_ball_scatter_plot;
    case 2:
      return data.plate_discipline_scatter_plot;
    case 4:
      return data.break_movement_strike_zone;
    default:
      return "";
  }
};

// filter dot chart data
// export const filterDotChart = (rec, roundNumber = 1) => {
//   let finalData;
//   let newData = [];
//   uniqueData(rec, (data) => {
//     data.newArr.map((item, i) => {
//       data.oldArr.map((items, j) => {
//         if (item.name === items.pitch_type && items.velo) {
//           newData.push(round(items.velo , roundNumber));
//         }
//       });
//       Object.assign(item, { data: newData });
//       newData = [];
//     });
//     finalData = data.newArr;
//   });
//   return finalData;
// };

// function uniqueData(chartData, cb) {
//   var unique = [];
//   var valocityArr = [];
//   chartData.map((value, i) => {
//     if (unique.indexOf(value.pitch_type) === -1) {
//       if (value.pitch_type !== "Undefined") {
//         let data = {
//           name: value.pitch_type,
//           lineColor: constant.pitchTypeColor[value.pitch_type],
//           color: constant.pitchTypeColor[value.pitch_type],
//           marker: {
//             symbol: "circle",
//             enabled: true,
//           },
//           data: value.velo,
//         };
//         valocityArr.push(data);
//       }
//     }
//     unique.push(value.pitch_type);
//     if (chartData.length - 1 === i) {
//       let data = {
//         newArr: valocityArr,
//         oldArr: chartData,
//       };
//       cb(data);
//     }
//   });
// }

// spline rolling velocity chart for game
export const filterDotChart = (chartData) => {
  let returnSplineData = [];
  const removeNullEntriesFromChartData = removeNullEntryFromArrayOfObjects(
    chartData
  );
  const uniqueNames = uniqueEntriesFromArray(
    removeNullEntriesFromChartData,
    "pitch_type"
  );

  // Tables Data For Each Player From Complete Data
  uniqueNames.map((val) => {
    const filteredRecords = removeNullEntriesFromChartData.filter(
      (record) => record.pitch_type === val
    );
    const batterData = filteredRecords.map((record) => ({
      spin_rate: record.spin_rate,
      ind_v_break: record.ind_v_break,
      ext: record.ext,
      h_break: record.h_break,
      pitch_number: record.pitch_number,
      pitch_type: record.pitch_type,
      pitcher_name: record.pitcher_name,
      pitcher_team: record.pitcher_team,
      release_height: record.release_height,
      release_side: record.release_side,
      spin_tilt: record.spin_tilt,
      velo: record.velo,
    }));
    const playerName = uniqueEntriesFromArray(filteredRecords, "pitch_type");

    returnSplineData.push({
      name: constant.pitchTypeAbbrevations[playerName[0]],
      data: batterData.map((row) => ({ ...row, y: parseFloat(row.velo) })),
      color: constant.pitchTypeColor[playerName],
    });
  });
  return returnSplineData;
};

// pitcher spline rolling velocity chart for scrimmage
export const filterDotChartWithFilter = (chartData, spline_chart) => {
  let returnSplineData = [];
  const removeNullEntriesFromChartData = removeNullEntryFromArrayOfObjects(
    chartData
  );
  const uniqueNames = uniqueEntriesFromArray(
    removeNullEntriesFromChartData,
    "pitch_type"
  );

  // Tables Data For Each Player From Complete Data
  uniqueNames.map((val) => {
    const filteredRecords = removeNullEntriesFromChartData.filter(
      (record) => record.pitch_type === val
    );
    const batterData = filteredRecords.map((record) => ({
      spin_rate: record.spin_rate,
      ind_v_break: record.ind_v_break,
      ext: record.ext,
      h_break: record.h_break,
      v_break: record.v_break,
      pitch_number: record.pitch_number,
      pitch_type: record.pitch_type,
      pitcher_name: record.pitcher_name,
      pitcher_team: record.pitcher_team,
      release_height: record.release_height,
      release_side: record.release_side,
      spin_tilt: record.spin_tilt,
      velo: record.velo,
      efficiency: record.efficiency,
      pitcher: record.pitcher,
      batter: record.batter,
      pitch_call: record.pitch_call,
      spin_axis: record.spin_axis,
      exit_velocity: record.exit_velocity,
      launch_angle: record.launch_angle,
      distance: record.distance,
      spinDirDeg: record.spinDirDeg,
      gyroAngle: record.gyroAngle,
      vert_appr_angle: record.vert_appr_angle,
      date:record.date,
      batter_team:record.batter_team,
      _id: record._id,
    }));
    const playerName = uniqueEntriesFromArray(filteredRecords, "pitch_type");
    const ySpline = constant.splineChartKey[spline_chart]
    const batterDataFilter = batterData.filter((e) => e[ySpline] !== undefined && e[ySpline] !== 0 && e[ySpline] !== null)
    returnSplineData.push({
      name: constant.pitchTypeAbbrevations[playerName[0]],
      data: batterDataFilter.map((row) => ({ ...row, y: parseFloat(row[ySpline]) })),
      color: constant.pitchTypeColor[playerName],
    });
  });
  return returnSplineData;
};

// Blast spline rolling velocity chart
export const BlastfilterDotChartWithFilter = (chartData, spline_chart) => {
  let returnSplineData = [];
  const removeNullEntriesFromChartData = removeNullEntryFromArrayOfObjects(
    chartData
  );
  const batterData = removeNullEntriesFromChartData.map((record) => ({
    batter_name: record.batter_name,
    bat_speed: record.bat_speed,
    plane: record.plane,
    connection: record.connection,
    rotation: record.rotation,
    rotational_acceleration: record.rotational_acceleration,
    on_plane_efficiency: record.on_plane_efficiency,
    attack_angle: record.attack_angle,
    early_connection: record.early_connection,
    connection_at_impact: record.connection_at_impact,
    vertical_bat_angle: record.vertical_bat_angle,
    power: record.power,
    time_to_contact: record.time_to_contact,
    peak_hand_speed: record.peak_hand_speed
  }));
  const batterDataFilter = batterData.filter((e) => e[spline_chart] !== undefined)
  returnSplineData.push({
    // name: constant.pitchTypeAbbrevations[playerName[0]],
    data: batterDataFilter.map((row) => ({ ...row, y: parseFloat(row[spline_chart]) })),
    color: '#013662',
  });
  return returnSplineData;
};

// filter sequencing charts
export const filterSequenceChart = (rec, pitchTypes) => {
  let finalData;
  let newData = [];
  let newCount = [];
  let newAvgEv = [];
  let newSM = [];
  let newChase = [];
  let groupedDataPoint = groupArrayOfObjects(rec, "data_point");
  sqquenceUniqueData(rec, (data) => {
    data.newArr.map((item, i) => {
      Object.keys(groupedDataPoint).map((items, j) => {
        if (!!groupedDataPoint[items].find((e) => constant.pitchTypeAbbrevations[e.pitch_type] === item.name)) {
          newData.push(
            groupedDataPoint[items].find((e) => constant.pitchTypeAbbrevations[e.pitch_type] === item.name)
              .value
              ? groupedDataPoint[items].find((e) => constant.pitchTypeAbbrevations[e.pitch_type] === item.name)
                .value
              : 0
          );
          newCount.push(
            groupedDataPoint[items].find((e) => constant.pitchTypeAbbrevations[e.pitch_type] === item.name)
              .count
              ? {
                name: constant.pitchTypeAbbrevations[items], value: groupedDataPoint[items].find((e) => constant.pitchTypeAbbrevations[e.pitch_type] === item.name)
                  .count
              }
              : {
                name: constant.pitchTypeAbbrevations[items], value: 0
              }
          );
          newAvgEv.push(
            groupedDataPoint[items].find((e) => constant.pitchTypeAbbrevations[e.pitch_type] === item.name)
              .Avg_EV
              ? {
                name: constant.pitchTypeAbbrevations[items], value: groupedDataPoint[items].find((e) => constant.pitchTypeAbbrevations[e.pitch_type] === item.name)
                  .Avg_EV
              }
              : {
                name: constant.pitchTypeAbbrevations[items], value: 0
              }
          );
          newSM.push(
            groupedDataPoint[items].find((e) => constant.pitchTypeAbbrevations[e.pitch_type] === item.name)
              .swing_and_miss
              ? {
                name: constant.pitchTypeAbbrevations[items], value: groupedDataPoint[items].find((e) => constant.pitchTypeAbbrevations[e.pitch_type] === item.name)
                  .swing_and_miss
              }
              : {
                name: constant.pitchTypeAbbrevations[items], value: 0
              }
          );
          newChase.push(
            groupedDataPoint[items].find((e) => constant.pitchTypeAbbrevations[e.pitch_type] === item.name)
              .chase
              ? {
                name: constant.pitchTypeAbbrevations[items], value: groupedDataPoint[items].find((e) => constant.pitchTypeAbbrevations[e.pitch_type] === item.name)
                  .chase
              }
              : {
                name: constant.pitchTypeAbbrevations[items], value: 0
              }
          );
        } else {
          newData.push(null);
          newCount.push(null);
          newAvgEv.push(null);
          newSM.push(null);
          newChase.push(null);
        }
      });
      Object.assign(item, { data: newData }, { count: newCount }, { Avg_EV: newAvgEv }, { swing_and_miss: newSM }, { chase: newChase });
      newData = [];
      newCount = [];
      newAvgEv = [];
      newSM = [];
      newChase = [];
    });
    finalData = data.newArr;
  });
  return finalData.reverse();
};

function sqquenceUniqueData(chartData, cb) {
  var unique = [];
  var valocityArr = [];

  chartData.map((value, i) => {
    if (unique.indexOf(value.pitch_type) === -1) {
      let data = {
        name: constant.pitchTypeAbbrevations[value.pitch_type],
        color: constant.pitchTypeColor[value.pitch_type],
        data: value.value ? value.value.toFixed(2) : 0,
        count: value.count ? value.count : "",
        chase: value.chase ? value.chase : "",
        swing_and_miss: value.swing_and_miss ? value.swing_and_miss : "",
        Avg_EV: value.Avg_EV ? value.Avg_EV : ""
      };
      valocityArr.push(data);
    }
    unique.push(value.pitch_type);
    if (chartData.length - 1 === i) {
      let data = {
        newArr: valocityArr,
        oldArr: chartData,
      };
      cb(data);
    }
  });
}

export const groupArrayOfObjects = (list, key) => {
  return list.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

// function for Release Point, Strike Zone charts
// 1. get and set Batter's data
export const battersTableData = (tableData) => {
  let returnBattersData = [];
  const removeNullEntriesFromChartData = removeNullEntryFromArrayOfObjects(
    tableData
  );
  const uniqueNames = uniqueEntriesFromArray(
    removeNullEntriesFromChartData,
    "batter"
  );
  // Tables Data For Each Player From Complete Data
  uniqueNames.map((batter) => {
    const filteredRecords = removeNullEntriesFromChartData.filter(
      (record) => record.batter === batter
    );
    const batterData = filteredRecords.map((record) => ({
      direction: record.direction,
      distance: record.distance,
      exit_velocity: record.exit_velocity,
      launch_angle: record.launch_angle,
      pitch_type: record.pitch_type,
      pitcher: record.pitcher,
      result: record.pitch_call,
      spin_rate: record.spin_rate,
      velocity: record.velocity,
    }));
    const playerName = uniqueEntriesFromArray(filteredRecords, "batter");

    returnBattersData.push({
      name: playerName[0],
      data: batterData,
    });
  });

  return returnBattersData;
};

// 2. batters pitch_type data
export const battersPitchTypeChartData = (chartData, chartType) => {
  let returnPitchersTypeData = [];
  const removeNullEntriesFromChartData = removeNullEntryFromArrayOfObjects(
    chartData
  );

  const uniqueNames = uniqueEntriesFromArray(
    removeNullEntriesFromChartData,
    "pitch_type"
  );
  const tablesData = uniqueNames.map((batter) => {
    const filteredRecords = removeNullEntriesFromChartData.filter(
      (record) => record.pitch_type === batter
    );
    const transformedData = filteredRecords.map((record) => ({
      batter: record.batter,
      h_break: record.h_break,
      ind_v_break: record.ind_v_break,
      pitch_call: record.pitch_call,
      pitch_number: record.pitch_number,
      pitch_type: record.pitch_type,
      pitcher: record.pitcher,
      pitcher_team: record.pitcher_team,
      release_height: record.release_height,
      release_side: record.release_side,
      ext: record.ext || record.extension,
      spin_rate: record.spin_rate,
      velocity: record.velocity,
      vert_appr_angle: record.vert_appr_angle,
      _id: record._id,
      spinDirDeg: record.spinDirDeg,
      gyroAngle: record.gyroAngle,
      tilt: record.tilt,
      spin_axis: record.spin_axis,
      efficiency: record.efficiency,
      v_break: record.v_break,
      exit_velocity: record.exit_velocity,
      launch_angle: record.launch_angle,
      distance: record.distance,
      x: record.x,
      y: record.y,
    }));
    const playerName = uniqueEntriesFromArray(filteredRecords, "pitch_type");
    if (playerName[0] !== "Undefined") {
      returnPitchersTypeData.push({
        name: constant.pitchTypeAbbrevations[playerName[0]],
        data: transformedData,
        color: constant.pitchTypeColor[playerName],
        marker: {
          symbol: "circle",
        },
      });
    }
  });
  if (chartType !== "StrikeZone") {
    returnPitchersTypeData.push({
      name: "Strikezone",
      color: "black",
      type: "line",
      data: [
        [-1, 0.3],
        [-1, 0.5],
        [1, 0.5],
        [1, 0.3],
        [-1, 0.3]
      ],
      showInLegend: false,
      zIndex: 1,
      states: {
        hover: {
          enabled: false,
        },
      },
    });
    returnPitchersTypeData.push({
      name: "curve",
      color: "black",
      type: "spline",
      data: [
        [-3.3, 0],
        [-1.5, 0.7],
        [0, 0.8],
        [1.5, 0.7],
        [3.3, 0]
      ],
      showInLegend: false,
      zIndex: 1,
      states: {
        hover: {
          enabled: false,
        },
      },
      marker: false
    });
  }
  return returnPitchersTypeData;
};

export const gameScrimmageStrikeZoneChartData = (chartData) => {
  let returnStrikeZoneData = [];
  const removeNullEntriesFromChartData = removeNullEntryFromArrayOfObjects(
    chartData
  );

  const uniqueNames = uniqueEntriesFromArray(
    removeNullEntriesFromChartData,
    "pitch_type"
  );
  const tablesData = uniqueNames.map((batter) => {
    const filteredRecords = removeNullEntriesFromChartData.filter(
      (record) => record.pitch_type === batter
    );
    const transformedData = filteredRecords.map((record) => ({
      batter: record.batter,
      h_break: record.h_break,
      ind_v_break: record.ind_v_break,
      pitch_call: record.pitch_call,
      pitch_number: record.pitch_number,
      pitch_type: record.pitch_type,
      pitcher: record.pitcher,
      pitcher_team: record.pitcher_team,
      release_height: record.release_height,
      release_side: record.release_side,
      spin_rate: record.spin_rate,
      velocity: record.velocity,
      vert_appr_angle: record.vert_appr_angle,
      play_result: record.play_result,
      _id: record._id,
      spinDirDeg: record.spinDirDeg,
      gyroAngle: record.gyroAngle,
      tilt: record.tilt,
      spin_axis: record.spin_axis,
      efficiency: record.efficiency,
      v_break: record.v_break,
      extension: record.extension,
      exit_velocity: record.exit_velocity,
      launch_angle: record.launch_angle,
      distance: record.distance,
      x: record.x,
      y: record.y,
    }));
    const playerName = uniqueEntriesFromArray(filteredRecords, "pitch_type");
    if (playerName[0] !== "Undefined") {
      returnStrikeZoneData.push({
        name: constant.pitchTypeAbbrevations[playerName[0]],
        data: transformedData,
        color: constant.pitchTypeColor[playerName],
        marker: {
          symbol: "circle",
        },
      });
    }
  });
  returnStrikeZoneData = returnStrikeZoneData.map(item => {
    item.zIndex = 2
    return item
  })

  let boxDimentions = constant.softball
    ? [
      [-0.83, 1.25],
      [-0.83, 3],
      [0.83, 3],
      [0.83, 1.25],
      [-0.83, 1.25],
    ]
    : [
      [-0.83, 1.5],
      [-0.83, 3.5],
      [0.83, 3.5],
      [0.83, 1.5],
      [-0.83, 1.5],
    ];

  returnStrikeZoneData.push({
    name: "Strikezone",
    color: "black",
    type: "line",
    data: boxDimentions,
    showInLegend: false,
    zIndex: 1,
    states: {
      hover: {
        enabled: false,
      },
    },
  });
  returnStrikeZoneData.push({
    name: "Stadium",
    color: "black",
    type: "line",
    data: [
      [-0.83, 0.1],
      [0.83, 0.1],
      [0.83, 0.5],
      [0, 0.83],
      [-0.83, 0.5],
      [-0.83, 0.1],
    ],
    showInLegend: false,
    zIndex: 1,
    states: {
      hover: {
        enabled: false,
      },
    },
  });
  return returnStrikeZoneData;
};

// 2. batters pitch_type data for side release chart
export const sideReleaseBattersPitchTypeChartData = (chartData) => {
  let returnPitchersTypeData = [];
  const removeNullEntriesFromChartData = removeNullEntryFromArrayOfObjects(
    chartData
  );
  const uniqueNames = uniqueEntriesFromArray(
    removeNullEntriesFromChartData,
    "pitch_type"
  );
  const tablesData = uniqueNames.map((batter) => {
    const filteredRecords = removeNullEntriesFromChartData.filter(
      (record) => record.pitch_type === batter
    );
    const transformedData = filteredRecords.map((record) => ({
      batter: record.batter,
      h_break: record.h_break,
      ind_v_break: record.ind_v_break,
      pitch_call: record.pitch_call,
      pitch_number: record.pitch_number,
      pitch_type: record.pitch_type,
      pitcher: record.pitcher,
      pitcher_team: record.pitcher_team,
      release_height: record.release_height,
      release_side: record.release_side,
      ext: record?.extension || record?.ext,
      spin_rate: record.spin_rate,
      velocity: record.velocity,
      vert_appr_angle: record.vert_appr_angle,
      _id: record._id,
      spinDirDeg: record.spinDirDeg,
      gyroAngle: record.gyroAngle,
      tilt: record.tilt,
      spin_axis: record.spin_axis,
      efficiency: record.efficiency,
      v_break: record.v_break,
      exit_velocity: record.exit_velocity,
      launch_angle: record.launch_angle,
      distance: record.distance,
      x: record.x,
      y: record.y,
    }));
    const playerName = uniqueEntriesFromArray(filteredRecords, "pitch_type");
    if (playerName[0] !== "Undefined") {
      returnPitchersTypeData.push({
        name: constant.pitchTypeAbbrevations[playerName[0]],
        data: transformedData,
        color: constant.pitchTypeColor[playerName],
        marker: {
          symbol: "circle",
        },
      });
    }
  });
  returnPitchersTypeData.push({
    name: "Strikezone",
    color: "black",
    type: "line",
    data: [
      [0.1, 0.2],
      [0.1, 0.65],
      [0.25, 0.65],
      [0.25, 0.2],
      [0.1, 0.2]
    ],
    showInLegend: false,
    zIndex: 1,
    states: {
      hover: {
        enabled: false,
      },
    },
  });

  returnPitchersTypeData.push({
    name: "polygon",
    color: "black",
    type: "spline",
    data: [
      [0, 0.8],
      [2, 0.7],
      [5, 0.4],
      [7, 0],
      // [0, 0],
      // [0, 0.8]
    ],
    showInLegend: false,
    zIndex: 1,
    states: {
      hover: {
        enabled: false,
      },
    },
    marker: false
  });
  return returnPitchersTypeData;
};

// 3. pitch_call data (umpire analysis data)
export const pitchersCallChartData = (chartData, profile) => {
  if (chartData.length !== 0) {
    let returnPitchersCallData = [];
    const removeNullEntriesFromChartData = removeNullEntryFromArrayOfObjects(
      chartData
    );

    const uniqueNames = uniqueEntriesFromArray(
      removeNullEntriesFromChartData,
      "pitch_call"
    );

    uniqueNames.map((batter) => {
      const filteredRecords = removeNullEntriesFromChartData.filter(
        (record) => record.pitch_call === batter
      );
      const transformedData = filteredRecords.map((record) => ({
        batter: record.batter,
        h_break: record.h_break,
        ind_v_break: record.ind_v_break,
        pitch_call: record.pitch_call,
        pitch_number: record.pitch_number,
        pitch_type: record.pitch_type,
        pitcher: record.pitcher,
        pitcher_team: record.pitcher_team,
        release_height: record.release_height,
        release_side: record.release_side,
        spin_rate: record.spin_rate,
        velocity: record.velocity,
        vert_appr_angle: record.vert_appr_angle,
        horz_appr_angle:record.horz_appr_angle,
        x: record.x,
        y: record.y,
      }));
      const playerName = uniqueEntriesFromArray(filteredRecords, "pitch_call");

      returnPitchersCallData.push({
        name: playerName[0],
        data: transformedData,
        color: constant.colors[batter],
        marker: {
          symbol: "circle",
        },
      });
    });

    returnPitchersCallData = returnPitchersCallData.map(item => {
      item.zIndex = 2
      return item
    })

    let boxDimentions = constant.softball
      ? [
        [-0.83, 1.25],
        [-0.83, 3],
        [0.83, 3],
        [0.83, 1.25],
        [-0.83, 1.25],
      ]
      : [
        [-0.83, 1.5],
        [-0.83, 3.5],
        [0.83, 3.5],
        [0.83, 1.5],
        [-0.83, 1.5],
      ];

    returnPitchersCallData.push({
      name: "Strikezone",
      color: "black",
      type: "line",
      data: boxDimentions,
      showInLegend: false,
      zIndex: 1,
      states: {
        hover: {
          enabled: false,
        },
      },
    });

    returnPitchersCallData.push({
      name: "Stadium",
      color: "black",
      type: "line",
      data: profile === "profile" ? [
        [-0.83, 0.4],
        [0.83, 0.4],
        [0.83, 0.2],
        [0, 0],
        [-0.83, 0.2],
        [-0.83, 0.4]
      ] : [
        [-0.83, 0.1],
        [0.83, 0.1],
        [0.83, 0.5],
        [0, 0.83],
        [-0.83, 0.5],
        [-0.83, 0.1],
      ],
      showInLegend: false,
      zIndex: 1,
      states: {
        hover: {
          enabled: false,
        },
      },
    });
    return returnPitchersCallData;
  }
};

// 4. batters pitch_type data for home and away batters module
export const battersChartData = (chartData) => {
  let returnBattersData = [];
  let tempSquareData = [];

  const removeNullEntriesFromChartData = removeNullEntryFromArrayOfObjects(
    chartData
  );

  const uniqueNames = uniqueEntriesFromArray(
    removeNullEntriesFromChartData,
    "batter"
  );

  uniqueNames.map((batter) => {
    const filteredRecords = removeNullEntriesFromChartData.filter(
      (record) => record.batter === batter
    );

    const transformedData = filteredRecords.map((record) => ({
      batter: record.batter,
      h_break: record.h_break,
      ind_v_break: record.ind_v_break,
      pitch_call: record.pitch_call,
      pitch_number: record.pitch_number,
      pitch_type: record.pitch_type,
      pitcher: record.pitcher,
      pitcher_team: record.pitcher_team,
      release_height: record.release_height,
      release_side: record.release_side,
      spin_rate: record.spin_rate,
      velocity: record.velocity,
      vert_appr_angle: record.vert_appr_angle,
      x: record.x,
      y: record.y,
    }));
    const playerName = uniqueEntriesFromArray(filteredRecords, "batter");
    let pitcherTypeData = battersPitchTypeChartData(
      transformedData,
      "StrikeZone"
    );
    pitcherTypeData = pitcherTypeData.map(item => {
      item.zIndex = 2
      return item
    })

    let boxDimentions = constant.softball
      ? [
        [-0.83, 1.25],
        [-0.83, 3],
        [0.83, 3],
        [0.83, 1.25],
        [-0.83, 1.25],
      ]
      : [
        [-0.83, 1.5],
        [-0.83, 3.5],
        [0.83, 3.5],
        [0.83, 1.5],
        [-0.83, 1.5],
      ];

    pitcherTypeData.push({
      name: "Strikezone",
      color: "black",
      type: "line",
      data: boxDimentions,
      showInLegend: false,
      zIndex: 1,
      states: {
        hover: {
          enabled: false,
        },
      },
    });
    pitcherTypeData.push({
      name: "Stadium",
      color: "black",
      type: "line",
      data: [
        [-0.83, 0.1],
        [0.83, 0.1],
        [0.83, 0.5],
        [0, 0.83],
        [-0.83, 0.5],
        [-0.83, 0.1],
      ],
      showInLegend: false,
      zIndex: 1,
      states: {
        hover: {
          enabled: false,
        },
      },
    });
    returnBattersData.push({
      name: playerName[0],
      data: pitcherTypeData,
    });
  });
  // if (returnBattersData.length >= 2) {
  return returnBattersData;
  // }
};

// 2. polar chart 
export const polarPitchTypeChartData = (chartData) => {
  let returnPitchersTypeData = [];
  const removeNullEntriesFromChartData = removeNullEntryFromArrayOfObjects(
    chartData
  );

  const uniqueNames = uniqueEntriesFromArray(
    removeNullEntriesFromChartData,
    "pitch_type"
  );
  const tablesData = uniqueNames.map((batter) => {
    const filteredRecords = removeNullEntriesFromChartData.filter(
      (record) => record.pitch_type === batter
    );
    const transformedData = filteredRecords.map((record) => ({
      batter: record.batter,
      h_break: record.h_break,
      ind_v_break: record.ind_v_break,
      pitch_call: record.pitch_call,
      pitch_number: record.pitch_number,
      pitch_type: record.pitch_type,
      pitcher: record.pitcher,
      pitcher_team: record.pitcher_team,
      release_height: record.release_height,
      release_side: record.release_side,
      ext: record.ext,
      spin_rate: record.spin_rate,
      velocity: record.velocity,
      vert_appr_angle: record.vert_appr_angle,
      spin_tilt: record.spin_tilt,
      spin_axis: record.spin_axis,
      efficiency: record.efficiency,
      v_break: record.v_break,
      ext: record.ext,
      _id: record._id,
      spinDirDeg: record.spinDirDeg,
      gyroAngle: record.gyroAngle,
      exit_velocity: record.exit_velocity,
      launch_angle: record.launch_angle,
      distance: record.distance,
      x: record.x,
      y: record.y,
    }));
    const playerName = uniqueEntriesFromArray(filteredRecords, "pitch_type");
    returnPitchersTypeData.push({
      name: constant.pitchTypeAbbrevations[playerName[0]],
      data: transformedData,
      color: constant.pitchTypeColor[playerName],
      marker: {
        symbol: "circle",
      },
      pointPlacement: 'between'
    });
  });
  return returnPitchersTypeData;
};

// plot chart 
export const plotChartData = (chartData, plot_f_x, plot_f_y) => {
  let returnPitchersTypeData = [];
  const removeNullEntriesFromChartData = removeNullEntryFromArrayOfObjects(
    chartData
  );
  const transformedData = removeNullEntriesFromChartData.map((record) => ({
    batter_name: record.batter_name,
    bat_speed: record.bat_speed,
    plane: record.plane,
    connection: record.connection,
    rotation: record.rotation,
    rotational_acceleration: record.rotational_acceleration,
    on_plane_efficiency: record.on_plane_efficiency,
    attack_angle: record.attack_angle,
    early_connection: record.early_connection,
    connection_at_impact: record.connection_at_impact,
    vertical_bat_angle: record.vertical_bat_angle,
    power: record.power,
    time_to_contact: record.time_to_contact,
    peak_hand_speed: record.peak_hand_speed,
    x: record[plot_f_x],
    y: record[plot_f_y],
  }));
  const transformedFinalData = transformedData.filter(e => e.x !== undefined && e.y !== undefined)
  returnPitchersTypeData.push({
    // name: constant.pitchTypeAbbrevations[playerName[0]],
    data: transformedFinalData,
    color: '#013662',
    marker: {
      symbol: "circle",
    },
  });
  return returnPitchersTypeData;
};

// view chart for catcher,top and side view
export const ViewAllChartData = (chartData, chartType, ViewColor, softball) => {
  let returnPitchersTypeData = [];
  const removeNullEntriesFromChartData = removeNullEntryFromArrayOfObjects(
    chartData
  );
  if (ViewColor === "exit_velocity") {
    // for code optimization exit velocity
    for (let i = 0; i < removeNullEntriesFromChartData.length; i++) {
      // use for exit velocity range color set blue to red
      // added object to color key use for range
      removeNullEntriesFromChartData[i].color = getSprayChartRangeColor(softball, [removeNullEntriesFromChartData[i]], ViewColor)[0].color
    }
    // push data using group of color(code optimize and loading time remove)
    // group of color wise array
    var groupedevColor = groupArrayOfObjects(
      removeNullEntriesFromChartData,
      "color"
    );
    Object.keys(groupedevColor).map((c) => {
      returnPitchersTypeData.push({
        showInLegend: false,
        name: "",
        data: groupedevColor[c],
        color: c,
        marker: {
          symbol: "circle",
        },
      });
    })
  } else {
    const uniqueNames = uniqueEntriesFromArray(
      removeNullEntriesFromChartData,
      ViewColor
    );
    // contact type order: gb,ld,fb,pu
    let newOrder = JSON.parse(JSON.stringify(uniqueNames))
    if (ViewColor === "hit_angle_type") {
      for (let i = 0; i < uniqueNames.length; i++) {
        newOrder[['gb', 'ld', 'fb', 'pu'].findIndex(e => e === uniqueNames[i])] = uniqueNames[i];
      }
    }
    // set play result(pitch call) order
    let includeArr = ['Out', 'Single', 'Double', 'Triple', 'Home Run', 'Error', 'Foul', 'Sacrifice']
    if (ViewColor === "pitch_call") {
      for (let i = 0; i < uniqueNames.length; i++) {
        if (!includeArr.includes(uniqueNames[i])) {
          includeArr.push(uniqueNames[i])
        }
      }
    }
    let newuniqueNames = ViewColor === "hit_angle_type" ? newOrder : ViewColor === "pitch_call" ? sortByPlayResult(uniqueNames, includeArr) : uniqueNames
    const tablesData = newuniqueNames.map((batter) => {
      const filteredRecords = removeNullEntriesFromChartData.filter(
        (record) => record[ViewColor] === batter
      );
      const transformedData = filteredRecords.map((record) => ({
        batter: record.batter,
        h_break: record.h_break,
        ind_v_break: record.ind_v_break,
        pitch_call: record.pitch_call,
        pitch_number: record.pitch_number,
        pitch_type: record.pitch_type,
        pitcher: record.pitcher,
        pitcher_team: record.pitcher_team,
        release_height: record.release_height,
        release_side: record.release_side,
        ext: record.ext,
        spin_rate: record.spin_rate,
        velocity: record.velocity,
        vert_appr_angle: record.vert_appr_angle,
        _id: record._id,
        spinDirDeg: record.spinDirDeg,
        gyroAngle: record.gyroAngle,
        exit_velocity: record.exit_velocity,
        launch_angle: record.launch_angle,
        distance: record.distance,
        hit_angle_type: record.hit_angle_type,
        x: record.x,
        y: record.y,
      }));
      // contact type, pitch type and play result color set
      const playerName = uniqueEntriesFromArray(filteredRecords, ViewColor);
      if (playerName[0] !== "Undefined") {
        returnPitchersTypeData.push({
          showInLegend: true,
          name: constant.pitchTypeAbbrevations[playerName[0]],
          data: transformedData,
          color: ViewColor === "pitch_call" ? constant.pitchCallColor[playerName[0]] : constant.pitchTypeColor[playerName[0]],
          marker: {
            symbol: "circle",
          },
        });
      }
    });
  }
  returnPitchersTypeData = returnPitchersTypeData.map(item => {
    item.zIndex = 2
    return item
  })
  if (chartType === "Catcher View") {
    let boxDimentions = constant.softball
      ? [
        [-0.708, 1.25],
        [-0.708, 3],
        [0.708, 3],
        [0.708, 1.25],
        [-0.708, 1.25],
      ]
      : [
        [-0.708, 1.5],
        [-0.708, 3.5],
        [0.708, 3.5],
        [0.708, 1.5],
        [-0.708, 1.5]
      ];
    returnPitchersTypeData.push({
      name: "Strikezone",
      color: "black",
      type: "line",
      data: boxDimentions,
      showInLegend: false,
      zIndex: 1,
      states: {
        hover: {
          enabled: false,
        },
      },
    });
    returnPitchersTypeData.push({
      name: "Stadium",
      color: "black",
      type: "line",
      data: [
        [-0.708, 0.4],
        [0.708, 0.4],
        [0.708, 0.2],
        [0, 0],
        [-0.708, 0.2],
        [-0.708, 0.4]
      ],
      showInLegend: false,
      zIndex: 1,
      states: {
        hover: {
          enabled: false,
        },
      },
    });
    // returnPitchersTypeData.push({
    //   name: "boxRight",
    //   color: "black",
    //   type: "line",
    //   data: [
    //     [1, 0],
    //     [1, 0.4],
    //     [2.5, 0.4],
    //     [2.5, 0],
    //     [1, 0]
    //   ],
    //   showInLegend: false,
    //   zIndex: 1,
    //   states: {
    //     hover: {
    //       enabled: false,
    //     },
    //   },
    //   marker: false
    // });
    // returnPitchersTypeData.push({
    //   name: "boxLeft",
    //   color: "black",
    //   type: "line",
    //   data: [
    //     [-1, 0],
    //     [-1, 0.4],
    //     [-2.5, 0.4],
    //     [-2.5, 0],
    //     [-1, 0]
    //   ],
    //   showInLegend: false,
    //   zIndex: 1,
    //   states: {
    //     hover: {
    //       enabled: false,
    //     },
    //   },
    //   marker: false
    // });
  }
  if (chartType === "Top View") {
    returnPitchersTypeData.push({
      name: "boxRight",
      color: "black",
      type: "line",
      data: [
        [1, -2],
        [1, 2],
        [2, 2],
        [2, -2],
        [1, -2]
      ],
      showInLegend: false,
      zIndex: 1,
      states: {
        hover: {
          enabled: false,
        },
      },
      marker: false
    });
    returnPitchersTypeData.push({
      name: "boxRightInner",
      color: "black",
      type: "line",
      data: [
        [1.2, -1.8],
        [1.2, 1.8],
        [2, 1.8],
        [2, -1.8],
        [1.2, -1.8]
      ],
      showInLegend: false,
      zIndex: 1,
      states: {
        hover: {
          enabled: false,
        },
      },
      marker: false
    });
    returnPitchersTypeData.push({
      name: "boxLeft",
      color: "black",
      type: "line",
      data: [
        [-1, -2],
        [-1, 2],
        [-2, 2],
        [-2, -2],
        [-1, -2],
      ],
      showInLegend: false,
      zIndex: 1,
      states: {
        hover: {
          enabled: false,
        },
      },
      marker: false
    });
    returnPitchersTypeData.push({
      name: "boxleftInner",
      color: "black",
      type: "line",
      data: [
        [-1.2, -1.8],
        [-1.2, 1.8],
        [-2, 1.8],
        [-2, -1.8],
        [-1.2, -1.8],
      ],
      showInLegend: false,
      zIndex: 1,
      states: {
        hover: {
          enabled: false,
        },
      },
      marker: false
    });
    returnPitchersTypeData.push({
      name: "Stadium",
      color: "black",
      type: "line",
      data: [
        [-0.708, 1.416],
        [0.708, 1.416],
        [0.708, 0.708],
        [0, 0],
        [-0.708, 0.708],
        [-0.708, 1.416]
      ],
      showInLegend: false,
      zIndex: 1,
      states: {
        hover: {
          enabled: false,
        },
      },
    });
  }
  if (chartType === "Side View") {
    returnPitchersTypeData.push({
      name: "Arrow1",
      color: "black",
      type: "line",
      data: [
        [0, 0.3],
        [0.709, 0.4],
        [1.417, 0.4],
        [1.417, 0.1],
        [0.709, 0.1],
        [0, 0.3]
      ],
      showInLegend: false,
      zIndex: 1,
      states: {
        hover: {
          enabled: false,
        },
      },
      marker: false
    });
    returnPitchersTypeData.push({
      name: "Arrow2",
      color: "black",
      type: "line",
      data: [
        [0, 1.7],
        [0.709, 1.8],
        [1.417, 1.8],
        [1.417, 1.5],
        [0.709, 1.5],
        [0, 1.7]
      ],
      showInLegend: false,
      zIndex: 1,
      states: {
        hover: {
          enabled: false,
        },
      },
      marker: false
    });
    returnPitchersTypeData.push({
      name: "Arrow3",
      color: "black",
      type: "line",
      data: [
        [0, 3.7],
        [0.709, 3.8],
        [1.417, 3.8],
        [1.417, 3.5],
        [0.709, 3.5],
        [0, 3.7]
      ],
      showInLegend: false,
      zIndex: 1,
      states: {
        hover: {
          enabled: false,
        },
      },
      marker: false
    });
    returnPitchersTypeData.push({
      name: "lineDash1",
      color: "black",
      type: "line",
      dashStyle: "Dash",
      data: [
        [0, 1.7],
        [0, 3.7]
      ],
      showInLegend: false,
      zIndex: 1,
      states: {
        hover: {
          enabled: false,
        },
      },
      marker: false
    });
    returnPitchersTypeData.push({
      name: "lineDash2",
      color: "black",
      type: "line",
      dashStyle: "Dash",
      data: [
        [0.709, 1.8],
        [0.709, 3.8]
      ],
      showInLegend: false,
      zIndex: 1,
      states: {
        hover: {
          enabled: false,
        },
      },
      marker: false
    });
    returnPitchersTypeData.push({
      name: "lineDash3",
      color: "black",
      type: "line",
      dashStyle: "Dash",
      data: [
        [0.809, 1.5],
        [0.809, 3.5]
      ],
      showInLegend: false,
      zIndex: 1,
      states: {
        hover: {
          enabled: false,
        },
      },
      marker: false
    });
    returnPitchersTypeData.push({
      name: "lineDash4",
      color: "black",
      type: "line",
      dashStyle: "Dash",
      data: [
        [1.417, 1.8],
        [1.417, 3.8]
      ],
      showInLegend: false,
      zIndex: 1,
      states: {
        hover: {
          enabled: false,
        },
      },
      marker: false
    });
  }
  return returnPitchersTypeData;
};

//histogram chart data 
export const histoGramChartData = (chartData, coloredBy, y_axis,histrogram_bucket) => {
  let returnPitchersTypeData = [];
  const removeNullEntriesFromChartData = removeNullEntryFromArrayOfObjects(
    chartData
  );
  if (coloredBy === "all_one_color") {
    const transformedData = removeNullEntriesFromChartData.map((record) => ({
      count: record.count,
      damage: record.damage,
      exit_velocity: record.exit_velocity,
      hard_hit: record.hard_hit,
      hard_hit_balls: record.hard_hit_balls,
      hits: record.hits,
      _id: record._id,
      x: record._id + (histrogram_bucket/2),
      y: record[y_axis],
    }));
    const transformedFinalData = transformedData.filter(e => e.x !== undefined && e.y !== undefined)
    returnPitchersTypeData.push({
      // name: constant.pitchTypeAbbrevations[playerName[0]],
      data: transformedFinalData,
      color: '#013662',
      showInLegend: false,
    });
  } else {
    for (let i = 0; i < removeNullEntriesFromChartData.length; i++) {
      // use for exit velocity range color set blue to red
      const roundedValue = Math.ceil(((removeNullEntriesFromChartData[i][coloredBy] / 100)) / 0.04) * 0.04;
      removeNullEntriesFromChartData[i].color = coloredBy === "exit_velocity" ?
        getSprayChartRangeColor(constant.softball, [removeNullEntriesFromChartData[i]], coloredBy)[0].color : constant.colorAxisStopsHistro[roundedValue]
      removeNullEntriesFromChartData[i].x = removeNullEntriesFromChartData[i]._id + (histrogram_bucket/2) 
      removeNullEntriesFromChartData[i].y = removeNullEntriesFromChartData[i][y_axis]
    }

    returnPitchersTypeData.push({
      data: removeNullEntriesFromChartData,
      showInLegend: false,
    });
  }
  return returnPitchersTypeData;
}

// Filter dublicate data
export const uniqByProp_map = (prop) => (arr) =>
  Array.from(
    arr
      .reduce(
        (acc, item) => item && item[prop] && acc.set(item[prop], item),
        new Map()
      )
      .values()
  );

// Filter except outside chart data for xaxis and yaxis
export const filterByAxis = (chartData, yAxis, xAxis) => {
  const selectedChartData = chartData.filter((obj) => {
    return (
      betweenAndEqual(obj.x, xAxis.min, xAxis.max) &&
      betweenAndEqual(obj.y, yAxis.min, yAxis.max)
    );
  });
  return selectedChartData;
};

// 5. for strike zone chart (red square box)
export const strikeZoneChartWithRedBox = (
  chartData,
  selectionMode,
  oldData,
  chartName,
  id
) => {
    const arrayUniqueByKey = uniqueEntriesFromArray(chartData, "pitch_type");
    if (selectionMode) {
      oldData = oldData.filter((a) => !chartData.find((b) => a._id === b._id));
      if (oldData.length !== 0) {
        const data = [
          ...strikeZoneChartData(chartData, arrayUniqueByKey,"",id,chartName),
          ...strikeZoneChartData(
            oldData,
            uniqueEntriesFromArray(oldData, "pitch_type"),
            true,
            id,
            chartName
          ),
        ];
        return data.filter((item) => item.data.length);
      } else {
        return strikeZoneChartData(chartData, arrayUniqueByKey,"",id,chartName);
      }
    } else return strikeZoneChartData(chartData, arrayUniqueByKey,"",id,chartName);
};
const strikeZoneChartData = (chartData, arrayUniqueByKey, selectionMode,id,chartName) => {
  let returnStrikeZoneData = [];
  arrayUniqueByKey.map((pitch_type) => {
    const filteredRecords = chartData.filter(
      (record) => record.pitch_type === pitch_type
    );
    // const batterData = filteredRecords.map((record) => ([record.x, record.y]));
    let batterData = filteredRecords.map((record) => ({
      batter: record.batter,
      h_break: record.h_break,
      ind_v_break: record.ind_v_break,
      pitch_call: record.pitch_call,
      pitch_number: record.pitch_number,
      pitch_type: record.pitch_type,
      pitcher: record.pitcher,
      pitcher_team: record.pitcher_team,
      release_height: record.release_height,
      release_side: record.release_side,
      spin_rate: record.spin_rate,
      velocity: record.velocity,
      vert_appr_angle: record.vert_appr_angle,
      play_result: record.play_result,
      _id: record._id,
      spinDirDeg: record.spinDirDeg,
      gyroAngle: record.gyroAngle,
      tilt: record.tilt,
      spin_axis: record.spin_axis,
      efficiency: record.efficiency,
      v_break: record.v_break,
      extension: record.extension,
      exit_velocity: record.exit_velocity,
      launch_angle: record.launch_angle,
      distance: record.distance,
      x: record.x,
      y: record.y,
      unique_key: `${record.x}${record.y}`,
      swing:record.swing,
      whiff:record.whiff,
      balls_in_play:record.balls_in_play,
      hits:record.hits,
      hard_hit:record.hard_hit,
      damage:record.damage,
      hit_angle_type:record.hit_angle_type,
      yt_seam_lat:record.yt_seam_lat,
      yt_seam_long:record.yt_seam_long
    }));
    if (selectionMode) batterData = uniqByProp_map("unique_key")(batterData);
    batterData = id === "pitcherStrikeZone" ?
      chartName !== "all_pitches" ?
        (chartName === "gb" || chartName === "ld" || chartName === "fb" || chartName === "pu") ? batterData.filter((record) => record.hit_angle_type === chartName) :
          batterData.filter((record) => record[chartName] === true) : batterData : batterData
    const playerName = uniqueEntriesFromArray(batterData, "pitch_type")
    // playerName = playerName.length!==0 ? playerName :""
    // const data = uniqueEntriesFromArray(chartData, "play_result");
    // data.forEach(_ => {
    //   const filData = batterData.filter(item => item.play_result === _)

    //   let old = returnStrikeZoneData.some((item) => {
    //     return item.name === constant.pitchTypeAbbrevations[playerName[0]]
    //   })
    if (playerName.length !== 0) {
      returnStrikeZoneData.push({
        // name: !old ? `${constant.pitchTypeAbbrevations[playerName[0]]}${selectionMode ? "(Unselected)" : ""}` : "",
        name: `${constant.pitchTypeAbbrevations[playerName[0]]}${selectionMode ? "(Unselected)" : ""}`,
        data:  batterData,
        color: `${constant.pitchTypeColor[playerName]}${selectionMode ? "33" : ""}`,
        marker: {
          symbol: "circle",
          // symbol: constant.PlayResultSymbol[_]
        },
      });
    }
    // })
  });

  returnStrikeZoneData = returnStrikeZoneData.map(item => {
    item.zIndex = 2
    return item
  })

  let boxDimentions = constant.softball
    ? [
      [-0.83, 1.25],
      [-0.83, 3],
      [0.83, 3],
      [0.83, 1.25],
      [-0.83, 1.25],
    ]
    : [
      [-0.83, 1.5],
      [-0.83, 3.5],
      [0.83, 3.5],
      [0.83, 1.5],
      [-0.83, 1.5],
    ];

  returnStrikeZoneData.push({
    name: "Strikezone",
    color: "black",
    type: "line",
    data: boxDimentions,
    showInLegend: false,
    zIndex: 1,
    states: {
      hover: {
        enabled: false,
      },
    },
  });
  returnStrikeZoneData.push({
    name: "Stadium",
    color: "black",
    type: "line",
    data: [
      [-0.83, 0.1],
      [0.83, 0.1],
      [0.83, 0.5],
      [0, 0.83],
      [-0.83, 0.5],
      [-0.83, 0.1],
    ],
    showInLegend: false,
    zIndex: 1,
    states: {
      hover: {
        enabled: false,
      },
    },
  });
  if (returnStrikeZoneData.length >= 2) {
    return returnStrikeZoneData;
  }
};


// 6. for spline chart
// export const splineChart = (chartData) => {
//   let returnStrikeZoneData = [];
//   const arrayUniqueByKey = uniqueEntriesFromArray(chartData, "pitch_type");
//   arrayUniqueByKey.map((pitch_type) => {
//     const filteredRecords = chartData.filter(
//       (record) => record.pitch_type === pitch_type
//     );
//     const batterData = filteredRecords.map((record) => {
//       return record.hasOwnProperty("vertical_break")
//         ? record.vertical_break
//         : record.horizontal_break;
//     });

//     const playerName = uniqueEntriesFromArray(filteredRecords, "pitch_type");
//     returnStrikeZoneData.push({
//       name: playerName[0],
//       data: batterData,
//       lineColor: constant.pitchTypeColor[playerName],
//       marker: {
//         symbol: "line",
//         enabled: true,
//       },
//     });
//   });

//   if (returnStrikeZoneData.length >= 2) {
//     return returnStrikeZoneData;
//   }
// };

// 6. for spline chart
export const splineChartWithDate = (chartData, roundNumber = 1) => {
  let returnStrikeZoneData = [];
  const arrayUniqueByKey = uniqueEntriesFromArray(chartData, "pitch_type");
  const dateCategory = [];
  arrayUniqueByKey.map((pitch_type) => {
    const filteredRecords = chartData.filter(
      (record) => record.pitch_type === pitch_type
    );
    const batterData = filteredRecords
      .sort(
        (a, b) => moment(a.date, "YYYY-MM-DD") - moment(b.date, "YYYY-MM-DD")
      )
      .map((record) => {
        const date = moment(record.date, "YYYY-MM-DD");
        const dateStamp = Date.UTC(
          parseInt(date.format("YYYY")),
          parseInt(date.format("M")) - 1,
          parseInt(date.format("D"))
        );
        if (!dateCategory.includes(dateStamp)) dateCategory.push(dateStamp);
        return [
          dateStamp,
          record.hasOwnProperty("vertical_break")
            ? round(record.vertical_break, roundNumber)
            : round(record.horizontal_break, roundNumber),
        ];
      });
    const playerName = uniqueEntriesFromArray(filteredRecords, "pitch_type");
    returnStrikeZoneData.push({
      name: constant.pitchTypeAbbrevations[playerName[0]],
      data: batterData,
      color: constant.pitchTypeColor[playerName],
      // marker: {
      //   symbol: "line",
      //   enabled: false,
      // },
    });
  });
  return [dateCategory, returnStrikeZoneData];

  // if (returnStrikeZoneData.length >= 2) {
  //   return [dateCategory, returnStrikeZoneData];
  // }
};

// this function takes string values
export const stringValueSortByfixTeam = (a, b, bats) => {
  if (a.HittersName === "Team" || b.HittersName === "Team") {
    return false
  } else {
    const firstString = a[bats].toUpperCase();
    const secondString = b[bats].toUpperCase();
    if (firstString > secondString) return 1;
    return -1;
  }
};

// this function takes string values
export const stringValueSort = (obj1Value, obj2Value) => {
  if (obj1Value === "Session Average" || obj2Value === "Session Average" || obj1Value === "Team Average" || obj2Value === "Team Average" || obj1Value === "Team" || obj2Value === "Team") {
    return false
  } else {
    const firstString = obj1Value.toUpperCase();
    const secondString = obj2Value.toUpperCase();
    if (firstString > secondString) return 1;
    return -1;
  }
};

export const getSortedValues = (a, b, sortOrder, key) => {
  if (a.name === "Session Average" || b.name === "Session Average" || a.name === "Team Average" || b.name === "Team Average" || a.HittersName === "Team" || b.HittersName === "Team") {
    return false
  } else {
    const multi = sortOrder === "ascend" ? 1 : -1;
    if (a[key] === b[key]) {
      return 0;
    }
    if (typeof a[key] === "string") {
      return 1 * multi;
    }
    if (typeof b[key] === "string") {
      return -1 * multi;
    }
    return a[key] - b[key];
  }
};

// Color filter function for ant table record
export const createColoredColumnObj = (
  title,
  key,
  data,
  percentage = false,
  extraProps = {}
) => ({
  title,
  dataIndex: key,
  key,
  ...extraProps,
  onCell: (record) => {
    const scales = getScalesUpdated(key, data);
    const cellValue = record && record[key];
    const cellColor =
      cellValue !== constant.LONG_HYPHEN ? scales[key](cellValue) : "#F7F8F9";
    return {
      style: {
        backgroundColor: record.HittersName !== "Team" ? cellColor : "LightGray",
        color: record.HittersName !== "Team" ? constant.colorFromBlueToRedShades[cellColor] || "#395d81" : "",
      },
    };
  },
  render: (text) => {
    return <AntdPercentageColumn cellValue={text} percentage={percentage} />;
  },
});

// for use bp summaries antd table
export const createColoredColumnObjCustomScale = (
  title,
  key,
  data,
  percentage = false,
  extraProps = {},
) => ({
  title,
  dataIndex: key,
  key,
  ...extraProps,
  onCell: record => {
    const scales = getScalesUpdatedCustomRange(
      key,
      data,
      extraProps.minRange,
      constant.softball ? 70 : 101,
    );
    const cellValue = record && record[key];
    const cellColor =
      cellValue !== constant.LONG_HYPHEN ? scales[key](cellValue) : '#F7F8F9';
    return {
      style: { backgroundColor: record.name !== "Session Average" ? cellColor : "silver" },
    };
  },
  render: text => {
    return <AntdPercentageColumn cellValue={text} percentage={percentage} />;
  },
});

// for use bp summaries antd table Batted Ball Profile By Field for ALA
export const createColoredColumnObjCustomScaleForALA = (
  title,
  key,
  data,
  percentage = false,
  extraProps = {},
) => ({
  title,
  dataIndex: key,
  key,
  ...extraProps,
  onCell: record => {
    const scales = getScalesUpdatedCustomRange(
      key,
      data,
      extraProps.minRange,
      50,
      "ALA"
    );
    const cellValue = record && record[key];
    const cellColor =
      cellValue !== constant.LONG_HYPHEN ? scales[key](cellValue) : '#F7F8F9';
    return {
      style: { backgroundColor: record.name !== "Session Average" ? cellColor : "silver" },
    };
  },
  render: text => {
    return <AntdPercentageColumn cellValue={text} percentage={percentage} />;
  },
});

export const createPercentageColumnObj = (
  title,
  key,
  data,
  percentage = false,
  extraProps = {},
) => ({
  title,
  dataIndex: key,
  key,
  ...extraProps,
  onCell: record => {
    return {
      style: { backgroundColor: record.name === "Session Average" ? "silver" : record.HittersName === "Team" ? "LightGray" : "" },
    };
  },
  render: text => {
    return <AntdPercentageColumn cellValue={text} percentage={percentage} />;
  },
});

export const createColoredColumnObjTypeText = (
  title,
  key,
  data,
  percentage = false,
  extraProps = {}
) => ({
  title,
  dataIndex: key,
  key,
  ...extraProps,
  onCell: (record) => {
    return {
      style: {
        color: constant.pitchTypeColor[record.pitch_type],
      },
    };
  },
});

// type color shows bullpentable
export const createTextColoredColumnObj = (title, key, extraProps = {}) => ({
  title,
  dataIndex: key,
  ...extraProps,
  onCell: (record) => {
    return {
      style: {
        color: constant.pitchTypeColor[record.pitch_type],
      },
    };
  },
});

// Color filter function for ant table record
export const createColoredColumnObjTable = (key, data, record, min) => {
  if (min) {
    var scales = getScalesUpdatedCustomRange(
      key,
      data,
      min,
      constant.softball ? 70 : 100,
    );
  } else {
    var scales = getScalesUpdated(key, data);
  }
  const cellValue = record && record[key];
  const cellColor =
    cellValue !== constant.LONG_HYPHEN ? scales[key](cellValue) : "#F7F8F9";
  return {
    backgroundColor: cellColor,
    color: constant.colorFromBlueToRedShades[cellColor] || "#395d81",
  };
};

export const getScalesUpdated = (key, data) => {
  const objectWithoutEmptyValues = filter((object) => {
    return object[key] !== constant.LONG_HYPHEN;
  }, data);
  const min = minBy(key, objectWithoutEmptyValues);
  const max = maxBy(key, objectWithoutEmptyValues);
  let rangeMin;
  if (min) {
    rangeMin = min[key] > 40 ? 40 : min[key];
  } else {
    rangeMin = 0;
  }
  return {
    [key]: d3
      .scaleQuantize()
      .domain(
        objectWithoutEmptyValues && objectWithoutEmptyValues.length === 0 && min
          ? [0, 0]
          : [rangeMin, max ? max[key] : 0]
      )
      .range(constant.blueToRedShades),
  };
};

export const getScalesUpdatedCustomRange = (key, data, min, max, ALA) => {
  const objectWithoutEmptyValues = filter(object => {
    return object[key] !== constant.LONG_HYPHEN;
  }, data);

  return {
    [key]: d3
      .scaleQuantize()
      .domain(
        objectWithoutEmptyValues && objectWithoutEmptyValues.length === 0 && min
          ? [0, 0]
          : [min || 0, max || 0],
      )
      .range(ALA === "ALA" ? constant.blueToRedToBlueShades : constant.blueToRedShades),
  };
};

export const d3ChartYAxis = (height, domain, reverseAxis = false) =>
  d3
    .scaleLinear()
    .domain(domain)
    .range(reverseAxis ? [0, height] : [height, 0]);

export const d3ChartStringXAxis = (width, xAxisLabels) =>
  d3.scaleBand().domain(xAxisLabels).range([0, width]);

export const d3ChartXAxis = (width, domain) =>
  d3.scaleLinear().domain(domain).range([0, width]);

export const d3ContourDensityCalculator = (xAxis, yAxis, width, height, data) =>
  d3
    .contourDensity()
    .x((d) => xAxis(d.x))
    .y((d) => yAxis(d.y))
    .size([width, height])
    .bandwidth(20)(data);

export const d3LineGenerator = d3.line();

export const d3ChartdimentionCalculator = (dimention, marginOne, marginTwo) =>
  dimention - marginOne - marginTwo;

export const getXAxisTicks = (data) => {
  // if (window.innerWidth < 480) {
  //   return data.map((obj) => {
  //     const str = constant.pitchTypeAbbkrevations[obj.type];
  //     return str && str.substring(0, 3);
  //   });
  // }
  return data.map((obj) => constant.pitchTypeAbbrevations[obj.type]);
};

export const findCenter = (var1, var2) => {
  return (var1 + var2) / 2;
};

export const xformBreakMovementChart = (
  series,
  uniqueBy,
  roundNeeded = true,
  roundValue = 1
) => {
  const transformedSeries = series.map((dataItem) => {
    return roundNeeded
      ? {
        ...dataItem,
        x1: dataItem.x1 ? round(parseFloat(dataItem.x1), roundValue) : 0,
        y1: dataItem.y1 ? round(dataItem.y1, roundValue) : 0,
        y2: dataItem.y2 ? round(dataItem.y2, roundValue) : 0,
      }
      : {
        ...dataItem,
        x1: dataItem.x1 ? parseFloat(dataItem.x1) : 0,
        y1: dataItem.y1 ? dataItem.y1 : 0,
        y2: dataItem.y2 ? dataItem.y2 : 0,
      };
  });
  return addColorsByKey(transformedSeries, uniqueBy);
};

export const findMaxAxisValue = (series) => {
  const x1Max = maxBy((o) => Math.abs(o.x1), series);
  const x2Max = maxBy((o) => Math.abs(o.x2), series);

  const maximumValue = Math.max(
    x1Max ? Math.abs(x1Max.x1) : 0,
    x2Max ? Math.abs(x2Max.x2) : 0
  );

  if (maximumValue < 30) {
    return 30;
  }

  return Math.ceil(maximumValue / 10) * 10;
};

export const addColorsByKey = (series, uniqueBy) => {
  const uniquePitches = uniqBy(uniqueBy, series);
  const pitchesWithColors = uniquePitches.map((pitch) => {
    if (pitch[uniqueBy] === null) {
      return {
        [uniqueBy]: pitch[uniqueBy],
        color: "#808080",
      };
    }
    return {
      [uniqueBy]: pitch[uniqueBy],
      color: constant.colors[pitch[uniqueBy]] ? constant.colors[pitch[uniqueBy]] : "#000000",
    };
  });
  return (
    series &&
    series.map((pitch) => {
      return {
        ...pitch,
        color: find({ [uniqueBy]: pitch[uniqueBy] }, pitchesWithColors).color,
      };
    })
  );
};

export const getArrayOfPrintAreaIdsTeam = (data) => {
  const numberOfTables = Math.ceil(data.length / 10);
  const tablesStringArray = [];

  for (let i = 0; i < numberOfTables; i += 1) {
    tablesStringArray.push(`ant-table-${i}`);
  }
  return numberOfTables > 1 ? tablesStringArray : ["antTablePrintId"];
};

export const getArrayOfPrintAreaIds = (data) => {
  const numberOfTables = Math.ceil(data.length / 10);
  const tablesStringArray = [];

  for (let i = 0; i < numberOfTables; i += 1) {
    tablesStringArray.push(`ant-table-${i}`);
  }
  return numberOfTables > 1 ? tablesStringArray : ["antTablePrintId"];
};

export const battingSummaryformMaxAndAvg = (dataA, dataB) => {
  let battingMaxAndAvgkeys = {};

  if (dataA.length === 0 && dataB.length === 0) {
    return {};
  }
  if (dataA.length >= dataB.length) {
    for (let i = 0; i < dataA.length; i++) {
      let matchBIndex = dataB.findIndex((obj) => obj._id == dataA[i]._id);
      battingMaxAndAvgkeys[dataA[i]._id] = {
        groupA: {
          type: dataA[i]._id,
          distance: dataA[i].distance
            ? dataA[i].distance.toString().includes(".")
              ? dataA[i].distance.toFixed(1)
              : dataA[i].distance
            : "-",
          angle: dataA[i].launch_angle
            ? dataA[i].launch_angle.toString().includes(".")
              ? dataA[i].launch_angle.toFixed(1)
              : dataA[i].launch_angle
            : "-",
          velo: dataA[i].exit_velocity
            ? dataA[i].exit_velocity.toString().includes(".")
              ? dataA[i].exit_velocity.toFixed(1)
              : dataA[i].exit_velocity
            : "-",
        },
        groupB: {
          type: matchBIndex !== -1 ? dataB[matchBIndex]._id : "-",
          distance:
            matchBIndex !== -1
              ? dataB[matchBIndex].distance
                ? dataB[matchBIndex].distance.toString().includes(".")
                  ? dataB[matchBIndex].distance.toFixed(1)
                  : dataB[matchBIndex].distance
                : "-"
              : "-",
          angle:
            matchBIndex !== -1
              ? dataB[matchBIndex].launch_angle
                ? dataB[matchBIndex].launch_angle.toString().includes(".")
                  ? dataB[matchBIndex].launch_angle.toFixed(1)
                  : dataB[matchBIndex].launch_angle
                : "-"
              : "-",
          velo:
            matchBIndex !== -1
              ? dataB[matchBIndex].exit_velocity
                ? dataB[matchBIndex].exit_velocity.toString().includes(".")
                  ? dataB[matchBIndex].exit_velocity.toFixed(1)
                  : dataB[matchBIndex].exit_velocity
                : "-"
              : "-",
        },
        differ: {
          distance:
            matchBIndex !== -1
              ? dataA[i].distance - dataB[matchBIndex].distance === 0
                ? 0
                : (dataA[i].distance - dataB[matchBIndex].distance)
                  .toString()
                  .includes(".")
                  ? (dataA[i].distance - dataB[matchBIndex].distance).toFixed(1)
                  : dataA[i].distance - dataB[matchBIndex].distance
              : "-",
          angle:
            matchBIndex !== -1
              ? dataA[i].launch_angle - dataB[matchBIndex].launch_angle === 0
                ? 0
                : (dataA[i].launch_angle - dataB[matchBIndex].launch_angle)
                  .toString()
                  .includes(".")
                  ? (
                    dataA[i].launch_angle - dataB[matchBIndex].launch_angle
                  ).toFixed(1)
                  : dataA[i].launch_angle - dataB[matchBIndex].launch_angle
              : "-",
          velo:
            matchBIndex !== -1
              ? dataA[i].exit_velocity - dataB[matchBIndex].exit_velocity === 0
                ? 0
                : (dataA[i].exit_velocity - dataB[matchBIndex].exit_velocity)
                  .toString()
                  .includes(".")
                  ? (
                    dataA[i].exit_velocity - dataB[matchBIndex].exit_velocity
                  ).toFixed(1)
                  : dataA[i].exit_velocity - dataB[matchBIndex].exit_velocity
              : "-",
        },
      };
    }
    return battingMaxAndAvgkeys;
  }
  if (dataB.length >= dataA.length) {
    for (let i = 0; i < dataB.length; i++) {
      let matchAIndex = dataA.findIndex((obj) => obj._id == dataB[i]._id);
      battingMaxAndAvgkeys[dataB[i]._id] = {
        groupA: {
          type: matchAIndex !== -1 ? dataA[matchAIndex]._id : "-",
          distance:
            matchAIndex !== -1
              ? dataA[matchAIndex].distance
                ? dataA[matchAIndex].distance.toString().includes(".")
                  ? dataA[matchAIndex].distance.toFixed(1)
                  : dataA[matchAIndex].distance
                : "-"
              : "-",
          angle:
            matchAIndex !== -1
              ? dataA[matchAIndex].launch_angle
                ? dataA[matchAIndex].launch_angle.toString().includes(".")
                  ? dataA[matchAIndex].launch_angle.toFixed(1)
                  : dataA[matchAIndex].launch_angle
                : "-"
              : "-",
          velo:
            matchAIndex !== -1
              ? dataA[matchAIndex].exit_velocity
                ? dataA[matchAIndex].exit_velocity.toString().includes(".")
                  ? dataA[matchAIndex].exit_velocity.toFixed(1)
                  : dataA[matchAIndex].exit_velocity
                : "-"
              : "-",
        },
        groupB: {
          type: dataB[i]._id ? dataB[i]._id : "-",
          distance: dataB[i].distance
            ? dataB[i].distance.toString().includes(".")
              ? dataB[i].distance.toFixed(1)
              : dataB[i].distance
            : "-",
          angle: dataB[i].launch_angle
            ? dataB[i].launch_angle.toString().includes(".")
              ? dataB[i].launch_angle.toFixed(1)
              : dataB[i].launch_angle
            : "-",
          velo: dataB[i].exit_velocity
            ? dataB[i].exit_velocity.toString().includes(".")
              ? dataB[i].exit_velocity.toFixed(1)
              : dataB[i].exit_velocity
            : "-",
        },
        differ: {
          distance:
            matchAIndex !== -1
              ? dataA[matchAIndex].distance - dataB[i].distance === 0
                ? 0
                : (dataA[matchAIndex].distance - dataB[i].distance)
                  .toString()
                  .includes(".")
                  ? (dataA[matchAIndex].distance - dataB[i].distance).toFixed(1)
                  : dataA[matchAIndex].distance - dataB[i].distance
              : "-",
          angle:
            matchAIndex !== -1
              ? dataA[matchAIndex].launch_angle - dataB[i].launch_angle === 0
                ? 0
                : (dataA[matchAIndex].launch_angle - dataB[i].launch_angle)
                  .toString()
                  .includes(".")
                  ? (
                    dataA[matchAIndex].launch_angle - dataB[i].launch_angle
                  ).toFixed(1)
                  : dataA[matchAIndex].launch_angle - dataB[i].launch_angle
              : "-",
          velo:
            matchAIndex !== -1
              ? dataA[matchAIndex].exit_velocity - dataB[i].exit_velocity === 0
                ? 0
                : (dataA[matchAIndex].exit_velocity - dataB[i].exit_velocity)
                  .toString()
                  .includes(".")
                  ? (
                    dataA[matchAIndex].exit_velocity - dataB[i].exit_velocity
                  ).toFixed(1)
                  : dataA[matchAIndex].exit_velocity - dataB[i].exit_velocity
              : "-",
        },
      };
    }
    return battingMaxAndAvgkeys;
  }
};

export const pitcherSummaryformMaxAndAvg = (dataA, dataB) => {
  let pitcherMaxAndAvgkeys = {};
  if (dataA.length === 0 && dataB.length === 0) {
    return {};
  }
  if (dataA.length >= dataB.length) {
    for (let i = 0; i < dataA.length; i++) {
      let matchBIndex = dataB.findIndex((obj) => obj._id == dataA[i]._id);
      pitcherMaxAndAvgkeys[dataA[i]._id] = {
        groupA: {
          type: dataA[i]._id,
          velocity: dataA[i].velocity
            ? dataA[i].velocity.toString().includes(".")
              ? dataA[i].velocity.toFixed(1)
              : dataA[i].velocity
            : "-",
          spin: dataA[i].spin_rate
            ? dataA[i].spin_rate.toString().includes(".")
              ? dataA[i].spin_rate.toFixed(1)
              : dataA[i].spin_rate
            : "-",
          extension: dataA[i].ext
            ? dataA[i].ext.toString().includes(".")
              ? dataA[i].ext.toFixed(1)
              : dataA[i].ext
            : "-",
        },
        groupB: {
          type: matchBIndex !== -1 ? dataB[matchBIndex]._id : "-",
          velocity:
            matchBIndex !== -1
              ? dataB[matchBIndex].velocity
                ? dataB[matchBIndex].velocity.toString().includes(".")
                  ? dataB[matchBIndex].velocity.toFixed(1)
                  : dataB[matchBIndex].velocity
                : "-"
              : "-",
          spin:
            matchBIndex !== -1
              ? dataB[matchBIndex].spin_rate
                ? dataB[matchBIndex].spin_rate.toString().includes(".")
                  ? dataB[matchBIndex].spin_rate.toFixed(1)
                  : dataB[matchBIndex].spin_rate
                : "-"
              : "-",
          extension:
            matchBIndex !== -1
              ? dataB[matchBIndex].ext
                ? dataB[matchBIndex].ext.toString().includes(".")
                  ? dataB[matchBIndex].ext.toFixed(1)
                  : dataB[matchBIndex].ext
                : "-"
              : "-",
        },
        differ: {
          velocity:
            matchBIndex !== -1
              ? dataA[i].velocity - dataB[matchBIndex].velocity === 0
                ? 0
                : (dataA[i].velocity - dataB[matchBIndex].velocity)
                  .toString()
                  .includes(".")
                  ? (dataA[i].velocity - dataB[matchBIndex].velocity).toFixed(1)
                  : dataA[i].velocity - dataB[matchBIndex].velocity
              : "-",
          spin:
            matchBIndex !== -1
              ? dataA[i].spin_rate - dataB[matchBIndex].spin_rate === 0
                ? 0
                : (dataA[i].spin_rate - dataB[matchBIndex].spin_rate)
                  .toString()
                  .includes(".")
                  ? (dataA[i].spin_rate - dataB[matchBIndex].spin_rate).toFixed(1)
                  : dataA[i].spin_rate - dataB[matchBIndex].spin_rate
              : "-",
          extension:
            matchBIndex !== -1
              ? dataA[i].ext - dataB[matchBIndex].ext === 0
                ? 0
                : (dataA[i].ext - dataB[matchBIndex].ext)
                  .toString()
                  .includes(".")
                  ? (dataA[i].ext - dataB[matchBIndex].ext).toFixed(1)
                  : dataA[i].ext - dataB[matchBIndex].ext
              : "-",
        },
      };
    }
    return pitcherMaxAndAvgkeys;
  }
  if (dataB.length >= dataA.length) {
    for (let i = 0; i < dataB.length; i++) {
      let matchAIndex = dataA.findIndex((obj) => obj._id == dataB[i]._id);
      pitcherMaxAndAvgkeys[dataB[i]._id] = {
        groupA: {
          type: matchAIndex !== -1 ? dataA[matchAIndex]._id : "-",
          velocity:
            matchAIndex !== -1
              ? dataA[matchAIndex].velocity
                ? dataA[matchAIndex].velocity.toString().includes(".")
                  ? dataA[matchAIndex].velocity.toFixed(1)
                  : dataA[matchAIndex].velocity
                : "-"
              : "-",
          spin:
            matchAIndex !== -1
              ? dataA[matchAIndex].spin_rate
                ? dataA[matchAIndex].spin_rate.toString().includes(".")
                  ? dataA[matchAIndex].spin_rate.toFixed(1)
                  : dataA[matchAIndex].spin_rate
                : "-"
              : "-",
          extension:
            matchAIndex !== -1
              ? dataA[matchAIndex].ext
                ? dataA[matchAIndex].ext.toString().includes(".")
                  ? dataA[matchAIndex].ext.toFixed(1)
                  : dataA[matchAIndex].ext
                : "-"
              : "-",
        },
        groupB: {
          type: dataB[i]._id ? dataB[i]._id : "-",
          velocity: dataB[i][0]
            ? dataB[i].velocity.toString().includes(".")
              ? dataB[i].velocity.toFixed(1)
              : dataB[i].velocity
            : "-",
          spin: dataB[i].spin_rate
            ? dataB[i].spin_rate.toString().includes(".")
              ? dataB[i].spin_rate.toFixed(1)
              : dataB[i].spin_rate
            : "-",
          extension: dataB[i].ext
            ? dataB[i].ext.toString().includes(".")
              ? dataB[i].ext.toFixed(1)
              : dataB[i].ext
            : "-",
        },
        differ: {
          velocity:
            matchAIndex !== -1
              ? dataA[matchAIndex].velocity - dataB[i].velocity === 0
                ? 0
                : (dataA[matchAIndex].velocity - dataB[i].velocity)
                  .toString()
                  .includes(".")
                  ? (dataA[matchAIndex].velocity - dataB[i].velocity).toFixed(1)
                  : dataA[matchAIndex].velocity - dataB[i].velocity
              : "-",
          spin:
            matchAIndex !== -1
              ? dataA[matchAIndex].spin_rate - dataB[i].spin_rate === 0
                ? 0
                : (dataA[matchAIndex].spin_rate - dataB[i].spin_rate)
                  .toString()
                  .includes(".")
                  ? (dataA[matchAIndex].spin_rate - dataB[i].spin_rate).toFixed(1)
                  : dataA[matchAIndex].spin_rate - dataB[i].spin_rate
              : "-",
          extension:
            matchAIndex !== -1
              ? dataA[matchAIndex].ext - dataB[i].ext === 0
                ? 0
                : (dataA[matchAIndex].ext - dataB[i].ext)
                  .toString()
                  .includes(".")
                  ? (dataA[matchAIndex].ext - dataB[i].ext).toFixed(1)
                  : dataA[matchAIndex].ext - dataB[i].ext
              : "-",
        },
      };
    }
    return pitcherMaxAndAvgkeys;
  }
};

export const formateForContactType = (dataA, dataB) => {
  let contactObje;
  if (dataA.length === 0 && dataB.length === 0) {
    return {};
  }
  contactObje = {
    groupA: dataA[0]
      ? dataA[0]
      : {
        _id: "",
        gb_percentage: 0,
        ld_percentage: 0,
        fb_percentage: 0,
        pu_percentage: 0,
      },
    groupB: dataB[0]
      ? dataB[0]
      : {
        _id: "",
        gb_percentage: 0,
        ld_percentage: 0,
        fb_percentage: 0,
        pu_percentage: 0,
      },
  };
  return contactObje;
};

export const zoneTableFormate = (dataA, dataB) => {
  let zoneObj;
  if (dataA.length === 0 && dataB.length === 0) {
    return {};
  }
  zoneObj = {
    groupA: dataA[0]
      ? dataA[0]
      : {
        left_avg_exit_velocity: 0,
        left_count: 0,
        middle_avg_exit_velocity: 0,
        middle_count: 0,
        right_avg_exit_velocity: 0,
        right_count: 0,
      },
    groupB: dataB[0]
      ? dataB[0]
      : {
        left_avg_exit_velocity: 0,
        left_count: 0,
        middle_avg_exit_velocity: 0,
        middle_count: 0,
        right_avg_exit_velocity: 0,
        right_count: 0,
      },
  };
  return zoneObj;
};

export const releaseTableFormate = (dataA, dataB) => {
  let releaseObj = {};
  if (Object.keys(dataA).length === 0 && Object.keys(dataB).length === 0) {
    return {};
  }
  if (Object.keys(dataA).length >= Object.keys(dataB).length) {
    Object.keys(dataA).map((keyName, i) => {
      let isInGroup = dataB.hasOwnProperty(keyName);
      releaseObj[keyName] = {
        groupA: {
          type: keyName,
          dispersion: dataA[keyName][0]
            ? dataA[keyName][0].toString().includes(".")
              ? Number(dataA[keyName][0]).toFixed(1)
              : dataA[keyName][0]
            : "-",
        },
        groupB: {
          type: isInGroup ? keyName : "-",
          dispersion: isInGroup
            ? dataB[keyName][0]
              ? dataB[keyName][0].toString().includes(".")
                ? Number(dataB[keyName][0]).toFixed(1)
                : dataB[keyName][0]
              : "-"
            : "-",
        },
        differ: {
          dispersion: isInGroup
            ? dataA[keyName][0] - dataB[keyName][0] === 0
              ? 0
              : (dataA[keyName][0] - dataB[keyName][0]).toString().includes(".")
                ? Number(dataA[keyName][0] - dataB[keyName][0]).toFixed(1)
                : dataA[keyName][0] - dataB[keyName][0]
            : "-",
        },
      };
    });
    return releaseObj;
  }
  if (Object.keys(dataB).length >= Object.keys(dataA).length) {
    Object.keys(dataB).map((keyName, i) => {
      let isInGroup = dataA.hasOwnProperty(keyName);
      releaseObj[keyName] = {
        groupA: {
          type: isInGroup ? keyName : "-",
          dispersion: isInGroup
            ? dataA[keyName][0]
              ? dataA[keyName][0].toString().includes(".")
                ? Number(dataA[keyName][0]).toFixed(1)
                : dataA[keyName][0]
              : "-"
            : "-",
        },
        groupB: {
          type: keyName,
          dispersion: dataB[keyName][0]
            ? dataB[keyName][0].toString().includes(".")
              ? Number(dataB[keyName][0]).toFixed(1)
              : dataB[keyName][0]
            : "-",
        },
        differ: {
          dispersion: isInGroup
            ? dataA[keyName][0] - dataB[keyName][0] === 0
              ? 0
              : (dataA[keyName][0] - dataB[keyName][0]).toString().includes(".")
                ? Number(dataA[keyName][0] - dataB[keyName][0]).toFixed(1)
                : dataA[keyName][0] - dataB[keyName][0]
            : "-",
        },
      };
    });
    return releaseObj;
  }
};

export const getPitchUsageDomainStartingPoints = (chartData) => {
  return chartData && chartData.map((item) => item.velocity_start);
};

export const getPitchUsageDomainEndingPoints = (chartData) => {
  return chartData && chartData.map((item) => item.velocity_end);
};

export const sortByPitchType = (data, field) => {
  return data.sort(function (a, b) {
    return pitchTypeOrder.indexOf(a[field]) - pitchTypeOrder.indexOf(b[field]);
  });
};

export const pitchUsageXformer = (data, roundNeeded = true, roundValue = 1) => {
  const sortedData = sortByPitchType(data, "_id");
  return sortedData.map((obj) => {
    return roundNeeded
      ? {
        type: obj._id,
        rightYAxis: {
          startValue: round(obj.velocity_start, roundValue),
          endValue: round(obj.velocity_end, roundValue),
          avgValue: round(obj.velocity_avg, roundValue),
          velocity: round(obj.velocity, roundValue),
          spin_rate: round(obj.spin_rate, roundValue),
          ind_v_break: round(obj.ind_v_break, roundValue),
          horizontal_break: round(obj.horizontal_break, roundValue),
        },
        leftYAxis: {
          value: round(obj.pitch_percentage, roundValue),
        },
        color: constant.colors[obj._id] || "#808080",
      }
      : {
        type: obj._id,
        rightYAxis: {
          startValue: obj.velocity_start,
          endValue: obj.velocity_end,
          avgValue: obj.velocity_avg,
        },
        leftYAxis: {
          value: obj.pitch_percentage,
        },
        color: constant.colors[obj._id] || "#808080",
      };
  });
};

export const heatMapDataXformer = (data, heat_map, uniqueDataLimit = 4) => {
  const series = [];
  for (let i = 0; i <= uniqueDataLimit; i += 1) {
    for (let j = 0; j <= uniqueDataLimit; j += 1) {
      const value = data[0][`zone${i}_${j}`];
      series.push([
        i,
        j,
        heat_map === 'Avg Tilt' ? value || null
          : (heat_map === 'Damage' ||  heat_map === 'Damage #' || heat_map === 'Whiff' || heat_map === 'Hard Hit' || heat_map === 'Hard Hit #' || heat_map === 'GB' || heat_map === 'LD' || heat_map === 'FB' || heat_map === 'PU') ? value
            : heat_map === 'Avg Spin Rate' ? Math.round(value) || null
              : value ? value.toString().includes(".") ? (parseFloat(value).toFixed(1) !== "0.0" ? parseFloat(value).toFixed(1) : null)
                : parseFloat(value)
                : null,
      ]);
    }
  }
  return series;
};

export const successMessage = (msg) => {
  return toast.success(msg, { position: toast.POSITION.TOP_RIGHT });
};

export const errorMessage = (msg) => {
  toast.error(msg, { position: toast.POSITION.TOP_RIGHT });
};

export const getSeasonFilterDefaultValues = (startYear, lastSeason) => {
  if (startYear === moment().year().toString) {
    // const result = [{ value: startYear, label: startYear }];
    const result = [{ value: startYear, label: `${startYear}-${startYear + 1}` }];
    if (lastSeason && parseInt(lastSeason, 10) > moment().year()) {
      const years = range(
        parseInt(startYear, 10),
        parseInt(lastSeason, 10) + 1,
        1
      );
      years.map((year) => {
        result.push({ value: year.toString(), label: `${year.toString()}-${(year + 1).toString()}` });
      });
    }
    return result;
  }

  // Range method will run from int start year to current year creating a inclusive Years
  let years = [];
  if (lastSeason && parseInt(lastSeason, 10) > moment().year()) {
    years = range(parseInt(startYear, 10), parseInt(lastSeason, 10) + 1, 1);
  } else {
    years = range(parseInt(startYear, 10), moment().year() + 1, 1);
  }
  // map will create {value: year, label: year} objects for each year
  const result = [];
  years.map((year) => {
    result.push({ value: year.toString(), label: `${year.toString()}-${(year + 1).toString()}` });
  });
  return result;
};

// scrimmage pitcher
export const getPitchingMetricsTranformDataPitcher = (pitchingMetrics) => {
  const pitchingMetricsRowKeys = Object.keys(pitchingMetrics[0]);
  return pitchingMetricsRowKeys.map((key) => {
    return {
      [`key`]: constant.pitchingMetricsKeyNames[key],
      ['value']:
        pitchingMetrics[0] &&
          (key === "whiff" ||
            key === "chase_swing" ||
            key === "first_pitch_k" ||
            key === "in_zone" || key === "edge" || key === "csw")
          ? parseFloat(pitchingMetrics[0][key])
            ? parseFloat(pitchingMetrics[0][key]).toString().includes(".")
              ? parseFloat(pitchingMetrics[0][key]).toFixed(1) + "%"
              : parseFloat(pitchingMetrics[0][key]) + "%"
            : "-"
          : parseFloat(pitchingMetrics[0][key])
            ? parseFloat(pitchingMetrics[0][key]).toString().includes(".")
              ? parseFloat(pitchingMetrics[0][key]).toFixed(1)
              : parseFloat(pitchingMetrics[0][key])
            : "-",
    };
  });
};

export const getPitchingMetricsTranformData = (pitchingMetrics) => {
  if (!pitchingMetrics || !pitchingMetrics.length) {
    return [("": ""), (["Pitching Metrics"]: ""), ("": "")];
  }
  const pitchingMetricsRowKeys = Object.keys(pitchingMetrics[0]);
  return pitchingMetricsRowKeys.map((key) => {
    const firstTeamName = pitchingMetrics[1]
      ? pitchingMetrics[1].pitcher_team
      : "";
    const secondTeamName = pitchingMetrics[0]
      ? pitchingMetrics[0].pitcher_team
      : "";
    return {
      [firstTeamName]:
        pitchingMetrics[1] &&
          (key === "whiff" ||
            key === "chase_swing" ||
            key === "first_pitch_k" ||
            key === "in_zone" || key === "edge" || key === "csw")
          ? parseFloat(pitchingMetrics[1][key])
            ? parseFloat(pitchingMetrics[1][key]).toString().includes(".")
              ? parseFloat(pitchingMetrics[1][key]).toFixed(1) + "%"
              : parseFloat(pitchingMetrics[1][key]) + "%"
            : "-"
          : parseFloat(pitchingMetrics[1][key])
            ? parseFloat(pitchingMetrics[1][key]).toString().includes(".")
              ? parseFloat(pitchingMetrics[1][key]).toFixed(1)
              : parseFloat(pitchingMetrics[1][key])
            : "-",
      [`Pitching Metrics`]: constant.pitchingMetricsKeyNames[key],
      [secondTeamName]:
        pitchingMetrics[0] &&
          (key === "whiff" ||
            key === "chase_swing" ||
            key === "first_pitch_k" ||
            key === "in_zone" || key === "edge" || key === "csw")
          ? parseFloat(pitchingMetrics[0][key])
            ? parseFloat(pitchingMetrics[0][key]).toString().includes(".")
              ? parseFloat(pitchingMetrics[0][key]).toFixed(1) + "%"
              : parseFloat(pitchingMetrics[0][key]) + "%"
            : "-"
          : parseFloat(pitchingMetrics[0][key])
            ? parseFloat(pitchingMetrics[0][key]).toString().includes(".")
              ? parseFloat(pitchingMetrics[0][key]).toFixed(1)
              : parseFloat(pitchingMetrics[0][key])
            : "-",
    };
  });
};

// scrimmage pitcher
export const getHittingMetricsTranformDataPitcher = (hittingMetrics) => {
  const hittingMetricsRowKeys = Object.keys(hittingMetrics[0]);
  return hittingMetricsRowKeys.map((key) => {
    return {
      ['value']:
        hittingMetrics[0] &&
          (key === "hard_hit_percentage" ||
            key === "gb_percentage" ||
            key === "ld_percentage" ||
            key === "fb_percentage" || key === "pu_percentage" || key === "damage_percentage")
          ? parseFloat(hittingMetrics[0][key]).toString().includes(".")
            ? parseFloat(hittingMetrics[0][key]).toFixed(1) + "%"
            : parseFloat(hittingMetrics[0][key]) + "%"
          : parseFloat(hittingMetrics[0][key]).toString().includes(".")
            ? parseFloat(hittingMetrics[0][key]).toFixed(1)
            : parseFloat(hittingMetrics[0][key]),
      ['key']: constant.hittingMetricsKeyNames[key],
    };
  });
};

export const getHittingMetricsTranformData = (hittingMetrics) => {
  if (!hittingMetrics || !hittingMetrics.length) {
    return [("": ""), (["Hitting Metrics"]: ""), ("": "")];
  }
  const hittingMetricsRowKeys = Object.keys(hittingMetrics[0]);
  return hittingMetricsRowKeys.map((key) => {
    const firstTeamName = hittingMetrics[0]
      ? hittingMetrics[0].batter_team
      : "";
    const secondTeamName = hittingMetrics[1]
      ? hittingMetrics[1].batter_team
      : "";
    return {
      [firstTeamName]:
        hittingMetrics[0] &&
          (key === "hard_hit_percentage" ||
            key === "gb_percentage" ||
            key === "ld_percentage" ||
            key === "fb_percentage" ||
            key === "pu_percentage" || key === "damage_percentage")
          ? parseFloat(hittingMetrics[0][key]).toString().includes(".")
            ? parseFloat(hittingMetrics[0][key]).toFixed(1) + "%"
            : parseFloat(hittingMetrics[0][key]) + "%"
          : parseFloat(hittingMetrics[0][key]).toString().includes(".")
            ? parseFloat(hittingMetrics[0][key]).toFixed(1)
            : parseFloat(hittingMetrics[0][key]),
      [`Hitting Metrics`]: constant.hittingMetricsKeyNames[key],
      [secondTeamName]:
        hittingMetrics[1] &&
          (key === "hard_hit_percentage" ||
            key === "gb_percentage" ||
            key === "ld_percentage" ||
            key === "fb_percentage" ||
            key === "pu_percentage" || key === "damage_percentage")
          ? parseFloat(hittingMetrics[1][key]).toString().includes(".")
            ? parseFloat(hittingMetrics[1][key]).toFixed(1) + "%"
            : parseFloat(hittingMetrics[1][key]) + " %"
          : parseFloat(hittingMetrics[1][key]).toString().includes(".")
            ? parseFloat(hittingMetrics[1][key]).toFixed(1)
            : parseFloat(hittingMetrics[1][key]),
    };
  });
};

export const getMaxKeysFromArrayOfObjects = (data) => {
  return (
    data &&
    data.map((item) => {
      return Object.keys(item).length;
    })
  );
};


export const sprayChartRangeHeaderStaticValues = softball => {
  // if (window.innerWidth < 575) {
  //   return [
  //     { label: '60 - 89', color: '#1500FF' },
  //     { label: '90 - 120', color: '#D10000' },
  //   ]
  // }
  // if (!softball) {
  //   return [
  //     { label: '< 60', color: '#1500FF' },
  //     { label: '61 - 65', color: '#3F2EFF' },
  //     { label: '66 - 70', color: '#6A5CFF' },
  //     { label: '71 - 75', color: '#948BFF' },
  //     { label: '76 - 80', color: '#BFB9FF' },
  //     { label: '81 - 85', color: '#E9E7FF' },
  //     { label: '86 - 90', color: '#FFE7E7' },
  //     { label: '91 - 95', color: '#FFB9B9' },
  //     { label: '96 - 100', color: '#FFA2A2' },
  //     { label: '101 - 105', color: '#FF7373' },
  //     { label: '106 - 110', color: '#FF4545' },
  //     { label: '111 - 115', color: '#FF1717' },
  //     { label: '116 - 120', color: '#FF0000' },
  //     { label: '> 120', color: '#D10000' },
  //   ];
  // }
  if (!softball) {
    return [
      { label: `< 60 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 85 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; > 120`, color: `linear-gradient(to right, #1500FF 0%, #E9E7FF 50%, #D10000 100%)` },
      // { label: '85', color: `linear-gradient(to right, #E9E7FF 0%, #FFE7E7 100%)` },
      // { label: '> 120', color: `linear-gradient(to right, #FFE7E7 0%, #D10000 100%)` },
    ];
  }
  // return [
  //   { label: '< 25', color: '#1500FF' },
  //   { label: '26 - 30', color: '#3F2EFF' },
  //   { label: '31 - 35', color: '#6A5CFF' },
  //   { label: '36 - 40', color: '#948BFF' },
  //   { label: '41 - 45', color: '#BFB9FF' },
  //   { label: '46 - 50', color: '#E9E7FF' },
  //   { label: '51 - 55', color: '#FFE7E7' },
  //   { label: '56 - 60', color: '#FFB9B9' },
  //   { label: '61 - 65', color: '#FFA2A2' },
  //   { label: '66 - 70', color: '#FF7373' },
  //   { label: '71 - 75', color: '#FF4545' },
  //   { label: '76 - 80', color: '#FF1717' },
  //   { label: '81 - 85', color: '#FF0000' },
  //   { label: '> 85', color: '#D10000' },
  // ];
  return [
    { label: `< 25 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 55 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; > 85`, color: `linear-gradient(to right, #1500FF 0%, #E9E7FF 50%, #D10000 100%)` },
    // { label: '< 25', color: `linear-gradient(to right, #1500FF 0%, #E9E7FF 100%)` },
    // { label: '55', color: `linear-gradient(to right, #E9E7FF 0%, #FFE7E7 100%)` },
    // { label: '> 85', color: `linear-gradient(to right, #FFE7E7 0%, #D10000 100%)` },
  ];
};

export const getSprayChartRangeColor = (softball, data, keyToColor) => {
  return data.map(item => ({
    ...item,
    color: sprayChartStaticColor(softball, item[keyToColor]),
  }));
};

const sprayChartStaticColor = (softball, value) => {
  if (!softball) {
    if (value < 60) {
      return '#1500FF';
    }
    if (value >= 60 && value < 65) {
      return '#3F2EFF';
    }
    if (value >= 65 && value < 70) {
      return '#6A5CFF';
    }
    if (value >= 70 && value < 75) {
      return '#948BFF';
    }
    if (value >= 75 && value < 80) {
      return '#BFB9FF';
    }
    if (value >= 80 && value < 85) {
      return '#E9E7FF';
    }
    // red
    if (value >= 85 && value < 90) {
      return '#FFE7E7';
    }
    if (value >= 90 && value < 95) {
      return '#FFB9B9';
    }
    if (value >= 95 && value < 100) {
      return '#FFA2A2';
    }
    if (value >= 100 && value < 105) {
      return '#FF7373';
    }
    if (value >= 105 && value < 110) {
      return '#FF4545';
    }
    if (value >= 110 && value < 115) {
      return '#FF1717';
    }
    if (value >= 115 && value < 120) {
      return '#FF0000';
    }
    if (value >= 120) {
      return '#D10000';
    }
  }
  if (value < 30) {
    return '#1500FF';
  }
  if (value >= 30 && value < 35) {
    return '#3F2EFF';
  }
  if (value >= 35 && value < 40) {
    return '#6A5CFF';
  }
  if (value >= 40 && value < 45) {
    return '#948BFF';
  }
  if (value >= 45 && value < 50) {
    return '#BFB9FF';
  }
  if (value >= 50 && value < 55) {
    return '#E9E7FF';
  }
  // red
  if (value >= 55 && value < 60) {
    return '#FFE7E7';
  }
  if (value >= 60 && value < 65) {
    return '#FFB9B9';
  }
  if (value >= 65 && value < 70) {
    return '#FFA2A2';
  }
  if (value >= 70 && value < 75) {
    return '#FF7373';
  }
  if (value >= 75 && value < 80) {
    return '#FF4545';
  }
  if (value >= 80 && value < 85) {
    return '#FF1717';
  }
  if (value >= 85 && value < 90) {
    return '#FF0000';
  }
  return '#D10000';
};

//reorder playresult
export const sortByPlayResult = (data, includeArr) => {
  var ordering = {};
  for (var i = 0; i < includeArr.length; i++) {
    ordering[includeArr[i]] = i;
    data.sort(function (a, b) {
      return (ordering[a] - ordering[b]) /* || a.name.localeCompare(b.name); */
    });
  }
  return data
}
