import React, { Component } from "react";
// redux
import { getTeamSummaryData } from "../../store/actions";
import { connect } from "react-redux";
// components
import Header from "../../containers/header";
import HeroBanner from "./common/heroBanner";
import TabPanel from "./common/tabPanel";
import AppliedFilter from "./common/appliedFilter";
import FilterForm from "./common/filterForm";
import "rc-slider/assets/index.css";
import moment from "moment";
import { getArrayOfPrintAreaIdsTeam, getSeasonFilterDefaultValues } from "../../shared/function";
import reactDom from "react-dom";
import HittersBattedball from "./BattedballTable";
import PitchersBattedball from "./BattedballTable";
import HittersPlatediscipline from "./PlatedisciplineTable";
import PitchersPlatediscipline from "./PlatedisciplineTable";
import HittersBlastmotion from "./HittersBlastmotion";
import PitchersPitchmetrics from "./PitchersPitchmetrics";
import { printToPDFMultiple } from "../../shared/printReportUtils";
import Loader from "../../shared/loader";

var arr = [];
class Teamsummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: "",
      isshow: false,
      rangeVal: 0,
      teamSummaryTableData: [],
      sidebarList: [{ title: "Home", path: "" }],
      summaryData: {
        tab: "hitters_batted_ball",
        season: moment().year(),
        date_from: "",
        date_to: "",
        event_type: "",
        home_away: "",
        hand: "",
        pitch_type: "",
        count: "",
        bbe_to: "",
        bbe_from: "",
        fall_spring: "",
        velocity_to: "",
        velocity_from: "",
        exit_velocity_to: "",
        exit_velocity_from: "",
        spin_rate_to: "",
        spin_rate_from: "",
        launch_angle_to: "",
        launch_angle_from: "",
        efficiency_to: "",
        efficiency_from: "",
        vertical_break_to: "",
        vertical_break_from: "",
        induced_vertical_break_to: "",
        induced_vertical_break_from: "",
        horizontal_break_to: "",
        horizontal_break_from: "",
        vertical_approach_angle_to: "",
        vertical_approach_angle_from: "",
        horz_approach_angle_to: "",
        horz_approach_angle_from: "",
        release_height_to: "",
        release_height_from: "",
        extension_to: "",
        extension_from: "",
      },
      teamInfo: {
        name: "",
        logo: "",
      },
      isSeasonFilter: false,
      isFallSpringFilter: false,
      startYear: moment().year().toString(),
      lastSeason: moment().year().toString(),
      seasonList: [],
      minDate: "01/01/" + (Number(moment().format("YYYY")) - 1),
      maxDate: "31/12/" + (Number(moment().format("YYYY")) + 1),
      isPrinting: false
    };
    this.handleToggle = this.handleToggle.bind(this);
  }

  componentDidMount() {
    document.body.classList.add("teamsummarylist");
    this.teamSummaryData("hitters_batted_ball");

    if (localStorage.getItem("teamData") !== null) {
      this.setState(
        {
          teamInfo: {
            name: JSON.parse(localStorage.getItem("teamData")).name,
            // logo: JSON.parse(localStorage.getItem("teamData")).hasOwnProperty(
            //   "teamLogo"
            // )
            //   ? JSON.parse(localStorage.getItem("teamData")).teamLogo?.filePath
            //     ? JSON.parse(localStorage.getItem("teamData")).teamLogo?.filePath : "" : "",
          },
        },
        () => {
          arr = [];
          if (this.state.summaryData.tab === "hitters_blast_motion") {
            arr.push("season");
          } else {
            arr.push("season", "fall_spring");
          }
        }
      );
    }
  }

  // fetch summary data
  teamSummaryData = (summaryTab) => {
    let data = {
      tab: summaryTab,
      season: Number(this.state.summaryData.season) || "",
      date_from: moment.utc(moment(this.state.summaryData.date_from).format('DD-MM-YYYY'), 'DD-MM-YYYY').toISOString(),
      date_to: moment.utc(moment(this.state.summaryData.date_to).format('DD-MM-YYYY'), 'DD-MM-YYYY').toISOString(),
      event_type: this.state.summaryData.event_type,
      home_away: this.state.summaryData.home_away,
      hand: this.state.summaryData.hand,
      pitch_type: this.state.summaryData.pitch_type,
      count: this.state.summaryData.count,
      bbe_to: this.state.summaryData.bbe_to === this.state.bbe_max ? "" : this.state.summaryData.bbe_to,
      bbe_from: this.state.summaryData.bbe_from,
      fall_spring: this.state.summaryData.tab === "hitters_blast_motion" ? "" : this.state.summaryData.fall_spring,
      velocity_from: (this.state.summaryData.velocity_from === 0 && this.state.summaryData.velocity_to === 100) ? "" : this.state.summaryData.velocity_from,
      velocity_to: (this.state.summaryData.velocity_from === 0 && this.state.summaryData.velocity_to === 100) ? "" : this.state.summaryData.velocity_to,
      exit_velocity_from: (this.state.summaryData.exit_velocity_from === 0 && this.state.summaryData.exit_velocity_to === 120) ? "" : this.state.summaryData.exit_velocity_from,
      exit_velocity_to: (this.state.summaryData.exit_velocity_from === 0 && this.state.summaryData.exit_velocity_to === 120) ? "" : this.state.summaryData.exit_velocity_to,
      spin_rate_from: (this.state.summaryData.spin_rate_from === 0 && this.state.summaryData.spin_rate_to === 3500) ? "" : this.state.summaryData.spin_rate_from,
      spin_rate_to: (this.state.summaryData.spin_rate_from === 0 && this.state.summaryData.spin_rate_to === 3500) ? "" : this.state.summaryData.spin_rate_to,
      launch_angle_from: (this.state.summaryData.launch_angle_from === -90 && this.state.summaryData.launch_angle_to === 90) ? "" : this.state.summaryData.launch_angle_from,
      launch_angle_to: (this.state.summaryData.launch_angle_from === -90 && this.state.summaryData.launch_angle_to === 90) ? "" : this.state.summaryData.launch_angle_to,
      efficiency_from: (this.state.summaryData.efficiency_from === 0 && this.state.summaryData.efficiency_to === 100) ? "" : this.state.summaryData.efficiency_from,
      efficiency_to: (this.state.summaryData.efficiency_from === 0 && this.state.summaryData.efficiency_to === 100) ? "" : this.state.summaryData.efficiency_to,
      vertical_break_from: (this.state.summaryData.vertical_break_from === -90 && this.state.summaryData.vertical_break_to === 0) ? "" : this.state.summaryData.vertical_break_from,
      vertical_break_to: (this.state.summaryData.vertical_break_from === -90 && this.state.summaryData.vertical_break_to === 0) ? "" : this.state.summaryData.vertical_break_to,
      induced_vertical_break_from: (this.state.summaryData.induced_vertical_break_from === -30 && this.state.summaryData.induced_vertical_break_to === 30) ? "" : this.state.summaryData.induced_vertical_break_from,
      induced_vertical_break_to: (this.state.summaryData.induced_vertical_break_from === -30 && this.state.summaryData.induced_vertical_break_to === 30) ? "" : this.state.summaryData.induced_vertical_break_to,
      horizontal_break_from: (this.state.summaryData.horizontal_break_from === -30 && this.state.summaryData.horizontal_break_to === 30) ? "" : this.state.summaryData.horizontal_break_from,
      horizontal_break_to: (this.state.summaryData.horizontal_break_from === -30 && this.state.summaryData.horizontal_break_to === 30) ? "" : this.state.summaryData.horizontal_break_to,
      vertical_approach_angle_from: (this.state.summaryData.vertical_approach_angle_from === -10 && this.state.summaryData.vertical_approach_angle_to === 0) ? "" : this.state.summaryData.vertical_approach_angle_from,
      vertical_approach_angle_to: (this.state.summaryData.vertical_approach_angle_from === -10 && this.state.summaryData.vertical_approach_angle_to === 0) ? "" : this.state.summaryData.vertical_approach_angle_to,
      horz_approach_angle_from: (this.state.summaryData.horz_approach_angle_from === -10 && this.state.summaryData.horz_approach_angle_to === 10) ? "" : this.state.summaryData.horz_approach_angle_from,
      horz_approach_angle_to: (this.state.summaryData.horz_approach_angle_from === -10 && this.state.summaryData.horz_approach_angle_to === 10) ? "" : this.state.summaryData.horz_approach_angle_to,
      release_height_from: (this.state.summaryData.release_height_from === 0 && this.state.summaryData.release_height_to === 10) ? "" : this.state.summaryData.release_height_from,
      release_height_to: (this.state.summaryData.release_height_from === 0 && this.state.summaryData.release_height_to === 10) ? "" : this.state.summaryData.release_height_to,
      extension_from: (this.state.summaryData.extension_from === 0 && this.state.summaryData.extension_to === 10) ? "" : this.state.summaryData.extension_from,
      extension_to: (this.state.summaryData.extension_from === 0 && this.state.summaryData.extension_to === 10) ? "" : this.state.summaryData.extension_to,
    };

    this.props.fetchTeamSummaryData(data, (res) => {
      // if(res.flag !== false){
      this.setState(
        (prevState) => ({
          ...prevState,
          teamSummaryTableData: res.data,
          summaryData: {
            ...prevState.summaryData,
            season: !this.state.isSeasonFilter ? moment().year() : this.state.summaryData.season,
            fall_spring: !this.state.isFallSpringFilter ? res.fall_spring : this.state.summaryData.fall_spring,
            // bbe_to: this.state.summaryData.bbe_to || (this.state.summaryData.bbe_from === 0 && this.state.summaryData.bbe_to === 0) ? this.state.summaryData.bbe_to : res.bbe_max,
            bbe_to:  res.bbe_max,
            velocity_from: this.state.summaryData.velocity_from || (this.state.summaryData.velocity_from === 0 && this.state.summaryData.velocity_to === 0) ? this.state.summaryData.velocity_from : 0,
            velocity_to: this.state.summaryData.velocity_to || (this.state.summaryData.velocity_from === 0 && this.state.summaryData.velocity_to === 0) ? this.state.summaryData.velocity_to : 100,
            exit_velocity_from: this.state.summaryData.exit_velocity_from || (this.state.summaryData.exit_velocity_from === 0 && this.state.summaryData.exit_velocity_to === 0) ? this.state.summaryData.exit_velocity_from : 0,
            exit_velocity_to: this.state.summaryData.exit_velocity_to || (this.state.summaryData.exit_velocity_from === 0 && this.state.summaryData.exit_velocity_to === 0) ? this.state.summaryData.exit_velocity_to : 120,
            spin_rate_from: this.state.summaryData.spin_rate_from || (this.state.summaryData.spin_rate_from === 0 && this.state.summaryData.spin_rate_to === 0) ? this.state.summaryData.spin_rate_from : 0,
            spin_rate_to: this.state.summaryData.spin_rate_to || (this.state.summaryData.spin_rate_from === 0 && this.state.summaryData.spin_rate_to === 0) ? this.state.summaryData.spin_rate_to : 3500,
            launch_angle_from: this.state.summaryData.launch_angle_from || (this.state.summaryData.launch_angle_from === 0 && this.state.summaryData.launch_angle_to === 0) ? this.state.summaryData.launch_angle_from : -90,
            launch_angle_to: this.state.summaryData.launch_angle_to || (this.state.summaryData.launch_angle_from === 0 && this.state.summaryData.launch_angle_to === 0) ? this.state.summaryData.launch_angle_to : 90,
            efficiency_from: this.state.summaryData.efficiency_from || (this.state.summaryData.efficiency_from === 0 && this.state.summaryData.efficiency_to === 0) ? this.state.summaryData.efficiency_from : 0,
            efficiency_to: this.state.summaryData.efficiency_to || (this.state.summaryData.efficiency_from === 0 && this.state.summaryData.efficiency_to === 0) ? this.state.summaryData.efficiency_to : 100,
            vertical_break_from: this.state.summaryData.vertical_break_from || (this.state.summaryData.vertical_break_from === 0 && this.state.summaryData.vertical_break_to === 0) ? this.state.summaryData.vertical_break_from : -90,
            vertical_break_to: this.state.summaryData.vertical_break_to || (this.state.summaryData.vertical_break_from === 0 && this.state.summaryData.vertical_break_to === 0) ? this.state.summaryData.vertical_break_to : 0,
            induced_vertical_break_from: this.state.summaryData.induced_vertical_break_from || (this.state.summaryData.induced_vertical_break_from === 0 && this.state.summaryData.induced_vertical_break_to === 0) ? this.state.summaryData.induced_vertical_break_from : -30,
            induced_vertical_break_to: this.state.summaryData.induced_vertical_break_to || (this.state.summaryData.induced_vertical_break_from === 0 && this.state.summaryData.induced_vertical_break_to === 0) ? this.state.summaryData.induced_vertical_break_to : 30,
            horizontal_break_from: this.state.summaryData.horizontal_break_from || (this.state.summaryData.horizontal_break_from === 0 && this.state.summaryData.horizontal_break_to === 0) ? this.state.summaryData.horizontal_break_from : -30,
            horizontal_break_to: this.state.summaryData.horizontal_break_to || (this.state.summaryData.horizontal_break_from === 0 && this.state.summaryData.horizontal_break_to === 0) ? this.state.summaryData.horizontal_break_to : 30,
            vertical_approach_angle_from: this.state.summaryData.vertical_approach_angle_from || (this.state.summaryData.vertical_approach_angle_from === 0 && this.state.summaryData.vertical_approach_angle_to === 0) ? this.state.summaryData.vertical_approach_angle_from : -10,
            vertical_approach_angle_to: this.state.summaryData.vertical_approach_angle_to || (this.state.summaryData.vertical_approach_angle_from === 0 && this.state.summaryData.vertical_approach_angle_to === 0) ? this.state.summaryData.vertical_approach_angle_to : 0,
            horz_approach_angle_from: this.state.summaryData.horz_approach_angle_from || (this.state.summaryData.horz_approach_angle_from === 0 && this.state.summaryData.horz_approach_angle_to === 0) ? this.state.summaryData.horz_approach_angle_from : -10,
            horz_approach_angle_to: this.state.summaryData.horz_approach_angle_to || (this.state.summaryData.horz_approach_angle_from === 0 && this.state.summaryData.horz_approach_angle_to === 0) ? this.state.summaryData.horz_approach_angle_to : 10,
            release_height_from: this.state.summaryData.release_height_from || (this.state.summaryData.release_height_from === 0 && this.state.summaryData.release_height_to === 0) ? this.state.summaryData.release_height_from : 0,
            release_height_to: this.state.summaryData.release_height_to || (this.state.summaryData.release_height_from === 0 && this.state.summaryData.release_height_to === 0) ? this.state.summaryData.release_height_to : 10,
            extension_from: this.state.summaryData.extension_from || (this.state.summaryData.extension_from === 0 && this.state.summaryData.extension_to === 0) ? this.state.summaryData.extension_from : 0,
            extension_to: this.state.summaryData.extension_to || (this.state.summaryData.extension_from === 0 && this.state.summaryData.extension_to === 0) ? this.state.summaryData.extension_to : 10,
          },
          startYear: res.start_year ? res.start_year : moment().year(),
          lastSeason: res.last_season,
          bbe_max: res.bbe_max
        }),
        () => {
          this.setState({
            seasonList: getSeasonFilterDefaultValues(
              this.state.startYear,
              this.state.lastSeason
            ),
          });
        }
      );
      // }
    });
  };
  
  //   toggle team summary data
  toggleTeamSummary = (e) => {
    // localStorage.setItem("activeType", e === "hitters" ? "Hitting" : "Pitching")
    localStorage.setItem("activeType", e.includes("hitters") ? "Hitting" : "Pitching")
    if (e.split('_')[0] != this.state.summaryData.tab.split('_')[0] || e == "hitters_blast_motion") {
      this.setState(
        {
          isSeasonFilter: false,
          isFallSpringFilter: false,
          summaryData: {
            season: this.state.summaryData.season,
            date_from: "",
            date_to: "",
            event_type: "",
            home_away: "",
            hand: "",
            pitch_type: "",
            count: "",
            bbe_to: "",
            bbe_from: "",
            tab: e,
            fall_spring: !this.state.isFallSpringFilter ? "" : this.state.summaryData.fall_spring,
            velocity_to: "",
            velocity_from: "",
            exit_velocity_to: "",
            exit_velocity_from: "",
            spin_rate_to: "",
            spin_rate_from: "",
            launch_angle_to: "",
            launch_angle_from: "",
            efficiency_to: "",
            efficiency_from: "",
            vertical_break_to: "",
            vertical_break_from: "",
            induced_vertical_break_to: "",
            induced_vertical_break_from: "",
            horizontal_break_to: "",
            horizontal_break_from: "",
            vertical_approach_angle_to: "",
            vertical_approach_angle_from: "",
            horz_approach_angle_to: "",
            horz_approach_angle_from: "",
            release_height_to: "",
            release_height_from: "",
            extension_to: "",
            extension_from: "",
          },
        },
        () => {
          arr = [];
          if (this.state.summaryData.tab === "hitters_blast_motion") {
            arr.push("season");
          } else {
            arr.push("season", "fall_spring");
          }
          this.teamSummaryData(e);
        }
      );
    }else{
      if(this.state.summaryData.tab=="hitters_blast_motion"){
        arr = [];
        arr.push("season", "fall_spring");
      }
      this.setState(
        {
          isSeasonFilter: false,
          isFallSpringFilter: false,
          summaryData: {
            season: this.state.summaryData.season ? this.state.summaryData.season : "",
            date_from: this.state.summaryData.date_from ? this.state.summaryData.date_from :"",
            date_to: this.state.summaryData.date_to ? this.state.summaryData.date_to :"",
            event_type: this.state.summaryData.event_type ? this.state.summaryData.event_type :"",
            home_away: this.state.summaryData.home_away ? this.state.summaryData.home_away :"",
            hand: this.state.summaryData.hand ? this.state.summaryData.hand :"",
            pitch_type: this.state.summaryData.pitch_type ? this.state.summaryData.pitch_type :this.state.summaryData.pitch_type ? this.state.summaryData.pitch_type :"",
            count: this.state.summaryData.count ? this.state.summaryData.count :"",
            bbe_to: this.state.summaryData.bbe_to ? this.state.summaryData.bbe_to :"",
            bbe_from: this.state.summaryData.bbe_from ? this.state.summaryData.bbe_from :"",
            tab: e,
            fall_spring: !this.state.isFallSpringFilter ? "" : this.state.summaryData.fall_spring,
            velocity_to: this.state.summaryData.velocity_to ? this.state.summaryData.velocity_to :"",
            velocity_from: this.state.summaryData.velocity_from ? this.state.summaryData.velocity_from :"",
            exit_velocity_to: this.state.summaryData.exit_velocity_to ? this.state.summaryData.exit_velocity_to :"",
            exit_velocity_from: this.state.summaryData.exit_velocity_from ? this.state.summaryData.exit_velocity_from :"",
            spin_rate_to: this.state.summaryData.spin_rate_to ? this.state.summaryData.spin_rate_to :"",
            spin_rate_from: this.state.summaryData.spin_rate_from ? this.state.summaryData.spin_rate_from :"",
            launch_angle_to: this.state.summaryData.launch_angle_to ? this.state.summaryData.launch_angle_to :"",
            launch_angle_from: this.state.summaryData.launch_angle_from ? this.state.summaryData.launch_angle_from :"",
            efficiency_to: this.state.summaryData.efficiency_to ? this.state.summaryData.efficiency_to :"",
            efficiency_from: this.state.summaryData.efficiency_from ? this.state.summaryData.efficiency_from :"",
            vertical_break_to: this.state.summaryData.vertical_break_to ? this.state.summaryData.vertical_break_to :"",
            vertical_break_from: this.state.summaryData.vertical_break_from ? this.state.summaryData.vertical_break_from :"",
            induced_vertical_break_to: this.state.summaryData.induced_vertical_break_to ? this.state.summaryData.induced_vertical_break_to :"",
            induced_vertical_break_from: this.state.summaryData.induced_vertical_break_from ? this.state.summaryData.induced_vertical_break_from :"",
            horizontal_break_to: this.state.summaryData.horizontal_break_to ? this.state.summaryData.horizontal_break_to :"",
            horizontal_break_from: this.state.summaryData.horizontal_break_from ? this.state.summaryData.horizontal_break_from :"",
            vertical_approach_angle_to: this.state.summaryData.vertical_approach_angle_to ? this.state.summaryData.vertical_approach_angle_to :"",
            vertical_approach_angle_from: this.state.summaryData.vertical_approach_angle_from ? this.state.summaryData.vertical_approach_angle_from :"",
            horz_approach_angle_to: this.state.summaryData.horz_approach_angle_to ? this.state.summaryData.horz_approach_angle_to :"",
            horz_approach_angle_from: this.state.summaryData.horz_approach_angle_from ? this.state.summaryData.horz_approach_angle_from :"",
            release_height_to: this.state.summaryData.release_height_to ? this.state.summaryData.release_height_to :"",
            release_height_from: this.state.summaryData.release_height_from ? this.state.summaryData.release_height_from :"",
            extension_to: this.state.summaryData.extension_to ? this.state.summaryData.extension_to :"",
            extension_from: this.state.summaryData.extension_from ? this.state.summaryData.extension_from :"",
          },
        },
        () => {
          // arr = [];
          if (this.state.summaryData.tab === "hitters_blast_motion") {
            arr.push("season");
          }
          this.teamSummaryData(e);
        }
      );
    }
  };

  handleToggle() {
    this.setState({
      isshow: !this.state.isshow,
    });
  }

  handleCallback = (index) => {
    this.setState({
      tabs: index,
    });
  };

  // filters functions
  // for date range

  handleChange = (e) => {
    const { name, value } = e.target;
    let old = arr.some((item) => {
      return item === name;
    });

    if (!old) {
      if (name !== "date_to") {
        arr.push(name);
      }
    }

    if (name === "date_from" || name === "date_to") {
      this.setState((prevState) => ({
        ...prevState,
        summaryData: {
          ...prevState.summaryData,
          [name]: new Date(value).toISOString(),
        },
      }));
    } else if (name === "season") {
      let sub = "01/01/" + (Number(value) - 1); // min (mm/dd/yyyy)
      let add = "12/31/" + (Number(value) + 1); // max (mm/dd/yyyy)

      this.setState((prevState) => ({
        ...prevState,
        isSeasonFilter: true,
        summaryData: {
          ...prevState.summaryData,
          [name]: value,
          fall_spring: "both"
        },
        minDate: sub,
        maxDate: add
      }));
    } else if (name === "fall_spring") {
      this.setState((prevState) => ({
        ...prevState,
        isFallSpringFilter: true,
        summaryData: {
          ...prevState.summaryData,
          [name]: value,
        },
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        summaryData: {
          ...prevState.summaryData,
          [name]: value,
        },
      }));
    }
  };

  handleDateChange = (e, name) => {
    let old = arr.some((item) => {
      return item === name;
    });

    if (!old) {
      //   if (name !== "date_to") {
      arr.push(name);
      //   }
    }

    if (name === "date_from") {
      arr.indexOf("date_to") > -1 && arr.splice(arr.indexOf("date_to"), 1);
    }

    if (name === "date_from") {
      this.setState((prevState) => ({
        ...prevState,
        summaryData: {
          ...prevState.summaryData,
          [name]: e,
          date_to: "",
        },
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        summaryData: {
          ...prevState.summaryData,
          [name]: e,
        },
      }));
    }
  };

  // for change range picker
  handleRangeChange = (e) => {
    let oldRange = arr.some((item) => {
      return item === "bbe_to";
    });

    if (!oldRange) {
      arr.push("bbe_to");
    }
    this.setState((prevState) => ({
      ...prevState,
      summaryData: {
        ...prevState.summaryData,
        bbe_to: e[1],
        bbe_from: e[0],
      },
    }));
  };

  // for all range filter custom handle change
  handleRangeChangeCustom = (e, rangeFrom, rangeTo) => {
    let oldRange = arr.some((item) => {
      return item === rangeFrom;
    });

    if (!oldRange) {
      arr.push(rangeFrom);
    }

    this.setState((prevState) => ({
      ...prevState,
      summaryData: {
        ...prevState.summaryData,
        [rangeTo]: e[1],
        [rangeFrom]: e[0],
      }
    }));
  };

  // for apply filters
  applyFilter = () => {
    this.teamSummaryData(this.state.summaryData.tab);
  }

  // remove selected filter
  removeFilter = (e) => {
    arr.map((d, i) => {
      if (d === e) {
        arr.splice(i, 1);
      }
    });

    if (e === "bbe_to" || e === "velocity_from" || e === "exit_velocity_from" || e === "spin_rate_from" || e === "launch_angle_from" || e === "efficiency_from" || e === "vertical_break_from" ||
      e === "induced_vertical_break_from" || e === "horizontal_break_from" || e === "vertical_approach_angle_from" || e === "horz_approach_angle_from" || e === "release_height_from" || e === "extension_from") {
      this.setState((prevState) => ({
        ...prevState,
        summaryData: {
          ...prevState.summaryData,
          bbe_to: "",
          bbe_from: "",
          velocity_from: e === "velocity_from" ? "" : this.state.summaryData.velocity_from,
          velocity_to: e === "velocity_from" ? "" : this.state.summaryData.velocity_to,
          exit_velocity_from: e === "exit_velocity_from" ? "" : this.state.summaryData.exit_velocity_from,
          exit_velocity_to: e === "exit_velocity_from" ? "" : this.state.summaryData.exit_velocity_to,
          spin_rate_from: e === "spin_rate_from" ? "" : this.state.summaryData.spin_rate_from,
          spin_rate_to: e === "spin_rate_from" ? "" : this.state.summaryData.spin_rate_to,
          launch_angle_from: e === "launch_angle_from" ? "" : this.state.summaryData.launch_angle_from,
          launch_angle_to: e === "launch_angle_from" ? "" : this.state.summaryData.launch_angle_to,
          efficiency_from: e === "efficiency_from" ? "" : this.state.summaryData.efficiency_from,
          efficiency_to: e === "efficiency_from" ? "" : this.state.summaryData.efficiency_to,
          vertical_break_from: e === "vertical_break_from" ? "" : this.state.summaryData.vertical_break_from,
          vertical_break_to: e === "vertical_break_from" ? "" : this.state.summaryData.vertical_break_to,
          induced_vertical_break_from: e === "induced_vertical_break_from" ? "" : this.state.summaryData.induced_vertical_break_from,
          induced_vertical_break_to: e === "induced_vertical_break_from" ? "" : this.state.summaryData.induced_vertical_break_to,
          horizontal_break_from: e === "horizontal_break_from" ? "" : this.state.summaryData.horizontal_break_from,
          horizontal_break_to: e === "horizontal_break_from" ? "" : this.state.summaryData.horizontal_break_to,
          vertical_approach_angle_from: e === "vertical_approach_angle_from" ? "" : this.state.summaryData.vertical_approach_angle_from,
          vertical_approach_angle_to: e === "vertical_approach_angle_from" ? "" : this.state.summaryData.vertical_approach_angle_to,
          horz_approach_angle_from: e === "horz_approach_angle_from" ? "" : this.state.summaryData.horz_approach_angle_from,
          horz_approach_angle_to: e === "horz_approach_angle_from" ? "" : this.state.summaryData.horz_approach_angle_to,
          release_height_from: e === "release_height_from" ? "" : this.state.summaryData.release_height_from,
          release_height_to: e === "release_height_from" ? "" : this.state.summaryData.release_height_to,
          extension_from: e === "extension_from" ? "" : this.state.summaryData.extension_from,
          extension_to: e === "extension_from" ? "" : this.state.summaryData.extension_to,
        },
      }),
        () => {
          this.teamSummaryData(this.state.summaryData.tab);
        });
    } else {
      this.setState((prevState) => ({
        ...prevState,
        summaryData: {
          ...prevState.summaryData,
          [e]: "",
        },
      }),
        () => {
          this.teamSummaryData(this.state.summaryData.tab);
        });
    }
  };

  handleCancelToggle = () => {
    arr = [];
    this.setState(
      {
        isshow: !this.state.isshow,
        isSeasonFilter: false,
        isFallSpringFilter: false,
        summaryData: {
          tab: this.state.summaryData.tab,
          season: moment().format("YYYY"),
          date_from: "",
          date_to: "",
          event_type: "",
          home_away: "",
          hand: "",
          pitch_type: "",
          count: "",
          bbe_to: "",
          bbe_from: "",
          fall_spring: "",
          velocity_to: "",
          velocity_from: "",
          exit_velocity_to: "",
          exit_velocity_from: "",
          spin_rate_to: "",
          spin_rate_from: "",
          launch_angle_to: "",
          launch_angle_from: "",
          efficiency_to: "",
          efficiency_from: "",
          vertical_break_to: "",
          vertical_break_from: "",
          induced_vertical_break_to: "",
          induced_vertical_break_from: "",
          horizontal_break_to: "",
          horizontal_break_from: "",
          vertical_approach_angle_to: "",
          vertical_approach_angle_from: "",
          horz_approach_angle_to: "",
          horz_approach_angle_from: "",
          release_height_to: "",
          release_height_from: "",
          extension_to: "",
          extension_from: "",
        },
      },
      () => {
        if (this.state.summaryData.tab === "hitters_blast_motion") {
          arr.push("season");
        } else {
          arr.push("season", "fall_spring");
        }
        this.teamSummaryData(this.state.summaryData.tab);
      }
    );
  };

  handleDateChangeRaw = (e) => {
    e.preventDefault();
  };

  print = async () => {
    await this.setState({ isPrinting: true })
    await this.updatefontsize()
    setTimeout(async() => {
      await printToPDFMultiple(`TeamSummary`, this.state.teamSummaryTableData.length > 10 ? getArrayOfPrintAreaIdsTeam(this.state.teamSummaryTableData) : ["antTablePrintId"]);
      this.setState({ isPrinting: false })
    }, 2000)
  };

  async updatefontsize(loopCount = 0) {
    const content = document.querySelector(".print--table--wrp .ant-table-content");

    const table = content.querySelector("table")
    const thList = document.querySelectorAll(".print--table--wrp .ant-table-thead > tr > th")
    const tdList = document.querySelectorAll(".print--table--wrp tbody td")

    if (content.offsetWidth < table.offsetWidth) {
      for (var i = 0; i < thList.length; i++) {
        thList[i].style.fontSize = `${14 - loopCount}px`;
        if (loopCount - 4 > 0) thList[i].style.padding = `${4 - loopCount}px`;
      }
      for (var i = 0; i < tdList.length; i++) {
        tdList[i].style.fontSize = `${14 - loopCount}px`;
        tdList[i].style.padding = `${16 - loopCount}px ${10 - loopCount}px`;
      }
      await this.updatefontsize(loopCount + 1)
    }
    const length = document.querySelectorAll('.ant-table-thead th').length || 0
    for (let i = 0; i < length; i++) {
      document.querySelectorAll('.ant-table-thead th')[i].style.backgroundColor = "#013662"
      document.querySelectorAll('.ant-table-thead th')[i].style.color = "#ffffff"
    }
    return true
  }

  render() {
    const { teamSummaryTableData, tabs, summaryData, teamInfo, seasonList, minDate, maxDate, bbe_max, isPrinting } = this.state;

    return (
      <>
        {isPrinting && <Loader Teamfullview={true} />}
        <div
          className={`page-content-wrapper ml-0 w-100 ${tabs === 0 || tabs === 1 ? "tabactive" : ""
            }`}
        >
          <Header
            parentCallback={this.handleCallback}
            teamInfo={this.props.teamInfo}
          />
          {tabs !== 0 && tabs !== 1 && (
            <div className="without_footer">
              <div className="main-div">
                {/* <HeroBanner teamInfo={teamInfo} /> */}
                <section className="teamsummary">
                  <div className="container-fluid" style={isPrinting ? { position: 'fixed', height: '100%', width: '100%', top: 0, left: 0, background: '#fff' } : {}}>
                    {/* <div id="titlepage">
                      {isPrinting ? <p>{localStorage.getItem("activeType")}{": "}{summaryData.tab === "hitters_batted_ball" ? "Batted Ball" : summaryData.tab === "hitters_plate_discipline" ? "Plate Discipline" : summaryData.tab === "hitters_blast_motion" ? "Blast Motion" :
                        summaryData.tab === "pitchers_batted_ball" ? "Batted Ball" : summaryData.tab === "pitchers_plate_discipline" ? "Plate Discipline" : summaryData.tab === "pitchers_pitch_metrics" ? "Pitch Metrics" : ""}</p> : ""}
                    </div> */}
                    <TabPanel
                      summaryData={summaryData}
                      toggleTeamSummary={this.toggleTeamSummary}
                      arr={arr} />

                    <AppliedFilter
                      summaryData={this.state.summaryData}
                      removeFilter={this.removeFilter}
                      arr={arr}
                      handleToggle={this.handleToggle}
                      print={this.print}
                      bbeMax={bbe_max} />

                    {this.state.isshow && (
                      <>
                        <FilterForm
                          summaryData={this.state.summaryData}
                          seasonList={seasonList}
                          handleDateChange={this.handleDateChange}
                          handleChange={this.handleChange}
                          handleRangeChange={this.handleRangeChange}
                          applyFilter={this.applyFilter}
                          handleCancelToggle={this.handleCancelToggle}
                          handleRangeChangeCustom={this.handleRangeChangeCustom}
                          minDate={minDate}
                          maxDate={maxDate}
                          bbeMax={bbe_max} />
                      </>
                    )}

                    {/* Hitting table */}
                    {summaryData.tab === "hitters_batted_ball" && (
                      <div id="antTablePrintId">
                        <HittersBattedball tableData={teamSummaryTableData} title="hitter" isPrinting={isPrinting} />
                      </div>
                    )}
                    {summaryData.tab === "hitters_plate_discipline" && (
                      <div id="antTablePrintId">
                        <HittersPlatediscipline tableData={teamSummaryTableData} title="hitter" isPrinting={isPrinting} />
                      </div>
                    )}
                    {summaryData.tab === "hitters_blast_motion" && (
                      <div id="antTablePrintId">
                        <HittersBlastmotion tableData={teamSummaryTableData} title="hitter" isPrinting={isPrinting} />
                      </div>
                    )}
                    {/* Pitching table */}
                    {summaryData.tab === "pitchers_batted_ball" && (
                      <div id="antTablePrintId">
                        <PitchersBattedball tableData={teamSummaryTableData} title="pitcher" isPrinting={isPrinting} />
                      </div>
                    )}
                    {summaryData.tab === "pitchers_plate_discipline" && (
                      <div id="antTablePrintId">
                        <PitchersPlatediscipline tableData={teamSummaryTableData} title="pitcher" isPrinting={isPrinting} />
                      </div>
                    )}
                    {summaryData.tab === "pitchers_pitch_metrics" && (
                      <div id="antTablePrintId">
                        <PitchersPitchmetrics tableData={teamSummaryTableData} title="pitcher" isPrinting={isPrinting} />
                      </div>
                    )}
                  </div>
                </section>
              </div>
            </div>
          )}
        </div>
        {/* sidebar-wrapper */}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // teamData: state.teamData,
    teamData: state.app.teamData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchTeamSummaryData: (data, next) =>
    dispatch(getTeamSummaryData(data, next)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Teamsummary);
