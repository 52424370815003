import React, { Component } from 'react';
import { connect } from "react-redux";
import { printToPDFMultiple } from "../../shared/printReportUtils";
import Teamsummary from "../teamsummary/teamsummary";
import Development from "../development/development";
import Header from "../../containers/header";
import Loader from "../../shared/loader";
import Scrimmagesummarysidebar from "../../containers/scrimmagesummarysidebar";
import Scrimmagesummary from "./ScrimmageSummary/index";
import PitchLog from "./pitchersLog/index";
import Pitchers from "./Pitchers/index";
import Hitters from "./Hitters/index";
import Umpireanalysis from "./umpireAnalysis/index"
import { getScrimmageSummaryData } from '../../store/actions';

class Scrimmage extends Component {
    constructor(props) {
        super(props);
        this.state = {
          activeTab: "",
          printAll: false,
          tabs: "",
          dataCount: 0,
          hitterFilter: [],
          pitcherFilter: [],
          mount: false,
          isStopPrint : false 
        };
    }

    increment = () => {
        this.setState(prevState => {
            return { dataCount: prevState.dataCount + 1 }
        })
    }

    decrement = () => {
        this.setState(prevState => {
            return { dataCount: prevState.dataCount - 1 }
        }, async () => {
            if (this.state.dataCount === 0 && !this.state.isStopPrint && this.state.printAll) {
                await this.setState({ dataCount: 0, isStopPrint: true })
                setTimeout(() => {
                    this.printAllPage()
                }, 2500);
            }
        })
    }

    // reduceDataCount = async () => await this.setState({ dataCount: this.state.dataCount - 1 }, async() => {
    //     if (this.state.dataCount === 0 && !this.state.isStopPrint) {
    //         await this.setState({ dataCount: 5, isStopPrint : true })
    //      setTimeout(() =>    {
    //          this.printAllPage()
    //      }, 2500);
    //     }
    // })

    componentDidMount = () => {
        let data = {
            sourceId: document.location.href.split("/")[4],
            tab: "summary",
        }
        this.props.fetchScrimmageSummaryData(data, (res) => {
            this.setState({
                hitterFilter: res.data.result.hitters_names,
                pitcherFilter: res.data.result.pitcher_names,
                eventName: res.data?.eventDetails?.event?.event_name,
                mount: true
            });
        });
    }

    // componentDidUpdate = async () => {
    //     if (this.state.dataCount === 0) {
    //         await this.setState({ dataCount: 2 })
    //         this.printAllPage()
    //     }
    // }

    printAllPage =  async () => {
        await printToPDFMultiple('scrimmageType', [
            // 'scrimmageSummaryPrint',
            // 'pitchLogSummaryPrint',
            // "pitcherSummaryPrint",
            // 'ChartPrintAreaHitter',
            // 'umpireSummaryPrint',
            'tablePrintArea',
            'mainChartPrintArea',
            'releaseStrikeChartPrintArea',
            'GameStrikeChartPrintArea',
            'sequencingChartPrintArea',
            'movementChartPrintArea',
            'lastPitchTable',
            'tablePrintAreaBatter',
            // ...JSON.parse(localStorage.getItem("hitterarr"))
        ]);
        this.setState({ printAll: false })
    }


    printAll = async () => {
        // this.setState({ printAll: true, dataCount: (!this.props.appState.ShitterfilterFlag || !this.props.appState.SpitcherfilterFlag)? 3 : 5, isStopPrint: false })
        this.setState({ printAll: true, dataCount: 0, isStopPrint: false })
    }

    handleCallback = (index) => {
        this.setState({
            tabs: index,
        });
    };

    getActiveTab = () => {
        var pathArray = window.location.pathname.split('/');
        if (!this.state.activeTab) {
            this.setState({
                activeTab: pathArray[1]
            });
        }
        if (!this.state.mount) return null
        if (this.state.printAll) {
            return (
                <>
                    <Loader />
                    {/* <div id="scrimmageSummaryPrint">
                        <Scrimmagesummary printAll={this.printAll} isPrint={this.state.printAll} reduceDataCount={this.reduceDataCount} />
                    </div> */}
                    {/* <div id="pitchLogSummaryPrint">
                        <PitchLog printAll={this.printAll} isPrint={this.state.printAll} reduceDataCount={this.reduceDataCount} />
                    </div> */}
                    <div id="pitcherSummaryPrint">
                        <Pitchers printAll={this.printAll} isPrint={this.state.printAll} increment={this.increment} decrement={this.decrement} isStopPrint={this.state.isStopPrint} pitcherFilter={this.state.pitcherFilter} />
                    </div>
                    <Hitters printAll={this.printAll} isPrint={this.state.printAll} increment={this.increment} decrement={this.decrement} isStopPrint={this.state.isStopPrint} hitterFilter={this.state.hitterFilter} />
                    {/* <div id="umpireSummaryPrint">
                        <Umpireanalysis printAll={this.printAll} isPrint={this.state.printAll} reduceDataCount={this.reduceDataCount} />
                    </div> */}
                </>
            );
        }

        switch (this.state.activeTab) {
            case "scrimmagesummary":
                return <Scrimmagesummary printAll={this.printAll} isPrint={this.state.printAll} />;
            case "s_pitchlog":
                return <PitchLog printAll={this.printAll} isPrint={this.state.printAll} />;
            case "s_pitchers":
                return <Pitchers printAll={this.printAll} isPrint={this.state.printAll} increment={this.increment} decrement={this.decrement} pitcherFilter={this.state.pitcherFilter} eventName={this.state.eventName} />;
            case "s_hitters":
                return <Hitters printAll={this.printAll} isPrint={this.state.printAll} increment={this.increment} decrement={this.decrement} hitterFilter={this.state.hitterFilter} eventName={this.state.eventName} />;
            case "s_umpireanylysis":
                return <Umpireanalysis printAll={this.printAll} isPrint={this.state.printAll} eventName={this.state.eventName} />;
            default:
                return '';
        }
    }

    render() {
        const tabs = this.state.tabs;
        return (
            <div className="d-flex gamesummary-sidebar">
                <div
                    className={`page-content-wrapper ${tabs === 0 || tabs === 1 ? "tabactive" : ""
                        }`}
                >
                    <Header parentCallback={this.handleCallback} />
                    {tabs === 0 && <Teamsummary />}
                    {tabs === 1 && <Development />}
                    {tabs !== 0 && tabs !== 1 && (
                        <>
                            <div className="main-div">
                                <div className="sidebar-content scrimmage-content">
                                    <Scrimmagesummarysidebar />
                                    <div className="content">{this.getActiveTab()}</div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    fetchScrimmageSummaryData: (data, next) => dispatch(getScrimmageSummaryData(data, next)),
    startLoader: (data, next) => dispatch({ type: "LOADING", payload: data }, next(data)),
  });
  export default connect(null, mapDispatchToProps)(Scrimmage);