import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import { histoGramChartData, sideReleaseBattersPitchTypeChartData } from "../../../../shared/function";
import { constant } from "../../../../shared/constant";
import BallrWebGL from "../../../webGL/BallrWebGL";
import PitchRWebGL from "../../../webGL/PitchRWebGL";
import Toggle from "../../../../shared/Toggle";
import domtoimage from 'dom-to-image';
import PrintImagePopup from "../../../../shared/PrintImagePopup";
import { downloadChartPrintImg } from "../../../../shared/printReportUtils";
import html2canvas from "html2canvas";
import use3DView from "../../../../shared/use3DView";

function HistoGramChart({ chartData, id, onChangeHistro, onChangeEvent, coloredBy, y_axis, x_axis, min, max, histrogram_bucket, eventProfileData, sendReq, tabslist, profilepage, isNotDownloadImgLoader, isDownloadImgLoader }) {
    const [data3D, setData3D, toggle, setToggle, onClickToggleButton, flag] = use3DView(id);
    const [isChartImg, setIsChartImg] = useState(false);
    const [clickFlag, setClickFlag] = useState(false);
    const [ballrFlag, setBallrFlag] = useState(false);
    const [tooltipData, setTooltipData] = useState(null)
    const [state, setState] = useState({
        chart: {
            type: 'column',
            marginTop: 70,
            borderWidth: 0,
            borderColor: "#D8D8D8",
            borderRadius: "8px",
        },
        title: {
            text: "",
        },
        subtitle: {
            text: "",
        },
        xAxis: {
            title: {
                text: x_axis === "exit_velocity" ? "Exit Velocity" : x_axis === "launch_angle" ? "Launch Angle" : "Launch Direction",
                style: {
                    color: "#777F8F",
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "14px",
                    fontWeight: "500",
                },
            },
            min:min,
            max:max,
            // tickInterval: 10,
            startOnTick: true,
            endOnTick: true,
            showLastLabel: true,
        },
        yAxis: {
            title: {
                text: y_axis === "count" ? "Batted Balls" : y_axis === "hits" ? "Hits" : "Hard Hit Balls",
                style: {
                    color: "#777F8F",
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "14px",
                    fontWeight: "500",
                },
            },
        },
        credits: { enabled: false },
        tooltip: {
            useHTML: true,
            hideDelay: 500,
            formatter: function () {
                // setTooltipData(this?.point);
                console.log('this?.point', this?.point)
                // ballrFlag && refview.current.GiveKeyToiframe(this?.point);
                // setBallrFlag(true)
                let tooltip = `<span style=font-weight:bold;">Exit Velocity: ${this.point.options.exit_velocity ? this.point.options.exit_velocity.toFixed(2) : "-"}</span><br>
                    <span style="font-weight:bold;">${y_axis === "count" ? "Batted Balls" : "batted"}: ${this.point.options.count ? this.point.options.count : "-"}</span><br>
                    <span style="font-weight:bold;">: ${this.point.options.count ? this.point.options.count : "-"}</span><br>
              </span>`;
              return tooltip;
            },
            // if hover or click functionality add in tooltip use pointerEvents
            style: {
                pointerEvents: 'auto'
            }
        },
        series: [],
        responsive: {
            rules: [
                {
                    condition: {
                        maxWidth: 500,
                    },
                },
            ],
        },
    });

    useEffect(() => {
        if (toggle.chartToggle === true) {
            if (chartData) {
                if (x_axis) {
                    state.xAxis = {
                        title: {
                            text: x_axis === "exit_velocity" ? "Exit Velocity" : x_axis === "launch_angle" ? "Launch Angle" : "Launch Direction",
                            style: {
                                color: "#777F8F",
                                fontFamily: "Poppins, sans-serif",
                                fontSize: "14px",
                                fontWeight: "500",
                            },
                        },
                        min: min,
                        max: max,
                        startOnTick: true,
                        endOnTick: true,
                        showLastLabel: true,
                    }
                }
                if (y_axis) {
                    state.yAxis.title = {
                        text: y_axis === "count" ? "Batted Balls" : y_axis === "hits" ? "Hits" : "Hard Hit Balls",
                        style: {
                            color: "#777F8F",
                            fontFamily: "Poppins, sans-serif",
                            fontSize: "14px",
                            fontWeight: "500",
                        },
                    }
                }
                if (histrogram_bucket) {
                    state.plotOptions ={
                        column: {
                            pointPadding: Number(histrogram_bucket) === 1 ? 0.1 :Number(histrogram_bucket) === 5 ? 0.025 :0.015,
                            borderWidth: 0,
                            groupPadding: 0,
                            shadow: false
                        }
                    }
                }
                if (chartData.length !== 0) {
                    const seriesData = histoGramChartData(chartData, coloredBy, y_axis,histrogram_bucket);
                    state.tooltip={
                        useHTML: true,
                        hideDelay: 500,
                        formatter: function () {
                            console.log('this.point', this.point)
                            // setTooltipData(this?.point);
                            // ballrFlag && refview.current.GiveKeyToiframe(this?.point);
                            // setBallrFlag(true)
                            let tooltip = `<span style=font-weight:bold;">${x_axis === "exit_velocity" ? "Exit Velocity" : x_axis === "launch_angle" ? "Launch Angle" : "Launch Direction"}: 
                            ${this.point.options._id === 0 ? "0" : this.point.options._id ? this.point.options._id : "-"} </span><br>
                            <span style="font-weight:bold;">${y_axis === "count" ? "Batted Balls" : y_axis === "hits" ? "Hits" : "Hard Hit Balls"}:
                             ${y_axis === "count" ? this.point.options.count : y_axis === "hits" ? this.point.options.hits : y_axis === "hard_hit_balls" ? this.point.options.hard_hit_balls : "-"}</span><br>
                            <span style="font-weight:bold;">${coloredBy === "exit_velocity" ? "Exit Velocity" : coloredBy === "hard_hit" ? "Hard Hit %" : coloredBy === "damage" ? "Damage %" : ""}${coloredBy === "all_one_color" ? "" : ":"}
                             ${coloredBy === "exit_velocity" ? this.point.options.exit_velocity.toFixed(1) : coloredBy === "hard_hit" ? this.point.options.hard_hit.toFixed(1) : coloredBy === "damage" ? this.point.options.damage.toFixed(1) : coloredBy === "all_one_color" ? "" : "-"}</span>
                            </span>`;
                            return tooltip;
                        },
                        // if hover or click functionality add in tooltip use pointerEvents
                        style: {
                            pointerEvents: 'auto'
                        }
                    }
                    console.log('seriesData', seriesData)
                    state.series = seriesData;
                    Highcharts.chart(id, state);
                } else {
                    state.series = [{ showInLegend: false }];
                    Highcharts.chart(id, state);
                }
            }
        } else {
            if (chartData && chartData.length !== 0) {
                let arr = chartData.map(x => x?._id) || []
                setData3D(arr)
            }
        }
    }, [chartData, toggle, y_axis, coloredBy, x_axis,histrogram_bucket]);

    // bullpen event player name change,set ballriframe initial state
    // useEffect(() => {
    //     if (ballrIframeFlag === "yes") {
    //         setBallrFlag(false)
    //         setTooltipData(null);
    //         closeBallrIframe();
    //     }
    // }, [ballrIframeFlag])

    // bullpen event player name change,set pichRIframe initial state
    // useEffect(() => {
    //     if (pitchrIframeFlag === "yes") {
    //         setData3D(null)
    //         setToggle({ chartToggle: true });
    //         closePitchrIframe();
    //     }
    // }, [pitchrIframeFlag])

    const chartImage = (id) => {
        isDownloadImgLoader();
        setClickFlag(true)
        if (document.getElementById(`img${id}`)) {
            document.getElementById(`img${id}`).remove()
        }
        setIsChartImg(true)
        downloadChartPrintImg(id, setIsChartImg, setClickFlag, isNotDownloadImgLoader)
    }

    return (
        <>
            <div className={`ground-chart ${ballrFlag ? "chart-ifream" : ""}`}>
                {/* {chartData && chartData.length !== 0 ? <Toggle toggle={toggle} onClickToggleButton={onClickToggleButton} /> : ""} */}
                <div style={{ display: toggle.chartToggle === true ? 'block' : 'none' }}>
                    {/* <div className="ifream-box">
                        {ballrFlag && <BallrWebGL tooltipData={tooltipData} id={id} index={tooltipData?._id} />}
                    </div> */}
                    <div style={{ height: "470px", margin: "0 auto" }} id={id} />
                    {/* <button className="btn blue btn-view" onClick={() => chartImage(id)} disabled={clickFlag} >Download</button> */}
                    <div className="histogram_dropdown"> 
                        <div className="select-box row">
                            <div class="select_box_inner w-25">
                                <select name="y_axis" class="custom-select" value={y_axis} onChange={onChangeHistro}>
                                    <option selected value="" disabled>Y axis</option>
                                    <option value="count">Batted Balls</option>
                                    <option value="hits">Hits</option>
                                    <option value="hard_hit_balls">Hard Hit Balls</option>
                                </select>
                            </div>
                       
                            <div class="select_box_inner w-25">
                                <select name="histrogram_field" className="custom-select" value={x_axis} onChange={onChangeEvent}>
                                    <option selected value="" disabled>X axis</option>
                                    <option value="exit_velocity">Exit Velocity</option>
                                    <option value="launch_angle">Launch Angle</option>
                                    <option value="direction">Launch Direction</option>
                                </select>
                            </div>
                       
                            <div class="select_box_inner w-25">
                                <select name="histrogram_bucket" className="custom-select" value={histrogram_bucket} onChange={onChangeEvent}>
                                    <option selected value="" disabled>Bucket Size</option>
                                    <option value={1}>1</option>
                                    <option value={5}>5</option>
                                    <option value={10}>10</option>
                                </select>
                            </div>
                      
                            <div class="select_box_inner w-25">
                                <select name="coloredBy" className="custom-select" value={coloredBy} onChange={onChangeHistro}>
                                    <option selected value="" disabled>Colored By</option>
                                    <option value="all_one_color">All one color</option>
                                    <option value="exit_velocity">Exit Velocity</option>
                                    <option value="hard_hit">Hard Hit %</option>
                                    <option value="damage">Damage %</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="chartdownload" onClick={() => chartImage(id)} disabled={clickFlag}><a class="btn white"><i class="fa fa-download" aria-hidden="true"></i></a></div>
                </div>
                {/* {flag?.[`iframe_${id}`] === true && <div id="pitchR-iframe" className="Chart-iframe" style={{ display: toggle.chartToggle === false ? 'block' : 'none' }}>
                    <PitchRWebGL data={data3D} id={id} cameraAngle={cameraAngle} visualType={visualType} Toggle={toggle.chartToggle} />
                </div>} */}
            </div>
            
            {/* chart visual image download */}
            {isChartImg && <PrintImagePopup
                eventProfileData={eventProfileData}
                id={id}
                sendReq={sendReq}
                tabslist={tabslist}
                profilepage={profilepage} />}
        </>
    );
}


export default HistoGramChart;
