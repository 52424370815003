import React, { Component } from "react";
// images
import { imagesArr } from "../../../assets/images";
// components
import CalledPitchesChart from "./components/calledPitchesChart";
import EdgeCallsChart from "./components/edgeCallsChart";
import ContourGraphChart from "./components/contourGraphChart";
import UmpireAnalysisTable from "./components/umpireAnalysisTable";
import InZoneAccuracyTable from "./components/inZoneAccuracyTable";
import OutZoneAccuracyTable from "./components/outZoneAccuracyTable";
import EdgeAccuracyTable from "./components/edgeAccuracyTable";
import TotalAccuracyTable from "./components/totalAccuracyTable";
import Select from "react-select";
// redux
import { connect } from "react-redux";
import { getGameSummaryData } from "../../../store/actions";
import {
  pitchersCallChartData,
} from "../../../shared/function";

import { printToPDFMultiple } from "../../../shared/printReportUtils";
import Loader from "../../../shared/loader";
import { constant } from "../../../shared/constant";

let filterCountArr = [];
class UmpireAnylysis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: "",
      isshow: false,
      umpireChartData: [],
      calledPitchChartData: [],
      edgeCallsChartData: [],
      contourChartData: [],

      umpireTableData: [],
      inZoneAccTableData: [],
      outZoneAccTableData: [],
      edgeAccTableData: [],
      totalAccTableData: [],

      filterFlag: true,
      pitcherThrows: [],
      battersStands: [],
      filterData: {
        sourceId: document.location.href.split("/")[4],
        tab: "umpire_analysis",
        filter: {
          pitcher_name: "",
          batter_name: "",
          pitcher_handedness: "",
          batter_handedness: "",
          pitch_type: "",
          pitcher_team: "",
          count: "",
          inning: "",
          catcher: "",
        },
      },
      pitch_type: "",
      isPrinting: false,
      inning: [
        { value: "1", label: "1" },
        { value: "2", label: "2" },
        { value: "3", label: "3" },
        { value: "4", label: "4" },
        { value: "5", label: "5" },
        { value: "6", label: "6" },
        { value: "7", label: "7" },
        { value: "8", label: "8" },
        { value: "9", label: "9" },
      ],
      countOption: [
        { value: "Hitter's Count", label: "Hitter's Count" },
        { value: "Pitcher's Count", label: "Pitcher's Count" },
        { value: "Two Strike Counts", label: "Two Strike Counts" },
        { value: "0-0", label: "0-0" },
        { value: "0-1", label: "0-1" },
        { value: "0-2", label: "0-2" },
        { value: "1-0", label: "1-0" },
        { value: "1-1", label: "1-1" },
        { value: "1-2", label: "1-2" },
        { value: "2-0", label: "2-0" },
        { value: "2-1", label: "2-1" },
        { value: "2-2", label: "2-2" },
        { value: "3-0", label: "3-0" },
        { value: "3-1", label: "3-1" },
        { value: "3-2", label: "3-2" },
      ],
      pitch_typeOption: constant.softball ?
        [
          { value: "Fastball", label: "Fastball" },
          { value: "Drop Ball", label: "Drop Ball" },
          { value: "Rise Ball", label: "Rise Ball" },
          { value: "Drop Curve", label: "Drop Curve" },
          { value: "Curveball", label: "Curveball" },
          { value: "Screw Ball", label: "Screw Ball" },
          { value: "Changeup", label: "Changeup" }
        ] :
        [
          { value: "Fastballs", label: "Fastballs" },
          { value: "Breaking", label: "Breaking" },
          { value: "Offspeed", label: "Offspeed" },
          { value: "Fastball", label: "Fastball" },
          { value: "Sinker", label: "Sinker" },
          { value: "Curveball", label: "Curveball" },
          { value: "Slider", label: "Slider" },
          { value: "Changeup", label: "Changeup" },
          { value: "Cutter", label: "Cutter" },
          { value: "Splitter", label: "Splitter" },
          { value: "Knuckleball", label: "Knuckleball" },
        ],
      teamOption: [],
      isDownloadImg: false,
      umpire_name:""
    };
    this.handleToggle = this.handleToggle.bind(this);
    this.handleCancleToggle = this.handleCancleToggle.bind(this);
  }

  componentDidMount() {
    this.state.pitcherThrows.push(
      {
        value: "L",
        label: "Left",
      },
      {
        value: "R",
        label: "Right",
      }
    );
    this.state.battersStands.push(
      {
        value: "L",
        label: "Left",
      },
      {
        value: "R",
        label: "Right",
      }
    );

    let sorceid = document.location.href.split("/")[4];
    if (sorceid === undefined) window.location.href = "/";
    else this.umpiteAnalysisData(this.state.filterData);
  }

  // fetch Pitch log data
  umpiteAnalysisData = () => {
    let data = this.state.filterData;
    this.props.fetchSummaryData(data, (res) => {
      const pitchersUniqData = [{ value: "", label: "All Pitchers" }];
      const battersUniqData = [{ value: "", label: "All Batters" }];
      const teamUniqData = [{ value: "", label: "All Teams" }];
      const catcherUniqData = [{ value: "", label: "All Catchers" }];
      res.data.result.pitchers.map((data) => {
        pitchersUniqData.push({
          value: data.pitcher_name,
          label: data.pitcher_name,
        });
      });
      res.data.result.batters.map((data) => {
        battersUniqData.push({
          value: data.batter_name,
          label: data.batter_name,
        });
      });
      res.data.result.catchers.map((data) => {
        catcherUniqData.push({
          value: data,
          label: data,
        });
      });
      
      if (this.state.filterFlag) {
        this.setState({
          pitchers: pitchersUniqData,
          batters: battersUniqData,
          catcher: catcherUniqData
        }, () => {
          filterCountArr = [];
          filterCountArr.push("pitcher_name", "batter_name", "pitcher_team", "catcher");
        });
      }

      const pitchersData = pitchersCallChartData(
        res.data.result.scatter_chart_data
      );
      res.data.result.teams.map(_ =>
        teamUniqData.push({
          value: _,
          label: _,
        }))
      this.setState({
        // umpireTableData: res.data.result.umpire_analysis_table_data[0],
        calledPitchChartData: pitchersCallChartData(res.data.result.scatter_chart_data),
        edgeCallsChartData: pitchersCallChartData(res.data.result.edge_scatter_chart_data),
        contourChartData: res.data.result.contour_graph_data,
        inZoneAccTableData: res.data.result.in_zone_accuracy_table_data,
        outZoneAccTableData: res.data.result.out_zone_accuracy_table_data,
        edgeAccTableData: res.data.result.edge_accuracy_table_data,
        totalAccTableData: res.data.result.total_accuracy_table_data,
        teamOption: teamUniqData,
        umpire_name:res.data.result?.umpire_name?.umpire_name
      });
      // this.props.reduceDataCount();
    });
  };

  // filter change
  handleChange = (e, data) => {
    let old = filterCountArr.some((item) => {
      return item === e;
    });

    if (!old) {
      filterCountArr.push(e);
    }
    this.setState((prevState) => ({
      ...prevState,
      filterData: {
        sourceId: document.location.href.split("/")[4],
        tab: "umpire_analysis",
        filter: {
          ...prevState.filterData.filter,
          [e]: data.value === "Fastballs" ? ['Fastball', 'Sinker', 'Cutter'] : data.value === "Breaking" ? ['Curveball', 'Slider'] : data.value === "Offspeed" ? ['Changeup', 'Splitter', 'Knuckleball'] : e === "pitch_type" ? [data.value] : data.value,
        },
      },
      pitch_type: e === "pitch_type" ? data.value : this.state.pitch_type
    }));
  };


  // apply filter
  applyFilter() {
    this.setState({ filterFlag: false });
    this.umpiteAnalysisData(this.state.filterData);
  }

  // remove filter
  removeFilter = (e) => {
    filterCountArr.map((d, i) => {
      if (e !== "pitcher_name" && e !== "batter_name" && e != "pitcher_team" && e !== "catcher" && d === e) {
        filterCountArr.splice(i, 1);
      }
    });

    this.setState(
      (prevState) => ({
        ...prevState,
        filterData: {
          sourceId: document.location.href.split("/")[4],
          tab: "pitch_log",
          filter: {
            ...prevState.filterData.filter,
            [e]: "",
          },
        },
      }),
      () => {
        this.umpiteAnalysisData(this.state.filterData);
      }
    );
  };

  handleCallback = (index) => {
    this.setState({
      tabs: index,
    });
  };

  handleToggle() {
    this.setState({
      isshow: !this.state.isshow,
    });
  }

  handleCancleToggle() {
    filterCountArr = [];
    filterCountArr.push("pitcher_name", "batter_name", "pitcher_team", "catcher");
    this.setState((prevState) => ({
      ...prevState,
      isshow: !this.state.isshow,
      filterData: {
        ...prevState.filterData,
        filter: {
          ...prevState.filterData.filter,
          pitcher_name: "",
          batter_name: "",
          pitcher_handedness: "",
          batter_handedness: "",
          pitch_type: "",
          pitcher_team: "",
          count: "",
          inning: "",
          catcher: "",
        },
      },
      pitch_type: ""
    }),
      () => {
        this.umpiteAnalysisData(this.state.filterData);
      });
  }

  print = async () => {
    await this.setState({ isPrinting: true })
    await printToPDFMultiple(`umpireAnalysis`, [
      'umpireAnalysisArea'
    ]);
    this.setState({ isPrinting: false })
  }

  printAll = () => {
    this.props.printAll();
  }


  render() {
    const tabs = this.state.tabs;
    let {
      umpireChartData,
      umpireTableData,
      pitchers,
      batters,
      catcher,
      pitcherThrows,
      battersStands,
      filterData,
      umpire_name
    } = this.state;

    return (
      <>
        {this.state.isPrinting && <Loader />}
        {this.state.isDownloadImg && <Loader text={true}/>}
        <div id="umpireAnalysisArea">
          <section className="pb-0" >
            <div className="container-fluid">
              <div className="common-title mb-0">
                <h2 className="mb-10">Umpire Analysis</h2>
                <div className="right-side">
                  {!(this.state.isPrinting || this.props.isPrint) && <div className="dropdown">
                    <span className="printer"><img src={imagesArr.printer} alt="print" /></span>
                    <div className="dropdown-content">
                      <ul>
                        <li onClick={() => this.print()}>Print</li>
                        {/* <li onClick={() => this.printAll()}>Print All</li> */}
                      </ul>
                    </div>
                  </div>}
                  <div className="btnfilter">
                    <button
                      className="btn white"
                      onClick={this.handleToggle}
                    >
                      <div className="d-none d-sm-block">
                        <sup>{filterCountArr.length}</sup>
                      </div>
                      <span>
                        <img
                          src={imagesArr.iconfilter}
                          alt="filter"
                          className="d-inline"
                        />
                      </span>
                      <span className="d-sm-none">
                        Filter<span>({filterCountArr.length})</span>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="filtersection mt-0">
                {umpire_name && <h3 className="mb-10">Umpire Name : <span className="umpire_name">{umpire_name}</span></h3>}
                <div className="d-flex align-items-center">
                  <h3 className="mb-10">Applied Filters:</h3>
                  {Object.entries(filterCountArr).map((key, value) => {
                    let name = (key[1] === "pitcher_name" || key[1] === "batter_name" || key[1] === "pitcher_team" || key[1] === "catcher") ? this.state.filterData.filter[key[1]] ? this.state.filterData.filter[key[1]] : key[1] === "pitcher_name" ? "All Pitchers" : key[1] === "batter_name" ? "All Batters" : key[1] === "catcher" ? "All Catchers" : "All Teams" : key[1] === "pitch_type" ? this.state.pitch_type : this.state.filterData.filter[key[1]];
                    return (
                      <span key={value} className="filtertags">
                        {name === "L" ? "Left" : name === "R" ? "Right" : name}
                        {(name !== "All Pitchers" && name !== "All Batters" && name !== "All Teams" && name !== "All Catchers") &&
                          <img
                            src={imagesArr.iconclose}
                            alt="close"
                            className="d-inline"
                            onClick={this.removeFilter.bind(
                              this,
                              key[1]
                            )}
                          />}
                      </span>

                    );
                  })}
                </div>
              </div>
              {this.state.isshow && (
                <>
                  <div className="filtersection">
                    <div className="white-bg filterinfo">
                      <div className="row">
                        <div className="w-25">
                          <label className="common-lable">
                            Pitchers
                          </label>
                          <div className="serch-box">
                            <Select
                              className="customize-select"
                              classNamePrefix="customize-select"
                              onChange={this.handleChange.bind(this, "pitcher_name")}
                              value={pitchers && pitchers.find(e => e.value === filterData.filter.pitcher_name)}
                              options={pitchers}
                            />
                          </div>
                        </div>

                        <div className="w-25">
                          <label className="common-lable">
                            Batters
                          </label>
                          <div className="serch-box">
                            <Select
                              className="customize-select"
                              classNamePrefix="customize-select"
                              onChange={this.handleChange.bind(this, "batter_name")}
                              value={batters && batters.find(e => e.value === filterData.filter.batter_name)}
                              options={batters}
                            />
                          </div>
                        </div>

                        <div className="w-25">
                          <label className="common-lable">Catcher</label>
                          <div className="serch-box">
                            <Select
                              className="customize-select"
                              classNamePrefix="customize-select"
                              onChange={this.handleChange.bind(this, "catcher")}
                              value={catcher && catcher.find(e => e.value === filterData.filter.catcher)}
                              options={catcher}
                            />
                          </div>
                        </div>

                        <div className="w-25">
                          <label className="common-lable"> Pitcher Team </label>
                          <Select
                            className="customize-select"
                            classNamePrefix="customize-select"
                            placeholder="No Selected"
                            onChange={this.handleChange.bind(this, "pitcher_team")}
                            value={this.state.teamOption.find(e => e.value === filterData.filter.pitcher_team) || null}
                            options={this.state.teamOption}
                          />
                        </div>

                        <div className="w-25">
                          <label className="common-lable">
                            Pitcher Throws
                          </label>
                          <Select
                            className="customize-select"
                            classNamePrefix="customize-select"
                            placeholder="No Selected"
                            onChange={this.handleChange.bind(this, "pitcher_handedness")}
                            value={pitcherThrows && pitcherThrows.find(e => e.value === filterData.filter.pitcher_handedness) || null}
                            options={pitcherThrows}
                          />
                        </div>

                        <div className="w-25">
                          <label className="common-lable">
                            Batters Stands
                          </label>
                          <Select
                            className="customize-select"
                            classNamePrefix="customize-select"
                            placeholder="No Selected"
                            onChange={this.handleChange.bind(this, "batter_handedness")}
                            value={battersStands && battersStands.find(e => e.value === filterData.filter.batter_handedness) || null}
                            options={battersStands}
                          />
                        </div>

                        <div className="w-25">
                          <label className="common-lable"> Count </label>
                          <Select
                            className="customize-select"
                            classNamePrefix="customize-select"
                            placeholder="No Selected"
                            onChange={this.handleChange.bind(this, "count")}
                            value={this.state.countOption.find(e => e.value === filterData.filter.count) || null}
                            options={this.state.countOption}
                          />
                        </div>

                        <div className="w-25">
                          <label className="common-lable"> Inning </label>
                          <Select
                            className="customize-select"
                            classNamePrefix="customize-select"
                            placeholder="No Selected"
                            onChange={this.handleChange.bind(this, "inning")}
                            value={this.state.inning.find(e => e.value === filterData.filter.inning) || null}
                            options={this.state.inning}
                          />
                        </div>

                        <div className="w-25">
                          <label className="common-lable"> Pitch Type </label>
                          <Select
                            className="customize-select"
                            classNamePrefix="customize-select"
                            placeholder="No Selected"
                            onChange={this.handleChange.bind(this, "pitch_type")}
                            value={this.state.pitch_typeOption.find(e => e.value === this.state.pitch_type) || null}
                            options={this.state.pitch_typeOption}
                          />
                        </div>

                      </div>
                      <div className="d-flex align-items-center justify-content-center formbutton">
                        <button
                          className="btn blue mr-15 btnshadow"
                          onClick={this.applyFilter.bind(this)}
                        >
                          Apply
                        </button>
                        <button
                          className="btn white"
                          onClick={this.handleCancleToggle}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="container-fluid" id="chartPrintArea">
            <div className="common-charts">
              <div className="row">
                  <div className="w-33">
                    <CalledPitchesChart
                      chartData={this.state.calledPitchChartData}
                      eventName={this.props.eventName}
                      profilepage={false}
                      isNotDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: false }))}
                      isDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: true }))}
                    />
                </div>
                  <div className="w-33">
                    <EdgeCallsChart
                      chartData={this.state.edgeCallsChartData}
                      eventName={this.props.eventName}
                      profilepage={false}
                      isNotDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: false }))}
                      isDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: true }))}
                    />
                  </div>
                  <div className="w-33">
                    <ContourGraphChart
                      chartData={this.state.contourChartData}
                      eventName={this.props.eventName}
                      profilepage={false}
                      isNotDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: false }))}
                      isDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: true }))}
                    />
                  </div>
                {/* {umpireChartData.length != 0 ? (<UmpireAnalysisChart chartData={umpireChartData} />) : (<div className="common-charts"> <NoInfo /> </div>)} */}
              </div>
            </div>
            </div>
            <div className="container-fluid" id="tablePrintArea3">
              <div className="row">
                <div className="w-50">
                  <TotalAccuracyTable tableData={this.state.totalAccTableData} />
                </div>
                <div className="w-50">
                  <EdgeAccuracyTable tableData={this.state.edgeAccTableData} />
                </div>
              </div>

              <div className="row">
                {/* <div className="umpireanylysis-table">
                  <UmpireAnalysisTable tableData={umpireTableData} />
                </div> */}
                <div className="w-50">
                  <InZoneAccuracyTable tableData={this.state.inZoneAccTableData} />
                </div>
                <div className="w-50">
                  <OutZoneAccuracyTable tableData={this.state.outZoneAccTableData} />
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    teamData: state.teamData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchSummaryData: (data, next) => dispatch(getGameSummaryData(data, next)),
});
export default connect(mapStateToProps, mapDispatchToProps)(UmpireAnylysis);
