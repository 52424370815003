import { imagesArr } from '../../../assets/images';
import { createColoredColumnObj, stringValueSort, getSortedValues, createTextColoredColumnObj, createColoredColumnObjCustomScale } from "../../../shared/function";
import { Table } from 'antd';
import { constant } from '../../../shared/constant';
import { useEffect, useRef, useState } from 'react';
import VideoPopup from '../../../shared/VideoPopup';

function SecondBullPenTable({ tableData }) {
    const wrapperRef = useRef(null);
    const [isVideoShow, setIsVideoShow] = useState({
        videoFlag: false,
        videoData: null
    })

    var allRows = [];
    tableData &&
        tableData.map((list, i) =>
            allRows.push({
                key: i,
                // rank: list.rank ? list.rank : '—',
                pitch_number: list.pitch_number ? list.pitch_number : '—',
                // pitch_number: list.videoData.length ? <div className="table-video-icon"><span onClick={() => list.videoData.length !== 0 && videoPlay(list.videoData)}><i class="fa fa-video" aria-hidden="true"></i></span>{list.pitch_number ? list.pitch_number : '—'}</div> : list.pitch_number ? list.pitch_number : '—',
                pitch_type: list.pitch_type ? list.pitch_type : '—',
                pitch_velocity: list.pitch_velocity ? list.pitch_velocity.toString().includes(".") ? Number(list.pitch_velocity.toFixed(1)) : list.pitch_velocity : '—',
                spin_rate: list.spin_rate ? Math.round(list.spin_rate) : '—',
                spin_efficiency: list.spin_efficiency ? Math.round(list.spin_efficiency) + '%' : '—',
                v_break: list.v_break ? list.v_break.toString().includes(".") ? Number(list.v_break.toFixed(1)) : list.v_break : '—',
                ind_v_break: list.ind_v_break ? list.ind_v_break.toString().includes(".") ? Number(list.ind_v_break.toFixed(1)) : list.ind_v_break : '—',
                h_break: list.h_break ? list.h_break.toString().includes(".") ? Number(list.h_break.toFixed(1)) : list.h_break : '—',
                tilt: list.tilt ? list.tilt : "-",
                spin_axis: list.spin_axis ? Math.round(list.spin_axis) : '—',
                v_approach: list.v_approach && list.v_approach ? Number(list.v_approach.toFixed(1)) : '—',
                h_approach: list.h_approach && list.h_approach ? Number(list.h_approach.toFixed(1)) : '—',
            })
        );

    const columns = [
        // {
        //     title: 'Rank',
        //     dataIndex: 'rank',
        // },
        {
            title: 'Pitch No',
            dataIndex: 'pitch_number',
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, 'pitch_number'),
            sortDirections: ['descend', 'ascend'],
        },
        createTextColoredColumnObj('Pitch Type', 'pitch_type', {
            sorter: (a, b) => stringValueSort(a.pitch_type, b.pitch_type),
            sortDirections: ['descend', 'ascend'],
            fixed: 'left',
        }),
        createColoredColumnObjCustomScale('Velo', 'pitch_velocity', allRows, false, {
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, 'pitch_velocity'),
            sortDirections: ['descend', 'ascend'],
            minRange: constant.softball ? 40 : 65
        }),
        {
            title: 'Spin',
            dataIndex: 'spin_rate',
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, 'spin_rate'),
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Efficiency',
            dataIndex: 'spin_efficiency',
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, 'spin_efficiency'),
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Tilt',
            dataIndex: 'tilt',
            sorter: (a, b) => {
                if (!b.tilt) return -1;
                a = (a.tilt.split(":")[0]*360)/12 + (a.tilt.split(":")[1]*360)/(12*60)
                b = (b.tilt.split(":")[0]*360)/12 + (b.tilt.split(":")[1]*360)/(12*60)
                return a > b ? 1  :-1
            },
            // sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, 'tilt'),
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Axis',
            dataIndex: 'spin_axis',
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, 'spin_axis'),
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'V Break',
            dataIndex: 'v_break',
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, 'v_break'),
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Ind V Break',
            dataIndex: 'ind_v_break',
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, 'ind_v_break'),
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'H Break',
            dataIndex: 'h_break',
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, 'h_break'),
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'V Appr',
            dataIndex: 'v_approach',
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, 'v_approach'),
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'H Appr',
            dataIndex: 'h_approach',
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, 'h_approach'),
            sortDirections: ['descend', 'ascend'],
        },
    ];
    const data = allRows;

    const onChange = (filters, sorter, extra) => {
        // console.log('params', filters, sorter, extra);
    }

    // useEffect(() => {
    //     document.addEventListener("click", handleClickOutside, false);
    //     return () => {
    //         document.removeEventListener("click", handleClickOutside, false);
    //     };
    // }, []);

    // const videoPlay = (videoData) => {
    //     console.log('list.videoData', videoData)
    //     setIsVideoShow({
    //         videoFlag: true,
    //         videoData: videoData
    //     })
    // }

    // const handleClickOutside = event => {
    //     if (wrapperRef.current && wrapperRef.current.contains(event.target)) {
    //         setIsVideoShow({
    //             videoFlag: false,
    //             videoData: null
    //         })
    //     }
    // };

    return (
        <>
            <section class="pt-0 pl-0 pr-0" id="pitcherBullpenPrintArea">
                <div className="container-fluid">
                    <div className="spierscarson-table">
                        <div className="table-responsive-xl">
                            <table className="table tabledashed">
                                <Table columns={columns} dataSource={data} onChange={onChange} pagination={false} />
                            </table>
                        </div>
                    </div>
                </div>
            </section>

            {/* popup design for video */}
            {/* <VideoPopup
                isVideoShow={isVideoShow}
                setIsVideoShow={setIsVideoShow}
                wrapperRef={wrapperRef} /> */}
        </>
    )
}

export default SecondBullPenTable