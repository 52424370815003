const appState = {
  token: localStorage.getItem("bcteamToken"),
  teamData: null,
  summaryData: null,
  blastData: null,
  scrimmageData: null,
  pitchLogData: null,
  teamSummaryData: null,
  hitterSummaryData: null,
  bullpenSummaryData: null,
  developmentSummaryData: null,
  developmentSearchData: null,
  developmentPlayerData: null,

  pitcherSummaryData: null,
  profileDashboardData: null,
  profileFilterData: null,
  profileEventsData: null,
  allPlayerData: null,
  savePlanData: null,
  getPlanData: null,
  getUserData: null,
  getInsightData: null,
  awayHitterfilterFlag: true,
  awayHitterName: "",
  homePitcherfilterFlag: true,
  homePitcherName: "",
  awayPitcherfilterFlag: true,
  awayPitcherName: "",
  homeHitterfilterFlag: true,
  homeHitterName: "",
  SpitcherfilterFlag: true,
  SpitcherName: "",
  ShitterfilterFlag: true,
  ShitterName: "",
};
export default function app(state = appState, action) {
  switch (action.type) {
    case "DASHBOARD_TEAM_DATA": {
      return { ...state, teamData: action.payload };
    }
    case "GAME_SUMMARY_DATA": {
      return { ...state, summaryData: action.payload };
    }
    case "BLAST_SUMMARY_DATA": {
      return { ...state, blastData: action.payload };
    }
    case "SCRIMMAGE_SUMMARY_DATA": {
      return { ...state, scrimmageData: action.payload };
    }
    case "PITCH_LOG_DATA": {
      return { ...state, pitchLogData: action.payload };
    }
    case "TEAM_SUMMARY_DATA": {
      return { ...state, teamSummaryData: action.payload };
    }
    case "HITTER_SUMMARY_DATA": {
      return { ...state, hitterSummaryData: action.payload };
    }
    case "PITCHER_SUMMARY_DATA": {
      return { ...state, bullpenSummaryData: action.payload };
    }
    case "DEVELOPMENT_SUMMARY_DATA": {
      return { ...state, developmentSummaryData: action.payload };
    }
    case "DEVELOPMENT_SEARCH_DATA": {
      return { ...state, developmentSearchData: action.payload };
    }
    case "DEVELOPMENT_PLAYER_DATA": {
      return { ...state, developmentPlayerData: action.payload };
    }
    case "PROFILE_DASHBOARD_DATA": {
      return { ...state, profileDashboardData: action.payload };
    }
    case "PROFILE_FILTER_DATA": {
      return { ...state, profileFilterData: action.payload };
    }
    case "PROFILE_EVENTS_DATA": {
      return { ...state, profileEventsData: action.payload };
    }
    case "ALL_PLAYER_DATA": {
      return { ...state, allPlayerData: action.payload };
    }
    case "PLAN_PLAYER_DATA": {
      return { ...state, savePlanData: action.payload };
    }
    case "GET_PLAN_PLAYER_DATA": {
      return { ...state, getPlanData: action.payload };
    }
    case "USER_ROLE": {
      return { ...state, getUserData: action.payload };
    }
    case "PITCHAR_IFRAME_LOAD" : {
      return { ...state, [action.variable]: action.payload };
    }
    case "INSIGHTS_DATA" : {
      return { ...state, getInsightData: action.payload };
    }
    default:
      return state;
  }
}
