import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import { gameScrimmageStrikeZoneChartData } from "../../shared/function";
import BallrWebGL from "../../view/webGL/BallrWebGL";
import PitchRWebGL from "../../view/webGL/PitchRWebGL";
import Toggle from "../../shared/Toggle";
import domtoimage from 'dom-to-image';
import { downloadChartPrintImg } from "../../shared/printReportUtils";
import PrintImagePopup from "../../shared/PrintImagePopup";
import use3DView from "../../shared/use3DView";

function GSStrikeZoneChart({ chartData, id, showTitle, title, cameraAngle, visualType, eventName, pitcherDefault, profilepage, isNotDownloadImgLoader, isDownloadImgLoader }) {
  const [data3D, setData3D, toggle, setToggle, onClickToggleButton, flag] = use3DView(id);
  const [isChartImg, setIsChartImg] = useState(false);
  const [clickFlag, setClickFlag] = useState(false);
  const [ballrFlag, setBallrFlag] = useState(false);
  const [tooltipData, setTooltipData] = useState(null);
  const [state, setState] = useState({
    chart: {
      type: "scatter",
      borderWidth: 0,
      marginTop: 100,
      borderColor: "#D8D8D8",
      borderRadius: "8px",
    },
    title: {
      text: "",
    },
    xAxis: {
      title: {
        enabled: true,
        // text: "Side at Plate (ft)",
        text: "",
        style: {
          color: "#777F8F",
          fontFamily: "Poppins, sans-serif",
          fontSize: "14px",
          fontWeight: "500",
        },
      },
      min: -2.5,
      max: 2.5,
      tickInterval: 1
    },
    yAxis: {
      title: {
        enabled: true,
        // text: "Height at Plate (ft)",
        text: "",
        style: {
          color: "#777F8F",
          fontFamily: "Poppins, sans-serif",
          fontSize: "14px",
          fontWeight: "500",
        },
      },
      min: 0,
      max: 5,
    },
    legend: {
      layout: "horizontal",
      align: "center",
      verticalAlign: "top",
      // y: 35,
    },
    credits: { enabled: false },
    plotOptions: {
      series: {
        stickyTracking: false,
        states: {
          inactive: {
            opacity: 1
          }
        }
      }
    },
    tooltip: {
      useHTML: true,
      hideDelay: 500,
      formatter: function () {
        setTooltipData(this?.point);
        setBallrFlag(true)
        if (this.point.series.name === "Strikezone") return false;
        if (this.point.series.name === "Stadium") return false;
        let tooltip = `<span style="color: ${this.point.color
          }; font-weight:bold;">${this.point.options.pitch_type}</span><br>
          <span style="font-weight:bold;">Pitch No: ${this.point.options.pitch_number
            ? this.point.options.pitch_number
            : "-"
          }</span><br>
          <span style="font-weight:bold;">Pitcher: ${this.point.options.pitcher ? this.point.options.pitcher : "-"
          }</span><br>
          <span style="font-weight:bold;">Batter: ${this.point.options.batter ? this.point.options.batter : "-"
          }</span><br>
          <span style="font-weight:bold;">Play Result: ${this.point.options.pitch_call ? this.point.options.pitch_call : "-"
          }</span><br>
          <span style="font-weight:bold;">Velocity: ${this.point.options && this.point.options.velocity
            ? this.point.options.velocity.toFixed(1)
            : "-"
          }</span><br>
          <span style="font-weight:bold;">Spin Rate: ${this.point.options && this.point.options.spin_rate
            ? Math.round(this.point.options.spin_rate)
            : "-"
          }</span><br>
          <span style="font-weight:bold;">Tilt: ${this.point.options && this.point.options.tilt
            ? this.point.options.tilt
            : "-"
          }</span><br>
          <span style="font-weight:bold;">Axis: ${this.point.options && this.point.options.spin_axis
            ? Math.round(this.point.options.spin_axis)
            : "-"
          }</span><br>
          <span style="font-weight:bold;">Efficiency: ${this.point.options && this.point.options.efficiency
            ? Math.round(this.point.options.efficiency)
            : "-"
          }</span><br>
          <span style="font-weight:bold;">Vert Break: ${this.point.options && this.point.options.v_break
            ? this.point.options.v_break.toFixed(1)
            : "-"
          }</span><br>
          <span style="font-weight:bold;">Ind V Break: ${this.point.options && this.point.options.ind_v_break
            ? this.point.options.ind_v_break.toFixed(1)
            : "-"
          }</span><br>
          <span style="font-weight:bold;">H Break: ${this.point.options && this.point.options.h_break
            ? this.point.options.h_break.toFixed(1)
            : "-"
          }</span><br>
          <span style="font-weight:bold;">V Approach: ${this.point.options && this.point.options.vert_appr_angle
            ? this.point.options.vert_appr_angle.toFixed(1)
            : "-"
          }</span><br>
          <span style="font-weight:bold;">Rel Height: ${this.point.options && this.point.options.release_height
            ? this.point.options.release_height.toFixed(1)
            : "-"
          }</span><br>
          <span style="font-weight:bold;">Rel Side: ${this.point.options && this.point.options.release_side
            ? this.point.options.release_side.toFixed(1)
            : "-"
          }</span><br>
          <span style="font-weight:bold;">Extension: ${this.point.options && this.point.options.extension
            ? this.point.options.extension.toFixed(1)
            : "-"
          }</span><br>
          <span style="font-weight:bold;">Exit Velocity: ${this.point.options && this.point.options.exit_velocity
            ? this.point.options.exit_velocity.toFixed(1)
            : "-"
          }</span><br>
          <span style="font-weight:bold;">Launch Angle: ${this.point.options && this.point.options.launch_angle
            ? this.point.options.launch_angle.toFixed(1)
            : "-"
          }</span><br>
          <span style="font-weight:bold;">Distance: ${this.point.options && this.point.options.distance
            ? Math.round(this.point.options.distance)
            : "-"
          }</span>
          <!-- <div style="color: red" onmouseover="StrikeLoadBallR()">BallR</div> -->
          </span>`;
        return tooltip;
      },
      // if hover or click functionality add in tooltip use pointerEvents
      style: {
        pointerEvents: 'auto'
      }
    },
    series: [],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
        },
      ],
    },
  });

  useEffect(() => {
    if (toggle.chartToggle === true) {
      if (chartData) {
        // increment();
        if (showTitle) {
          state.title = {
            text: ""
            // text: title === "Swings" ? "Swings" : title === "Whiffs" ? "Whiffs" : "Balls In Play",
            // layout: "horizontal",
            // align: "center",
            // verticalAlign: "center",
            // y: 20,
            // x: 10,
            // style: {
            //   color: "#1B1B1B",
            //   fontFamily: "Poppins, sans-serif",
            //   fontSize: "18px",
            //   fontWeight: "bold",
            // },
          }
        }
        if (chartData.length !== 0) {
          const seriesData = gameScrimmageStrikeZoneChartData(chartData);
          state.series = seriesData;
          if (state.series) {
            state.series[state.series.length - 1].showInLegend = false
          }
          Highcharts.chart(id, state);
          // decrement();
        } else {
          state.series = [{ showInLegend: false }];
          Highcharts.chart(id, state);
          // decrement();
        }
      }
    } else {
      if (chartData && chartData.length !== 0) {
        let arr = chartData.map(x => x?._id) || []
        setData3D(arr)
      }
    }
  }, [chartData, toggle]);

  const chartImage = (id) => {
    isDownloadImgLoader();
    setClickFlag(true)
    if (document.getElementById(`img${id}`)) {
      document.getElementById(`img${id}`).remove()
    }
    setIsChartImg(true)
    downloadChartPrintImg(id, setIsChartImg, setClickFlag, isNotDownloadImgLoader)
  }

  return (
    <>
      <div className={`ground-chart ${ballrFlag ? "chart-ifream" : ""}`}>
        {chartData && chartData.length !== 0 ? <Toggle toggle={toggle} onClickToggleButton={onClickToggleButton} /> : ""}
        <div style={{ display: toggle.chartToggle === true ? 'block' : 'none' }}>
          <div className="legends graph-select text-left">
            <div className="text-center w-100">
              <h3 className="mb-0">{title === "Swings" ? "Swings" : title === "Whiffs" ? "Whiffs" : "Balls In Play"}</h3>
            </div>
          </div>
          <div className="ifream-box">
            {ballrFlag && <BallrWebGL tooltipData={tooltipData} id={id} index={tooltipData?._id} />}
          </div>
          <div style={{ height: "444px" }} id={id} />
          {/* <button className="btn blue btn-view" onClick={() => chartImage(id)} disabled={clickFlag} >Download</button> */}
          <div class="chartdownload" onClick={() => chartImage(id)} disabled={clickFlag}><a class="btn white"><i class="fa fa-download" aria-hidden="true"></i></a></div>
        </div>
        {flag?.[`iframe_${id}`] === true && <div id="pitchR-iframe" className="Chart-iframe" style={{ display: toggle.chartToggle === false ? 'block' : 'none' }}>
          <PitchRWebGL data={data3D} id={id} cameraAngle={cameraAngle} visualType={visualType} Toggle={toggle.chartToggle} />
        </div>}
      </div>

      {/* chart visual image download */}
      {isChartImg && <PrintImagePopup
        title={title === "Swings" ? "Swings" : title === "Whiffs" ? "Whiffs" : "Balls In Play"}
        id={id}
        eventName={eventName}
        pitcherDefault={pitcherDefault}
        profilepage={profilepage} />}
    </>
  );
}

export default GSStrikeZoneChart;
