import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';
import { constant } from '../../shared/constant';
import Cookies from 'js-cookie';

function PitchRWebGL({ data, id, cameraAngle, visualType, Toggle, pitchLocationType, colorScheme }) {
    const [state, setState] = useState({
        webGLUrl: process.env.REACT_APP_MINI_PITCHR_WEBGL_LINK,
        loader: false,
        iframeWidth: 372,
        iframeHeight: 430,
        firstLoadIframe: false,
        chartId: null
    })

    const refData = useRef();

    useEffect(() => {
        // get iframe(webGL) to height to bcteam
        window.addEventListener("message", (event) => {
            if(event.data) setState({ ...state, iframeHeight: JSON.parse(event.data).height })
        }, false);
        setState({
            ...state,
            webGLUrl: process.env.REACT_APP_MINI_PITCHR_WEBGL_LINK, loader: true
        })
    }, [])

    useEffect(() => {
        window.addEventListener("message", (event) => {
            if (event.data && JSON.parse(event.data).canvas === id && Toggle === false && data) {
                GiveKeyToiframe(data);
            }
        }, false);
        if (Toggle === false && data) {
            GiveKeyToiframe(data);
        }
    }, [data])

    const SendToken = async (_authToken) => {
        var jsonData = { "webglToken": _authToken, "chartId": id }
        if (refData.current) {
            refData.current.contentWindow.postMessage(jsonData, "*");
        }
    }

    const GiveKeyToiframe = (data) => {
        // Create a json object containing the login key to send to the iframe. "webglToken" is case-sensitive.
        var json = {
            "sport": constant.softball === false ? "baseball" : "softball",
            "cameraAngle": cameraAngle || [],
            "visualType": visualType || "",
            "pitchIDs": data || [],
            "pitchLocationType": pitchLocationType || "",
            "colorScheme": colorScheme === "exit_velocity" ? "ExitVelocity" : colorScheme === "pitch_type" ? "PitchType" : colorScheme === "hit_angle_type" ? "ContactType" : colorScheme === "pitch_call" ? "PlayResult" : colorScheme || ""
        };
        // setState({ ...state, chartId: id })
        if (Toggle === false) {
            if (refData.current) {
                if (id) {
                    setState({ ...state, loader: false, chartId: id })
                    setTimeout(() => {
                        SendToken(Cookies.get('bcSession', { domain: process.env.REACT_APP_COOKIE_DOMAIN })).then(() => {
                            if (refData.current) {
                                refData.current.contentWindow.postMessage(json, "*");
                            }
                        })
                    }, 10);
                }
            }
        }
    }

    return (
        <>
            <div className="pitchr-webgl" style={{ textAlign: 'center' }}>
                <iframe ref={refData} onLoad={() => GiveKeyToiframe(data)} id={`pitchr_iframe${id}`} frameBorder="0" src={state.webGLUrl} height="430" width="372" style={{ height: `${state.iframeHeight}px` }} />
                {state.loader && <div className="loader-bg">
                    <div className="loader">
                    </div>
                </div>}
            </div>
        </>
    )
}
export default PitchRWebGL
