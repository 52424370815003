import React, { Component } from "react";
import { imagesArr } from "../../../assets/images";
import PeakExitVelocityLeaders from "./components/peakExitVelocityLeaders";
import PeakVelocityLeaders from "./components/peakVelocityLeaders";
import HittingMetrics from "./components/hittingMetrics";
import PitchingMetrics from "./components/pitchingMetrics";
import { getGameSummaryData } from "../../../store/actions";
import { connect } from "react-redux";
import { printToPDF } from "../../../shared/printReportUtils";
import {
  getPitchingMetricsTranformData,
  getHittingMetricsTranformData,
  getMaxKeysFromArrayOfObjects,
  getHittingMetricsTranformDataPitcher,
  getPitchingMetricsTranformDataPitcher,
} from "../../../shared/function";
import Loader from "../../../shared/loader";
import HomeSummaryTable from "./components/SummaryTable";
import AwaySummaryTable from "./components/SummaryTable";
import InZone from "./components/Zone";
import OutZone from "./components/Zone";
import HittingMetrics1 from "../../scrimmagesummary/ScrimmageSummary/components/hittingMetrics";
import PitchingMetrics1 from "../../scrimmagesummary/ScrimmageSummary/components/pitchingMetrics";
class gamesummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: "",
      peakExitVelocityLeader: null,
      peakVelocityLeaders: null,
      hittingMetrics: null,
      pitchingMetrics: null,
      pMetrics_data: [],
      pMetrics_cols: [],
      hMetrics_data: [],
      hMetrics_cols: [],
      homeSummary_data: [],
      awaySummary_data: [],
      home_away_teams: {},
      zone_data: [],
      isPrinting: false,
    };
  }

  componentDidMount() {
    let sorceid = document.location.href.split("/")[4];
    if (sorceid === undefined) window.location.href = "/";
    else this.summaryData();
  }

  // fetch summary data
  summaryData = () => {
    let data = {
      sourceId: document.location.href.split("/")[4],
      tab: "summary",
    };
    this.props.fetchSummaryData(data, (res) => {
      this.setState({
        peakExitVelocityLeader: res.data.result.peak_exit_velocity_leader,
        peakVelocityLeaders: res.data.result.peak_velocity_leaders,
        hittingMetrics: res.data.result.hitting_metrics,
        pitchingMetrics: res.data.result.pitching_metrics,
        zone_data: res.data.result.plate_discipline_matrics,
        homeSummary_data: res.data.result.home_team_summary_table_data,
        awaySummary_data: res.data.result.away_team_summary_table_data,
        home_away_teams: res.data.result.home_away_teams
      });

      // hitting metrics table data and rows
      let hMetrics = res.data.result.hitting_metrics;
      if (hMetrics.length !== 0) {
        if (hMetrics.length === 1) {
          let hittingMetricsTranformData =
            getHittingMetricsTranformDataPitcher(hMetrics);
          this.setState({
            hMetrics_data: hittingMetricsTranformData
          });
        } else {
          let hittingMetricsTranformData =
            getHittingMetricsTranformData(hMetrics);
          const hittingMetricsTransformedDataRowKeys = Object.keys(
            hittingMetricsTranformData[0]
          );
          const hittingMatricsMaxKeys = Math.max(
            ...getMaxKeysFromArrayOfObjects(hMetrics)
          );
          const removeFirstElementFromHittingMatricsTransformedData =
            hittingMetricsTranformData.slice(1, hittingMatricsMaxKeys);
          this.setState({
            hMetrics_data: removeFirstElementFromHittingMatricsTransformedData,
            hMetrics_cols: hittingMetricsTransformedDataRowKeys,
          });
        }
      }

      // pitching metrics table data and rows
      let pMetrics = res.data.result.pitching_metrics;
      if (pMetrics.length !== 0) {
        if (pMetrics.length === 1) {
          let pitchingMetricsTranformData =
            getPitchingMetricsTranformDataPitcher(pMetrics);
          this.setState({
            pMetrics_data: pitchingMetricsTranformData
          });
        } else {
          let pitchingMetricsTranformData =
            getPitchingMetricsTranformData(pMetrics);
          let pTempArr = [];

          pitchingMetricsTranformData.map((item, i) => {
            if (
              item["Pitching Metrics"] !== "Count" &&
              item["Pitching Metrics"] !== "Swing Count" &&
              item["Pitching Metrics"] !== "In Zone Count" &&
              item["Pitching Metrics"] !== "Pitcher Team"
            ) {
              pTempArr.push(item);
            }
          });
          pitchingMetricsTranformData = pTempArr;
          const pitchingMetricsTransformedDataRowKeys = Object.keys(
            pitchingMetricsTranformData[0]
          );
          this.setState({
            pMetrics_data: pitchingMetricsTranformData,
            pMetrics_cols: pitchingMetricsTransformedDataRowKeys,
          });
        }
      }

      //zone home
      let ZoneData = this.groupArrayOfObjects(this.state.zone_data, "zone");
      this.setState({
        zone_data: ZoneData
      })
      // this.props.reduceDataCount();
    });
  };

  groupArrayOfObjects = (list, key) => {
    return list.reduce(function (rv, x) {
      (rv[x._id[key]] = rv[x._id[key]] || []).push(x);
      return rv;
    }, {});
  }

  handleCallback = (index) => {
    this.setState({
      tabs: index,
    });
  };

  print = async () => {
    await this.setState({ isPrinting: true });
    await printToPDF(`gameSummary`, "gameSummaryPrintArea");
    this.setState({ isPrinting: false });
  };

  printAll = () => {
    this.props.printAll();
  };

  render() {
    const tabs = this.state.tabs;
    let {
      peakExitVelocityLeader,
      peakVelocityLeaders,
      homeSummary_data,
      awaySummary_data,
      zone_data,
      pMetrics_data,
      pMetrics_cols,
      hMetrics_data,
      hMetrics_cols,
      home_away_teams
    } = this.state;
    return (
      <>
        {this.state.isPrinting && <Loader />}
        <section className="pb-0">
          <div className="container-fluid">
            <div className="common-title">
              <h2>Game Summary</h2>
              {!(this.state.isPrinting || this.props.isPrint) && (
                <div className="dropdown">
                  <span className="printer">
                    <img src={imagesArr.printer} alt="print" />
                  </span>
                  <div className="dropdown-content">
                    <ul>
                      <li onClick={() => this.print()}>Print</li>
                      {/* <li onClick={() => this.printAll()}>Print All</li> */}
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="container-fluid" id="gameSummaryPrintArea">
            <div className="text-center">
              <h3 className="table-title">BREAKDOWN</h3>
            </div>
            <div className="row">
              {this.state.hittingMetrics && this.state.hittingMetrics.length === 1 ?
                <HittingMetrics1 hMetrics_data={hMetrics_data} />
                :
                <HittingMetrics
                  hMetrics_data={hMetrics_data}
                  hMetrics_cols={hMetrics_cols}
                />}
              {this.state.pitchingMetrics && this.state.pitchingMetrics.length === 1 ?
                <PitchingMetrics1 pMetrics_data={pMetrics_data} />
                :
                <PitchingMetrics
                  pMetrics_data={pMetrics_data}
                  pMetrics_cols={pMetrics_cols}
                />}
            </div>
            <div className="text-center">
              <h3 className="table-title">{home_away_teams?.home_team || ""} Pitching Summary</h3>
            </div>
            <div className="row">
              <div className="ss-summary-result-table">
                <HomeSummaryTable
                  tableData={homeSummary_data}
                  title={false}
                />
              </div>
            </div>
            {home_away_teams?.home_team === home_away_teams?.away_team ? "" :
              <div>
                <div className="text-center">
                  <h3 className="table-title">{home_away_teams?.away_team || ""} Pitching Summary</h3>
                </div>
                <div className="row">
                  <div className="ss-summary-result-table">
                    <AwaySummaryTable
                      tableData={awaySummary_data}
                      title={false}
                    />
                  </div>
                </div>
              </div>}
            <div className="text-center">
              <h3 className="table-title">PLATE DISCIPLINE</h3>
            </div>
            <div className="row">
              <InZone
                zone_data={zone_data["in_zone"]}
                zone="In Zone"
                home_away_teams={home_away_teams}
              />
              <OutZone
                zone_data={zone_data["out_zone"]}
                zone="Out of Zone"
                home_away_teams={home_away_teams}
              />
            </div>
            <div className="row">
              <PeakExitVelocityLeaders data={peakExitVelocityLeader} />
              <PeakVelocityLeaders data={peakVelocityLeaders} />
            </div>
          </div>
        </section>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    teamData: state.teamData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchSummaryData: (data, next) => dispatch(getGameSummaryData(data, next)),
});
export default connect(mapStateToProps, mapDispatchToProps)(gamesummary);
