import React from 'react'
import { constant } from '../../../shared/constant';
import { createColoredColumnObjTable } from '../../../shared/function';

function AtBatTable({ tableData, index }) {
    const ordinal = (index) => {
        const english_ordinal_rules = new Intl.PluralRules("en", {
            type: "ordinal"
        });
        const suffixes = {
            one: "st",
            two: "nd",
            few: "rd",
            other: "th"
        }
        const suffix = suffixes[english_ordinal_rules.select(index)];
        return (index + suffix);
    }

    const renderList = () => {
        return (tableData && tableData.length !== 0 ?
            tableData.map((data, j) => {
                return (
                    <tr key={j}>
                        <td className="table-rowwhite">
                            {data.pitch_number ? data.pitch_number : '—'}
                        </td>
                        <td className="table-rowwhite">
                            {data.pitcher_name ? data.pitcher_name : '—'}
                        </td>
                        <td className="table-rowwhite" style={{ color: constant.pitchTypeColor[data.pitch_type] }}>
                            {data.pitch_type ? data.pitch_type : '—'}
                        </td>
                        <td className="table-rowwhite">
                            {data.outs ? data.outs.length !== 0 ? data.outs.toString().includes(".") ? data.outs.toFixed(1) : data.outs : '—' : '—'}
                        </td>
                        <td className="table-rowwhite">
                            {data.balls ? data.balls.length !== 0 ? data.balls.toString().includes(".") ? data.balls.toFixed(1) : data.balls : '—' : '—'}
                        </td>
                        <td className="table-rowwhite">
                            {data.strikes ? data.strikes.length !== 0 ? data.strikes.toString().includes(".") ? data.strikes.toFixed(1) : data.strikes : '—' : '—'}
                        </td>
                        <td className="table-rowwhite">
                            {data.play_result ? data.play_result : '—'}
                        </td>
                        <td className="table-rowwhite">
                            {data.velocity ? data.velocity.length !== 0 ? data.velocity.toString().includes(".") ? data.velocity.toFixed(1) : data.velocity : '—' : '—'}
                        </td>
                        <td className="table-rowwhite">
                            {data.spin_rate ? data.spin_rate.length !== 0 ? Math.round(data.spin_rate) : '—' : '—'}
                        </td>
                        <td className="table-rowwhite" style={createColoredColumnObjTable('exit_velocity', tableData, data, constant.softball ? 40 : 60)}>
                            {data.exit_velocity ? data.exit_velocity.length !== 0 ? data.exit_velocity.toString().includes(".") ? data.exit_velocity.toFixed(1) : data.exit_velocity : '—' : '—'}
                        </td>
                        <td className="table-rowwhite">
                            {data.launch_angle ? data.launch_angle.length !== 0 ? data.launch_angle.toString().includes(".") ? data.launch_angle.toFixed(1) : data.launch_angle : '—' : '—'}
                        </td>
                        <td className="table-rowwhite">
                            {data.distance ? data.distance.length !== 0 ? Math.round(data.distance) : '—' : '—'}
                        </td>
                    </tr>
                );
            })
            :
            <tr>
                <td className="table-rowwhite text-center" colSpan="12">There's no info yet!</td>
            </tr>
        )
    }

    return (
        <div className="container-fluid">
            {/* <h3 className="table-title">Plate Discipline</h3> */}
            <div className="homepitchers-table">
                <div className="table-responsive-xl">
                    <table className="table tabledashed">
                        <thead>
                            <tr>
                                <th colSpan="3" className="one-child text-center">{ordinal(parseFloat(index)+1)} AB</th>
                                <th colSpan="4" className="one-child text-center">Count/Result</th>
                                <th colSpan="2" className="one-child text-center">Pitch</th>
                                <th colSpan="4" className="one-child text-center">Hit</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="tablerowgray fw-500">Pitch No.</td>
                                <td className="tablerowgray fw-500">Pitcher</td>
                                <td className="tablerowgray fw-500">Pitch Type</td>
                                <td className="tablerowgray fw-500">Out(s)</td>
                                <td className="tablerowgray fw-500">Ball</td>
                                <td className="tablerowgray fw-500">Strike</td>
                                <td className="tablerowgray fw-500">Result</td>
                                <td className="tablerowgray fw-500">Velocity</td>
                                <td className="tablerowgray fw-500">Spin</td>
                                <td className="tablerowgray fw-500">EV</td>
                                <td className="tablerowgray fw-500">LA</td>
                                <td className="tablerowgray fw-500">Distance</td>
                                {/* <td className="tablerowgray fw-500">xBA</td> */}
                            </tr>
                            {renderList()}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default AtBatTable
