import React from "react";
import Routers from "./Route";
import "react-datepicker/dist/react-datepicker.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'antd/dist/antd.css'; 

const App = () => {
  return <Routers />;
};

export default App;
