import React, { Component } from 'react';
import { imagesArr } from "../../../../assets/images";
import { constant } from '../../../../shared/constant';

class pitchingMetrics extends Component {
    render() {

        const { pMetrics_data } = this.props;

        return (
            <>
                <div className="w-50">
                    <div className="gamesummary-table m-0">
                        <div className="table-responsive-xl">
                            <table className="table tabledashed">
                                <thead>
                                    <tr>
                                        <th colSpan="2" className="one-child text-center">
                                            Pitching Metrics
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        pMetrics_data && pMetrics_data.length !== 0 ?
                                            pMetrics_data.map((td, i) => {
                                                if (td.key !== "Count" && td.key !== "Swing Count" && td.key !== "In Zone Count" && td.key !== "Avg FB Spin" && td.key !== "Avg BB Spin" && td.key !== "Pitcher Team") {
                                                    if (constant.softball) {
                                                        if (td.key !== "Avg FB Velo") {
                                                            return (
                                                                <tr>
                                                                    <td className="table-rowwhite">{td.key}</td>
                                                                    <td className="table-rowwhite">{td.value}</td>
                                                                </tr>
                                                            );
                                                        }
                                                    } else {
                                                        return (
                                                            <tr>
                                                                <td className="table-rowwhite">{td.key}</td>
                                                                <td className="table-rowwhite">{td.value}</td>
                                                            </tr>
                                                        );
                                                    }
                                                }
                                            })
                                            :
                                            <tr>
                                                <td className="table-rowwhite text-center" colSpan="2">
                                                    There's no info yet!
                                                </td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default pitchingMetrics;
