import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import { strikeZoneChartWithRedBox } from "../../../../../shared/function";
import { NoGraph } from "../../noInfo";
import StrikeZoneChart from "../../../../../components/Charts/strikeZoneChart";

function PitcherStrikeZoneChart({ zoneChartA, zoneChartB }) {
  const [s, setS] = useState({ chartToggle: true });

  // let options = {
  //   chart: {
  //     type: "scatter",
  //     marginTop: 100,
  //     borderWidth: 1,
  //     borderColor: '#D8D8D8',
  //     borderRadius: '8px',
  //   },
  //   title: {
  //     text: "",
  //     layout: "horizontal",
  //     align: "center",
  //     verticalAlign: "left",
  //     y: 5,
  //     x: 10,
  //     style: {
  //       color: "#1B1B1B",
  //       fontFamily: "Poppins, sans-serif",
  //       fontSize: "18px",
  //       fontWeight: "bold",
  //     },
  //   },
  //   xAxis: {
  //     enabled: true,
  //     title: {
  //       text: "Side at Plate (ft)",
  //       style: {
  //         color: "#777F8F",
  //         fontFamily: "Poppins, sans-serif",
  //         fontSize: "14px",
  //         fontWeight: "500",
  //       },
  //     },
  //     min: -2.5,
  //     max: 2.5,
  //     tickInterval: 1
  //   },
  //   yAxis: {
  //     title: {
  //       enabled: true,
  //       text: "Height at Plate (ft)",
  //       style: {
  //         color: "#777F8F",
  //         fontFamily: "Poppins, sans-serif",
  //         fontSize: "14px",
  //         fontWeight: "500",
  //       },
  //     },
  //     min: 0,
  //     max: 5
  //   },
  //   legend: {
  //     layout: "horizontal",
  //     align: "top",
  //     verticalAlign: "top",
  //     y: 20,
  //   },
  //   credits: { enabled: false },
  //   plotOptions: {
  //     series: {
  //       stickyTracking: false,
  //       states: {
  //         inactive: {
  //           opacity: 1
  //         }
  //       }
  //     }
  //   },
  //   tooltip: {
  //     formatter: function () {
  //       if (this.point.series.name === "Strikezone") return false;
  //       if (this.point.series.name === "Stadium") return false;
  //       let tooltip = `<span style="color: ${this.point.color}; font-weight:bold;">${this.point.options.pitch_type
  //         }</span><br>
  //             <span>Batter: ${this.point.options.batter ? this.point.options.batter : "-"
  //         }</span><br>
  //             <span>Pitcher: ${this.point.options.pitcher ? this.point.options.pitcher : "-"
  //         }</span><br>
  //             <span>Pitch Number: ${this.point.options.pitch_number
  //           ? this.point.options.pitch_number
  //           : "-"
  //         }</span><br>
  //             <span>Velocity: ${this.point.options && this.point.options.velocity
  //           ? this.point.options.velocity.toFixed(1)
  //           : "-"
  //         }</span><br>
  //             <span>Spin Rate: ${this.point.options && this.point.options.spin_rate
  //           ? Math.round(this.point.options.spin_rate)
  //           : "-"
  //         }</span><br>
  //             <span>Ind V Break: ${this.point.options && this.point.options.ind_v_break
  //           ? this.point.options.ind_v_break.toFixed(1)
  //           : "-"
  //         }</span><br>
  //             <span>H Break: ${this.point.options && this.point.options.h_break
  //           ? this.point.options.h_break.toFixed(1)
  //           : "-"
  //         }</span><br>
  //             <span>Vert Appr Angle: ${this.point.options && this.point.options.vert_appr_angle
  //           ? this.point.options.vert_appr_angle.toFixed(1)
  //           : "-"
  //         }</span><br>
  //             <span>Release Height: ${this.point.options && this.point.options.release_height
  //           ? this.point.options.release_height.toFixed(1)
  //           : "-"
  //         }</span><br>
  //             <span>Release Side: ${this.point.options && this.point.options.release_side
  //           ? this.point.options.release_side.toFixed(1)
  //           : "-"
  //         }</span><br>
  //             <span>Play Result: ${this.point.options.pitch_call
  //           ? this.point.options.pitch_call
  //           : "-"
  //         }</span>
  //           </span>`;
  //       return tooltip;
  //     },
  //   },
  //   series: [],
  //   responsive: {
  //     rules: [
  //       {
  //         condition: {
  //           maxWidth: 500,
  //         },
  //       },
  //     ],
  //   },
  // };

  // useEffect(() => {
  //   if (zoneChartA.length !== 0) {
  //     const seriesData = strikeZoneChartWithRedBox(zoneChartA);
  //     options.series = seriesData;
  //     if (options.series) {
  //       options.series[options.series.length - 1].showInLegend = false;
  //     }
  //     Highcharts.chart("picherZoneChartA", options);
  //   } else {
  //     options.series = [{}];
  //     Highcharts.chart("picherZoneChartA", options);
  //   }
  //   if (zoneChartB.length !== 0) {
  //     const seriesData = strikeZoneChartWithRedBox(zoneChartB);
  //     options.series = seriesData;
  //     if (options.series) {
  //       options.series[options.series.length - 1].showInLegend = false;
  //     }
  //     Highcharts.chart("pitcherZoneChartB", options);
  //   } else {
  //     options.series = [{}];
  //     Highcharts.chart("pitcherZoneChartB", options);
  //   }
  // }, [zoneChartA, zoneChartB]);

  const onClickToggleButton = () => {
    setS({
      chartToggle: !s.chartToggle
    });
  };

  return (
    <>
      {window.innerWidth < 1366 ? (
        <div className="row">
          {s.chartToggle === true ? (
            <div className="w-38">
              <div className="group-select-box">
                <h4>Group A</h4>
                <h3 className="chart-name-title">Pitcher Strike Zone Chart</h3>
                <p className="chart-name-disc">Pitcher View</p>
                <StrikeZoneChart
                  chartData={zoneChartA}
                  id="picherZoneChartA"
                  showTitle={false}
                  developmentTab={true}
                />
                {/* <div style={{ height: '500px' }} id="picherZoneChartA" /> */}
                {/* {zoneChartA.length !== 0 ? <div style={{ height: '500px' }} id="picherZoneChartA" />
        : <NoGraph />} */}
              </div>
            </div>
          ) : (
            <div className="w-38">
              <div className="group-select-box">
                <h4>Group B</h4>
                <h3 className="chart-name-title">Pitcher Strike Zone Chart</h3>
                <p className="chart-name-disc">Pitcher View</p>
                <StrikeZoneChart
                  chartData={zoneChartB}
                  id="pitcherZoneChartB"
                  showTitle={false}
                  developmentTab={true}
                />
                {/* <div style={{ height: '500px' }} id="pitcherZoneChartB" /> */}
                {/* {zoneChartB.length !== 0 ? <div style={{ height: '500px' }} id="pitcherZoneChartB" />
              : <NoGraph />} */}
              </div>
            </div>
          )}
          <div className="toggle-switch">
            <label className="switch">
              <input
                type="checkbox"
                id="togBtn"
                checked={s.chartToggle}
                onChange={onClickToggleButton}
              />
              <div className="slider round">
                <span className="on">Group A</span>
                <span className="off">Group B</span>
              </div>
            </label>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="w-38">
            <div className="group-select-box">
              <h4>Group A</h4>
              <h3 className="chart-name-title">Pitcher Strike Zone Chart</h3>
              <p className="chart-name-disc">Pitcher View</p>
              <StrikeZoneChart
                chartData={zoneChartA}
                id="picherZoneChartA"
                showTitle={false}
                developmentTab={true}
              />
              {/* <div style={{ height: '500px' }} id="picherZoneChartA" /> */}
              {/* {zoneChartA.length !== 0 ? <div style={{ height: '500px' }} id="picherZoneChartA" />
            : <NoGraph />} */}
            </div>
          </div>
          <div className="w-38">
            <div className="group-select-box">
              <h4>Group B</h4>
              <h3 className="chart-name-title">Pitcher Strike Zone Chart</h3>
              <p className="chart-name-disc">Pitcher View</p>
              <StrikeZoneChart
                chartData={zoneChartB}
                id="pitcherZoneChartB"
                showTitle={false}
                developmentTab={true}
              />
              {/* <div style={{ height: '500px' }} id="pitcherZoneChartB" /> */}
              {/* {zoneChartB.length !== 0 ? <div style={{ height: '500px' }} id="pitcherZoneChartB" />
            : <NoGraph />} */}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PitcherStrikeZoneChart;
