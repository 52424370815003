import React, { Component } from 'react';

class noInfo extends Component {
    render() {
        return (
            // <div className="ground-chart" style={{ height: "481px", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div style={{ height: "442px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                There's is no Info yet!
            </div>
        );
    }
}

export default noInfo;
