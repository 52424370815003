
import React from 'react';
import { sprayChartRangeHeaderStaticValues } from '../../../shared/function';

function SprayChartRangeHeader({softball}){
  return (
    <div className="sprayChartRangeHeader">
      {sprayChartRangeHeaderStaticValues(softball).map(range => {
        return (
          <div key={range.label} className="range">
            <span
              className="rangeColor"
              style={{ background: range.color }}
            />
            <span className="rangeText" dangerouslySetInnerHTML={{__html:range.label}}></span>
          </div>
        );
      })}
    </div>
  );
};

export default SprayChartRangeHeader;
