import React from 'react';
import { stringValueSort, getSortedValues } from "../../../shared/function";
import { Table } from "antd";

function SwingSummaryTable({ tableData, type, isPrinting }) {
    var allRows = [];

    if (type === "sessionSummary") {
        tableData && tableData.length !== 0 &&
            tableData.map((list, i) =>
                allRows.push({
                    key: i,
                    name: list._id ? list._id : "",
                    plane: list.plane && list.plane ? Math.round(list.plane) : "—",
                    connection: list.connection && list.connection ? Math.round(list.connection) : "—",
                    rotation: list.rotation && list.rotation ? Math.round(list.rotation) : "—",
                    bat_speed: list.bat_speed && list.bat_speed ? Number(list.bat_speed.toFixed(1)) : "—",
                    rotational_acceleration: list.rotational_acceleration && list.rotational_acceleration ? Number(list.rotational_acceleration.toFixed(1)) : "—",
                    on_plane_efficiency: list.on_plane_efficiency && list.on_plane_efficiency ? Math.round(list.on_plane_efficiency) : "—",
                    attack_angle: list.attack_angle && list.attack_angle ? Math.round(list.attack_angle) : "—",
                    early_connection: list.early_connection && list.early_connection ? Math.round(list.early_connection) : "—",
                    connection_at_impact: list.connection_at_impact && list.connection_at_impact ? Math.round(list.connection_at_impact) : "—",
                    vertical_bat_angle: list.vertical_bat_angle && list.vertical_bat_angle ? Math.round(list.vertical_bat_angle) : "—",
                    power: list.power && list.power ? Number(list.power.toFixed(2)) : "—",
                    time_to_contact: list.time_to_contact && list.time_to_contact ? Number(list.time_to_contact.toFixed(2)) : "—",
                    peak_hand_speed: list.peak_hand_speed && list.peak_hand_speed ? Number(list.peak_hand_speed.toFixed(1)) : "—",
                }))
    } else if (type === "swinglog") {
        tableData && tableData.length !== 0 &&
            tableData.map((list, i) =>
                allRows.push({
                    key: i,
                    name: list._id ? list._id : "",
                    plane: list.plane && list.plane ? Math.round(list.plane) : "—",
                    connection: list.connection && list.connection ? Math.round(list.connection) : "—",
                    rotation: list.rotation && list.rotation ? Math.round(list.rotation) : "—",
                    bat_speed: list.bat_speed && list.bat_speed ? Number(list.bat_speed.toFixed(1)) : "—",
                    rotational_acceleration: list.rotational_acceleration && list.rotational_acceleration ? Number(list.rotational_acceleration.toFixed(1)) : "—",
                    on_plane_efficiency: list.on_plane_efficiency && list.on_plane_efficiency ? Math.round(list.on_plane_efficiency) : "—",
                    attack_angle: list.attack_angle && list.attack_angle ? Math.round(list.attack_angle) : "—",
                    early_connection: list.early_connection && list.early_connection ? Math.round(list.early_connection) : "—",
                    connection_at_impact: list.connection_at_impact && list.connection_at_impact ? Math.round(list.connection_at_impact) : "—",
                    vertical_bat_angle: list.vertical_bat_angle && list.vertical_bat_angle ? Math.round(list.vertical_bat_angle) : "—",
                    power: list.power && list.power ? Number(list.power.toFixed(2)) : "—",
                    time_to_contact: list.time_to_contact && list.time_to_contact ? Number(list.time_to_contact.toFixed(2)) : "—",
                    peak_hand_speed: list.peak_hand_speed && list.peak_hand_speed ? Number(list.peak_hand_speed.toFixed(1)) : "—",
                }))
    } else {
        tableData && tableData.length !== 0 &&
            tableData.map((battedTable, i) =>
                allRows.push({
                    key: i,
                    name: battedTable._id ? battedTable._id : "",
                    plane: battedTable.plane && battedTable.plane ? Math.round(battedTable.plane) : "—",
                    connection: battedTable.connection && battedTable.connection ? Math.round(battedTable.connection) : "—",
                    rotation: battedTable.rotation && battedTable.rotation ? Math.round(battedTable.rotation) : "—",
                    bat_speed: battedTable.bat_speed && battedTable.bat_speed ? Number(battedTable.bat_speed.toFixed(1)) : "—",
                    rotational_acceleration: battedTable.rotational_acceleration && battedTable.rotational_acceleration ? Number(battedTable.rotational_acceleration.toFixed(1)) : "—",
                    on_plane_efficiency: battedTable.on_plane_efficiency && battedTable.on_plane_efficiency ? Math.round(battedTable.on_plane_efficiency) : "—",
                    attack_angle: battedTable.attack_angle && battedTable.attack_angle ? Math.round(battedTable.attack_angle) : "—",
                    early_connection: battedTable.early_connection && battedTable.early_connection ? Math.round(battedTable.early_connection) : "—",
                    connection_at_impact: battedTable.connection_at_impact && battedTable.connection_at_impact ? Math.round(battedTable.connection_at_impact) : "—",
                    vertical_bat_angle: battedTable.vertical_bat_angle && battedTable.vertical_bat_angle ? Math.round(battedTable.vertical_bat_angle) : "—",
                    power: battedTable.power && battedTable.power ? Number(battedTable.power.toFixed(2)) : "—",
                    time_to_contact: battedTable.time_to_contact && battedTable.time_to_contact ? Number(battedTable.time_to_contact.toFixed(2)) : "—",
                    peak_hand_speed: battedTable.peak_hand_speed && battedTable.peak_hand_speed ? Number(battedTable.peak_hand_speed.toFixed(1)) : "—",
                }))
    }

    const columns = [
        type === "swinglog" ?
            {
                title: "Swing#",
                dataIndex: "key",
                sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "key"),
                sortDirections: ['descend', 'ascend'],
                render(text, record) {
                    return {
                        props: {
                            style: { background: record.name === "Session Average" ? "lightyellow" : record.name === "Team Average" ? "lightgray" : "" }
                        },
                        children: <div>{text + 1}</div>
                    };
                }
            } :
            {
                title: "Name",
                dataIndex: "name",
                sorter: (a, b) => stringValueSort(a.name, b.name),
                sortDirections: ['descend', 'ascend'],
                render(text, record) {
                    return {
                        props: {
                            style: { background: record.name === "Session Average" ? "lightyellow" : record.name === "Team Average" ? "lightgray" : "" }
                        },
                        children: <div>{text}</div>
                    };
                }
            },
        // {
        //     title: "Plane",
        //     dataIndex: "plane",
        //     sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "plane"),
        //     sortDirections: ['descend', 'ascend'],
        //     render(text, record) {
        //         return {
        //             props: {
        //                 style: { background: record.name === "Session Average" ? "lightyellow" : record.name === "Team Average" ? "lightgray" : "" }
        //             },
        //             children: <div>{text}</div>
        //         };
        //     }
        // },
        // {
        //     title: "Connection",
        //     dataIndex: "connection",
        //     sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "connection"),
        //     sortDirections: ['descend', 'ascend'],
        //     render(text, record) {
        //         return {
        //             props: {
        //                 style: { background: record.name === "Session Average" ? "lightyellow" : record.name === "Team Average" ? "lightgray" : "" }
        //             },
        //             children: <div>{text}</div>
        //         };
        //     }
        // },
        // {
        //     title: "Rotation",
        //     dataIndex: "rotation",
        //     sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "rotation"),
        //     sortDirections: ['descend', 'ascend'],
        //     render(text, record) {
        //         return {
        //             props: {
        //                 style: { background: record.name === "Session Average" ? "lightyellow" : record.name === "Team Average" ? "lightgray" : "" }
        //             },
        //             children: <div>{text}</div>
        //         };
        //     }
        // },
        {
            title: "Bat Speed",
            dataIndex: "bat_speed",
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "bat_speed"),
            sortDirections: ['descend', 'ascend'],
            render(text, record) {
                return {
                    props: {
                        style: { background: record.name === "Session Average" ? "lightyellow" : record.name === "Team Average" ? "lightgray" : "" }
                    },
                    children: <div>{text}</div>
                };
            }
        },
        {
            title: "Rotational Acceleration",
            dataIndex: "rotational_acceleration",
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "rotational_acceleration"),
            sortDirections: ['descend', 'ascend'],
            render(text, record) {
                return {
                    props: {
                        style: { background: record.name === "Session Average" ? "lightyellow" : record.name === "Team Average" ? "lightgray" : "" }
                    },
                    children: <div>{text}</div>
                };
            }
        },
        {
            title: "On Plane Efficiency",
            dataIndex: "on_plane_efficiency",
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "on_plane_efficiency"),
            sortDirections: ['descend', 'ascend'],
            render(text, record) {
                return {
                    props: {
                        style: { background: record.name === "Session Average" ? "lightyellow" : record.name === "Team Average" ? "lightgray" : "" }
                    },
                    children: <div>{text}</div>
                };
            }
        },
        {
            title: "Attack Angle",
            dataIndex: "attack_angle",
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "attack_angle"),
            sortDirections: ['descend', 'ascend'],
            render(text, record) {
                return {
                    props: {
                        style: { background: record.name === "Session Average" ? "lightyellow" : record.name === "Team Average" ? "lightgray" : "" }
                    },
                    children: <div>{text}</div>
                };
            }
        },
        {
            title: "Early Connection",
            dataIndex: "early_connection",
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "early_connection"),
            sortDirections: ['descend', 'ascend'],
            render(text, record) {
                return {
                    props: {
                        style: { background: record.name === "Session Average" ? "lightyellow" : record.name === "Team Average" ? "lightgray" : "" }
                    },
                    children: <div>{text}</div>
                };
            }
        },
        {
            title: "Connection at Impact",
            dataIndex: "connection_at_impact",
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "connection_at_impact"),
            sortDirections: ['descend', 'ascend'],
            render(text, record) {
                return {
                    props: {
                        style: { background: record.name === "Session Average" ? "lightyellow" : record.name === "Team Average" ? "lightgray" : "" }
                    },
                    children: <div>{text}</div>
                };
            }
        },
        {
            title: "Vertical Bat Angle",
            dataIndex: "vertical_bat_angle",
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "vertical_bat_angle"),
            sortDirections: ['descend', 'ascend'],
            render(text, record) {
                return {
                    props: {
                        style: { background: record.name === "Session Average" ? "lightyellow" : record.name === "Team Average" ? "lightgray" : "" }
                    },
                    children: <div>{text}</div>
                };
            }
        },
        {
            title: "Power",
            dataIndex: "power",
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "power"),
            sortDirections: ['descend', 'ascend'],
            render(text, record) {
                return {
                    props: {
                        style: { background: record.name === "Session Average" ? "lightyellow" : record.name === "Team Average" ? "lightgray" : "" }
                    },
                    children: <div>{text}</div>
                };
            }
        },
        {
            title: "Time to Contact",
            dataIndex: "time_to_contact",
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "time_to_contact"),
            sortDirections: ['descend', 'ascend'],
            render(text, record) {
                return {
                    props: {
                        style: { background: record.name === "Session Average" ? "lightyellow" : record.name === "Team Average" ? "lightgray" : "" }
                    },
                    children: <div>{text}</div>
                };
            }
        },
        {
            title: "Peak Hand Speed",
            dataIndex: "peak_hand_speed",
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "peak_hand_speed"),
            sortDirections: ['descend', 'ascend'],
            render(text, record) {
                return {
                    props: {
                        style: { background: record.name === "Session Average" ? "lightyellow" : record.name === "Team Average" ? "lightgray" : "" }
                    },
                    children: <div>{text}</div>
                };
            }
        }
    ]

    // Batting Practice Player Table Header
    const columnPlayer = [
        {
            title: type === "profileSwingSummary" ? 'Type' : 'Name',
            dataIndex: 'name',
            key: 'name',
            render(text, record) {
                return {
                    props: {
                        style: { background: record.name === "Session Average" ? "lightyellow" : record.name === "Team Average" ? "lightgray" : "" }
                    },
                    children: <div>{text}</div>
                };
            }
        },
        // {
        //     title: 'Plane',
        //     dataIndex: 'plane',
        //     key: 'plane',
        //     render(text, record) {
        //         return {
        //             props: {
        //                 style: { background: record.name === "Session Average" ? "lightyellow" : record.name === "Team Average" ? "lightgray" : "" }
        //             },
        //             children: <div>{text}</div>
        //         };
        //     }
        // },
        // {
        //     title: 'Connection',
        //     dataIndex: 'connection',
        //     key: 'connection',
        //     render(text, record) {
        //         return {
        //             props: {
        //                 style: { background: record.name === "Session Average" ? "lightyellow" : record.name === "Team Average" ? "lightgray" : "" }
        //             },
        //             children: <div>{text}</div>
        //         };
        //     }
        // },
        // {
        //     title: 'Rotation',
        //     dataIndex: 'rotation',
        //     key: 'rotation',
        //     render(text, record) {
        //         return {
        //             props: {
        //                 style: { background: record.name === "Session Average" ? "lightyellow" : record.name === "Team Average" ? "lightgray" : "" }
        //             },
        //             children: <div>{text}</div>
        //         };
        //     }
        // },
        {
            title: 'Bat Speed',
            dataIndex: 'bat_speed',
            key: 'bat_speed',
            render(text, record) {
                return {
                    props: {
                        style: { background: record.name === "Session Average" ? "lightyellow" : record.name === "Team Average" ? "lightgray" : "" }
                    },
                    children: <div>{text}</div>
                };
            }
        },
        {
            title: 'Rotational Acceleration',
            dataIndex: 'rotational_acceleration',
            key: 'rotational_acceleration',
            render(text, record) {
                return {
                    props: {
                        style: { background: record.name === "Session Average" ? "lightyellow" : record.name === "Team Average" ? "lightgray" : "" }
                    },
                    children: <div>{text}</div>
                };
            }
        },
        {
            title: 'On Plane Efficiency',
            dataIndex: 'on_plane_efficiency',
            key: 'on_plane_efficiency',
            render(text, record) {
                return {
                    props: {
                        style: { background: record.name === "Session Average" ? "lightyellow" : record.name === "Team Average" ? "lightgray" : "" }
                    },
                    children: <div>{text}</div>
                };
            }
        },
        {
            title: 'Attack Angle',
            dataIndex: 'attack_angle',
            key: 'attack_angle',
            render(text, record) {
                return {
                    props: {
                        style: { background: record.name === "Session Average" ? "lightyellow" : record.name === "Team Average" ? "lightgray" : "" }
                    },
                    children: <div>{text}</div>
                };
            }
        },
        {
            title: 'Early Connection',
            dataIndex: 'early_connection',
            key: 'early_connection',
            render(text, record) {
                return {
                    props: {
                        style: { background: record.name === "Session Average" ? "lightyellow" : record.name === "Team Average" ? "lightgray" : "" }
                    },
                    children: <div>{text}</div>
                };
            }
        },
        {
            title: 'Connection at Impact',
            dataIndex: 'connection_at_impact',
            key: 'connection_at_impact',
            render(text, record) {
                return {
                    props: {
                        style: { background: record.name === "Session Average" ? "lightyellow" : record.name === "Team Average" ? "lightgray" : "" }
                    },
                    children: <div>{text}</div>
                };
            }
        },
        {
            title: 'Vertical Bat Angle',
            dataIndex: 'vertical_bat_angle',
            key: 'vertical_bat_angle',
            render(text, record) {
                return {
                    props: {
                        style: { background: record.name === "Session Average" ? "lightyellow" : record.name === "Team Average" ? "lightgray" : "" }
                    },
                    children: <div>{text}</div>
                };
            }
        },
        {
            title: 'Power',
            dataIndex: 'power',
            key: 'power',
            render(text, record) {
                return {
                    props: {
                        style: { background: record.name === "Session Average" ? "lightyellow" : record.name === "Team Average" ? "lightgray" : "" }
                    },
                    children: <div>{text}</div>
                };
            }
        },
        {
            title: 'Time to Contact',
            dataIndex: 'time_to_contact',
            key: 'time_to_contact',
            render(text, record) {
                return {
                    props: {
                        style: { background: record.name === "Session Average" ? "lightyellow" : record.name === "Team Average" ? "lightgray" : "" }
                    },
                    children: <div>{text}</div>
                };
            }
        },
        {
            title: 'Peak Hand Speed',
            dataIndex: 'peak_hand_speed',
            key: 'peak_hand_speed',
            render(text, record) {
                return {
                    props: {
                        style: { background: record.name === "Session Average" ? "lightyellow" : record.name === "Team Average" ? "lightgray" : "" }
                    },
                    children: <div>{text}</div>
                };
            }
        }
    ]

    const data = allRows;

    return (
        <>
            <div className="ant-fixed-table">
                <div className="gs-pitchlog-table">
                    {type === "swinglog" ?
                        // window.innerWidth > 767 ?
                            <>
                                {isPrinting ? <div className="print--table--wrp">
                                    <Table
                                        columns={columns}
                                        dataSource={data}
                                        pagination={false}
                                    />
                                </div> :
                                    <div className="custom-header-fixed">
                                        <Table
                                            columns={columns}
                                            dataSource={data}
                                            pagination={false}
                                        // scroll={{ x: 1500, y: 500 }}
                                        />
                                    </div>
                                }
                            </>
                            // :
                            // <>
                            //     {isPrinting ? <div className="print--table--wrp">
                            //         <Table
                            //             columns={columns}
                            //             dataSource={data}
                            //             pagination={false}
                            //         />
                            //     </div> :
                            //         <Table
                            //             columns={columns}
                            //             dataSource={data}
                            //             pagination={false}
                            //         />
                            //     }
                            // </>
                        :
                        <>
                            {isPrinting ? <div className="print--table--wrp">
                                <Table
                                    columns={type === "sessionSummary" ? columns : columnPlayer}
                                    dataSource={data}
                                    pagination={false}
                                />
                            </div> :
                                <Table
                                    columns={type === "sessionSummary" ? columns : columnPlayer}
                                    dataSource={data}
                                    pagination={false}
                                />
                            }
                        </>}
                </div>
            </div>
        </>
    )
}

export default SwingSummaryTable;