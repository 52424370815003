import React, { Component } from 'react';
import { imagesArr } from '../../../assets/images';

class noSpreyChart extends Component {
    render() {
        return (
            <div style={{ height: "413px", display: "flex", alignItems: "center", width: "90%", margin: "auto" }}>
                 {/* <svg width="100%" height="450">
                     <g transform="translate(331,335)" style={{ opacity: '1' }}>
                         <path className="arc" d="M-87.99999999999999,-88A124.45079348883235,124.45079348883235,0,0,1,-32.21023553303058,-120.21023553303058L0,0Z" style={{ fill: 'rgb(178, 24, 43)' }}></path>
                         <path className="arc" d="M-32.21023553303058,-120.21023553303058A124.45079348883235,124.45079348883235,0,0,1,32.21023553303059,-120.21023553303058L0,0Z" style={{ fill: 'rgb(178, 24, 43)' }}></path>
                         <path className="arc" d="M32.21023553303059,-120.21023553303058A124.45079348883235,124.45079348883235,0,0,1,88,-87.99999999999999L0,0Z" style={{ fill: 'rgb(178, 24, 43)' }}></path>
                         <path className="arc" d="M-192.49999999999994,-192.49999999999997A272.2361107568207,272.2361107568207,0,0,1,-70.45989022850438,-262.9598902285044L-32.21023553303058,-120.21023553303058A124.45079348883235,124.45079348883235,0,0,0,-87.99999999999999,-88Z" style={{ fill: 'rgb(178, 24, 43)' }}></path>
                         <path className="arc" d="M-70.45989022850438,-262.9598902285044A272.2361107568207,272.2361107568207,0,0,1,70.45989022850442,-262.9598902285044L32.21023553303059,-120.21023553303058A124.45079348883235,124.45079348883235,0,0,0,-32.21023553303058,-120.21023553303058Z" style={{ fill: 'rgb(178, 24, 43)' }}></path>
                         <path className="arc" d="M70.45989022850442,-262.9598902285044A272.2361107568207,272.2361107568207,0,0,1,192.49999999999997,-192.49999999999994L88,-87.99999999999999A124.45079348883235,124.45079348883235,0,0,0,32.21023553303059,-120.21023553303058Z" style={{ fill: 'rgb(178, 24, 43)' }}></path>
                         <text className="arc-text" x="0" y="-93.33809511662426" transform="rotate(-30)" style={{ color: 'rgb(0, 0, 0)', fill: 'rgb(0, 0, 0)', fontSize: '13px', fontWeight: 'bold' }}>0%</text>
                         <text className="arc-text" x="0" y="-93.33809511662426" transform="rotate(0)" style={{ color: 'rgb(0, 0, 0)', fill: 'rgb(0, 0, 0)', fontSize: '13px', fontWeight: 'bold' }}>0%</text>
                         <text className="arc-text" x="0" y="-93.33809511662426" transform="rotate(30)" style={{ color: 'rgb(0, 0, 0)', fill: 'rgb(0, 0, 0)', fontSize: '13px', fontWeight: 'bold' }}>0%</text>
                         <text className="arc-text" x="0" y="-204.17708306761554" transform="rotate(-30)" style={{ color: 'rgb(0, 0, 0)', fill: 'rgb(0, 0, 0)', fontSize: '13px', fontWeight: 'bold' }}>0%</text>
                         <text className="arc-text" x="0" y="-204.17708306761554" transform="rotate(0)" style={{ color: 'rgb(0, 0, 0)', fill: 'rgb(0, 0, 0)', fontSize: '13px', fontWeight: 'bold' }}>0%</text>
                         <text className="arc-text" x="0" y="-204.17708306761554" transform="rotate(30)" style={{ color: 'rgb(0, 0, 0)', fill: 'rgb(0, 0, 0)', fontSize: '13px', fontWeight: 'bold' }}>0%</text>
                     </g>
                     <g transform="translate(331,335)" style={{ opacity: '1' }}>
                         <rect className="dots-panel" x="-331" y="-335" width="663" height="450" style={{ opacity: '0.6', fill: 'rgb(255, 255, 255)' }}></rect>
                     </g>
                     <g transform="translate(331,245)" style={{ opacity: '0' }}>
                         <text style={{ textAnchor: 'middle' }}></text>
                     </g>
                 </svg> */}
                <img src={imagesArr.nograph} />
             </div>
        );
    }
}

export default noSpreyChart;
