import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import { heatMapDataXformer } from "../../shared/function";
import { constant } from "../../shared/constant";
import domtoimage from 'dom-to-image';
import { downloadChartPrintImg } from "../../shared/printReportUtils";
import PrintImagePopup from "../../shared/PrintImagePopup";

let colorAxisMin = constant.softball ? 25 : 60;
let colorAxisMax = constant.softball ? 85 : 105;
let colorAxisTickInterval = constant.softball ? 5 : 10;
let colorAxisStops;
let dataLabelsFormatter;
let heatMapChartData;

function HeatMapChart({ chartData, id, title, heat_map, min, max, Interval, eventProfileData, sendReq, tabslist, profilepage, isNotDownloadImgLoader, isDownloadImgLoader }) {
    const [isChartImg, setIsChartImg] = useState(false);
    const [clickFlag, setClickFlag] = useState(false);
    const [state, setState] = useState({
        chart: {
            type: 'heatmap',
            marginTop: 52,
            spacingTop: 20,
            spacingBottom: 20,
            spacingLeft: 20,
        },
        credits: {
            enabled: false
        },
        title: {
            text: '',
        },
        xAxis: {
            categories: [""],
            labels: {
                enabled: false,
            },
        },
        yAxis: {
            categories: [""],
            labels: {
                enabled: false,
            },
            title: null,
            reversed: false
        },
        plotOptions: {
            series: {
                states: {
                    inactive: {
                        opacity: 1
                    },
                    hover: {
                        enabled: false,
                    },
                },
            },
        },
        colorAxis: {
            min: constant.softball ? 25 : 60,
            max: constant.softball ? 85 : 105,
            startOnTick: true,
            tickInterval: constant.softball ? 5 : 10,
            visible: false,
            stops: [
                [0, '#1707C7'],
                [0.3, '#8686FD'],
                [0.6, '#FF8465'],
                [0.9, '#850101'],
            ]
        },
        legend: {
            align: 'right',
            layout: 'vertical',
            margin: 0,
            verticalAlign: 'top',
            y: 25,
            symbolHeight: 280,
        },
        tooltip: {
            enabled: false,
        },
        series: [],
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 500
                },
            }]
        }
    });

    useEffect(() => {
        if (chartData) {
            if (chartData.length !== 0) {
                heatMapChartData = heatMapDataXformer(chartData, heat_map);
                if (heat_map) {
                    if (['Efficiency'].includes(heat_map)) {
                        colorAxisMin = 0;
                        colorAxisMax = 100;
                        colorAxisTickInterval = 10;
                        colorAxisStops = constant.colorAxisStops1;
                        dataLabelsFormatter = function formatter() {
                            if (this.point.value) {
                                const roundedValue = Math.ceil(((this.point.value / 100)) / 0.04) * 0.04;
                                const color = constant.colorFromBlueToRedShades1[roundedValue] ? constant.colorFromBlueToRedShades1[roundedValue] : '#FFF';
                                return '<small style="color: ' + color + '">' + this.point.value ? this.point.value : ' ' + '</small>';
                            }
                            return null;
                        }
                    }
                    if (['Avg EV'].includes(heat_map)) {
                        colorAxisMin = constant.softball ? 25 : 60;
                        colorAxisMax = constant.softball ? 85 : 105;
                        colorAxisTickInterval = constant.softball ? 5 : 10;
                        colorAxisStops = constant.colorAxisStops2;
                        dataLabelsFormatter = function formatter() {
                            if (this.point.value) {
                                const roundedValue = Math.ceil(((this.point.value / 100)) / 0.1) * 0.1;
                                const color = constant.colorFromBlueToRedShades2[roundedValue] ? constant.colorFromBlueToRedShades2[roundedValue] : '#FFF';
                                return '<small style="color: ' + color + '">' + this.point.value ? this.point.value : ' ' + '</small>';
                            }
                            return null;
                        }
                    }
                    if (['Pitch', 'Swing', 'Whiff', 'Batted Balls', 'Hard Hit', 'Damage','GB', 'LD', 'FB', 'PU'].includes(heat_map)) {
                        const heatmapfilterdata = heatMapChartData.filter(h => h[2] != null)
                        colorAxisMin = Math.round(Math.min(...heatmapfilterdata.map(h => h[2])))
                        colorAxisMax = Math.round(Math.max(...heatmapfilterdata.map(h => h[2])))
                        colorAxisTickInterval = 0
                        colorAxisStops = constant.colorAxisStops2;
                        dataLabelsFormatter = function formatter() {
                            if (heat_map === 'Damage' || heat_map === 'Whiff' || heat_map === 'Hard Hit' || heat_map === 'GB'|| heat_map === 'LD' || heat_map === 'FB' || heat_map === 'PU') {
                                if (this.point.value !== null) {
                                    const roundedValue = Math.ceil(((this.point.value / 100)) / 0.1) * 0.1;
                                    const color = constant.colorFromBlueToRedShades2[roundedValue] ? constant.colorFromBlueToRedShades2[roundedValue] : '#FFF';
                                    return '<small style="color: ' + color + '">' + this.point.value ? this.point.value.toString().includes(".") ? (parseFloat(this.point.value).toFixed(1) !== "0.0" ? parseFloat(this.point.value).toFixed(1) : '') : parseFloat(this.point.value) : ' ' + '</small>';
                                }
                                return null;
                            } else {  
                                if (this.point.value) {
                                    const roundedValue = Math.ceil(((this.point.value / 100)) / 0.1) * 0.1;
                                    const color = constant.colorFromBlueToRedShades2[roundedValue] ? constant.colorFromBlueToRedShades2[roundedValue] : '#FFF';
                                    return '<small style="color: ' + color + '">' + this.point.value ? this.point.value : ' ' + '</small>';
                                }
                                return null;
                            }
                        }
                    }
                    if (['Avg Velocity', 'Avg Spin Rate', 'V Break', 'Induced V Break', 'H Break', 'V Approach', 'H Approach', 'V Release Angle', 'H Release Angle'].includes(heat_map)) {
                        colorAxisMin = min;
                        colorAxisMax = max;
                        colorAxisTickInterval = Interval;
                        colorAxisStops = constant.colorAxisStops2;
                        dataLabelsFormatter = function formatter() {
                            if (this.point.value) {
                                const roundedValue = Math.ceil(((this.point.value / 100)) / 0.1) * 0.1;
                                const color = constant.colorFromBlueToRedShades2[roundedValue] ? constant.colorFromBlueToRedShades2[roundedValue] : '#FFF';
                                return '<small style="color: ' + color + '">' + this.point.value ? this.point.value : ' ' + '</small>';
                            }
                            return null;
                        }
                    }
                    if (['Avg LA', 'Avg Tilt'].includes(heat_map)) {
                        dataLabelsFormatter = function formatter() {
                            if (this.point.value) {
                                return '<small style="color: #000;">' + this.point.value ? heat_map === "Avg Tilt" ? convertTimeToSS(this.point.value) : this.point.value : ' ' + '</small>';
                            }
                            return null;
                        }
                    }
                }
                let seriesData = [{
                    name: '',
                    borderWidth: 1,
                    data: heatMapChartData,
                    dataLabels: {
                        enabled: true,
                        style: {
                            // color: '#FFFFFF',
                            textOutline: '0px contrast',
                            fontSize: heat_map === "Avg Spin Rate" ? window.innerWidth < 1600 ? '14px' : '11px' : '15px',
                        },
                        formatter: dataLabelsFormatter
                    }
                },
                {
                    name: 'Heatmap',
                    color: 'black',
                    type: 'line',
                    data: [
                        [0.5, 0.5],
                        [0.5, 3.5],
                        [3.5, 3.5],
                        [3.5, 0.5],
                        [0.5, 0.5],
                    ],
                }]

                // let newTooltip = {
                //     enabled: false,
                //     formatter: function () {
                //         if (this.point.series.name === "Heatmap") return false
                //         var count = chartData[0][`zone${this.point.x}_${this.point.y}_count`];
                //         return `Batted Balls: ${count ? count : 0}`;
                //     },
                // }
                let colorAxisObj = {
                    stops: colorAxisStops,
                    min: colorAxisMin,
                    max: colorAxisMax,
                    tickInterval: colorAxisTickInterval,
                    visible: false
                }

                state.colorAxis = (heat_map === "Avg LA" || heat_map === "Avg Tilt") ? { visible: false, stops: constant.colorAxisStops3 } : colorAxisObj
                // state.tooltip = newTooltip;
                state.series = seriesData;
                Highcharts.chart(id, state);
            } else {
                state.series = [{ showInLegend: false }];
                Highcharts.chart(id, state);
            }
        }

    }, [chartData]);

    const convertTimeToSS = (x) => {
        let hour = Math.floor(x / 30) + 6
        if(hour > 12) hour = hour - 12
        let minute = Math.round((x % 30) * 2)
        if (minute < 10) minute = `0${minute}`
        return `${hour}:${minute}`
    }

    const chartImage = (id) => {
        isDownloadImgLoader();
        setClickFlag(true)
        if (document.getElementById(`img${id}`)) {
            document.getElementById(`img${id}`).remove()
        }
        setIsChartImg(true)
        downloadChartPrintImg(id, setIsChartImg, setClickFlag, isNotDownloadImgLoader)
    }

    return (
        <>
            <div className="ground-chart">
                <div className="legends graph-select text-left">
                    <div className="text-left d-inline">
                        <h3 className="mb-0">{title}</h3>
                    </div>
                </div>
                <div className="heatmap">
                    <div style={{ width: "320px" }} id={id} />
                </div>
                {/* <button className="btn blue btn-view" onClick={() => chartImage(id)} disabled={clickFlag} >Download</button> */}
                <div class="chartdownload" onClick={() => chartImage(id)} disabled={clickFlag}><a class="btn white"><i class="fa fa-download" aria-hidden="true"></i></a></div>
            </div>

            {/* chart visual image download */}
            {isChartImg && <PrintImagePopup
                title={title}
                eventProfileData={eventProfileData}
                id={id}
                sendReq={sendReq}
                tabslist={tabslist}
                profilepage={profilepage} />}
        </>
    );
}

export default HeatMapChart;