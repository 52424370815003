import React, { useState } from 'react';
import { imagesArr } from '../../../assets/images';
import SwingSummaryTable from './SwingSummaryTable';

function PitcherSummaryTable({ summaryTable, print, printAll, isPrint }) {
    const [isPrinting, setIsPrinting] = useState(false)
    return (
        <>
            <section className="pb-0">
                <div className="container-fluid">
                    <div className="common-title">
                        <h2>Session Summary</h2>
                        {/* {!isPrint && <div className="dropdown">
                            <span className="printer"><img src={imagesArr.printer} alt="print" /></span>
                            <div className="dropdown-content">
                                <ul>
                                    <li onClick={() => print()}>Print</li>
                                    <li onClick={() => printAll()}>Print All</li>
                                </ul>
                            </div>
                        </div>} */}
                    </div>
                </div>
                <div id="sessionSummaryPrintArea" >
                    <div className="text-center">
                        <h3 className="table-title">BLAST SESSION SUMMARY</h3>
                    </div>
                    <div className="container-fluid">
                        <div className="blast-summary-result-table">
                            <div className="table-responsive-xl">
                                <SwingSummaryTable
                                    tableData={summaryTable}
                                    type="sessionSummary"
                                    isPrinting={isPrint || isPrinting}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default PitcherSummaryTable