import React, { Component } from 'react';
import { imagesArr } from "../../../assets/images";
import moment from "moment";
import range from "../../profiledashboard/common/range.json";

class appliedFilter extends Component {
    render() {
        const { summaryData, arr, bbeMax, print } = this.props;
        return (
            <div className="filtersection d-flex align-items-center">
                <div className="d-flex align-items-center w-70">
                    {arr.length !== 0 && <h3>Applied Filters:</h3>}
                    {Object.entries(summaryData).map(
                        (key, value) => {
                            return (
                                key[0] !== "tab" &&
                                key[1] !== "" &&
                                key[0] !== "date_from" &&
                                key[0] !== "date_to" &&
                                key[0] !== "bbe_to" &&
                                key[0] !== "bbe_from" &&
                                key[0] !== "velocity_to" &&
                                key[0] !== "velocity_from" &&
                                key[0] !== "exit_velocity_to" &&
                                key[0] !== "exit_velocity_from" &&
                                key[0] !== "spin_rate_to" &&
                                key[0] !== "spin_rate_from" &&
                                key[0] !== "launch_angle_to" &&
                                key[0] !== "launch_angle_from" &&
                                key[0] !== "efficiency_to" &&
                                key[0] !== "efficiency_from" &&
                                key[0] !== "vertical_break_to" &&
                                key[0] !== "vertical_break_from" &&
                                key[0] !== "induced_vertical_break_to" &&
                                key[0] !== "induced_vertical_break_from" &&
                                key[0] !== "horizontal_break_to" &&
                                key[0] !== "horizontal_break_from" &&
                                key[0] !== "vertical_approach_angle_to" &&
                                key[0] !== "vertical_approach_angle_from" &&
                                key[0] !== "horz_approach_angle_to" &&
                                key[0] !== "horz_approach_angle_from" &&
                                key[0] !== "release_height_to" &&
                                key[0] !== "release_height_from" &&
                                key[0] !== "extension_to" &&
                                key[0] !== "extension_from" && (
                                    <span key={value} className="filtertags">
                                        {key[0] === "season" ? key[1] + "-" + (parseFloat(key[1]) + 1) : key[1]}
                                        {key[0] !== "season" && key[0] !== "fall_spring" && <img
                                            src={imagesArr.iconclose}
                                            alt="close"
                                            className="d-inline"
                                            onClick={this.props.removeFilter.bind(
                                                this,
                                                key[0]
                                            )}
                                        />}
                                    </span>
                                )
                            );
                        }
                    )}
                    {/* {summaryData.date_from !== "" &&
                        summaryData.date_to !== "" && (
                            <span className="filtertags">
                                {" "}
                                {moment(summaryData.date_from).format(
                                    "DD/MM/YYYY"
                                )}{" "}
                              -{" "}
                                {moment(summaryData.date_to).format(
                                    "DD/MM/YYYY"
                                )}{" "}
                                <img
                                    src={imagesArr.iconclose}
                                    alt="close"
                                    className="d-inline"
                                    onClick={this.props.removeFilter.bind(
                                        this,
                                        "date_from"
                                    )}
                                />{" "}
                            </span>
                        )} */}
                    {summaryData.date_from !== "" &&
                        (<span className="filtertags">
                            From : {moment(summaryData.date_from).format("MM/DD/YYYY")}
                            <img
                                src={imagesArr.iconclose}
                                alt="close"
                                className="d-inline"
                                onClick={this.props.removeFilter.bind(this, "date_from")}
                            />
                        </span>)}
                    {summaryData.date_to !== "" &&
                        (<span className="filtertags">
                            To : {moment(summaryData.date_to).format("MM/DD/YYYY")}
                            <img
                                src={imagesArr.iconclose}
                                alt="close"
                                className="d-inline"
                                onClick={this.props.removeFilter.bind(this, "date_to")}
                            />
                        </span>)}
                    {summaryData.bbe_to !== "" && summaryData.bbe_from !== "" && (summaryData.bbe_from > 0 || summaryData.bbe_to < bbeMax) && (
                        <span className="filtertags">
                            {" "}
                            Batted Ball Events : {summaryData.bbe_from} to {summaryData.bbe_to}{" "}
                            <img
                                src={imagesArr.iconclose}
                                alt="close"
                                className="d-inline"
                                onClick={this.props.removeFilter.bind(this, "bbe_to")}
                            />{" "}
                        </span>
                    )}
                    {range.map((r, i) => {
                        return (
                            <>
                                {summaryData[r.to] !== "" && summaryData[r.from] !== "" && (summaryData[r.from] > r.min || summaryData[r.to] < r.max) && (
                                    <span className="filtertags" key={i}>
                                        {" "}
                                        {r.name} : {summaryData[r.from]}mph - {summaryData[r.to]}mph{" "}
                                        <img
                                            src={imagesArr.iconclose}
                                            alt="close"
                                            className="d-inline"
                                            onClick={() => this.props.removeFilter(r.from, r.min, r.max)}
                                        />{" "}
                                    </span>
                                )}
                            </>
                        )
                    })}
                </div>
                <div className="btnfilter w-30">
                    <button
                        className="btn white"
                        onClick={this.props.handleToggle}
                    >
                        <div className="d-none d-sm-block">
                            <sup>{arr.length}</sup>
                        </div>
                        <span>
                            <img
                                src={imagesArr.iconfilter}
                                alt="filter"
                                className="d-inline"
                            />
                        </span>
                        <span className="d-sm-none">
                            Filter<span>({arr.length})</span>
                        </span>
                    </button>
                    <div className="btnprint">
                        <a className="btn white" id='getPDF'><span> <img src={imagesArr.printer} alt="print" onClick={() => print()} /></span> </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default appliedFilter;
