import React, { useEffect, useState } from "react";
import * as d3 from "d3";
import BasicSpray from "./SprayChartd3";
import NoSpreyChart from "../noSpreyChart";
import SprayChartRangeHeader from "./SprayChartRangeHeader";
import PitchRWebGL from "../../webGL/PitchRWebGL";
import Toggle from "../../../shared/Toggle";
import domtoimage from 'dom-to-image';
import PrintImagePopup from "../../../shared/PrintImagePopup";
import { downloadChartPrintImg } from "../../../shared/printReportUtils";
import use3DView from "../../../shared/use3DView";

function HitterSpyayChart({ chartData, softball, index, tabName, cameraAngle, visualType, pitchLocationType, colorScheme, pitchrIframeFlag, closePitchrIframe, eventProfileData, sendReq, tabslist, profilepage, pitcherDefault, isNotDownloadImgLoader, isDownloadImgLoader, eventName }) {
  const [data3D, setData3D, toggle, setToggle, onClickToggleButton, flag] = use3DView(`groundChartForHitt${index || ""}`);
  const [isChartImg, setIsChartImg] = useState(false);
  const [clickFlag, setClickFlag] = useState(false);

  useEffect(() => {
    let arr = chartData && chartData.length !== 0 && chartData.map(x => x?._id) || []
    setData3D(arr)
    renderChart(chartData);
  }, [chartData, toggle]);

  // bp event player name change,set pichRIframe initial state
  useEffect(() => {
    if (pitchrIframeFlag === "yes") {
      setData3D(null)
      setToggle({ chartToggle: true });
      closePitchrIframe();
    }
  }, [pitchrIframeFlag])

  const renderChart = (data) => {
    const selection = d3.select(`#groundChartForHitt${index || ""}`);
    if (!selection.empty()) {
      const sprayChart = new BasicSpray(selection, softball);
      sprayChart.update(data);
    }
  };

  const chartImage = (id) => {
    isDownloadImgLoader();
    setClickFlag(true)
    if (document.getElementById(`img${id}`)) {
      document.getElementById(`img${id}`).remove()
    }
    setIsChartImg(true)
    downloadChartPrintImg(id, setIsChartImg, setClickFlag, isNotDownloadImgLoader)
  }

  return (
    <>
      {chartData && chartData.length !== 0 ?
        <div className={`ground-chart sprayChartCls contactTabSpray1 ${tabName === "contactTab" ? "fixed-h" : ""}`} >
          {chartData && chartData.length !== 0 ? <Toggle toggle={toggle} onClickToggleButton={onClickToggleButton} /> : ""}
          <div style={{ display: toggle.chartToggle === true ? 'block' : 'none' }}>
            <div className="spraychart-section">
              <div className="legends text-left fixed-s-l">
                <h3 style={{ marginBottom: "5px" }}>EV Spray Chart</h3>
                <div className="sprayChartHead">
                  <SprayChartRangeHeader softball={softball} />
                </div>
              </div>
              <div id={`groundChartForHitt${index || ""}`} className="groundChart" ></div>
              {/* <button className="btn blue btn-view" onClick={() => chartImage(`groundChartForHitt${index || ""}`)} disabled={clickFlag} >Download</button> */}
              <div class="chartdownload" onClick={() => chartImage(`groundChartForHitt${index || ""}`)} disabled={clickFlag}><a class="btn white"><i class="fa fa-download" aria-hidden="true"></i></a></div>
            </div>
          </div>
          {flag?.[`iframe_groundChartForHitt${index || ""}`] === true && <div id="pitchR-iframe" className="Chart-iframe" style={{ display: toggle.chartToggle === false ? 'block' : 'none' }}>
            <PitchRWebGL
              data={data3D}
              id={`groundChartForHitt${index || ""}`}
              cameraAngle={cameraAngle}
              visualType={visualType}
              Toggle={toggle.chartToggle}
              pitchLocationType={pitchLocationType}
              colorScheme={colorScheme} />
          </div>}
        </div>
        :
        <NoSpreyChart tabName={tabName} height="333px" />}

      {/* chart visual image download */}
      {isChartImg && <PrintImagePopup
        title="EV Spray Chart"
        eventProfileData={eventProfileData}
        id={`groundChartForHitt${index || ""}`}
        sendReq={sendReq}
        tabslist={tabslist}
        pitcherDefault={pitcherDefault}
        eventName={eventName}
        profilepage={profilepage} />}
    </>
  );
}

export default HitterSpyayChart;
