// lib
import * as d3 from 'd3';

import {
  d3LineGenerator,
  d3ChartXAxis,
  d3ChartYAxis,
  d3ContourDensityCalculator,
  d3ChartdimentionCalculator,
} from '../../../shared/function';

// chnage structuring

const ContourGraph = (
  id,
  data,
  revievedWidth = 500,
  recievedHeight = 500,
  xAxisDomain,
  yAxisDomain,
  squareBox,
  pentagonBox,
  colorRange,
  xAxisTicksRequired = 5,
  yAxisTicksRequired = 5,
) => {
  const margin = { top: 72, right: 20, bottom: 50, left: 20 };
  const width = d3ChartdimentionCalculator(
    revievedWidth,
    margin.left,
    margin.right,
  );
  const height = d3ChartdimentionCalculator(
    recievedHeight,
    margin.top,
    margin.bottom,
  );
  const xAxis = d3ChartXAxis(width, xAxisDomain);
  const yAxis = d3ChartYAxis(height, yAxisDomain);
  const densityData = d3ContourDensityCalculator(
    xAxis,
    yAxis,
    width,
    height,
    data,
  );

  // append the svg object to the body of the page
  const svg = d3
    .select(id)
    .append('svg')
    .attr('width', width + margin.left + margin.right)
    .attr('height', height + margin.top + margin.bottom);

  const chart = svg
    .append('g')
    .attr('transform', `translate(${margin.left},${margin.top})`);

  // Add X axis
  chart
    .append('g')
    .attr('transform', `translate(0, ${height})`)
    .call(d3.axisBottom(xAxis).ticks(xAxisTicksRequired));

  // Add Y axis
  chart.append('g').call(d3.axisLeft(yAxis).ticks(yAxisTicksRequired));

  const boxDataGenerator = squareBoxData =>
    squareBoxData.map(e => [xAxis(e[0]), yAxis(e[1])]);

  // compute the density data
  const thresholds = densityData.map(r => r.value);
  const extents = d3.extent(thresholds);
  extents.push(d3.median(thresholds));
  const color = d3
    .scaleLog()
    .domain(extents.sort())
    .range(colorRange);

  // Add the contour: several "path"
  chart
    .selectAll('path')
    .data(densityData)
    .enter()
    .append('path')
    .attr('class', 'contourGraph')
    .attr('d', d3.geoPath())
    .attr('fill', d => color(d.value))
    .attr('stroke', '#e3e3e3')
    .attr('stroke-opacity', 0.15);

  // Repeated Axis are to show axis above of chart
  // Add X axis
  chart
    .append('g')
    .attr('transform', `translate(0, ${height})`)
    .call(d3.axisBottom(xAxis).ticks(xAxisTicksRequired));

  // Add Y axis
  chart.append('g').call(d3.axisLeft(yAxis).ticks(yAxisTicksRequired));

  if (squareBox) {
    chart
      .append('path')
      .attr('stroke', 'black')
      .attr('fill', 'none')
      .attr('d', () => d3LineGenerator(boxDataGenerator(squareBox)));
  }
  if (pentagonBox) {
    chart
      .append('path')
      .attr('stroke', 'black')
      .attr('fill', 'none')
      .attr('d', () => d3LineGenerator(boxDataGenerator(pentagonBox)));
  }
};

export default ContourGraph;
