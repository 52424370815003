import React, { useState, useEffect, useRef } from "react";
import { imagesArr } from "../../../../assets/images";
import { createColoredColumnObj, stringValueSort, getSortedValues, createColoredColumnObjTypeText, createPercentageColumnObj } from "../../../../shared/function";
import { Table } from 'antd';
import PrintTable from "./print-pitchLog-table";
import VideoPopup from "../../../../shared/VideoPopup";

function PitcherLogTable({ tableData, isPrinting }) {
  const wrapperRef = useRef(null);
  const [isVideoShow, setIsVideoShow] = useState({
    videoFlag: false,
    videoData: null
  })

  var allRows = [];
  tableData &&
    tableData.map((list, i) =>
      allRows.push({
        key: i,
        // rank: list.rank ? Number(list.rank) : '—',
        game_pitch: list.game_pitch ? Number(list.game_pitch) : '—',
        // game_pitch: list.videoData.length ? <div className="table-video-icon"><span onClick={() => list.videoData.length !== 0 && videoPlay(list.videoData)}><i class="fa fa-video" aria-hidden="true"></i></span>{list.game_pitch ? Number(list.game_pitch) : '—'}</div> : list.game_pitch ? Number(list.game_pitch) : '—',
        pitcher: list.pitcher ? list.pitcher : '—',
        pitch_type: list.pitch_type ? list.pitch_type : '—',
        batter: list.batter ? list.batter : '—',
        result: list.result ? list.result : '—',
        team: list.team ? list.team : '—',
        pitch_velocity: list.pitch_velocity ? Number(list.pitch_velocity.toString().includes(".")) ? Number(list.pitch_velocity.toFixed(1)) : Number(list.pitch_velocity) : '—',
        spin_rate: list.spin_rate ? Number(Math.round(list.spin_rate)) : '—',
        tilt: list.tilt ? list.tilt : '—',
        efficiency: list.efficiency ? Number(Math.round(list.efficiency)) : '—',
        ind_v_break: list.ind_v_break ? Number(list.ind_v_break.toString().includes(".")) ? Number(list.ind_v_break.toFixed(1)) : Number(list.ind_v_break) : '—',
        h_break: list.h_break ? Number(list.h_break.toString().includes(".")) ? Number(list.h_break.toFixed(1)) : Number(list.h_break) : '—',
        vert_appr_angle: list.vert_appr_angle ? Number(list.vert_appr_angle.toString().includes(".")) ? Number(list.vert_appr_angle.toFixed(1)) : Number(list.vert_appr_angle) : '—',
        release_height: list.release_height ? Number(list.release_height.toString().includes(".")) ? Number(list.release_height.toFixed(1)) : Number(list.release_height) : '—',
        exit_velocity: list.exit_velocity ? Number(list.exit_velocity.toString().includes(".")) ? Number(list.exit_velocity.toFixed(1)) : Number(list.exit_velocity) : '—',
        launch_angle: list.launch_angle ? Number(list.launch_angle.toString().includes(".")) ? Number(list.launch_angle.toFixed(1)) : Number(list.launch_angle) : '—',
        distance: list.distance ? Number(Math.round(list.distance)) : '—',
      })
    );

  const columns = [
    {
      title: 'Pitches',
      children: [
        // {
        //   title: 'Rank',
        //   dataIndex: 'rank',
        //   // sorter: (a, b) => stringValueSort(a.rank, b.rank),
        //   sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, 'rank'),
        //   sortDirections: ['descend', 'ascend'],
        // },
        {
          title: '#',
          dataIndex: 'game_pitch',
          // sorter: (a, b) => stringValueSort(a.game_pitch, b.game_pitch),
          sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, 'game_pitch'),
          sortDirections: ['descend', 'ascend'],
          fixed: isPrinting ? "" : 'left',
          width: "5%"
        },
        {
          title: 'Pitcher',
          dataIndex: 'pitcher',
          sorter: (a, b) => stringValueSort(a.pitcher, b.pitcher),
          sortDirections: ['descend', 'ascend'],
          fixed: isPrinting ? "" : 'left',
          width: "12%"
        },
        createColoredColumnObjTypeText('Pitch Type', 'pitch_type', allRows, false, {
          sorter: (a, b) => stringValueSort(a.pitch_type, b.pitch_type),
          sortDirections: ['descend', 'ascend'],
          fixed: isPrinting ? "" : 'left',
          width: "8%"
        }),
        {
          title: 'Batter',
          dataIndex: 'batter',
          sorter: (a, b) => stringValueSort(a.batter, b.batter),
          sortDirections: ['descend', 'ascend'],
          width: "12%"
        },
        {
          title: 'Result',
          dataIndex: 'result',
          sorter: (a, b) => stringValueSort(a.result, b.result),
          sortDirections: ['descend', 'ascend'],
          width: "12%"
        },
        {
          title: 'Team',
          dataIndex: 'team',
          sorter: (a, b) => stringValueSort(a.team, b.team),
          sortDirections: ['descend', 'ascend'],
          width: "12%"
        },
      ],
    },
    {
      title: 'Pitch',
      children: [
        createColoredColumnObj('Velo', 'pitch_velocity', allRows, false, {
          sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, 'pitch_velocity'),
          sortDirections: ['descend', 'ascend'],
          width: "5%"
        }),
        {
          title: 'Spin',
          dataIndex: 'spin_rate',
          sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, 'spin_rate'),
          sortDirections: ['descend', 'ascend'],
          width: "6%"
        },
        {
          title: 'Tilt',
          dataIndex: 'tilt',
          sorter: (a, b) => {
            if (!b.tilt) return -1;
            a = (a.tilt.split(":")[0] * 360) / 12 + (a.tilt.split(":")[1] * 360) / (12 * 60)
            b = (b.tilt.split(":")[0] * 360) / 12 + (b.tilt.split(":")[1] * 360) / (12 * 60)
            return a > b ? 1 : -1
          },
          // sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, 'tilt'),
          sortDirections: ['descend', 'ascend'],
          width: "6%"
        },
        createPercentageColumnObj('Efficiency', 'efficiency', allRows, true, {
          sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, 'efficiency'),
          sortDirections: ['descend', 'ascend'],
          width: "8%"
        }),
        // {
        //   title: 'Efficiency',
        //   dataIndex: 'efficiency',
        //   sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, 'efficiency'),
        //   sortDirections: ['descend', 'ascend'],
        // },
        {
          title: 'Ind V',
          dataIndex: 'ind_v_break',
          sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, 'ind_v_break'),
          sortDirections: ['descend', 'ascend'],
          width: "5%"
        },
        {
          title: 'H Break',
          dataIndex: 'h_break',
          sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, 'h_break'),
          sortDirections: ['descend', 'ascend'],
          width: "5%"
        },
        {
          title: 'VAA',
          dataIndex: 'vert_appr_angle',
          sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, 'vert_appr_angle'),
          sortDirections: ['descend', 'ascend'],
          width: "5%"
        },
        {
          title: 'Rel Ht',
          dataIndex: 'release_height',
          sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, 'release_height'),
          sortDirections: ['descend', 'ascend'],
          width: "5%"
        },
      ],
    },
    {
      title: 'Hit',
      children: [
        createColoredColumnObj('EV', 'exit_velocity', allRows, false, {
          sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, 'exit_velocity'),
          sortDirections: ['descend', 'ascend'],
          width: "5%"
        }),
        {
          title: 'LA',
          dataIndex: 'launch_angle',
          sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, 'launch_angle'),
          sortDirections: ['descend', 'ascend'],
          width: "5%"
        },
        // {
        //   title: 'Dist',
        //   dataIndex: 'distance',
        //   sorter: (a, b) => (a.dist && b.dist) && stringValueSort(a.dist, b.dist),
        //   sortDirections: ['descend', 'ascend'],
        // },
        createColoredColumnObj('Dist', 'distance', allRows, false, {
          sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, 'distance'),
          sortDirections: ['descend', 'ascend'],
          width: "5%"
        }),
      ],
    }
  ];

  const data = allRows;

  // useEffect(() => {
  //   document.addEventListener("click", handleClickOutside, false);
  //   return () => {
  //     document.removeEventListener("click", handleClickOutside, false);
  //   };
  // }, []);

  const onChange = (filters, sorter, extra) => {
    // console.log('params', filters, sorter, extra);
  }

  // const videoPlay = (videoData) => {
  //   setIsVideoShow({
  //     videoFlag: true,
  //     videoData: videoData
  //   })
  // }

  // const handleClickOutside = event => {
  //   if (wrapperRef.current && wrapperRef.current.contains(event.target)) {
  //     setIsVideoShow({
  //       videoFlag: false,
  //       videoData: null
  //     })
  //   }
  // };

  return (
    <>
      <div className="ant-fixed-table">
        {(isPrinting && data.length > 10) ? <PrintTable columns={columns} dataSource={data} /> :
          window.innerWidth > 767 ?
            <div className="gs-pitchlog-table" id="antTablePrintId">
              {isPrinting ? <div className="print--table--wrp"><Table columns={columns} dataSource={data} onChange={onChange} pagination={false} /></div> :
                <Table columns={columns} dataSource={data} onChange={onChange} pagination={false} scroll={{ x: 1500, y: 500 }} />}
            </div>
            :
            <div className="gs-pitchlog-table" id="antTablePrintId">
              {isPrinting ? <div className="print--table--wrp"><Table columns={columns} dataSource={data} onChange={onChange} pagination={false} /></div> :
              <div className="table-responsive-xl">
                <Table columns={columns} dataSource={data} onChange={onChange} pagination={false} />
              </div>}
            </div>}
      </div>

      {/* popup design for video */}
      {/* <VideoPopup
        isVideoShow={isVideoShow}
        setIsVideoShow={setIsVideoShow}
        wrapperRef={wrapperRef} /> */}
    </>
  );
}

export default PitcherLogTable;
