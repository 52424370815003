// lib
import html2canvas from 'html2canvas';
import JSPDF from 'jspdf';
import { imagesArr } from '../assets/images';
import { constant } from './constant';
import domtoimage from 'dom-to-image';

export const printToPDF = async (reportNamePrefix, printArea = 'printArea') => {
  const area = document.getElementById(printArea);
  return html2canvas(area, {
    scale: 1.5,
    logging: false,
    backgroundColor: '#E5E5E5',
  }).then(canvas => {
    const imgData = canvas.toDataURL('image/png');
    const orientation = canvas.width > canvas.height ? 'l' : 'p';
    const pdf = new JSPDF(orientation, 'px', [
      canvas.width + 20,
      canvas.height + 20,
    ]);
    const width = pdf.internal.pageSize.getWidth();
    const height = pdf.internal.pageSize.getHeight();
    pdf.setFillColor('#E5E5E5');
    pdf.rect(0, 0, width, height, 'F');
    pdf.addImage(imgData, 'JPEG', 10, 10, width - 20, height - 20, '', 'FAST');
    pdf.save(`${reportNamePrefix}_${new Date().getTime()}.pdf`);
  });
};

export const printToPDFMultiple = async (reportOwner, areas) => {
  let doc;
  let i = 0;
  await areas.reduce(async prevPromise => {
    return prevPromise.then((canvas = '') => {
      if (canvas !== '') {
        const orientation = canvas.width > canvas.height ? 'l' : 'p';
        if (!doc) {
          doc = new JSPDF(orientation, 'px', [
            canvas.height + 20,
            canvas.width + 20,
          ]);
        }
        doc.addImage(constant.softball ? imagesArr.sc_print : imagesArr.bc_print ,'PNG',20,doc.internal.pageSize.getHeight()-25,90,0);
        if (i > 0) {
          doc.addPage([canvas.width + 20, canvas.height + 20], orientation)
        }
        const width = doc.internal.pageSize.getWidth();
        const height = doc.internal.pageSize.getHeight();

        doc.setFillColor(235, 235, 235);
        doc.rect(0, 0, width, height, 'F');

        doc.addImage(
          canvas.toDataURL('image/png'),
          'JPEG',
          10,
          10,
          width - 20,
          height - 20,
          '',
          'FAST',
        );
        if (i === areas.length - 1) {
          doc.addImage(constant.softball ? imagesArr.sc_print : imagesArr.bc_print ,'PNG',20,doc.internal.pageSize.getHeight()-25,90,0);
          doc.save(`${reportOwner}`);
          return Promise.resolve();
        }
        i += 1;

        return html2canvas(document.getElementById(areas[i]), {
          scale: 1,
          logging: false,
          scrollX: 0, scrollY: 0,
        });
      }
      return Promise.resolve();
    });
  }, html2canvas(document.getElementById(areas[0]), { scale: 1, logging: false,scrollX: 0, scrollY: 0,imageTimeout: 0 }));
};

export const downloadChartPrintImg = (id, setIsChartImg, setClickFlag, isNotDownloadImgLoader) => {
  domtoimage.toPng(document.getElementById(id))
    .then(function (dataUrl) {
      var img = new Image();
      img.src = dataUrl;
      img.id = `img${id}`
      const imageDiv = document.getElementById(`printImg${id}`)
      imageDiv.appendChild(img);

      setTimeout(() => {
        html2canvas(document.getElementById(`printImgmain${id}`), {
          useCORS: true,
          allowTaint: false,
          logging: true,
          scale: 1,
          quality: 1
        }).then(canvas => {
          const imgData = canvas.toDataURL('image/png');
          var link = document.createElement('a');
          link.download = 'chart.jpeg';
          link.href = imgData;
          link.click();
          setIsChartImg(false)
          setClickFlag(false)
          isNotDownloadImgLoader()
        })
      }, 1000);
    })
    .catch(function (error) {
      console.error('oops, something went wrong!', error);
    });
}

export const printToPDFTest = async (reportNamePrefix, areas) => {
  let data = []
  var pdf = new JSPDF({orientation: 'p',  unit: 'px', format: 'a3',putOnlyUsedFonts:true});
  for (let i = 0; i < areas.length; i++) {
    const area = document.getElementById(areas[i]);
    let canvas = await html2canvas(area, {
      scale: 1.5,
      logging: false,
      backgroundColor: '#E5E5E5',
    })
    const imgData = canvas.toDataURL('image/png');
    data.push(imgData)
      // const orientation = canvas.width > canvas.height ? 'l' : 'p';
      const width = pdf.internal.pageSize.getWidth();
      const height = pdf.internal.pageSize.getHeight();
      pdf.setFillColor('#E5E5E5');
      pdf.rect(0, 0, width, height, 'F');
      pdf.addPage({orientation: 'p',  unit: 'px', format: 'a3',putOnlyUsedFonts:true})
      pdf.addImage(imgData, 'JPEG', 10, 10, width - 20, height - 20, '', 'FAST');
    }  
    return pdf.save(`${reportNamePrefix}_${new Date().getTime()}.pdf`);

};