import React, { Component } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
// import StrikeZoneChart from "./component/strikeZoneChart";
import StrikeZoneChart from "../Chart/strikeZoneChart";
import ReleasePointChart from "./component/releasePointChart";
import { constant } from '../../../shared/constant';
import SideReleaseChart from './component/sideReleaseChart';

class releasepoint extends Component {

    constructor(props) {
        super(props)
        this.state = {

        }
    }

    getSummaryData = (type) => {
        let summaryData = this.props.filterRes.release_point_summary_table;
        var isData = "";
        summaryData.length > 0 &&
        summaryData.forEach((data) => {
                if (data._id === type) return (isData = data)
            });
        return isData;
    }

    renderTable = () => {
        return (
            this.props.filterRes &&
            this.props.filterRes.release_point_table_data &&
            Object.entries(this.props.filterRes.release_point_table_data).map((key, value) => {
                return (
                    <tr>
                        <td className="table-rowwhite" style={{color: constant.pitchTypeColor[key[0]]}}>{key[0] !== null ? key[0] : "—"}</td>
                        <td className="table-rowwhite">{key[1] ? key[1].toString().includes(".") ? Number(key[1]).toFixed(2) : key[1] : "—"}</td>
                        <td className="table-rowwhite">{this.getSummaryData(key[0]) ? this.getSummaryData(key[0]).avg_release_height ? this.getSummaryData(key[0]).avg_release_height.toString().includes(".") ? this.getSummaryData(key[0]).avg_release_height.toFixed(2) : this.getSummaryData(key[0]).avg_release_height : "—" : "—"}</td>
                        <td className="table-rowwhite">{this.getSummaryData(key[0]) ? this.getSummaryData(key[0]).avg_release_side ? this.getSummaryData(key[0]).avg_release_side.toString().includes(".") ? this.getSummaryData(key[0]).avg_release_side.toFixed(2) : this.getSummaryData(key[0]).avg_release_side : "—" : "—"}</td>
                        <td className="table-rowwhite">{this.getSummaryData(key[0]) ? this.getSummaryData(key[0]).avg_extension ? this.getSummaryData(key[0]).avg_extension.toString().includes(".") ? this.getSummaryData(key[0]).avg_extension.toFixed(2) : this.getSummaryData(key[0]).avg_extension : "—" : "—"}</td>
                        <td className="table-rowwhite">{this.getSummaryData(key[0]) ? this.getSummaryData(key[0]).avg_vertical_release_angle ? this.getSummaryData(key[0]).avg_vertical_release_angle.toString().includes(".") ? this.getSummaryData(key[0]).avg_vertical_release_angle.toFixed(2) : this.getSummaryData(key[0]).avg_vertical_release_angle : "—" : "—"}</td>
                        <td className="table-rowwhite">{this.getSummaryData(key[0]) ? this.getSummaryData(key[0]).avg_horizontal_release_angle ? this.getSummaryData(key[0]).avg_horizontal_release_angle.toString().includes(".") ? this.getSummaryData(key[0]).avg_horizontal_release_angle.toFixed(2) : this.getSummaryData(key[0]).avg_horizontal_release_angle : "—" : "—"}</td>
                    </tr>
                )
            })
        )
    }

    render() {
        return (
            <>
                <div className="d-flex">
                    <div className="w-100">
                        <div className="container-fluid">
                            <div className="common-charts">
                                <div className={`row ${!this.props.isPrinting ? 'singleDiv' : 'breakDiv'}`}>
                                    <div className="w-33">
                                        {
                                            this.props.filterRes &&
                                            <StrikeZoneChart
                                                chartData={this.props.filterRes.break_movement_strike_zone}
                                                setScatterAxis={this.props.setScatterAxis}
                                                onResetScatterFilter={this.props.applyFilter}
                                                oldData={this.props.oldData}
                                                cameraAngle={["Strike Zone", "Catcher", "Home Top", "Home 3B Side", "Home 1B Side", "Press Box"]}
                                                visualType="Pitching"
                                                eventProfileData={this.props.eventProfileData}
                                                sendReq={this.props?.sendReq}
                                                tabslist={this.props?.tabslist}
                                                profilepage={true}
                                                isNotDownloadImgLoader={this.props.isNotDownloadImgLoader}
                                                isDownloadImgLoader={this.props.isDownloadImgLoader}
                                                pitchrIframeFlag={this.props.pitchrIframeFlag}
                                                closePitchrIframe={this.props.closePitchrIframe} />
                                        }
                                    </div>
                                    <div className="w-33">
                                        {
                                            this.props.filterRes &&
                                            <ReleasePointChart
                                                chartData={this.props.filterRes.break_movement_release_points}
                                                cameraAngle={["Pitcher", "Mound Top", "Mound 1B Side", "Mound 3B Side", "First Base", "Third Base"]}
                                                visualType="Release"
                                                eventProfileData={this.props.eventProfileData}
                                                sendReq={this.props?.sendReq}
                                                tabslist={this.props?.tabslist}
                                                profilepage={true}
                                                isNotDownloadImgLoader={this.props.isNotDownloadImgLoader}
                                                isDownloadImgLoader={this.props.isDownloadImgLoader}
                                                pitchrIframeFlag={this.props.pitchrIframeFlag}
                                                closePitchrIframe={this.props.closePitchrIframe} />
                                        }
                                    </div>
                                    <div className="w-33">
                                        {
                                            this.props.filterRes &&
                                            <SideReleaseChart
                                                chartData={this.props.filterRes.break_movement_side_release}
                                                cameraAngle={["Mound 3B Side", "Mound 1B Side", "Mound Top", "Pitcher", "Third Base", "First Base"]}
                                                visualType="Release"
                                                eventProfileData={this.props.eventProfileData}
                                                sendReq={this.props?.sendReq}
                                                tabslist={this.props?.tabslist}
                                                profilepage={true}
                                                isNotDownloadImgLoader={this.props.isNotDownloadImgLoader}
                                                isDownloadImgLoader={this.props.isDownloadImgLoader}
                                                pitchrIframeFlag={this.props.pitchrIframeFlag}
                                                closePitchrIframe={this.props.closePitchrIframe} />
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive-xl">
                                <table className="table tabledashed">
                                    <thead>
                                        <tr>
                                            <th colSpan="7" className="one-child text-center">Release Point</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="tablerowgray fw-500">Pitch Type</td>
                                            <td className="tablerowgray fw-500">Dispersion</td>
                                            <td className="tablerowgray fw-500">Avg Rel Ht</td>
                                            <td className="tablerowgray fw-500">Avg Rel Side</td>
                                            <td className="tablerowgray fw-500">Avg Ext</td>
                                            <td className="tablerowgray fw-500">Avg V Rel Angle</td>
                                            <td className="tablerowgray fw-500">Avg H Rel Angle</td>
                                        </tr>
                                        {this.renderTable()}
                                        {
                                            this.props.filterRes &&
                                            this.props.filterRes.release_point_table_data &&
                                            Object.entries(this.props.filterRes.release_point_table_data).length === 0 &&
                                            <tr>
                                                <td colSpan="7" className="text-center table-rowwhite">There's is no Info yet!</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default releasepoint;
