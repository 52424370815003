import React, { useState, useEffect } from 'react'
import { constant } from '../../shared/constant';
import { findMaxAxisValue } from '../../shared/function';
import BreakMovementChart from '../../view/pitcherssummary/component/pitcherBreackChart';
import BallrWebGL from '../../view/webGL/BallrWebGL';
import domtoimage from 'dom-to-image';
import PrintImagePopup from '../../shared/PrintImagePopup';
import { downloadChartPrintImg } from '../../shared/printReportUtils';

function BreakMovementFormChart({ id, form, className, bubbleChartData, dotChartData, top, bottom, ballrIframeFlag, closeBallrIframe, eventProfileData, sendReq, tabslist, profilepage, eventName, pitcherDefault, isNotDownloadImgLoader, isDownloadImgLoader }) {
  const [isChartImg, setIsChartImg] = useState(false);
  const [clickFlag, setClickFlag] = useState(false);
  const [breckM, setBreckM] = useState({
    chartHeight: 421,
    width: "100%",
  })
  const [chartType, setChartType] = useState("Pitches")
  const [ballrFlag, setBallrFlag] = useState(false);
  const [tooltipData, setTooltipData] = useState(null);

  const maxXAxisValue = findMaxAxisValue(bubbleChartData);
  const xAxisDomain = constant.softball ? [-15, 15] : [-maxXAxisValue, maxXAxisValue];
  const yAxisDomainLong = constant.softball ? [-100, 0] : [-80, 0];
  const yAxisDomainShort = constant.softball ? [-15, 15] : [-30, 30];

  const maxXAxisValue2 = findMaxAxisValue(dotChartData);
  const xAxisDomain2 = constant.softball ? [-15, 15] : [-maxXAxisValue2, maxXAxisValue2];

  useEffect(() => {
    if (bubbleChartData || dotChartData) {
      const chartWidth =
        typeof breckM.width === 'string'
          ? document.getElementById(id).offsetWidth
          : breckM.width;
      // increment();
      if (bubbleChartData.length !== 0 || dotChartData.length !== 0) {
        {
          chartType && chartType === 'Clusters' && (
            BreakMovementChart(
              id,
              breckM.chartHeight,
              chartWidth,
              xAxisDomain,
              form && form === 'Short-Form' ? yAxisDomainShort : yAxisDomainLong,
              bubbleChartData,
              maxXAxisValue,
              "H Break",
              form && form === 'Short-Form' ? "Induced V Break" : "Vert Break",
              form,
              top,
              bottom,
              "Clusters",
              tooltipData,
              setTooltipData,
              setBallrFlag,
              ballrFlag
            )
          )
        }

        {
          chartType && chartType === 'Pitches' && (
            BreakMovementChart(
              id,
              breckM.chartHeight,
              chartWidth,
              xAxisDomain2,
              form && form === 'Short-Form' ? yAxisDomainShort : yAxisDomainLong,
              dotChartData,
              maxXAxisValue2,
              "H Break",
              form && form === 'Short-Form' ? "Induced V Break" : "Vert Break",
              form,
              top,
              bottom,
              "Pitches",
              tooltipData,
              setTooltipData,
              setBallrFlag,
              ballrFlag
            )
          )
        }
        // setTimeout(() => {
        //   decrement();
        // }, 1000);
      } else {
        {
          chartType && chartType === 'Clusters' && (
            BreakMovementChart(
              id,
              breckM.chartHeight,
              chartWidth,
              xAxisDomain,
              form && form === 'Short-Form' ? yAxisDomainShort : yAxisDomainLong,
              [],
              maxXAxisValue,
              "H Break",
              form && form === 'Short-Form' ? "Induced V Break" : "Vert Break",
              form,
              top,
              bottom,
              "Clusters",
              tooltipData,
              setTooltipData,
              setBallrFlag,
              ballrFlag
            )
          )
        }

        {
          chartType && chartType === 'Pitches' && (
            BreakMovementChart(
              id,
              breckM.chartHeight,
              chartWidth,
              xAxisDomain2,
              form && form === 'Short-Form' ? yAxisDomainShort : yAxisDomainLong,
              [],
              maxXAxisValue2,
              "H Break",
              form && form === 'Short-Form' ? "Induced V Break" : "Vert Break",
              form,
              top,
              bottom,
              "Pitches",
              tooltipData,
              setTooltipData,
              setBallrFlag,
              ballrFlag
            )
          )
        }
        // setTimeout(() => {
        //   decrement();
        // }, 1000);
      }
    }
  }, [bubbleChartData, dotChartData, chartType]);

  useEffect(() => {
    if (ballrIframeFlag === "yes") {
      setBallrFlag(false)
      setTooltipData(null);
      closeBallrIframe();
    }
  }, [ballrIframeFlag])

  const onChangeChartType = (value) => {
    setChartType(value)
    setBallrFlag(false)
    setTooltipData(null);
  }

  const chartImage = (id) => {
    isDownloadImgLoader();
    setClickFlag(true)
    if (document.getElementById(`img${id}`)) {
      document.getElementById(`img${id}`).remove()
    }
    setIsChartImg(true)
    downloadChartPrintImg(id, setIsChartImg, setClickFlag, isNotDownloadImgLoader)
  }

  return (
    <>
      <div className={`ground-chart single-select ${ballrFlag ? "chart-ifream" : ""}`}>
        <div className="legends graph-select text-left">
          {/* <div className="text-left d-inline">
            <h3 className="mb-0">{form !== 'Short-Form' ? "Long Form Movement" : "Short Form Movement"}</h3>
          </div> */}
          <div className="select-box">
            <select
              name="play_result"
              className="custom-select"
              value={chartType}
              onChange={(e) => onChangeChartType(e.target.value)}
            >
              <option value="Clusters">Clusters</option>
              <option value="Pitches">Pitches</option>
            </select>
          </div>
        </div>
        <div className="ifream-box">
          {ballrFlag && <BallrWebGL tooltipData={tooltipData} id={id} index={tooltipData?._id} />}
        </div>
        <div id={id} className={className} />
        {/* <button className="btn blue btn-view" onClick={() => chartImage(id)} disabled={clickFlag} >Download</button> */}
        <div class="chartdownload" onClick={() => chartImage(id)} disabled={clickFlag}><a class="btn white"><i class="fa fa-download" aria-hidden="true"></i></a></div>
      </div>

      {/* chart visual image download */}
      {isChartImg && <PrintImagePopup
        eventProfileData={eventProfileData}
        id={id}
        sendReq={sendReq}
        tabslist={tabslist}
        eventName={eventName}
        pitcherDefault={pitcherDefault}
        profilepage={profilepage} />}
    </>
  )
}

export default BreakMovementFormChart
