// lib
import React from 'react';
import propTypes from 'prop-types';
import { constant } from "../../shared/constant";

const AntdPercentageColumn = props => {
  const { cellValue, percentage } = props;
  return (
    <span>
      {percentage && cellValue !== constant.LONG_HYPHEN ? `${cellValue}%` : cellValue}
    </span>
  );
};

AntdPercentageColumn.defaultProps = {
  percentage: false,
};

AntdPercentageColumn.propTypes = {
  cellValue: propTypes.oneOfType([propTypes.number, propTypes.string])
    .isRequired,
  percentage: propTypes.bool,
};

export default AntdPercentageColumn;
