import React, { Component } from 'react';
import { imagesArr } from "../../../assets/images";

class heroBanner extends Component {
    render() {
        const { teamInfo } = this.props;
        return (
            <div className="teambanner">
                <div className="container-fluid">
                    <div className="logo">
                        <img
                            src={
                                teamInfo.logo
                                    ? teamInfo.logo
                                    : imagesArr.defaultTeamLogo
                            }
                            alt="major_logo"
                        />
                    </div>
                    <h1>{teamInfo.name}</h1>
                </div>
            </div>
        );
    }
}

export default heroBanner;
