import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";

class footer extends Component {
    render() {
        return (
            <>
            <footer>
                <div className="d-flex">
                    <ul className="social-icon same_size">
                        <li><Link><i className="fab fa-facebook-f"></i></Link></li>
                        <li><Link><i className="fab fa-twitter"></i></Link></li>
                        <li><Link><i className="fab fa-instagram"></i></Link></li>
                    </ul>
                    <Link className="same_size">©2020BaseballCloud</Link>
                    <div className="footer_url same_size">
                        <Link>Terms of Service </Link>
                        <Link>Privacy Policy</Link>
                    </div>
                </div>
            </footer>
            </>
        )
    }
}

export default withRouter(footer);