export const NoInfo = () => {
    return (
        <div className="show-dashboard no-deta">
            <div>
            <h1>Choose a Profile</h1>
            <p>Select a Profile from the list to view their Player Development. This feature provides the ability to compare data across multiple games and practices.</p>
            </div>
            
        </div>
    )
}

export const NoEvent = () => {
    return (
        <div>
            <p className="text-center mt-15">There's no info yet!</p>
        </div>
    )
}

export const NoGraph = () => {
    return (
        <div className="no-graph">
            There's is no Info yet!
        </div>
    )
}