import React, { useEffect, useRef, useState } from "react";
import Highcharts from "highcharts";
import {
  heatMapDataXformer,
} from "../../../../shared/function";
import { constant } from "../../../../shared/constant";
import domtoimage from 'dom-to-image';
import PrintImagePopup from "../../../../shared/PrintImagePopup";
import { downloadChartPrintImg } from "../../../../shared/printReportUtils";

let colorAxisMin = constant.softball ? 25 : 60;
let colorAxisMax = constant.softball ? 85 : 105;
let colorAxisTickInterval = constant.softball ? 5 : 10;
let colorAxisStops;
let dataLabelsFormatter;
let heatMapChartData;
function HeatMapChart({ chartData,id, heat_map, onChangeEvent, eventProfileData, sendReq, tabslist, profilepage, isNotDownloadImgLoader, isDownloadImgLoader}) {
  const [isChartImg, setIsChartImg] = useState(false);
  const [clickFlag, setClickFlag] = useState(false);
  const [state, setState] = useState({
    chart: {
      type: 'heatmap',
      marginTop: 52,
      spacingTop: 20,
      spacingBottom: 20,
      spacingLeft: 20,
    },
    credits: {
      enabled: false
    },
    title: {
      text: '',
    },
    xAxis: {
      categories: [""],
      labels: {
        enabled: false,
      },
    },
    yAxis: {
      categories: [""],
      labels: {
        enabled: false,
      },
      title: null,
      reversed: false
    },
    plotOptions: {
      series: {
        states: {
          inactive: {
            opacity: 1
          },
          hover: {
            enabled: false,
          },
        },
      },
    },
    colorAxis: {
      min: constant.softball ? 25 : 60,
      max: constant.softball ? 85 : 105,
      startOnTick: true,
      tickInterval: constant.softball ? 5 : 10,
      stops: [
        [0, '#1707C7'],
        [0.3, '#8686FD'],
        [0.6, '#FF8465'],
        [0.9, '#850101'],
      ]
    },
    legend: {
      align: 'right',
      layout: 'vertical',
      margin: 0,
      verticalAlign: 'top',
      y: 25,
      symbolHeight: 280,
    },
    tooltip: {
      enabled: true,
    },
    series: [],
    responsive: {
      rules: [{
        condition: {
          maxWidth: 500
        },
      }]
    }
  });

  useEffect(() => {
    if (chartData) {
      if (chartData.length !== 0) {
         heatMapChartData = heatMapDataXformer(chartData,heat_map);
        if (heat_map) {
          if (['Hard Hit', 'Damage', 'GB', 'LD', 'FB','PU','Batted Balls','Swing','Hard Hit #', 'Damage #', 'Batted Ball #', 'Swing %', 'Swing #'].includes(heat_map)) {
            const heatmapfilterdata = heatMapChartData.filter(h => h[2] != null)
            colorAxisMin = Math.round(Math.min(...heatmapfilterdata.map(h => h[2])))
            colorAxisMax = Math.round(Math.max(...heatmapfilterdata.map(h => h[2])))
            colorAxisTickInterval = 0
            colorAxisStops = constant.colorAxisStops2;
            dataLabelsFormatter = function formatter() {
              if (heat_map === 'Damage' || heat_map === 'Whiff' || heat_map === 'Hard Hit' || heat_map === 'GB'|| heat_map === 'LD' || heat_map === 'FB' || heat_map === 'PU' || heat_map === 'Damage #' || heat_map === 'Hard Hit #' || heat_map === 'Batted Ball #') {
                  if (this.point.value !== null) {
                      const roundedValue = Math.ceil(((this.point.value / 100)) / 0.1) * 0.1;
                      const color = constant.colorFromBlueToRedShades2[roundedValue] ? constant.colorFromBlueToRedShades2[roundedValue] : '#FFF';
                      return '<small style="color: ' + color + '">' + this.point.value ? this.point.value.toString().includes(".") ? (parseFloat(this.point.value).toFixed(1) !== "0.0" ? parseFloat(this.point.value).toFixed(1) : '') : parseFloat(this.point.value) : ' ' + '</small>';
                  }
                  return null;
              } else {  
                  if (this.point.value) {
                      const roundedValue = Math.ceil(((this.point.value / 100)) / 0.1) * 0.1;
                      const color = constant.colorFromBlueToRedShades2[roundedValue] ? constant.colorFromBlueToRedShades2[roundedValue] : '#FFF';
                      return '<small style="color: ' + color + '">' + this.point.value ? this.point.value : ' ' + '</small>';
                  }
                  return null;
              }
          }
          }
          if (['Avg EV'].includes(heat_map)) {
            colorAxisMin = constant.softball ? 25 : 60;
            colorAxisMax = constant.softball ? 85 : 105;
            colorAxisTickInterval = constant.softball ? 5 : 10;
            colorAxisStops = constant.colorAxisStops2;
            dataLabelsFormatter = function formatter() {
              if (this.point.value != 0) {
                const roundedValue = Math.ceil(((this.point.value / 100)) / 0.1) * 0.1;
                const color = constant.colorFromBlueToRedShades2[roundedValue] ? constant.colorFromBlueToRedShades2[roundedValue] : '#FFF';
                return '<small style="color: ' + color + '">' + this.point.value ? this.point.value : ' ' + '</small>';
              }
              return null;
            }
          }
          if (['Avg LA'].includes(heat_map)) {
            dataLabelsFormatter = function formatter() {
              if (this.point.value != 0) {
                return '<small style="color: #000;">' + this.point.value ? this.point.value : ' ' + '</small>';
              }
              return null;
            }
          }
        }

        let seriesData = [{
          name: '',
          borderWidth: 1,
          data: heatMapChartData,
          dataLabels: {
            enabled: true,
            style: {
              // color: '#FFFFFF',
              textOutline: '0px contrast',
              fontSize: '15px',
            },
            formatter: dataLabelsFormatter
          }
        },
        {
          name: 'Heatmap',
          color: 'black',
          type: 'line',
          data: [
            [0.5, 0.5],
            [0.5, 3.5],
            [3.5, 3.5],
            [3.5, 0.5],
            [0.5, 0.5],
          ],
        }]

        let newTooltip = {
          enabled: true,
          formatter: function () {
            if (this.point.series.name === "Heatmap") return false
            var count = chartData[0][`zone${this.point.x}_${this.point.y}_count`];
            return `Batted Balls: ${count ? count : 0}`;
          },
        }
        let colorAxisObj = {
          stops: colorAxisStops,
          min: colorAxisMin,
          max: colorAxisMax,
          tickInterval: colorAxisTickInterval,
          visible: heat_map === "Hard Hit" || heat_map === 'Hard Hit #' || heat_map === 'Damage' || heat_map === 'Damage #' || heat_map === 'Batted Ball #' || heat_map === 'GB'|| heat_map === 'LD' || heat_map === 'FB' || heat_map === 'PU' || heat_map === 'Swing %'|| heat_map === 'Swing #' ? false : true
        }

        state.colorAxis = heat_map === "Avg LA" ? {visible : false, stops: constant.colorAxisStops3 } : colorAxisObj
        state.tooltip = newTooltip;
        state.series = seriesData;

        Highcharts.chart(id, state);
      } else {
        state.series = [{showInLegend: false}];
        Highcharts.chart(id, state);
      }
    }

  }, [chartData]);

  const chartImage = (id) => {
    isDownloadImgLoader();
    setClickFlag(true)
    if (document.getElementById(`img${id}`)) {
      document.getElementById(`img${id}`).remove()
    }
    setIsChartImg(true)
    downloadChartPrintImg(id, setIsChartImg, setClickFlag, isNotDownloadImgLoader)
  }

  return (
    <>
      <div className="ground-chart">
        <div className="legends graph-select text-left">
          <div className="text-left d-inline">
            <h3 className="mb-0">Heat Map</h3>
            <p>Pitcher View</p>
          </div>
          <div className="select-box ">
            <select name={id === "HeatMapChart" ? "heat_map_0" : id === "EVHeatMapChart" ? "heat_map_1" : id === "HardHitHeatMapChart" ? "heat_map_2" : "heat_map_3"} className="custom-select" value={heat_map} onChange={onChangeEvent}>
              {/* <option selected value="" >No selected</option> */}
              <option value="Avg EV">Avg EV</option>
              <option value="Hard Hit">Hard Hit%</option>
              <option value="Hard Hit #">Hard Hit#</option>
              <option value="Damage">Damage%</option>
              <option value="Damage #">Damage#</option>
              <option value="Avg LA">Avg LA</option>
              <option value="Batted Ball #">Batted Balls</option>
              <option value="GB">GB%</option>
              <option value="LD">LD%</option>
              <option value="FB">FB%</option>
              <option value="PU">PU%</option>
              <option value="Swing %">Swing %</option>
              <option value="Swing #">Swing #</option>
            </select>
          </div>
        </div>
        <div className="heatmap">
          <div style={{ height: "423px", width: "382px", margin: "0 auto" }} id={id} />
          {/* <button className="btn blue btn-view" onClick={() => chartImage("HeatMapChart")} disabled={clickFlag} >Download</button> */}
          <div class="chartdownload" onClick={() => chartImage(id)} disabled={clickFlag}><a class="btn white"><i class="fa fa-download" aria-hidden="true"></i></a></div>
        </div>
      </div>

      {/* chart visual image download popup */}
      {isChartImg && <PrintImagePopup
        title="Heat Map"
        eventProfileData={eventProfileData}
        id={id}
        sendReq={sendReq}
        tabslist={tabslist}
        profilepage={profilepage} />}
    </>
  );
}

HeatMapChart.propTypes = {};

export default HeatMapChart;
