import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { imagesArr } from "../../assets/images";

class SidebarListData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: "game summery",
      teamInfo: {
        name: "",
        logo: ""
      }
    };
  }

  componentDidMount() {
    if (localStorage.getItem("teamData") !== null) {
      this.setState({
        teamInfo: {
          name: JSON.parse(localStorage.getItem("teamData")).name,
          // logo: JSON.parse(localStorage.getItem("teamData")).hasOwnProperty('teamLogo') ? JSON.parse(localStorage.getItem("teamData")).teamLogo.filePath : ""
        }
      })
    }
  }

  setPath = (val) => {
    this.setState({ path: val });
    let url = "/" + val;
    this.props.history.push(url);
  };

  render() {
    const { teamInfo } = this.state
    return (
      <>
        {/* sidebar-wrapper */}
        <div >
          {/* <div className="sidebar-logo">
            <Link to="">
              <img src={imagesArr.logo} alt="logo" />
            </Link>
            <p>{teamInfo.name}</p>
          </div> */}
          <ul className="list-group">
            {this.props.data.map((list, i) => {
              return (
                <li key={i}>
                  <Link to={`/profiledashboard/${list._id}`}>
                    {list.fname} {list.lname}
                  </Link>
                </li>
              );
            })}
            {
              this.props.data.length === 0 &&
              <li className="text-center"><a>There's no info yet!</a></li>
            }
          </ul>
        </div>
        {/* sidebar-wrapper */}
      </>
    );
  }
}

export default withRouter(SidebarListData);
