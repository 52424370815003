import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import domtoimage from 'dom-to-image';
import { downloadChartPrintImg } from "../../../../shared/printReportUtils";
import PrintImagePopup from "../../../../shared/PrintImagePopup";

function CalledPitchesChart({ chartData, eventName, profilepage, isNotDownloadImgLoader, isDownloadImgLoader }) {
  const [isChartImg, setIsChartImg] = useState(false);
  const [clickFlag, setClickFlag] = useState(false);
  const [state, setState] = useState({
    chart: {
      type: "scatter",
      // borderWidth: 1,
      marginTop: 65,
      // borderColor: "#D8D8D8",
      // borderRadius: "8px",
    },
    title: {
      text: ""
      // text: "Called Pitches",
      // layout: "horizontal",
      // align: "left",
      // verticalAlign: "left",
      // y: 20,
      // x: 10,
      // style: {
      //   color: "#1B1B1B",
      //   fontFamily: "Poppins, sans-serif",
      //   fontSize: "18px",
      //   fontWeight: "bold",
      // },
    },
    xAxis: {
      title: {
        enabled: true,
        // text: "Side at Plate (ft)",
        text: "",
        style: {
          color: "#777F8F",
          fontFamily: "Poppins, sans-serif",
          fontSize: "14px",
          fontWeight: "500",
        },
      },
      min: -2.5,
      max: 2.5,
      tickInterval: 1,
      allowDecimals: false,
    },
    yAxis: {
      title: {
        enabled: true,
        // text: "Height at Plate (ft)",
        text: "",
        style: {
          color: "#777F8F",
          fontFamily: "Poppins, sans-serif",
          fontSize: "14px",
          fontWeight: "500",
        },
      },
      min: 0,
      max: 5
    },
    legend: {
      layout: "horizontal",
      align: "top",
      verticalAlign: "top",
      // y: 35,
      reversed: true,
    },
    credits: { enabled: false },
    plotOptions: {
      series: {
        stickyTracking: false,
        states: {
          inactive: {
            opacity: 1
          }
        }
      }
    },
    tooltip: {
      formatter: function () {
        if (this.point.series.name === "Strikezone") return false;
        if (this.point.series.name === "Stadium") return false;
        let tooltip = `<span style="color: ${this.point.color}; font-weight:bold;">${this.point.options.pitch_call}</span><br>
            <span>Batter: ${this.point.options.batter ? this.point.options.batter : '-'}</span><br>
            <span>Pitcher: ${this.point.options.pitcher ? this.point.options.pitcher : '-'}</span><br>
            <span>Pitch Number: ${this.point.options.pitch_number ? this.point.options.pitch_number : '-'}</span><br>
            <span>Velocity: ${this.point.options &&
          this.point.options.velocity ?
          this.point.options.velocity.toFixed(1) : '-'
          }</span><br>
            <span>Spin Rate: ${this.point.options &&
          this.point.options.spin_rate ?
          Math.round(this.point.options.spin_rate) : '-'
          }</span><br>
            <span>Ind V Break: ${this.point.options &&
          this.point.options.ind_v_break ?
          this.point.options.ind_v_break.toFixed(1) : '-'
          }</span><br>
            <span>H Break: ${this.point.options &&
          this.point.options.h_break ?
          this.point.options.h_break.toFixed(1) : '-'
          }</span><br>
            <span>Vert Appr Angle: ${this.point.options &&
          this.point.options.vert_appr_angle ?
          this.point.options.vert_appr_angle.toFixed(1) : '-'
          }</span><br>
            <span>Release Height: ${this.point.options &&
          this.point.options.release_height ?
          this.point.options.release_height.toFixed(1) : '-'
          }</span><br>
            <span>Release Side: ${this.point.options &&
          this.point.options.release_side ?
          this.point.options.release_side.toFixed(1) : '-'
          }</span><br>
            <span>Play Result: ${this.point.options.pitch_call ? this.point.options.pitch_call : '-'}</span>
          </span>`;
        return tooltip;
      },
    },
    series: [],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
        },
      ],
    },
  });

  useEffect(() => {
    if (chartData) {
      if (chartData.length !== 0) {
        state.series = chartData;
        Highcharts.chart("calledPitchesChart", state);
      } else {
        state.series = [{showInLegend: false}];
        Highcharts.chart("calledPitchesChart", state);
      }
    }
  }, [chartData]);

  const chartImage = (id) => {
    isDownloadImgLoader();
    setClickFlag(true)
    if (document.getElementById(`img${id}`)) {
      document.getElementById(`img${id}`).remove()
    }
    setIsChartImg(true)
    downloadChartPrintImg(id, setIsChartImg, setClickFlag, isNotDownloadImgLoader)
  }

  return (
    <>
      <div className="ground-chart">
        <div className="legends graph-select text-left">
          <div className="text-left d-inline">
            <h3 className="mb-0">Called Pitches</h3>
          </div>
        </div>
        <div style={{ height: "444px" }} id="calledPitchesChart" />
        {/* <button className="btn blue btn-view" onClick={() => chartImage("calledPitchesChart")} disabled={clickFlag} >Download</button> */}
        <div class="chartdownload" onClick={() => chartImage("calledPitchesChart")} disabled={clickFlag}><a class="btn white"><i class="fa fa-download" aria-hidden="true"></i></a></div>
      </div>

      {/* chart visual image download */}
      {isChartImg && <PrintImagePopup
        title="Called Pitches"
        id="calledPitchesChart"
        eventName={eventName}
        profilepage={profilepage} />}
    </>
  );
}

export default CalledPitchesChart;
