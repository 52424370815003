import { imagesArr } from '../../../assets/images';
import PitcherTable from './pitcherTable';

function pitcherSummaryTable({ print, pitcherList, printAll ,isprintAll }) {
    return(
        <>
        {/* // <div className="main-div"> */}
        <section className="pb-0">
            <div className="container-fluid">
                <div className="common-title mb-0">
                    <h2>Pitcher Summary</h2>
                    {!isprintAll && <div className="dropdown">
                        <span className="printer"><img src={imagesArr.printer} alt="print" /></span>
                        <div className="dropdown-content">
                          <ul>
                            <li onClick={() => print()}>Print</li>
                            <li onClick={() => printAll()}>Print All</li>
                          </ul>
                        </div>
                      </div>}
                </div>
            </div>
        </section>
        <section id="pitcherTablePrintArea">
            <PitcherTable tableData={pitcherList} type="pitcher" />
        </section>
    {/* // </div> */}
    </>
    )
}
export default pitcherSummaryTable