import React from "react";
import { createColoredColumnObjTable } from "../../shared/function";
import { constant } from "../../shared/constant";

function HitterBattedBallTable({ tableData }) {

  const renderList = () => {
    return (tableData && tableData.length !== 0 ?
      tableData.map((bodyData, j) => {
        return (
          <tr key={j}>
            <td className="table-rowwhite">
              {bodyData.pitch_of_plate_appearance ? bodyData.pitch_of_plate_appearance : '—'}
            </td>
            <td className="table-rowwhite">
              {bodyData.pitcher ? bodyData.pitcher : '—'}
            </td>
            <td className="table-rowwhite" style={{ color: constant.pitchTypeColor[bodyData.pitch_type] }}>
              {bodyData.pitch_type ? bodyData.pitch_type : '—'}
            </td>
            <td className="table-rowwhite">
              {bodyData.play_result ? bodyData.play_result : '—'}
            </td>
            <td className="table-rowwhite" style={createColoredColumnObjTable('exit_velocity', tableData, bodyData, constant.softball ? 40 : 60)}>
              {bodyData.exit_velocity ? bodyData.exit_velocity.length !== 0 ? bodyData.exit_velocity.toString().includes(".") ? bodyData.exit_velocity.toFixed(1) : bodyData.exit_velocity : '—' : '—'}
            </td>
            <td className="table-rowwhite">
              {bodyData.launch_angle ? bodyData.launch_angle.length !== 0 ? bodyData.launch_angle.toString().includes(".") ? bodyData.launch_angle.toFixed(1) : bodyData.launch_angle : '—' : '—'}
            </td>
            <td className="table-rowwhite">
              {bodyData.distance ? bodyData.distance.length !== 0 ? Math.round(bodyData.distance) : '—' : '—'}
            </td>
          </tr>
        );
      })
      :
      <tr>
        <td className="table-rowwhite text-center" colSpan="7">There's no info yet!</td>
      </tr>
    )
  }

  return (
    <>
      <div className="container-fluid">
        <h3 className="table-title">Batted Balls</h3>
        <div className="homebatters-table">
          <div className="table-responsive-xl">
            <table className="table tabledashed">
              <thead>
                <tr>
                  <th className="tablerowblue fw-500">Pitch of PA</th>
                  <th className="tablerowblue fw-500">Pitcher</th>
                  <th className="tablerowblue fw-500">Pitch Type</th>
                  <th className="tablerowblue fw-500">Result</th>
                  <th className="tablerowblue fw-500">EV</th>
                  <th className="tablerowblue fw-500">LA</th>
                  <th className="tablerowblue fw-500">Distance</th>
                  {/* <th className="tablerowblue fw-500">xBA</th> */}
                </tr>
              </thead>
              <tbody>
                {renderList()}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default HitterBattedBallTable;
