import React from "react";
import { Link } from "react-router-dom";
import { stringValueSort, getSortedValues } from "../../shared/function";
import { Table } from "antd";
import PrintTable from "./common/printTable";

function HittersBlastmotion({ tableData, isPrinting }) {
    var allRows = [];
    tableData &&
        tableData.map((list, i) =>
            allRows.push({
                key: i,
                HittersName: list.batter_name,
                name: { batterName: list?.batter_name, batterId: list?._id },
                bats: list?.bats || "—",
                avg_exit_velo: list.avg_exit_velo && list.avg_exit_velo ? Number(list.avg_exit_velo.toFixed(1)) : "—",
                peak_exit_velo: list.peak_exit_velo && list.peak_exit_velo ? Number(list.peak_exit_velo.toFixed(1)) : "—",
                bat_speed: list.bat_speed && list.bat_speed ? Number(list.bat_speed.toFixed(1)) : "—",
                rotational_acceleration: list.rotational_acceleration && list.rotational_acceleration ? Number(list.rotational_acceleration.toFixed(1)) : "—",
                on_plane_efficiency: list.on_plane_efficiency && list.on_plane_efficiency ? Math.round(list.on_plane_efficiency) : "—",
                attack_angle: list.attack_angle && list.attack_angle ? Math.round(list.attack_angle) : "—",
                early_connection: list.early_connection && list.early_connection ? Math.round(list.early_connection) : "—",
                connection_at_impact: list.connection_at_impact && list.connection_at_impact ? Math.round(list.connection_at_impact) : "—",
                vertical_bat_angle: list.vertical_bat_angle && list.vertical_bat_angle ? Math.round(list.vertical_bat_angle) : "—",
                power: list.power && list.power ? Number(list.power.toFixed(2)) : "—",
                time_to_contact: list.time_to_contact && list.time_to_contact ? Number(list.time_to_contact.toFixed(2)) : "—",
                peak_hand_speed: list.peak_hand_speed && list.peak_hand_speed ? Number(list.peak_hand_speed.toFixed(1)) : "—",
            })
        );

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            sorter: (a, b) => stringValueSort(a["name"].batterName, b["name"].batterName),
            sortDirections: ['descend', 'ascend'],
            fixed: isPrinting ? '' :'left',
            render: (text, record) => {
                return {
                    props: {
                        style: { background: record.HittersName === "Team" ? "LightGray" : "" }
                    },
                    children: <>
                        {record.HittersName === "Team" ?
                            <div>{text.batterName}</div> :
                            <Link to={record.HittersName === "Team" ? "" : `/profiledashboard/${text.batterId}`}>
                                {/* <img src={record.batterProfile} alt="icon" className="d-inline icon" /> */}
                                {text.batterName}
                            </Link>
                        }
                    </>
                }
            }
        },
        {
            title: "Bats",
            dataIndex: "bats",
            sorter: (a, b) => stringValueSort(a.bats, b.bats),
            sortDirections: ['descend', 'ascend'],
            render(text, record) {
                return {
                    props: {
                        style: { background: record.HittersName === "Team" ? "LightGray" : "" }
                    },
                    children: <div>{text}</div>
                };
            }
        },
        {
            title: "Avg EV",
            dataIndex: "avg_exit_velo",
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "avg_exit_velo"),
            sortDirections: ['descend', 'ascend'],
            render(text, record) {
                return {
                    props: {
                        style: { background: record.HittersName === "Team" ? "LightGray" : "" }
                    },
                    children: <div>{text}</div>
                };
            }
        },
        {
            title: "Peak EV",
            dataIndex: "peak_exit_velo",
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "peak_exit_velo"),
            sortDirections: ['descend', 'ascend'],
            render(text, record) {
                return {
                    props: {
                        style: { background: record.HittersName === "Team" ? "LightGray" : "" }
                    },
                    children: <div>{text}</div>
                };
            }
        },
        {
            title: "Bat Speed",
            dataIndex: "bat_speed",
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "bat_speed"),
            sortDirections: ['descend', 'ascend'],
            render(text, record) {
                return {
                    props: {
                        style: { background: record.HittersName === "Team" ? "LightGray" : "" }
                    },
                    children: <div>{text}</div>
                };
            }
        },
        {
            title: "Rotational Acceleration",
            dataIndex: "rotational_acceleration",
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "rotational_acceleration"),
            sortDirections: ['descend', 'ascend'],
            render(text, record) {
                return {
                    props: {
                        style: { background: record.HittersName === "Team" ? "LightGray" : "" }
                    },
                    children: <div>{text}</div>
                };
            }
        },
        {
            title: "On Plane Efficiency",
            dataIndex: "on_plane_efficiency",
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "on_plane_efficiency"),
            sortDirections: ['descend', 'ascend'],
            render(text, record) {
                return {
                    props: {
                        style: { background: record.HittersName === "Team" ? "LightGray" : "" }
                    },
                    children: <div>{text}</div>
                };
            }
        },
        {
            title: "Attack Angle",
            dataIndex: "attack_angle",
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "attack_angle"),
            sortDirections: ['descend', 'ascend'],
            render(text, record) {
                return {
                    props: {
                        style: { background: record.HittersName === "Team" ? "LightGray" : "" }
                    },
                    children: <div>{text}</div>
                };
            }
        },
        {
            title: "Early Connection",
            dataIndex: "early_connection",
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "early_connection"),
            sortDirections: ['descend', 'ascend'],
            render(text, record) {
                return {
                    props: {
                        style: { background: record.HittersName === "Team" ? "LightGray" : "" }
                    },
                    children: <div>{text}</div>
                };
            }
        },
        {
            title: "Connection at Impact",
            dataIndex: "connection_at_impact",
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "connection_at_impact"),
            sortDirections: ['descend', 'ascend'],
            render(text, record) {
                return {
                    props: {
                        style: { background: record.HittersName === "Team" ? "LightGray" : "" }
                    },
                    children: <div>{text}</div>
                };
            }
        },
        {
            title: "Vertical Bat Angle",
            dataIndex: "vertical_bat_angle",
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "vertical_bat_angle"),
            sortDirections: ['descend', 'ascend'],
            render(text, record) {
                return {
                    props: {
                        style: { background: record.HittersName === "Team" ? "LightGray" : "" }
                    },
                    children: <div>{text}</div>
                };
            }
        },
        {
            title: "Power",
            dataIndex: "power",
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "power"),
            sortDirections: ['descend', 'ascend'],
            render(text, record) {
                return {
                    props: {
                        style: { background: record.HittersName === "Team" ? "LightGray" : "" }
                    },
                    children: <div>{text}</div>
                };
            }
        },
        {
            title: "Time to Contact",
            dataIndex: "time_to_contact",
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "time_to_contact"),
            sortDirections: ['descend', 'ascend'],
            render(text, record) {
                return {
                    props: {
                        style: { background: record.HittersName === "Team" ? "LightGray" : "" }
                    },
                    children: <div>{text}</div>
                };
            }
        },
        {
            title: "Peak Hand Speed",
            dataIndex: "peak_hand_speed",
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "peak_hand_speed"),
            sortDirections: ['descend', 'ascend'],
            render(text, record) {
                return {
                    props: {
                        style: { background: record.HittersName === "Team" ? "LightGray" : "" }
                    },
                    children: <div>{text}</div>
                };
            }
        }
    ]

    const data = allRows;

    return (
        <>
            {(isPrinting && data.length > 10) ? <PrintTable columns={columns} dataSource={data} /> :
                <div>
                    {isPrinting ?
                        <div className="print--table--wrp">
                            <Table
                                className={"table with-border"}
                                columns={columns}
                                dataSource={data}
                                pagination={false}
                            />
                        </div>
                         :
                        <div className="table-responsive-xl">
                            <table className="table with-border">
                                <div className="custom-header-fixed">
                                    <Table
                                        columns={columns}
                                        dataSource={data}
                                        pagination={false}
                                    />
                                </div>
                            </table>
                        </div>}
                </div>}
        </>
    );
}

export default HittersBlastmotion;