import Cookies from "js-cookie";
import { imagesArr } from "../assets/images";

let API_URL = "";
let gametype =  Cookies.get('gameType', { domain: process.env.REACT_APP_COOKIE_DOMAIN });
export const constant = {
  API_URL: process.env.REACT_APP_END_POINT, // http://192.168.0.123:3019/api
  token: "4e5613a8215d6f50d4fbb9e351d2c56f",
  softball: gametype === "2" ? true : false,
  // date format
  FORMAT_FULL_DATE: "dddd, MMMM D, YYYY",
  FORMAT_DATE: "DD/MM/YYYY",
  FORMAT_TIME: "hh:mm A",
  FORMAT_DATE_TIME: "DD/MM/YYYY hh:mm A",
  FORMAT_DAY_TIME: "dddd hh:mm A",
  noValue: '-',
  colors: {
    Fastballs: '#FE0707',
    Breaking: '#07D2FE',
    Offspeed: '#03CD30',
    'Drop Curve': '#07FEEF',
    Fastball: gametype === "2" ? "#013662" : '#FE0707',
    Sinker: '#FF9900',
    Changeup: '#03CD30',
    ChangeUp: '#03CD30',
    Splitter: '#5DD89C',
    Curveball: '#07D2FE',
    Slider: '#F1C232',
    Cutter: '#783F04',
    Knuckleball: '#000000',
    'Rise Ball': '#FF9900',
    Riseball: '#FF9900',
    'Drop Ball': '#FE0707',
    Dropball: '#FE0707',
    'Screw Ball': '#DE6DFC',
    'Foul Ball': '#00FF3B',
    FoulBall: '#00FF3B',
    Foul:'#00FF3B',
    Triple: '#FE9B07',
    InPlay: '#6A9CAF',
    'Home Run': '#4CBB17',
    Walk: '#49A9F5',
    HitByPitch: '#F7CCE1',
    CatchersInterference: '#A905C6',
    'Hit by Pitch': '#76E5FC',
    Single: '#FE0707',
    Double: '#0B99E6',
    'Called Strikeout': '#F8E8D2',
    'Callled Strike': '#E8C547',
    'Called Strike': '#ff0000',
    'Swinging Strikeout': '#F6E79B',
    Ball: '#280af8',
    BallCalled: '#6D0034',
    BallIntentional: '#7A0404',
    'Swinging Strike': '#7FC7FF',
    'Strike Swinging': '#C9D5DB',
    StrikeCalled: '#EFD22B',
    StrikeSwinging: '#042B7A',
    'Intentional Ball': '#CE8A77',
    Out: '#B4B4B4',
    FieldersChoice: '#FFFFFF',
    Sacrifice: '#444444',
    Other: '#E367AF',
    Error: '#F8AAFF',
    None: '#808080',
    nil: '#FAE3B3',
    Undefined: '#6A0E2D',
    HomeRun: '#4CBB17',
    undefined: '#808080',
    null: '#808080',
    'Contact Out': 'blue',
    BP: '#808080',
    gb: '#b4b4b4',
    ld: '#fe0707',
    fb: '#031cff',
    pu: '#f8aaff'
  },
  pitchTypeColor: {
    Fastballs: '#FE0707',
    Breaking: '#07D2FE',
    Offspeed: '#03CD30',
    'Drop Curve': '#07FEEF',
    Fastball: gametype === "2" ? "#013662" : "#FE0707",
    Sinker: "#FF9900",
    Changeup: "#03CD30",
    ChangeUp: '#03CD30',
    Splitter: "#5DD89C",
    Curveball: "#07D2FE",
    Slider: "#F1C232",
    Cutter: "#783F04",
    Knuckleball: "#000000",
    "Rise Ball": "#FF9900",
    Riseball: "#FF9900",
    "Drop Ball": "#FE0707",
    Dropball: '#FE0707',
    "Screw Ball": "#DE6DFC",
    'Foul Ball': '#00FF3B',
    FoulBall: '#00FF3B',
    Foul:'#00FF3B',
    Triple: '#FE9B07',
    InPlay: '#6A9CAF',
    'Home Run': '#4CBB17',
    Walk: '#49A9F5',
    HitByPitch: '#F7CCE1',
    CatchersInterference: '#A905C6',
    'Hit by Pitch': '#76E5FC',
    Single: '#FE0707',
    Double: '#0B99E6',
    'Called Strikeout': '#F8E8D2',
    'Callled Strike': '#E8C547',
    'Called Strike': '#ff0000',
    'Swinging Strikeout': '#F6E79B',
    Ball: '#280af8',
    BallCalled: '#6D0034',
    BallIntentional: '#7A0404',
    'Swinging Strike': '#7FC7FF',
    'Strike Swinging': '#C9D5DB',
    StrikeCalled: '#EFD22B',
    StrikeSwinging: '#042B7A',
    'Intentional Ball': '#CE8A77',
    Out: '#B4B4B4',
    FieldersChoice: '#FFFFFF',
    Sacrifice: '#444444',
    Other: '#E367AF',
    Error: '#F8AAFF',
    None: '#808080',
    nil: '#FAE3B3',
    Undefined: '#6A0E2D',
    HomeRun: '#4CBB17',
    undefined: '#808080',
    'Contact Out': 'blue',
    // Undefined: 'rgba(128,128,128,1)',
    BP: '#808080',
    gb: '#b4b4b4',
    ld: '#fe0707',
    fb: '#031cff',
    pu: '#f8aaff'
  },
  pitchCallColor: {
    Fastballs: '#FE0707',
    Breaking: '#07D2FE',
    Offspeed: '#03CD30',
    'Drop Curve': '#07FEEF',
    Fastball: gametype === "2" ? "#013662" : "#FE0707",
    Sinker: "#FF9900",
    Changeup: "#03CD30",
    ChangeUp: '#03CD30',
    Splitter: "#5DD89C",
    Curveball: "#07D2FE",
    Slider: "#F1C232",
    Cutter: "#783F04",
    Knuckleball: "#000000",
    "Rise Ball": "#FF9900",
    Riseball: "#FF9900",
    "Drop Ball": "#FE0707",
    Dropball: '#FE0707',
    "Screw Ball": "#DE6DFC",
    'Foul Ball': '#666666',
    FoulBall: '#666666',
    Foul:'#666666',
    Triple: '#FE9B07',
    InPlay: '#6A9CAF',
    'Home Run': '#52F903',
    Walk: '#49A9F5',
    HitByPitch: '#F7CCE1',
    CatchersInterference: '#A905C6',
    'Hit by Pitch': '#76E5FC',
    Single: '#FE0707',
    Double: '#0B99E6',
    'Called Strikeout': '#F8E8D2',
    'Callled Strike': '#E8C547',
    'Called Strike': '#ff0000',
    'Swinging Strikeout': '#F6E79B',
    Ball: '#280af8',
    BallCalled: '#6D0034',
    BallIntentional: '#7A0404',
    'Swinging Strike': '#7FC7FF',
    'Strike Swinging': '#C9D5DB',
    StrikeCalled: '#EFD22B',
    StrikeSwinging: '#042B7A',
    'Intentional Ball': '#CE8A77',
    Out: '#C4C4C4',
    FieldersChoice: '#FFFFFF',
    Sacrifice: '#444444',
    Other: '#E367AF',
    Error: '#FDBDFE',
    None: '#808080',
    nil: '#FAE3B3',
    Undefined: '#6A0E2D',
    HomeRun: '#52F903',
    undefined: '#808080',
    'Contact Out': 'blue',
    // Undefined: 'rgba(128,128,128,1)',
    BP: '#808080',
    gb: '#b4b4b4',
    ld: '#fe0707',
    fb: '#031cff',
    pu: '#f8aaff'
  },
  pitchTypeAbbrevations: {
    'Drop Curve': 'DC',
    'First Pitch': '1P',
    'After Drop Ball': 'ADB',
    'After Dropball': 'ADB',
    'After Changeup': 'ACH',
    'After Curveball': 'ACB',
    'After Fastball': 'AFB',
    'After Undefined': 'AU',
    'After ': 'AU',
    'After Knuckleball': 'AKN',
    'After Cutter': 'ACT',
    'After Other': 'AO',
    'After Rise Ball': 'ARB',
    'After Riseball': 'ARB',
    'After Sinker': 'ASI',
    'After Four Seam': 'AFF',
    'After Screw Ball': 'ASB',
    'After Slider': 'ASL',
    'After Splitter': 'ASP',
    'After Two Seam': 'A2F',
    'After Four Seam Fastball': 'A4F',
    'After Drop Curve': 'ADC',
    'Fastball': 'FB',
    'Sinker': 'SI',
    'Curveball': 'CB',
    'Slider': 'SL',
    'Changeup': 'CH',
    'Splitter': 'SP',
    'Cutter': 'CT',
    'Knuckleball': 'KN',
    'Other':'Other',
    'Rise Ball': 'RB',
    'Riseball': 'RB',
    'Drop Ball': 'DB',
    'Dropball': 'DB',
    'Screw Ball': 'SB',
    'Undefined': 'UD',
    'undefined': 'UD',
    'Fastballs': 'Fastballs',
    'Breaking': 'Breaking',
    'Offspeed': 'Offspeed',
    'OT': 'OT',
    'BP': 'BP',
    'gb': 'GB',
    'ld': 'LD',
    'fb': 'FB',
    'pu': 'PU',
    'Even': 'Even',
    'Full': 'Full',
    'Ahead': 'Ahead',
    'Behind': 'Behind',
    'After Ball': 'AB',
    'After Called Strike': 'ACS',
    'After Whiff': 'AW',
    'After Foul': 'AF',
    'After Fastballs': 'AFB',
    'After Breaking': 'ABR',
    'After Offspeed': 'AOFF',
    // pitch call or play result
    'Foul': 'Foul',
    'Foul Ball': 'Foul Ball',
    'FoulBall': 'FoulBall',
    'Single': 'Single',
    'Double': 'Double',
    'Triple': 'Triple',
    'Home Run': 'Home Run',
    'HomeRun': 'HomeRun',
    'Out': 'Out',
    'Error': 'Error',
    'Sacrifice': 'Sacrifice',
    'Contact Out': 'Contact Out',
    'Ball': 'Ball',
    'HitByPitch': 'HitByPitch',
    'Hit By Pitch': 'Hit By Pitch',
    'Swinging Strike': 'Swinging Strike',
    'BallCalled': 'BallCalled',
    'StrikeSwinging': 'StrikeSwinging',
    'Strike Swinging': 'Strike Swinging',
    'Called Strike': 'Called Strike',
    'StrikeCalled': 'StrikeCalled',
    'Swinging Strikeout': 'Swinging Strikeout',
    'FieldersChoice': 'FieldersChoice',
    'Called Strikeout': 'Called Strikeout'
  },
  PlayResultSymbol: {
    Undefined: "diamond",
    Ball: `url(${imagesArr.Ball})`,
    'Called Strike': `url(${imagesArr.CalledStrike})`,
    'Swinging Strike': `url(${imagesArr.CalledStrike})`,
    'Foul Ball': "triangle",
    Single: `url(${imagesArr.Quarterfilledcircle})`,
    Double: `url(${imagesArr.HalffilledCircle})`,
    Triple: `url(${imagesArr.triple})`,
    'Home Run': 'circle',
    Out: `url(${imagesArr.Sideways})`
  },
  // color code
  LONG_HYPHEN: '—',
  blueToRedShades: [
    '#555EF7',
    '#666EF8',
    '#777EF9',
    '#888EF9',
    '#999EFA',
    '#AAAEFB',
    '#BBBEFC',
    '#CCCEFC',
    '#DDDEFD',
    '#EEEEFE',
    '#F7F8F9',
    '#FFEDED',
    '#FFDCDC',
    '#FFCACA',
    '#FFC4C4',
    '#FFB9B9',
    '#FFA9A9',
    '#FFA0A0',
    '#FF9090',
    '#FF8080',
    '#FF7070',
    '#FF6060',
    '#FF5050',
    '#FF4848',
    '#FF4040',
  ],
  blueToRedToBlueShades: [
    //blue
    '#555EF7',
    '#666EF8',
    '#777EF9',
    '#888EF9',
    '#999EFA',
    '#AAAEFB',
    '#BBBEFC',
    '#CCCEFC',
    '#DDDEFD',
    '#EEEEFE',
    '#F7F8F9',
    //red
    '#FFEDED',
    '#FFDCDC',
    '#FFCACA',
    '#FFC4C4',
    '#FFB9B9',
    '#FFA9A9',
    '#FFA0A0',
    '#FF9090',
    '#FF8080',
    '#FF7070',
    '#FF6060',
    '#FF5050',
    '#FF4848',
    '#FF4040',
    '#FF4848',
    '#FF5050',
    '#FF6060',
    '#FF7070',
    '#FF8080',
    '#FF9090',
    '#FFA0A0',
    '#FFA9A9',
    '#FFB9B9',
    '#FFC4C4',
    '#FFCACA',
    '#FFDCDC',
    '#FFEDED',
    //blue
    '#F7F8F9',
    '#EEEEFE',
    '#DDDEFD',
    '#CCCEFC',
    '#BBBEFC',
    '#AAAEFB',
    '#999EFA',
    '#888EF9',
    '#777EF9',
    '#666EF8',
    '#555EF7',
  ],
  colorFromBlueToRedShades: {
    '#555EF7': '#FFFFFF',
    '#666EF8': '#FFFFFF',
    '#777EF9': '#FFFFFF',
    '#888EF9': '#FFFFFF',
    '#999EFA': '#FFFFFF',
    '#AAAEFB': '#395d81',
    '#BBBEFC': '#395d81',
    '#CCCEFC': '#395d81',
    '#DDDEFD': '#395d81',
    '#EEEEFE': '#395d81',
    '#F7F8F9': '#395d81',
    '#FFEDED': '#395d81',
    '#FFDCDC': '#395d81',
    '#FFCACA': '#395d81',
    '#FFC4C4': '#395d81',
    '#FFB9B9': '#395d81',
    '#FFA9A9': '#395d81',
    '#FFA0A0': '#395d81',
    '#FF9090': '#395d81',
    '#FF8080': '#FFFFFF',
    '#FF7070': '#FFFFFF',
    '#FF6060': '#FFFFFF',
    '#FF5050': '#FFFFFF',
    '#FF4848': '#FFFFFF',
    '#FF4040': '#FFFFFF',
    '#F7F8F9': '#395d81',
  },
  pitchingMetricsKeyNames: {
    pitcher_team: 'Pitcher Team',
    avg_fb_velo: 'Avg FB Velo',
    avg_fb_spin: 'Avg FB Spin',
    avg_bb_spin: 'Avg BB Spin',
    in_zone: 'In Zone %',
    first_pitch_k: 'First Pitch K %',
    whiff: 'Whiff %',
    chase_swing: 'Chase %',
    count: 'Count',
    in_zoneCount: 'In Zone Count',
    swingCount: 'Swing Count',
    edge: 'Edge %',
    csw: 'CSW %',
    strikeout: 'Strikeouts',
    walks: 'Walks'
  },
  hittingMetricsKeyNames: {
    batter_team: 'Batter Team',
    avg_ev: 'Avg EV',
    hard_hit_percentage: 'Hard Hit %',
    gb_percentage: 'GB %',
    ld_percentage: 'LD %',
    fb_percentage: 'FB %',
    pu_percentage: 'PU %',
    damage_percentage: 'Damage %'
  },
  splineChartKey: {
    velocity: 'velo',
    spin_rate: 'spin_rate',
    release_height: 'release_height',
    extension: 'ext',
    vertical_break: 'v_break',
    induced_vertical_break: 'ind_v_break',
    horizontal_break: 'h_break',
    efficiency:'efficiency',
    vert_appr_angle:'vert_appr_angle',
    release_side:"release_side"
  },
  yaxisSplineChart: {
    velocity: 'Velocity (mph)',
    spin_rate: 'Spin Rate',
    release_height: 'Rel Height',
    extension: 'Extension',
    vertical_break: 'Vert Break',
    induced_vertical_break: 'Ind V Break',
    horizontal_break: 'H Break',
    bat_speed: 'Bat Speed',
    plane: 'Plane',
    connection: 'Connection',
    rotation: 'Rotation',
    rotational_acceleration: 'Rotational Acceleration',
    on_plane_efficiency: 'On Plane Efficiency',
    attack_angle: 'Attack Angle',
    early_connection: 'Early Connection',
    connection_at_impact: 'Connection at Impact',
    vertical_bat_angle: 'Vertical Bat Angle',
    power: 'Power',
    time_to_contact: 'Time to Contact',
    peak_hand_speed: 'Peak Hand Speed',
    efficiency:'Efficiency',
    vert_appr_angle:'VAA',
    release_side:'Rel Side'
  },
  colorAxisStops1 : [
    [0, '#555EF7'],
    [0.04, '#666EF8'],
    [0.08, '#777EF9'],
    [0.12, '#888EF9'],
    [0.16, '#999EFA'],
    [0.20, '#AAAEFB'],
    [0.24, '#BBBEFC'],
    [0.28, '#CCCEFC'],
    [0.32, '#DDDEFD'],
    [0.36, '#EEEEFE'],
    [0.40, '#F7F8F9'],
    [0.44, '#FFEDED'],
    [0.48, '#FFDCDC'],
    [0.52, '#FFCACA'],
    [0.56, '#FFC4C4'],
    [0.60, '#FFB9B9'],
    [0.64, '#FFA9A9'],
    [0.68, '#FFA0A0'],
    [0.72, '#FF9090'],
    [0.76, '#FF8080'],
    [0.80, '#FF7070'],
    [0.84, '#FF6060'],
    [0.88, '#FF5050'],
    [0.92, '#FF4848'],
    [0.96, '#FF4040'],
  ],
  colorAxisStops2: [
    [0, '#1707C7'],
    [0.3, '#8686FD'],
    [0.6, '#FF8465'],
    [0.9, '#850101'],
  ],
  colorAxisStops3: [
    [0, '#CCC'],
    [0.3, '#CCC'],
    [0.6, '#CCC'],
    [0.9, '#CCC'],
  ],
  colorFromBlueToRedShades1 : {
    0: '#FFFFFF',
    0.04: '#FFFFFF',
    0.08: '#FFFFFF',
    0.12: '#FFFFFF',
    0.16: '#FFFFFF',
    0.20: '#395d81',
    0.24: '#395d81',
    0.28: '#395d81',
    0.32: '#395d81',
    0.36: '#395d81',
    0.40: '#395d81',
    0.44: '#395d81',
    0.48: '#395d81',
    0.52: '#395d81',
    0.56: '#395d81',
    0.60: '#395d81',
    0.64: '#395d81',
    0.68: '#395d81',
    0.72: '#395d81',
    0.76: '#FFFFFF',
    0.80: '#FFFFFF',
    0.84: '#FFFFFF',
    0.88: '#FFFFFF',
    0.92: '#FFFFFF',
    0.96: '#FFFFFF',
    1.00: '#FFFFFF',
  },
  colorFromBlueToRedShades2 : {
    0: '#FFFFFF',
    0.10: '#FFFFFF',
    0.20: '#FFFFFF',
    0.30: '#FFFFFF',
    0.40: '#395d81',
    0.50: '#395d81',
    0.60: '#395d81',
    0.70: '#FFFFFF',
    0.80: '#FFFFFF',
    0.90: '#FFFFFF',
    1.00: '#FFFFFF',
  },
  colorAxisStopsHistro : {
    0: '#555EF7',
    0.04: '#666EF8',
    0.08: '#777EF9',
    0.12: '#888EF9',
    0.16: '#999EFA',
    0.20: '#AAAEFB',
    0.24: '#BBBEFC',
    0.28: '#CCCEFC',
    0.32: '#DDDEFD',
    0.36: '#EEEEFE',
    0.40: '#F7F8F9',
    0.44: '#FFEDED',
    0.48: '#FFDCDC',
    0.52: '#FFCACA',
    0.56: '#FFC4C4',
    0.60: '#FFB9B9',
    0.64: '#FFA9A9',
    0.68: '#FFA0A0',
    0.72: '#FF9090',
    0.76: '#FF8080',
    0.80: '#FF7070',
    0.84: '#FF6060',
    0.88: '#FF5050',
    0.92: '#FF4848',
    0.96: '#FF4040',
    1: '#FF2727'
  },
  teamInsightsTooltip: {
    "EV in Air": "Average EV of LD and FB",
    "Hard Hit%": gametype === "2" ? "% of batted balls ≥ 65 EV" : "% of batted balls ≥ 90 EV",
    "Damage%": gametype === "2" ? "% of batted balls ≥ 65 EV and ≥ 10 degrees" : "% of batted balls ≥ 90 EV and ≥ 10 degrees",
    "SwStr%": "Whiffs / All Pitches",
    "Whiff%": "Whiffs / All Swings",
    "IZ Whiff%": "IZ Whiffs / IZ Swings",
    "Contact%": "Contact / All Swings",
    "Impact Zone%": "% of batted balls between 10 and 25 degrees",
    "Zone%": "",
    "Edge%": "",
    "Chase%": "% of swings on pitch out of zone",
    "CSW%": "(Called Strikes + Whiffs) / All Pitches",
    "Put Away%": "% of time 2 strike count ends in K",
    "Race to 2K%": "% of time pitcher gets to 2 strikes before 2 balls"
  },
};

