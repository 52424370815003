import React, { useEffect, useState } from "react";
import * as d3 from "d3";
import BasicSpray from "./SprayChartd3";
import SprayTable from "./sprayTable";
import { zoneTableFormate } from "../../../../../shared/function";
import NoSpreyChart from "../../noSpreyChart";
import { constant } from "../../../../../shared/constant";

function SprayChart({ zoneChartA, zoneChartB, zoneTableA, zoneTableB }) {
  const [s, setS] = useState({ chartToggle: true });
  const [zoneTable, setZoneTable] = useState({});
  
  useEffect(() => {
    if (window.innerWidth < 1366) {
      if (s.chartToggle === true) { 
        renderChart(zoneChartA, "#groundChart");
      } else {
        renderChart(zoneChartB, "#groundChart1");
      }
    } else {
      renderChart(zoneChartA, "#groundChart");
      renderChart(zoneChartB, "#groundChart1");
    }
  }, [zoneChartA, zoneChartB, s]);

  // useEffect(() => {
  //   renderChart(zoneChartB, "#groundChart1");
  // }, [zoneChartB]);

  useEffect(() => {
    setZoneTable(zoneTableFormate(zoneTableA, zoneTableB));
  }, [zoneTableA, zoneTableB]);

  const renderChart = (data, id) => {
    const selection = d3.select(id);
    if (!selection.empty()) {
      const sprayChart = new BasicSpray(selection, constant.softball);
      sprayChart.update(data);
    }
  };

  const onClickToggleButton = () => {
    setS({
      chartToggle: !s.chartToggle
    });
  };

  return (
    <>
      {window.innerWidth < 1366 ? (
        <div className="row">
          {s.chartToggle === true ? (
            <div className="w-38">
              <div className="group-select-box">
                <h4>Group A</h4>
                <h3 className="chart-name-title">Spray Chart</h3>
                {/* <p className="chart-name-disc">From catcher's perspective</p> */}
              </div>
              <div className="ground-chart sprayChartCls contactTabSpray1">
                {zoneChartA.length !== 0 ? (
                  <div id="groundChart"></div>
                ) : (
                  <NoSpreyChart />
                )}
              </div>
            </div>
          ) : (
            <div className="w-38">
              <div className="group-select-box">
                <h4>Group B</h4>
                <h3 className="chart-name-title">Spray Chart</h3>
                {/* <p className="chart-name-disc">From catcher's perspective</p> */}
              </div>
              <div className="ground-chart sprayChartCls contactTabSpray1">
                {zoneChartB.length !== 0 ? (
                  <div id="groundChart1"></div>
                ) : (
                  <NoSpreyChart />
                )}
              </div>
            </div>
          )}
          <div className="toggle-switch">
            <label className="switch">
              <input
                type="checkbox"
                id="togBtn"
                checked={s.chartToggle}
                onChange={onClickToggleButton}
              />
              <div className="slider round">
                <span className="on">Group A</span>
                <span className="off">Group B</span>
              </div>
            </label>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="w-38">
            <div className="group-select-box">
              <h4>Group A</h4>
              <h3 className="chart-name-title">Spray Chart</h3>
              {/* <p className="chart-name-disc">From catcher's perspective</p> */}
            </div>
            <div className="ground-chart sprayChartCls contactTabSpray1">
              {zoneChartA.length !== 0 ? (
                <div id="groundChart"></div>
              ) : (
                <NoSpreyChart />
              )}
            </div>
          </div>
          <div className="w-38">
            <div className="group-select-box">
              <h4>Group B</h4>
              <h3 className="chart-name-title">Spray Chart</h3>
              {/* <p className="chart-name-disc">From catcher's perspective</p> */}
            </div>
            <div className="ground-chart sprayChartCls contactTabSpray1">
              {zoneChartB.length !== 0 ? (
                <div id="groundChart1"></div>
              ) : (
                <NoSpreyChart />
              )}
            </div>
          </div>
        </div>
      )}

      {Object.keys(zoneTable).length > 0 && <SprayTable data={zoneTable} />}
    </>
  );
}

export default SprayChart;
