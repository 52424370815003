import React from 'react';
import ProfileCalledPitchesChart from "../../../components/Charts/calledPitchesChart";
import ProfileEdgeCallsChart from "../../../components/Charts/edgeCallsChart";
import ProfileContourGraphChart from "../../../components/Charts/contourGraphChart";
import AllPitchesTable from "./CatcherTable";
import EdgeTable from "./CatcherTable";
import RHPitchersTable from "./CatcherTable";
import LHPitchersTable from "./CatcherTable";
import RHHittersTable from "./CatcherTable";
import LHHittersTable from "./CatcherTable";

function CatchingTab({ filterRes, isPrinting, eventProfileData, sendReq, tabslist, isNotDownloadImgLoader, isDownloadImgLoader }) {
    return (
        <>
            <div className="d-flex">
                <div className="w-100">
                    <div className="container-fluid">
                        {/* view chart */}
                        <div className="common-charts">
                            <div className={`row ${!isPrinting ? 'singleDiv' : 'breakDiv'}`}>
                                <div className="w-33">
                                    <ProfileCalledPitchesChart
                                        chartData={filterRes.called_pitches || []}
                                        id="profileCalledPitchesChart"
                                        eventProfileData={eventProfileData}
                                        sendReq={sendReq}
                                        tabslist={tabslist}
                                        profilepage={true}
                                        isNotDownloadImgLoader={isNotDownloadImgLoader}
                                        isDownloadImgLoader={isDownloadImgLoader} />
                                </div>
                                <div className="w-33">
                                    <ProfileEdgeCallsChart
                                        chartData={filterRes.edge_calls || []}
                                        id="profileEdgeCallsChart"
                                        eventProfileData={eventProfileData}
                                        sendReq={sendReq}
                                        tabslist={tabslist}
                                        profilepage={true}
                                        isNotDownloadImgLoader={isNotDownloadImgLoader}
                                        isDownloadImgLoader={isDownloadImgLoader} />
                                </div>
                                <div className="w-33">
                                    <ProfileContourGraphChart
                                        chartData={filterRes.called_strikes_contour_graph || []}
                                        id="profileContourGraphChart"
                                        eventProfileData={eventProfileData}
                                        sendReq={sendReq}
                                        tabslist={tabslist}
                                        profilepage={true}
                                        isNotDownloadImgLoader={isNotDownloadImgLoader}
                                        isDownloadImgLoader={isDownloadImgLoader}
                                        profile="profile" />
                                </div>
                            </div>
                        </div>
                        {/* table */}
                        <div className="container-fluid">
                            <div className="row">
                                <div className="w-50">
                                    <AllPitchesTable
                                        tableData={filterRes.all_pitches_table_data || []}
                                        title="All Pitches" />
                                </div>
                                <div className="w-50">
                                    <EdgeTable
                                        tableData={filterRes.edge_pitches_table_data || []}
                                        title="Edge" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="w-50">
                                    <RHPitchersTable
                                        tableData={filterRes.righty_pitcher_pitches_table_data || []}
                                        title="Right Handed Pitchers" />
                                </div>
                                <div className="w-50">
                                    <LHPitchersTable
                                        tableData={filterRes.lefty_pitcher_pitches_table_data || []}
                                        title="Left Handed Pitchers" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="w-50">
                                    <RHHittersTable
                                        tableData={filterRes.righty_hitter_pitches_table_data || []}
                                        title="Right Handed Hitters" />
                                </div>
                                <div className="w-50">
                                    <LHHittersTable
                                        tableData={filterRes.lefty_hitter_pitches_table_data || []}
                                        title="Left Handed Hitters" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CatchingTab