import React from "react";
import PropTypes from "prop-types";
import { createColoredColumnObjTable } from "../../shared/function";
import { constant } from "../../shared/constant";

function AwayBattedBallsTable({ tableData }) {
  const renderList = () => {
    return (
      tableData && tableData[0]?.data &&
        tableData[0]?.data.length !== 0 ?
        tableData[0]?.data.map((data, i) => {
          return (
            <tr key={i}>
              <td className="table-rowwhite">{data.rank ? data.rank : '-'}</td>
              <td className="table-rowwhite">{data.batter ? data.batter : '-'}</td>
              <td className="table-rowwhite" style={{ color: constant.pitchTypeColor[data.pitch_type] }}>{data.pitch_type ? data.pitch_type : '-'}</td>
              <td className="table-rowwhite">{data.result ? data.result : '-'}</td>
              <td className="table-rowwhite" style={createColoredColumnObjTable('exit_velocity',tableData[0]?.data, data)}>
                {data.exit_velocity ? data.exit_velocity.length !== 0 ? data.exit_velocity.toString().includes(".") ? data.exit_velocity.toFixed(1) : data.exit_velocity : '-' : '-'}
              </td>
              <td className="table-rowwhite">
                {data.launch_angle ? data.launch_angle.length !== 0 ? data.launch_angle.toString().includes(".") ? data.launch_angle.toFixed(1) : data.launch_angle : '-' : '-' }
              </td>
              <td className="table-rowwhite">
                {data.distance ? data.distance.length !== 0 ? Math.round(data.distance) : '-' : '-'}
              </td>
            </tr>
          );
        })
        :
        <tr>
          <td className="table-rowwhite text-center" colSpan="7">There's no info yet!</td>
        </tr>
    )
  }

  return (
    <div className="container-fluid">
      <h3>Batted Balls</h3>
      <div className="homepitchers-table">
        <div className="table-responsive-xl">
          <table className="table tabledashed">
            <thead>
              <tr>
                <th className="tablerowblue fw-500">Pitch No</th>
                <th className="tablerowblue fw-500">Batter</th>
                <th className="tablerowblue fw-500">Pitch Type</th>
                <th className="tablerowblue fw-500">Result</th>
                <th className="tablerowblue fw-500">EV</th>
                <th className="tablerowblue fw-500">LA</th>
                <th className="tablerowblue fw-500">Distance</th>
              </tr>
            </thead>
            <tbody>
              {renderList()}
              {tableData && tableData[0]?.data && tableData[0]?.data.length !== 0 && <tr>
                <td className="table-rowgrey80">Average</td>
                <td className="table-rowgrey80"></td>
                <td className="table-rowgrey80"></td>
                <td className="table-rowgrey80"></td>
                <td className="table-rowgrey80">
                  {tableData[0]?.avg_ev ? tableData[0]?.avg_ev.toString().includes(".") ? tableData[0]?.avg_ev.toFixed(1) : tableData[0]?.avg_ev : '-'}
                </td>
                <td className="table-rowgrey80">
                  {tableData[0]?.avg_la ? tableData[0]?.avg_la.toString().includes(".") ? tableData[0]?.avg_la.toFixed(1) : tableData[0]?.avg_la : '-'}
                </td>
                <td className="table-rowgrey80">
                  {tableData[0]?.avg_distance ? Math.round(tableData[0]?.avg_distance) : '-'}
                </td>
              </tr>}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

AwayBattedBallsTable.propTypes = {};

export default AwayBattedBallsTable;
