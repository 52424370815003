import React, { Component } from "react";
import { imagesArr } from "../../../assets/images";
import { connect } from "react-redux";
import { getScrimmageSummaryData } from "../../../store/actions";
import Select from "react-select";
import { printToPDFMultiple } from "../../../shared/printReportUtils";
import Loader from "../../../shared/loader";
import AtBat from "../../../components/Charts/AtBat/AtBat";
import SummaryTable from "../../../components/CommanTable/SummaryTable";
import HitterPlateDiscipline from "../../../components/CommanTable/HitterPlateDiscipline";
import HitterBattedBallTable from "../../../components/CommanTable/HitterBattedBallTable";
import HitterPitchSequencingChart from "../../../components/Charts/pitchSequencingChart";
import HitterCountSequencingChart from "../../../components/Charts/pitchSequencingChart";
import HitterResultSequencingChart from "../../../components/Charts/pitchSequencingChart";

class Hitters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: "",
      filterFlag: true,
      hittersData: [],
      hitterSummaryTableData: [],
      HitterPlateDisciplineTableData: [],
      HitterBattedBallTableData: [],
      pitchersSequenceChart: [],
      countSequenceChart: [],
      resultSequenceChart: [],
      atBatTableData: [],
      atBatSprayChartData: [],
      atBatStrikeChartData: [],
      atBatLength: [],
      isPrinting: false,
      isDownloadImg: false
    };
  }

  componentDidMount() {
    let sorceid = document.location.href.split("/")[4];
    if (sorceid === undefined) window.location.href = "/";
    else this.hittersTabData("", false);
  }

  componentDidUpdate = () => {
    if (this.props.appState.ShitterfilterFlag) {
      if (this.props.hitterFilter[0] !== this.state.hittersData[0]?.value) {
        let sorceid = document.location.href.split("/")[4];
        if (sorceid === undefined) window.location.href = "/";
        else this.hittersTabData("", true);
      }
    }
  }

  // fetch Pitch log data
  hittersTabData = (hitterName, flag) => {
    let data = {
      sourceId: document.location.href.split("/")[4],
      tab: "hitters",
      filter: {
        pitcher_name: "",
        batter_name: this.props.appState.ShitterfilterFlag ? this.props.hitterFilter[0] : hitterName ? hitterName : this.props.appState.ShitterName,
      },
      // printflag: this.props?.isPrint
    };
    // this.props.increment();
    this.props.fetchScrimmageSummaryData(data, (res) => {
      this.setState({
        hitterSummaryTableData: res.data.result.hitter_summary_table_data,
        HitterPlateDisciplineTableData: res.data.result.hitter_plate_discipline_table_data,
        HitterBattedBallTableData: res.data.result.hitter_batted_balls_data,
        pitchersSequenceChart: res.data.result.hitter_pitch_sequence_chart_data,
        countSequenceChart: res.data.result.hitter_count_sequencing_chart_data,
        resultSequenceChart: res.data.result.hitter_result_sequencing_chart_data,
        atBatTableData: res.data.result.hitter_at_bats_table_data,
        atBatSprayChartData: res.data.result.hitter_spray_chart_data,
        atBatStrikeChartData: res.data.result.hitter_strike_zone_data,
        hitterDefault: this.props.appState.ShitterfilterFlag ? this.props.hitterFilter[0] : hitterName ? hitterName : this.props.appState.ShitterName
      });

      // set atbats length arrary for mapping
      if (this.state.atBatStrikeChartData.length !== 0 && this.state.atBatTableData.length !== 0 && this.state.atBatSprayChartData.length !== 0) {
        let str = Object.keys(this.state.atBatStrikeChartData);
        let tab = Object.keys(this.state.atBatTableData);
        let spr = Object.keys(this.state.atBatSprayChartData);

        // if (JSON.stringify(str) === JSON.stringify(tab) && JSON.stringify(tab)  === JSON.stringify(spr) && JSON.stringify(str) === JSON.stringify(spr)) {
        this.setState({
          atBatLength: str
        })
        // }
      }

      // for load hitter's wise data
      let hitterData = this.props.hitterFilter;

      let hittersSelectData = [];
      // hittersSelectData.push({ value: "", label: "All Hitters" })
      hitterData.map((data) => {
        hittersSelectData.push({
          value: data,
          label: data,
        });
      });
      this.setState({ hittersData: hittersSelectData })
      // this.props.decrement();
      // if (!this.props.isStopPrint && flag ) {
      //   this.props.reduceDataCount();
      // }
    });
  };

  // decrease = () => {
  //   if (!this.props.isStopPrint && this.props.isPrint) {
  //     this.props.reduceDataCount();
  //   }
  // }

  handleCallback = (index) => {
    this.setState({
      tabs: index,
    });
  };

  print = async () => {
    await this.setState({ isPrinting: true })
    await printToPDFMultiple(`hitters`, [
      // 'ChartPrintAreaHitter',
      'tablePrintAreaBatter',
      ...this.state.atBatLength
    ]);
    this.setState({ isPrinting: false })
  }

  printAll = async () => {
    this.props.printAll();
    this.props.appState.ShitterName = this.state.hitterDefault
  }

  // change hitter wise data
  handleChange = (e) => {
    this.props.appState.ShitterfilterFlag = false
    this.hittersTabData(e.value, true);
  };

  render() {
    const tabs = this.state.tabs;
    let { atBatLength, hittersData, hitterSummaryTableData, HitterPlateDisciplineTableData, HitterBattedBallTableData, pitchersSequenceChart, countSequenceChart, resultSequenceChart, atBatTableData, atBatSprayChartData, atBatStrikeChartData } = this.state;
    return (
      <>
        {this.state.isPrinting && <Loader />}
        {this.state.isDownloadImg && <Loader text={true}/>}
        <section className="pb-0" id="ChartPrintAreaHitter">
          <div className="container-fluid">
            <div className="common-title withsearch">
              <h2>Hitters</h2>
              <div className="right-side">
                <div className="serch-box">
                  <Select
                    className="customize-select"
                    classNamePrefix="customize-select"
                    onChange={this.handleChange.bind(this)}
                    options={hittersData}
                    value={hittersData.find((obj) => obj.value === this.state.hitterDefault)}
                  // defaultValue={{
                  //   label: "All Hitters",
                  //   value: "",
                  // }}
                  />
                </div>
                {(!(this.state.isPrinting || this.props.isPrint)) && <div className="dropdown">
                  <span className="printer"><img src={imagesArr.printer} alt="print" /></span>
                  <div className="dropdown-content">
                    <ul>
                      <li onClick={() => this.print()}>Print</li>
                      {/* <li onClick={() => this.printAll()}>Print All</li> */}
                    </ul>
                  </div>
                </div>}
              </div>
            </div>
          </div>
          {/* hitter tables */}
          <div id="tablePrintAreaBatter">
            {(this.state.isPrinting || this.props.isPrint) ? <h3>{this.state.hitterDefault}</h3> : ""}
            <div className="ss-hitter-summary-table">
              <SummaryTable tableData={hitterSummaryTableData} />
            </div>
            <div className="ss-hitter-platediscipline-table">
              <HitterPlateDiscipline tableData={HitterPlateDisciplineTableData} />
            </div>
            <div className="ss-hitter-battedball-table">
              <HitterBattedBallTable tableData={HitterBattedBallTableData} />
            </div>

            {/* sequencuning chart */}
            <div className="container-fluid" id="sequencingChartPrintArea">
              <div className="common-charts">
                <div className={`row ${!(this.state.isPrinting || this.props.isPrint) ? 'singleDiv' : 'breakDiv'}`}>
                  <div className="w-33">
                    <HitterPitchSequencingChart
                      chartData={pitchersSequenceChart}
                      id="PitchSequencingChartHitter"
                      showTitle={true}
                      increment={this.props.increment}
                      decrement={this.props.decrement}
                      eventName={this.props.eventName}
                      pitcherDefault={this.state.hitterDefault}
                      profilepage={false}
                      isNotDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: false }))}
                      isDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: true }))}
                    />
                  </div>
                  <div className="w-33">
                    <HitterCountSequencingChart
                      chartData={countSequenceChart}
                      title="Count"
                      id="countSequencingChartHitter"
                      showTitle={true}
                      increment={this.props.increment}
                      decrement={this.props.decrement}
                      eventName={this.props.eventName}
                      pitcherDefault={this.state.hitterDefault}
                      profilepage={false}
                      isNotDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: false }))}
                      isDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: true }))}
                    />
                  </div>
                  <div className="w-33">
                    <HitterResultSequencingChart
                      chartData={resultSequenceChart}
                      title="Result"
                      id="resultSequencingChartHitter"
                      showTitle={true}
                      increment={this.props.increment}
                      decrement={this.props.decrement}
                      eventName={this.props.eventName}
                      pitcherDefault={this.state.hitterDefault}
                      profilepage={false}
                      isNotDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: false }))}
                      isDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: true }))}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ss-hitter-atbats-table">
            <h3 className="table-title">AT BATS</h3>
            {atBatLength.length !== 0 ?
              atBatLength.map((i) => {
                return <AtBat
                  index={i}
                  atBatStrikeChartData={atBatStrikeChartData}
                  atBatTableData={atBatTableData}
                  atBatSprayChartData={atBatSprayChartData}
                  isPrinting={this.state.isPrinting}
                  isPrint={this.props.isPrint}
                  scrimmage={true}
                  increment={this.props.increment}
                  decrement={this.props.decrement}
                  eventName={this.props.eventName}
                  pitcherDefault={this.state.hitterDefault}
                  profilepage={false}
                  isNotDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: false }))}
                  isDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: true }))}
                />
              })
              :
              <div className="container-fluid">
                <div className="common-charts text-center">There's no info yet!</div>
              </div>}
          </div>
        </section>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    teamData: state.teamData,
    appState: state.app
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchScrimmageSummaryData: (data, next) => dispatch(getScrimmageSummaryData(data, next)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Hitters);
