import React from "react";
import { Link } from "react-router-dom";
import { stringValueSort, getSortedValues, createPercentageColumnObj, stringValueSortByfixTeam } from "../../shared/function";
import { Table } from "antd";
import PrintTable from "./common/printTable";
import { DownOutlined, UpOutlined,RightOutlined } from "@ant-design/icons";
import { constant } from '../../shared/constant';


function PlatedisciplineTable({ tableData, title, isPrinting }) {
    var allRows = [];
    tableData &&
        tableData.map((list, i) =>
            allRows.push({
                key: i,
                HittersName: title === "pitcher" ? list?.pitcher_name : list?.batter_name,
                name: { batterName: title === "pitcher" ? list?.pitcher_name : list?.batter_name, batterId: list?._id, batterProfile: list?.profileUrl },
                bats: title === "pitcher" ? list?.throws || "—" : list?.bats || "—",
                bbe: list?.bbe || "—",
                egde: list.egde && list.egde ? Number(list.egde.toFixed(1)) : "—",
                first_pitch_k: list.first_pitch_k && list.first_pitch_k ? Number(list.first_pitch_k.toFixed(1)) : "—",
                strike: list.strike && list.strike ? Number(list.strike.toFixed(1)) : "—",
                zone: list.zone && list.zone ? Number(list.zone.toFixed(1)) : "—",
                swing: list.swing && list.swing ? Number(list.swing.toFixed(1)) : "—",
                swinging_strike: list.swinging_strike && list.swinging_strike ? Number(list.swinging_strike.toFixed(1)) : "—",
                whiff: list.whiff && list.whiff ? Number(list.whiff.toFixed(1)) : '—',
                zone_swing: list.zone_swing && list.zone_swing ? Number(list.zone_swing.toFixed(1)) : '—',
                zone_contact: list.zone_contact && list.zone_contact ? Number(list.zone_contact.toFixed(1)) : '—',
                chase_swing: list.chase_swing && list.chase_swing ? Number(list.chase_swing.toFixed(1)) : '—',
                chase_contact: list.chase_contact && list.chase_contact ? Number(list.chase_contact.toFixed(1)) : '—',
                pitch_data: list.pitch_data
            })
        );

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            sorter: (a, b) => stringValueSort(a["name"].batterName, b["name"].batterName),
            sortDirections: ['descend', 'ascend'],
            fixed: isPrinting ? '' :'left',
            render: (text, record) => {
                return {
                    props: {
                        style: { background: record.HittersName === "Team" ? "LightGray" : "" }
                    },
                    children: <>
                        {record.HittersName === "Team" ?
                            <div>{text.batterName}</div> :
                            <Link to={record.HittersName === "Team" ? "" : `/profiledashboard/${text.batterId}`}>
                                {/* <img src={record.batterProfile} alt="icon" className="d-inline icon" /> */}
                                {text.batterName}
                            </Link>
                        }
                    </>
                }
            }
        },
        Table.EXPAND_COLUMN,
        {
            title: title === "pitcher" ? "Throws" : "Bats",
            dataIndex: "bats",
            sorter: (a, b) => stringValueSortByfixTeam(a, b, "bats"),
            sortDirections: ['descend', 'ascend'],
            render(text, record) {
                return {
                    props: {
                        style: { background: record.HittersName === "Team" ? "LightGray" : "" }
                    },
                    children: <div>{text}</div>
                };
            }
        },
        {
            title: "#",
            dataIndex: "bbe",
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "bbe"),
            sortDirections: ['descend', 'ascend'],
            render(text, record) {
                return {
                    props: {
                        style: { background: record.HittersName === "Team" ? "LightGray" : "" }
                    },
                    children: <div>{text}</div>
                };
            }
        },
        createPercentageColumnObj('Edge %', 'egde', allRows, true, {
            className: "tablegraybp",
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "egde"),
            sortDirections: ['descend', 'ascend'],
        }),
        createPercentageColumnObj('1st Pitch K %', 'first_pitch_k', allRows, true, {
            className: "tablegraybp",
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "first_pitch_k"),
            sortDirections: ['descend', 'ascend'],
        }),
        createPercentageColumnObj('Strike %', 'strike', allRows, true, {
            className: "tablegraybp",
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "strike"),
            sortDirections: ['descend', 'ascend'],
        }),
        createPercentageColumnObj('Zone %', 'zone', allRows, true, {
            className: "tablegraybp",
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "zone"),
            sortDirections: ['descend', 'ascend'],
        }),
        createPercentageColumnObj('Swing %', 'swing', allRows, true, {
            className: "tablegraybp",
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "swing"),
            sortDirections: ['descend', 'ascend'],
        }),
        createPercentageColumnObj('SwStr %', 'swinging_strike', allRows, true, {
            className: "tablegraybp",
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "swinging_strike"),
            sortDirections: ['descend', 'ascend'],
        }),
        createPercentageColumnObj('Whiff %', 'whiff', allRows, true, {
            className: "tablegraybp",
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "whiff"),
            sortDirections: ['descend', 'ascend'],
        }),
        createPercentageColumnObj('Zone Swing %', 'zone_swing', allRows, true, {
            className: "tablegraybp",
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "zone_swing"),
            sortDirections: ['descend', 'ascend'],
        }),
        createPercentageColumnObj('Zone Contact %', 'zone_contact', allRows, true, {
            className: "tablegraybp",
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "zone_contact"),
            sortDirections: ['descend', 'ascend'],
        }),
        createPercentageColumnObj('Chase %', 'chase_swing', allRows, true, {
            className: "tablegraybp",
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "chase_swing"),
            sortDirections: ['descend', 'ascend'],
        }),
        createPercentageColumnObj('Chase Contact %', 'chase_contact', allRows, true, {
            className: "tablegraybp",
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "chase_contact"),
            sortDirections: ['descend', 'ascend'],
        })
    ];
    const data = allRows;
    const renderInnerRows = (row) => {
        var childRows = [];
        row?.pitch_data && row?.pitch_data.map((items, j) => {
            childRows.push({
                key: j,
                id: items._id,
                HittersName: title === "pitcher" ? items?.pitcher_name : items?.batter_name,
                name: { batterName: title === "pitcher" ? items?.pitcher_name : items?.batter_name, batterId: items?._id, batterProfile: items?.profileUrl },
                bats: title === "pitcher" ? items?.throws || "—" : items?.bats || "—",
                bbe: items?.bbe || "—",
                egde: items.egde && items.egde ? Number(items.egde.toFixed(1)) : "—",
                first_pitch_k: items.first_pitch_k && items.first_pitch_k ? Number(items.first_pitch_k.toFixed(1)) : "—",
                strike: items.strike && items.strike ? Number(items.strike.toFixed(1)) : "—",
                zone: items.zone && items.zone ? Number(items.zone.toFixed(1)) : "—",
                swing: items.swing && items.swing ? Number(items.swing.toFixed(1)) : "—",
                swinging_strike: items.swinging_strike && items.swinging_strike ? Number(items.swinging_strike.toFixed(1)) : "—",
                whiff: items.whiff && items.whiff ? Number(items.whiff.toFixed(1)) : '—',
                zone_swing: items.zone_swing && items.zone_swing ? Number(items.zone_swing.toFixed(1)) : '—',
                zone_contact: items.zone_contact && items.zone_contact ? Number(items.zone_contact.toFixed(1)) : '—',
                chase_swing: items.chase_swing && items.chase_swing ? Number(items.chase_swing.toFixed(1)) : '—',
                chase_contact: items.chase_contact && items.chase_contact ? Number(items.chase_contact.toFixed(1)) : '—',
            })
        })

        const inner_columns = [
            {
                title: "PitchType",
                dataIndex: "id",
                render(text, record) {
                    return {
                      props: {
                        style: { color: constant.pitchTypeColor[text] }
                      },
                      children: <div>{text}</div>
                    };
                  }
            },
            {
                title: "",
                dataIndex: "",
                width: title === "pitcher" ? 22.20 : 35.20,
            },
            {
                title: title === "pitcher" ? "" : "Bats",
                dataIndex:  title === "pitcher" ? "" : "bats",
            },
            {
                title: "#",
                dataIndex: "bbe",
                sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "bbe"),
                sortDirections: ['descend', 'ascend'],
                render(text, record) {
                    return {
                        props: {
                            style: { background: record.HittersName === "Team" ? "LightGray" : "" }
                        },
                        children: <div>{text}</div>
                    };
                }
            },
            createPercentageColumnObj('Edge %', 'egde', allRows, true, {
                className: "tablegraybp",
                sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "egde"),
                sortDirections: ['descend', 'ascend'],
            }),
            createPercentageColumnObj('1st Pitch K %', 'first_pitch_k', allRows, true, {
                className: "tablegraybp",
                sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "first_pitch_k"),
                sortDirections: ['descend', 'ascend'],
            }),
            createPercentageColumnObj('Strike %', 'strike', allRows, true, {
                className: "tablegraybp",
                sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "strike"),
                sortDirections: ['descend', 'ascend'],
            }),
            createPercentageColumnObj('Zone %', 'zone', allRows, true, {
                className: "tablegraybp",
                sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "zone"),
                sortDirections: ['descend', 'ascend'],
            }),
            createPercentageColumnObj('Swing %', 'swing', allRows, true, {
                className: "tablegraybp",
                sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "swing"),
                sortDirections: ['descend', 'ascend'],
            }),
            createPercentageColumnObj('SwStr %', 'swinging_strike', allRows, true, {
                className: "tablegraybp",
                sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "swinging_strike"),
                sortDirections: ['descend', 'ascend'],
            }),
            createPercentageColumnObj('Whiff %', 'whiff', allRows, true, {
                className: "tablegraybp",
                sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "whiff"),
                sortDirections: ['descend', 'ascend'],
            }),
            createPercentageColumnObj('Zone Swing %', 'zone_swing', allRows, true, {
                className: "tablegraybp",
                sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "zone_swing"),
                sortDirections: ['descend', 'ascend'],
            }),
            createPercentageColumnObj('Zone Contact %', 'zone_contact', allRows, true, {
                className: "tablegraybp",
                sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "zone_contact"),
                sortDirections: ['descend', 'ascend'],
            }),
            createPercentageColumnObj('Chase %', 'chase_swing', allRows, true, {
                className: "tablegraybp",
                sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "chase_swing"),
                sortDirections: ['descend', 'ascend'],
            }),
            createPercentageColumnObj('Chase Contact %', 'chase_contact', allRows, true, {
                className: "tablegraybp",
                sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "chase_contact"),
                sortDirections: ['descend', 'ascend'],
            })
        ];
        const inner_data = childRows;
        return (
            <Table
                className="nestedTable"
                columns={inner_columns}
                dataSource={inner_data}
                pagination={false}
                showHeader={false}
                // scroll={{ y: 430 }}
            />
        );
    }

    return (
        <>
            {(isPrinting && data.length > 10) ? <PrintTable columns={columns} dataSource={data} /> :
                <div>
                    {isPrinting ?
                        <div className="print--table--wrp">
                            <Table
                                className={"table with-border"}
                                columns={columns}
                                dataSource={data}
                                pagination={false}
                            />
                        </div> 
                        :
                        <div className="table-responsive-xl teamsummary-table">
                            <table className="table with-border">
                                <div className="custom-header-fixed">
                                    <Table
                                        columns={columns}
                                        dataSource={data}
                                        pagination={false}
                                        className="parentTable_plate_discipline team_sec"
                                        expandIcon={({ expanded, onExpand, record }) =>
                                        record.pitch_data ? record.pitch_data.length ?
                                            expanded ? (
                                                <DownOutlined
                                                    style={{ color: "black" ,float: 'right'}}
                                                    onClick={(e) => onExpand(record, e)}
                                                />
                                            ) : (
                                                <RightOutlined style={{ color: "black" }} onClick={(e) => onExpand(record, e)} />
                                            ):
                                            "":""
                                        }
                                        expandable={{
                                            expandedRowRender: (row) => (
                                                <p
                                                  style={{
                                                    margin: 0,
                                                  }}
                                                >
                                                  { renderInnerRows(row)}
                                                </p>
                                              ),
                                        }}
                                    />
                                </div>
                            </table>
                        </div>}
                </div>}
        </>
    );
}

export default PlatedisciplineTable;
