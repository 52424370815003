function SprayTable({ data }) {
    return(
    <div className="table-responsive-xl mt-15">
        <table className="table tabledashed">
            <thead>
                <tr>
                    <th>Titles</th>
                    <th>Group A</th>
                    <th>Group B</th>
                    <th>Difference</th>
                </tr>
            </thead>
            <tbody>
                    <tr>
                        <th className="tablerowgray">Type</th>
                        <th className="tablerowgray">Zone %</th>
                        <th className="tablerowgray">Zone %</th>
                        <th className="tablerowgray">-</th>
                    </tr>
                    <tr>
                        <td className="table-rowwhite">Left</td>
                        <td className="table-rowwhite">{data.groupA.left_count !== null ? data.groupA.left_count.toString().includes(".") ? data.groupA.left_count.toFixed(1) : data.groupA.left_count : "-"}</td>
                        <td className="table-rowwhite">{data.groupB.left_count !== null ? data.groupB.left_count.toString().includes(".") ? data.groupB.left_count.toFixed(1) : data.groupB.left_count : "-"}</td>
                        <td className="table-rowwhite">
                            {(data.groupA.left_count - data.groupB.left_count).toString().includes(".")
                                ? (data.groupA.left_count - data.groupB.left_count).toFixed(1)
                                : data.groupA.left_count - data.groupB.left_count}</td>
                    </tr>
                    <tr>
                        <td className="table-rowwhite">Center</td>
                        <td className="table-rowwhite">{data.groupA.middle_count !== null ? data.groupA.middle_count.toString().includes(".") ? data.groupA.middle_count.toFixed(1) : data.groupA.middle_count : "-"}</td>
                        <td className="table-rowwhite">{data.groupB.middle_count !== null ? data.groupB.middle_count.toString().includes(".") ? data.groupB.middle_count.toFixed(1) : data.groupB.middle_count : "-"}</td>
                        <td className="table-rowwhite">
                            {(data.groupA.middle_count - data.groupB.middle_count).toString().includes(".")
                                ? (data.groupA.middle_count - data.groupB.middle_count).toFixed(1)
                                : data.groupA.middle_count - data.groupB.middle_count}</td>
                    </tr>
                    <tr>
                        <td className="table-rowwhite">Right</td>
                        <td className="table-rowwhite">{data.groupA.right_count !== null ? data.groupA.right_count.toString().includes(".") ? data.groupA.right_count.toFixed(1) : data.groupA.right_count : "-"}</td>
                        <td className="table-rowwhite">{data.groupB.right_count !== null ? data.groupB.right_count.toString().includes(".") ? data.groupB.right_count.toFixed(1) : data.groupB.right_count : "-"}</td>
                        <td className="table-rowwhite">
                            {(data.groupA.right_count - data.groupB.right_count).toString().includes(".")
                                ? (data.groupA.right_count - data.groupB.right_count).toFixed(1)
                                : data.groupA.right_count - data.groupB.right_count}</td>
                    </tr>
                    <tr>
                        <th className="tablerowgray">Type</th>
                        <th className="tablerowgray">Avg Exit Velo</th>
                        <th className="tablerowgray">Avg Exit Velo</th>
                        <th className="tablerowgray">-</th>
                    </tr>
                    <tr>
                        <td className="table-rowwhite">Left</td>
                        <td className="table-rowwhite">{data.groupA.left_avg_exit_velocity !== null ? data.groupA.left_avg_exit_velocity.toString().includes(".") ? data.groupA.left_avg_exit_velocity.toFixed(1) : data.groupA.left_avg_exit_velocity : "-"}</td>
                        <td className="table-rowwhite">{data.groupB.left_avg_exit_velocity !== null ? data.groupB.left_avg_exit_velocity.toString().includes(".") ? data.groupB.left_avg_exit_velocity.toFixed(1) : data.groupB.left_avg_exit_velocity : "-"}</td>
                        <td className="table-rowwhite">
                            {(data.groupA.left_avg_exit_velocity - data.groupB.left_avg_exit_velocity).toString().includes(".")
                                ? (data.groupA.left_avg_exit_velocity - data.groupB.left_avg_exit_velocity).toFixed(1)
                                : data.groupA.left_avg_exit_velocity - data.groupB.left_avg_exit_velocity}</td>
                    </tr>
                    <tr>
                        <td className="table-rowwhite">Center</td>
                        <td className="table-rowwhite">{data.groupA.middle_avg_exit_velocity !== null ? data.groupA.middle_avg_exit_velocity.toString().includes(".") ? data.groupA.middle_avg_exit_velocity.toFixed(1) : data.groupA.middle_avg_exit_velocity : "-"}</td>
                        <td className="table-rowwhite">{data.groupB.middle_avg_exit_velocity !== null ? data.groupB.middle_avg_exit_velocity.toString().includes(".") ? data.groupB.middle_avg_exit_velocity.toFixed(1) : data.groupB.middle_avg_exit_velocity : "-"}</td>
                        <td className="table-rowwhite">
                            {(data.groupA.middle_avg_exit_velocity - data.groupB.middle_avg_exit_velocity).toString().includes(".")
                                ? (data.groupA.middle_avg_exit_velocity - data.groupB.middle_avg_exit_velocity).toFixed(1)
                                : data.groupA.middle_avg_exit_velocity - data.groupB.middle_avg_exit_velocity}</td>
                    </tr>
                    <tr>
                        <td className="table-rowwhite">Right</td>
                        <td className="table-rowwhite">{data.groupA.right_avg_exit_velocity !== null ? data.groupA.right_avg_exit_velocity.toString().includes(".") ? data.groupA.right_avg_exit_velocity.toFixed(1) : data.groupA.right_avg_exit_velocity : "-"}</td>
                        <td className="table-rowwhite">{data.groupB.right_avg_exit_velocity !== null ? data.groupB.right_avg_exit_velocity.toString().includes(".") ? data.groupB.right_avg_exit_velocity.toFixed(1) : data.groupB.right_avg_exit_velocity : "-"}</td>
                        <td className="table-rowwhite">
                            {(data.groupA.right_avg_exit_velocity - data.groupB.right_avg_exit_velocity).toString().includes(".")
                                ? (data.groupA.right_avg_exit_velocity - data.groupB.right_avg_exit_velocity).toFixed(1)
                                : data.groupA.right_avg_exit_velocity - data.groupB.right_avg_exit_velocity}</td>
                    </tr>
            </tbody>
        </table>
    </div>
    )
}

export default SprayTable