import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { imagesArr } from "../../../assets/images";
import moment from "moment";
import range from "./range.json";

class appliedFilter extends Component {
    render() {
        const { sendReq, tabslist, arr, print, isPrinting } = this.props;
        return (
            <div className="filtersection d-flex align-items-center w-70">
                <div className="d-flex align-items-center w-80"> 
                    {!isPrinting && <h3>Applied Filters:</h3>}
                    {sendReq && Object.entries(sendReq).map((key, value) => {
                        return (
                            key[0] !== "tab" &&
                            key[1] !== "" &&
                            key[0] !== "scatter" &&
                            key[0] !== "date_from" &&
                            key[0] !== "date_to" &&
                            key[0] !== "velocity_to" &&
                            key[0] !== "velocity_from" &&
                            key[0] !== "exit_velocity_to" &&
                            key[0] !== "exit_velocity_from" &&
                            key[0] !== "spin_rate_to" &&
                            key[0] !== "spin_rate_from" &&
                            key[0] !== "launch_angle_to" &&
                            key[0] !== "launch_angle_from" &&
                            key[0] !== "efficiency_to" &&
                            key[0] !== "efficiency_from" &&
                            key[0] !== "vertical_break_to" &&
                            key[0] !== "vertical_break_from" &&
                            key[0] !== "induced_vertical_break_to" &&
                            key[0] !== "induced_vertical_break_from" &&
                            key[0] !== "horizontal_break_to" &&
                            key[0] !== "horizontal_break_from" &&
                            key[0] !== "vertical_approach_angle_to" &&
                            key[0] !== "vertical_approach_angle_from" &&
                            key[0] !== "horz_approach_angle_to" &&
                            key[0] !== "horz_approach_angle_from" &&
                            key[0] !== "release_height_to" &&
                            key[0] !== "release_height_from" &&
                            key[0] !== "extension_to" &&
                            key[0] !== "extension_from" &&
                            key[0] !== "heat_map_1" &&
                            key[0] !== "heat_map_2" &&
                            key[0] !== "heat_map_3" &&
                            key[0] !== "histrogram_field" &&
                            key[0] !== "histrogram_bucket" && (
                                <span key={value} className="filtertags">
                                    {key[0] === "season" ? key[1] + "-" + (parseFloat(key[1]) + 1) : key[1]}
                                    {key[0] !== "season" &&
                                        key[0] !== "type" &&
                                        key[0] !== "fall_spring" &&
                                        (tabslist !== 1 ||
                                            key[0] !== "heat_map") &&
                                        (tabslist !== 2 ||
                                            key[0] !== "contour_graph") && (
                                            <img
                                                src={imagesArr.iconclose}
                                                alt="close"
                                                className="d-inline"
                                                onClick={() =>
                                                    !isPrinting && this.props.removeFilter(key[0])
                                                }
                                            />
                                        )}
                                </span>
                            )
                        );
                    })}
                    {/* {sendReq.date_from !== "" && sendReq.date_to !== "" && (
                        <Link className="filtertags">
                            {moment(sendReq.date_from).format("DD/MM/YYYY")}
                            {" - "}
                            {moment(sendReq.date_to).format("DD/MM/YYYY")}
                            <img
                                src={imagesArr.iconclose}
                                alt="close" 
                                className="d-inline"
                                onClick={() => this.props.removeFilter("date_from")}
                            />
                        </Link>
                    )} */}
                    {sendReq && sendReq.date_from !== "" &&
                    (<span className="filtertags">
                        From : {moment(sendReq.date_from).format("MM/DD/YYYY")}
                        <img
                            src={imagesArr.iconclose}
                            alt="close"
                            className="d-inline"
                            onClick={() => !isPrinting && this.props.removeFilter("date_from")}
                        />
                    </span>)}
                    {sendReq && sendReq.date_to !== "" &&
                    (<span className="filtertags">
                        To : {moment(sendReq.date_to).format("MM/DD/YYYY")}
                        <img
                            src={imagesArr.iconclose}
                            alt="close"
                            className="d-inline"
                            onClick={() => !isPrinting && this.props.removeFilter("date_to")}
                        />
                    </span>)}
                    {/* {sendReq.velocity_to !== ""  && sendReq.velocity_from !== "" && (sendReq.velocity_from > 0 || sendReq.velocity_to < 100 )&& (
                        <span className="filtertags">
                            {" "}
                            Pitch Speed : {sendReq.velocity_from}mph - {sendReq.velocity_to}mph{" "}
                            <img
                                src={imagesArr.iconclose}
                                alt="close"
                                className="d-inline"
                                onClick={() => this.props.removeFilter("velocity_from")}
                            />{" "}
                        </span>
                    )} */}
                    {/* {sendReq.exit_velocity_to !== "" && sendReq.exit_velocity_from !== "" && (sendReq.exit_velocity_from > 0 || sendReq.exit_velocity_to < 120) && (
                        <span className="filtertags">
                            {" "}
                            Exit Velocity : {sendReq.exit_velocity_from}mph - {sendReq.exit_velocity_to}mph{" "}
                            <img
                                src={imagesArr.iconclose}
                                alt="close"
                                className="d-inline"
                                onClick={() => this.props.removeFilter("exit_velocity_from")}
                            />{" "}
                        </span>
                    )} */}
                    {range.map((r, i) => {
                        return (
                            <>
                                {sendReq && (sendReq[r.to] !== "" && sendReq[r.from] !== "" && (sendReq[r.from] > r.min || sendReq[r.to] < r.max) && (
                                    <span className="filtertags" key={i}>
                                        {" "}
                                        {r.name} : {sendReq[r.from]}mph - {sendReq[r.to]}mph{" "}
                                        <img
                                            src={imagesArr.iconclose}
                                            alt="close"
                                            className="d-inline"
                                            onClick={() => !isPrinting && this.props.removeFilter(r.from)}
                                        />{" "}
                                    </span>
                                ))}
                            </>
                        )
                    })}
                </div>
                {!isPrinting ? <div className="btnfilter w-30">
                    <a
                        className="btn white"
                        onClick={this.props.handleToggle}
                    >
                        <div className="d-none d-sm-block">
                            <sup>{arr.length}</sup>
                        </div>
                        <span>
                            <img
                                src={imagesArr.iconfilter}
                                alt="filter"
                                className="d-inline"
                            />
                        </span>
                        <span className="d-sm-none">
                            Filter<span>({arr.length})</span>
                        </span>
                    </a>
                    <div className="btnprint">
                        <a className="btn white"><span> <img src={imagesArr.printer} alt="print" onClick={() => print()} /></span> </a>
                    </div>
                </div> : ""}
            </div>
        );
    }
}

export default appliedFilter;
