import React from 'react'
import { constant } from '../../../../shared/constant';

function Zone({ zone_data, zone}) {
    return (
        <>
            <div className="w-50">
                <div className="gamesummary-table m-0">
                    <div className="table-responsive-xl">
                        <table className="table tabledashed">
                            <thead>
                                <tr>
                                    <th colSpan="2" className="one-child text-center">
                                        {zone}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    zone_data && zone_data.length !== 0 ?
                                        <>
                                            {zone_data.data.map((z, i) => {
                                                return (
                                                    <>
                                                        <tr key={i}>
                                                            <td style={{color: constant.pitchTypeColor[z.pitch_type]}} className="table-rowwhitesmoke">{z.pitch_type}</td>
                                                            <td className="table-rowwhitesmoke"></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="table-rowwhite">Swings</td>
                                                            <td className="table-rowwhite">{z.swing}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="table-rowwhite">Takes</td>
                                                            <td className="table-rowwhite">{z.takes}</td>
                                                        </tr>

                                                    </>
                                                );
                                            })}
                                            <tr>
                                                <td className="table-rowgrey80">Total Swings</td>
                                                <td className="table-rowgrey80">{zone_data?.total_swing}</td>
                                            </tr>
                                            <tr>
                                                <td className="table-rowgrey80">Total Takes</td>
                                                <td className="table-rowgrey80">{zone_data?.total_takes}</td>
                                            </tr>
                                        </>
                                        :
                                        <tr>
                                            <td className="table-rowwhite text-center" colSpan="2">
                                                There's no info yet!
                                            </td>
                                        </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Zone
