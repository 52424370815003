import React from 'react'
import { constant } from '../../shared/constant';

function ContactTabTable({ tableData, title }) {
    const renderList = () => {
        return tableData && tableData.length !== 0 ? (
            tableData.map((data, i) => {
                let rowColor = data._id === "Total" ? "table-rowoasis" : "table-rowwhite"
                return (
                    <tr key={i}>
                        <td className={rowColor} style={{ color: constant.pitchTypeColor[data._id] }}>
                            {data._id ? data._id : '—'}
                        </td>
                        <td className={rowColor}>
                            {data.perc ? data.perc.toString().includes(".") ? data.perc.toFixed(1) + "%" : data.perc + "%" : '0%'}
                        </td>
                        <td className={rowColor}>
                            {data.contact_height ? data.contact_height.toString().includes(".") ? data.contact_height.toFixed(2) : data.contact_height : '—'}
                        </td>
                        <td className={rowColor}>
                            {data.contact_side ? data.contact_side.toString().includes(".") ? data.contact_side.toFixed(2) : data.contact_side : '—'}
                        </td>
                        <td className={rowColor}>
                            {data.contact_depth ? data.contact_depth.toString().includes(".") ? data.contact_depth.toFixed(2) : data.contact_depth : '—'}
                        </td>
                        <td className={rowColor}>
                            {data.avg_ev ? data.avg_ev.toString().includes(".") ? data.avg_ev.toFixed(1) : data.avg_ev : '—'}
                        </td>
                        <td className={rowColor}>
                            {data.peak_ev ? data.peak_ev.toString().includes(".") ? data.peak_ev.toFixed(1) : data.peak_ev : '—'}
                        </td>
                        <td className={rowColor}>
                            {data.hard_hit ? data.hard_hit.toString().includes(".") ? data.hard_hit.toFixed(1) + "%" : data.hard_hit + "%" : '0%'}
                        </td>
                        <td className={rowColor}>
                            {data.damage ? data.damage.toString().includes(".") ? data.damage.toFixed(1) + "%" : data.damage + "%" : '0%'}
                        </td>
                        <td className={rowColor}>
                            {data.gb ? data.gb.toString().includes(".") ? data.gb.toFixed(1) + "%" : data.gb + "%" : '0%'}
                        </td>
                        <td className={rowColor}>
                            {data.ld ? data.ld.toString().includes(".") ? data.ld.toFixed(1) + "%" : data.ld + "%" : '0%'}
                        </td>
                        <td className={rowColor}>
                            {data.fb ? data.fb.toString().includes(".") ? data.fb.toFixed(1) + "%" : data.fb + "%" : '0%'}
                        </td>
                        <td className={rowColor}>
                            {data.pu ? data.pu.toString().includes(".") ? data.pu.toFixed(1) + "%" : data.pu + "%" : '0%'}
                        </td>
                    </tr>
                );
            })
        ) : (
            <tr>
                <td className="table-rowwhite text-center" colSpan="13">
                    There's no info yet!
                </td>
            </tr>
        );
    };

    return (
        <div className="table-responsive-xl">
            <table className="table tabledashed">
                <thead>
                    <tr>
                        <th colSpan="13" className="one-child text-center">{title}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="tablerowgray fw-500">Type</td>
                        <td className="tablerowgray fw-500">%</td>
                        <td className="tablerowgray fw-500">Contact Ht</td>
                        <td className="tablerowgray fw-500">Contact Side</td>
                        <td className="tablerowgray fw-500">Contact Depth</td>
                        <td className="tablerowgray fw-500">EV</td>
                        <td className="tablerowgray fw-500">Peak</td>
                        <td className="tablerowgray fw-500">Hard%</td>
                        <td className="tablerowgray fw-500">Damage%</td>
                        <td className="tablerowgray fw-500">GB%</td>
                        <td className="tablerowgray fw-500">LD%</td>
                        <td className="tablerowgray fw-500">FB%</td>
                        <td className="tablerowgray fw-500">PU%</td>
                    </tr>
                    {renderList()}
                </tbody>
            </table>
        </div>
    )
}

export default ContactTabTable
