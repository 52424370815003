import SwingSummaryTable from './SwingSummaryTable';
import SwingLogTable from './SwingSummaryTable';
import PlotChartFirst from '../../../components/Charts/PlotChart';
import PlotChartSecond from '../../../components/Charts/PlotChart';
import PlotChartThird from '../../../components/Charts/PlotChart';
import RollingMainChart from '../../../components/Charts/RollingMainChart';

function SessionPlayer({ keyName, splineChartData, metricHandleChange, spline_chart, plot_f_x, plot_f_y, plot_s_x, plot_s_y, plot_t_x, plot_t_y, pitcherList, scatterList, index, isprint, isprintAll, isNotDownloadImgLoader, isDownloadImgLoader, eventName }) {

  return (
    <>
      <div className="container-fluid" id="">
        <div className="text-center">
          <h3 className="table-title">BLAST SESSION SUMMARY</h3>
        </div>
        <div className="blast-summary-result-table">
          <div className="table-responsive-xl">
            <SwingSummaryTable
              tableData={[...pitcherList[keyName], ...pitcherList["Session Average"]] || []}
              type="BlastTable"
            />
          </div>
        </div>
        {/* polt chart */}
        <div className="text-center">
          <h3 className="table-title">PLOT CHARTS</h3>
        </div>
        <div className="common-charts">
          <div className={`row ${!(isprint || isprintAll) ? 'singleDiv' : 'breakDiv'}`}>
            <div className="w-33">
              <div className="ground-chart">
                <PlotChartFirst
                  chartData={scatterList[keyName][0]?.data || []}
                  metricHandleChange={metricHandleChange}
                  plot_x={plot_f_x}
                  plot_y={plot_f_y}
                  index={index}
                  id={`strikeZoneChartfirst${index || ""}`}
                  type="poltFirst"
                  showTitle={true}
                  pitcherDefault={keyName}
                  profilepage={false}
                  eventName={eventName}
                  isNotDownloadImgLoader={isNotDownloadImgLoader}
                  isDownloadImgLoader={isDownloadImgLoader} />
              </div>
            </div>
            <div className="w-33">
              <div className="ground-chart">
                <PlotChartSecond
                  chartData={scatterList[keyName][0]?.data || []}
                  metricHandleChange={metricHandleChange}
                  plot_x={plot_s_x}
                  plot_y={plot_s_y}
                  index={index}
                  id={`strikeZoneChartsecond${index || ""}`}
                  type="poltSecond"
                  showTitle={true}
                  pitcherDefault={keyName}
                  profilepage={false}
                  eventName={eventName}
                  isNotDownloadImgLoader={isNotDownloadImgLoader}
                  isDownloadImgLoader={isDownloadImgLoader} />
              </div>
            </div>
            <div className="w-33">
              <div className="ground-chart">
                <PlotChartThird
                  chartData={scatterList[keyName][0]?.data || []}
                  metricHandleChange={metricHandleChange}
                  plot_x={plot_t_x}
                  plot_y={plot_t_y}
                  index={index}
                  id={`strikeZoneChartthird${index || ""}`}
                  type="poltThird"
                  showTitle={true}
                  pitcherDefault={keyName}
                  profilepage={false}
                  eventName={eventName}
                  isNotDownloadImgLoader={isNotDownloadImgLoader}
                  isDownloadImgLoader={isDownloadImgLoader} />
              </div>
            </div>
          </div>
        </div>
        {/* charts */}
        <div className="text-center">
          <h3 className="table-title">ROLLING CHART</h3>
        </div>
        <div className="common-charts" id="mainChartPrintArea">
          <RollingMainChart
            id={`BlastChart${index || ""}`}
            chartData={splineChartData[keyName][0]?.data || []}
            metricHandleChange={metricHandleChange}
            spline_chart={spline_chart}
            pitcherDefault={keyName}
            profilepage={false}
            eventName={eventName}
            isNotDownloadImgLoader={isNotDownloadImgLoader}
            isDownloadImgLoader={isDownloadImgLoader} />
        </div>
      </div>
      <div className="text-center">
        <h3 className="table-title">SWING LOG</h3>
      </div>
      <div className="blast-summary-result-table">
        <div className="table-responsive-xl">
          <SwingLogTable
            tableData={splineChartData[keyName][0]?.data || []}
            type="swinglog"
          />
        </div>
      </div>
    </>
  );
}

export default SessionPlayer;