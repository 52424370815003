import React, { Component } from "react";
import { connect } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Link } from "react-router-dom";
import quillEmoji from "quill-emoji";
import "quill-emoji/dist/quill-emoji.css";
import { getPlanData, setPlanData } from "../../../store/actions";
import { successMessage } from "../../../shared/function";
import { ToastContainer } from "react-toastify";

const Quill = ReactQuill.Quill;
var Font = Quill.import("formats/font");
Font.whitelist = ["Roboto", "Raleway", "Montserrat", "Lato", "Rubik"];

const { EmojiBlot, ShortNameEmoji, ToolbarEmoji, TextAreaEmoji } = quillEmoji;

Quill.register(
  {
    "formats/emoji": EmojiBlot,
    "modules/emoji-shortname": ShortNameEmoji,
    "modules/emoji-toolbar": ToolbarEmoji,
    "modules/emoji-textarea": TextAreaEmoji,
    Font
  },
  true
);

const icons = Quill.import("ui/icons");
icons["undo"] =
  '<svg viewbox="0 0 18 18"><polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon>' +
  '<path class="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path></svg>';
icons["redo"] =
  '<svg viewbox="0 0 18 18"><polygon class="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10"></polygon>' +
  '<path class="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"></path></svg>';

class plan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorHtml: "",
      theme: "snow",
      sendReqData: {
        player_id: this.props.pid,
        plan: "",
      },
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    let data = {
      player_id: this.state.sendReqData.player_id,
    };
    this.props.fetchPlanData(data, (res) => {
      this.setState((prevState) => ({
        ...prevState,
        sendReqData: {
          ...prevState.sendReqData,
          plan: res.plan,
        },
      }));
    });
  }

  handleChange(value) {
    this.setState((prevState) => ({
      ...prevState,
      sendReqData: {
        ...prevState.sendReqData,
        plan: value,
      },
    }));
  }

  modules = {
    history: {
      delay: 2000,
      userOnly: true,
    },
    toolbar: {
      container: [
        [{ header: [1, 2, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        // [{ script: "sub" }, { script: "super" }],
        // [{ direction: "rtl" }],
        // ["link", "image", "video"],
        [{ color: [] }, { background: [] }],
        // [{ font: [] }],
        // [{ font: Font.whitelist }],
        [{ align: [] }],
        // ["clean"],
        // ["emoji"],
        ["redo", "undo"],
      ],
      handlers: {
        undo: function () {
          this.quill.history.undo();
        },
        redo: function () {
          this.quill.history.redo();
        },
      },
    },
    "emoji-toolbar": true,
    "emoji-textarea": false,
    "emoji-shortname": true,
  };
  formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    // "link",
    // "image",
    // "video",
    // "emoji",
    "color",
    // "font",
    "background",
    // "script",
    // "direction",
    "align"
  ];

  sendReq = () => {
    this.props.sendPlanData(this.state.sendReqData, (res) => {
      this.setState(
        (prevState) => ({
          ...prevState,
          sendReqData: {
            ...prevState.sendReqData,
            plan: res.plan,
          },
        }),
        () => {
          successMessage("Plan Updated");
        }
      );
    });
  };

  render() {
    const { sendReqData } = this.state;
    const { showButton } = this.props;
    return (
      <>
        <div className="editorview white-bg">
          <ReactQuill
            value={sendReqData.plan}
            onChange={this.handleChange}
            theme={this.state.theme}
            modules={this.modules}
            formats={this.formats}
          />
          {showButton && (
            <a
              className="btn blue mt-15 btnshadow"
              onClick={() => this.sendReq()}
            >
              {" "}
              Submit{" "}
            </a>
          )}
        </div>
        <ToastContainer />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    savePlanData: state.app.savePlanData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  sendPlanData: (data, next) => dispatch(setPlanData(data, next)),
  fetchPlanData: (data, next) => dispatch(getPlanData(data, next)),
});
export default connect(mapStateToProps, mapDispatchToProps)(plan);
