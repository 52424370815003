import React, { Component } from 'react'
import Highcharts from 'highcharts'
import Bubble from 'highcharts/highcharts-more.js';
import SplineVerticalChart from "./component/splineVerticalChart";
import SplineIndVerticalChart from "./component/splineVerticalChart";
import SplineHorizontalChart from "./component/splineHorizontalChart";
import ProfileLongBreakMovementChart from "../../../components/Charts/breakMovementChart";
import ProfileShortBreakMovementChart from "../../../components/Charts/breakMovementChart";
import PolarChartProfile from "../../../components/Charts/PolarChart"
import { constant } from '../../../shared/constant';
import { xformBreakMovementChart } from '../../../shared/function';
Bubble(Highcharts);

class breakmovement extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    getHBreak = (type) => {
        let hBreak = this.props.filterRes.horizontal_movement_table_data;
        var isData = "";
        hBreak.length > 0 &&
            hBreak.forEach((data) => {
                if (data._id === type) return (isData = data)
            });
        return isData;
    }

    getBreakMovement = (type) => {
        let breakMovement = this.props.filterRes.vertical_movement_table_data;
        var isData = "";
        breakMovement.length > 0 &&
            breakMovement.forEach((data) => {
                if (data._id === type) return (isData = data)
            });
        return isData;
    }

    renderTable = () => {
        return (
            this.props.filterRes &&
            this.props.filterRes.break_movement_table_data &&
            this.props.filterRes.break_movement_table_data.map((list, i) => {
                return (
                    <tr>
                        <td className="table-rowwhite" style={{ color: constant.pitchTypeColor[list._id] }}>{list._id !== null ? list._id : "—"}</td>
                        <td className="table-rowwhite">{list.count !== null ? list.count : "—"}</td>
                        <td className="table-rowwhite">{list.velocity ? list.velocity.toString().includes(".") ? list.velocity.toFixed(1) : list.velocity : "—"}</td>
                        <td className="table-rowwhite">{list.spin_rate ? Math.round(list.spin_rate) : "—"}</td>
                        <td className="table-rowwhite">{list.efficiency ? Math.round(list.efficiency) + '%' : "—"}</td>
                        <td className="table-rowwhite">{list.spin_tilt !== null ? list.spin_tilt : "—"}</td>
                        <td className="table-rowwhite">{list.spin_axis ? Math.round(list.spin_axis) : "—"}</td>
                        <td className="table-rowwhite">{list.v_break ? list.v_break.toString().includes(".") ? list.v_break.toFixed(1) : list.v_break : "—"}</td>
                        <td className="table-rowwhite">{list.ind_v_break ? list.ind_v_break.toString().includes(".") ? list.ind_v_break.toFixed(1) : list.ind_v_break : "—"}</td>
                        <td className="table-rowwhite">{list.h_break ? list.h_break.toString().includes(".") ? list.h_break.toFixed(1) : list.h_break : "—"}</td>
                        <td className="table-rowwhite">{list.vertical_approach_angle ? list.vertical_approach_angle.toString().includes(".") ? list.vertical_approach_angle.toFixed(1) : list.vertical_approach_angle : "—"}</td>
                        <td className="table-rowwhite">{list.horizontal_approach_angle ? list.horizontal_approach_angle.toString().includes(".") ? list.horizontal_approach_angle.toFixed(1) : list.horizontal_approach_angle : "—"}</td>
                    </tr>
                )
            })
        )
    }

    render() {
        const bubbleChartDataShort =
            this.props.filterRes.bubble_chart_data_short ? this.props.filterRes.bubble_chart_data_short.length !== 0
                ? xformBreakMovementChart(this.props.filterRes.bubble_chart_data_short, 'pitch_type')
                : [] : [];

        const bubbleChartDataLong =
            this.props.filterRes.bubble_chart_data_long ? this.props.filterRes.bubble_chart_data_long.length !== 0
                ? xformBreakMovementChart(this.props.filterRes.bubble_chart_data_long, 'pitch_type')
                : [] : [];

        const dotChartDataShort =
            this.props.filterRes.dot_chart_data_short && this.props.filterRes.dot_chart_data_short.length !== 0
                ? xformBreakMovementChart(this.props.filterRes.dot_chart_data_short, 'pitch_type')
                : [];
        const dotChartDataLong =
            this.props.filterRes.dot_chart_data_long && this.props.filterRes.dot_chart_data_long.length !== 0
                ? xformBreakMovementChart(this.props.filterRes.dot_chart_data_long, 'pitch_type')
                : [];
        return (
            <>
                <div className="d-flex">
                    <div className="w-100">
                        <div className="container-fluid">
                            <div className='common-charts'>
                                <div className={`row ${!this.props.isPrinting ? 'singleDiv' : 'breakDiv'}`}>
                                    <div className='w-33'>
                                        {
                                            this.props.filterRes &&
                                            <ProfileLongBreakMovementChart
                                                id="longBreakMovementChartProfile"
                                                form="Long-Form"
                                                top={55}
                                                bottom={15}
                                                className="breakMovementChart"
                                                bubbleChartData={bubbleChartDataLong}
                                                dotChartData={dotChartDataLong}
                                                eventProfileData={this.props.eventProfileData}
                                                sendReq={this.props?.sendReq}
                                                tabslist={this.props?.tabslist}
                                                profilepage={true}
                                                isNotDownloadImgLoader={this.props.isNotDownloadImgLoader}
                                                isDownloadImgLoader={this.props.isDownloadImgLoader} />
                                        }
                                    </div>
                                    <div className='w-33'>
                                        {
                                            this.props.filterRes &&
                                            <ProfileShortBreakMovementChart
                                                id="shortBreakMovementChartProfile"
                                                form="Short-Form"
                                                top={30}
                                                bottom={55}
                                                className="breakMovementChart"
                                                bubbleChartData={bubbleChartDataShort}
                                                dotChartData={dotChartDataShort}
                                                eventProfileData={this.props.eventProfileData}
                                                sendReq={this.props?.sendReq}
                                                tabslist={this.props?.tabslist}
                                                profilepage={true}
                                                isNotDownloadImgLoader={this.props.isNotDownloadImgLoader}
                                                isDownloadImgLoader={this.props.isDownloadImgLoader} />
                                        }
                                    </div>
                                    <div className='w-33'>
                                        {
                                            this.props.filterRes &&
                                            <PolarChartProfile
                                                chartData={this.props.filterRes.pitcher_polar_movement_chart_data}
                                                id="polarChartchartProfile"
                                                eventProfileData={this.props.eventProfileData}
                                                sendReq={this.props?.sendReq}
                                                tabslist={this.props?.tabslist}
                                                profilepage={true}
                                                isNotDownloadImgLoader={this.props.isNotDownloadImgLoader}
                                                isDownloadImgLoader={this.props.isDownloadImgLoader} />
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive-xl">
                                <table className="table tabledashed">
                                    <thead>
                                        <tr>
                                            <th colSpan="5" className="text-center"></th>
                                            <th colSpan="2" className="text-center">Spin Direction</th>
                                            <th colSpan="3" className="text-center">Break and Movement</th>
                                            <th colSpan="2" className="text-center">Approach Angle</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="tablerowgray fw-500">Pitch Type  </td>
                                            <td className="tablerowgray fw-500">#</td>
                                            <td className="tablerowgray fw-500">Velo</td>
                                            <td className="tablerowgray fw-500">Spin</td>
                                            <td className="tablerowgray fw-500">Efficiency</td>
                                            <td className="tablerowgray fw-500">Tilt</td>
                                            <td className="tablerowgray fw-500">Axis</td>
                                            <td className="tablerowgray fw-500">V Break</td>
                                            <td className="tablerowgray fw-500">Induced V</td>
                                            <td className="tablerowgray fw-500">H Break</td>
                                            <td className="tablerowgray fw-500">V Approach </td>
                                            <td className="tablerowgray fw-500">H Approach</td>
                                        </tr>
                                        {this.renderTable()}
                                        {
                                            this.props.filterRes &&
                                            this.props.filterRes.break_movement_table_data &&
                                            this.props.filterRes.break_movement_table_data.length === 0 &&
                                            <tr>
                                                <td colSpan="12" className="text-center table-rowwhite">There's is no Info yet!</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className="common-charts">
                                <div className={`row ${!this.props.isPrinting ? 'singleDiv' : 'breakDiv'}`}>
                                    <div className="w-33">
                                        {
                                            this.props.filterRes &&
                                            <SplineVerticalChart
                                                title="V Break"
                                                chartData={this.props.filterRes.v_break_movement_spline_chart_series}
                                                id="splineVerticalChart"
                                                max={0}
                                                min={-90}
                                                eventProfileData={this.props.eventProfileData}
                                                sendReq={this.props?.sendReq}
                                                tabslist={this.props?.tabslist}
                                                profilepage={true}
                                                isNotDownloadImgLoader={this.props.isNotDownloadImgLoader}
                                                isDownloadImgLoader={this.props.isDownloadImgLoader} />
                                        }
                                    </div>
                                    <div className="w-33">
                                        {
                                            this.props.filterRes &&
                                            <SplineIndVerticalChart
                                                title="Induced V Break"
                                                chartData={this.props.filterRes.vertical_movement_spline_chart_series}
                                                id="splineIndVerticalChart"
                                                max={30}
                                                min={-30}
                                                eventProfileData={this.props.eventProfileData}
                                                sendReq={this.props?.sendReq}
                                                tabslist={this.props?.tabslist}
                                                profilepage={true}
                                                isNotDownloadImgLoader={this.props.isNotDownloadImgLoader}
                                                isDownloadImgLoader={this.props.isDownloadImgLoader} />
                                        }
                                    </div>
                                    <div className="w-33">
                                        {
                                            this.props.filterRes &&
                                            <SplineHorizontalChart
                                                chartData={this.props.filterRes.horizontal_movement_spline_chart_series}
                                                eventProfileData={this.props.eventProfileData}
                                                sendReq={this.props?.sendReq}
                                                tabslist={this.props?.tabslist}
                                                profilepage={true}
                                                isNotDownloadImgLoader={this.props.isNotDownloadImgLoader}
                                                isDownloadImgLoader={this.props.isDownloadImgLoader} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default breakmovement;
