import React, { Component } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official';
import BasicSpray from '../../../shared/SprayChartd3'
import * as d3 from 'd3';
import 'highcharts/modules/heatmap.src.js';
import Heatmap from 'highcharts/modules/heatmap.js';
// import StrikeZoneChart from "./component/strikeZoneChart";
import StrikeZoneChart from "../Chart/strikeZoneChart";
import SpyayChart from "./component/spyayChart";
import HeatMapChart from "./component/heatMapChart";
import EVHeatMapChart from "./component/heatMapChart";
import HardHitHeatMapChart from "./component/heatMapChart";
import DamageHeatMapChart from "./component/heatMapChart";
import ReactTooltip from 'react-tooltip';
import { constant } from '../../../shared/constant';
import PointSprayChart from '../ContactTab/spyayChart'
import DotRangeSpyayChart from '../../hittersummary/component/hitterDotRangeSpyayChart';
import EVSpyayChart from '../../hittersummary/component/hitterSpyayChart';
import CatcherView from '../../../components/Charts/ViewChart';
import TopView from '../../../components/Charts/ViewChart';
import SideView from '../../../components/Charts/ViewChart';
import ContactCatcherView from '../../../components/Charts/ViewChart';
import ContactTopView from '../../../components/Charts/ViewChart';
import ContactSideView from '../../../components/Charts/ViewChart';
import PitchCatcherView from '../../../components/Charts/ViewChart';
import PitchTopView from '../../../components/Charts/ViewChart';
import PitchSideView from '../../../components/Charts/ViewChart';
import PlayCatcherView from '../../../components/Charts/ViewChart';
import PlayTopView from '../../../components/Charts/ViewChart';
import PlaySideView from '../../../components/Charts/ViewChart';
import { getSprayChartRangeColor } from '../../../shared/function';
import HistoGram from './component/HistoGramChart';
Heatmap(Highcharts);

class battedball extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ViewColor: "exit_velocity",
            ViewColor_1: "hit_angle_type",
            ViewColor_2: "pitch_type",
            ViewColor_3: "pitch_call",
            coloredBy: "all_one_color",
            y_axis: "count"
        }
    }

    componentDidMount = () => {
        this.renderChart()
    }

    renderChart = () => {

        const selection = d3.select('#groundChart');
        if (!selection.empty()) {
            const sprayChart = new BasicSpray(selection, constant.softball);
            const data = [
                {
                    "dr": 27.231,
                    "ds": 279.313
                }, {
                    "dr": 13.221,
                    "ds": 289.428
                }, {
                    "dr": 25.65,
                    "ds": 100.5
                }
            ]
            sprayChart.update(data);
        }
    }

    getExitVelocity = (type) => {
        let exitVeloData = [...(this.props.filterRes && this.props.filterRes.bbp_pitch_table_data_query || []), ...(this.props.filterRes && this.props.filterRes.bbp_table_data_query || [])]
        var isData = "";
        exitVeloData.length > 0 &&
            exitVeloData.forEach((data) => {
                if (data.season === type || data._id === type) return (isData = data)
            });
        return isData;
    }

    renderTable = () => {
        let batted_ball_table_Data = [...(this.props.filterRes && this.props.filterRes.bbp_pitch_by_field_table_data_query || []), ...(this.props.filterRes && this.props.filterRes.bbp_by_field_table_data_query || [])]
        return (
            batted_ball_table_Data.length !== 0 &&
            batted_ball_table_Data.map((list, i) => {

                let rowColor = ""
                if (list.season === "Total") {
                    rowColor = "table-rowoasis"
                } else if (list.season === "D1 Average" || list.season === "Indy Ball") {
                    rowColor = "table-rowgrey80"
                } else if (list.season === "Team Average") {
                    rowColor = "table-rowwhitesmoke"
                } else {
                    rowColor = "table-rowwhite"
                }

                return (
                    <tr key={i}>
                        <td className={`${rowColor}`} style={{ color: constant.pitchTypeColor[list._id] }}>{list.hasOwnProperty('_id') ? list._id ? list._id : "—" : list.season !== null ? list.season : "—"}</td>
                        <td className={`${rowColor}`}>{list.season === "D1 Average" ? " " : list['#'] ? list['#'] : "—"}</td>
                        <td className={`${rowColor}`}>{this.getExitVelocity(list.season ? list.season : list._id) ? this.getExitVelocity(list.season ? list.season : list._id).avg_ev ? this.getExitVelocity(list.season ? list.season : list._id).avg_ev.toString().includes(".") ? this.getExitVelocity(list.season ? list.season : list._id).avg_ev.toFixed(1) : this.getExitVelocity(list.season ? list.season : list._id).avg_ev : "—" : "—"}</td>
                        <td className={`${rowColor}`}>{this.getExitVelocity(list.season ? list.season : list._id) ? this.getExitVelocity(list.season ? list.season : list._id).peak_ev ? this.getExitVelocity(list.season ? list.season : list._id).peak_ev.toString().includes(".") ? this.getExitVelocity(list.season ? list.season : list._id).peak_ev.toFixed(1) : this.getExitVelocity(list.season ? list.season : list._id).peak_ev : "—" : "—"}</td>
                        <td className={`${rowColor}`}>{this.getExitVelocity(list.season ? list.season : list._id) ? this.getExitVelocity(list.season ? list.season : list._id).avg_la ? this.getExitVelocity(list.season ? list.season : list._id).avg_la.toString().includes(".") ? this.getExitVelocity(list.season ? list.season : list._id).avg_la.toFixed(1) : this.getExitVelocity(list.season ? list.season : list._id).avg_la : "—" : "—"}</td>
                        {/* <td className={`${rowColor}`}>{this.getExitVelocity(list.season ? list.season : list._id) ? this.getExitVelocity(list.season ? list.season : list._id).peak_distance ? Math.round(this.getExitVelocity(list.season ? list.season : list._id).peak_distance) : "—" : "—"}</td> */}
                        <td className={`${rowColor}`}>{this.getExitVelocity(list.season ? list.season : list._id) ? this.getExitVelocity(list.season ? list.season : list._id).hard_hit_la ? this.getExitVelocity(list.season ? list.season : list._id).hard_hit_la.toString().includes(".") ? this.getExitVelocity(list.season ? list.season : list._id).hard_hit_la.toFixed(1) : this.getExitVelocity(list.season ? list.season : list._id).hard_hit_la : "—" : "—"}</td>
                        <td className={`${rowColor}`}>{this.getExitVelocity(list.season ? list.season : list._id) ? this.getExitVelocity(list.season ? list.season : list._id).hard_hit ? this.getExitVelocity(list.season ? list.season : list._id).hard_hit.toString().includes(".") ? this.getExitVelocity(list.season ? list.season : list._id).hard_hit.toFixed(1) + "%" : this.getExitVelocity(list.season ? list.season : list._id).hard_hit + "%" : "—" : "—"}</td>
                        <td className={`${rowColor}`}>{this.getExitVelocity(list.season ? list.season : list._id) ? this.getExitVelocity(list.season ? list.season : list._id).damage ? this.getExitVelocity(list.season ? list.season : list._id).damage.toString().includes(".") ? this.getExitVelocity(list.season ? list.season : list._id).damage.toFixed(1) + "%" : this.getExitVelocity(list.season ? list.season : list._id).damage + "%" : "—" : "—"}</td>
                        <td className={`${rowColor}`}>{list.gb ? list.gb.toString().includes(".") ? list.gb.toFixed(1) + "%" : list.gb + "%" : "—"}</td>
                        <td className={`${rowColor}`}>{list.ld ? list.ld.toString().includes(".") ? list.ld.toFixed(1) + "%" : list.ld + "%" : "—"}</td>
                        <td className={`${rowColor}`}>{list.fb ? list.fb.toString().includes(".") ? list.fb.toFixed(1) + "%" : list.fb + "%" : "—"}</td>
                        <td className={`${rowColor}`}>{list.pu ? list.pu.toString().includes(".") ? list.pu.toFixed(1) + "%" : list.pu + "%" : "—"}</td>
                        <td className={`${rowColor}`}>{list["gb/fb"] ? list["gb/fb"].toString().includes(".") ? list["gb/fb"].toFixed(2) : list["gb/fb"] : "—"}</td>
                    </tr>
                )
            })
        )
    }

    renderTableField = () => {
        let batted_ball_table_Data = [...(this.props.filterRes && this.props.filterRes.bbp_pitch_by_field_table_data_query || []), ...(this.props.filterRes && this.props.filterRes.bbp_by_field_table_data_query || [])]
        return (
            batted_ball_table_Data.length !== 0 &&
            batted_ball_table_Data.map((list, i) => {

                let rowColor = ""
                if (list.season === "Total") {
                    rowColor = "table-rowoasis"
                } else if (list.season === "D1 Average" || list.season === "Indy Ball") {
                    rowColor = "table-rowgrey80"
                } else if (list.season === "Team Average") {
                    rowColor = "table-rowwhitesmoke"
                } else {
                    rowColor = "table-rowwhite"
                }

                return (
                    <tr key={i}>
                        <td className={`${rowColor}`} style={{ color: constant.pitchTypeColor[list._id] }}>{list.hasOwnProperty('_id') ? list._id ? list._id : "—" : list.season !== null ? list.season : "—"}</td>
                        <td className={`${rowColor}`}>{list.season === "D1 Average" ? " " : list['#'] ? list['#'] : "—"}</td>
                        <td className={`${rowColor}`}>{list.pull ? list.pull.toString().includes(".") ? list.pull.toFixed(1) + "%" : list.pull + "%" : "—"}</td>
                        <td className={`${rowColor}`}>{list.avg_ev_pull ? list.avg_ev_pull.toString().includes(".") ? list.avg_ev_pull.toFixed(1) : list.avg_ev_pull : "—"}</td>
                        <td className={`${rowColor}`}>{list.avg_la_pull ? list.avg_la_pull.toString().includes(".") ? list.avg_la_pull.toFixed(1) : list.avg_la_pull : "—"}</td>
                        <td className={`${rowColor}`}>{list.middle ? list.middle.toString().includes(".") ? list.middle.toFixed(1) + "%" : list.middle + "%" : "—"}</td>
                        <td className={`${rowColor}`}>{list.avg_ev_middle ? list.avg_ev_middle.toString().includes(".") ? list.avg_ev_middle.toFixed(1) : list.avg_ev_middle : "—"}</td>
                        <td className={`${rowColor}`}>{list.avg_la_middle ? list.avg_la_middle.toString().includes(".") ? list.avg_la_middle.toFixed(1) : list.avg_la_middle : "—"}</td>
                        <td className={`${rowColor}`}>{list.oppo ? list.oppo.toString().includes(".") ? list.oppo.toFixed(1) + "%" : list.oppo + "%" : "—"}</td>
                        <td className={`${rowColor}`}>{list.avg_ev_oppo ? list.avg_ev_oppo.toString().includes(".") ? list.avg_ev_oppo.toFixed(1) : list.avg_ev_oppo : "—"}</td>
                        <td className={`${rowColor}`}>{list.avg_la_oppo ? list.avg_la_oppo.toString().includes(".") ? list.avg_la_oppo.toFixed(1) : list.avg_la_oppo : "—"}</td>
                        <td className={`${rowColor}`}>{this.getExitVelocity(list.season ? list.season : list._id) ? this.getExitVelocity(list.season ? list.season : list._id).perc_ifh ? this.getExitVelocity(list.season ? list.season : list._id).perc_ifh.toString().includes(".") ? this.getExitVelocity(list.season ? list.season : list._id).perc_ifh.toFixed(1) + "%" : this.getExitVelocity(list.season ? list.season : list._id).perc_ifh + "%" : "—" : "—"}</td>
                    </tr>
                )
            })
        )
    }

    renderTableContact = () => {
        let tableData = this.props.filterRes.point_of_contact_table_data || []
        return tableData && tableData.length !== 0 && (
            tableData.map((data, i) => {
                let rowColor = data._id === "Total" ? "table-rowoasis" : "table-rowwhite"
                return (
                    <tr key={i}>
                        <td className={rowColor} style={{ color: constant.pitchTypeColor[data._id] }}>
                            {data._id ? data._id : '—'}
                        </td>
                        <td className={rowColor}>
                            {data.count ? data.count : '-'}
                        </td>
                        <td className={rowColor}>
                            {data.perc ? data.perc.toString().includes(".") ? data.perc.toFixed(1) + "%" : data.perc + "%" : '0%'}
                        </td>
                        <td className={rowColor}>
                            {data.contact_height ? data.contact_height.toString().includes(".") ? data.contact_height.toFixed(2) : data.contact_height : '—'}
                        </td>
                        <td className={rowColor}>
                            {data.contact_side ? data.contact_side.toString().includes(".") ? data.contact_side.toFixed(2) : data.contact_side : '—'}
                        </td>
                        <td className={rowColor}>
                            {data.contact_depth ? data.contact_depth.toString().includes(".") ? data.contact_depth.toFixed(2) : data.contact_depth : '—'}
                        </td>
                        <td className={rowColor}>
                            {data.pull_contact_height ? data.pull_contact_height.toString().includes(".") ? data.pull_contact_height.toFixed(2) : data.pull_contact_height : '—'}
                        </td>
                        <td className={rowColor}>
                            {data.pull_contact_side ? data.pull_contact_side.toString().includes(".") ? data.pull_contact_side.toFixed(2) : data.pull_contact_side : '—'}
                        </td>
                        <td className={rowColor}>
                            {data.pull_contact_depth ? data.pull_contact_depth.toString().includes(".") ? data.pull_contact_depth.toFixed(2) : data.pull_contact_depth : '—'}
                        </td>
                        <td className={rowColor}>
                            {data.middle_contact_height ? data.middle_contact_height.toString().includes(".") ? data.middle_contact_height.toFixed(2) : data.middle_contact_height : '—'}
                        </td>
                        <td className={rowColor}>
                            {data.middle_contact_side ? data.middle_contact_side.toString().includes(".") ? data.middle_contact_side.toFixed(2) : data.middle_contact_side : '—'}
                        </td>
                        <td className={rowColor}>
                            {data.middle_contact_depth ? data.middle_contact_depth.toString().includes(".") ? data.middle_contact_depth.toFixed(2) : data.middle_contact_depth : '—'}
                        </td>
                        <td className={rowColor}>
                            {data.oppo_contact_height ? data.oppo_contact_height.toString().includes(".") ? data.oppo_contact_height.toFixed(2) : data.oppo_contact_height : '—'}
                        </td>
                        <td className={rowColor}>
                            {data.oppo_contact_side ? data.oppo_contact_side.toString().includes(".") ? data.oppo_contact_side.toFixed(2) : data.oppo_contact_side : '—'}
                        </td>
                        <td className={rowColor}>
                            {data.oppo_contact_depth ? data.oppo_contact_depth.toString().includes(".") ? data.oppo_contact_depth.toFixed(2) : data.oppo_contact_depth : '—'}
                        </td>
                    </tr>
                );
            })
        )
    }

    HandleChangePitchColor = (e) => {
        const { name, value } = e.target
        this.setState({
            [name]: value
        })
    }

    render() {
        return (
            <>
                <div className="d-flex">
                    <div className="w-100">
                        <div className="container-fluid">
                            <div className="common-charts">
                                <div className={`row ${!this.props.isPrinting ? 'singleDiv' : 'breakDiv'}`}>
                                    <div className="w-33">
                                        {
                                            this.props.filterRes &&
                                            <StrikeZoneChart
                                                chartData={this.props.filterRes.batted_ball_scatter_plot}
                                                setScatterAxis={this.props.setScatterAxis}
                                                onResetScatterFilter={this.props.applyFilter}
                                                oldData={this.props.oldData}
                                                cameraAngle={["Strike Zone", "Catcher", "Home Top", "Home 3B Side", "Home 1B Side", "Press Box"]}
                                                visualType="Hitting"
                                                eventProfileData={this.props.eventProfileData}
                                                sendReq={this.props?.sendReq}
                                                tabslist={this.props?.tabslist}
                                                profilepage={true}
                                                isNotDownloadImgLoader={this.props.isNotDownloadImgLoader}
                                                isDownloadImgLoader={this.props.isDownloadImgLoader}
                                                pitchrIframeFlag={this.props.pitchrIframeFlag}
                                                closePitchrIframe={this.props.closePitchrIframe} />
                                        }
                                    </div>
                                    <div className="w-33">
                                        {
                                            this.props.filterRes &&
                                            <SpyayChart
                                                chartData={this.props.filterRes.batted_ball_spray_chart_series}
                                                cameraAngle={["Press Box", "Spray Chart", "First Base", "Third Base"]}
                                                visualType="Hitting"
                                                eventProfileData={this.props.eventProfileData}
                                                sendReq={this.props?.sendReq}
                                                tabslist={this.props?.tabslist}
                                                profilepage={true}
                                                isNotDownloadImgLoader={this.props.isNotDownloadImgLoader}
                                                isDownloadImgLoader={this.props.isDownloadImgLoader}
                                                pitchrIframeFlag={this.props.pitchrIframeFlag}
                                                closePitchrIframe={this.props.closePitchrIframe} />
                                        }
                                    </div>
                                    <div className="w-33">
                                        {this.props.filterRes &&
                                            <HeatMapChart
                                                chartData={this.props.filterRes.batted_ball_heat_maps}
                                                id="HeatMapChart"
                                                heat_map={this.props.heat_map_0}
                                                onChangeEvent={this.props.onChangeEvent}
                                                eventProfileData={this.props.eventProfileData}
                                                sendReq={this.props?.sendReq}
                                                tabslist={this.props?.tabslist}
                                                profilepage={true}
                                                isNotDownloadImgLoader={this.props.isNotDownloadImgLoader}
                                                isDownloadImgLoader={this.props.isDownloadImgLoader} />
                                        }
                                    </div>
                                </div>
                            </div>

                            {/* batted ball table */}
                            <div className="table-responsive-xl">
                                <table className="table tabledashed">
                                    <thead>
                                        <tr>
                                            <th colSpan="13" className="text-center">Batted Ball</th>
                                            {/* <th colSpan="3" className="text-center">Launch Angle</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="tablerowgray fw-500">Type</td>
                                            <td className="tablerowgray fw-500">#</td>
                                            <td className="tablerowgray fw-500">EV</td>
                                            <td className="tablerowgray fw-500">Peak EV</td>
                                            <td className="tablerowgray fw-500">LA</td>
                                            {/* <td className="tablerowgray fw-500">Peak Dist</td> */}
                                            <td className="tablerowgray fw-500" data-tip={constant.softball ? "Avg LA of batted balls ≥ 65mph" : "Avg LA of batted balls ≥ 90mph"}><ReactTooltip place="top" type="dark" effect="solid" offset={{ top: -20, left: 0 }} />Hard Hit LA</td>
                                            <td className="tablerowgray fw-500" data-tip={constant.softball ? "EV ≥ 60" : "EV ≥ 90"}>Hard Hit %</td>
                                            <td className="tablerowgray fw-500" data-tip={constant.softball ? " EV ≥ 60,LA ≥ 10" : "EV ≥ 90,LA ≥ 10"}><ReactTooltip place="top" type="dark" effect="solid" offset={{ top: -20, left: 0 }} />Damage %</td>
                                            <td className="tablerowgray fw-500">GB %</td>
                                            <td className="tablerowgray fw-500">LD %</td>
                                            <td className="tablerowgray fw-500">FB %</td>
                                            <td className="tablerowgray fw-500">PU %</td>
                                            <td className="tablerowgray fw-500">GB/FB</td>
                                        </tr>
                                        {this.renderTable()}
                                        {
                                            this.props.filterRes &&
                                            this.props.filterRes.bbp_by_field_table_data_query &&
                                            this.props.filterRes.bbp_by_field_table_data_query.length === 0 &&
                                            <tr>
                                                <td colSpan="13" className="text-center table-rowwhite">There's is no Info yet!</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>

                            {/* spray chart */}
                            <div className="common-charts">
                                <div className={`row ${!this.props.isPrinting ? 'singleDiv' : 'breakDiv'}`}>
                                    <div className="w-33">
                                        <PointSprayChart
                                            chartData={this.props.filterRes.batted_ball_3_spray_chart_data || []}
                                            cameraAngle={["Press Box", "Spray Chart"]}
                                            visualType="Hitting"
                                            pitchLocationType="Hit"
                                            colorScheme="PlayResult"
                                            eventProfileData={this.props.eventProfileData}
                                            sendReq={this.props.sendReq}
                                            tabslist={this.props.tabslist}
                                            profilepage={true}
                                            isNotDownloadImgLoader={this.props.isNotDownloadImgLoader}
                                            isDownloadImgLoader={this.props.isDownloadImgLoader}
                                            pitchrIframeFlag={this.props.pitchrIframeFlag}
                                            closePitchrIframe={this.props.closePitchrIframe} />
                                    </div>
                                    <div className="w-33">
                                        <EVSpyayChart
                                            chartData={getSprayChartRangeColor(constant.softball, this.props.filterRes.batted_ball_3_spray_chart_data || [], "exit_velocity")}
                                            softball={constant.softball}
                                            tabName="contactTab"
                                            cameraAngle={["Press Box", "Spray Chart"]}
                                            visualType="Hitting"
                                            pitchLocationType="Hit"
                                            colorScheme="ExitVelocity"
                                            eventProfileData={this.props.eventProfileData}
                                            sendReq={this.props.sendReq}
                                            tabslist={this.props.tabslist}
                                            profilepage={true}
                                            isNotDownloadImgLoader={this.props.isNotDownloadImgLoader}
                                            isDownloadImgLoader={this.props.isDownloadImgLoader}
                                            pitchrIframeFlag={this.props.pitchrIframeFlag}
                                            closePitchrIframe={this.props.closePitchrIframe} />
                                    </div>
                                    <div className="w-33">
                                        <DotRangeSpyayChart
                                            chartData={this.props.filterRes.batted_ball_3_spray_chart_data || []}
                                            softball={constant.softball}
                                            tabName="contactTab"
                                            cameraAngle={["Press Box", "Spray Chart"]}
                                            visualType="Hitting"
                                            pitchLocationType="Hit"
                                            colorScheme="ContactType"
                                            eventProfileData={this.props.eventProfileData}
                                            sendReq={this.props.sendReq}
                                            tabslist={this.props.tabslist}
                                            profilepage={true}
                                            isNotDownloadImgLoader={this.props.isNotDownloadImgLoader}
                                            isDownloadImgLoader={this.props.isDownloadImgLoader}
                                            pitchrIframeFlag={this.props.pitchrIframeFlag}
                                            closePitchrIframe={this.props.closePitchrIframe} />
                                    </div>
                                </div>
                            </div>

                            {/* batted ball heat maps */}
                            <div className="common-charts">
                                <div className={`row ${!this.props.isPrinting ? 'singleDiv' : 'breakDiv'}`}>
                                    <div className="w-33">
                                        {this.props.filterRes &&
                                            <EVHeatMapChart
                                                chartData={this.props.filterRes.batted_ball_heat_maps_1}
                                                id="EVHeatMapChart"
                                                heat_map={this.props.heat_map_1}
                                                onChangeEvent={this.props.onChangeEvent}
                                                eventProfileData={this.props.eventProfileData}
                                                sendReq={this.props?.sendReq}
                                                tabslist={this.props?.tabslist}
                                                profilepage={true}
                                                isNotDownloadImgLoader={this.props.isNotDownloadImgLoader}
                                                isDownloadImgLoader={this.props.isDownloadImgLoader} />
                                        }
                                    </div>
                                    <div className="w-33">
                                        {this.props.filterRes &&
                                            <HardHitHeatMapChart
                                                chartData={this.props.filterRes.batted_ball_heat_maps_2}
                                                id="HardHitHeatMapChart"
                                                heat_map={this.props.heat_map_2}
                                                onChangeEvent={this.props.onChangeEvent}
                                                eventProfileData={this.props.eventProfileData}
                                                sendReq={this.props?.sendReq}
                                                tabslist={this.props?.tabslist}
                                                profilepage={true}
                                                isNotDownloadImgLoader={this.props.isNotDownloadImgLoader}
                                                isDownloadImgLoader={this.props.isDownloadImgLoader} />
                                        }
                                    </div>
                                    <div className="w-33">
                                        {this.props.filterRes &&
                                            <DamageHeatMapChart
                                                chartData={this.props.filterRes.batted_ball_heat_maps_3}
                                                id="DamageHeatMapChart"
                                                heat_map={this.props.heat_map_3}
                                                onChangeEvent={this.props.onChangeEvent}
                                                eventProfileData={this.props.eventProfileData}
                                                sendReq={this.props?.sendReq}
                                                tabslist={this.props?.tabslist}
                                                profilepage={true}
                                                isNotDownloadImgLoader={this.props.isNotDownloadImgLoader}
                                                isDownloadImgLoader={this.props.isDownloadImgLoader} />
                                        }
                                    </div>
                                </div>
                            </div>

                            {/* batted ball field table */}
                            <div className="table-responsive-xl">
                                <table className="table tabledashed">
                                    <thead>
                                        <tr>
                                            <th colSpan="12" className="text-center">Batted Ball By Field</th>
                                            {/* <th colSpan="3" className="text-center">Launch Angle</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="tablerowgray fw-500">Type</td>
                                            <td className="tablerowgray fw-500">#</td>
                                            <td className="tablerowgray fw-500">Pull %</td>
                                            <td className="tablerowgray fw-500">Pull EV</td>
                                            <td className="tablerowgray fw-500">Pull LA</td>
                                            <td className="tablerowgray fw-500">Mid %</td>
                                            <td className="tablerowgray fw-500">Mid EV</td>
                                            <td className="tablerowgray fw-500">Mid LA</td>
                                            <td className="tablerowgray fw-500">Oppo %</td>
                                            <td className="tablerowgray fw-500">Oppo EV</td>
                                            <td className="tablerowgray fw-500">Oppo LA</td>
                                            <td className="tablerowgray fw-500">IFH %</td>
                                        </tr>
                                        {this.renderTableField()}
                                        {
                                            this.props.filterRes &&
                                            this.props.filterRes.bbp_by_field_table_data_query &&
                                            this.props.filterRes.bbp_by_field_table_data_query.length === 0 &&
                                            <tr>
                                                <td colSpan="12" className="text-center table-rowwhite">There's is no Info yet!</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>

                            {/* 4 section of Point of Contact Visuals - view chart */}

                            <div className="common-charts">
                                <div className={`row ${!this.props.isPrinting ? 'singleDiv' : 'breakDiv'}`}>
                                    <div className="w-33">
                                        <CatcherView
                                            chartData={this.props.filterRes.catcher_view_strike_zone || []}
                                            ViewColor={this.state.ViewColor}
                                            onChangeEvent={this.HandleChangePitchColor}
                                            softball={constant.softball}
                                            x_min={-2.5}
                                            x_max={2.5}
                                            y_min={0}
                                            y_max={5}
                                            x_axis="Side at Plate (ft)"
                                            y_axis="Height at Plate (ft)"
                                            id="CatcherViewChart"
                                            title="Catcher View"
                                            cameraAngle={["Catcher", "Press Box"]}
                                            visualType="Pitching"
                                            pitchLocationType="Hit"
                                            colorScheme={this.state.ViewColor}
                                            eventProfileData={this.props.eventProfileData}
                                            sendReq={this.props.sendReq}
                                            tabslist={this.props.tabslist}
                                            profilepage={true}
                                            isNotDownloadImgLoader={this.props.isNotDownloadImgLoader}
                                            isDownloadImgLoader={this.props.isDownloadImgLoader}
                                            pitchrIframeFlag={this.props.pitchrIframeFlag}
                                            closePitchrIframe={this.props.closePitchrIframe} />
                                    </div>
                                    <div className="w-33">
                                        <TopView
                                            chartData={this.props.filterRes.top_view_strike_zone || []}
                                            ViewColor={this.state.ViewColor}
                                            onChangeEvent={this.HandleChangePitchColor}
                                            softball={constant.softball}
                                            x_min={-2}
                                            x_max={2}
                                            y_min={-2}
                                            y_max={4}
                                            x_axis="Side at Plate (ft)"
                                            y_axis="Depth at Plate (ft)"
                                            id="TopViewChart"
                                            title="Top View"
                                            cameraAngle={["Home Top", "Home 1B side", "Home 3B side"]}
                                            visualType="Pitching"
                                            pitchLocationType="Hit"
                                            colorScheme={this.state.ViewColor}
                                            eventProfileData={this.props.eventProfileData}
                                            sendReq={this.props.sendReq}
                                            tabslist={this.props.tabslist}
                                            profilepage={true}
                                            isNotDownloadImgLoader={this.props.isNotDownloadImgLoader}
                                            isDownloadImgLoader={this.props.isDownloadImgLoader}
                                            pitchrIframeFlag={this.props.pitchrIframeFlag}
                                            closePitchrIframe={this.props.closePitchrIframe} />
                                    </div>
                                    <div className="w-33">
                                        <SideView
                                            chartData={this.props.filterRes.side_view_strike_zone || []}
                                            ViewColor={this.state.ViewColor}
                                            onChangeEvent={this.HandleChangePitchColor}
                                            softball={constant.softball}
                                            x_min={-2}
                                            x_max={4}
                                            y_min={0}
                                            y_max={5}
                                            x_axis="Depth at Plate (ft)"
                                            y_axis="Height at Plate (ft)"
                                            id="SideViewChart"
                                            title="Side View"
                                            cameraAngle={["Home 1B side", "Home 3B side", "Home Top"]}
                                            visualType="Pitching"
                                            pitchLocationType="Hit"
                                            colorScheme={this.state.ViewColor}
                                            eventProfileData={this.props.eventProfileData}
                                            sendReq={this.props.sendReq}
                                            tabslist={this.props.tabslist}
                                            profilepage={true}
                                            isNotDownloadImgLoader={this.props.isNotDownloadImgLoader}
                                            isDownloadImgLoader={this.props.isDownloadImgLoader}
                                            pitchrIframeFlag={this.props.pitchrIframeFlag}
                                            closePitchrIframe={this.props.closePitchrIframe} />
                                    </div>
                                </div>
                            </div>

                            <div className="common-charts">
                                <div className={`row ${!this.props.isPrinting ? 'singleDiv' : 'breakDiv'}`}>
                                    <div className="w-33">
                                        <ContactCatcherView
                                            chartData={this.props.filterRes.catcher_view_strike_zone || []}
                                            ViewColor={this.state.ViewColor_1}
                                            onChangeEvent={this.HandleChangePitchColor}
                                            softball={constant.softball}
                                            x_min={-2.5}
                                            x_max={2.5}
                                            y_min={0}
                                            y_max={5}
                                            x_axis="Side at Plate (ft)"
                                            y_axis="Height at Plate (ft)"
                                            id="ContactCatcherViewChart"
                                            title="Catcher View"
                                            cameraAngle={["Catcher", "Press Box"]}
                                            visualType="Pitching"
                                            pitchLocationType="Hit"
                                            colorScheme={this.state.ViewColor_1}
                                            eventProfileData={this.props.eventProfileData}
                                            sendReq={this.props.sendReq}
                                            tabslist={this.props.tabslist}
                                            profilepage={true}
                                            isNotDownloadImgLoader={this.props.isNotDownloadImgLoader}
                                            isDownloadImgLoader={this.props.isDownloadImgLoader}
                                            pitchrIframeFlag={this.props.pitchrIframeFlag}
                                            closePitchrIframe={this.props.closePitchrIframe} />
                                    </div>
                                    <div className="w-33">
                                        <ContactTopView
                                            chartData={this.props.filterRes.top_view_strike_zone || []}
                                            ViewColor={this.state.ViewColor_1}
                                            onChangeEvent={this.HandleChangePitchColor}
                                            softball={constant.softball}
                                            x_min={-2}
                                            x_max={2}
                                            y_min={-2}
                                            y_max={4}
                                            x_axis="Side at Plate (ft)"
                                            y_axis="Depth at Plate (ft)"
                                            id="ContactTopViewChart"
                                            title="Top View"
                                            cameraAngle={["Home Top", "Home 1B side", "Home 3B side"]}
                                            visualType="Pitching"
                                            pitchLocationType="Hit"
                                            colorScheme={this.state.ViewColor_1}
                                            eventProfileData={this.props.eventProfileData}
                                            sendReq={this.props.sendReq}
                                            tabslist={this.props.tabslist}
                                            profilepage={true}
                                            isNotDownloadImgLoader={this.props.isNotDownloadImgLoader}
                                            isDownloadImgLoader={this.props.isDownloadImgLoader}
                                            pitchrIframeFlag={this.props.pitchrIframeFlag}
                                            closePitchrIframe={this.props.closePitchrIframe} />
                                    </div>
                                    <div className="w-33">
                                        <ContactSideView
                                            chartData={this.props.filterRes.side_view_strike_zone || []}
                                            ViewColor={this.state.ViewColor_1}
                                            onChangeEvent={this.HandleChangePitchColor}
                                            softball={constant.softball}
                                            x_min={-2}
                                            x_max={4}
                                            y_min={0}
                                            y_max={5}
                                            x_axis="Depth at Plate (ft)"
                                            y_axis="Height at Plate (ft)"
                                            id="ContactSideViewChart"
                                            title="Side View"
                                            cameraAngle={["Home 1B side", "Home 3B side", "Home Top"]}
                                            visualType="Pitching"
                                            pitchLocationType="Hit"
                                            colorScheme={this.state.ViewColor_1}
                                            eventProfileData={this.props.eventProfileData}
                                            sendReq={this.props.sendReq}
                                            tabslist={this.props.tabslist}
                                            profilepage={true}
                                            isNotDownloadImgLoader={this.props.isNotDownloadImgLoader}
                                            isDownloadImgLoader={this.props.isDownloadImgLoader}
                                            pitchrIframeFlag={this.props.pitchrIframeFlag}
                                            closePitchrIframe={this.props.closePitchrIframe} />
                                    </div>
                                </div>
                            </div>

                            <div className="common-charts">
                                <div className={`row ${!this.props.isPrinting ? 'singleDiv' : 'breakDiv'}`}>
                                    <div className="w-33">
                                        <PitchCatcherView
                                            chartData={this.props.filterRes.catcher_view_strike_zone || []}
                                            ViewColor={this.state.ViewColor_2}
                                            onChangeEvent={this.HandleChangePitchColor}
                                            softball={constant.softball}
                                            x_min={-2.5}
                                            x_max={2.5}
                                            y_min={0}
                                            y_max={5}
                                            x_axis="Side at Plate (ft)"
                                            y_axis="Height at Plate (ft)"
                                            id="PitchCatcherViewChart"
                                            title="Catcher View"
                                            cameraAngle={["Catcher", "Press Box"]}
                                            visualType="Pitching"
                                            pitchLocationType="Hit"
                                            colorScheme={this.state.ViewColor_2}
                                            eventProfileData={this.props.eventProfileData}
                                            sendReq={this.props.sendReq}
                                            tabslist={this.props.tabslist}
                                            profilepage={true}
                                            isNotDownloadImgLoader={this.props.isNotDownloadImgLoader}
                                            isDownloadImgLoader={this.props.isDownloadImgLoader}
                                            pitchrIframeFlag={this.props.pitchrIframeFlag}
                                            closePitchrIframe={this.props.closePitchrIframe} />
                                    </div>
                                    <div className="w-33">
                                        <PitchTopView
                                            chartData={this.props.filterRes.top_view_strike_zone || []}
                                            ViewColor={this.state.ViewColor_2}
                                            onChangeEvent={this.HandleChangePitchColor}
                                            softball={constant.softball}
                                            x_min={-2}
                                            x_max={2}
                                            y_min={-2}
                                            y_max={4}
                                            x_axis="Side at Plate (ft)"
                                            y_axis="Depth at Plate (ft)"
                                            id="PitchTopViewChart"
                                            title="Top View"
                                            cameraAngle={["Home Top", "Home 1B side", "Home 3B side"]}
                                            visualType="Pitching"
                                            pitchLocationType="Hit"
                                            colorScheme={this.state.ViewColor_2}
                                            eventProfileData={this.props.eventProfileData}
                                            sendReq={this.props.sendReq}
                                            tabslist={this.props.tabslist}
                                            profilepage={true}
                                            isNotDownloadImgLoader={this.props.isNotDownloadImgLoader}
                                            isDownloadImgLoader={this.props.isDownloadImgLoader}
                                            pitchrIframeFlag={this.props.pitchrIframeFlag}
                                            closePitchrIframe={this.props.closePitchrIframe} />
                                    </div>
                                    <div className="w-33">
                                        <PitchSideView
                                            chartData={this.props.filterRes.side_view_strike_zone || []}
                                            ViewColor={this.state.ViewColor_2}
                                            onChangeEvent={this.HandleChangePitchColor}
                                            softball={constant.softball}
                                            x_min={-2}
                                            x_max={4}
                                            y_min={0}
                                            y_max={5}
                                            x_axis="Depth at Plate (ft)"
                                            y_axis="Height at Plate (ft)"
                                            id="PitchSideViewChart"
                                            title="Side View"
                                            cameraAngle={["Home 1B side", "Home 3B side", "Home Top"]}
                                            visualType="Pitching"
                                            pitchLocationType="Hit"
                                            colorScheme={this.state.ViewColor_2}
                                            eventProfileData={this.props.eventProfileData}
                                            sendReq={this.props.sendReq}
                                            tabslist={this.props.tabslist}
                                            profilepage={true}
                                            isNotDownloadImgLoader={this.props.isNotDownloadImgLoader}
                                            isDownloadImgLoader={this.props.isDownloadImgLoader}
                                            pitchrIframeFlag={this.props.pitchrIframeFlag}
                                            closePitchrIframe={this.props.closePitchrIframe} />
                                    </div>
                                </div>
                            </div>

                            <div className="common-charts">
                                <div className={`row ${!this.props.isPrinting ? 'singleDiv' : 'breakDiv'}`}>
                                    <div className="w-33">
                                        <PlayCatcherView
                                            chartData={this.props.filterRes.catcher_view_strike_zone || []}
                                            ViewColor={this.state.ViewColor_3}
                                            onChangeEvent={this.HandleChangePitchColor}
                                            softball={constant.softball}
                                            x_min={-2.5}
                                            x_max={2.5}
                                            y_min={0}
                                            y_max={5}
                                            x_axis="Side at Plate (ft)"
                                            y_axis="Height at Plate (ft)"
                                            id="PlayCatcherViewChart"
                                            title="Catcher View"
                                            cameraAngle={["Catcher", "Press Box"]}
                                            visualType="Pitching"
                                            pitchLocationType="Hit"
                                            colorScheme={this.state.ViewColor_3}
                                            eventProfileData={this.props.eventProfileData}
                                            sendReq={this.props.sendReq}
                                            tabslist={this.props.tabslist}
                                            profilepage={true}
                                            isNotDownloadImgLoader={this.props.isNotDownloadImgLoader}
                                            isDownloadImgLoader={this.props.isDownloadImgLoader}
                                            pitchrIframeFlag={this.props.pitchrIframeFlag}
                                            closePitchrIframe={this.props.closePitchrIframe} />
                                    </div>
                                    <div className="w-33">
                                        <PlayTopView
                                            chartData={this.props.filterRes.top_view_strike_zone || []}
                                            ViewColor={this.state.ViewColor_3}
                                            onChangeEvent={this.HandleChangePitchColor}
                                            softball={constant.softball}
                                            x_min={-2}
                                            x_max={2}
                                            y_min={-2}
                                            y_max={4}
                                            x_axis="Side at Plate (ft)"
                                            y_axis="Depth at Plate (ft)"
                                            id="PlayTopViewChart"
                                            title="Top View"
                                            cameraAngle={["Home Top", "Home 1B side", "Home 3B side"]}
                                            visualType="Pitching"
                                            pitchLocationType="Hit"
                                            colorScheme={this.state.ViewColor_3}
                                            eventProfileData={this.props.eventProfileData}
                                            sendReq={this.props.sendReq}
                                            tabslist={this.props.tabslist}
                                            profilepage={true}
                                            isNotDownloadImgLoader={this.props.isNotDownloadImgLoader}
                                            isDownloadImgLoader={this.props.isDownloadImgLoader}
                                            pitchrIframeFlag={this.props.pitchrIframeFlag}
                                            closePitchrIframe={this.props.closePitchrIframe} />
                                    </div>
                                    <div className="w-33">
                                        <PlaySideView
                                            chartData={this.props.filterRes.side_view_strike_zone || []}
                                            ViewColor={this.state.ViewColor_3}
                                            onChangeEvent={this.HandleChangePitchColor}
                                            softball={constant.softball}
                                            x_min={-2}
                                            x_max={4}
                                            y_min={0}
                                            y_max={5}
                                            x_axis="Depth at Plate (ft)"
                                            y_axis="Height at Plate (ft)"
                                            id="PlaySideViewChart"
                                            title="Side View"
                                            cameraAngle={["Home 1B side", "Home 3B side", "Home Top"]}
                                            visualType="Pitching"
                                            pitchLocationType="Hit"
                                            colorScheme={this.state.ViewColor_3}
                                            eventProfileData={this.props.eventProfileData}
                                            sendReq={this.props.sendReq}
                                            tabslist={this.props.tabslist}
                                            profilepage={true}
                                            isNotDownloadImgLoader={this.props.isNotDownloadImgLoader}
                                            isDownloadImgLoader={this.props.isDownloadImgLoader}
                                            pitchrIframeFlag={this.props.pitchrIframeFlag}
                                            closePitchrIframe={this.props.closePitchrIframe} />
                                    </div>
                                </div>
                            </div>

                            {/* point of contact table */}

                            <div className="table-responsive-xl">
                                <table className="table tabledashed">
                                    <thead>
                                        <tr>
                                            <th colSpan="15" className="text-center">Point of Contact</th>
                                            {/* <th colSpan="3" className="text-center">Launch Angle</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="tablerowgray fw-500">Type</td>
                                            <td className="tablerowgray fw-500">#</td>
                                            <td className="tablerowgray fw-500">%</td>
                                            <td className="tablerowgray fw-500">Contact Ht</td>
                                            <td className="tablerowgray fw-500">Contact Side</td>
                                            <td className="tablerowgray fw-500">Contact Depth</td>
                                            <td className="tablerowgray fw-500">Pull Cont Ht</td>
                                            <td className="tablerowgray fw-500">Pull Cont Side</td>
                                            <td className="tablerowgray fw-500">Pull Cont Depth</td>
                                            <td className="tablerowgray fw-500">Mid Cont Ht</td>
                                            <td className="tablerowgray fw-500">Mid Cont Side</td>
                                            <td className="tablerowgray fw-500">Mid Cont Depth</td>
                                            <td className="tablerowgray fw-500">Oppo Cont Ht</td>
                                            <td className="tablerowgray fw-500">Oppo Cont Side</td>
                                            <td className="tablerowgray fw-500">Oppo Cont Depth</td>
                                        </tr>
                                        {this.renderTableContact()}
                                        {
                                            this.props.filterRes &&
                                            this.props.filterRes.point_of_contact_table_data &&
                                            this.props.filterRes.point_of_contact_table_data.length === 0 &&
                                            <tr>
                                                <td colSpan="15" className="text-center table-rowwhite">There's is no Info yet!</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>

                            {/* histogram of batted ball */}
                            <div className="common-charts hisrogram">
                                <div className={` ${!this.props.isPrinting ? 'singleDiv' : 'breakDiv'}`}>
                                    {
                                        this.props.filterRes &&
                                        <div className="w-100">
                                            <HistoGram
                                                chartData={this.props.filterRes.histrogram_chart_data || []}
                                                id="histogramChart"
                                                showTitle={true}
                                                onChangeHistro={this.HandleChangePitchColor}
                                                onChangeEvent={this.props.onChangeEvent}
                                                coloredBy={this.state.coloredBy}
                                                y_axis={this.state.y_axis}
                                                x_axis={this.props.histrogram_field}
                                                min={this.props.histrogram_field === "exit_velocity" ? constant.softball ? 20 : 30 : this.props.histrogram_field === "launch_angle" ? -80 : -50}
                                                max={this.props.histrogram_field === "exit_velocity" ? constant.softball ? 90 : 120 : this.props.histrogram_field === "launch_angle" ? 80 : 50}
                                                histrogram_bucket={this.props.histrogram_bucket}
                                                eventProfileData={this.props.eventProfileData}
                                                sendReq={this.props?.sendReq}
                                                tabslist={this.props?.tabslist}
                                                profilepage={true}
                                                isNotDownloadImgLoader={this.props.isNotDownloadImgLoader}
                                                isDownloadImgLoader={this.props.isDownloadImgLoader}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default battedball;
