import React, { useEffect, useState } from "react";
import * as d3 from "d3";
import BasicSpray from "../../../shared/SprayChartd3";
import {
  removeNullEntryFromArrayOfObjects,
  uniqueEntriesFromArray,
} from "../../../shared/function";
import { constant } from "../../../shared/constant";
import NoSpreyChart from "../noSpreyChart";
import PitchRWebGL from "../../webGL/PitchRWebGL";
import Toggle from "../../../shared/Toggle";
import domtoimage from 'dom-to-image';
import { downloadChartPrintImg } from "../../../shared/printReportUtils";
import PrintImagePopup from "../../../shared/PrintImagePopup";
import use3DView from "../../../shared/use3DView";

function HBDotRangeSpyayChart({ chartData, softball, index, tabName, cameraAngle, visualType, pitchLocationType, colorScheme, pitchrIframeFlag, closePitchrIframe, eventProfileData, sendReq, tabslist, profilepage, pitcherDefault, isNotDownloadImgLoader, isDownloadImgLoader, eventName }) {
  const [data3D, setData3D, toggle, setToggle, onClickToggleButton, flag] = use3DView(`groundDotChartForHitt${index || ""}`);
  const [isChartImg, setIsChartImg] = useState(false);
  const [clickFlag, setClickFlag] = useState(false);
  const [chartLegendData, setChartLegendData] = useState([]);
  
  useEffect(() => {
    let arr = chartData && chartData.length !== 0 && chartData.map(x => x?._id) || []
    setData3D(arr)
    const removeNullEntriesFromChartData = removeNullEntryFromArrayOfObjects(
      chartData
    );
    const uniqueNames = uniqueEntriesFromArray(
      removeNullEntriesFromChartData,
      "hit_angle_type"
    );
    var ordering = JSON.parse(JSON.stringify(uniqueNames));
    for (var i = 0; i < uniqueNames.length; i++) {
        ordering[['gb','ld','fb','pu'].findIndex(e=>e === uniqueNames[i])] = uniqueNames[i];
    }
    setChartLegendData(ordering);
    renderChart(chartData);
  }, [chartData, toggle]);

  // bp event player name change,set pichRIframe initial state
  useEffect(() => {
    if (pitchrIframeFlag === "yes") {
      setData3D(null)
      setToggle({ chartToggle: true });
      closePitchrIframe();
    }
  }, [pitchrIframeFlag])

  const renderChart = (data) => {
      const selection = d3.select(`#groundDotChartForHitt${index || ""}`);
      if (!selection.empty()) {
        const sprayChart = new BasicSpray(selection, softball, "hit_angle_type");
        sprayChart.update(data);
      }
  };

  const chartImage = (id) => {
    isDownloadImgLoader();
    setClickFlag(true)
    if (document.getElementById(`img${id}`)) {
      document.getElementById(`img${id}`).remove()
    }
    setIsChartImg(true)
    downloadChartPrintImg(id, setIsChartImg, setClickFlag, isNotDownloadImgLoader)
  }
  console.log('flag', flag)

  return (
    <>
      {chartData && chartData.length !== 0 ? (
        <div className={`ground-chart sprayChartCls contactTabSpray1 ${tabName === "contactTab" ? "fixed-h" : ""}`}>
          {chartData && chartData.length !== 0 ? <Toggle toggle={toggle} onClickToggleButton={onClickToggleButton} /> : ""}
          <div style={{ display: toggle.chartToggle === true ? 'block' : 'none' }}>
            <div className="spraychart-section">
              <div className="legends text-left fixed-s-l">
                <div className="text-left d-inline">
                  <h3 className="mb-0">Contact Type</h3>
                  {chartLegendData.map((pitch, i) => {
                    return (
                      <div key={i} className="legend">
                        <span
                          className="legendCircle"
                          style={{
                            backgroundColor: constant.colors[pitch],
                          }}
                        />
                        <span className="legendText">{constant.pitchTypeAbbrevations[pitch] || "None"}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div id={`groundDotChartForHitt${index || ""}`} className="groundChart"></div>
              {/* <button className="btn blue btn-view" onClick={() => chartImage(`groundDotChartForHitt${index || ""}`)} disabled={clickFlag} >Download</button> */}
              <div class="chartdownload" onClick={() => chartImage(`groundDotChartForHitt${index || ""}`)} disabled={clickFlag}><a class="btn white"><i class="fa fa-download" aria-hidden="true"></i></a></div>
            </div>
          </div>
          {flag?.[`iframe_groundDotChartForHitt${index || ""}`] === true && <div id="pitchR-iframe" className="Chart-iframe" style={{ display: toggle.chartToggle === false ? 'block' : 'none' }}>
            <PitchRWebGL
              data={data3D}
              id={`groundDotChartForHitt${index || ""}`}
              cameraAngle={cameraAngle}
              visualType={visualType}
              Toggle={toggle.chartToggle}
              pitchLocationType={pitchLocationType}
              colorScheme={colorScheme} />
          </div>}
        </div>
      ) : (
        <>
          <NoSpreyChart tabName={tabName} height="333px" />
        </>
      )}

      {/* chart visual image download popup */}
      {isChartImg && <PrintImagePopup
        title="Contact Type"
        eventProfileData={eventProfileData}
        id={`groundDotChartForHitt${index || ""}`}
        sendReq={sendReq}
        tabslist={tabslist}
        eventName={eventName}
        pitcherDefault={pitcherDefault}
        profilepage={profilepage} />}
    </>
  );
}

export default HBDotRangeSpyayChart;