import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import PropTypes from "prop-types";
import {
  filterSequenceChart,
  uniqueEntriesFromArray,
} from "../../shared/function";
import { constant } from "../../shared/constant";
import PrintImagePopup from "../../shared/PrintImagePopup";
import domtoimage from 'dom-to-image';
import { downloadChartPrintImg } from "../../shared/printReportUtils";

function PitchSequencingChart({ chartData, id, title, type, eventProfileData, sendReq, tabslist, profilepage, eventName, pitcherDefault, isNotDownloadImgLoader, isDownloadImgLoader }) {
  const [isChartImg, setIsChartImg] = useState(false);
  const [clickFlag, setClickFlag] = useState(false);
  const [state, setState] = useState({
    chart: {
      type: "bar",
      borderWidth: 0,
      marginTop: 70,
      borderColor: "#D8D8D8",
      borderRadius: "8px",
    },
    title: {
      text: ""
      // text: `${title === "Count" ? "Count" : title === "Result" ? "Result" : "Pitch"} Sequencing`,
      // layout: "horizontal",
      // align: "left",
      // verticalAlign: "left",
      // y: 20,
      // x: 10,
      // style: {
      //   color: "#1B1B1B",
      //   fontFamily: "Poppins, sans-serif",
      //   fontSize: "18px",
      //   fontWeight: "bold",
      // },
    },
    xAxis: {
      title: {
        text: "",
        style: {
          color: "#777F8F",
          fontFamily: "Poppins, sans-serif",
          fontSize: "14px",
          fontWeight: "500",
        },
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "",
        style: {
          color: "#777F8F",
          fontFamily: "Poppins, sans-serif",
          fontSize: "14px",
          fontWeight: "500",
        },
      },
      labels: {
        format: '{value}%',
      },
      tickInterval: 25,
      min: 0,
      max: 100
    },
    legend: {
      layout: "horizontal",
      align: "top",
      verticalAlign: "top",
      // y: 35,
      reversed: true,
    },
    credits: { enabled: false },
    plotOptions: {
      series: {
        stacking: "normal",
        pointWidth: 36,
        states: {
          hover: {
            enabled: false
          },
          inactive: {
            opacity: 1
          }
        },
      },
    },
    tooltip: {
      formatter: function () {
        var tooltip = `<div><p><b>${Object.keys(constant.pitchTypeAbbrevations).find(key => constant.pitchTypeAbbrevations[key] === this.x)}</b></p><br/>
        <span>${Object.keys(constant.pitchTypeAbbrevations).find(key => constant.pitchTypeAbbrevations[key] === this.series.name)}
        </span>: <b>${this.y.toString().includes(".") ? this.y.toFixed(1) : this.y}%</b><br/>
        ${type === "profilechart" ? "" : `<span>#: ${this.point.series.userOptions.count ? this.point.series.userOptions.count.find(e => e?.name === this.x).value : "-"}</span><br/>`}
        ${type === "profilechart" ? "" : type === "pitcherProfile" ? "" : `<span>Avg EV: ${this.point.series.userOptions && this.point.series.userOptions.Avg_EV ? this.point.series.userOptions.Avg_EV.find(e => e?.name === this.x).value.toString().includes(".") ? this.point.series.userOptions.Avg_EV.find(e => e?.name === this.x).value.toFixed(1) : this.point.series.userOptions.Avg_EV.find(e => e?.name === this.x).value : "-"}</span><br/>`}
        ${type === "profilechart" ? "" : type === "pitcherProfile" ? "" : `<span>Swing and Miss: ${this.point.series.userOptions && this.point.series.userOptions.swing_and_miss ? this.point.series.userOptions.swing_and_miss.find(e => e?.name === this.x).value.toString().includes(".") ? this.point.series.userOptions.swing_and_miss.find(e => e?.name === this.x).value.toFixed(1) + "%" : this.point.series.userOptions.swing_and_miss.find(e => e?.name === this.x).value + "%" : "-"}</span><br/>`}
        ${type === "profilechart" ? "" : type === "pitcherProfile" ? "" : `<span>Chase: ${this.point.series.userOptions && this.point.series.userOptions.chase ? this.point.series.userOptions.chase.find(e => e?.name === this.x).value.toString().includes(".") ? this.point.series.userOptions.chase.find(e => e?.name === this.x).value.toFixed(1) + "%" : this.point.series.userOptions.chase.find(e => e?.name === this.x).value + "%" : "-"}</span><br/></div>`}`;
        return tooltip;
      },
    },
    series: [],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
        },
      ],
    },
  });

  useEffect(() => {
    if (chartData) {
      // increment();
      if (chartData.length !== 0) {
        const chartPoint = filterSequenceChart(chartData);
        const xAxisUniqueData = uniqueEntriesFromArray(chartData, "data_point");
        let xAxisData = [];
        xAxisUniqueData.map((data) => {
          xAxisData.push(constant.pitchTypeAbbrevations[data]);
        });
        state.series = chartPoint;
        state.xAxis.categories = xAxisData;
        Highcharts.chart(id, state)
        // decrement();
      } else {
        state.series = [{ showInLegend: false }];
        Highcharts.chart(id, state)
        // decrement();
      }
    }

  }, [chartData]);

  const chartImage = (id) => {
    isDownloadImgLoader();
    setClickFlag(true)
    if (document.getElementById(`img${id}`)) {
      document.getElementById(`img${id}`).remove()
    }
    setIsChartImg(true)
    downloadChartPrintImg(id, setIsChartImg, setClickFlag, isNotDownloadImgLoader)
  }

  return (
    <>
      <div className="ground-chart">
        <div className="legends text-left">
          <div className="text-left d-inline">
            <h3 className="mb-0">{title === "Count" ? "Count" : title === "Result" ? "Result" : "Pitch"} Sequencing</h3>
          </div>
        </div>
        <div style={{ height: "444px" }} id={id} ></div>
        {/* <button className="btn blue btn-view" onClick={() => chartImage(id)} disabled={clickFlag} >Download</button> */}
        <div class="chartdownload" onClick={() => chartImage(id)} disabled={clickFlag}><a class="btn white"><i class="fa fa-download" aria-hidden="true"></i></a></div>
      </div>

      {/* chart visual image download */}
      {isChartImg && <PrintImagePopup
        title={`${title === "Count" ? "Count" : title === "Result" ? "Result" : "Pitch"} Sequencing`}
        eventProfileData={eventProfileData}
        id={id}
        sendReq={sendReq}
        tabslist={tabslist}
        eventName={eventName}
        pitcherDefault={pitcherDefault}
        profilepage={profilepage} />}
    </>
  );
}

PitchSequencingChart.propTypes = {};

export default PitchSequencingChart;
