import React, { useEffect, useState } from 'react'
import { constant } from '../../shared/constant';
import { groupArrayOfObjects } from '../../shared/function';

function HitterPlateDiscipline({ tableData }) {

    const renderList = () => {
        if (tableData.length !== 0) { 
            var groupedPitcher = groupArrayOfObjects(
                tableData,
                "pitch_type"
            );
        }
        return groupedPitcher && groupedPitcher.length !== 0 ? (
            Object.keys(groupedPitcher).map((keyName, i) => {
                const rowColor = keyName === "Total" ? "table-rowgrey80" : "table-rowwhite"
                return (
                    <tr key={i}>
                        <td className={`${rowColor}`} style={{ color: constant.pitchTypeColor[keyName] }}>
                            {keyName ? keyName : "-"}
                        </td>
                        <td className={`${rowColor}`}>
                            {groupedPitcher[keyName].find((k) => k.zone === "in_zone")?.swings ? groupedPitcher[keyName].find((k) => k.zone === "in_zone").swings.toString().includes(".") ? groupedPitcher[keyName].find((k) => k.zone === "in_zone").swings.toFixed(1) : groupedPitcher[keyName].find((k) => k.zone === "in_zone").swings : '—'}
                        </td>
                        <td className={`${rowColor}`}>
                            {groupedPitcher[keyName].find((k) => k.zone === "in_zone")?.takes ? groupedPitcher[keyName].find((k) => k.zone === "in_zone").takes.toString().includes(".") ? groupedPitcher[keyName].find((k) => k.zone === "in_zone").takes.toFixed(1) : groupedPitcher[keyName].find((k) => k.zone === "in_zone").takes : '—'}
                        </td>
                        <td className={`${rowColor}`}>
                            {groupedPitcher[keyName].find((k) => k.zone === "out_zone")?.swings ? groupedPitcher[keyName].find((k) => k.zone === "out_zone").swings.toString().includes(".") ? groupedPitcher[keyName].find((k) => k.zone === "out_zone").swings.toFixed(1) : groupedPitcher[keyName].find((k) => k.zone === "out_zone").swings : '—'}
                        </td>
                        <td className={`${rowColor}`}>
                            {groupedPitcher[keyName].find((k) => k.zone === "out_zone")?.takes ? groupedPitcher[keyName].find((k) => k.zone === "out_zone").takes.toString().includes(".") ? groupedPitcher[keyName].find((k) => k.zone === "out_zone").takes.toFixed(1) : groupedPitcher[keyName].find((k) => k.zone === "out_zone").takes : '—'}
                        </td>
                    </tr>
                )
            })
        ) : (
            <tr>
                <td className="table-rowwhite text-center" colSpan="5">
                    There's no info yet!
                </td>
            </tr>
        );
    };

    return (
        <div className="container-fluid">
            <h3 className="table-title">Plate Discipline</h3>
            <div className="homepitchers-table">
                <div className="table-responsive-xl">
                    <table className="table tabledashed">
                        <thead>
                            <tr>
                                <th></th>
                                <th colSpan="2" className="one-child text-center">In Zone</th>
                                <th colSpan="2" className="one-child text-center">Out of Zone</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="tablerowgray fw-500">Pitch Type</td>
                                <td className="tablerowgray fw-500">Swings</td>
                                <td className="tablerowgray fw-500">Takes</td>
                                <td className="tablerowgray fw-500">Swings</td>
                                <td className="tablerowgray fw-500">Takes</td>
                            </tr>
                            {renderList()}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default HitterPlateDiscipline
