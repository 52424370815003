import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import { polarPitchTypeChartData } from "../../shared/function";
import { constant } from "../../shared/constant";
import BallrWebGL from "../../view/webGL/BallrWebGL";
import domtoimage from 'dom-to-image';
import { downloadChartPrintImg } from "../../shared/printReportUtils";
import PrintImagePopup from "../../shared/PrintImagePopup";

function PolarChart({ chartData, id, showTitle, ballrIframeFlag, closeBallrIframe, eventProfileData, sendReq, tabslist, profilepage, eventName, pitcherDefault, isNotDownloadImgLoader, isDownloadImgLoader }) {
  const [isChartImg, setIsChartImg] = useState(false);
  const [clickFlag, setClickFlag] = useState(false);
  const [ballrFlag, setBallrFlag] = useState(false);
  const [tooltipData, setTooltipData] = useState(null);
  const [state, setState] = useState({
    chart: {
      type: "scatter",
      borderWidth: 0,
      marginTop: 100,
      borderColor: "#D8D8D8",
      borderRadius: "8px",
      polar: true,
      marginLeft: 50,
      marginRight: 50,
    },
    title: {
      text: "",
      // text: "Polar Spin",
      // layout: "horizontal",
      // align: "left",
      // verticalAlign: "left",
      // y: 20,
      // x: 10,
      // style: {
      //   color: "#1B1B1B",
      //   fontFamily: "Poppins, sans-serif",
      //   fontSize: "18px",
      //   fontWeight: "bold",
      // },
    },
    pane: {
      startAngle: 180,
    },
    xAxis: {
      lineWidth: 1,
      lineColor: '#000000',
      min: 0,
      max: 360,
      labels: {
        formatter: function () {
          return (this.value === 90 ? `${this.value}/9:00` : this.value === 180 ? `${this.value}/12:00` : this.value === 270 ? `${this.value}/3:00` : this.value === 360 ? `${this.value}/6:00` : "");
        },
        style: {
          color: "#1B1B1B",
          fontFamily: "Poppins, sans-serif",
          fontSize: "11px",
          fontWeight: "bold",
        }
      },
      tickInterval: 90,
      showLastLabel: true,
      endOnTick: true,
      tickmarkPlacement: 'on',
      gridLineColor: "#000000",
    },
    yAxis: {
      tickInterval: 500,
      min: constant.softball ? 1000 : 1000,
      max: constant.softball ? 2500 : 3000,
      angle: 270,
      lineWidth: 1,
      lineColor: '#000000',
      // tickPosition: 'inside',
      startOnTick: false,
      showLastLabel: true,
      endOnTick: true,
      // gridLineDashStyle: 'longdash',
      // tickWidth: 1,
      // tickColor: '#000000',
      labels: {
        y: 185,
        formatter: function () {
          let rpms = `<span style="font-weight:bold; font-size:10px;">${this.isFirst ? "RPM'S:" : ""}${this.value}</span>`
          return (rpms)
        },
      },
    },
    legend: {
      layout: "horizontal",
      align: "center",
      verticalAlign: "top",
      y: 35,
    },
    credits: { enabled: false },
    plotOptions: {
      series: {
        pointStart: 0,
        pointInterval: 1,
        stickyTracking: false,
        states: {
          inactive: {
            opacity: 1,
          },
        }
      },
      column: {
        pointPadding: 0,
        groupPadding: 0,
        pointPlacement: 'between'
      }
    },
    tooltip: {
      useHTML: true,
      hideDelay: 500,
      formatter: function () {
        setTooltipData(this?.point);
        // ballrFlag && refview.current.GiveKeyToiframe(this?.point);
        setBallrFlag(true)
        let tooltip = `<span style="color: ${this.point.color}; font-weight:bold;">${this.point.options.pitch_type}</span><br>
          <span style="font-weight:bold;">Pitch No: ${this.point.options.pitch_number
            ? this.point.options.pitch_number
            : "-"
          }</span><br>
          <span style="font-weight:bold;">Pitch No: ${this.point.options.pitch_number
            ? this.point.options.pitch_number
            : "-"
          }</span><br>
          <span style="font-weight:bold;">Pitcher: ${this.point.options.pitcher ? this.point.options.pitcher : "-"
          }</span><br>
          <span style="font-weight:bold;">Batter: ${this.point.options.batter ? this.point.options.batter : "-"
          }</span><br>
          <span style="font-weight:bold;">Play Result: ${this.point.options.pitch_call ? this.point.options.pitch_call : "-"
          }</span><br>
          <span style="font-weight:bold;">Velocity: ${this.point.options && this.point.options.velocity
            ? this.point.options.velocity.toFixed(1)
            : "-"
          }</span><br>
          <span style="font-weight:bold;">Spin Rate: ${this.point.options && this.point.options.spin_rate
            ? Math.round(this.point.options.spin_rate)
            : "-"
          }</span><br>
          <span style="font-weight:bold;">Spin Tilt: ${this.point.options && this.point.options.spin_tilt
            ? this.point.options.spin_tilt
            : "-"
          }</span><br>
          <span style="font-weight:bold;">Axis: ${this.point.options && this.point.options.spin_axis
            ? Math.round(this.point.options.spin_axis)
            : "-"
          }</span><br>
          <span style="font-weight:bold;">Efficiency: ${this.point.options && this.point.options.efficiency
            ? Math.round(this.point.options.efficiency)
            : "-"
          }</span><br>
          <span style="font-weight:bold;">Vert Break: ${this.point.options && this.point.options.v_break
            ? this.point.options.v_break.toFixed(1)
            : "-"
          }</span><br>
          <span style="font-weight:bold;">Ind V Break: ${this.point.options && this.point.options.ind_v_break
            ? this.point.options.ind_v_break.toFixed(1)
            : "-"
          }</span><br>
          <span style="font-weight:bold;">H Break: ${this.point.options && this.point.options.h_break
            ? this.point.options.h_break.toFixed(1)
            : "-"
          }</span><br>
          <span style="font-weight:bold;">V Approach: ${this.point.options && this.point.options.vert_appr_angle
            ? this.point.options.vert_appr_angle.toFixed(1)
            : "-"
          }</span><br>
          <span style="font-weight:bold;">Rel Height: ${this.point.options && this.point.options.release_height
            ? this.point.options.release_height.toFixed(1)
            : "-"
          }</span><br>
          <span style="font-weight:bold;">Rel Side: ${this.point.options && this.point.options.release_side
            ? this.point.options.release_side.toFixed(1)
            : "-"
          }</span><br>
          <span style="font-weight:bold;">Extension: ${this.point.options && this.point.options.ext
            ? this.point.options.ext.toFixed(1)
            : "-"
          }</span><br>
          <span style="font-weight:bold;">Exit Velocity: ${this.point.options && this.point.options.exit_velocity
            ? this.point.options.exit_velocity.toFixed(1)
            : "-"
          }</span><br>
          <span style="font-weight:bold;">Launch Angle: ${this.point.options && this.point.options.launch_angle
            ? this.point.options.launch_angle.toFixed(1)
            : "-"
          }</span><br>
          <span style="font-weight:bold;">Distance: ${this.point.options && this.point.options.distance
            ? Math.round(this.point.options.distance)
            : "-"
          }</span>
          <!-- <div style="color: red" onmouseover="PolarLoadBallR()">BallR</div> -->
        </span>`;
        return tooltip;
      },
      // if hover or click functionality add in tooltip use pointerEvents
      style: {
        pointerEvents: 'auto'
      }
    },
    series: [],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
        },
      ],
    },
  });

  useEffect(() => {
    if (chartData) {
      // increment();
      if (chartData.length !== 0) {
        const seriesData = polarPitchTypeChartData(chartData);
        state.series = [...seriesData];
        Highcharts.chart(id, state);
        // decrement();
      } else {
        state.series = [{ showInLegend: false }];
        Highcharts.chart(id, state);
        // decrement();
      }
    }
  }, [chartData]);

  useEffect(() => {
    if (ballrIframeFlag === "yes") {
      setBallrFlag(false)
      setTooltipData(null);
      closeBallrIframe();
    }
  }, [ballrIframeFlag])

  const chartImage = (id) => {
    isDownloadImgLoader();
    setClickFlag(true)
    if (document.getElementById(`img${id}`)) {
      document.getElementById(`img${id}`).remove()
    }
    setIsChartImg(true)
    downloadChartPrintImg(id, setIsChartImg, setClickFlag, isNotDownloadImgLoader)
  }

  // hover functionality for ballr iframe load
  // window.PolarLoadBallR = function () {
  //   setBallrFlag(true)
  // };

  return (
    <>
      <div className={`ground-chart ${ballrFlag ? "chart-ifream" : ""}`}>
        <div className="ifream-box">
          {ballrFlag && <BallrWebGL tooltipData={tooltipData} id={id} index={tooltipData?._id} />}
        </div>
        <div style={{ height: "470px" }} id={id} />
        {/* <button className="btn blue btn-view" onClick={() => chartImage(id)} disabled={clickFlag} >Download</button> */}
        <div class="chartdownload" onClick={() => chartImage(id)} disabled={clickFlag}><a class="btn white"><i class="fa fa-download" aria-hidden="true"></i></a></div>
      </div>

      {/* chart visual image download */}
      {isChartImg && <PrintImagePopup
        eventProfileData={eventProfileData}
        id={id}
        sendReq={sendReq}
        tabslist={tabslist}
        eventName={eventName}
        pitcherDefault={pitcherDefault}
        profilepage={profilepage} />}
    </>
  );
}

export default PolarChart;
