import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { imagesArr } from "../assets/images";

class Sessionsummarysidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: "Session Summary",
      sourceID: document.location.href.split("/")[4] || "",
      sidebarList: [],
      teamInfo: {
        name: "",
        logo: ""
      }
    };
  }

  componentDidMount() {
    if (this.props.sideData) {
      let routList = [];
      routList.push({
        title: "Session Summary",
        path: "sessionsummary",
        route: "sessionsummary",
      });
      Object.keys(this.props.sideData).map((keyName, i) => {
        routList.push({ title: keyName, path: keyName, route: keyName });
      });
      this.setState((prevState) => ({
        ...prevState,
        sidebarList: routList,
      }));
    }
    if (localStorage.getItem("teamData") !== null) {
      this.setState({
        teamInfo: {
          name: JSON.parse(localStorage.getItem("teamData")).name,
          // logo: JSON.parse(localStorage.getItem("teamData")).hasOwnProperty('teamLogo') ? JSON.parse(localStorage.getItem("teamData")).teamLogo.filePath : ""
        }
      })
    }
  }

  changeSide = (keyname) => {
    this.props.changeside(
      keyname,
      keyname.split(" ").join("") === "sessionsummary" ? true : false
    );
    this.props.history.push("/sessionsummary/" + this.state.sourceID);
  };
  render() {
    const { teamInfo } = this.state
    return (
      <>
        {/* sidebar-wrapper */}
        <div className="sidebar">
          {/* <div className="sidebar-logo">
            <Link to="/">
              <img src={imagesArr.logo} alt="logo" />
            </Link>
            <p>{teamInfo.name}</p>
          </div> */}

          <ul className="list-group">
            {this.state.sidebarList.map((list, i) => {
              if (list.title !== "Team Average" && list.title !== "Session Average") {
                return (
                  <li
                    key={i}
                    className={`${this.props.keyName === list.route && "active"}`}
                  >
                    <a
                      onClick={() => {
                        this.changeSide(list.route);
                      }}
                    >
                      {list.title}
                    </a>
                  </li>
                );
              }
            })}
          </ul>
        </div>
        {/* sidebar-wrapper */}
      </>
    );
  }
}
export default withRouter(Sessionsummarysidebar);
