import React, { Component, forwardRef } from 'react';
import DatePicker from "react-datepicker";
import SliderRange from "rc-slider";
import { imagesArr } from "../../../assets/images";
import range from "./range.json";
import rangeCatchers from "./rangeCatchers.json";
import moment from "moment";
import { constant } from '../../../shared/constant';
import Select from "react-select";

const { createSliderWithTooltip } = SliderRange;
const Range = createSliderWithTooltip(SliderRange.Range);

class filterForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openFcal: false,
            openTcal: false,
        };
    }
    
    handleDateChangeRaw = (e) => {
        e.preventDefault();
    };

    render() {

        const CustomInputFrom = forwardRef(({ value, onClick }, ref) => (
            <button className="form-control custom-date" onClick={onClick} ref={ref}>
              {value ? value : "From"}
            </button>
          ));

        const CustomInputTo = forwardRef(({ value, onClick }, ref) => (
            <button className="form-control custom-date" onClick={onClick} ref={ref}>
              {value ? value : "To"}
            </button>
        ));

        const { sendReq, tabslist, seasonList, minDate, maxDate, inning, filterRes } = this.props;
        // const catchersOption = [{ value: "", label: "All Catchers" }];
        const catchersOption = filterRes?.catchers && filterRes?.catchers.map(_ => ({ value: _, label: _ }));
        // const pitchersOption = [{ value: "", label: "All Pitchers" }];
        const pitchersOption = filterRes?.pitchers && filterRes?.pitchers.map(_ => ({ value: _, label: _ }));
        
        const years = Array.from(
            new Array(81),
            (val, index) => new Date().getFullYear() + 1 - index
        );
        const months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];
        return (
            <div className="filtersection">
                <div className="white-bg filterinfo">
                    <div className="select_box row">
                        <div className="select_box_inner w-25">
                            <label className="common-lable">Year</label>
                            <select
                                name="season"
                                value={sendReq.season}
                                onChange={this.props.handleChange}
                                className="custom-select"
                            >
                                <option value="" disabled>
                                    No Selected
                                </option>
                                {seasonList &&
                                    seasonList.map((opt, i) => {
                                        return <option value={opt.value}>{opt.label}</option>;
                                    })}
                            </select>
                        </div>
                        {tabslist !== 6 && <div className="select_box_inner w-25">
                            <label className="common-lable">
                                Fall/Spring
                            </label>
                            <select
                                name="fall_spring"
                                value={sendReq.fall_spring}
                                className="custom-select"
                                onChange={this.props.handleChange.bind(this)}
                            >
                                <option value="">No selected</option>
                                <option value="fall">Fall</option>
                                <option value="spring">Spring</option>
                                <option value="both">Both</option>
                            </select>
                        </div>}
                        <div className="select_box_inner w-50">
                            <label className="common-lable">
                                Date Range
                            </label>
                            <div className="d-inline-flex">
                                <div className="w-100 mr-15">
                                    <div className="form-group mb-0 d-grid">
                                        <div className="bc_relative">
                                            <DatePicker
                                                ref={(c) => (this._fcal = c)}
                                                renderCustomHeader={({
                                                    date,
                                                    changeYear,
                                                    changeMonth,
                                                    decreaseMonth,
                                                    increaseMonth,
                                                    prevMonthButtonDisabled,
                                                    nextMonthButtonDisabled,
                                                }) => (
                                                    <div
                                                        style={{
                                                            margin: 10,
                                                            display: "flex",
                                                            justifyContent: "center",
                                                        }}
                                                    >
                                                        <a
                                                            className="datearrow"
                                                            onClick={decreaseMonth}
                                                            disabled={
                                                                prevMonthButtonDisabled
                                                            }
                                                        >
                                                            {"<"}
                                                        </a>
                                                        <select
                                                            value={date.getFullYear()}
                                                            onChange={({
                                                                target: { value },
                                                            }) => changeYear(value)}
                                                        >
                                                            {years.map((option) => (
                                                                <option
                                                                    key={option}
                                                                    value={option}
                                                                >
                                                                    {option}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <select
                                                            value={months[date.getMonth()]}
                                                            onChange={({
                                                                target: { value },
                                                            }) =>
                                                                changeMonth(
                                                                    months.indexOf(value)
                                                                )
                                                            }
                                                        >
                                                            {months.map((option) => (
                                                                <option
                                                                    key={option}
                                                                    value={option}
                                                                >
                                                                    {option}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <a
                                                            className="datearrow"
                                                            onClick={increaseMonth}
                                                            disabled={
                                                                nextMonthButtonDisabled
                                                            }
                                                        >
                                                            {">"}
                                                        </a>
                                                    </div>
                                                )}
                                                minDate={moment(minDate).toDate()}
                                                maxDate={moment(maxDate).toDate()}
                                                selected={sendReq.date_from && moment(sendReq.date_from).toDate()}
                                                onChangeRaw={(e) =>
                                                    this.handleDateChangeRaw(e)
                                                }
                                                onChange={(e) =>
                                                    this.props.handleDateChange(
                                                        e,
                                                        "date_from"
                                                    )
                                                }
                                                placeholderText="From"
                                                customInput={<CustomInputFrom />}
                                            />
                                            <img
                                                src={imagesArr.calendar}
                                                className="bc_datepicker-icon"
                                                alt="calendar"
                                                onClick={() => {
                                                    this.state.openFcal = !this.state.openFcal;
                                                    this._fcal.setOpen(this.state.openFcal);
                                                }}
                                            />
                                        </div>
                                        {/* <input name="date_from" value={moment(sendReq.date_from).format("YYYY-MM-DD")} onChange={this.props.handleChange} type="date" className="form-control custom-date" placeholder="From" /> */}
                                    </div>
                                </div>
                                <div className="w-100">
                                    <div className="form-group mb-0 d-grid">
                                        <div className="bc_relative">
                                            <DatePicker
                                                ref={(c) => (this._tcal = c)}
                                                renderCustomHeader={({
                                                    date,
                                                    changeYear,
                                                    changeMonth,
                                                    decreaseMonth,
                                                    increaseMonth,
                                                    prevMonthButtonDisabled,
                                                    nextMonthButtonDisabled,
                                                }) => (
                                                    <div
                                                        style={{
                                                            margin: 10,
                                                            display: "flex",
                                                            justifyContent: "center",
                                                        }}
                                                    >
                                                        <a
                                                            className="datearrow"
                                                            onClick={decreaseMonth}
                                                            disabled={
                                                                prevMonthButtonDisabled
                                                            }
                                                        >
                                                            {"<"}
                                                        </a>
                                                        <select
                                                            value={date.getFullYear()}
                                                            onChange={({
                                                                target: { value },
                                                            }) => changeYear(value)}
                                                        >
                                                            {years.map((option) => (
                                                                <option
                                                                    key={option}
                                                                    value={option}
                                                                >
                                                                    {option}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <select
                                                            value={months[date.getMonth()]}
                                                            onChange={({
                                                                target: { value },
                                                            }) =>
                                                                changeMonth(
                                                                    months.indexOf(value)
                                                                )
                                                            }
                                                        >
                                                            {months.map((option) => (
                                                                <option
                                                                    key={option}
                                                                    value={option}
                                                                >
                                                                    {option}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <a
                                                            className="datearrow"
                                                            onClick={increaseMonth}
                                                            disabled={
                                                                nextMonthButtonDisabled
                                                            }
                                                        >
                                                            {">"}
                                                        </a>
                                                    </div>
                                                )}
                                                selected={sendReq.date_to && moment(sendReq.date_to).toDate()}
                                                minDate={sendReq.date_from ? moment(sendReq.date_from)
                                                    .subtract(0, "days")
                                                    .toDate() : moment(minDate).toDate()}
                                                maxDate={moment(maxDate).toDate()}
                                                onChangeRaw={(e) =>
                                                    this.handleDateChangeRaw(e)
                                                }
                                                onChange={(e) =>
                                                    this.props.handleDateChange(e, "date_to")
                                                }
                                                placeholderText="To"
                                                customInput={<CustomInputTo />}
                                            />
                                            <img
                                                src={imagesArr.calendar}
                                                className="bc_datepicker-icon"
                                                alt="calendar"
                                                onClick={() => {
                                                    this.state.openTcal = !this.state.openTcal;
                                                    this._tcal.setOpen(this.state.openTcal);
                                                }}
                                            />
                                        </div>
                                        {/* <input name="date_to" value={moment(sendReq.date_to).format("YYYY-MM-DD")} onChange={this.props.handleChange} type="date" className="form-control custom-date" placeholder="From" /> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {tabslist !== 6 && (<div className="select_box_inner w-25">
                            <label className="common-lable">
                                Event type
                            </label>
                            <select
                                name="event_type"
                                value={sendReq.event_type}
                                onChange={this.props.handleChange}
                                className="custom-select"
                            >
                                <option selected value="">
                                    No selected
                                </option>
                                <option value="Game">Game</option>
                                <option value="Scrimmage">Scrimmage</option>
                                <option value="Bullpen">Bullpen</option>
                                <option value="BP">BP</option>
                            </select>
                        </div>)}
                        {(tabslist !== 6 && tabslist !== 9) && (<div className="select_box_inner w-25">
                            <label className="common-lable">Hitting/Pitching</label>
                            <select
                                name="type"
                                value={sendReq.type}
                                onChange={this.props.handleChange}
                                className="custom-select"
                            >
                                <option value="Hitting">Hitting</option>
                                <option value="Pitching">Pitching</option>
                            </select>
                        </div>)}
                        {tabslist === 9 && (<div className="select_box_inner w-25">
                            <label className="common-lable">Pitchers</label>
                            <div className="serch-box">
                                <Select
                                    className="customize-select"
                                    classNamePrefix="customize-select"
                                    onChange={(e) => this.props.handlePICChange(e, "pitchers")}
                                    value={pitchersOption && pitchersOption.find(e => e.value === sendReq.pitchers) || null}
                                    options={pitchersOption}
                                />
                            </div>
                        </div>)}
                        {tabslist !== 6 && (<div className="select_box_inner w-25">
                            <label className="common-lable">
                                Pitcher Throws
                            </label>
                            <select
                                name="pitcher_throws"
                                value={sendReq.pitcher_throws}
                                onChange={this.props.handleChange}
                                className="custom-select"
                            >
                                <option selected value="">
                                    No selected
                                </option>
                                <option value="L">L</option>
                                <option value="R">R</option>
                            </select>
                        </div>)}
                        {tabslist !== 6 && (<div className="select_box_inner w-25">
                            <label className="common-lable">
                                Batter Stands
                            </label>
                            <select
                                name="batter_stands"
                                value={sendReq.batter_stands}
                                onChange={this.props.handleChange}
                                className="custom-select"
                            >
                                <option selected value="">
                                    No selected
                                </option>
                                <option value="L">L</option>
                                <option value="R">R</option>
                            </select>
                        </div>)}

                        {tabslist !== 6 && (<div className="select_box_inner w-25">
                            <label className="common-lable">
                                Pitch Type
                            </label>
                            <select
                                name="pitch_type"
                                value={sendReq.pitch_type}
                                onChange={this.props.handleChange}
                                className="custom-select"
                            >
                                {constant.softball ?
                                    <>
                                        <option selected value="">No selected</option>
                                        <option value="Fastball">Fastball</option>
                                        <option value="Drop Ball">Drop Ball</option>
                                        <option value="Rise Ball">Rise Ball</option>
                                        <option value="Curveball">Curveball</option>
                                        <option value="Screw Ball">Screw Ball</option>
                                        <option value="Changeup">Changeup</option>
                                        <option value="Drop Curve">Drop Curve</option>
                                    </> :
                                    <>
                                        <option selected value="">No selected</option>
                                        <option value="Fastball">Fastball</option>
                                        <option value="Curveball">Curveball</option>
                                        <option value="Slider">Slider</option>
                                        <option value="Changeup">Changeup</option>
                                        <option value="Sinker">Sinker</option>
                                        <option value="Cutter">Cutter</option>
                                        <option value="Splitter">Splitter</option>
                                        <option value="Knuckleball">Knuckleball</option>
                                        <option value="Fastballs">Fastballs</option>
                                        <option value="Breaking">Breaking</option>
                                        <option value="Offspeed">Offspeed</option>
                                    </>}
                            </select>
                        </div>)}
                        {(tabslist !== 6 && tabslist !== 9) && (<div className="select_box_inner w-25">
                            <label className="common-lable">
                                Play Result
                            </label>
                            <select
                                name="play_result"
                                value={sendReq.play_result}
                                onChange={this.props.handleChange}
                                className="custom-select"
                            >
                                <option selected value="">
                                    No selected
                                </option>
                                <option value="All Pitches">All Pitches</option>
                                <option value="Swings">Swings</option>
                                <option value="Swing and Miss">
                                    Swing and Miss
                                </option>
                                <option value="Take">Take</option>
                                <option value="Base Hit">Base Hit</option>
                                <option value="Single">Single</option>
                                <option value="Double">Double</option>
                                <option value="Triple">Triple</option>
                                <option value="Home Run">Home Run</option>
                                <option value="All BIP">All BIP</option>
                            </select>
                        </div>)}
                        {tabslist !== 6 && (<div className="select_box_inner w-25">
                            <label className="common-lable">Zone</label>
                            <select
                                name="zone"
                                value={sendReq.zone}
                                onChange={this.props.handleChange}
                                className="custom-select"
                            >
                                <option selected value="">
                                    No selected
                                </option>
                                <option value="In Zone">In Zone</option>
                                <option value="Out of Zone">
                                    Out of Zone
                                </option>
                                <option value="Heart">Heart</option>
                                <option value="Edge">Edge</option>
                                <option value="Chase">Chase</option>
                            </select>
                        </div>)}

                        {tabslist !== 6 && (<div className="select_box_inner w-25">
                            <label className="common-lable">Count</label>
                            <select
                                name="count"
                                value={sendReq.count}
                                onChange={this.props.handleChange}
                                className="custom-select"
                            >
                                <option value="">No selected</option>
                                <option value="Hitter's Count">
                                    Hitter's Count
                                </option>
                                <option value="Pitcher's Count">
                                    Pitcher's Count
                                </option>
                                <option value="Two Strike Counts">Two Strike Counts</option>
                                <option value="0-0">0-0</option>
                                <option value="0-1">0-1</option>
                                <option value="0-2">0-2</option>
                                <option value="1-0">1-0</option>
                                <option value="1-1">1-1</option>
                                <option value="1-2">1-2</option>
                                <option value="2-0">2-0</option>
                                <option value="2-1">2-1</option>
                                <option value="2-2">2-2</option>
                                <option value="3-0">3-0</option>
                                <option value="3-1">3-1</option>
                                <option value="3-2">3-2</option>
                            </select>
                        </div>)}
                        {tabslist === 9 && (<div className="select_box_inner w-25">
                            <label className="common-lable">Inning</label>
                            <Select
                                className="customize-select"
                                classNamePrefix="customize-select"
                                placeholder="No Selected"
                                name="inning"
                                onChange={(e) => this.props.handlePICChange(e, "inning")}
                                value={inning.find(e => e.value === sendReq.inning) || null}
                                options={inning}
                            />
                        </div>)}
                        {localStorage.getItem("activeType") === "Pitching" && (<div className="select_box_inner w-25">
                            <label className="common-lable">Catcher</label>
                            <div className="serch-box">
                                <Select
                                    className="customize-select"
                                    classNamePrefix="customize-select"
                                    onChange={(e) => this.props.handlePICChange(e, "catcher")}
                                    value={catchersOption && catchersOption.find(e => e.value === sendReq.catcher) || null}
                                    options={catchersOption}
                                />
                            </div>
                        </div>)}
                        {/* {<div className="select_box_inner w-25">
                            <label className="common-lable">
                                Home/Away
                                </label>
                            <select
                                name="home_away"
                                value={sendReq.home_away}
                                className="custom-select"
                                onChange={this.props.handleChange}
                            >
                                <option value="">No selected</option>
                                <option value="Home">Home</option>
                                <option value="Away">Away</option>
                            </select>
                        </div>} */}

                        {/* {tabslist !== 6 && (
                            <div className="select_box_inner w-25">
                                <label className="common-lable">
                                    Pitch Speed
                                </label>
                                <Range
                                    min={0}
                                    max={100}
                                    value={[
                                        sendReq.velocity_from || 0,
                                        sendReq.velocity_to || 0,
                                    ]}
                                    defaultValue={[0, 0]}
                                    tipFormatter={(value) => `${value}`}
                                    onChange={(e) =>
                                        this.props.handleRangeChange(e)
                                    }
                                />
                            </div>
                        )}
                        {tabslist !== 6 && (
                            <div className="select_box_inner w-25">
                                <label className="common-lable">
                                    Spin Rate
                                </label>
                                <Range
                                    min={0}
                                    max={3500}
                                    value={[
                                        sendReq.spin_rate_from || 0,
                                        sendReq.spin_rate_to || 0,
                                    ]}
                                    defaultValue={[0, 0]}
                                    tipFormatter={(value) => `${value}`}
                                    onChange={(e) =>
                                        this.props.handleRangeChange(e)
                                    }
                                />
                            </div>
                        )} */}
                    </div>
                    <div className="select_box row">
                        {tabslist !== 6 && (
                            <>
                                {tabslist === 9 ?
                                    rangeCatchers.map((r, i) => {
                                        return (
                                            <>
                                                <div className="select_box_inner w-25" key={i}>
                                                    <label className="common-lable">
                                                        {r.name}
                                                    </label>
                                                    <Range
                                                        min={r.min}
                                                        max={r.max}
                                                        value={[
                                                            sendReq[r.from] || 0,
                                                            sendReq[r.to] || 0,
                                                        ]}
                                                        defaultValue={[0, 0]}
                                                        tipFormatter={(value) => `${value}`}
                                                        onChange={(e) =>
                                                            this.props.handleRangeChange(e, r.from, r.to)
                                                        }
                                                    />
                                                </div>
                                            </>
                                        )
                                    }) :
                                    range.map((r, i) => {
                                        return (
                                            <>
                                                <div className="select_box_inner w-25" key={i}>
                                                    <label className="common-lable">
                                                        {r.name}
                                                    </label>
                                                    <Range
                                                        min={r.min}
                                                        max={r.max}
                                                        value={[
                                                            sendReq[r.from] || 0,
                                                            sendReq[r.to] || 0,
                                                        ]}
                                                        defaultValue={[0, 0]}
                                                        tipFormatter={(value) => `${value}`}
                                                        onChange={(e) =>
                                                            this.props.handleRangeChange(e, r.from, r.to)
                                                        }
                                                    />
                                                </div>
                                            </>
                                        )
                                    })}
                            </>
                        )}
                        {/* {tabslist !== 6 && (
                                <div className="select_box_inner w-25">
                                    <label className="common-lable">
                                        Exit Velocity
                                    </label>
                                    <Range
                                        min={0}
                                        max={120}
                                        value={[
                                            sendReq.exit_velocity_from || 0,
                                            sendReq.exit_velocity_to || 0,
                                        ]}
                                        defaultValue={[0, 0]}
                                        tipFormatter={(value) => `${value}`}
                                        onChange={(e) =>
                                            this.props.handleVeloRangeChange(e)
                                        }
                                    />
                                </div>
                            )} */}
                    </div>
                    <div className="d-flex align-items-center justify-content-center formbutton">
                        <a
                            className="btn blue mr-15 btnshadow"
                            onClick={() => this.props.applyFilter()}
                        >
                            {" "}
                                Apply{" "}
                        </a>
                        <a
                            className="btn white"
                            onClick={this.props.handleCancelToggle}
                        >
                            {" "}
                                Cancel{" "}
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default filterForm;
