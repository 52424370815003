import React, { Component } from "react";
import { imagesArr } from "../../../assets/images";
import { getGameSummaryData } from "../../../store/actions";
import { connect } from "react-redux";
import PitcherLogTable from "./components/pitcher-log-table";
import Select from "react-select";
import { getArrayOfPrintAreaIds, uniqueEntriesFromArray } from "../../../shared/function";
import { printToPDFMultiple } from "../../../shared/printReportUtils";
import Loader from "../../../shared/loader";
import { constant } from "../../../shared/constant";

let filterCountArr = ["pitcher_name", "batter_name", "pitcher_team"];
class PitchLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: "",
      isshow: false,
      pitchLogTableData: [],
      pitcher_name: [],
      batter_name: [],
      pitcher_team: [],
      play_result: [],
      balls: [
        {value: "0", label: "0"},
        {value: "1", label: "1"},
        {value: "2", label: "2"},
        {value: "3", label: "3"}
      ],
      strikes: [
        {value: "0", label: "0"},
        {value: "1", label: "1"},
        {value: "2", label: "2"}
      ],
      outs: [
        {value: "0", label: "0"},
        {value: "1", label: "1"},
        {value: "2", label: "2"}
      ],
      inning: [
        {value: "1", label: "1"},
        {value: "2", label: "2"},
        {value: "3", label: "3"},
        {value: "4", label: "4"},
        {value: "5", label: "5"},
        {value: "6", label: "6"},
        {value: "7", label: "7"},
        {value: "8", label: "8"},
        {value: "9", label: "9"},
      ],
      pitch_typeOption: constant.softball ?
        [
          { value: "Fastball", label: "Fastball" },
          { value: "Drop Ball", label: "Drop Ball" },
          { value: "Rise Ball", label: "Rise Ball" },
          { value: "Drop Curve", label: "Drop Curve" },
          { value: "Curveball", label: "Curveball" },
          { value: "Screw Ball", label: "Screw Ball" },
          { value: "Changeup", label: "Changeup" }
        ] :
        [
          { value: "Fastballs", label: "Fastballs" },
          { value: "Breaking", label: "Breaking" },
          { value: "Offspeed", label: "Offspeed" },
          { value: "Fastball", label: "Fastball" },
          { value: "Sinker", label: "Sinker" },
          { value: "Curveball", label: "Curveball" },
          { value: "Slider", label: "Slider" },
          { value: "Changeup", label: "Changeup" },
          { value: "Cutter", label: "Cutter" },
          { value: "Splitter", label: "Splitter" },
          { value: "Knuckleball", label: "Knuckleball" },
        ],
      filterFlag: true,
      filterData: {
        sourceId: document.location.href.split("/")[4],
        tab: "pitch_log",
        filter: {
          pitcher_name: "",
          batter_name: "",
          pitcher_team: "",
          play_result: "",
          pitch_type: [],
          balls: "",
          strikes: "",
          outs: "",
          inning: ""
        },
      },
      pitch_type: "",
      isPrinting: false
    };
    this.handleToggle = this.handleToggle.bind(this);
    this.handleCancelToggle = this.handleCancelToggle.bind(this);
  }

  componentDidMount() {
    let sorceid = document.location.href.split("/")[4];
    if (sorceid === undefined) window.location.href = "/";
    else {
      this.pitchLogData(this.state.filterData);
    }
  }

  // fetch Pitch log data
  pitchLogData = () => {
    let data = this.state.filterData;
    this.props.fetchSummaryData(data, (res) => {
      const pitchersUniqData = [{ value: "", label: "All Pitchers" }];
      const battersUniqData = [{ value: "", label: "All Batters" }];
      const teamUniqData = [{ value: "", label: "All Teams" }];
      // const resultUniqData = [{ value: "", label: "All Play Result" }];
      const resultUniqData = [];

      uniqueEntriesFromArray(res.data.result.scrimmage_pitch_log_details, "pitcher").map((data) => {
        pitchersUniqData.push({
          value: data,
          label: data,
        });
      });
      uniqueEntriesFromArray(res.data.result.scrimmage_pitch_log_details, "batter").map((data) => {
        battersUniqData.push({
          value: data,
          label: data,
        });
      });
      uniqueEntriesFromArray(res.data.result.scrimmage_pitch_log_details, "team").map((data) => {
        teamUniqData.push({
          value: data,
          label: data,
        });
      });
      uniqueEntriesFromArray(res.data.result.scrimmage_pitch_log_details, "result").map((data) => {
        if (data !== undefined) {
          resultUniqData.push({
            value: data,
            label: data,
          });
        }
      });
      this.setState({
        pitchLogTableData: res.data.result.scrimmage_pitch_log_details,
      });
      if (this.state.filterFlag) {
        // filterCountArr = [];
        // filterCountArr.push("pitcher_name", "batter_name", "pitcher_team")
        this.setState({
          pitcher_name: pitchersUniqData,
          batter_name: battersUniqData,
          pitcher_team: teamUniqData,
          play_result: this.sortByPitchType(resultUniqData),
        });
      }
      // this.props.reduceDataCount()
    });
  };

  //reorder playresult
  sortByPitchType = (data) => {
    let Order = ['BallCalled', 'StrikeCalled', 'StrikeSwinging', 'Foul', 'Single', 'Double', 'Triple', 'HomeRun', 'Out', 'Error', "Undefined", null]
    var ordering = {};
    for (var i = 0; i < Order.length; i++) {
      ordering[Order[i]] = i;
      data.sort(function (a, b) {
        return (ordering[a.value] - ordering[b.value]) /* || a.name.localeCompare(b.name); */
      });
    }
    return data
  }

  handleCallback = (index) => {
    this.setState({
      tabs: index,
    });
  };

  handleToggle() {
    this.setState({
      isshow: !this.state.isshow,
    });
  }

  handleCancelToggle() {
    filterCountArr = [];
    filterCountArr.push("pitcher_name", "batter_name", "pitcher_team")
    this.setState((prevState) => ({
      ...prevState,
      isshow: !this.state.isshow,
      filterData: {
        sourceId: document.location.href.split("/")[4],
        tab: "pitch_log",
        filter: {
          ...prevState.filterData.filter,
          pitcher_name: "",
          batter_name: "",
          pitcher_team: "",
          play_result: "",
          pitch_type: [],
          balls: "",
          strikes: "",
          outs: "",
          inning: ""
        },
      },
      pitch_type: ""
    }), () => {
      this.pitchLogData(this.state.filterData);
    });
  }

  // filter change
  handleChange = (e, data) => {
    let old = filterCountArr.some((item) => {
      return item === e;
    });

    if (!old) {
      filterCountArr.push(e);
    }
    this.setState((prevState) => ({
      ...prevState,
      filterData: {
        sourceId: document.location.href.split("/")[4],
        tab: "pitch_log",
        filter: {
          ...prevState.filterData.filter,
          [e]: data.value === "Fastballs" ? ['Fastball', 'Sinker', 'Cutter'] : data.value === "Breaking" ? ['Curveball', 'Slider'] : data.value === "Offspeed" ? ['Changeup', 'Splitter', 'Knuckleball'] : e === "pitch_type" ? [data.value] : data.value,
        },
      },
      pitch_type: e === "pitch_type" ? data.value : this.state.pitch_type
    }));
  };

  // apply filter
  applyFilter() {
    this.setState({ filterFlag: false });
    this.pitchLogData(this.state.filterData);
  }

  // remove filter
  removeFilter = (e) => {
    filterCountArr.map((d, i) => {
      if (!["pitcher_name", "batter_name", "pitcher_team"].find(p => p === e)) {
        if (d === e) {
          filterCountArr.splice(i, 1);
        }
      }
    });
    this.setState(
      (prevState) => ({
        ...prevState,
        filterData: {
          sourceId: document.location.href.split("/")[4],
          tab: "pitch_log",
          filter: {
            ...prevState.filterData.filter,
            [e]: e === "pitch_type" ? [] : "",
          },
        },
        pitch_type: e === "pitch_type" ? "" : this.state.pitch_type
      }),
      () => {
        this.pitchLogData(this.state.filterData);
      }
    );
  };

  print = async () => {
    await this.setState({ isPrinting: true })
    await this.updatefontsize()
    await printToPDFMultiple(`pitchersLog`, getArrayOfPrintAreaIds(this.state.pitchLogTableData));
    this.setState({ isPrinting: false })
  }

  printAll = () => {
    this.props.printAll();
  }
  async updatefontsize(loopCount = 0) {
    const content = document.querySelector(".print--table--wrp .ant-table-content");
    const table = content.querySelector("table")
    const thList = document.querySelectorAll(".print--table--wrp .ant-table-thead > tr > th")
    const tdList = document.querySelectorAll(".print--table--wrp tbody td")
    await new Promise((resolve) => {
      setTimeout(() => resolve(true), 100);
    })
    if (content.offsetWidth < table.offsetWidth) {
      for (var i = 0; i < thList.length; i++) {
        thList[i].style.fontSize = `${14 - loopCount}px`;
        if (loopCount - 4 > 0) thList[i].style.padding = `${4 - loopCount}px`;
      }
      for (var i = 0; i < tdList.length; i++) {
        tdList[i].style.fontSize = `${14 - loopCount}px`;
        tdList[i].style.padding = `${16 - loopCount}px ${10 - loopCount}px`;
      }
      await this.updatefontsize(loopCount + 1)
    }
    return true
  }

  render() {
    const tabs = this.state.tabs;
    let {
      pitchLogTableData,
      pitcher_name,
      batter_name,
      pitcher_team,
      play_result,
      filterData,
      pitch_type,
      balls,
      strikes,
      outs,
      inning,
      pitch_typeOption
    } = this.state;
    return (
      <>
        {this.state.isPrinting && <Loader />}
        <section className="pb-0">
          <div className="container-fluid">
            <div className="common-title">
              <h2>Pitch Log</h2>
              <div className="right-side">
                {!(this.state.isPrinting || this.props.isPrint) && <div className="dropdown">
                  <span className="printer"><img src={imagesArr.printer} alt="print" /></span>
                  <div className="dropdown-content">
                    <ul>
                      <li onClick={() => this.print()}>Print</li>
                      {/* <li onClick={() => this.printAll()}>Print All</li> */}
                    </ul>
                  </div>
                </div>}
                <div className="btnfilter">
                  <button
                    className="btn white"
                    onClick={this.handleToggle}
                  >
                    <div className="d-none d-sm-block">
                      <sup>{filterCountArr.length}</sup>
                    </div>
                    <span>
                      <img
                        src={imagesArr.iconfilter}
                        alt="filter"
                        className="d-inline"
                      />
                    </span>
                    <span className="d-sm-none">
                      Filter<span>({filterCountArr.length})</span>
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className="filtersection">
              <div className="d-flex align-items-center">
                <h3>Applied Filters:</h3>
                {Object.entries(filterCountArr).map((key, value) => {
                  let name = (key[1] === "pitcher_name" || key[1] === "batter_name" || key[1] === "pitcher_team") ? this.state.filterData.filter[key[1]] ? this.state.filterData.filter[key[1]] : key[1] === "pitcher_name" ? "All Pitchers" : key[1] === "batter_name" ? "All Batters" : "All Teams" : key[1] === "pitch_type" ? this.state.pitch_type : this.state.filterData.filter[key[1]];
                  return (
                    <span key={value} className="filtertags">
                      {name}
                      {(name !== "All Pitchers" && name !== "All Batters" && name !== "All Teams") &&
                        <img
                          src={imagesArr.iconclose}
                          alt="close"
                          className="d-inline"
                          onClick={this.removeFilter.bind(
                            this,
                            key[1]
                          )}
                        />}
                    </span>
                  );
                })}
              </div>
            </div>

            {/* filter */}
            {this.state.isshow && (
              <>
                <div className="filtersection">
                  <div className="white-bg filterinfo">
                    <div className="row">
                      <div className="w-25">
                        <label className="common-lable">Pitchers</label>
                        <div className="serch-box">
                          <Select
                            menuPlacement="auto"
                            className='customize-select'
                            classNamePrefix='customize-select'
                            onChange={this.handleChange.bind(this,"pitcher_name")}
                            name="pitcher_name"
                            value={pitcher_name.find(e => e.value === filterData.filter.pitcher_name)}
                            options={pitcher_name}
                          />
                        </div>
                      </div>
                      <div className="w-25">
                        <label className="common-lable">Batters</label>
                        <div className="serch-box">
                          <Select
                            menuPlacement="auto"
                            className='customize-select'
                            classNamePrefix='customize-select'
                            onChange={this.handleChange.bind(this,"batter_name")}
                            value={batter_name.find(e => e.value === filterData.filter.batter_name)}
                            name="batter_name"
                            options={batter_name}
                          />
                        </div>
                      </div>
                      <div className="w-25">
                        <label className="common-lable">Teams</label>
                        <div className="serch-box">
                          <Select
                            menuPlacement="auto"
                            className='customize-select'
                            classNamePrefix='customize-select'
                            // placeholder="All Teams"
                            onChange={this.handleChange.bind(this,"pitcher_team")}
                            name="pitcher_team"
                            value={pitcher_team.find(e => e.value === filterData.filter.pitcher_team)}
                            options={pitcher_team}
                          />
                        </div>
                      </div>
                      <div className="w-25">
                        <label className="common-lable">Pitch Type</label>
                        <div className="serch-box">
                          <Select
                            menuPlacement="auto"                    
                            className='customize-select'
                            classNamePrefix='customize-select'
                            // placeholder="All Teams"
                            onChange={this.handleChange.bind(this,"pitch_type")}
                            name="pitch_type"
                            value={pitch_typeOption.find(e => e.value === pitch_type) || null}
                            options={pitch_typeOption}
                             />
                        </div>
                      </div>
                      <div className="w-25">
                        <label className="common-lable">Play Result</label>
                        <Select
                          menuPlacement="auto"
                          className='customize-select'
                          classNamePrefix='customize-select'
                          // placeholder="Not Selected"
                          onChange={this.handleChange.bind(this,"play_result")}
                          name="play_result"
                          value={!!play_result.find(e => e.value === filterData.filter.play_result) ? play_result.find(e => e.value === filterData.filter.play_result) : null}
                          options={play_result}
                        />
                      </div>
                      <div className="w-25">
                        <label className="common-lable">Balls</label>
                        <div className="serch-box">
                          <Select
                            menuPlacement="auto"                    
                            className='customize-select'
                            classNamePrefix='customize-select'
                            // placeholder="All Teams"
                            onChange={this.handleChange.bind(this,"balls")}
                            name="balls"
                            value={balls.find(e => e.value === filterData.filter.balls) || null}
                            options={balls}
                             />
                        </div>
                      </div>
                      <div className="w-25">
                        <label className="common-lable">Strikes</label>
                        <div className="serch-box">
                          <Select
                            menuPlacement="auto"                    
                            className='customize-select'
                            classNamePrefix='customize-select'
                            // placeholder="All Teams"
                            onChange={this.handleChange.bind(this,"strikes")}
                            name="strikes"
                            value={strikes.find(e => e.value === filterData.filter.strikes) || null}
                            options={strikes}
                             />
                        </div>
                      </div>
                      <div className="w-25">
                        <label className="common-lable">Outs</label>
                        <div className="serch-box">
                          <Select
                            menuPlacement="auto"                    
                            className='customize-select'
                            classNamePrefix='customize-select'
                            // placeholder="All Teams"
                            onChange={this.handleChange.bind(this,"outs")}
                            name="outs"
                            value={outs.find(e => e.value === filterData.filter.outs) || null}
                            options={outs}
                             />
                        </div>
                      </div>
                      <div className="w-25">
                        <label className="common-lable">Inning</label>
                        <div className="serch-box">
                          <Select
                            menuPlacement="auto"                    
                            className='customize-select'
                            classNamePrefix='customize-select'
                            // placeholder="All Teams"
                            onChange={this.handleChange.bind(this,"inning")}
                            name="inning"
                            value={inning.find(e => e.value === filterData.filter.inning) || null}
                            options={inning}
                             />
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-center formbutton">
                      <button
                        className="btn blue mr-15 btnshadow"
                        onClick={this.applyFilter.bind(this)}
                      >
                        Apply
                      </button>
                      <button
                        className="btn white"
                        onClick={this.handleCancelToggle}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}

            <div className="" id="pitchLogPrintArea">
              <PitcherLogTable tableData={pitchLogTableData} isPrinting={this.state.isPrinting || this.props.isPrint} />
            </div>
          </div>
        </section>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    teamData: state.teamData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchSummaryData: (data, next) => dispatch(getGameSummaryData(data, next)),
});
export default connect(mapStateToProps, mapDispatchToProps)(PitchLog);
