import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { imagesArr } from "../../assets/images";
import Header from "../../containers/header";
import Teamsummary from "../teamsummary/teamsummary";
import Development from "../development/development";
import Battedball from "./battedBall";
import Breakmovement from "./breakMovement";
import Pitcharsenal from "./pitchArsenal";
import Plan from "./plan";
import SwingDataBlast from "./SwingData";
import PointOfContact from "./ContactTab";
import Platediscipline from "./plateDiscipline";
import Releasepoint from "./releasePoint";
import HeatMapTab from "./HeatMap";
import ProfileEvent from "./common/profileEvent";
import TabPanel from "./common/tabPanel";
import AppliedFilter from "./common/appliedFilter";
import FilterForm from "./common/filterForm";
import {
  getProfileData,
  getFilterData,
  getEventData,
  getUserRoleData,
  getDashboardData,
} from "../../store/actions";
import moment from "moment";
import { betweenAndEqual, filterScatterPlotNullValues, getActiveTabData, getSeasonFilterDefaultValues } from "../../shared/function";
import { printToPDFMultiple } from "../../shared/printReportUtils";
import Loader from "../../shared/loader";
import CatchingTab from "./Catching";

// import "rc-slider/assets/index.css";

var arr = [];

class profiledashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: "",
      tabslist: localStorage.getItem("activeType") === "Pitching" ? 0 : 1,
      isshow: true,
      profileData: [],
      eventReq: {
        playerId: "",
        date: "",
        eventType: "",
      },
      events: [],
      uid: "",
      sendReq: {
        tab:
          localStorage.getItem("activeType") === "Pitching"
            ? "pitch_outcomes"
            : "batted_ball",
        type: localStorage.getItem("activeType"),
        pitcher_throws: "",
        batter_stands: "",
        pitch_type: "",
        play_result: "",
        zone: "",
        event_type: "",
        date_from: "",
        date_to: "",
        season: "",
        contour_graph: "",
        count: "",
        home_away: "",
        fall_spring: "",
        velocity_to: "",
        velocity_from: "",
        exit_velocity_to: "",
        exit_velocity_from: "",
        spin_rate_to: "",
        spin_rate_from: "",
        launch_angle_to: "",
        launch_angle_from: "",
        efficiency_to: "",
        efficiency_from: "",
        vertical_break_to: "",
        vertical_break_from: "",
        induced_vertical_break_to: "",
        induced_vertical_break_from: "",
        horizontal_break_to: "",
        horizontal_break_from: "",
        vertical_approach_angle_to: "",
        vertical_approach_angle_from: "",
        horz_approach_angle_to: "",
        horz_approach_angle_from: "",
        release_height_to: "",
        release_height_from: "",
        extension_to: "",
        extension_from: "",
        heat_map_0: localStorage.getItem("activeType") === "Pitching" ? "" : "Avg EV",
        heat_map_1: localStorage.getItem("activeType") === "Pitching" ? "" : "Avg EV",
        heat_map_2: localStorage.getItem("activeType") === "Pitching" ? "" : "Hard Hit",
        heat_map_3: localStorage.getItem("activeType") === "Pitching" ? "" : "Damage",
        histrogram_field:"exit_velocity",
        histrogram_bucket:5,
        pitchers: "",
        inning: "",
        catchers: ""
      },
      backupReq: {
        tab:
          localStorage.getItem("activeType") === "Pitching"
            ? "pitch_outcomes"
            : "batted_ball",
        type: localStorage.getItem("activeType"),
        pitcher_throws: "",
        batter_stands: "",
        pitch_type: "",
        play_result: "",
        zone: "",
        event_type: "",
        date_from: "",
        date_to: "",
        season: "",
        contour_graph: "",
        count: "",
        home_away: "",
        fall_spring: "",
        velocity_to: "",
        velocity_from: "",
        exit_velocity_to: "",
        exit_velocity_from: "",
        spin_rate_to: "",
        spin_rate_from: "",
        launch_angle_to: "",
        launch_angle_from: "",
        efficiency_to: "",
        efficiency_from: "",
        vertical_break_to: "",
        vertical_break_from: "",
        induced_vertical_break_to: "",
        induced_vertical_break_from: "",
        horizontal_break_to: "",
        horizontal_break_from: "",
        vertical_approach_angle_to: "",
        vertical_approach_angle_from: "",
        horz_approach_angle_to: "",
        horz_approach_angle_from: "",
        release_height_to: "",
        release_height_from: "",
        extension_to: "",
        extension_from: "",
        heat_map_0: localStorage.getItem("activeType") === "Pitching" ? "" : "Avg EV",
        heat_map_1: localStorage.getItem("activeType") === "Pitching" ? "" : "Avg EV",
        heat_map_2: localStorage.getItem("activeType") === "Pitching" ? "" : "Hard Hit",
        heat_map_3: localStorage.getItem("activeType") === "Pitching" ? "" : "Damage",
        histrogram_field:"exit_velocity",
        histrogram_bucket:5,
        pitchers: "",
        inning: "",
        catcher: ""
      },
      filterRes: [],
      teamInfo: {
        name: "",
        logo: "",
      },
      prevButton: 0,
      nextButton: 0,
      role: false,
      isSeasonFilter: false,
      isFallSpringFilter: false,
      filterIndex: 0,
      profileList: [],
      seasonList: [],
      minDate: "01/01/" + (Number(moment().format("YYYY")) - 1),
      maxDate: "31/12/" + (Number(moment().format("YYYY")) + 1),
      oldData:null,
      isFilter: false,
      parentOldData: null,
      isPrinting: false,
      isDownloadImg: false,
      inning: [
        { value: "1", label: "1" },
        { value: "2", label: "2" },
        { value: "3", label: "3" },
        { value: "4", label: "4" },
        { value: "5", label: "5" },
        { value: "6", label: "6" },
        { value: "7", label: "7" },
        { value: "8", label: "8" },
        { value: "9", label: "9" },
      ],
      pitchrIframeFlag: "",
    };
    this.handleTabs = this.handleTabs.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
  }

  componentDidMount() {
    this.renderPageSummary();
  }

  renderPageSummary = () => {
    this.props.loader(true);
    // this.fetchSummary();
    // this.fetchUserRoleData();
    let url = new URL(document.location);
    let data = {
      count: 0,
      offset: 0,
      type:
        localStorage.getItem("activeType") === "Hitting"
          ? "hitters"
          : "pitchers",
      season: localStorage.getItem("activeSeason"),
    };

    this.props.fetchProfileData(data, (res) => {
      this.setState(
        {
          filterIndex: res.team_profiles.findIndex(
            (obj) => obj._id === url.pathname.split("/")[2]
          ),
          profileList: res.team_profiles,
        },
        () => {
          this.fetchSummary();
        }
      );
    });
  };

  fetchSummary = () => {
    let url = new URL(document.location);
    let userName = url.pathname.split("/")[3];
    this.setState(
      {
        uid: url.pathname.split("/")[2],
        eventReq: {
          playerId: url.pathname.split("/")[2],
          date: "",
          eventType: "",
        },
      },
      () => {
        // let data = {
        //     q: userName
        // }
        let data = {
          playerId: this.state.eventReq.playerId,
        };
        this.props.fetchProfileDashboardData(data, (res) => {
          if (res.result.Player_data[0].hasOwnProperty('age')) {
            res.result.Player_data[0].age = this.calculate_age(
              res.result.Player_data[0].age
            );
          }

          this.setState((prevState) => (
            {
              ...prevState,
              eventProfileData:
                res.result.Player_data && res.result.Player_data[0],
              tabslist:
                localStorage.getItem("activeType") === "Pitching" ? 0 : 1,
              sendReq: {
                ...prevState.sendReq,
                tab:
                  localStorage.getItem("activeType") === "Pitching"
                    ? "pitch_outcomes"
                    : "batted_ball",
                type: localStorage.getItem("activeType"),
              },
            }),
            () => {
              arr = [];
              localStorage.getItem("activeType") === "Pitching"
                    ? arr.push("type", "season", "fall_spring")
                    : arr.push("type", "season", "fall_spring", "heat_map_0");
              // this.setFilter(this.updateRequest(this.state.sendReq));
              this.fetchUserRoleData();
            }
          );
        });
      }
    );

    if (localStorage.getItem("teamData") !== null) {
      this.setState({
        teamInfo: {
          name: JSON.parse(localStorage.getItem("teamData")).name,
          logo: JSON.parse(localStorage.getItem("teamData")).hasOwnProperty(
            "teamLogo"
          )
            ? JSON.parse(localStorage.getItem("teamData")).teamLogo?.filePath
              ? JSON.parse(localStorage.getItem("teamData")).teamLogo?.filePath : "" : "",
        },
      });
    }
  };

  fetchUserRoleData = () => {
    let url = new URL(document.location);
    this.props.fetchUserRoleData((res) => {
      this.setState(
        {
          role: res.role === "Coach" ? true : false,
        },
        () => {
          this.getEvents();
        }
      );
    });
  };

  getEvents = () => {
    this.props.fetchProfileEventData(this.state.eventReq, (res) => {
      this.setState(
        {
          events: res.result.Player_data,
          sendReq: { ...this.state.sendReq, season: moment().year() },
          backupReq: { ...this.state.backupReq, season: moment().year() }
        },
        () => {
          this.setFilter(this.updateRequest(this.state.sendReq));
        }
      );
    });
  };

  setFilter = (sendReq) => {
    const data = {
      tab: sendReq.tab,
      type: sendReq.type,
      pitcher_throws: sendReq.pitcher_throws,
      batter_stands: sendReq.batter_stands,
      pitch_type: sendReq.pitch_type,
      play_result: sendReq.play_result,
      zone: sendReq.zone,
      event_type: sendReq.event_type,
      date_from: sendReq.date_from,
      date_to: sendReq.date_to,
      season: Number(sendReq.season) || "",
      contour_graph: sendReq.contour_graph,
      count: sendReq.count,
      home_away: sendReq.home_away,
      fall_spring: sendReq.fall_spring,
      velocity_from: (sendReq.velocity_from === 0 && sendReq.velocity_to === 100) ? "" : sendReq.velocity_from,
      velocity_to: (sendReq.velocity_from === 0 && sendReq.velocity_to === 100) ? "" : sendReq.velocity_to,
      exit_velocity_from: (sendReq.exit_velocity_from === 0 && sendReq.exit_velocity_to === 120) ? "" : sendReq.exit_velocity_from,
      exit_velocity_to: (sendReq.exit_velocity_from === 0 && sendReq.exit_velocity_to === 120) ? "" : sendReq.exit_velocity_to,
      spin_rate_from: (sendReq.spin_rate_from === 0 && sendReq.spin_rate_to === 3500) ? "" : sendReq.spin_rate_from,
      spin_rate_to: (sendReq.spin_rate_from === 0 && sendReq.spin_rate_to === 3500) ? "" : sendReq.spin_rate_to,
      launch_angle_from: (sendReq.launch_angle_from === -90 && sendReq.launch_angle_to === 90) ? "" : sendReq.launch_angle_from,
      launch_angle_to: (sendReq.launch_angle_from === -90 && sendReq.launch_angle_to === 90) ? "" : sendReq.launch_angle_to,
      efficiency_from: (sendReq.efficiency_from === 0 && sendReq.efficiency_to === 100) ? "" : sendReq.efficiency_from,
      efficiency_to: (sendReq.efficiency_from === 0 && sendReq.efficiency_to === 100) ? "" : sendReq.efficiency_to,
      vertical_break_from: (sendReq.vertical_break_from === -90 && sendReq.vertical_break_to === 0) ? "" : sendReq.vertical_break_from,
      vertical_break_to: (sendReq.vertical_break_from === -90 && sendReq.vertical_break_to === 0) ? "" : sendReq.vertical_break_to,
      induced_vertical_break_from: (sendReq.induced_vertical_break_from === -30 && sendReq.induced_vertical_break_to === 30) ? "" : sendReq.induced_vertical_break_from,
      induced_vertical_break_to: (sendReq.induced_vertical_break_from === -30 && sendReq.induced_vertical_break_to === 30) ? "" : sendReq.induced_vertical_break_to,
      horizontal_break_from: (sendReq.horizontal_break_from === -30 && sendReq.horizontal_break_to === 30) ? "" : sendReq.horizontal_break_from,
      horizontal_break_to: (sendReq.horizontal_break_from === -30 && sendReq.horizontal_break_to === 30) ? "" : sendReq.horizontal_break_to,
      vertical_approach_angle_from: (sendReq.vertical_approach_angle_from === -10 && sendReq.vertical_approach_angle_to === 0) ? "" : sendReq.vertical_approach_angle_from,
      vertical_approach_angle_to: (sendReq.vertical_approach_angle_from === -10 && sendReq.vertical_approach_angle_to === 0) ? "" : sendReq.vertical_approach_angle_to,
      horz_approach_angle_from: (sendReq.horz_approach_angle_from === -10 && sendReq.horz_approach_angle_to === 10) ? "" : sendReq.horz_approach_angle_from,
      horz_approach_angle_to: (sendReq.horz_approach_angle_from === -10 && sendReq.horz_approach_angle_to === 10) ? "" : sendReq.horz_approach_angle_to,
      release_height_from: (sendReq.release_height_from === 0 && sendReq.release_height_to === 10) ? "" : sendReq.release_height_from,
      release_height_to: (sendReq.release_height_from === 0 && sendReq.release_height_to === 10) ? "" : sendReq.release_height_to,
      extension_from: (sendReq.extension_from === 0 && sendReq.extension_to === 10) ? "" : sendReq.extension_from,
      extension_to: (sendReq.extension_from === 0 && sendReq.extension_to === 10) ? "" : sendReq.extension_to,
      heat_map_0: sendReq.heat_map_0 ? sendReq.heat_map_0 : localStorage.getItem("activeType") === "Pitching" ? "" : "Avg EV",
      heat_map_1: sendReq.heat_map_1 ? sendReq.heat_map_1 : localStorage.getItem("activeType") === "Pitching" ? "" : "Avg EV",
      heat_map_2: sendReq.heat_map_2 ? sendReq.heat_map_2 : localStorage.getItem("activeType") === "Pitching" ? "" : "Hard Hit",
      heat_map_3: sendReq.heat_map_3 ? sendReq.heat_map_3 : localStorage.getItem("activeType") === "Pitching" ? "" : "Damage",
      histrogram_field: sendReq.histrogram_field ? sendReq.histrogram_field : "exit_velocity",
      histrogram_bucket: sendReq.histrogram_bucket ? Number(sendReq.histrogram_bucket) : 5,
      scatter: sendReq.scatter,
      pitchers: sendReq.pitchers,
      inning: sendReq.inning,
      catcher: sendReq.catcher
    }

    this.props.fetchFilterData(data, this.state.uid, (res) => {
      if (res.data.flag === true) {
        this.setState(
          (prevState) => ({
            ...prevState,
            filterRes: res.data.result && res.data.result,
            oldData:  this.state.isFilter ? !this.state.oldData ? res.data.result : this.state.oldData : !this.state.oldData ? res.data.result : this.state.parentOldData,
            parentOldData: !this.state.parentOldData ?  res.data.result : this.state.parentOldData,
            sendReq: {
              ...prevState.sendReq,
              season: !this.state.isSeasonFilter ? moment().year() : sendReq.season,
              velocity_from: sendReq.velocity_from || (sendReq.velocity_from === 0 && sendReq.velocity_to === 0) ? sendReq.velocity_from : 0,
              velocity_to: sendReq.velocity_to || (sendReq.velocity_from === 0 && sendReq.velocity_to === 0) ? sendReq.velocity_to : 100,
              exit_velocity_from: sendReq.exit_velocity_from || (sendReq.exit_velocity_from === 0 && sendReq.exit_velocity_to === 0) ? sendReq.exit_velocity_from : 0,
              exit_velocity_to: sendReq.exit_velocity_to || (sendReq.exit_velocity_from === 0 && sendReq.exit_velocity_to === 0) ? sendReq.exit_velocity_to : 120,
              spin_rate_from: sendReq.spin_rate_from || (sendReq.spin_rate_from === 0 && sendReq.spin_rate_to === 0) ? sendReq.spin_rate_from : 0,
              spin_rate_to: sendReq.spin_rate_to || (sendReq.spin_rate_from === 0 && sendReq.spin_rate_to === 0) ? sendReq.spin_rate_to : 3500,
              launch_angle_from: sendReq.launch_angle_from || (sendReq.launch_angle_from === 0 && sendReq.launch_angle_to === 0) ? sendReq.launch_angle_from : -90,
              launch_angle_to: sendReq.launch_angle_to || (sendReq.launch_angle_from === 0 && sendReq.launch_angle_to === 0) ? sendReq.launch_angle_to : 90,
              efficiency_from: sendReq.efficiency_from || (sendReq.efficiency_from === 0 && sendReq.efficiency_to === 0) ? sendReq.efficiency_from : 0,
              efficiency_to: sendReq.efficiency_to || (sendReq.efficiency_from === 0 && sendReq.efficiency_to === 0) ? sendReq.efficiency_to : 100,
              vertical_break_from: sendReq.vertical_break_from || (sendReq.vertical_break_from === 0 && sendReq.vertical_break_to === 0) ? sendReq.vertical_break_from : -90,
              vertical_break_to: sendReq.vertical_break_to || (sendReq.vertical_break_from === 0 && sendReq.vertical_break_to === 0) ? sendReq.vertical_break_to : 0,
              induced_vertical_break_from: sendReq.induced_vertical_break_from || (sendReq.induced_vertical_break_from === 0 && sendReq.induced_vertical_break_to === 0) ? sendReq.induced_vertical_break_from : -30,
              induced_vertical_break_to: sendReq.induced_vertical_break_to || (sendReq.induced_vertical_break_from === 0 && sendReq.induced_vertical_break_to === 0) ? sendReq.induced_vertical_break_to : 30,
              horizontal_break_from: sendReq.horizontal_break_from || (sendReq.horizontal_break_from === 0 && sendReq.horizontal_break_to === 0) ? sendReq.horizontal_break_from : -30,
              horizontal_break_to: sendReq.horizontal_break_to || (sendReq.horizontal_break_from === 0 && sendReq.horizontal_break_to === 0) ? sendReq.horizontal_break_to : 30,
              vertical_approach_angle_from: sendReq.vertical_approach_angle_from || (sendReq.vertical_approach_angle_from === 0 && sendReq.vertical_approach_angle_to === 0) ? sendReq.vertical_approach_angle_from : -10,
              vertical_approach_angle_to: sendReq.vertical_approach_angle_to || (sendReq.vertical_approach_angle_from === 0 && sendReq.vertical_approach_angle_to === 0) ? sendReq.vertical_approach_angle_to : 0,
              horz_approach_angle_from: sendReq.horz_approach_angle_from || (sendReq.horz_approach_angle_from === 0 && sendReq.horz_approach_angle_to === 0) ? sendReq.horz_approach_angle_from : -10,
              horz_approach_angle_to: sendReq.horz_approach_angle_to || (sendReq.horz_approach_angle_from === 0 && sendReq.horz_approach_angle_to === 0) ? sendReq.horz_approach_angle_to : 10,
              release_height_from: sendReq.release_height_from || (sendReq.release_height_from === 0 && sendReq.release_height_to === 0) ? sendReq.release_height_from : 0,
              release_height_to: sendReq.release_height_to || (sendReq.release_height_from === 0 && sendReq.release_height_to === 0) ? sendReq.release_height_to : 10,
              extension_from: sendReq.extension_from || (sendReq.extension_from === 0 && sendReq.extension_to === 0) ? sendReq.extension_from : 0,
              extension_to: sendReq.extension_to || (sendReq.extension_from === 0 && sendReq.extension_to === 0) ? sendReq.extension_to : 10,
              fall_spring: !this.state.isFallSpringFilter
                ? sendReq.tab === "swing_data" ? "" : res.data.result.fall_spring
                : sendReq.fall_spring,
            },
            backupReq: {
              ...prevState.backupReq,
              season: !this.state.isSeasonFilter ? moment().year() : sendReq.season,
              velocity_from: this.state.backupReq.velocity_from ? this.state.backupReq.velocity_from : 0,
              velocity_to: this.state.backupReq.velocity_to ? this.state.backupReq.velocity_to : 100,
              exit_velocity_from: this.state.backupReq.exit_velocity_from ? this.state.backupReq.exit_velocity_from : 0,
              exit_velocity_to: this.state.backupReq.exit_velocity_to ? this.state.backupReq.exit_velocity_to : 120,
              spin_rate_from: this.state.backupReq.spin_rate_from ? this.state.backupReq.spin_rate_from : 0,
              spin_rate_to: this.state.backupReq.spin_rate_to ? this.state.backupReq.spin_rate_to : 3500,
              launch_angle_from: this.state.backupReq.launch_angle_from ? this.state.backupReq.launch_angle_from : -90,
              launch_angle_to: this.state.backupReq.launch_angle_to ? this.state.backupReq.launch_angle_to : 90,
              efficiency_from: this.state.backupReq.efficiency_from ? this.state.backupReq.efficiency_from : 0,
              efficiency_to: this.state.backupReq.efficiency_to ? this.state.backupReq.efficiency_to : 100,
              vertical_break_from: this.state.backupReq.vertical_break_from ? this.state.backupReq.vertical_break_from : -90,
              vertical_break_to: this.state.backupReq.vertical_break_to ? this.state.backupReq.vertical_break_to : 0,
              induced_vertical_break_from: this.state.backupReq.induced_vertical_break_from ? this.state.backupReq.induced_vertical_break_from : -30,
              induced_vertical_break_to: this.state.backupReq.induced_vertical_break_to ? this.state.backupReq.induced_vertical_break_to : 30,
              horizontal_break_from: this.state.backupReq.horizontal_break_from ? this.state.backupReq.horizontal_break_from : -30,
              horizontal_break_to: this.state.backupReq.horizontal_break_to ? this.state.backupReq.horizontal_break_to : 30,
              vertical_approach_angle_from: this.state.backupReq.vertical_approach_angle_from ? this.state.backupReq.vertical_approach_angle_from : -10,
              vertical_approach_angle_to: this.state.backupReq.vertical_approach_angle_to ? this.state.backupReq.vertical_approach_angle_to : 0,
              horz_approach_angle_from: this.state.backupReq.horz_approach_angle_from ? this.state.backupReq.horz_approach_angle_from : -10,
              horz_approach_angle_to: this.state.backupReq.horz_approach_angle_to ? this.state.backupReq.horz_approach_angle_to : 10,
              release_height_from: this.state.backupReq.release_height_from ? this.state.backupReq.release_height_from : 0,
              release_height_to: this.state.backupReq.release_height_to ? this.state.backupReq.release_height_to : 10,
              extension_from: this.state.backupReq.extension_from ? this.state.backupReq.extension_from : 0,
              extension_to: this.state.backupReq.extension_to ? this.state.backupReq.extension_to : 10,
              fall_spring: !this.state.isFallSpringFilter
                ? sendReq.tab === "swing_data" ? "" : res.data.result.fall_spring
                : sendReq.fall_spring,
            },
            startYear: res.data.result.start_year ? res.data.result.start_year : moment().year(),
            lastSeason: res.data.result.last_season,
          }),
          () => {
            this.setState((prevState)=>({
              ...prevState,
              seasonList: getSeasonFilterDefaultValues(
                this.state.startYear,
                this.state.lastSeason
                ),
              }));
            this.props.loader(false);
          }
        );
      }
    });
  };

  handlePagination = (type) => {
    // for pitchriframe load flag to set false
    Object.keys(this.props.appState).filter(key => key.includes("iframe_")).map(key => {
      this.props.appState[key] = false;
    });

    this.setState(
      {
        filterIndex:
          type === "next"
            ? this.state.filterIndex + 1
            : this.state.filterIndex - 1,
        pitchrIframeFlag: "yes"
      },
      () => {
        this.props.history.push(
          "/profiledashboard/" +
          this.state.profileList[this.state.filterIndex]._id
        );
        this.clearState();
        this.renderPageSummary();
      }
    );
  };

  calculate_age = (dob1) => {
    var ageDifMs = Date.now() - new Date(dob1).getTime();
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  handleCallback = (index) => {
    this.setState({
      tabs: index,
    });
  };

  handleTabs = (e) => {
    // for pitchriframe load flag to set false
    Object.keys(this.props.appState).filter(key => key.includes("iframe_")).map(key => {
      this.props.appState[key] = false;
    });

    this.setState(
      (prevState) => ({
        ...prevState,
        tabslist: e,
        oldData: null,
        parentOldData: null,
        isFilter: false,
        sendReq: {
          ...prevState.sendReq,
          tab:
            e === 0
              ? "pitch_outcomes"
              : e === 1
                ? "batted_ball"
                : e === 2
                  ? "plate_discipline"
                  : e === 3
                    ? "break_movement"
                    : e === 6
                      ? "swing_data"
                      : e === 7
                        ? "point_of_contact"
                        : e === 8
                          ? "heat_map"
                          : e === 9
                            ? "catching"
                            : "release_point",
          type: this.state.backupReq.type ? this.state.backupReq.type : localStorage.getItem("activeType"),
          pitcher_throws: e === 6 ? "" : this.state.backupReq.pitcher_throws,
          batter_stands: e === 6 ? "" : this.state.backupReq.batter_stands,
          pitch_type: e === 6 ? "" : this.state.backupReq.pitch_type,
          zone: e === 6 ? "" : this.state.backupReq.zone,
          event_type: e === 6 ? "" : this.state.backupReq.event_type ? this.state.backupReq.event_type : "",
          // date_from: this.state.backupReq.date_from,
          // date_to: this.state.backupReq.date_to,
          date_from: this.state.backupReq.date_from || "",
          date_to: this.state.backupReq.date_to || "",
          // season: !this.state.isSeasonFilter ? "" : this.state.backupReq.season,
          season: this.state.backupReq.season ? this.state.backupReq.season : "",
          play_result: e === 6 ? "" : this.state.backupReq.play_result ? this.state.backupReq.play_result : "",
          contour_graph: e === 2 ? this.state.backupReq.contour_graph ? this.state.backupReq.contour_graph : "Swing and Miss" : "",
          count: e === 6 ? "" : this.state.backupReq.count,
          home_away: e === 6 ? "" : this.state.backupReq.home_away,
          fall_spring: e === 6 ? "" : this.state.backupReq.fall_spring,
          velocity_to: e === 6 ? "" : this.state.backupReq.velocity_to ? this.state.backupReq.velocity_to : 100,
          velocity_from: e === 6 ? "" :  this.state.backupReq.velocity_from ? this.state.backupReq.velocity_from : 0 ,
          exit_velocity_to: e === 6 ? "" : this.state.backupReq.exit_velocity_to ? this.state.backupReq.exit_velocity_to : 120,
          exit_velocity_from: e === 6 ? "" : this.state.backupReq.exit_velocity_from ? this.state.backupReq.exit_velocity_from : 0,
          spin_rate_to: e === 6 ? "" : this.state.backupReq.spin_rate_to ? this.state.backupReq.spin_rate_to : 3500,
          spin_rate_from: e === 6 ? "" : this.state.backupReq.spin_rate_from ? this.state.backupReq.spin_rate_from : 0,
          launch_angle_to: e === 6 ? "" : this.state.backupReq.launch_angle_to ? this.state.backupReq.launch_angle_to : 90,
          launch_angle_from: e === 6 ? "" : this.state.backupReq.launch_angle_from ? this.state.backupReq.launch_angle_from : -90,
          efficiency_to: e === 6 ? "" : this.state.backupReq.efficiency_to ? this.state.backupReq.efficiency_to : 100,
          efficiency_from: e === 6 ? "" : this.state.backupReq.efficiency_from ? this.state.backupReq.efficiency_from : 0,
          vertical_break_to: e === 6 ? "" : this.state.backupReq.vertical_break_to ? this.state.backupReq.vertical_break_to : 0,
          vertical_break_from: e === 6 ? "" : this.state.backupReq.vertical_break_from ? this.state.backupReq.vertical_break_from : -90,
          induced_vertical_break_to: e === 6 ? "" : this.state.backupReq.induced_vertical_break_to ? this.state.backupReq.induced_vertical_break_to : 30,
          induced_vertical_break_from: e === 6 ? "" : this.state.backupReq.induced_vertical_break_from ? this.state.backupReq.induced_vertical_break_from : -30,
          horizontal_break_to: e === 6 ? "" : this.state.backupReq.horizontal_break_to ? this.state.backupReq.horizontal_break_to : 30,
          horizontal_break_from: e === 6 ? "" : this.state.backupReq.horizontal_break_from ? this.state.backupReq.horizontal_break_from : -30,
          vertical_approach_angle_to: e === 6 ? "" : this.state.backupReq.vertical_approach_angle_to ? this.state.backupReq.vertical_approach_angle_to : 0,
          vertical_approach_angle_from: e === 6 ? "" : this.state.backupReq.vertical_approach_angle_from ? this.state.backupReq.vertical_approach_angle_from : -10,
          horz_approach_angle_to: e === 6 ? "" : this.state.backupReq.horz_approach_angle_to ? this.state.backupReq.horz_approach_angle_to : 10,
          horz_approach_angle_from: e === 6 ? "" : this.state.backupReq.horz_approach_angle_from ? this.state.backupReq.horz_approach_angle_from : -10,
          release_height_to: e === 6 ? "" : this.state.backupReq.release_height_to ? this.state.backupReq.release_height_to : 10,
          release_height_from: e === 6 ? "" : this.state.backupReq.release_height_from ? this.state.backupReq.release_height_from : 0,
          extension_to: e === 6 ? "" : this.state.backupReq.extension_to ? this.state.backupReq.extension_to : 10,
          extension_from: e === 6 ? "" : this.state.backupReq.extension_from ? this.state.backupReq.extension_from : 0,
          heat_map_0: e === 1 ? this.state.backupReq.heat_map_0 ? this.state.backupReq.heat_map_0 : "Avg EV" : "",
          heat_map_1: e === 1 ? this.state.backupReq.heat_map_1 ? this.state.backupReq.heat_map_1 : "Avg EV" : "",
          heat_map_2: e === 1 ? this.state.backupReq.heat_map_2 ? this.state.backupReq.heat_map_2 : "Hard Hit" : "",
          heat_map_3: e === 1 ? this.state.backupReq.heat_map_3 ? this.state.backupReq.heat_map_3 : "Damage" : "",
          histrogram_field: e === 1 ? this.state.backupReq.histrogram_field ? this.state.backupReq.histrogram_field : "exit_velocity" : "",
          histrogram_bucket: e === 1 ? this.state.backupReq.histrogram_bucket ? this.state.backupReq.histrogram_bucket : 5 : "",
          scatter:undefined
        },
      }),
      () => {
        // if (e === 3) {
        //   arr = [];
        //   arr.push("season", "fall_spring");
        //   if (this.state.backupReq.date_from && this.state.backupReq.date_to) {
        //     arr.push("date_from");
        //   }
        // } else 
        if (e === 6) {
          arr = [];
          arr.push("season");
          if (this.state.backupReq.date_from && this.state.backupReq.date_to) {
            arr.push("date_from");
          }
        } 
        // else if (e === 0 || e === 4) {
        //   arr = [];
        //   let backup = this.state.backupReq;
        //   Object.keys(backup).map(function (key, index) {
        //     if (backup[key]) {
        //       arr.push(key);
        //     }
        //   })
        //   arr.indexOf("velocity_to") > -1 && arr.splice(arr.indexOf("velocity_to"), 1);
        //   arr.indexOf("velocity_from") > -1 && arr.splice(arr.indexOf("velocity_from"), 1);
        //   arr.indexOf("exit_velocity_to") > -1 && arr.splice(arr.indexOf("exit_velocity_to"), 1);
        //   arr.indexOf("exit_velocity_from") > -1 && arr.splice(arr.indexOf("exit_velocity_from"), 1);
        //   arr.indexOf("tab") > -1 && arr.splice(arr.indexOf("tab"), 1);
        //   arr.indexOf("heat_map") > -1 && arr.splice(arr.indexOf("heat_map"), 1);
        //   arr.indexOf("contour_graph") > -1 && arr.splice(arr.indexOf("contour_graph"), 1);
        //   arr.indexOf("scatter") > -1 && arr.splice(arr.indexOf("scatter"), 1);

        // }
        else {
          arr = [];
          let backup = this.state.backupReq;
          Object.keys(backup).map(function (key, index) {
            if (backup[key]) {
              arr.push(key);
            }
          });
          (backup.velocity_from === 0 && backup.velocity_to === 100 ) && arr.indexOf("velocity_to") > -1 && arr.splice(arr.indexOf("velocity_to"), 1);
          arr.indexOf("velocity_from") > -1 && arr.splice(arr.indexOf("velocity_from"), 1);
          (backup.exit_velocity_from === 0 && backup.exit_velocity_to === 120) && arr.indexOf("exit_velocity_to") > -1 && arr.splice(arr.indexOf("exit_velocity_to"), 1);
          arr.indexOf("exit_velocity_from") > -1 && arr.splice(arr.indexOf("exit_velocity_from"), 1);
          (backup.spin_rate_from === 0 && backup.spin_rate_to === 3500) && arr.indexOf("spin_rate_to") > -1 && arr.splice(arr.indexOf("spin_rate_to"), 1);
          arr.indexOf("spin_rate_from") > -1 && arr.splice(arr.indexOf("spin_rate_from"), 1);
          (backup.launch_angle_from === -90 && backup.launch_angle_to === 90) && arr.indexOf("launch_angle_to") > -1 && arr.splice(arr.indexOf("launch_angle_to"), 1);
          arr.indexOf("launch_angle_from") > -1 && arr.splice(arr.indexOf("launch_angle_from"), 1);
          (backup.efficiency_from === 0 && backup.efficiency_to === 100) && arr.indexOf("efficiency_to") > -1 && arr.splice(arr.indexOf("efficiency_to"), 1);
          arr.indexOf("efficiency_from") > -1 && arr.splice(arr.indexOf("efficiency_from"), 1);
          (backup.vertical_break_from === -90 && backup.vertical_break_to === 0) && arr.indexOf("vertical_break_to") > -1 && arr.splice(arr.indexOf("vertical_break_to"), 1);
          arr.indexOf("vertical_break_from") > -1 && arr.splice(arr.indexOf("vertical_break_from"), 1);
          (backup.induced_vertical_break_from === -30 && backup.induced_vertical_break_to === 30) && arr.indexOf("induced_vertical_break_to") > -1 && arr.splice(arr.indexOf("induced_vertical_break_to"), 1);
          arr.indexOf("induced_vertical_break_from") > -1 && arr.splice(arr.indexOf("induced_vertical_break_from"), 1);
          (backup.horizontal_break_from === -30 && backup.horizontal_break_to === 30) && arr.indexOf("horizontal_break_to") > -1 && arr.splice(arr.indexOf("horizontal_break_to"), 1);
          arr.indexOf("horizontal_break_from") > -1 && arr.splice(arr.indexOf("horizontal_break_from"), 1);
          (backup.vertical_approach_angle_from === -10 && backup.vertical_approach_angle_to === 0) && arr.indexOf("vertical_approach_angle_to") > -1 && arr.splice(arr.indexOf("vertical_approach_angle_to"), 1);
          arr.indexOf("vertical_approach_angle_from") > -1 && arr.splice(arr.indexOf("vertical_approach_angle_from"), 1);
          (backup.horz_approach_angle_from === -10 && backup.horz_approach_angle_to === 10) && arr.indexOf("horz_approach_angle_to") > -1 && arr.splice(arr.indexOf("horz_approach_angle_to"), 1);
          arr.indexOf("horz_approach_angle_from") > -1 && arr.splice(arr.indexOf("horz_approach_angle_from"), 1);
          (backup.release_height_from === 0 && backup.release_height_to === 10) && arr.indexOf("release_height_to") > -1 && arr.splice(arr.indexOf("release_height_to"), 1);
          arr.indexOf("release_height_from") > -1 && arr.splice(arr.indexOf("release_height_from"), 1);
          (backup.extension_from === 0 && backup.extension_to === 10) && arr.indexOf("extension_to") > -1 && arr.splice(arr.indexOf("extension_to"), 1);
          arr.indexOf("extension_from") > -1 && arr.splice(arr.indexOf("extension_from"), 1);
          arr.indexOf("heat_map_0") > -1 && arr.splice(arr.indexOf("heat_map_0"), 1);
          arr.indexOf("heat_map_1") > -1 && arr.splice(arr.indexOf("heat_map_1"), 1);
          arr.indexOf("heat_map_2") > -1 && arr.splice(arr.indexOf("heat_map_2"), 1);
          arr.indexOf("heat_map_3") > -1 && arr.splice(arr.indexOf("heat_map_3"), 1);
          arr.indexOf("histrogram_field") > -1 && arr.splice(arr.indexOf("histrogram_field"), 1);
          arr.indexOf("histrogram_bucket") > -1 && arr.splice(arr.indexOf("histrogram_bucket"), 1);
          arr.indexOf("contour_graph") > -1 && arr.splice(arr.indexOf("contour_graph"), 1);
          arr.indexOf("scatter") > -1 && arr.splice(arr.indexOf("scatter"), 1);
          if (e === 1) {
            arr.indexOf("heat_map_0") === -1 && arr.push("heat_map_0");
            // arr.indexOf("heat_map_1") === -1 && arr.push("heat_map_1");
            // arr.indexOf("heat_map_2") === -1 && arr.push("heat_map_2");
            // arr.indexOf("heat_map_3") === -1 && arr.push("heat_map_3");
          }
          if (e === 2) {
            arr.indexOf("contour_graph") === -1 && arr.push("contour_graph");
          }
          arr.indexOf("tab") > -1 && arr.splice(arr.indexOf("tab"), 1);
        }
        this.setFilter(this.updateRequest(this.state.sendReq));
      }
    );
  };

  handleToggle() {
    this.setState({
      isshow: !this.state.isshow,
    });
  }

  handleCancelToggle = () => {
    arr = [];
    this.setState(
      {
        isshow: !this.state.isshow,
        isFilter: false,
        parentOldData: null,
        oldData:null,
        isSeasonFilter: false,
        isFallSpringFilter: false,
        sendReq: {
          tab: this.state.sendReq.tab,
          type: this.state.sendReq.tab === "swing_data" ? "" : localStorage.getItem("activeType"),
          pitcher_throws: "",
          batter_stands: "",
          pitch_type: "",
          scatter:undefined,
          play_result: "",
          zone: "",
          event_type: "",
          date_from: "",
          date_to: "",
          season: "",
          contour_graph: this.state.tabslist === 2 ? "Swing and Miss"  : "",
          count: "",
          home_away: "",
          fall_spring: "" ,
          velocity_to: this.state.tabslist === 1 || this.state.tabslist === 2 || this.state.tabslist === 0 || this.state.tabslist === 3  || this.state.tabslist === 4 || this.state.tabslist === 7 || this.state.tabslist === 8 ? 100 : "",
          velocity_from: this.state.tabslist === 1 || this.state.tabslist === 2 || this.state.tabslist === 0 || this.state.tabslist === 3  || this.state.tabslist === 4 || this.state.tabslist === 7 || this.state.tabslist === 8 ? 0 : "",
          exit_velocity_to: this.state.tabslist === 1 || this.state.tabslist === 2  || this.state.tabslist === 0 || this.state.tabslist === 3  || this.state.tabslist === 4 || this.state.tabslist === 7 || this.state.tabslist === 8 ? 120 : "",
          exit_velocity_from: this.state.tabslist === 1 || this.state.tabslist === 2  || this.state.tabslist === 0 || this.state.tabslist === 3  || this.state.tabslist === 4 || this.state.tabslist === 7 || this.state.tabslist === 8 ? 0 : "",
          spin_rate_to: this.state.tabslist === 1 || this.state.tabslist === 2 || this.state.tabslist === 0 || this.state.tabslist === 3  || this.state.tabslist === 4 || this.state.tabslist === 7 || this.state.tabslist === 8 ? 3500 : "",
          spin_rate_from: this.state.tabslist === 1 || this.state.tabslist === 2 || this.state.tabslist === 0 || this.state.tabslist === 3  || this.state.tabslist === 4 || this.state.tabslist === 7 || this.state.tabslist === 8 ? 0 : "",
          launch_angle_to: this.state.tabslist === 1 || this.state.tabslist === 2 || this.state.tabslist === 0 || this.state.tabslist === 3  || this.state.tabslist === 4 || this.state.tabslist === 7 || this.state.tabslist === 8 ? 90 : "",
          launch_angle_from: this.state.tabslist === 1 || this.state.tabslist === 2 || this.state.tabslist === 0 || this.state.tabslist === 3  || this.state.tabslist === 4 || this.state.tabslist === 7 || this.state.tabslist === 8 ? -90 : "",
          efficiency_to: this.state.tabslist === 1 || this.state.tabslist === 2 || this.state.tabslist === 0 || this.state.tabslist === 3  || this.state.tabslist === 4 || this.state.tabslist === 7 || this.state.tabslist === 8 ? 100 : "",
          efficiency_from: this.state.tabslist === 1 || this.state.tabslist === 2 || this.state.tabslist === 0 || this.state.tabslist === 3  || this.state.tabslist === 4 || this.state.tabslist === 7 || this.state.tabslist === 8 ? 0 : "",
          vertical_break_to: this.state.tabslist === 1 || this.state.tabslist === 2 || this.state.tabslist === 0 || this.state.tabslist === 3  || this.state.tabslist === 4 || this.state.tabslist === 7 || this.state.tabslist === 8 ? 0 : "",
          vertical_break_from: this.state.tabslist === 1 || this.state.tabslist === 2 || this.state.tabslist === 0 || this.state.tabslist === 3  || this.state.tabslist === 4 || this.state.tabslist === 7 || this.state.tabslist === 8 ? -90 : "",
          induced_vertical_break_to: this.state.tabslist === 1 || this.state.tabslist === 2 || this.state.tabslist === 0 || this.state.tabslist === 3  || this.state.tabslist === 4 || this.state.tabslist === 7 || this.state.tabslist === 8 ? 30 : "",
          induced_vertical_break_from: this.state.tabslist === 1 || this.state.tabslist === 2 || this.state.tabslist === 0 || this.state.tabslist === 3  || this.state.tabslist === 4 || this.state.tabslist === 7 || this.state.tabslist === 8 ? -30 : "",
          horizontal_break_to: this.state.tabslist === 1 || this.state.tabslist === 2 || this.state.tabslist === 0 || this.state.tabslist === 3  || this.state.tabslist === 4 || this.state.tabslist === 7 || this.state.tabslist === 8 ? 30 : "",
          horizontal_break_from: this.state.tabslist === 1 || this.state.tabslist === 2 || this.state.tabslist === 0 || this.state.tabslist === 3  || this.state.tabslist === 4 || this.state.tabslist === 7 || this.state.tabslist === 8 ? -30 : "",
          vertical_approach_angle_to: this.state.tabslist === 1 || this.state.tabslist === 2 || this.state.tabslist === 0 || this.state.tabslist === 3  || this.state.tabslist === 4 || this.state.tabslist === 7 || this.state.tabslist === 8 ? 0 : "",
          vertical_approach_angle_from: this.state.tabslist === 1 || this.state.tabslist === 2 || this.state.tabslist === 0 || this.state.tabslist === 3  || this.state.tabslist === 4 || this.state.tabslist === 7 || this.state.tabslist === 8 ? -10 : "",
          horz_approach_angle_to: this.state.tabslist === 1 || this.state.tabslist === 2 || this.state.tabslist === 0 || this.state.tabslist === 3  || this.state.tabslist === 4 || this.state.tabslist === 7 || this.state.tabslist === 8 ? 10 : "",
          horz_approach_angle_from: this.state.tabslist === 1 || this.state.tabslist === 2 || this.state.tabslist === 0 || this.state.tabslist === 3  || this.state.tabslist === 4 || this.state.tabslist === 7 || this.state.tabslist === 8 ? -10 : "",
          release_height_to: this.state.tabslist === 1 || this.state.tabslist === 2 || this.state.tabslist === 0 || this.state.tabslist === 3  || this.state.tabslist === 4 || this.state.tabslist === 7 || this.state.tabslist === 8 ? 10 : "",
          release_height_from: this.state.tabslist === 1 || this.state.tabslist === 2 || this.state.tabslist === 0 || this.state.tabslist === 3  || this.state.tabslist === 4 || this.state.tabslist === 7 || this.state.tabslist === 8 ? 0 : "",
          extension_to: this.state.tabslist === 1 || this.state.tabslist === 2 || this.state.tabslist === 0 || this.state.tabslist === 3  || this.state.tabslist === 4 || this.state.tabslist === 7 || this.state.tabslist === 8 ? 10 : "",
          extension_from: this.state.tabslist === 1 || this.state.tabslist === 2 || this.state.tabslist === 0 || this.state.tabslist === 3  || this.state.tabslist === 4 || this.state.tabslist === 7 || this.state.tabslist === 8 ? 0 : "",
          heat_map_0: localStorage.getItem("activeType") === "Pitching" ?  this.state.tabslist === 1 ? "Avg EV" : "" : this.state.sendReq.tab === "swing_data" ? "" : this.state.tabslist === 7 || this.state.tabslist === 2 || this.state.tabslist === 9 || this.state.tabslist === 8 ? "" : "Avg EV",
          heat_map_1: localStorage.getItem("activeType") === "Pitching" ?  this.state.tabslist === 1 ? "Avg EV" : "" : this.state.sendReq.tab === "swing_data" ? "" : this.state.tabslist === 7 || this.state.tabslist === 2 || this.state.tabslist === 9 || this.state.tabslist === 8 ? "" : "Avg EV",
          heat_map_2: localStorage.getItem("activeType") === "Pitching" ?  this.state.tabslist === 1 ? "Hard Hit" : "" : this.state.sendReq.tab === "swing_data" ? "" : this.state.tabslist === 7 || this.state.tabslist === 2 || this.state.tabslist === 9 || this.state.tabslist === 8 ? "" : "Hard Hit",
          heat_map_3: localStorage.getItem("activeType") === "Pitching" ?  this.state.tabslist === 1 ? "Damage" : "" : this.state.sendReq.tab === "swing_data" ? "" : this.state.tabslist === 7 || this.state.tabslist === 2 || this.state.tabslist === 9 || this.state.tabslist === 8 ? "" : "Damage",
          histrogram_field:"exit_velocity",
          histrogram_bucket: 5,
          pitchers: "",
          inning: "",
          catcher: "",
        },
        backupReq: {
          tab: this.state.sendReq.tab,
          type: this.state.sendReq.tab === "swing_data" ? "" : localStorage.getItem("activeType"),
          pitcher_throws: "",
          batter_stands: "",
          pitch_type: "",
          scatter:undefined,
          play_result: "",
          zone: "",
          event_type: "",
          date_from: "",
          date_to: "",
          season: "",
          contour_graph: this.state.tabslist === 2 ? "Swing and Miss" : "",
          count: "",
          home_away: "",
          fall_spring: this.state.backupReq.fall_spring ?  this.state.backupReq.fall_spring : "" ,
          velocity_to: this.state.tabslist === 1 || this.state.tabslist === 2 || this.state.tabslist === 0 || this.state.tabslist === 3  || this.state.tabslist === 4 || this.state.tabslist === 7 || this.state.tabslist === 8 ? 100 : "",
          velocity_from:this.state.tabslist === 1 || this.state.tabslist === 2 || this.state.tabslist === 0 || this.state.tabslist === 3  || this.state.tabslist === 4 || this.state.tabslist === 7 || this.state.tabslist === 8 ? 0 : "",
          exit_velocity_to: this.state.tabslist === 1 || this.state.tabslist === 2 || this.state.tabslist === 0 || this.state.tabslist === 3  || this.state.tabslist === 4 || this.state.tabslist === 7 || this.state.tabslist === 8 ? 120 : "",
          exit_velocity_from: this.state.tabslist === 1 || this.state.tabslist === 2 || this.state.tabslist === 0 || this.state.tabslist === 3  || this.state.tabslist === 4 || this.state.tabslist === 7 || this.state.tabslist === 8 ? 0 : "",
          spin_rate_to: this.state.tabslist === 1 || this.state.tabslist === 2 || this.state.tabslist === 0 || this.state.tabslist === 3  || this.state.tabslist === 4 || this.state.tabslist === 7 || this.state.tabslist === 8 ? 3500 : "",
          spin_rate_from: this.state.tabslist === 1 || this.state.tabslist === 2 || this.state.tabslist === 0 || this.state.tabslist === 3  || this.state.tabslist === 4 || this.state.tabslist === 7 || this.state.tabslist === 8 ? 0 : "",
          launch_angle_to: this.state.tabslist === 1 || this.state.tabslist === 2 || this.state.tabslist === 0 || this.state.tabslist === 3  || this.state.tabslist === 4 || this.state.tabslist === 7 || this.state.tabslist === 8 ? 90 : "",
          launch_angle_from: this.state.tabslist === 1 || this.state.tabslist === 2 || this.state.tabslist === 0 || this.state.tabslist === 3  || this.state.tabslist === 4 || this.state.tabslist === 7 || this.state.tabslist === 8 ? -90 : "",
          efficiency_to: this.state.tabslist === 1 || this.state.tabslist === 2 || this.state.tabslist === 0 || this.state.tabslist === 3  || this.state.tabslist === 4 || this.state.tabslist === 7 || this.state.tabslist === 8 ? 100 : "",
          efficiency_from: this.state.tabslist === 1 || this.state.tabslist === 2 || this.state.tabslist === 0 || this.state.tabslist === 3  || this.state.tabslist === 4 || this.state.tabslist === 7 || this.state.tabslist === 8 ? 0 : "",
          vertical_break_to: this.state.tabslist === 1 || this.state.tabslist === 2 || this.state.tabslist === 0 || this.state.tabslist === 3  || this.state.tabslist === 4 || this.state.tabslist === 7 || this.state.tabslist === 8 ? 0 : "",
          vertical_break_from: this.state.tabslist === 1 || this.state.tabslist === 2 || this.state.tabslist === 0 || this.state.tabslist === 3  || this.state.tabslist === 4 || this.state.tabslist === 7 || this.state.tabslist === 8 ? -90 : "",
          induced_vertical_break_to: this.state.tabslist === 1 || this.state.tabslist === 2 || this.state.tabslist === 0 || this.state.tabslist === 3  || this.state.tabslist === 4 || this.state.tabslist === 7 || this.state.tabslist === 8 ? 30 : "",
          induced_vertical_break_from: this.state.tabslist === 1 || this.state.tabslist === 2 || this.state.tabslist === 0 || this.state.tabslist === 3  || this.state.tabslist === 4 || this.state.tabslist === 7 || this.state.tabslist === 8 ? -30 : "",
          horizontal_break_to: this.state.tabslist === 1 || this.state.tabslist === 2 || this.state.tabslist === 0 || this.state.tabslist === 3  || this.state.tabslist === 4 || this.state.tabslist === 7 || this.state.tabslist === 8 ? 30 : "",
          horizontal_break_from: this.state.tabslist === 1 || this.state.tabslist === 2 || this.state.tabslist === 0 || this.state.tabslist === 3  || this.state.tabslist === 4 || this.state.tabslist === 7 || this.state.tabslist === 8 ? -30 : "",
          vertical_approach_angle_to: this.state.tabslist === 1 || this.state.tabslist === 2 || this.state.tabslist === 0 || this.state.tabslist === 3  || this.state.tabslist === 4 || this.state.tabslist === 7 || this.state.tabslist === 8 ? 0 : "",
          vertical_approach_angle_from: this.state.tabslist === 1 || this.state.tabslist === 2 || this.state.tabslist === 0 || this.state.tabslist === 3  || this.state.tabslist === 4 || this.state.tabslist === 7 || this.state.tabslist === 8 ? -10 : "",
          horz_approach_angle_to: this.state.tabslist === 1 || this.state.tabslist === 2 || this.state.tabslist === 0 || this.state.tabslist === 3  || this.state.tabslist === 4 || this.state.tabslist === 7 || this.state.tabslist === 8 ? 10 : "",
          horz_approach_angle_from: this.state.tabslist === 1 || this.state.tabslist === 2 || this.state.tabslist === 0 || this.state.tabslist === 3  || this.state.tabslist === 4 || this.state.tabslist === 7 || this.state.tabslist === 8 ? -10 : "",
          release_height_to: this.state.tabslist === 1 || this.state.tabslist === 2 || this.state.tabslist === 0 || this.state.tabslist === 3  || this.state.tabslist === 4 || this.state.tabslist === 7 || this.state.tabslist === 8 ? 10 : "",
          release_height_from: this.state.tabslist === 1 || this.state.tabslist === 2 || this.state.tabslist === 0 || this.state.tabslist === 3  || this.state.tabslist === 4 || this.state.tabslist === 7 || this.state.tabslist === 8 ? 0 : "",
          extension_to: this.state.tabslist === 1 || this.state.tabslist === 2 || this.state.tabslist === 0 || this.state.tabslist === 3  || this.state.tabslist === 4 || this.state.tabslist === 7 || this.state.tabslist === 8 ? 10 : "",
          extension_from: this.state.tabslist === 1 || this.state.tabslist === 2 || this.state.tabslist === 0 || this.state.tabslist === 3  || this.state.tabslist === 4 || this.state.tabslist === 7 || this.state.tabslist === 8 ? 0 : "",
          heat_map_0: localStorage.getItem("activeType") === "Pitching" ? this.state.tabslist === 1 ? "Avg EV" : "" : this.state.sendReq.tab === "swing_data" ? "" : this.state.tabslist === 7 || this.state.tabslist === 2 || this.state.tabslist === 9 || this.state.tabslist === 8 ? "" : "Avg EV",
          heat_map_1: localStorage.getItem("activeType") === "Pitching" ? this.state.tabslist === 1 ? "Avg EV" : "" : this.state.sendReq.tab === "swing_data" ? "" : this.state.tabslist === 7 || this.state.tabslist === 2 || this.state.tabslist === 9 || this.state.tabslist === 8 ? "" : "Avg EV",
          heat_map_2: localStorage.getItem("activeType") === "Pitching" ? this.state.tabslist === 1 ? "Hard Hit" : "" : this.state.sendReq.tab === "swing_data" ? "" : this.state.tabslist === 7 || this.state.tabslist === 2 || this.state.tabslist === 9 || this.state.tabslist === 8 ? "" : "Hard Hit",
          heat_map_3: localStorage.getItem("activeType") === "Pitching" ? this.state.tabslist === 1 ? "Damage" : "" : this.state.sendReq.tab === "swing_data" ? "" : this.state.tabslist === 7 || this.state.tabslist === 2 || this.state.tabslist === 9 || this.state.tabslist === 8 ? "" : "Damage",
          histrogram_field:"exit_velocity",
          histrogram_bucket:5,
          pitchers: "",
          inning: "",
          catcher: "",
        },
      },
      () => {
        // arr.push("type", "season", "fall_spring"); 
        // if (this.state.tabslist === 3) {
        //   arr = [];
        //   arr.push("season", "fall_spring");
        //   if (this.state.backupReq.date_from && this.state.backupReq.date_to) {
        //     arr.push("date_from");
        //   }
        // } else 
        if (this.state.tabslist === 6) {
          arr = [];
          arr.push("season");
          if (this.state.backupReq.date_from && this.state.backupReq.date_to) {
            arr.push("date_from");
          }
        }
        //  else if (this.state.tabslist === 0 || this.state.tabslist === 4) {
        //   arr = [];
        //   let backup = this.state.backupReq;
        //   Object.keys(backup).map(function (key, index) {
        //     if (backup[key]) {
        //       arr.push(key);
        //     }
        //   })
        //   arr.indexOf("date_to") > -1 && arr.splice(arr.indexOf("date_to"), 1);
        //   arr.indexOf("velocity_to") > -1 && arr.splice(arr.indexOf("velocity_to"), 1);
        //   arr.indexOf("velocity_from") > -1 && arr.splice(arr.indexOf("velocity_from"), 1);
        //   arr.indexOf("exit_velocity_to") > -1 && arr.splice(arr.indexOf("exit_velocity_to"), 1);
        //   arr.indexOf("exit_velocity_from") > -1 && arr.splice(arr.indexOf("exit_velocity_from"), 1);
        //   arr.indexOf("tab") > -1 && arr.splice(arr.indexOf("tab"), 1);
        //   arr.indexOf("heat_map") > -1 && arr.splice(arr.indexOf("heat_map"), 1);
        // }
        else {
          arr = [];
          let backup = this.state.backupReq;
          Object.keys(backup).map(function (key, index) {
            if (backup[key]) {
              arr.push(key);
            }
          });

          arr.indexOf("date_to") > -1 && arr.splice(arr.indexOf("date_to"), 1);
          arr.indexOf("velocity_to") > -1 && arr.splice(arr.indexOf("velocity_to"), 1);
          arr.indexOf("velocity_from") > -1 && arr.splice(arr.indexOf("velocity_from"), 1);
          arr.indexOf("exit_velocity_to") > -1 && arr.splice(arr.indexOf("exit_velocity_to"), 1);
          arr.indexOf("exit_velocity_from") > -1 && arr.splice(arr.indexOf("exit_velocity_from"), 1);
          arr.indexOf("spin_rate_to") > -1 && arr.splice(arr.indexOf("spin_rate_to"), 1);
          arr.indexOf("spin_rate_from") > -1 && arr.splice(arr.indexOf("spin_rate_from"), 1);
          arr.indexOf("launch_angle_to") > -1 && arr.splice(arr.indexOf("launch_angle_to"), 1);
          arr.indexOf("launch_angle_from") > -1 && arr.splice(arr.indexOf("launch_angle_from"), 1);
          arr.indexOf("efficiency_to") > -1 && arr.splice(arr.indexOf("efficiency_to"), 1);
          arr.indexOf("efficiency_from") > -1 && arr.splice(arr.indexOf("efficiency_from"), 1);
          arr.indexOf("vertical_break_to") > -1 && arr.splice(arr.indexOf("vertical_break_to"), 1);
          arr.indexOf("vertical_break_from") > -1 && arr.splice(arr.indexOf("vertical_break_from"), 1);
          arr.indexOf("induced_vertical_break_to") > -1 && arr.splice(arr.indexOf("induced_vertical_break_to"), 1);
          arr.indexOf("induced_vertical_break_from") > -1 && arr.splice(arr.indexOf("induced_vertical_break_from"), 1);
          arr.indexOf("horizontal_break_to") > -1 && arr.splice(arr.indexOf("horizontal_break_to"), 1);
          arr.indexOf("horizontal_break_from") > -1 && arr.splice(arr.indexOf("horizontal_break_from"), 1);
          arr.indexOf("vertical_approach_angle_to") > -1 && arr.splice(arr.indexOf("vertical_approach_angle_to"), 1);
          arr.indexOf("vertical_approach_angle_from") > -1 && arr.splice(arr.indexOf("vertical_approach_angle_from"), 1);
          arr.indexOf("horz_approach_angle_to") > -1 && arr.splice(arr.indexOf("horz_approach_angle_to"), 1);
          arr.indexOf("horz_approach_angle_from") > -1 && arr.splice(arr.indexOf("horz_approach_angle_from"), 1);
          arr.indexOf("release_height_to") > -1 && arr.splice(arr.indexOf("release_height_to"), 1);
          arr.indexOf("release_height_from") > -1 && arr.splice(arr.indexOf("release_height_from"), 1);
          arr.indexOf("extension_to") > -1 && arr.splice(arr.indexOf("extension_to"), 1);
          arr.indexOf("extension_from") > -1 && arr.splice(arr.indexOf("extension_from"), 1);
          arr.indexOf("heat_map_0") > -1 && arr.splice(arr.indexOf("heat_map_0"), 1);
          arr.indexOf("heat_map_1") > -1 && arr.splice(arr.indexOf("heat_map_1"), 1);
          arr.indexOf("heat_map_2") > -1 && arr.splice(arr.indexOf("heat_map_2"), 1);
          arr.indexOf("heat_map_3") > -1 && arr.splice(arr.indexOf("heat_map_3"), 1);
          arr.indexOf("histrogram_field") > -1 && arr.splice(arr.indexOf("histrogram_field"), 1);
          arr.indexOf("histrogram_bucket") > -1 && arr.splice(arr.indexOf("histrogram_bucket"), 1);
          if (this.state.tabslist === 1) {
            arr.indexOf("heat_map_0") === -1 && arr.push("heat_map_0");
            // arr.indexOf("heat_map_1") === -1 && arr.push("heat_map_1");
            // arr.indexOf("heat_map_2") === -1 && arr.push("heat_map_2");
            // arr.indexOf("heat_map_3") === -1 && arr.push("heat_map_3");
          }
          if (this.state.tabslist === 2) {
            arr.indexOf("contour_graph") === -1 && arr.push("contour_graph");
          }
          if (this.state.tabslist === 9 || this.state.tabslist === 6) {
            arr.indexOf("tab") > -1 && arr.splice(arr.indexOf("tab"), 1);
          }
          // if (this.state.tabslist === 9) {
          //   arr.indexOf("pitchers") === -1 && arr.push("pitchers");
          //   arr.indexOf("inning") === -1 && arr.push("inning");
          //   arr.indexOf("catcher") === -1 && arr.push("catcher");
            console.log('arr======', arr)
          // }
        }
        this.setFilter(this.updateRequest(this.state.sendReq));
      }
    );
  };

  getEventReport = () => {
    return (
      this.state.events.length !== 0 &&
      this.state.events.map((list, i) => {
        return (
          <>
            <div className="slider-card active" key={i}>
              <Link
                // to={`/${list.event_type === "BP"
                //   ? "hittersummary"
                //   : list.event_type === "Bullpen"
                //     ? "pitcherssummary"
                //     : "gamesummary"
                //   }/${list.source_id}`}
                to={`/${list.file_type === "Blast" ? "sessionsummary" : list.event_type === "BP" ? "hittersummary" : list.event_type === "Bullpen" ?
                  "pitcherssummary" : list.event_type === "Scrimmage" ?
                    "scrimmagesummary" : "gamesummary"
                  }/${list.source_id}`}
              >
                <div className="price-title">{list.event_type}</div>
                <div className="d-flex">
                  <span>{moment(list.date, "MM-DD-YYYY").format("MMM")}</span>
                  <h5>{moment(list.date, "MM-DD-YYYY").format("DD")}</h5>
                  <span>{moment(list.date, "MM-DD-YYYY").format("YYYY")}</span>
                </div>
                <p>{list.event_name}</p>
                <div className="major_logo">
                  {list.file_type === "Yakkertech" && (
                    <img src={imagesArr.yakkertech} alt="major_logo" />
                  )}
                  {list.file_type === "FlightScope" && (
                    <img src={imagesArr.flightScope} alt="major_logo" />
                  )}
                  {list.file_type.includes("TrackMan") && (
                    <img src={imagesArr.trackMan} alt="major_logo" />
                  )}
                  {list.file_type === "Blast" && (
                    <img src={imagesArr.Blast} alt="major_logo" />
                  )}
                  {list.file_type.includes("Rapsodo") && (
                    <img src={imagesArr.rapsodo} alt="major_logo" />
                  )}
                  {list.file_type === "Hittrax" && (
                    <img src={imagesArr.Hittrax} alt="major_logo" />
                  )}
                  {list.file_type.includes("Savant") && (
                    <img src={imagesArr.Savant} alt="major_logo" />
                  )}
                  {/* <img src={imagesArr.major_logo} alt="major_logo" /> */}
                </div>
              </Link>
            </div>
          </>
        );
      })
    );
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    let old = arr.some((item) => {
      return item === name;
    });

    if (!old) {
      if (name !== "date_to") {
        arr.push(name);
      }
    }

    if (name === "date_from" || name === "date_to") {
      this.setState((prevState) => ({
        ...prevState,
        isFilter: true,
        oldData:null,
        sendReq: {
          ...prevState.sendReq,
          [name]: new Date(value).toISOString(),
        },
        backupReq: {
          ...prevState.backupReq,
          [name]: new Date(value).toISOString(),
        },
      }));
    } else if (name === "season") {
      let sub = "01/01/" + (Number(value) - 1); // min (mm/dd/yyyy)
      let add = "12/31/" + (Number(value) + 1); // max (mm/dd/yyyy)

      this.setState((prevState) => ({
        ...prevState,
        isSeasonFilter: true,
        isFilter: true,
        oldData:null,
        sendReq: {
          ...prevState.sendReq,
          [name]: value,
          fall_spring: "both"
        },
        backupReq: {
          ...prevState.backupReq,
          [name]: value,
          fall_spring: "both"
        },
        minDate: sub,
        maxDate: add
      }));
    } else if (name === "fall_spring") {
      this.setState((prevState) => ({
        ...prevState,
        isFilter: true,
        oldData:null,
        isFallSpringFilter: true,
        sendReq: {
          ...prevState.sendReq,
          [name]: value,
        },
        backupReq: {
          ...prevState.backupReq,
          [name]: value,
        },
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        isFilter: true,
        oldData:null,
        sendReq: {
          ...prevState.sendReq,
          [name]: value,
        },
        backupReq: {
          ...prevState.backupReq,
          [name]: value,
        },
      }));
    }
  };

  handleDateChange = (e, name) => {
    let old = arr.some((item) => {
      return item === name;
    });
    if (!old) {
        // if (name !== "date_to") {
          arr.push(name);
        // }
    }
    if (name === "date_from") {
      arr.indexOf("date_to") > -1 && arr.splice(arr.indexOf("date_to"), 1);
    }
    if (name === "date_from") {
      this.setState((prevState) => ({
        ...prevState,
        sendReq: {
          ...prevState.sendReq,
          [name]: e,
          date_to: "",
        },
        backupReq: {
          ...prevState.backupReq,
          [name]: e,
          date_to: "",
        },
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        isFilter: true,
        oldData:null,
        sendReq: {
          ...prevState.sendReq,
          [name]: e,
        },
        backupReq: {
          ...prevState.backupReq,
          [name]: e,
        },
      }));
    }
  };

  // for all range filter custom handle change
  handleRangeChange = (e, rangeFrom, rangeTo) => {
    let oldRange = arr.some((item) => {
      return item === rangeFrom;
    });

    if (!oldRange) {
      arr.push(rangeFrom);
    }

    this.setState((prevState) => ({
      ...prevState,
      isFilter: true,
      oldData:null,
      sendReq: {
        ...prevState.sendReq,
        [rangeTo]: e[1],
        [rangeFrom]: e[0],
      },
      backupReq: {
        ...prevState.backupReq,
        [rangeTo]: e[1],
        [rangeFrom]: e[0],
      },
    }));
  };

  // for change pitchers, Inning and Catcher
  handlePICChange = (e, data) => {
    let old = arr.some((item) => {
      return item === data;
    });
    if (!old) {
      arr.push(data);
    }
    this.setState((prevState) => ({
      ...prevState,
        isFilter: true,
        oldData:null,
        sendReq: {
          ...prevState.sendReq,
          [data]: e.value,
        },
        backupReq: {
          ...prevState.backupReq,
          [data]: e.value,
        },
    }));
  };

  removeFilter = (e) => {
    arr.map((d, i) => {
      if (d === e) {
        arr.splice(i, 1);
      }
    });
    if (e === "velocity_from" || e === "exit_velocity_from" || e === "spin_rate_from" || e === "launch_angle_from" || e === "efficiency_from" || e === "vertical_break_from" ||
        e === "induced_vertical_break_from" || e === "horizontal_break_from" || e === "vertical_approach_angle_from" || e === "horz_approach_angle_from" || e === "release_height_from" || e === "extension_from") {
      this.setState(
        (prevState) => ({
          ...prevState,
          // isFilter: false,
          oldData: null,
          sendReq: {
            ...prevState.sendReq,
            scatter : undefined, 
            // date_from: e === "date_from" ? "" : this.state.sendReq.date_from,
            // date_to: e === "date_from" ? "" : this.state.sendReq.date_to,
            velocity_from: e === "velocity_from" ? "" : this.state.sendReq.velocity_from,
            velocity_to: e === "velocity_from" ? "" : this.state.sendReq.velocity_to,
            exit_velocity_from: e === "exit_velocity_from" ? "" : this.state.sendReq.exit_velocity_from,
            exit_velocity_to: e === "exit_velocity_from" ? "" : this.state.sendReq.exit_velocity_to,
            spin_rate_from: e === "spin_rate_from" ? "" : this.state.sendReq.spin_rate_from,
            spin_rate_to: e === "spin_rate_from" ? "" : this.state.sendReq.spin_rate_to,
            launch_angle_from: e === "launch_angle_from" ? "" : this.state.sendReq.launch_angle_from,
            launch_angle_to: e === "launch_angle_from" ? "" : this.state.sendReq.launch_angle_to,
            efficiency_from: e === "efficiency_from" ? "" : this.state.sendReq.efficiency_from,
            efficiency_to: e === "efficiency_from" ? "" : this.state.sendReq.efficiency_to,
            vertical_break_from: e === "vertical_break_from" ? "" : this.state.sendReq.vertical_break_from,
            vertical_break_to: e === "vertical_break_from" ? "" : this.state.sendReq.vertical_break_to,
            induced_vertical_break_from: e === "induced_vertical_break_from" ? "" : this.state.sendReq.induced_vertical_break_from,
            induced_vertical_break_to: e === "induced_vertical_break_from" ? "" : this.state.sendReq.induced_vertical_break_to,
            horizontal_break_from: e === "horizontal_break_from" ? "" : this.state.sendReq.horizontal_break_from,
            horizontal_break_to: e === "horizontal_break_from" ? "" : this.state.sendReq.horizontal_break_to,
            vertical_approach_angle_from: e === "vertical_approach_angle_from" ? "" : this.state.sendReq.vertical_approach_angle_from,
            vertical_approach_angle_to: e === "vertical_approach_angle_from" ? "" : this.state.sendReq.vertical_approach_angle_to,
            horz_approach_angle_from: e === "horz_approach_angle_from" ? "" : this.state.sendReq.horz_approach_angle_from,
            horz_approach_angle_to: e === "horz_approach_angle_from" ? "" : this.state.sendReq.horz_approach_angle_to,
            release_height_from: e === "release_height_from" ? "" : this.state.sendReq.release_height_from,
            release_height_to: e === "release_height_from" ? "" : this.state.sendReq.release_height_to,
            extension_from: e === "extension_from" ? "" : this.state.sendReq.extension_from,
            extension_to: e === "extension_from" ? "" : this.state.sendReq.extension_to,
          },
          backupReq: {
            ...prevState.backupReq,
            scatter : undefined, 
            // date_from: e === "date_from" ? "" : this.state.backupReq.date_from,
            // date_to: e === "date_from" ? "" : this.state.backupReq.date_to,
            exit_velocity_from: e === "exit_velocity_from" ? "" : this.state.backupReq.exit_velocity_from,
            exit_velocity_to: e === "exit_velocity_from" ? "" : this.state.backupReq.exit_velocity_to,
            velocity_from: e === "velocity_from" ? "" : this.state.backupReq.velocity_from,
            velocity_to: e === "velocity_from" ? "" : this.state.backupReq.velocity_to,
            spin_rate_from: e === "spin_rate_from" ? "" : this.state.backupReq.spin_rate_from,
            spin_rate_to: e === "spin_rate_from" ? "" : this.state.backupReq.spin_rate_to,
            launch_angle_from: e === "launch_angle_from" ? "" : this.state.backupReq.launch_angle_from,
            launch_angle_to: e === "launch_angle_from" ? "" : this.state.backupReq.launch_angle_to,
            efficiency_from: e === "efficiency_from" ? "" : this.state.backupReq.efficiency_from,
            efficiency_to: e === "efficiency_from" ? "" : this.state.backupReq.efficiency_to,
            vertical_break_from: e === "vertical_break_from" ? "" : this.state.backupReq.vertical_break_from,
            vertical_break_to: e === "vertical_break_from" ? "" : this.state.backupReq.vertical_break_to,
            induced_vertical_break_from: e === "induced_vertical_break_from" ? "" : this.state.backupReq.induced_vertical_break_from,
            induced_vertical_break_to: e === "induced_vertical_break_from" ? "" : this.state.backupReq.induced_vertical_break_to,
            horizontal_break_from: e === "horizontal_break_from" ? "" : this.state.backupReq.horizontal_break_from,
            horizontal_break_to: e === "horizontal_break_from" ? "" : this.state.backupReq.horizontal_break_to,
            vertical_approach_angle_from: e === "vertical_approach_angle_from" ? "" : this.state.backupReq.vertical_approach_angle_from,
            vertical_approach_angle_to: e === "vertical_approach_angle_from" ? "" : this.state.backupReq.vertical_approach_angle_to,
            horz_approach_angle_from: e === "horz_approach_angle_from" ? "" : this.state.backupReq.horz_approach_angle_from,
            horz_approach_angle_to: e === "horz_approach_angle_from" ? "" : this.state.backupReq.horz_approach_angle_to,
            release_height_from: e === "release_height_from" ? "" : this.state.backupReq.release_height_from,
            release_height_to: e === "release_height_from" ? "" : this.state.backupReq.release_height_to,
            extension_from: e === "extension_from" ? "" : this.state.backupReq.extension_from,
            extension_to: e === "extension_from" ? "" : this.state.backupReq.extension_to,
          },
        }),
        () => {
          this.setFilter(this.updateRequest(this.state.sendReq));
        }
      );
    } else {
      this.setState(
        (prevState) => ({
          // isFilter: false,
          ...prevState,
          oldData: null,
          sendReq: {
            ...prevState.sendReq,
            scatter : undefined,    
            [e]: "",
          },
          backupReq: {
            ...prevState.backupReq,
            scatter : undefined,
            [e]: "",
          },
        }),
        () => {
          this.setFilter(this.updateRequest(this.state.sendReq));
        }
      );
    }
  };
  updateRequest = (req)=>{
    return{
      ...req,
      // scatter : undefined,
      date_from: req.date_from ? moment.utc(moment(req.date_from).format('DD-MM-YYYY'),'DD-MM-YYYY').toISOString() : "",
      date_to: req.date_to ? moment.utc(moment(req.date_to).format('DD-MM-YYYY'),'DD-MM-YYYY').toISOString() : ""
    }
  }

  applyFilter = () => {
    this.setState((prevState)=>({
      ...prevState,
      isFilter: true,
      oldData:null,
      sendReq: {
        ...prevState.sendReq,
        scatter : undefined,
      },
      backupReq: {
        ...prevState.backupReq,
        scatter : undefined
      },
    }),()=>{
      this.setFilter(this.updateRequest(this.state.sendReq));
    })
  };

  clearState = () =>{
    this.setState({
      isFilter: false,
      oldData: null,
      parentOldData: null,
      sendReq: {
        tab:
          localStorage.getItem("activeType") === "Pitching"
            ? "pitch_outcomes"
            : "batted_ball",
        type: localStorage.getItem("activeType"),
        pitcher_throws: "",
        batter_stands: "",
        pitch_type: "",
        play_result: "",
        zone: "",
        event_type: "",
        date_from: "",
        date_to: "",
        season: "",
        contour_graph: "",
        count: "",
        home_away: "",
        fall_spring: "",
        velocity_to: "",
        velocity_from: "",
        exit_velocity_to: "",
        exit_velocity_from: "",
        spin_rate_to: "",
        spin_rate_from: "",
        launch_angle_to: "",
        launch_angle_from: "",
        efficiency_to: "",
        efficiency_from: "",
        vertical_break_to: "",
        vertical_break_from: "",
        induced_vertical_break_to: "",
        induced_vertical_break_from: "",
        horizontal_break_to: "",
        horizontal_break_from: "",
        vertical_approach_angle_to: "",
        vertical_approach_angle_from: "",
        horz_approach_angle_to: "",
        horz_approach_angle_from: "",
        release_height_to: "",
        release_height_from: "",
        extension_to: "",
        extension_from: "",
        heat_map_0: localStorage.getItem("activeType") === "Pitching" ? "" : "Avg EV",
        heat_map_1: localStorage.getItem("activeType") === "Pitching" ? "" : "Avg EV",
        heat_map_2: localStorage.getItem("activeType") === "Pitching" ? "" : "Hard Hit",
        heat_map_3: localStorage.getItem("activeType") === "Pitching" ? "" : "Damage",
        histrogram_field:"exit_velocity",
        histrogram_bucket:5,
        pitchers: "",
        inning: "",
        catcher: "",
      },
      backupReq: {
        tab:
          localStorage.getItem("activeType") === "Pitching"
            ? "pitch_outcomes"
            : "batted_ball",
        type: localStorage.getItem("activeType"),
        pitcher_throws: "",
        batter_stands: "",
        pitch_type: "",
        play_result: "",
        zone: "",
        event_type: "",
        date_from: "",
        date_to: "",
        season: "",
        contour_graph: "",
        count: "",
        home_away: "",
        fall_spring: "",
        velocity_to: "",
        velocity_from: "",
        exit_velocity_to: "",
        exit_velocity_from: "",
        spin_rate_to: "",
        spin_rate_from: "",
        launch_angle_to: "",
        launch_angle_from: "",
        efficiency_to: "",
        efficiency_from: "",
        vertical_break_to: "",
        vertical_break_from: "",
        induced_vertical_break_to: "",
        induced_vertical_break_from: "",
        horizontal_break_to: "",
        horizontal_break_from: "",
        vertical_approach_angle_to: "",
        vertical_approach_angle_from: "",
        horz_approach_angle_to: "",
        horz_approach_angle_from: "",
        release_height_to: "",
        release_height_from: "",
        extension_to: "",
        extension_from: "",
        heat_map_0: localStorage.getItem("activeType") === "Pitching" ? "" : "Avg EV",
        heat_map_1: localStorage.getItem("activeType") === "Pitching" ? "" : "Avg EV",
        heat_map_2: localStorage.getItem("activeType") === "Pitching" ? "" : "Hard Hit",
        heat_map_3: localStorage.getItem("activeType") === "Pitching" ? "" : "Damage",
        histrogram_field:"exit_velocity",
        histrogram_bucket:5,
        pitchers: "",
        inning: "",
        catcher: "",
      },
    })
  }
  
  handlePlayResultChange = (e) => {
    const { name, value } = e.target;
    if (name !== "histrogram_field" && name !== "histrogram_bucket" && name !== "heat_map_1" && name !== "heat_map_2" && name !== "heat_map_3") {
      if (value) {
        let old = arr.some((item) => {
          return item === name;
        });
  
        if (!old) {
          arr.push(name);
        }
      } else {
        arr.map((d, i) => {
          if (d === name) {
            arr.splice(i, 1);
          }
        });
      }
    }
    
    this.setState(
      (prevState) => ({
        ...prevState,
        sendReq: {
          ...prevState.sendReq,
          scatter : undefined,
          [name]: value,
        },
        backupReq: {
          ...prevState.backupReq,
          scatter : undefined,
          [name]: value,
        },
      }),
      () => {
        this.setFilter(this.updateRequest(this.state.sendReq));
        this.setState(
          (prevState) => ({
            ...prevState,
            filterRes: {
              ...prevState.filterRes,
              ["histrogram_chart_data"]: [],
            },
          }),
        )
      }
    );
  };

  handleDateChangeRaw = (e) => {
    e.preventDefault();
  };

  setScatterAxis = e => {
    const activeTabData = filterScatterPlotNullValues(getActiveTabData(this.state.tabslist,this.state.oldData));
    const selectedChartData = activeTabData.filter(obj => {
      return (
        betweenAndEqual(obj.x, e.xAxis[0].min, e.xAxis[0].max) &&
        betweenAndEqual(obj.y, e.yAxis[0].min, e.yAxis[0].max)
      );
    });
    if (selectedChartData.length !== activeTabData.length) {
      const selectedChartDataIds = selectedChartData.map(item => item._id);
      this.setState((prevState)=>({
        ...prevState,
        sendReq:{
          ...prevState.sendReq,
          scatter:selectedChartDataIds
        },
        backupReq:{
          ...prevState.backupReq,
          scatter:selectedChartDataIds
        }
      }),()=>{
        this.setFilter(this.updateRequest(this.state.sendReq));
      })
      
    } else if (selectedChartData.length === activeTabData.length) {
       this.setState((prevState)=>({
        ...prevState,
        sendReq:{
          ...prevState.sendReq,
          scatter:undefined
        },
        backupReq:{
          ...prevState.backupReq,
          scatter:undefined
        }
      }),()=>{
        this.setFilter(this.updateRequest(this.state.sendReq));
      })
    }
  };

  print = async () => {
    await this.setState({ isPrinting: true })
    await printToPDFMultiple(`profilePage`, ['tabprint']);
    this.setState({ isPrinting: false })
  };

  render() {
    const tabs = this.state.tabs;
    const tabslist = this.state.tabslist;

    const {
      sendReq,
      filterRes,
      eventReq,
      teamInfo,
      role,
      seasonList,
      minDate,
      maxDate,
      isPrinting
    } = this.state;

    return (
      <>
        {/* sidebar-wrapper */}
        <div className="sidebar-wrapper d-md-block d-none">
          <div className="sidebar-logo">
            <Link to={"/"}>
              <img src={imagesArr.logo} alt="logo" />
            </Link>
            <p>{teamInfo.name}</p>
          </div>
        </div>
        {/* sidebar-wrapper */}
        <div className="d-flex">
          <div
            className={`page-content-wrapper ml-0 w-100 ${tabs === 0 || tabs === 1 ? "tabactive" : ""
              }`}
          >

            <Header
              parentCallback={this.handleCallback}
              renderPageSummary={this.renderPageSummary}
              clearState={this.clearState}
              page="profile"
              pitchrIframeFlag={this.state.pitchrIframeFlag}
              PitchrIframeEvent={() => this.setState((prevState) => ({ ...prevState, pitchrIframeFlag: "yes" }))}
            />
            {isPrinting && <Loader fullview={true}  />}
            {this.state.isDownloadImg && <Loader fullview={true} text={true} />}
            {tabs === 0 && <Teamsummary />}
            {tabs === 1 && <Development />}
            {tabs !== 0 && tabs !== 1 && (
              <>
                <div className="main-div">
                  <section className="main-dashboard profiledashboard" id="tabprint">
                    {isPrinting ? <h3>{this.state?.eventProfileData?.fname + " " + this.state?.eventProfileData?.lname}</h3> : ""}
                    {!isPrinting ? <ProfileEvent
                      eventProfileData={this.state.eventProfileData}
                      teamInfo={teamInfo}
                      getEventReport={this.getEventReport}
                      events={this.state.events} /> : ""}

                    {!isPrinting ? <TabPanel
                      tabslist={tabslist}
                      handleTabs={this.handleTabs}
                      filterIndex={this.state.filterIndex}
                      profileList={this.state.profileList}
                      handlePagination={this.handlePagination} /> : ""}

                    {tabslist !== 5 && (
                      <AppliedFilter
                        sendReq={sendReq}
                        tabslist={tabslist}
                        arr={arr}
                        removeFilter={this.removeFilter}
                        print={this.print}
                        handleToggle={this.handleToggle}
                        isPrinting={isPrinting} />
                    )}

                    {!isPrinting ? !this.state.isshow && tabslist !== 5 && (
                      <>
                        <FilterForm
                          sendReq={sendReq}
                          inning={this.state.inning}
                          tabslist={tabslist}
                          seasonList={seasonList}
                          handleChange={this.handleChange}
                          handleDateChange={this.handleDateChange}
                          handleRangeChange={this.handleRangeChange}
                          handlePICChange={this.handlePICChange}
                          handleVeloRangeChange={this.handleVeloRangeChange}
                          applyFilter={this.applyFilter}
                          handleCancelToggle={this.handleCancelToggle}
                          filterRes={filterRes && filterRes}
                          minDate={minDate}
                          maxDate={maxDate} />
                      </>
                    ) : ""}

                    {/* Pitch outcomes */}
                    {localStorage.getItem("activeType") === "Pitching" &&
                      tabslist === 0 && (
                      <Pitcharsenal
                        filterRes={filterRes && filterRes}
                        applyFilter={this.applyFilter}
                        setScatterAxis={this.setScatterAxis}
                        oldData={this.state.oldData && this.state.oldData.strike_zone_chart}
                        isPrinting={isPrinting}
                        eventProfileData={this.state.eventProfileData}
                        sendReq={sendReq}
                        tabslist={tabslist}
                        isNotDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: false }))}
                        isDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: true }))}
                        pitchrIframeFlag={this.state.pitchrIframeFlag}
                        closePitchrIframe={() => this.setState((prevState) => ({ ...prevState, pitchrIframeFlag: "" }))}
                      />
                      )}
                    {tabslist === 1 && (
                      <Battedball filterRes={filterRes && filterRes}
                        heat_map_0={sendReq.heat_map_0}
                        heat_map_1={sendReq.heat_map_1}
                        heat_map_2={sendReq.heat_map_2}
                        heat_map_3={sendReq.heat_map_3}
                        histrogram_field={sendReq.histrogram_field}
                        histrogram_bucket = {sendReq.histrogram_bucket}
                        setScatterAxis={this.setScatterAxis}
                        applyFilter={this.applyFilter}
                        onChangeEvent={this.handlePlayResultChange}
                        isPrinting={isPrinting}
                        eventProfileData={this.state.eventProfileData}
                        sendReq={sendReq}
                        tabslist={tabslist}
                        oldData={this.state.oldData && this.state.oldData.batted_ball_scatter_plot}
                        isNotDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: false }))}
                        isDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: true }))}
                        pitchrIframeFlag={this.state.pitchrIframeFlag}
                        closePitchrIframe={() => this.setState((prevState) => ({ ...prevState, pitchrIframeFlag: "" }))}
                      />
                    )}
                    {localStorage.getItem("activeType") !== "Pitching" &&
                      tabslist === 2 && (
                      <Platediscipline
                        filterRes={filterRes && filterRes}
                        contour_graph={sendReq.contour_graph}
                        play_result={sendReq.play_result}
                        setScatterAxis={this.setScatterAxis}
                        applyFilter={this.applyFilter}
                        onChangeEvent={this.handlePlayResultChange}
                        isPrinting={isPrinting}
                        eventProfileData={this.state.eventProfileData}
                        sendReq={sendReq}
                        tabslist={tabslist}
                        oldData={this.state.oldData && this.state.oldData.plate_discipline_scatter_plot}
                        isNotDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: false }))}
                        isDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: true }))}
                        pitchrIframeFlag={this.state.pitchrIframeFlag}
                        closePitchrIframe={() => this.setState((prevState) => ({ ...prevState, pitchrIframeFlag: "" }))}
                      />
                    )}
                    {localStorage.getItem("activeType") === "Pitching" &&
                      tabslist === 3 && (
                      <Breakmovement
                        filterRes={filterRes && filterRes}
                        isPrinting={isPrinting}
                        eventProfileData={this.state.eventProfileData}
                        sendReq={sendReq}
                        tabslist={tabslist}
                        isNotDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: false }))}
                        isDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: true }))}
                      />
                      )}
                    {localStorage.getItem("activeType") === "Pitching" &&
                      tabslist === 4 && (
                      <Releasepoint
                        filterRes={filterRes && filterRes}
                        setScatterAxis={this.setScatterAxis}
                        applyFilter={this.applyFilter}
                        isPrinting={isPrinting}
                        eventProfileData={this.state.eventProfileData}
                        sendReq={sendReq}
                        tabslist={tabslist}
                        oldData={this.state.oldData && this.state.oldData.break_movement_strike_zone}
                        isNotDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: false }))}
                        isDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: true }))}
                        pitchrIframeFlag={this.state.pitchrIframeFlag}
                        closePitchrIframe={() => this.setState((prevState) => ({ ...prevState, pitchrIframeFlag: "" }))}
                      />
                      )}
                    {
                      tabslist === 8 && (
                        <HeatMapTab
                          filterRes={filterRes && filterRes}
                          eventProfileData={this.state.eventProfileData}
                          sendReq={sendReq}
                          tabslist={tabslist}
                          isNotDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: false }))}
                          isDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: true }))}
                        />
                      )}
                    {localStorage.getItem("activeType") !== "Pitching" &&
                      tabslist === 7 && (
                      <PointOfContact filterRes={filterRes && filterRes}
                        isPrinting={isPrinting}
                        eventProfileData={this.state.eventProfileData}
                        sendReq={sendReq}
                        tabslist={tabslist}
                        isNotDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: false }))}
                        isDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: true }))}
                        pitchrIframeFlag={this.state.pitchrIframeFlag}
                        closePitchrIframe={() => this.setState((prevState) => ({ ...prevState, pitchrIframeFlag: "" }))}
                      />
                      )}
                    {localStorage.getItem("activeType") !== "Pitching" &&
                      tabslist === 6 && (
                      <SwingDataBlast filterRes={filterRes && filterRes}
                        isPrinting={isPrinting}
                        eventProfileData={this.state.eventProfileData}
                        sendReq={sendReq}
                        tabslist={tabslist}
                        isNotDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: false }))}
                        isDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: true }))}
                      />
                      )}
                    {localStorage.getItem("activeType") !== "Pitching" &&
                      tabslist === 9 && (
                      <CatchingTab filterRes={filterRes && filterRes}
                        isPrinting={isPrinting}
                        eventProfileData={this.state.eventProfileData}
                        sendReq={sendReq}
                        tabslist={tabslist}
                        isNotDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: false }))}
                        isDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: true }))}
                      />
                      )}
                    {tabslist === 5 && (
                      <Plan
                        pid={eventReq && eventReq.playerId}
                        showButton={role}
                      />
                    )}
                  </section>
                </div>
              </>
            )}
          </div>
          {/* <Footer /> */}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profileDashboardData: state.app.profileDashboardData,
    profileFilterData: state.app.profileFilterData,
    profileEventsData: state.app.profileEventsData,
    getUserData: state.app.getUserData,
    appState: state.app
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchProfileDashboardData: (data, next) => dispatch(getProfileData(data, next)),
  fetchFilterData: (data, uid, next) =>  dispatch(getFilterData(data, uid, next)),
  fetchProfileEventData: (data, next) => dispatch(getEventData(data, next)),
  fetchUserRoleData: (next) => dispatch(getUserRoleData(next)),
  fetchProfileData: (data, next) => dispatch(getDashboardData(data, next)),
  loader: (data) => dispatch({ type: "LOADING", payload: data }),
});
export default connect(mapStateToProps, mapDispatchToProps)(profiledashboard);
