import { useEffect, useState } from "react";
import { constant } from "../../../../shared/constant";
import { playerDevelopmentXformMaxAndAvg } from "../../../../shared/function";

function SummaryTable({ data , type ,subType }) {
    return (
        <>
            {type === "batting" ?
                 subType !== "contact" ? Object.keys(data).map((keyName, i) => {
                        return (
                            <>
                                <tr>
                                    <td className="tablerowgray">Pitch Type</td>
                                    <td className="tablerowgray" style={{ color: constant.pitchTypeColor[data[keyName].groupA.type] }}>{data[keyName].groupA.type}</td>
                                    <td className="tablerowgray" style={{ color: constant.pitchTypeColor[data[keyName].groupB.type] }}>{data[keyName].groupB.type}</td>
                                    <td className="tablerowgray">-</td>
                                </tr>
                                <tr>
                                    <td className="table-rowwhite">Distance</td>
                                    <td className="table-rowwhite">{data[keyName].groupA.distance}</td>
                                    <td className="table-rowwhite">{data[keyName].groupB.distance}</td>
                                    <td className="table-rowwhite">{data[keyName].differ.distance}</td>
                                </tr>
                                <tr>
                                    <td className="table-rowwhite">Launch Angle</td>
                                    <td className="table-rowwhite">{data[keyName].groupA.angle}</td>
                                    <td className="table-rowwhite">{data[keyName].groupB.angle}</td>
                                    <td className="table-rowwhite">{data[keyName].differ.angle}</td>
                                </tr>
                                <tr>
                                    <td className="table-rowwhite">Exit Velocity</td>
                                    <td className="table-rowwhite">{data[keyName].groupA.velo}</td>
                                    <td className="table-rowwhite">{data[keyName].groupB.velo}</td>
                                    <td className="table-rowwhite">{data[keyName].differ.velo}</td>
                                </tr>
                            </>
                        )
                    }
                    )
                        :
                        <>
                            <tr>
                                <td className="table-rowwhite">GB</td>
                                <td className="table-rowwhite">{data.groupA.gb_percentage.toString().includes(".") ? data.groupA.gb_percentage.toFixed(1) : data.groupA.gb_percentage}%</td>
                                <td className="table-rowwhite">{data.groupB.gb_percentage.toString().includes(".") ? data.groupB.gb_percentage.toFixed(1) : data.groupB.gb_percentage}%</td>
                                <td className="table-rowwhite">
                                    {(data.groupA.gb_percentage - data.groupB.gb_percentage).toString().includes(".") 
                                    ? (data.groupA.gb_percentage - data.groupB.gb_percentage).toFixed(1) 
                                    : data.groupA.gb_percentage - data.groupB.gb_percentage }%</td>
                            </tr>
                            <tr>
                                <td className="table-rowwhite">LD</td>
                                <td className="table-rowwhite">{data.groupA.ld_percentage.toString().includes(".") ? data.groupA.ld_percentage.toFixed(1) :data.groupA.ld_percentage}%</td>
                                <td className="table-rowwhite">{data.groupB.ld_percentage.toString().includes(".") ? data.groupB.ld_percentage.toFixed(1) :data.groupB.ld_percentage}%</td>
                                <td className="table-rowwhite">
                                    {(data.groupA.ld_percentage - data.groupB.ld_percentage).toString().includes(".") 
                                    ? (data.groupA.ld_percentage - data.groupB.ld_percentage).toFixed(1) 
                                    : data.groupA.ld_percentage - data.groupB.ld_percentage }%</td>
                            </tr>
                            <tr>
                                <td className="table-rowwhite">FB</td>
                                <td className="table-rowwhite">{data.groupA.fb_percentage.toString().includes(".") ? data.groupA.fb_percentage.toFixed(1) :data.groupA.fb_percentage}%</td>
                                <td className="table-rowwhite">{data.groupB.fb_percentage.toString().includes(".") ? data.groupB.fb_percentage.toFixed(1) :data.groupB.fb_percentage}%</td>
                                <td className="table-rowwhite">
                                    {(data.groupA.fb_percentage - data.groupB.fb_percentage).toString().includes(".") 
                                    ? (data.groupA.fb_percentage - data.groupB.fb_percentage).toFixed(1) 
                                    : data.groupA.fb_percentage - data.groupB.fb_percentage }%</td>
                            </tr>
                            <tr>
                                <td className="table-rowwhite">PU</td>
                                <td className="table-rowwhite">{data.groupA.pu_percentage.toString().includes(".") ? data.groupA.pu_percentage.toFixed(1) :data.groupA.pu_percentage}%</td>
                                <td className="table-rowwhite">{data.groupB.pu_percentage.toString().includes(".") ? data.groupB.pu_percentage.toFixed(1) :data.groupB.pu_percentage}%</td>
                                <td className="table-rowwhite">
                                    {(data.groupA.pu_percentage - data.groupB.pu_percentage).toString().includes(".") 
                                    ? (data.groupA.pu_percentage - data.groupB.pu_percentage).toFixed(1) 
                                    : data.groupA.pu_percentage - data.groupB.pu_percentage }%</td>
                            </tr>
                        </>
                :
                subType !== "contact" ? Object.keys(data).map((keyName, i) => {
                    return (
                        <>
                            <tr>
                                <td className="tablerowgray">Pitch Type</td>
                                <td className="tablerowgray" style={{ color: constant.pitchTypeColor[data[keyName].groupA.type] }}>{data[keyName].groupA.type}</td>
                                <td className="tablerowgray" style={{ color: constant.pitchTypeColor[data[keyName].groupB.type] }}>{data[keyName].groupB.type}</td>
                                <td className="tablerowgray">-</td>
                            </tr>
                            <tr>
                                <td className="table-rowwhite">Velocity</td>
                                <td className="table-rowwhite">{data[keyName].groupA.velocity}</td>
                                <td className="table-rowwhite">{data[keyName].groupB.velocity}</td>
                                <td className="table-rowwhite">{data[keyName].differ.velocity}</td>
                            </tr>
                            <tr>
                                <td className="table-rowwhite">Spin Rate</td>
                                <td className="table-rowwhite">{data[keyName].groupA.spin}</td>
                                <td className="table-rowwhite">{data[keyName].groupB.spin}</td>
                                <td className="table-rowwhite">{data[keyName].differ.spin}</td>
                            </tr>
                            <tr>
                                <td className="table-rowwhite">Extension</td>
                                <td className="table-rowwhite">{data[keyName].groupA.extension}</td>
                                <td className="table-rowwhite">{data[keyName].groupB.extension}</td>
                                <td className="table-rowwhite">{data[keyName].differ.extension}</td>
                            </tr>
                        </>
                    )
                })
                    :
                    <>
                        <tr>
                                <td className="table-rowwhite">GB</td>
                                <td className="table-rowwhite">{data.groupA.gb_percentage.toString().includes(".") ? data.groupA.gb_percentage.toFixed(1) : data.groupA.gb_percentage}%</td>
                                <td className="table-rowwhite">{data.groupB.gb_percentage.toString().includes(".") ? data.groupB.gb_percentage.toFixed(1) : data.groupB.gb_percentage}%</td>
                                <td className="table-rowwhite">
                                    {(data.groupA.gb_percentage - data.groupB.gb_percentage).toString().includes(".") 
                                    ? (data.groupA.gb_percentage - data.groupB.gb_percentage).toFixed(1) 
                                    : data.groupA.gb_percentage - data.groupB.gb_percentage }%</td>
                            </tr>
                            <tr>
                                <td className="table-rowwhite">LD</td>
                                <td className="table-rowwhite">{data.groupA.ld_percentage.toString().includes(".") ? data.groupA.ld_percentage.toFixed(1) :data.groupA.ld_percentage}%</td>
                                <td className="table-rowwhite">{data.groupB.ld_percentage.toString().includes(".") ? data.groupB.ld_percentage.toFixed(1) :data.groupB.ld_percentage}%</td>
                                <td className="table-rowwhite">
                                    {(data.groupA.ld_percentage - data.groupB.ld_percentage).toString().includes(".") 
                                    ? (data.groupA.ld_percentage - data.groupB.ld_percentage).toFixed(1) 
                                    : data.groupA.ld_percentage - data.groupB.ld_percentage }%</td>
                            </tr>
                            <tr>
                                <td className="table-rowwhite">FB</td>
                                <td className="table-rowwhite">{data.groupA.fb_percentage.toString().includes(".") ? data.groupA.fb_percentage.toFixed(1) :data.groupA.fb_percentage}%</td>
                                <td className="table-rowwhite">{data.groupB.fb_percentage.toString().includes(".") ? data.groupB.fb_percentage.toFixed(1) :data.groupB.fb_percentage}%</td>
                                <td className="table-rowwhite">
                                    {(data.groupA.fb_percentage - data.groupB.fb_percentage).toString().includes(".") 
                                    ? (data.groupA.fb_percentage - data.groupB.fb_percentage).toFixed(1) 
                                    : data.groupA.fb_percentage - data.groupB.fb_percentage }%</td>
                            </tr>
                            <tr>
                                <td className="table-rowwhite">PU</td>
                                <td className="table-rowwhite">{data.groupA.pu_percentage.toString().includes(".") ? data.groupA.pu_percentage.toFixed(1) :data.groupA.pu_percentage}%</td>
                                <td className="table-rowwhite">{data.groupB.pu_percentage.toString().includes(".") ? data.groupB.pu_percentage.toFixed(1) :data.groupB.pu_percentage}%</td>
                                <td className="table-rowwhite">
                                    {(data.groupA.pu_percentage - data.groupB.pu_percentage).toString().includes(".") 
                                    ? (data.groupA.pu_percentage - data.groupB.pu_percentage).toFixed(1) 
                                    : data.groupA.pu_percentage - data.groupB.pu_percentage }%</td>
                            </tr>
                    </>
            }
        </>
    )
}

export default SummaryTable;