import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { splineChartWithDate } from "../../../../shared/function";
import PropTypes from "prop-types";
import { constant } from "../../../../shared/constant";
import domtoimage from 'dom-to-image';
import PrintImagePopup from "../../../../shared/PrintImagePopup";
import { downloadChartPrintImg } from "../../../../shared/printReportUtils";

function SplineHorizontalChart({ chartData, eventProfileData, sendReq, tabslist, profilepage, isNotDownloadImgLoader, isDownloadImgLoader }) {
  const [isChartImg, setIsChartImg] = useState(false);
  const [clickFlag, setClickFlag] = useState(false);
  const [state, setState] = useState({
    chart: {
      // type: "spline",
      // borderWidth: 1,
      marginTop: 55,
      borderColor: "#D8D8D8",
      borderRadius: "8px",
    },
    title: {
      text: ""
      // text: "H Break",
      // layout: "horizontal",
      // align: "left",
      // verticalAlign: "left",
      // y: 20,
      // x: 10,
      // style: {
      //   color: "#1B1B1B",
      //   fontFamily: "Poppins, sans-serif",
      //   fontSize: "18px",
      //   fontWeight: "bold",
      // },
    },
    xAxis: {
      endOnTick: false,
      maxPadding: 0,
      gridLineWidth: 1,
      type: "datetime",
      title: {
        text: "Date",
        style: {
          color: "#777F8F",
          fontFamily: "Poppins, sans-serif",
          fontSize: "14px",
          fontWeight: "500",
        },
      },
      labels: {
        formatter: function () {
          return Highcharts.dateFormat("%e %b'%y", this.value);
        },
      },
    },
    yAxis: {
      endOnTick: false,
      maxPadding: 0,
      gridLineWidth: 1,
      title: {
        text: "Inches of Break",
        style: {
          color: "#777F8F",
          fontFamily: "Poppins, sans-serif",
          fontSize: "14px",
          fontWeight: "500",
        },
      },
      labels: {
        formatter: function () {
          return this.value + "";
        },
      },
      min: -30,
      max: 30,
    },
    legend: {
      layout: "horizontal",
      align: "top",
      verticalAlign: "top",
      // y: 35,
    },
    credits: { enabled: false },
    plotOptions: {
      series: {
        marker: {
          enabled: false
        }
      }
    },
    tooltip: {
      formatter: function () {
      var tooltip = `<span>
      <span style="font-size: 10px">${Highcharts.dateFormat('%A, %b %e, %Y', new Date(this.x))}</span><br/> 
      <span style="color:${this.point.color}">●</span> ${Object.keys(constant.pitchTypeAbbrevations).find(key => constant.pitchTypeAbbrevations[key] === this.series.name )}: <b>${this.point.y} inches</b><br/>
      </span>`;
      return tooltip;
      }
    },
    series: [],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
        },
      ],
    },
  });

  useEffect(() => {
    if (chartData) {
      if (chartData.length !== 0) {
        const [dateCategory, seriesData] = splineChartWithDate(chartData);
        state.series = seriesData;
        Highcharts.chart("splineHorizontalChart", state);
      } else {
        state.series = [{showInLegend: false}];
        Highcharts.chart("splineHorizontalChart", state);
      }
    }
  }, [chartData]);

  const chartImage = (id) => {
    isDownloadImgLoader();
    setClickFlag(true)
    if (document.getElementById(`img${id}`)) {
      document.getElementById(`img${id}`).remove()
    }
    setIsChartImg(true)
    downloadChartPrintImg(id, setIsChartImg, setClickFlag, isNotDownloadImgLoader)
  }

  return (
    <>
      <div className="ground-chart">
        <div className="legends graph-select text-left">
          <div className="text-left d-inline">
            <h3 className="mb-0">H Break</h3>
          </div>
        </div>
        <div style={{ height: "444px" }} id="splineHorizontalChart" />
        {/* <button className="btn blue btn-view" onClick={() => chartImage("splineHorizontalChart")} disabled={clickFlag} >Download</button> */}
        <div class="chartdownload" onClick={() => chartImage("splineHorizontalChart")} disabled={clickFlag}><a class="btn white"><i class="fa fa-download" aria-hidden="true"></i></a></div>
      </div>

      {/* chart visual image download */}
      {isChartImg && <PrintImagePopup
        title="H Break"
        eventProfileData={eventProfileData}
        id="splineHorizontalChart"
        sendReq={sendReq}
        tabslist={tabslist}
        profilepage={profilepage} />}
    </>
  );
}

SplineHorizontalChart.propTypes = {};

export default SplineHorizontalChart;
