import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { imagesArr } from "../assets/images";
import SidebarListData from "../view/SidebarListData";

class Hittersummarysidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: "Hitter Summary",
      sourceID: document.location.href.split("/")[4] || "",
      sidebarList: [],
      teamInfo: {
        name: "",
        logo: ""
      }
    };
  }

  componentDidMount() {
    if (this.props.sideData) {
      let routList = [];
      routList.push({
        title: "Hitter Summary",
        path: "hittersummary",
        route: "hittersummary",
      });
      this.props.sideData.map((data, i) => {
        routList.push({
          title: data.full_name,
          path: data.full_name,
          route: data.full_name,
        });
      });
      this.setState((prevState) => ({
        ...prevState,
        sidebarList: routList,
      }));
    }

    if (localStorage.getItem("teamData") !== null) {
      this.setState({
        teamInfo: {
          name: JSON.parse(localStorage.getItem("teamData")).name,
          // logo: JSON.parse(localStorage.getItem("teamData")).hasOwnProperty('teamLogo') ? JSON.parse(localStorage.getItem("teamData")).teamLogo.filePath : ""
        }
      })
    }

    // for pitchriframe load
    Object.keys(this.props.appState).filter(key => key.includes("iframe_")).map(key => {
      this.props.appState[key] = false;
    });
  }

  changeSide = (keyname, index) => {
    this.props.changeside(
      keyname,
      keyname.split(" ").join("") === "hittersummary" ? true : false,
      index
    );
    this.props.history.push("/hittersummary/" + this.state.sourceID);
  };

  render() {
    const { teamInfo } = this.state
    return (
      <>
        {/* sidebar-wrapper */}
        <div className="sidebar">
          {/* <div className="sidebar-logo">
            <Link to="/">
              <img src={imagesArr.logo} alt="logo" />
            </Link>
            <p>{teamInfo.name}</p>
          </div> */}
          <ul className="list-group">
            {this.state.sidebarList.map((list, i) => {
              return (
                list.title !== "Session Average" && 
                <li
                  key={i}
                  className={`${this.props.keyName === list.route && "active"}`}
                >
                  <a
                    onClick={() => {
                      this.changeSide(list.route, --i);
                    }}
                  >
                    {list.title}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
        {/* sidebar-wrapper */}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    appState: state.app
  };
};

export default withRouter(connect(mapStateToProps)(Hittersummarysidebar));