import React from 'react'
import ABSpyayChart from './ABSpyayChart';
import HitterStrikeZoneChart from "../strikeZoneChart";
import AtBatTable from './AtBatTable';

function AtBat({ index, atBatStrikeChartData, atBatTableData, atBatSprayChartData, isPrinting, isPrint, type, eventName, pitcherDefault, profilepage, isNotDownloadImgLoader, isDownloadImgLoader }) {
  return (
    <div id={index} >
      <div id="tablePrintAreaAwayBatter">
        <AtBatTable tableData={atBatTableData[index]} index={index} />
      </div>
      <div className="container-fluid" id="AtBatChartPrintArea">
        <div className="common-charts">
          <div className={`row ${!(isPrinting || isPrint) ? 'singleDiv' : 'breakDiv'}`}>
            {atBatStrikeChartData.length !== 0 &&
              <div className="w-33">
                <HitterStrikeZoneChart
                  chartData={atBatStrikeChartData[index] || []}
                  id={`strikeZoneChartHitter${type + index || ""}`}
                  showTitle={false}
                  cameraAngle={["Strike Zone", "Catcher", "Home Top", "Home 3B Side", "Home 1B Side", "Press Box"]}
                  visualType="Both"
                  eventName={eventName}
                  pitcherDefault={pitcherDefault}
                  profilepage={profilepage}
                  isNotDownloadImgLoader={isNotDownloadImgLoader}
                  isDownloadImgLoader={isDownloadImgLoader}
                />
              </div>}
            <div className="w-33">
              <ABSpyayChart
                chartData={atBatSprayChartData[index] || []}
                index={index}
                type={type}
                cameraAngle={["Press Box", "Spray Chart", "First Base", "Third Base"]}
                visualType="Hitting"
                eventName={eventName}
                pitcherDefault={pitcherDefault}
                profilepage={profilepage}
                isNotDownloadImgLoader={isNotDownloadImgLoader}
                isDownloadImgLoader={isDownloadImgLoader}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AtBat
