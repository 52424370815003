import { constant } from "../../../shared/constant";

function PitcherTable({ tableData, type }) {
    return (
        <>
            {type === "pitcher" && tableData &&
                Object.keys(tableData).length !== 0 &&
                Object.keys(tableData).map((keyName, i) => {
                    return (
                        <div className="container-fluid" key={i}>
                            {type === "pitcher" && <h3>{keyName}</h3>}
                            <div className="pitcherssummary-table custom-left-fixed">
                                <div className="table-responsive-xl">
                                    <table className="table tabledashed">
                                        <thead>
                                            <tr>
                                                <th colSpan="2"></th>
                                                <th colSpan="2">Velocity</th>
                                                <th colSpan="3">Spin</th>
                                                <th colSpan="2">Spin Direction</th>
                                                <th colSpan="3">Break and Movement</th>
                                                <th colSpan="2">Approach Angle </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="tablerowgray fw-500">Pitch Type</td>
                                                <td className="tablerowgray fw-500">Count</td>
                                                <td className="tablerowgray fw-500">Avg</td>
                                                <td className="tablerowgray fw-500">Max</td>
                                                <td className="tablerowgray fw-500">Avg</td>
                                                <td className="tablerowgray fw-500">Max</td>
                                                <td className="tablerowgray fw-500">Efficiency</td>
                                                <td className="tablerowgray fw-500">Tilt</td>
                                                <td className="tablerowgray fw-500">Axis</td>
                                                <td className="tablerowgray fw-500">V</td>
                                                <td className="tablerowgray fw-500">Ind. V</td>
                                                <td className="tablerowgray fw-500">H</td>
                                                <td className="tablerowgray fw-500">Avg V</td>
                                                <td className="tablerowgray fw-500">Avg H</td>
                                            </tr>
                                            {tableData[keyName].length != 0 ?
                                                (
                                                    tableData[keyName].map((data, i) => {
                                                        return (
                                                            <tr>
                                                                <td className="table-rowwhite" style={{color: constant.pitchTypeColor[data.pitch_type]}}>{data.pitch_type ? data.pitch_type : "-"}</td>
                                                                <td className="table-rowwhite">{data.count ? data.count : "-"}</td>
                                                                <td className="table-rowwhite">{data.velocity_avg ? data.velocity_avg.toString().includes(".") ? data.velocity_avg.toFixed(1) : data.velocity_avg : "-"}</td>
                                                                <td className="table-rowwhite">{data.velocity_max ? data.velocity_max.toString().includes(".") ? data.velocity_max.toFixed(1) : data.velocity_max : "-"}</td>
                                                                <td className="table-rowwhite">{data.spin_avg ? Math.round(data.spin_avg) : "-"}</td>
                                                                <td className="table-rowwhite">{data.spin_max ? Math.round(data.spin_max) : "-"}</td>
                                                                <td className="table-rowwhite">{data.spin_efficiency ? Math.round(data.spin_efficiency) + '%' : "-"}</td>
                                                                <td className="table-rowwhite">{data.tilt ? data.tilt : "-"}</td>
                                                                <td className="table-rowwhite">{data.spin_axis ? Math.round(data.spin_axis) : "-"}</td>
                                                                <td className="table-rowwhite">{data.avg_v_break ? data.avg_v_break.toString().includes(".") ? data.avg_v_break.toFixed(1) : data.avg_v_break : "-"}</td>
                                                                <td className="table-rowwhite">{data.avg_ind_v_break ? data.avg_ind_v_break.toString().includes(".") ? data.avg_ind_v_break.toFixed(1) : data.avg_ind_v_break : "-"}</td>
                                                                <td className="table-rowwhite">{data.avg_h_break ? data.avg_h_break.toString().includes(".") ? data.avg_h_break.toFixed(1) : data.avg_h_break : "-"}</td>
                                                                <td className="table-rowwhite">{data.avg_vertical && data.avg_vertical ? Number(data.avg_vertical.toFixed(1)) : "0"}</td>
                                                                <td className="table-rowwhite">{data.avg_horizontal && data.avg_horizontal ? Number(data.avg_horizontal.toFixed(1)) : "0"}</td>
                                                            </tr>
                                                        )
                                                    }))

                                                : (
                                                    <tr>
                                                        <td className="table-rowwhite text-center" colSpan="14">There's no info yet!</td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
            {type === "spicerscarson" &&
                <section className="pb-0 pt-0 pl-0 pr-0" id="pitcherTablePrintArea">
                    <div className="container-fluid">
                        <div className="spierscarson-table custom-left-fixed">
                            <div className="table-responsive-xl">
                                <table className="table tabledashed">
                                    <thead>
                                        <tr>
                                            <th colSpan="2"></th>
                                            <th colSpan="2">Velocity</th>
                                            <th colSpan="3">Spin</th>
                                            <th colSpan="2">Spin Direction</th>
                                            <th colSpan="3">Break and Movement</th>
                                            <th colSpan="2">Approach Angle </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="tablerowgray fw-500">Pitch Type</td>
                                            <td className="tablerowgray fw-500">Count</td>
                                            <td className="tablerowgray fw-500">Avg</td>
                                            <td className="tablerowgray fw-500">Max</td>
                                            <td className="tablerowgray fw-500">Avg</td>
                                            <td className="tablerowgray fw-500">Max</td>
                                            <td className="tablerowgray fw-500">Efficiency</td>
                                            <td className="tablerowgray fw-500">Tilt</td>
                                            <td className="tablerowgray fw-500">Axis</td>
                                            <td className="tablerowgray fw-500">V</td>
                                            <td className="tablerowgray fw-500">Ind. V</td>
                                            <td className="tablerowgray fw-500">H</td>
                                            <td className="tablerowgray fw-500">Avg V</td>
                                            <td className="tablerowgray fw-500">Avg H</td>
                                        </tr>
                                        {tableData && tableData.length !== 0 ?
                                            tableData.map((data, i) => {
                                                return (
                                                    <tr>
                                                        <td className="table-rowwhite" style={{color: constant.pitchTypeColor[data.pitch_type]}}>{data.pitch_type ? data.pitch_type : "-"}</td>
                                                        <td className="table-rowwhite">{data.count ? data.count : "-"}</td>
                                                        <td className="table-rowwhite">{data.velocity_avg ? data.velocity_avg.toString().includes(".") ? data.velocity_avg.toFixed(1) : data.velocity_avg : "-"}</td>
                                                        <td className="table-rowwhite">{data.velocity_max ? data.velocity_max.toString().includes(".") ? data.velocity_max.toFixed(1) : data.velocity_max : "-"}</td>
                                                        <td className="table-rowwhite">{data.spin_avg ? Math.round(data.spin_avg) : "-"}</td>
                                                        <td className="table-rowwhite">{data.spin_max ? Math.round(data.spin_max) : "-"}</td>
                                                        <td className="table-rowwhite">{data.spin_efficiency ? Math.round(data.spin_efficiency) + '%' : "-"}</td>
                                                        <td className="table-rowwhite">{data.tilt ? data.tilt : "-"}</td>
                                                        <td className="table-rowwhite">{data.spin_axis ? Math.round(data.spin_axis) : "-"}</td>
                                                        <td className="table-rowwhite">{data.avg_v_break ? data.avg_v_break.toString().includes(".") ? data.avg_v_break.toFixed(1) : data.avg_v_break : "-"}</td>
                                                        <td className="table-rowwhite">{data.avg_ind_v_break ? data.avg_ind_v_break.toString().includes(".") ? data.avg_ind_v_break.toFixed(1) : data.avg_ind_v_break : "-"}</td>
                                                        <td className="table-rowwhite">{data.avg_h_break ? data.avg_h_break.toString().includes(".") ? data.avg_h_break.toFixed(1) : data.avg_h_break : "-"}</td>
                                                        <td className="table-rowwhite">{data.avg_vertical && data.avg_vertical ? Number(data.avg_vertical.toFixed(1)) : "0"}</td>
                                                        <td className="table-rowwhite">{data.avg_horizontal && data.avg_horizontal ? Number(data.avg_horizontal.toFixed(1)) : "0"}</td>
                                                    </tr>
                                                )
                                            })

                                            : (
                                                <tr>
                                                    <td className="table-rowwhite text-center" colSpan="14">There's no info yet!</td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            }
        </>
    )
}

export default PitcherTable;