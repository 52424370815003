import React, { useEffect, useRef, useState } from "react";
import Highcharts from "highcharts";
import { strikeZoneChartWithRedBox } from "../../../../shared/function";
import PropTypes from "prop-types";
import BallrWebGLPitch from "../../../../view/webGL/BallrWebGLPitch";
import PitchRWebGL from "../../../../view/webGL/PitchRWebGL";
import Toggle from "../../../../shared/Toggle";
import domtoimage from 'dom-to-image';
import PrintImagePopup from "../../../../shared/PrintImagePopup";
import { downloadChartPrintImg } from "../../../../shared/printReportUtils";
import html2canvas from "html2canvas";
import { useDispatch, useSelector } from "react-redux";
import use3DView from "../../../../shared/use3DView";
import { constant } from "../../../../shared/constant";
import ContourGraph from '../../commonChartSettings/ContourGraph';

function StrikeZoneChart({ chartData, id, chartKey, contourChartKey, contourData, contourId, chartName, onChangeEvent, ballrIframeFlag, closeBallrIframe, cameraAngle, visualType, pitchrIframeFlag, closePitchrIframe, eventProfileData, sendReq, tabslist, profilepage, isNotDownloadImgLoader, isDownloadImgLoader }) {
  const [isChartImg, setIsChartImg] = useState(false);
  const [clickFlag, setClickFlag] = useState(false);
  const [ballrFlag, setBallrFlag] = useState(false);
  const [tooltipData, setTooltipData] = useState(null);
  const [chartToggle,setChartToggle] = useState(null);
  const [chartStrike,setChartStrike] = useState(true);
  const [chartContour,setChartContour] = useState(false);
  const [breckM, setBreckM] = useState({
    chartHeight: 417,
    width: "100%",
  })
  const [data3D, setData3D, toggle, setToggle, onClickToggleButton, flag,onClickToggle] = use3DView(chartStrike ? id : contourId);
  const [state, setState] = useState({
    chart: {
      type: "scatter",
      borderWidth: 0,
      marginTop: 75,
      borderColor: "#D8D8D8",
      borderRadius: "8px",
    },
    title: {
      text: "",
    },
    xAxis: {
      title: {
        enabled: true,
        // text: "Side at Plate (ft)",
        text: "",
        style: {
          color: "#777F8F",
          fontFamily: "Poppins, sans-serif",
          fontSize: "14px",
          fontWeight: "500",
        },
      },
      min: -2.5,
      max: 2.5,
      tickInterval: 1
    },
    yAxis: {
      title: {
        enabled: true,
        // text: "Height at Plate (ft)",
        text: "",
        style: {
          color: "#777F8F",
          fontFamily: "Poppins, sans-serif",
          fontSize: "14px",
          fontWeight: "500",
        },
      },
      min: 0,
      max: 5,
    },
    legend: {
      layout: "horizontal",
      align: "center",
      verticalAlign: "top",
      y: 10,
    },
    credits: { enabled: false },
    plotOptions: {
      series: {
        stickyTracking: false,
        states: {
          inactive: {
            opacity: 1
          }
        }
      }
    },
    tooltip: {
        useHTML: true,
        hideDelay: 500,
        formatter: function () {
          setTooltipData(this?.point);
          // ballrFlag && refview.current.GiveKeyToiframe(this?.point);
          setBallrFlag(true)
          if (this.point.series.name === "Strikezone") return false;
          if (this.point.series.name === "Stadium") return false;
          let tooltip = `<span style="color: ${this.point.color
            }; font-weight:bold;">${this.point.options.pitch_type}</span><br>
          <span style="font-weight:bold;">Pitch No: ${this.point.options.pitch_number
              ? this.point.options.pitch_number
              : "-"
            }</span><br>
          <span style="font-weight:bold;">Pitcher: ${this.point.options.pitcher ? this.point.options.pitcher : "-"
            }</span><br>
          <span style="font-weight:bold;">Batter: ${this.point.options.batter ? this.point.options.batter : "-"
            }</span><br>
          <span style="font-weight:bold;">Play Result: ${this.point.options.pitch_call ? this.point.options.pitch_call : "-"
            }</span><br>
          <span style="font-weight:bold;">Velocity: ${this.point.options && this.point.options.velocity
              ? this.point.options.velocity.toFixed(1)
              : "-"
            }</span><br>
          <span style="font-weight:bold;">Spin Rate: ${this.point.options && this.point.options.spin_rate
              ? Math.round(this.point.options.spin_rate)
              : "-"
            }</span><br>
          <span style="font-weight:bold;">Tilt: ${this.point.options && this.point.options.tilt
              ? this.point.options.tilt
              : "-"
            }</span><br>
          <span style="font-weight:bold;">Axis: ${this.point.options && this.point.options.spin_axis
              ? Math.round(this.point.options.spin_axis)
              : "-"
            }</span><br>
          <span style="font-weight:bold;">Efficiency: ${this.point.options && this.point.options.efficiency
              ? Math.round(this.point.options.efficiency)
              : "-"
            }</span><br>
          <span style="font-weight:bold;">Vert Break: ${this.point.options && this.point.options.v_break
              ? this.point.options.v_break.toFixed(1)
              : "-"
            }</span><br>
          <span style="font-weight:bold;">Ind V Break: ${this.point.options && this.point.options.ind_v_break
              ? this.point.options.ind_v_break.toFixed(1)
              : "-"
            }</span><br>
          <span style="font-weight:bold;">H Break: ${this.point.options && this.point.options.h_break
              ? this.point.options.h_break.toFixed(1)
              : "-"
            }</span><br>
            <span style="font-weight:bold;">V Approach: ${this.point.options && this.point.options.vert_appr_angle
              ? this.point.options.vert_appr_angle.toFixed(1)
              : "-"
            }</span><br>
          <span style="font-weight:bold;">Rel Height: ${this.point.options && this.point.options.release_height
              ? this.point.options.release_height.toFixed(1)
              : "-"
            }</span><br>
          <span style="font-weight:bold;">Rel Side: ${this.point.options && this.point.options.release_side
              ? this.point.options.release_side.toFixed(1)
              : "-"
            }</span><br>
          <span style="font-weight:bold;">Extension: ${this.point.options && this.point.options.extension
              ? this.point.options.extension.toFixed(1)
              : "-"
            }</span><br>
          <span style="font-weight:bold;">Exit Velocity: ${this.point.options && this.point.options.exit_velocity
              ? this.point.options.exit_velocity.toFixed(1)
              : "-"
            }</span><br>
          <span style="font-weight:bold;">Launch Angle: ${this.point.options && this.point.options.launch_angle
              ? this.point.options.launch_angle.toFixed(1)
              : "-"
            }</span><br>
          <span style="font-weight:bold;">Distance: ${this.point.options && this.point.options.distance
              ? Math.round(this.point.options.distance)
              : "-"
            }</span><br>
            <span style="font-weight:bold;">yt_seam_lat: ${this.point.options && this.point.options.yt_seam_lat
              ? this.point.options.yt_seam_lat.toFixed(1)
              : "-"
            }</span><br>
            <span style="font-weight:bold;">yt_seam_long: ${this.point.options && this.point.options.yt_seam_long
              ? this.point.options.yt_seam_long.toFixed(1)
              : "-"
            }</span>
          <!-- <div style="color: red" onmouseover="StrikeLoadBallR()">BallR</div> -->
          </span>`;
          return tooltip;
        },
        style: {
          pointerEvents: 'auto'
        }
      },
    series: [],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
        },
      ],
    },
  });

  useEffect(() => {
    if(chartStrike===true){
      if (toggle.chartToggle === true) {
        if (chartData) {
          // developmentTab !== true && increment();
          if (chartData.length !== 0) {
            let chartArr = [];
            const seriesData = strikeZoneChartWithRedBox(chartData,"","",chartName,"pitcherStrikeZone");
            if(!!seriesData.find((e)=>e.name === chartKey)){
              chartArr.push(seriesData.find((e) => e.name === chartKey))
            }
            chartArr.push(seriesData.find((e)=>e.name==='Strikezone'))
            chartArr.push(seriesData.find((e)=>e.name==='Stadium'))
            state.series = chartArr;
            if (state.series) {
              state.series[state.series.length - 1].showInLegend = false
              // state.series.filter(x => x.name === "").map((item) => {
              //   Object.assign(item, { showInLegend: false })
              // })
            }
            Highcharts.chart(id, state);
          } else {
            state.series = [{ showInLegend: false }];
            Highcharts.chart(id, state);
          }
        }
      } else {
        if (chartData && chartData.length !== 0) {
          chartData = chartData.filter((e)=>e.pitch_type === contourChartKey)
          chartData =  chartName !== "all_pitches" ?
          (chartName === "gb" || chartName === "ld" || chartName === "fb" || chartName === "pu") ? chartData.filter((record) => record.hit_angle_type === chartName) :
            chartData.filter((record) => record[chartName] === true) : chartData 
          let arr = chartData.map(x => x?._id) || []
          setData3D(arr)
          
        }
      }
    }else if(chartContour===true){
      if (toggle.chartToggle === true) {
        if (contourData) {
          // start remove child of svg chart if it's availavble
          var div = document.getElementById(contourId);
          var elements = div.getElementsByTagName('svg');
          while (elements[0])
            elements[0].parentNode.removeChild(elements[0])
          // end
          const chartWidth =
            typeof breckM.width === 'string'
              ? document.getElementById(contourId).offsetWidth
              : breckM.width;
          const xAxisDomain = [-2.5, 2.5];
          const xAxisTicksRequired = 5;
          const yAxisTicksRequired = 5;
          const yAxisDomain = [0, 5];
          const colorRange = ['#93b3ed', 'white', 'red'];
          const boxDimentions = constant.softball
            ? [
              [-0.9, 1.25],
              [-0.9, 3],
              [0.9, 3],
              [0.9, 1.25],
              [-0.9, 1.25],
            ]
            : [
              [-0.9, 1.5],
              [-0.9, 3.5],
              [0.9, 3.5],
              [0.9, 1.5],
              [-0.9, 1.5],
            ];
  
          if (contourData.length !== 0) {
            const squareBox = boxDimentions;
            const pentagonBox = [
              [-0.9, 0.05],
              [-0.9, 0.4],
              [0, 0.8],
              [0.9, 0.4],
              [0.9, 0.05],
              [-0.9, 0.05],
            ]
            
            contourData = contourData.filter((item)=>item.pitch_type === contourChartKey)
            contourData = chartName !== "all_pitches" ?
            (chartName === "gb" || chartName === "ld" || chartName === "fb" || chartName === "pu") ? contourData.filter((record) => record.hit_angle_type === chartName) :
            contourData.filter((record) => record[chartName] === true) : contourData 

            const contourGrapghDataXformer = contourData.map(obj => ({
              y: obj.y,
              x: obj.x,
            }));

            ContourGraph(
              `#${contourId}`,
              contourGrapghDataXformer,
              chartWidth,
              breckM.chartHeight,
              xAxisDomain,
              yAxisDomain,
              squareBox,
              pentagonBox,
              colorRange,
              xAxisTicksRequired,
              yAxisTicksRequired,
            );
          } else {
            ContourGraph(
              `#${contourId}`,
              [],
              chartWidth,
              breckM.chartHeight,
              xAxisDomain,
              yAxisDomain,
              [],
              colorRange,
              xAxisTicksRequired,
              yAxisTicksRequired,
            );
          }
        }
      } else {
        if (contourData && contourData.length !== 0) {
          contourData = contourData.filter((e) => e.pitch_type === contourChartKey)
          contourData = chartName !== "all_pitches" ?
            (chartName === "gb" || chartName === "ld" || chartName === "fb" || chartName === "pu") ? contourData.filter((record) => record.hit_angle_type === chartName) :
              contourData.filter((record) => record[chartName] === true) : contourData
          let arr = contourData.map(x => x?._id) || []
          setData3D(arr)
        
        }
      }
    }
    
  }, [chartData, toggle,chartName,contourData]);

  // bullpen event player name change,set ballriframe initial state 
  useEffect(() => {
    if (ballrIframeFlag === "yes") {
      setBallrFlag(false)
      setTooltipData(null);
      closeBallrIframe();
    }
  }, [ballrIframeFlag])

  // bullpen event player name change,set pichRIframe initial state
  useEffect(() => {
    if (pitchrIframeFlag === "yes") {
      setData3D(null)
      setToggle({ chartToggle: true });
      closePitchrIframe();
    }
  }, [pitchrIframeFlag])

  const chartImage = (id) => {
    isDownloadImgLoader();
    setClickFlag(true)
    if (document.getElementById(`img${id}`)) {
      document.getElementById(`img${id}`).remove()
    }
    setIsChartImg(true)
    downloadChartPrintImg(id, setIsChartImg, setClickFlag, isNotDownloadImgLoader)
  }

  const onClickToggleChart = (chart) => {
    if(chart==="Pitches"){
      setChartStrike(true)
      setChartContour(false)
      setBallrFlag(false)
      setTooltipData(null);
      setToggle({ chartToggle: true });
    }else if(chart==="Contour"){
      setChartStrike(false)
      setChartContour(true)
      setBallrFlag(false)
      setTooltipData(null);
      setToggle({ chartToggle: true });
    }
  }
  
  return (
    <>
      <div className={`ground-chart individual ${ballrFlag ? "chart-ifream" : ""}`}>
       
            <div className="legends graph-select text-left pitch-outcomes">
          {
            toggle.chartToggle === true ?
              <div className="select-box">
                <select
                  name={constant.softball ? id === "strikeZone_FB" ? "strikeZoneS_fb" : id === "strikeZone_DB" ? "strikeZoneS_db" : id === "strikeZone_RB" ? "strikeZoneS_rb" :
                    id === "strikeZone_CB" ? "strikeZoneS_cb" : id === "strikeZone_SB" ? "strikeZoneS_sb" : id === "strikeZone_CH" ? "strikeZoneS_ch" : "strikeZoneS_dc"
                    : id === "strikeZone_FB" ? "strikeZoneB_fb" : id === "strikeZone_SI" ? "strikeZoneB_si" : id === "strikeZone_CT" ? "strikeZoneB_cu" : id === "strikeZone_CB" ? "strikeZoneB_cb" :
                      id === "strikeZone_SL" ? "strikeZoneB_sl" : id === "strikeZone_CH" ? "strikeZoneB_ch" : id === "strikeZone_SP" ? "strikeZoneB_sp" : "strikeZoneB_kb"}
                  value={chartName} className="custom-select" onChange={onChangeEvent}>
                  <option value="all_pitches">All Pitches</option>
                  <option value="swing">Swings</option>
                  <option value="whiff">Whiffs</option>
                  <option value="balls_in_play">Balls In Play</option>
                  <option value="hits">Hits</option>
                  <option value="hard_hit">Hard Hit</option>
                  <option value="damage">Damage</option>
                  <option value="gb">GB</option>
                  <option value="ld">LD</option>
                  <option value="fb">FB</option>
                  <option value="pu">PU</option>
                </select>
              </div>
              : ""
          }
          <Toggle
            right={window.innerWidth < 575 ? "5px" : toggle.chartToggle === true ? "40px" : "17px"}
            top={window.innerWidth < 575 ? toggle.chartToggle === true ? "27px" : "10px" : "10px"}
            toggle={toggle}
            onClickToggleButton={onClickToggleButton}
            toggleStrikeZone={true}
            onClickToggleChart={onClickToggleChart}
            chartStrike={chartStrike}
            chartContour={chartContour}
            onClickToggle={onClickToggle}
          />
        </div>


       

        {/* {chartData && chartData.length !== 0 ?
          <Toggle
            right={window.innerWidth < 575 ? "5px" : toggle.chartToggle === true ? "40px" : "17px"}
            top={window.innerWidth < 575 ? toggle.chartToggle === true ? "27px" : "10px" : "10px"}
            toggle={toggle}
            onClickToggleButton={onClickToggleButton} />
          : ""} */}
              <div style={{ display: toggle.chartToggle === true ? 'block' : 'none' }}>
                  {chartStrike &&
                      <>
                          <div className="ifream-box">
                              {ballrFlag && <BallrWebGLPitch tooltipData={tooltipData} id={id} index={tooltipData?._id} />}
                          </div>
                          <div style={{ width: "400px", margin: "0 auto" }} id={id} />
                      </>
                  }
                  {chartContour && <div style={{ height: "425px", margin: "0 auto" }} id={contourId} />}
         
          {/* <button className="btn blue btn-view" onClick={() => chartImage("strikeZoneChart")} disabled={clickFlag} >Download</button> */}
          <div class="chartdownload" onClick={() => chartImage(chartStrike ? id : contourId)} disabled={clickFlag}><a class="btn white"><i class="fa fa-download" aria-hidden="true"></i></a></div>
        </div>
        {flag?.[`iframe_${chartStrike ? id : contourId}`] === true && <div id="pitchR-iframe" className="Chart-iframe" style={{ display: toggle.chartToggle === false ? 'block' : 'none' }}>
          <PitchRWebGL data={data3D} id={chartStrike ? id : contourId} cameraAngle={cameraAngle} visualType={visualType} Toggle={toggle.chartToggle} />
        </div>}
      </div>

      {/* chart visual image download */}
      {isChartImg && <PrintImagePopup
        eventProfileData={eventProfileData}
        id={chartStrike ? id : contourId}
        sendReq={sendReq}
        tabslist={tabslist}
        profilepage={profilepage} />}
    </>
  );
}

StrikeZoneChart.propTypes = {};

export default StrikeZoneChart;
