import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import PrintImagePopup from "../../shared/PrintImagePopup";
import { downloadChartPrintImg } from "../../shared/printReportUtils";
import { pitchersCallChartData } from "../../shared/function";

function CalledPitchesChart({ chartData, id,  eventProfileData, sendReq, tabslist, profilepage, isNotDownloadImgLoader, isDownloadImgLoader }) {
  const [isChartImg, setIsChartImg] = useState(false);
  const [clickFlag, setClickFlag] = useState(false);
  const [state, setState] = useState({
    chart: {
      type: "scatter",
      marginTop: 65,
    },
    title: {
      text: ""
    },
    xAxis: {
      title: {
        enabled: true,
        text: "",
        style: {
          color: "#777F8F",
          fontFamily: "Poppins, sans-serif",
          fontSize: "14px",
          fontWeight: "500",
        },
      },
      min: -2.5,
      max: 2.5,
      tickInterval: 1,
      allowDecimals: false,
    },
    yAxis: {
      title: {
        enabled: true,
        text: "",
        style: {
          color: "#777F8F",
          fontFamily: "Poppins, sans-serif",
          fontSize: "14px",
          fontWeight: "500",
        },
      },
      min: 0,
      max: 5
    },
    legend: {
      layout: "horizontal",
      align: "top",
      verticalAlign: "top",
      reversed: true,
    },
    credits: { enabled: false },
    plotOptions: {
      series: {
        turboThreshold: 2000,
        stickyTracking: false,
        states: {
          inactive: {
            opacity: 1
          }
        }
      }
    },
    tooltip: {
      formatter: function () {
        if (this.point.series.name === "Strikezone") return false;
        if (this.point.series.name === "Stadium") return false;
        let tooltip = `<span style="color: ${this.point.color}; font-weight:bold;">${this.point.options.pitch_call}</span><br>
            <span>Batter: ${this.point.options.batter ? this.point.options.batter : '-'}</span><br>
            <span>Pitcher: ${this.point.options.pitcher ? this.point.options.pitcher : '-'}</span><br>
            <span>Pitch Type: ${this.point.options.pitch_type ? this.point.options.pitch_type : '-'}</span><br>
            <span>Play Result: ${this.point.options.pitch_call ? this.point.options.pitch_call : '-'}</span><br>
            <span>Velocity: ${this.point.options &&
          this.point.options.velocity &&
          this.point.options.velocity.toFixed(1)
          }</span><br>
            <span>Ind V Break: ${this.point.options &&
          this.point.options.ind_v_break &&
          this.point.options.ind_v_break.toFixed(1)
          }</span><br>
            <span>H Break: ${this.point.options &&
          this.point.options.h_break &&
          this.point.options.h_break.toFixed(1)
          }</span><br>
            <span>V Approach: ${this.point.options &&
          this.point.options.vert_appr_angle &&
          this.point.options.vert_appr_angle.toFixed(1)
          }</span><br>
            <span>H Approach: ${this.point.options &&
          this.point.options.horz_appr_angle &&
          this.point.options.horz_appr_angle.toFixed(1)
          }</span>
          </span>`;
        return tooltip;
      },
    },
    series: [],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
        },
      ],
    },
  });

  useEffect(() => {
    if (chartData) {
      if (chartData.length !== 0) {
        const seriesData = pitchersCallChartData(chartData,"profile")
        state.series = seriesData;
        Highcharts.chart(id, state);
      } else {
        state.series = [{showInLegend: false}];
        Highcharts.chart(id, state);
      }
    }
  }, [chartData]);

  const chartImage = (id) => {
    isDownloadImgLoader();
    setClickFlag(true)
    if (document.getElementById(`img${id}`)) {
      document.getElementById(`img${id}`).remove()
    }
    setIsChartImg(true)
    downloadChartPrintImg(id, setIsChartImg, setClickFlag, isNotDownloadImgLoader)
  }

  return (
    <>
      <div className="ground-chart">
        <div className="legends graph-select text-left">
          <div className="text-left d-inline">
            <h3 className="mb-0">Called Pitches</h3>
          </div>
        </div>
        <div style={{ height: "444px" }} id={id} />
        <div class="chartdownload" onClick={() => chartImage(id)} disabled={clickFlag}><a class="btn white"><i class="fa fa-download" aria-hidden="true"></i></a></div>
      </div>

      {/* chart visual image download */}
      {isChartImg && <PrintImagePopup
        eventProfileData={eventProfileData}
        title="Called Pitches"
        id={id}
        sendReq={sendReq}
        tabslist={tabslist}
        profilepage={profilepage} />}
    </>
  );
}

export default CalledPitchesChart;
