import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getDevelopmentSummary } from "../../../../store/actions";
import ReleasePointChart from "./chartComponent/releasePointChart";
import SprayChart from "./chartComponent/sprayChart";
import HitterStrikeZoneChart from "./chartComponent/hitterstrikeZoneChart";
import PitcherStrikeZoneChart from "./chartComponent/pitcherstrikeZoneChart";
import HitterHeatMap from "./chartComponent/hitterHeatMap";
import PitcherHeatMap from "./chartComponent/pitcherHeatMap";
import SprayAgainstChart from "./chartComponent/sprayAgainstChart";
import Select from 'react-select';

function Chart({ developA, developB }) {  
  const dispatch = useDispatch();
  const [chartOption, setChartOption] = useState([])
  const [groupA, setGroupA] = useState({
    pitcherZoneChart : [],
    hitterZoneChart : [],
    sprayChart : [],
    sprayTable : [],
    releaseChart : [],
    releaseTable : [],
    hitterHeatMapChart : [],
    pitcherHeatMapChart : [],
    sprayAgainstChart : []
  })
  const [groupB, setGroupB] = useState({
    pitcherZoneChart : [],
    hitterZoneChart : [],
    sprayChart : [],
    sprayTable : [],
    releaseChart : [],
    releaseTable : [],
    hitterHeatMapChart : [],
    pitcherHeatMapChart : [],
    sprayAgainstChart : []
  })
  const [chart, setChart] = useState();

  useEffect(() => {
    if (developA) {
      chartSummary("groupA", developA)
    }
    if (developB) {
      chartSummary("groupB", developB)
    }
  }, [developA, developB])

  useEffect(() => {
    let arr = [];
    if (groupA.hitterZoneChart.length != 0 || groupB.hitterZoneChart.length != 0) {
      arr.push({ value: 'hitter_zone', label: 'Hitter Strike Zone' })
    } 
    if (groupA.pitcherZoneChart.length != 0 || groupB.pitcherZoneChart.length != 0) {
      arr.push({ value: 'pitcher_zone', label: 'Pitcher Strike Zone' })
    } 
    if (groupA.sprayChart.length != 0 || groupB.sprayChart.length != 0) {
      arr.push({ value: 'spray_chart', label: 'Spray Chart' })
    } 
    if (groupA.releaseChart.length != 0 || groupB.releaseChart.length != 0) {
      arr.push({ value: 'dispersion', label: 'Dispersion' })
    } 
    if (groupA.hitterHeatMapChart.length != 0 || groupB.hitterHeatMapChart.length != 0) {
      arr.push({ value: 'hitter_heatmap', label: 'Hitter HeatMap' })
    } 
    if (groupA.pitcherHeatMapChart.length != 0 || groupB.pitcherHeatMapChart.length != 0) {
      arr.push({ value: 'pitcher_heatmap', label: 'Pitcher HeatMap' })
    } 
    if (groupA.sprayAgainstChart.length != 0 || groupB.sprayAgainstChart.length != 0) {
      arr.push({ value: 'spray_chart_against', label: 'Spray Chart Against' })
    }
    if (chart == "hitter_zone" || chart == "pitcher_zone" || chart == "spray_chart" || chart == "dispersion" || chart == "spray_chart_against" || !chart) {
      setChart(arr.length !== 0 ? arr[0].value : ""); 
    }
    setChartOption(arr);
  }, [groupA,groupB])
  
  const handleChange = (e,req,type) => {
    if (req === "developA") {
      developA[type]=e.value
      chartSummary("groupA", developA)
    }
    if (req === "developB") {
      developB[type]=e.value
      chartSummary("groupB", developB)
    }
  };
  
  const changeChart = (e) => {
    setChart(e.value);
  };

  const chartSummary = (group, req) => {
    if (group === "groupA") {
      dispatch(getDevelopmentSummary(req, (res) => {
        setGroupA({
          pitcherZoneChart: res.data.result.pitcher_zone_chart_rows.length != 0 ? res.data.result.pitcher_zone_chart_rows : [],
          hitterZoneChart: res.data.result.hitting_zone_chart_rows.length != 0 ? res.data.result.hitting_zone_chart_rows : [],
          sprayChart: res.data.result.hitting_summary_table_rows.length != 0 ? res.data.result.hitting_summary_table_rows : [],
          sprayTable: res.data.result.spray_chart_table_rows.length != 0 ? res.data.result.spray_chart_table_rows : [],
          releaseChart: res.data.result.release_point_chart_rows.length != 0 ? res.data.result.release_point_chart_rows : [],
          releaseTable: res.data.result.release_point_avg_dists.length != 0 ? res.data.result.release_point_avg_dists : [],
          hitterHeatMapChart: res.data.result.hitter_heatmaps_chart_rows.length != 0 ? res.data.result.hitter_heatmaps_chart_rows : [],
          pitcherHeatMapChart: res.data.result.pitcher_heatmaps_chart_rows.length != 0 ? res.data.result.pitcher_heatmaps_chart_rows : [],
          sprayAgainstChart: res.data.result.spray_against_chart_rows.length != 0 ? res.data.result.spray_against_chart_rows : [],
        })
      }))

    }
    if (group === "groupB") {
      dispatch(getDevelopmentSummary(req, (res) => {
        setGroupB({
          pitcherZoneChart: res.data.result.pitcher_zone_chart_rows.length != 0 ? res.data.result.pitcher_zone_chart_rows : [],
          hitterZoneChart: res.data.result.hitting_zone_chart_rows.length != 0 ? res.data.result.hitting_zone_chart_rows : [],
          sprayChart: res.data.result.hitting_summary_table_rows.length != 0 ? res.data.result.hitting_summary_table_rows : [],
          sprayTable: res.data.result.spray_chart_table_rows.length != 0 ? res.data.result.spray_chart_table_rows : [],
          releaseChart: res.data.result.release_point_chart_rows.length != 0 ? res.data.result.release_point_chart_rows : [],
          releaseTable: res.data.result.release_point_avg_dists.length != 0 ? res.data.result.release_point_avg_dists : [],
          hitterHeatMapChart: res.data.result.hitter_heatmaps_chart_rows.length != 0 ? res.data.result.hitter_heatmaps_chart_rows : [],
          pitcherHeatMapChart: res.data.result.pitcher_heatmaps_chart_rows.length != 0 ? res.data.result.pitcher_heatmaps_chart_rows : [],
          sprayAgainstChart: res.data.result.spray_against_chart_rows.length != 0 ? res.data.result.spray_against_chart_rows : [],
        })
      }))
    }
  }
  
  return (
    <>
    {chartOption.length !==0  ? <div className="common-charts">
      <div className="chart-box">
        <div className="chart-select">
          <h3>Select</h3>
          <Select
            className="customize-select"
            classNamePrefix="customize-select"
            options={chartOption}
            autosizes
            searchable={false}
            clearable={false}
            value={chartOption.find(obj => obj.value === chart)}
            simpleValue
            onChange={(e)=>changeChart(e)}
          />
        </div>
       
        {chartOption.length !==0 && chart === "hitter_zone" && (groupA.hitterZoneChart.length !== 0 || groupB.hitterZoneChart.length !== 0) && <HitterStrikeZoneChart zoneChartA={groupA.hitterZoneChart} zoneChartB={groupB.hitterZoneChart}/>}
        {chartOption.length !==0 && chart === "pitcher_zone" &&(groupA.pitcherZoneChart.length !== 0 || groupB.pitcherZoneChart.length !== 0) && <PitcherStrikeZoneChart zoneChartA={groupA.pitcherZoneChart} zoneChartB={groupB.pitcherZoneChart}/>}
        {chartOption.length !==0 && chart === "spray_chart" &&(groupA.sprayChart.length !== 0 || groupB.sprayChart.length !== 0) && <SprayChart zoneChartA={groupA.sprayChart} zoneChartB={groupB.sprayChart}  zoneTableA={groupA.sprayTable} zoneTableB={groupB.sprayTable} />}
        {chartOption.length !==0 && chart === "dispersion" &&(groupA.releaseChart.length !== 0 || groupB.releaseChart.length !== 0) && <ReleasePointChart releaseChartA={groupA.releaseChart} releaseChartB={groupB.releaseChart}  releaseTableA={groupA.releaseTable} releaseTableB={groupB.releaseTable} />}
        {chartOption.length !==0 && chart === "hitter_heatmap" &&<HitterHeatMap heatMapA={groupA.hitterHeatMapChart} heatMapB={groupB.hitterHeatMapChart} reqA={developA} reqB={developB} onChange={handleChange}/>}
        {chartOption.length !==0 && chart === "pitcher_heatmap" &&<PitcherHeatMap heatMapA={groupA.pitcherHeatMapChart} heatMapB={groupB.pitcherHeatMapChart} reqA={developA} reqB={developB} onChange={handleChange}/>}
        {chartOption.length !==0 && chart === "spray_chart_against" &&(groupA.sprayAgainstChart.length !== 0 || groupB.sprayAgainstChart.length !== 0) && <SprayAgainstChart zoneChartA={groupA.sprayAgainstChart} zoneChartB={groupB.sprayAgainstChart}/>}
      </div>
    </div> 
    : <div className="tabviewbtn-nodeta" >There's no info yet!</div>}
    </>
  );
}

export default Chart;