import { useEffect, useState } from "react";
import {
  battersPitchTypeChartData,
  releaseTableFormate,
} from "../../../../../shared/function";
import Highcharts from "highcharts";
import ReleasePointTable from "./releasePointTable";
import { NoGraph } from "../../noInfo";
import ReleaseChart from "../../../../../components/Charts/releasePointChart";


function ReleasePointChart({
  releaseChartA,
  releaseChartB,
  releaseTableA,
  releaseTableB,
}) {
  const [table, setTable] = useState({});
  const [s, setS] = useState({ chartToggle: true });

  // let options = {
  //   chart: {
  //     type: "scatter",
  //     marginTop: 100,
  //     borderWidth: 1,
  //     borderColor: '#D8D8D8',
  //     borderRadius: '8px',
  //   },
  //   title: {
  //     text: "",
  //     layout: "horizontal",
  //     align: "center",
  //     verticalAlign: "left",
  //     y: 5,
  //     x: 10,
  //     style: {
  //       color: "#1B1B1B",
  //       fontFamily: "Poppins, sans-serif",
  //       fontSize: "18px",
  //       fontWeight: "bold",
  //     },
  //   },
  //   xAxis: {
  //     title: {
  //       text: "Release Side",
  //       style: {
  //         color: "#777F8F",
  //         fontFamily: "Poppins, sans-serif",
  //         fontSize: "14px",
  //         fontWeight: "500",
  //       },
  //     },
  //     startOnTick: true,
  //     endOnTick: true,
  //     showLastLabel: true,
  //     min: -4,
  //     max: 4,
  //   },
  //   yAxis: {
  //     title: {
  //       text: "Release Height",
  //       style: {
  //         color: "#777F8F",
  //         fontFamily: "Poppins, sans-serif",
  //         fontSize: "14px",
  //         fontWeight: "500",
  //       },
  //     },
  //     min: 0,
  //     max: 8,
  //   },
  //   legend: {
  //     layout: "horizontal",
  //     align: "bottom",
  //     verticalAlign: "top",
  //     y: 35,
  //   },
  //   credits: { enabled: false },
  //   plotOptions: {
  //     series: {
  //       stickyTracking: false,
  //       states: {
  //         inactive: {
  //           opacity: 1
  //         }
  //       }
  //     }
  //   },
  //   tooltip: {
  //     formatter: function () {
  //       let tooltip = `<span style="color: ${this.point.color}; font-weight:bold;">${this.point.options.pitch_type
  //         }</span><br>
  //             <span>Batter: ${this.point.options.batter ? this.point.options.batter : "-"
  //         }</span><br>
  //             <span>Pitcher: ${this.point.options.pitcher ? this.point.options.pitcher : "-"
  //         }</span><br>
  //             <span>Pitch Number: ${this.point.options.pitch_number
  //           ? this.point.options.pitch_number
  //           : "-"
  //         }</span><br>
  //             <span>Velocity: ${this.point.options && this.point.options.velocity
  //           ? this.point.options.velocity.toFixed(1)
  //           : "-"
  //         }</span><br>
  //             <span>Spin Rate: ${this.point.options && this.point.options.spin_rate
  //           ? Math.round(this.point.options.spin_rate)
  //           : "-"
  //         }</span><br>
  //             <span>Ind V Break: ${this.point.options && this.point.options.ind_v_break
  //           ? this.point.options.ind_v_break.toFixed(1)
  //           : "-"
  //         }</span><br>
  //             <span>H Break: ${this.point.options && this.point.options.h_break
  //           ? this.point.options.h_break.toFixed(1)
  //           : "-"
  //         }</span><br>
  //             <span>Vert Appr Angle: ${this.point.options && this.point.options.vert_appr_angle
  //           ? this.point.options.vert_appr_angle.toFixed(1)
  //           : "-"
  //         }</span><br>
  //             <span>Release Height: ${this.point.options && this.point.options.release_height
  //           ? this.point.options.release_height.toFixed(1)
  //           : "-"
  //         }</span><br>
  //             <span>Release Side: ${this.point.options && this.point.options.release_side
  //           ? this.point.options.release_side.toFixed(1)
  //           : "-"
  //         }</span><br>
  //             <span>Play Result: ${this.point.options.pitch_call
  //           ? this.point.options.pitch_call
  //           : "-"
  //         }</span>
  //           </span>`;
  //       return tooltip;
  //     },
  //   },
  //   series: [],
  //   responsive: {
  //     rules: [
  //       {
  //         condition: {
  //           maxWidth: 500,
  //         },
  //       },
  //     ],
  //   },
  // };

  // useEffect(() => {
  //   if (releaseChartA.length > 0) {
  //     const seriesData = battersPitchTypeChartData(releaseChartA);
  //     options.series = seriesData;
  //     Highcharts.chart("releasePointChart", options);
  //   } else {
  //     options.series = [{}];
  //     Highcharts.chart("releasePointChart", options);
  //   }
  //   if (releaseChartB.length > 0) {
  //     const seriesData1 = battersPitchTypeChartData(releaseChartB);
  //     options.series = seriesData1;
  //     Highcharts.chart("releasePointChart1", options);
  //   } else {
  //     options.series = [{}];
  //     Highcharts.chart("releasePointChart1", options);
  //   }
  // }, [releaseChartA, releaseChartB]);

  const onClickToggleButton = () => {
    setS({
      chartToggle: !s.chartToggle
    });
  };

  useEffect(() => {
    setTable(releaseTableFormate(releaseTableA, releaseTableB));
  }, [releaseTableA, releaseTableB]);

  return (
    <>
      {Object.keys(table).length > 0 && (
        <>
          <div className="dispersion-info">
            <h3>Dispersion</h3>
            <p>
              {`What does this mean? Release point dispersion is a measure of
                            how closely concentrated a pitcher's release points are. A
                            higher value represents less consistency, whereas a value of 0
                            shows all the pitches were released at the same point.`}
            </p>
            <p>
              A sample of MLB data showed that pitchers at that level have a
              dispersion of approximately .2, but this varies greatly by the
              type of pitcher.
            </p>
          </div>
          <ReleasePointTable data={table} />{" "}
        </>
      )}
      {window.innerWidth < 1366 ? (
        <div className="row">
          {s.chartToggle === true ? (
            <div className="w-38">
              <div className="group-select-box">
                <h4>Group A</h4>
                <h3 className="chart-name-title">Release Point</h3>
                <p className="chart-name-disc">Pitcher View</p>
                <ReleaseChart
                  chartData={releaseChartA}
                  id="releasePointChart"
                  showTitle={false}
                  developmentTab={true}
                />
                {/* <div style={{ height: "500px" }} id="releasePointChart" /> */}
              </div>
            </div>
          ) : (
            <div className="w-38">
              <div className="group-select-box">
                <h4>Group B</h4>
                <h3 className="chart-name-title">Release Point</h3>
                <p className="chart-name-disc">Pitcher View</p>
                <ReleaseChart
                  chartData={releaseChartB}
                  id="releasePointChart1"
                  showTitle={false}
                  developmentTab={true}
                />
                {/* <div style={{ height: "500px" }} id="releasePointChart1" /> */}
              </div>
            </div>
          )}
          <div className="toggle-switch">
            <label className="switch">
              <input
                type="checkbox"
                id="togBtn"
                checked={s.chartToggle}
                onChange={onClickToggleButton}
              />
              <div className="slider round">
                <span className="on">Group A</span>
                <span className="off">Group B</span>
              </div>
            </label>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="w-38">
            <div className="group-select-box">
              <h4>Group A</h4>
              <h3 className="chart-name-title">Release Point</h3>
              <p className="chart-name-disc">Pitcher View</p>
              <ReleaseChart
                chartData={releaseChartA}
                id="releasePointChart"
                showTitle={false}
                developmentTab={true}
              />
              {/* <div style={{ height: "500px" }} id="releasePointChart" /> */}
            </div>
          </div>
          <div className="w-38">
            <div className="group-select-box">
              <h4>Group B</h4>
              <h3 className="chart-name-title">Release Point</h3>
              <p className="chart-name-disc">Pitcher View</p>
              <ReleaseChart
                chartData={releaseChartB}
                id="releasePointChart1"
                showTitle={false}
                developmentTab={true}
              />
              {/* <div style={{ height: "500px" }} id="releasePointChart1" /> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default ReleasePointChart;
