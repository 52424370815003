import React, { useState, useEffect } from 'react';
import CatcherView from '../../../components/Charts/ViewChart';
import TopView from '../../../components/Charts/ViewChart';
import SideView from '../../../components/Charts/ViewChart';
import PointOfContactTable from '../../../components/CommanTable/ContactTabTable';
import PullTable from '../../../components/CommanTable/ContactTabTable';
import MiddleTable from '../../../components/CommanTable/ContactTabTable';
import OppoTable from '../../../components/CommanTable/ContactTabTable';
import { constant } from '../../../shared/constant';
import { getSprayChartRangeColor } from '../../../shared/function';
import DotRangeSpyayChart from '../../hittersummary/component/hitterDotRangeSpyayChart';
import EVSpyayChart from '../../hittersummary/component/hitterSpyayChart';
import SpyayChart from './spyayChart';

function ContactTab({ filterRes, isPrinting, eventProfileData, sendReq, tabslist, isNotDownloadImgLoader, isDownloadImgLoader, pitchrIframeFlag, closePitchrIframe }) {
    const [contactTab, setcontactTab] = useState({
        ViewColor: "exit_velocity"
    })

    const HandleChangePitchColor = (e) => {
        const { name, value } = e.target
        setcontactTab({
            [name]: value
        })
    }

    return (
        <>
            <div className="d-flex">
                <div className="w-100">
                    <div className="container-fluid">
                        {/* view chart */}
                        <div className="common-charts">
                            <div className={`row ${!isPrinting ? 'singleDiv' : 'breakDiv'}`}>
                                <div className="w-33">
                                    <CatcherView
                                        chartData={filterRes.catcher_view_strike_zone || []}
                                        ViewColor={contactTab.ViewColor}
                                        onChangeEvent={HandleChangePitchColor}
                                        softball={constant.softball}
                                        x_min={-2.5}
                                        x_max={2.5}
                                        y_min={0}
                                        y_max={5}
                                        x_axis="Side at Plate (ft)"
                                        y_axis="Height at Plate (ft)"
                                        id="CatcherViewChart"
                                        title="Catcher View"
                                        cameraAngle={["Catcher", "Press Box"]}
                                        visualType="Pitching"
                                        pitchLocationType="Hit"
                                        colorScheme={contactTab.ViewColor}
                                        eventProfileData={eventProfileData}
                                        sendReq={sendReq}
                                        tabslist={tabslist}
                                        profilepage={true}
                                        isNotDownloadImgLoader={isNotDownloadImgLoader}
                                        isDownloadImgLoader={isDownloadImgLoader}
                                        pitchrIframeFlag={pitchrIframeFlag}
                                        closePitchrIframe={closePitchrIframe} />
                                </div>
                                <div className="w-33">
                                    <TopView
                                        chartData={filterRes.top_view_strike_zone || []}
                                        ViewColor={contactTab.ViewColor}
                                        onChangeEvent={HandleChangePitchColor}
                                        softball={constant.softball}
                                        x_min={-2}
                                        x_max={2}
                                        y_min={-2}
                                        y_max={4}
                                        x_axis="Side at Plate (ft)"
                                        y_axis="Depth at Plate (ft)"
                                        id="TopViewChart"
                                        title="Top View"
                                        cameraAngle={["Home Top", "Home 1B side", "Home 3B side"]}
                                        visualType="Pitching"
                                        pitchLocationType="Hit"
                                        colorScheme={contactTab.ViewColor}
                                        eventProfileData={eventProfileData}
                                        sendReq={sendReq}
                                        tabslist={tabslist}
                                        profilepage={true}
                                        isNotDownloadImgLoader={isNotDownloadImgLoader}
                                        isDownloadImgLoader={isDownloadImgLoader}
                                        pitchrIframeFlag={pitchrIframeFlag}
                                        closePitchrIframe={closePitchrIframe} />
                                </div>
                                <div className="w-33">
                                    <SideView
                                        chartData={filterRes.side_view_strike_zone || []}
                                        ViewColor={contactTab.ViewColor}
                                        onChangeEvent={HandleChangePitchColor}
                                        softball={constant.softball}
                                        x_min={-2}
                                        x_max={4}
                                        y_min={0}
                                        y_max={5}
                                        x_axis="Depth at Plate (ft)"
                                        y_axis="Height at Plate (ft)"
                                        id="SideViewChart"
                                        title="Side View"
                                        cameraAngle={["Home 1B side", "Home 3B side", "Home Top"]}
                                        visualType="Pitching"
                                        pitchLocationType="Hit"
                                        colorScheme={contactTab.ViewColor}
                                        eventProfileData={eventProfileData}
                                        sendReq={sendReq}
                                        tabslist={tabslist}
                                        profilepage={true}
                                        isNotDownloadImgLoader={isNotDownloadImgLoader}
                                        isDownloadImgLoader={isDownloadImgLoader}
                                        pitchrIframeFlag={pitchrIframeFlag}
                                        closePitchrIframe={closePitchrIframe} />
                                </div>
                            </div>
                        </div>
                        <div className="common-charts">
                            <div className={`row ${!isPrinting ? 'singleDiv' : 'breakDiv'}`}>
                                <div className="w-33">
                                    <SpyayChart
                                        chartData={filterRes.spray_chart_data || []}
                                        cameraAngle={["Press Box", "Spray Chart"]}
                                        visualType="Hitting"
                                        pitchLocationType="Hit"
                                        colorScheme="PlayResult"
                                        eventProfileData={eventProfileData}
                                        sendReq={sendReq}
                                        tabslist={tabslist}
                                        profilepage={true}
                                        isNotDownloadImgLoader={isNotDownloadImgLoader}
                                        isDownloadImgLoader={isDownloadImgLoader}
                                        pitchrIframeFlag={pitchrIframeFlag}
                                        closePitchrIframe={closePitchrIframe} />
                                </div>
                                <div className="w-33">
                                    <EVSpyayChart
                                        chartData={getSprayChartRangeColor(constant.softball, filterRes.spray_chart_data || [], "exit_velocity")}
                                        softball={constant.softball}
                                        tabName="contactTab"
                                        cameraAngle={["Press Box", "Spray Chart"]}
                                        visualType="Hitting"
                                        pitchLocationType="Hit"
                                        colorScheme="ExitVelocity"
                                        eventProfileData={eventProfileData}
                                        sendReq={sendReq}
                                        tabslist={tabslist}
                                        profilepage={true}
                                        isNotDownloadImgLoader={isNotDownloadImgLoader}
                                        isDownloadImgLoader={isDownloadImgLoader}
                                        pitchrIframeFlag={pitchrIframeFlag}
                                        closePitchrIframe={closePitchrIframe} />
                                </div>
                                <div className="w-33">
                                    <DotRangeSpyayChart
                                        chartData={filterRes.spray_chart_data || []}
                                        softball={constant.softball}
                                        tabName="contactTab"
                                        cameraAngle={["Press Box", "Spray Chart"]}
                                        visualType="Hitting"
                                        pitchLocationType="Hit"
                                        colorScheme="ContactType"
                                        eventProfileData={eventProfileData}
                                        sendReq={sendReq}
                                        tabslist={tabslist}
                                        profilepage={true}
                                        isNotDownloadImgLoader={isNotDownloadImgLoader}
                                        isDownloadImgLoader={isDownloadImgLoader}
                                        pitchrIframeFlag={pitchrIframeFlag}
                                        closePitchrIframe={closePitchrIframe} />
                                </div>
                            </div>
                        </div>
                        {/* table */}
                        <div>
                            <PointOfContactTable
                                tableData={filterRes.point_of_contact_table_data || []}
                                title="Point of Contact" />
                        </div>
                        <div>
                            <PullTable
                                tableData={filterRes.pull_table_data || []}
                                title="Pull" />
                        </div>
                        <div>
                            <MiddleTable
                                tableData={filterRes.middle_table_data || []}
                                title="Middle" />
                        </div>
                        <div>
                            <OppoTable
                                tableData={filterRes.oppo_table_data || []}
                                title="Oppo" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactTab
