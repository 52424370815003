import React, { Component } from "react";
import { imagesArr } from "../../../assets/images";
import { connect } from "react-redux";
import { getScrimmageSummaryData } from "../../../store/actions";
import Select from "react-select";
import { printToPDFMultiple } from "../../../shared/printReportUtils";
import Loader from "../../../shared/loader";
import AwayBattedBallsTable from "../../../components/CommanTable/BattedBallsTable";
import PitchersMainChart from "../../../components/Charts/PitchersMainChart";
import PitcherReleasePointChart from "../../../components/Charts/releasePointChart";
import PitcherPitchSequencingChart from "../../../components/Charts/pitchSequencingChart";
import PitcherCountSequencingChart from "../../../components/Charts/pitchSequencingChart";
import PitcherResultSequencingChart from "../../../components/Charts/pitchSequencingChart";
import PitcherSideReleaseChart from "../../../components/Charts/sideReleaseChart";
import PitcherStrikeZoneChart from "../../../components/Charts/strikeZoneChart";
import ResultsTable from "../../../components/CommanTable/ResultsTable";
import SummaryTable from "../../../components/CommanTable/SummaryTable";
import PitchCharacteristicsTable from "../../../components/CommanTable/PitchCharacteristics";
import PlateDisciplineTable from "../../../components/CommanTable/PlateDisciplineTable";
import PitcherPolarChart from "../../../components/Charts/PolarChart";
import PitcherLongBreakMovementChart from './../../../components/Charts/breakMovementChart';
import PitcherShortBreakMovementChart from './../../../components/Charts/breakMovementChart';
import PitcherSwingsStrikeZoneChart from './../../../components/Charts/GSStrikeZoneChart';
import PitcherWhiffsStrikeZoneChart from './../../../components/Charts/GSStrikeZoneChart';
import PitcherBallsInPlayStrikeZoneChart from './../../../components/Charts/GSStrikeZoneChart';
import { xformBreakMovementChart } from "../../../shared/function";

class Pitchers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: "",
      filterFlag: true,
      resultData: [],
      pitchSummaryTableData: [],
      characteristicTableData: [],
      disciplineTableData: [],
      splineChartData: [],
      strikeZoneData: [],
      releasePointChartData: [],
      sideReleasePointChartData: [],
      longBubbleBreakMovement: [],
      shortBubbleBreakMovement: [],
      longDotBreakMovement: [],
      shortDotBreakMovement: [],
      polarChartData: [],
      pitchersSequenceChart: [],
      countSequenceChart: [],
      resultSequenceChart: [],
      battedBallsTable: [],
      swingsChartData: [],
      whiffsChartData: [],
      ballsInPlayChartData: [],
      pitchersData: [],
      isPrinting: false,
      backUP: null,
      spline_chart: "velocity",
      isDownloadImg: false
    };
  }

  componentDidMount() {
    let sorceid = document.location.href.split("/")[4];
    if (sorceid === undefined) window.location.href = "/";
    else this.pitchersTabData("pitchers", "", false);
  }

  componentDidUpdate = () => {
    if (this.props.appState.SpitcherfilterFlag) {
      if (this.props.pitcherFilter[0] !== this.state.pitchersData[0]?.value) {
        let sorceid = document.location.href.split("/")[4];
        if (sorceid === undefined) window.location.href = "/";
        else this.pitchersTabData("pitchers", "", true);
      }
    }
  }

  // fetch Pitch log data
  pitchersTabData = (activeTab, pitchersName, flag) => {
    let data = {
      sourceId: document.location.href.split("/")[4],
      tab: activeTab,
      filter: {
        pitcher_name: this.props.appState.SpitcherfilterFlag ? this.props.pitcherFilter[0] : pitchersName ? pitchersName : this.props.appState.SpitcherName,
        batter_name: "",
      },
      // printflag: this.props?.isPrint
    };
    // this.props.increment();
    this.props.fetchScrimmageSummaryData(data, (res) => {
      this.setState({
        resultData: res.data.result.pitcher_result_data,
        pitchSummaryTableData: res.data.result.pitcher_summary_table_data,
        characteristicTableData: res.data.result.pitcher_characteristic_table_data,
        disciplineTableData: res.data.result.pitcher_plate_discipline_table_data,
        strikeZoneData: res.data.result.pitchers_strike_zone_data,
        releasePointChartData: res.data.result.pitcher_release_data,
        sideReleasePointChartData: res.data.result.pitcher_side_release_data,
        longDotBreakMovement: res.data.result.pitcher_dot_chart_long_data,
        shortDotBreakMovement: res.data.result.pitcher_dot_chart_short_data,
        longBubbleBreakMovement: res.data.result.pitcher_break_movement_long_data,
        shortBubbleBreakMovement: res.data.result.pitcher_break_movement_short_data,
        polarChartData: res.data.result.pitcher_polar_movement_chart_data,
        pitchersSequenceChart: res.data.result.pitch_sequence_chart_data,
        countSequenceChart: res.data.result.count_sequencing_chart_data,
        resultSequenceChart: res.data.result.result_sequencing_chart_data,
        battedBallsTable: res.data.result.pitcher_batted_ball_data,
        splineChartData: res.data.result.pitcher_spline_chart_data,
        swingsChartData: res.data.result.pitchers_swing_strike_zone_data,
        whiffsChartData: res.data.result.pitchers_whiff_strike_zone_data,
        ballsInPlayChartData: res.data.result.pitchers_balls_inplay_strike_zone_data,
        backUP: res.data.result,
        pitcherDefault: this.props.appState.SpitcherfilterFlag ? this.props.pitcherFilter[0] : pitchersName ? pitchersName : this.props.appState.SpitcherName
      });

      // for load piteher's wise data
      let pitcherData = this.props.pitcherFilter;

      let pitchersSelectData = [];
      // pitchersSelectData.push({ value: "", label: "All Pitchers" })
      pitcherData.map((data) => {
        pitchersSelectData.push({
          value: data,
          label: data,
        });
      });
      this.setState({ pitchersData: pitchersSelectData })
      // this.props.decrement();
        // if (!this.props.isStopPrint && flag) {
        //   this.props?.reduceDataCount()
        // }
    });
  };

  // decrease = () => {
  //   if (!this.props.isStopPrint && this.props.isPrint) {
  //     this.props?.reduceDataCount()
  //   }
  // }

  handleCallback = (index) => {
    this.setState({
      tabs: index,
    });
  };

  // change pitchers wise data
  handleChange = (e) => {
      this.props.appState.SpitcherfilterFlag = false
      this.pitchersTabData("pitchers", e.value, true);
  };

  // metric change y axis of rolling line chart
  metricHandleChange = (e) => {
    const { name, value } = e.target
    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  print = async () => {
    await this.setState({
      isPrinting: true,
      countSequenceChart: Object.assign([], this.state.backUP.count_sequencing_chart_data),
      resultSequenceChart: Object.assign([], this.state.backUP.result_sequencing_chart_data),
      pitchersSequenceChart: Object.assign([], this.state.backUP.pitch_sequence_chart_data),
      polarChartData: Object.assign([], this.state.backUP.pitcher_polar_movement_chart_data),
      longDotBreakMovement: Object.assign([], this.state.backUP.pitcher_dot_chart_long_data),
      shortDotBreakMovement: Object.assign([], this.state.backUP.pitcher_dot_chart_short_data),
      longBubbleBreakMovement: Object.assign([], this.state.backUP.pitcher_break_movement_long_data),
      shortBubbleBreakMovement: Object.assign([], this.state.backUP.pitcher_break_movement_short_data),
      sideReleasePointChartData: Object.assign([], this.state.backUP.pitcher_side_release_data),
      releasePointChartData: Object.assign([], this.state.backUP.pitcher_release_data),
      strikeZoneData: Object.assign([], this.state.backUP.pitchers_strike_zone_data),
      splineChartData: Object.assign([], this.state.backUP.pitcher_spline_chart_data),
      swingsChartData: Object.assign([], this.state.backUP.pitchers_swing_strike_zone_data),
      whiffsChartData: Object.assign([], this.state.backUP.pitchers_whiff_strike_zone_data),
      ballsInPlayChartData: Object.assign([], this.state.backUP.pitchers_balls_inplay_strike_zone_data),
    });
    setTimeout(async () => {
      await printToPDFMultiple(`pitchers`, [
        'tablePrintArea',
        'mainChartPrintArea',
        'releaseStrikeChartPrintArea',
        'GameStrikeChartPrintArea',
        'sequencingChartPrintArea',
        'movementChartPrintArea',
        'lastPitchTable'
      ]);
      this.setState({
        isPrinting: false,
        countSequenceChart: Object.assign([], this.state.countSequenceChart),
        resultSequenceChart: Object.assign([], this.state.resultSequenceChart),
        pitchersSequenceChart: Object.assign([], this.state.pitchersSequenceChart),
        polarChartData: Object.assign([], this.state.polarChartData),
        longDotBreakMovement: Object.assign([], this.state.longDotBreakMovement),
        shortDotBreakMovement: Object.assign([], this.state.shortDotBreakMovement),
        longBubbleBreakMovement: Object.assign([], this.state.longBubbleBreakMovement),
        shortBubbleBreakMovement: Object.assign([], this.state.shortBubbleBreakMovement),
        sideReleasePointChartData: Object.assign([], this.state.sideReleasePointChartData),
        releasePointChartData: Object.assign([], this.state.releasePointChartData),
        strikeZoneData: Object.assign([], this.state.strikeZoneData),
        splineChartData: Object.assign([], this.state.splineChartData),
        swingsChartData: Object.assign([], this.state.swingsChartData),
        whiffsChartData: Object.assign([], this.state.whiffsChartData),
        ballsInPlayChartData: Object.assign([], this.state.ballsInPlayChartData),
      })
    }, 1000);
  }

  printAll = () => {
    this.props.printAll();
    this.props.appState.SpitcherName = this.state.pitcherDefault
  }

  render() {
    const tabs = this.state.tabs;
    let {
      resultData,
      pitchSummaryTableData,
      characteristicTableData,
      disciplineTableData,
      splineChartData,
      strikeZoneData,
      releasePointChartData,
      sideReleasePointChartData,
      longBubbleBreakMovement,
      shortBubbleBreakMovement,
      longDotBreakMovement,
      shortDotBreakMovement,
      polarChartData,
      pitchersSequenceChart,
      countSequenceChart,
      resultSequenceChart,
      battedBallsTable,
      pitchersData,
      swingsChartData,
      whiffsChartData,
      ballsInPlayChartData
    } = this.state;

    const bubbleChartDataShort =
      shortBubbleBreakMovement ? shortBubbleBreakMovement.length !== 0
        ? xformBreakMovementChart(shortBubbleBreakMovement, 'pitch_type')
        : [] : [];

    const bubbleChartDataLong =
      longBubbleBreakMovement ? longBubbleBreakMovement.length !== 0
        ? xformBreakMovementChart(longBubbleBreakMovement, 'pitch_type')
        : [] : [];

    const dotChartDataShort =
      shortDotBreakMovement && shortDotBreakMovement.length !== 0
        ? xformBreakMovementChart(shortDotBreakMovement, 'pitch_type')
        : [];

    const dotChartDataLong =
      longDotBreakMovement && longDotBreakMovement.length !== 0
        ? xformBreakMovementChart(longDotBreakMovement, 'pitch_type')
        : [];

    return (
      <>
        {this.state.isPrinting && <Loader />}
        {this.state.isDownloadImg && <Loader text={true}/>}
        <section className="pb-0">
          <div className="container-fluid">
            <div className="common-title withsearch">
              <h2>Pitchers</h2>
              <div className="right-side">
                <div className="serch-box">
                  <Select
                    className="customize-select"
                    classNamePrefix="customize-select"
                    onChange={this.handleChange.bind(this)}
                    options={pitchersData}
                    value={pitchersData.find((obj) => obj.value === this.state.pitcherDefault)}
                  // defaultValue={{
                  //   label: "All Pitchers",
                  //   value: "",
                  // }}
                  />
                </div>
                {!(this.state.isPrinting || this.props.isPrint) && <div className="dropdown">
                  <span className="printer"><img src={imagesArr.printer} alt="print" /></span>
                  <div className="dropdown-content">
                    <ul>
                      <li onClick={() => this.print()}>Print</li>
                      {/* <li onClick={() => this.printAll()}>Print All</li> */}
                    </ul>
                  </div>
                </div>}
              </div>
            </div>

            {/* pitcher tables */}
            <div id="tablePrintArea">
              {(this.state.isPrinting || this.props.isPrint) ? <h3>{this.state.pitcherDefault}</h3> : ""}
              <div>
                <ResultsTable tableData={resultData} />
              </div>
              <div>
                <div className="ss-pitcher-summary-table">
                  <SummaryTable tableData={pitchSummaryTableData} />
                </div>
              </div>
              <div>
                <div className="ss-pitcher-platediscipline-table">
                  <PlateDisciplineTable tableData={disciplineTableData} />
                </div>
              </div>
              <div>
                <div className="ss-pitcher-characteristics-table">
                  <PitchCharacteristicsTable tableData={characteristicTableData} />
                </div>
              </div>
            </div>

            {/* charts */}
            <div className="common-charts" id="mainChartPrintArea">
              <PitchersMainChart id="PitcherChart"
                printAll={this.props.isPrint}
                chartData={splineChartData}
                metricHandleChange={this.metricHandleChange}
                spline_chart={this.state.spline_chart}
                increment={this.props.increment}
                decrement={this.props.decrement}
                eventName={this.props.eventName}
                pitcherDefault={this.state.pitcherDefault}
                profilepage={false}
                isNotDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: false }))}
                isDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: true }))}
              />
            </div>
          </div>

          <div className="container-fluid" id="releaseStrikeChartPrintArea">
            <div className="common-charts">
              <div className={`row ${!(this.state.isPrinting || this.props.isPrint) ? 'singleDiv' : 'breakDiv'}`}>
                <div className="w-33">
                  <PitcherStrikeZoneChart
                    chartData={strikeZoneData}
                    id="strikeZoneChartPitcher"
                    showTitle={true}
                    cameraAngle={["Strike Zone", "Catcher", "Home Top", "Home 3B Side", "Home 1B Side", "Press Box"]}
                    visualType="Pitching"
                    increment={this.props.increment}
                    decrement={this.props.decrement}
                    eventName={this.props.eventName}
                    pitcherDefault={this.state.pitcherDefault}
                    profilepage={false}
                    isNotDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: false }))}
                    isDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: true }))}
                  />
                </div>
                <div className="w-33">
                  <PitcherReleasePointChart
                    chartData={releasePointChartData}
                    id="releasePointChartPitcher"
                    showTitle={true}
                    title="Pitcher Release"
                    cameraAngle={["Pitcher", "Mound Top", "Mound 1B Side", "Mound 3B Side", "First Base", "Third Base"]}
                    visualType="Release"
                    increment={this.props.increment}
                    decrement={this.props.decrement}
                    eventName={this.props.eventName}
                    pitcherDefault={this.state.pitcherDefault}
                    profilepage={false}
                    isNotDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: false }))}
                    isDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: true }))}
                  />
                </div>
                <div className="w-33">
                  <PitcherSideReleaseChart
                    chartData={sideReleasePointChartData}
                    id="sideReleasePointChartPitcher"
                    showTitle={true}
                    cameraAngle={["Mound 3B Side", "Mound 1B Side", "Mound Top", "Pitcher", "Third Base", "First Base"]}
                    visualType="Release"
                    increment={this.props.increment}
                    decrement={this.props.decrement}
                    eventName={this.props.eventName}
                    pitcherDefault={this.state.pitcherDefault}
                    profilepage={false}
                    isNotDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: false }))}
                    isDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: true }))}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid" id="GameStrikeChartPrintArea">
            <div className="common-charts">
              <div className={`row ${!(this.state.isPrinting || this.props.isPrint) ? 'singleDiv' : 'breakDiv'}`}>
                <div className="w-33">
                  <PitcherSwingsStrikeZoneChart
                    chartData={swingsChartData}
                    id="strikeZoneChartPitcherSwings"
                    title="Swings"
                    showTitle={true}
                    cameraAngle={["Strike Zone", "Catcher", "Home Top", "Home 3B Side", "Home 1B Side", "Press Box"]}
                    visualType="Pitching"
                    increment={this.props.increment}
                    decrement={this.props.decrement}
                    eventName={this.props.eventName}
                    pitcherDefault={this.state.pitcherDefault}
                    profilepage={false}
                    isNotDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: false }))}
                    isDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: true }))}
                  />
                </div>
                <div className="w-33">
                  <PitcherWhiffsStrikeZoneChart
                    chartData={whiffsChartData}
                    id="strikeZoneChartPitcherWhiffs"
                    title="Whiffs"
                    showTitle={true}
                    cameraAngle={["Strike Zone", "Catcher", "Home Top", "Home 3B Side", "Home 1B Side", "Press Box"]}
                    visualType="Pitching"
                    increment={this.props.increment}
                    decrement={this.props.decrement}
                    eventName={this.props.eventName}
                    pitcherDefault={this.state.pitcherDefault}
                    profilepage={false}
                    isNotDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: false }))}
                    isDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: true }))}
                  />
                </div>
                <div className="w-33">
                  <PitcherBallsInPlayStrikeZoneChart
                    chartData={ballsInPlayChartData}
                    id="strikeZoneChartPitcherBallsInPlay"
                    title="Balls In Play"
                    showTitle={true}
                    cameraAngle={["Strike Zone", "Catcher", "Home Top", "Home 3B Side", "Home 1B Side", "Press Box"]}
                    visualType="Pitching"
                    increment={this.props.increment}
                    decrement={this.props.decrement}
                    eventName={this.props.eventName}
                    pitcherDefault={this.state.pitcherDefault}
                    profilepage={false}
                    isNotDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: false }))}
                    isDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: true }))}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid" id="sequencingChartPrintArea">
            <div className="common-charts">
              <div className={`row ${!(this.state.isPrinting || this.props.isPrint) ? 'singleDiv' : 'breakDiv'}`}>
                <div className="w-33">
                  <PitcherPitchSequencingChart
                    chartData={pitchersSequenceChart}
                    id="PitchSequencingChartPitcher"
                    showTitle={true}
                    increment={this.props.increment}
                    decrement={this.props.decrement}
                    eventName={this.props.eventName}
                    pitcherDefault={this.state.pitcherDefault}
                    profilepage={false}
                    isNotDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: false }))}
                    isDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: true }))}
                  />
                </div>
                <div className="w-33">
                  <PitcherCountSequencingChart
                    chartData={countSequenceChart}
                    title="Count"
                    id="countSequencingChartPitcher"
                    showTitle={true}
                    increment={this.props.increment}
                    decrement={this.props.decrement}
                    eventName={this.props.eventName}
                    pitcherDefault={this.state.pitcherDefault}
                    profilepage={false}
                    isNotDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: false }))}
                    isDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: true }))}
                  />
                </div>
                <div className="w-33">
                  <PitcherResultSequencingChart
                    chartData={resultSequenceChart}
                    title="Result"
                    id="resultSequencingChartPitcher"
                    showTitle={true}
                    increment={this.props.increment}
                    decrement={this.props.decrement}
                    eventName={this.props.eventName}
                    pitcherDefault={this.state.pitcherDefault}
                    profilepage={false}
                    isNotDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: false }))}
                    isDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: true }))}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid" id="movementChartPrintArea">
            <div className="common-charts">
              <div className={`row ${!(this.state.isPrinting || this.props.isPrint) ? 'singleDiv' : 'breakDiv'}`}>
                <div className="w-33">
                  <PitcherLongBreakMovementChart
                    id="longBreakMovementChartPitcher"
                    form="Long-Form"
                    top={55}
                    bottom={15}
                    className="breakMovementChart"
                    bubbleChartData={bubbleChartDataLong}
                    dotChartData={dotChartDataLong}
                    increment={this.props.increment}
                    decrement={this.props.decrement}
                    eventName={this.props.eventName}
                    pitcherDefault={this.state.pitcherDefault}
                    profilepage={false}
                    isNotDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: false }))}
                    isDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: true }))}
                  />
                </div>
                <div className="w-33">
                  <PitcherShortBreakMovementChart
                    id="shortBreakMovementChartPitcher"
                    form="Short-Form"
                    top={30}
                    bottom={55}
                    className="breakMovementChart"
                    bubbleChartData={bubbleChartDataShort}
                    dotChartData={dotChartDataShort}
                    increment={this.props.increment}
                    decrement={this.props.decrement}
                    eventName={this.props.eventName}
                    pitcherDefault={this.state.pitcherDefault}
                    profilepage={false}
                    isNotDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: false }))}
                    isDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: true }))}
                  />
                </div>
                <div className="w-33">
                  <PitcherPolarChart
                    chartData={polarChartData}
                    id="polarChartchartPitcher"
                    increment={this.props.increment}
                    decrement={this.props.decrement}
                    eventName={this.props.eventName}
                    pitcherDefault={this.state.pitcherDefault}
                    profilepage={false}
                    isNotDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: false }))}
                    isDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: true }))}
                  />
                </div>
              </div>
            </div>
          </div>
          <div id="lastPitchTable">
            <div className="ss-pitcher-battedballs-table">
              <AwayBattedBallsTable tableData={battedBallsTable} />
            </div>
          </div>
        </section>

      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    teamData: state.teamData,
    appState: state.app
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchScrimmageSummaryData: (data, next) => dispatch(getScrimmageSummaryData(data, next)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Pitchers);
