import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import {sideReleaseBattersPitchTypeChartData } from "../../../../shared/function";
import { constant } from "../../../../shared/constant";
import BallrWebGL from "../../../webGL/BallrWebGL";
import Toggle from "../../../../shared/Toggle";
import PitchRWebGL from "../../../webGL/PitchRWebGL";
import domtoimage from 'dom-to-image';
import PrintImagePopup from "../../../../shared/PrintImagePopup";
import { downloadChartPrintImg } from "../../../../shared/printReportUtils";
import use3DView from "../../../../shared/use3DView";

function SideReleaseChart({ chartData, cameraAngle, visualType, eventProfileData, sendReq, tabslist, profilepage, isNotDownloadImgLoader, isDownloadImgLoader, closePitchrIframe, pitchrIframeFlag }) {
  const [data3D, setData3D, toggle, setToggle, onClickToggleButton, flag] = use3DView("sideReleaseChart");
  const [isChartImg, setIsChartImg] = useState(false);
  const [clickFlag, setClickFlag] = useState(false);
  const [ballrFlag, setBallrFlag] = useState(false);
  const [tooltipData, setTooltipData] = useState(null)
  const [state, setState] = useState({
    chart: {
      type: "scatter",
      marginTop: 135,
    },
    title: {
      text: "",
    },
    subtitle: {
      text: "",
    },
    xAxis: {
      title: {
        text: "Extension",
        style: {
          color: "#777F8F",
          fontFamily: "Poppins, sans-serif",
          fontSize: "14px",
          fontWeight: "500",
        },
      },
      min: 0,
      max: 8,
      startOnTick: true,
      endOnTick: true,
      showLastLabel: true,
    },
    yAxis: {
      title: {
        text: "Release Height",
        style: {
          color: "#777F8F",
          fontFamily: "Poppins, sans-serif",
          fontSize: "14px",
          fontWeight: "500",
        },
      },
      min: 0,
      max: constant.softball ? 6 : 8,
    },
    legend: {
      layout: "horizontal",
      align: "center",
      verticalAlign: "top",
      floating: true,
      y: 30
    },
    credits: { enabled: false },
    plotOptions: {
      series: {
        stickyTracking: false,
        states: {
          // stickyTracking: false,
          inactive: {
            opacity: 1,
          },
        }
      },
    },
    tooltip: {
      useHTML: true,
      hideDelay: 500,
      formatter: function () {
        setTooltipData(this?.point);
        // ballrFlag && refview.current.GiveKeyToiframe(this?.point);
        setBallrFlag(true)
        if (this.point.series.name === "Strikezone") return false;
        if (this.point.series.name === "polygon") return false;
        let tooltip = `<span style="color: ${this.point.color
          }; font-weight:bold;">${this.point.options.pitch_type ? this.point.options.pitch_type : "-"
          }</span><br>
        <span style="font-weight:bold;">Pitch No: ${this.point.options.pitch_number
            ? this.point.options.pitch_number
            : "-"
          }</span><br>
        <span style="font-weight:bold;">Pitcher: ${this.point.options.pitcher ? this.point.options.pitcher : "-"
          }</span><br>
          <span style="font-weight:bold;">Batter: ${this.point.options.batter ? this.point.options.batter : "-"
          }</span><br>
          <span style="font-weight:bold;">Play Result: ${this.point.options.pitch_call ? this.point.options.pitch_call : "-"
          }</span><br>
          <span style="font-weight:bold;">Velocity: ${this.point.options && this.point.options.velocity
            ? this.point.options.velocity.toFixed(1)
            : "-"
          }</span><br>
          <span style="font-weight:bold;">Spin Rate: ${this.point.options && this.point.options.spin_rate
            ? Math.round(this.point.options.spin_rate)
            : "-"
          }</span><br>
          <span style="font-weight:bold;">Tilt: ${this.point.options && this.point.options.tilt
            ? this.point.options.tilt
            : "-"
          }</span><br>
          <span style="font-weight:bold;">Axis: ${this.point.options && this.point.options.spin_axis
            ? Math.round(this.point.options.spin_axis)
            : "-"
          }</span><br>
          <span style="font-weight:bold;">Efficiency: ${this.point.options && this.point.options.efficiency
            ? Math.round(this.point.options.efficiency)
            : "-"
          }</span><br>
          <span style="font-weight:bold;">Vert Break: ${this.point.options && this.point.options.v_break
            ? this.point.options.v_break.toFixed(1)
            : "-"
          }</span><br>
          <span style="font-weight:bold;">Ind V Break: ${this.point.options && this.point.options.ind_v_break
            ? this.point.options.ind_v_break.toFixed(1)
            : "-"
          }</span><br>
          <span style="font-weight:bold;">H Break: ${this.point.options && this.point.options.h_break
            ? this.point.options.h_break.toFixed(1)
            : "-"
          }</span><br>
          <span style="font-weight:bold;">V Approach: ${this.point.options && this.point.options.vert_appr_angle
            ? this.point.options.vert_appr_angle.toFixed(1)
            : "-"
          }</span><br>
          <span style="font-weight:bold;">Rel Height: ${this.point.options && this.point.options.release_height
            ? this.point.options.release_height.toFixed(1)
            : "-"
          }</span><br>
          <span style="font-weight:bold;">Rel Side: ${this.point.options && this.point.options.release_side
            ? this.point.options.release_side.toFixed(1)
            : "-"
          }</span><br>
          <span style="font-weight:bold;">Extension: ${this.point.options && this.point.options.ext
            ? this.point.options.ext.toFixed(1)
            : "-"
          }</span><br>
          <span style="font-weight:bold;">Exit Velocity: ${this.point.options && this.point.options.exit_velocity
            ? this.point.options.exit_velocity.toFixed(1)
            : "-"
          }</span><br>
          <span style="font-weight:bold;">Launch Angle: ${this.point.options && this.point.options.launch_angle
            ? this.point.options.launch_angle.toFixed(1)
            : "-"
          }</span><br>
          <span style="font-weight:bold;">Distance: ${this.point.options && this.point.options.distance
            ? Math.round(this.point.options.distance)
            : "-"
          }</span>
          <!-- <div style="color: red" onmouseover="ProfileSideReleaseLoadBallR()">BallR</div> -->
        </span>`;
        return tooltip;
      },
      style: {
        pointerEvents: 'auto'
      }
    },
    series: [],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
        },
      ],
    },
  });

  useEffect(() => {
    if (toggle.chartToggle === true) {
      if (chartData) {
        if (chartData.length !== 0) {
          const seriesData = sideReleaseBattersPitchTypeChartData(chartData);
          state.series = seriesData;
          if (state.series) {
            state.series[state.series.length - 1].showInLegend = false;
            // state.series[state.series.length - 1].marker = false;
          }
          Highcharts.chart("sideReleaseChart", state);
        } else {
          state.series = [{ showInLegend: false }];
          Highcharts.chart("sideReleaseChart", state);
        }
      }
    } else {
      if (chartData && chartData.length !== 0) {
        let arr = chartData.map(x => x?._id) || []
        setData3D(arr)
      }
    }
  }, [chartData, toggle]);

  useEffect(() => {
    if (pitchrIframeFlag === "yes") {
      setData3D(null)
      setToggle({ chartToggle: true });
      closePitchrIframe();
    }
  }, [pitchrIframeFlag])

  const chartImage = (id) => {
    isDownloadImgLoader();
    setClickFlag(true)
    if (document.getElementById(`img${id}`)) {
      document.getElementById(`img${id}`).remove()
    }
    setIsChartImg(true)
    downloadChartPrintImg(id, setIsChartImg, setClickFlag, isNotDownloadImgLoader)
  }

  return (
    <>
      <div className={`ground-chart ${ballrFlag ? "chart-ifream" : ""}`}>
        {chartData && chartData.length !== 0 ? <Toggle toggle={toggle} onClickToggleButton={onClickToggleButton} /> : ""}
        {/* <div className="legends graph-select text-left">
          <div className="text-left d-inline">
            <h3 className="mb-0">Side Release</h3>
            <p>3rd Base View</p>
          </div>
        </div> */}
        <div style={{ display: toggle.chartToggle === true ? 'block' : 'none' }}>
          <div className="ifream-box">
            {ballrFlag && <BallrWebGL tooltipData={tooltipData} id={"sideReleaseballr"} index={tooltipData?._id} />}
          </div>
          <div
            style={{ height: "500px", width: "400px", margin: "0 auto" }}
            id="sideReleaseChart"
          />
          {/* <button className="btn blue btn-view" onClick={() => chartImage("sideReleaseChart")} disabled={clickFlag} >Download</button> */}
          <div class="chartdownload" onClick={() => chartImage("sideReleaseChart")} disabled={clickFlag}><a class="btn white"><i class="fa fa-download" aria-hidden="true"></i></a></div>
        </div>
        {flag?.["iframe_sideReleaseChart"] === true && <div id="pitchR-iframe" className="Chart-iframe" style={{ display: toggle.chartToggle === false ? 'block' : 'none' }}>
          <PitchRWebGL data={data3D} id="sideReleaseChart" cameraAngle={cameraAngle} visualType={visualType} Toggle={toggle.chartToggle} />
        </div>}
      </div>

      {/* chart visual image download */}
      {isChartImg && <PrintImagePopup
        eventProfileData={eventProfileData}
        id="sideReleaseChart"
        sendReq={sendReq}
        tabslist={tabslist}
        profilepage={profilepage} />}
    </>
  );
}

SideReleaseChart.propTypes = {};

export default SideReleaseChart;
