import React from 'react'

function ResultsTable({ tableData }) {
    const renderList = () => {
        return tableData && tableData.length !== 0 ? (
            tableData.map((data, i) => {
                return (
                    <tr key={i}>
                        <td className="table-rowwhite">
                            {data.K ? data.K.toString().includes(".") ? data.K.toFixed(1) : data.K : '—'}
                        </td>
                        <td className="table-rowwhite">
                            {data.BB ? data.BB.toString().includes(".") ? data.BB.toFixed(1) : data.BB : '—'}
                        </td>
                        <td className="table-rowwhite">
                            {data.HBP ? data.HBP.toString().includes(".") ? data.HBP.toFixed(1) : data.HBP : '—'}
                        </td>
                        <td className="table-rowwhite">
                            {data.H ? data.H.toString().includes(".") ? data.H.toFixed(1) : data.H : '—'}
                        </td>
                        <td className="table-rowwhite">
                            {data["2B"] ? data["2B"].toString().includes(".") ? data["2B"].toFixed(1) : data["2B"] : '—'}
                        </td>
                        <td className="table-rowwhite">
                            {data["3B"] ? data["3B"].toString().includes(".") ? data["3B"].toFixed(1) : data["3B"] : '—'}
                        </td>
                        <td className="table-rowwhite">
                            {data.HR ? data.HR.toString().includes(".") ? data.HR.toFixed(1) : data.HR : '—'}
                        </td>
                        <td className="table-rowwhite">
                            {data.first_pitch_k ? data.first_pitch_k.toString().includes(".") ? data.first_pitch_k.toFixed(1) + "%" : data.first_pitch_k + "%" : '0%'}
                        </td>
                    </tr>
                );
            })
        ) : (
            <tr>
                <td className="table-rowwhite text-center" colSpan="8">
                    There's no info yet!
                </td>
            </tr>
        );
    };

    return (
        <div className="container-fluid">
            <h3 className="table-title">Results</h3>
            <div className="homepitchers-table">
                <div className="table-responsive-xl">
                    <table className="table tabledashed">
                        <tbody>
                            <tr>
                                <td className="tablerowblue fw-500">K</td>
                                <td className="tablerowblue fw-500">BB</td>
                                <td className="tablerowblue fw-500">HBP</td>
                                <td className="tablerowblue fw-500">H</td>
                                <td className="tablerowblue fw-500">2B</td>
                                <td className="tablerowblue fw-500">3B</td>
                                <td className="tablerowblue fw-500">HR</td>
                                <td className="tablerowblue fw-500">1st Pitch K %</td>
                            </tr>
                            {renderList()}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default ResultsTable
