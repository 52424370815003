import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { imagesArr } from "../assets/images";

class Gamesummarysidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: "game summery",
      sourceID: document.location.href.split("/")[4] || "",
      currentRoute: document.location.href.split("/")[3] || "",
      sidebarList: [
        {
          sidebarTitle: "Summary",
          sidebarKey: "summary",
          route: "gamesummary",
        },
        {
          sidebarTitle: "pitch log",
          sidebarKey: "pitch_log",
          route: "pitchlog",
        },
        {
          sidebarTitle: "home pitchers",
          sidebarKey: "",
          route: "homepitchers",
        },
        {
          sidebarTitle: "away pitchers",
          sidebarKey: "",
          route: "awaypitchers",
        },
        { sidebarTitle: "home batters", sidebarKey: "", route: "homebatters" },
        { sidebarTitle: "away batters", sidebarKey: "", route: "awaybatters" },
        {
          sidebarTitle: "umpire analysis",
          sidebarKey: "",
          route: "umpireanylysis",
        },
      ],
      teamInfo: {
        name: "",
        logo: ""
      }
    };
  }

  componentDidMount() {
    if (localStorage.getItem("teamData") !== null) {
      this.setState({
        teamInfo: {
          name: JSON.parse(localStorage.getItem("teamData")).name,
          // logo: JSON.parse(localStorage.getItem("teamData")).hasOwnProperty('teamLogo') ? JSON.parse(localStorage.getItem("teamData")).teamLogo.filePath : ""
        }
      })
    }
    this.props.appState.awayHitterName = "";
    this.props.appState.awayHitterfilterFlag = true
    this.props.appState.homePitcherName = "";
    this.props.appState.homePitcherfilterFlag = true
    this.props.appState.awayPitcherName = "";
    this.props.appState.awayPitcherfilterFlag = true
    this.props.appState.homeHitterName = "";
    this.props.appState.homeHitterfilterFlag = true

    // for pitchriframe load
    Object.keys(this.props.appState).filter(key => key.includes("iframe_")).map(key => {
      this.props.appState[key] = false;
    });
  }

  render() {
    const { teamInfo } = this.state
    return (
      <>
        {/* sidebar-wrapper */}
        <div className="sidebar">
          {/* <div className="sidebar-logo">
            <Link to={'/'}>
              <img src={imagesArr.logo} alt="logo" />
            </Link>
            <p>{teamInfo.name}</p>
          </div> */}
          <ul className="list-group">
            {this.state.sidebarList.map((list, i) => {
              return (
                <li
                  key={i}
                  className={`${this.state.currentRoute === list.route && "active"
                    }`}
                >
                  <Link to={`/${list.route}/${this.state.sourceID}`}>
                    {list.sidebarTitle}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
        {/* sidebar-wrapper */}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    appState: state.app
  };
};

export default withRouter(connect(mapStateToProps)(Gamesummarysidebar));
