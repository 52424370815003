import React, { Component, useEffect, useReducer, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { getDevelopmentSummary } from '../../../../store/actions';
import PlateDiscTable from './platediscTable';


function Platedisc({ developA, developB }) {
    const dispatch = useDispatch();
    const [groupA, setGroupA] = useState({
        hitterList: [],
        pitcherList: []
    })
    const [groupB, setGroupB] = useState({
        hitterList: [],
        pitcherList: []
    })
    useEffect(() => {
        if (developA) {
            getPlateDisc("groupA", developA)
        }
        if (developB) {
            getPlateDisc("groupB", developB)
        }
    }, [developA, developB])


    const getPlateDisc = (group, req) => {
        if (group === "groupA") {
            dispatch(getDevelopmentSummary(req, (res) => {
                setGroupA({
                    hitterList: res.data.result.hitter_swing_miss_calc_rows.length != 0 ? res.data.result.hitter_swing_miss_calc_rows : [],
                    pitcherList: res.data.result.pitcher_swing_miss_calc_rows.length != 0 ? res.data.result.pitcher_swing_miss_calc_rows : []
                })
            }))

        }
        if (group === "groupB") {
            dispatch(getDevelopmentSummary(req, (res) => {
                setGroupB({
                    hitterList: res.data.result.hitter_swing_miss_calc_rows.length != 0 ? res.data.result.hitter_swing_miss_calc_rows : [],
                    pitcherList: res.data.result.pitcher_swing_miss_calc_rows.length != 0 ? res.data.result.pitcher_swing_miss_calc_rows : []
                })
            }))
        }
    }

    return (
        <>
            {groupA.pitcherList.length !== 0 || groupA.hitterList.length !== 0 || groupB.pitcherList.length !== 0 || groupB.hitterList.length !== 0 ?
                <>
                    <a href="https://baseballcloud.blog/glossary#plate_discipline" target="_blank">Click here for information on plate discipline data points</a>
                    <div className="platedisc">
                        {groupA.pitcherList.length !== 0 && <PlateDiscTable title="Group A" subTitle="Pitching Data" data={groupA.pitcherList} />}
                        {groupB.pitcherList.length !== 0 && <PlateDiscTable title="Group B" subTitle="Pitching Data" data={groupB.pitcherList} />}
                        {groupA.hitterList.length !== 0 && <PlateDiscTable title="Group A" subTitle="Batting Data" data={groupA.hitterList} />}
                        {groupB.hitterList.length !== 0 && <PlateDiscTable title="Group B" subTitle="Batting Data" data={groupB.hitterList} />}
                    </div>
                </>
                : <div className="tabviewbtn-nodeta" >There's no info yet!</div>
            }
        </>
    )
}

export default Platedisc;

