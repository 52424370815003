import React, { Component } from "react";
import { imagesArr } from "../../../assets/images";
import PeakExitVelocityLeaders from "./components/peakExitVelocityLeaders";
import PeakVelocityLeaders from "./components/peakVelocityLeaders";
import HittingMetrics from "./components/hittingMetrics";
import PitchingMetrics from "./components/pitchingMetrics";
import { getScrimmageSummaryData } from "../../../store/actions";
import { connect } from "react-redux";
import { printToPDF } from "../../../shared/printReportUtils";
import {
  getHittingMetricsTranformDataPitcher,
  getPitchingMetricsTranformDataPitcher
} from "../../../shared/function";
import Loader from "../../../shared/loader";
import InZone from "./components/Zone";
import OutZone from "./components/Zone";
import SummaryTable from "./components/SummaryTable";

class scrimmageSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: "",
      peakExitVelocityLeader: null,
      peakVelocityLeaders: null,
      pMetrics_data: [],
      hMetrics_data: [],
      zone_data: [],
      summary_data: [],
      isPrinting: false,
    };
  }

  componentDidMount() {
    let sorceid = document.location.href.split("/")[4];
    if (sorceid === undefined) window.location.href = "/";
    else this.summaryData();
  }

  // fetch summary data
  summaryData = () => {
    let data = {
      sourceId: document.location.href.split("/")[4],
      tab: "summary",
    };
    this.props.fetchScrimmageSummaryData(data, (res) => {
      this.setState({
        peakExitVelocityLeader: res.data.result.peak_exit_velocity_leader,
        peakVelocityLeaders: res.data.result.peak_velocity_leaders,
        zone_data: res.data.result.plate_discipline_matrics,
        summary_data: res.data.result.scrimmage_summary_table_data
      });
      // hitting metrics table data and rows
      let hMetrics = res.data.result.hitting_metrics;
      if (hMetrics.length !== 0) {
        let hittingMetricsTranformData =
        getHittingMetricsTranformDataPitcher(hMetrics);
        this.setState({
          hMetrics_data: hittingMetricsTranformData
        });
      }
      
      // pitching metrics table data and rows
      let pMetrics = res.data.result.pitching_metrics;
      if (pMetrics.length !== 0) {
        let pitchingMetricsTranformData =
        getPitchingMetricsTranformDataPitcher(pMetrics);
        
        this.setState({
          pMetrics_data: pitchingMetricsTranformData
        });
      }
      // this.props.reduceDataCount();
    });
  };

  handleCallback = (index) => {
    this.setState({
      tabs: index,
    });
  };

  print = async () => {
    await this.setState({ isPrinting: true });
    await printToPDF(`gameSummary`, "scrimmageSummaryPrintArea");
    this.setState({ isPrinting: false });
  };

  printAll = () => {
    this.props.printAll();
  };

  render() {
    const tabs = this.state.tabs;
    let {
      peakExitVelocityLeader,
      peakVelocityLeaders,
      summary_data,
      pMetrics_data,
      hMetrics_data,
      zone_data,
    } = this.state;
    return (
      <>
        {this.state.isPrinting && <Loader />}
        <section className="pb-0">
          <div className="container-fluid">
            <div className="common-title">
              <h2>Scrimmage Summary</h2>
              {!(this.state.isPrinting || this.props.isPrint) && (
                <div className="dropdown">
                  <span className="printer">
                    <img src={imagesArr.printer} alt="print" />
                  </span>
                  <div className="dropdown-content">
                    <ul>
                      <li onClick={() => this.print()}>Print</li>
                      {/* <li onClick={() => this.printAll()}>Print All</li> */}
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="container-fluid" id="scrimmageSummaryPrintArea">
            <div className="text-center">
              <h3 className="table-title">BREAKDOWN</h3>
            </div>
            <div className="row">
              <HittingMetrics hMetrics_data={hMetrics_data} />
              <PitchingMetrics pMetrics_data={pMetrics_data} />
            </div>
            <div className="text-center">
              <h3 className="table-title">RESULTS</h3>
            </div>
            <div className="row">
              <div className="ss-summary-result-table">
                <SummaryTable
                  summary_data={summary_data}
                />
              </div>
            </div>
            <div className="text-center">
              <h3 className="table-title">PLATE DISCIPLINE</h3>
            </div>
            <div className="row">
              <InZone
                zone_data={zone_data.find((i)=>i._id === "in_zone")}
                zone="In Zone"
              />
              <OutZone
                zone_data={zone_data.find((i)=>i._id === "out_zone")}
                zone="Out of Zone"
              />
            </div>
            <div className="row">
              <PeakExitVelocityLeaders data={peakExitVelocityLeader} />
              <PeakVelocityLeaders data={peakVelocityLeaders} />
            </div>
          </div>
        </section>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    teamData: state.teamData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchScrimmageSummaryData: (data, next) => dispatch(getScrimmageSummaryData(data, next)),
});
export default connect(mapStateToProps, mapDispatchToProps)(scrimmageSummary);
