import React, { Component } from 'react';
import { imagesArr } from "../../../assets/images";

class tabPanel extends Component {
    render() {
        const { summaryData, arr } = this.props;
        return (
            <>
            <div className="filtersection d-flex mt-0 team-text-align">
                <h3 className='mb-0'>Hitting: </h3>
                <div className='hitting-pitching'>
                    <button className={`btn btnhead mr-15 ${summaryData.tab === "hitters_batted_ball" ? "blue" : "trans"}`} onClick={this.props.toggleTeamSummary.bind(this, "hitters_batted_ball")}>
                        Batted Ball
                    </button>
                    <button className={`btn btnhead mr-15 ${summaryData.tab === "hitters_plate_discipline" ? "blue" : "trans"}`} onClick={this.props.toggleTeamSummary.bind(this, "hitters_plate_discipline")}>
                        Plate Discipline
                    </button>
                    <button className={`btn btnhead mr-15 ${summaryData.tab === "hitters_blast_motion" ? "blue" : "trans"}`} onClick={this.props.toggleTeamSummary.bind(this, "hitters_blast_motion")}>
                        Blast Motion
                    </button>
                </div>
            </div>
            <div className="filtersection d-flex mt-0 team-text-align">
                <h3 className='mb-0'>Pitching: </h3>
                <div className='hitting-pitching'>
                    <button className={`btn btnhead mr-15 ${summaryData.tab === "pitchers_batted_ball" ? "blue" : "trans"}`} onClick={this.props.toggleTeamSummary.bind(this, "pitchers_batted_ball")}>
                        Batted Ball
                    </button>
                    <button className={`btn btnhead mr-15 ${summaryData.tab === "pitchers_plate_discipline" ? "blue" : "trans"}`} onClick={this.props.toggleTeamSummary.bind(this, "pitchers_plate_discipline")}>
                        Plate Discipline
                    </button>
                    <button className={`btn btnhead mr-15 ${summaryData.tab === "pitchers_pitch_metrics" ? "blue" : "trans"}`} onClick={this.props.toggleTeamSummary.bind(this, "pitchers_pitch_metrics")}>
                        Pitch Metrics
                    </button>
                </div>
            </div>
            </>
        );
    }
}

export default tabPanel;
