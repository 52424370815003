import React, { Component } from "react";
import { connect } from "react-redux";
import Footer from "../containers/footer";
import Header from "../containers/header";
import Teamsummary from "./teamsummary/teamsummary";
import Development from "./development/development";
import Maindashboard from "./maindashboard/maindashboard";
import SidebarListData from "./SidebarListData";
import { getDashboardData } from "../store/actions";
import Cookies from 'js-cookie';
import moment from "moment";

class Dashborad extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: "",
      sessionReports: [],
      teamProfiles: [],
      team_info: []
    };
  }

  componentDidMount = () => {
    let userToken = Cookies.get('bcSession', { domain: process.env.REACT_APP_COOKIE_DOMAIN });
    this.myTeamHittersData("hitters");
  };

  // myTeam data
  myTeamHittersData = (teamData) => {
    let data = {
      count: 0,
      offset: 0,
      type: teamData,
      season: moment().year().toString(),
    };
    this.props.loader(true);
    this.props.fetchDashboardData(data, (res) => {
      this.setState({ sessionReports: res.events, teamProfiles: res.team_profiles, team_info: res.team_info, seasonList: res.seasons },
        () => {
          this.props.loader(false);
        })
    });
  };

  handleCallback = (index) => {
    this.setState({
      tabs: index,
    });
  };

  userTypeData = (data) => {
    this.setState({ teamProfiles: data })
  }

  render() {
    const tabs = this.state.tabs;
    let { sessionReports, teamProfiles, team_info, seasonList } = this.state;
    return (
      <>
        <div className="d-flex button_hitters_fixed">
          {/* {tabs !== 0 && tabs !== 1 && <SidebarListData data={teamProfiles} />} */}
          <div
            className={`page-content-wrapper ${tabs === 0 || tabs === 1 ? "tabactive" : ""
              }`}
          >
            {
              team_info.length !== 0 &&
              <Header parentCallback={this.handleCallback} teamInfo={team_info} />
            }
            {tabs === 0 && <Teamsummary />}
            {tabs === 1 && <Development />}
            {tabs !== 0 && tabs !== 1 && teamProfiles && (
              <Maindashboard sessionReport={sessionReports} userTypeData={this.userTypeData} seasonList={seasonList} teamProfiles={teamProfiles && teamProfiles.length !== 0 ? teamProfiles : []} />
            )}
          </div>
          {/* <Footer /> */}
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    teamData: state.app.teamData
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchDashboardData: (data, next) => dispatch(getDashboardData(data, next)),
  loader: (data) => dispatch({ type: "LOADING", payload: data })
});
export default connect(mapStateToProps, mapDispatchToProps)(Dashborad);
