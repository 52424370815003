import React, { Component } from "react";
import { connect } from "react-redux";
import { imagesArr } from "../../../assets/images";
import { getGameSummaryData } from "../../../store/actions";
import Select from "react-select";
import Loader from "../../../shared/loader";
import AwayAtBat from "../../../components/Charts/AtBat/AtBat";
import AwaySummaryTable from "../../../components/CommanTable/SummaryTable";
import AwayHitterPlateDiscipline from "../../../components/CommanTable/HitterPlateDiscipline";
import AwayHitterBattedBallTable from "../../../components/CommanTable/HitterBattedBallTable";
import AwayHitterPitchSequencingChart from "../../../components/Charts/pitchSequencingChart";
import AwayHitterCountSequencingChart from "../../../components/Charts/pitchSequencingChart";
import AwayHitterResultSequencingChart from "../../../components/Charts/pitchSequencingChart";
import { printToPDFMultiple } from "../../../shared/printReportUtils";

class AwayBatters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: "",
      hittersData: [],
      awayHitterSummaryTableData: [],
      awayHitterPlateDisciplineTableData: [],
      awayHitterBattedBallTableData: [],
      awayPitchersSequenceChart: [],
      awayCountSequenceChart: [],
      awayResultSequenceChart: [],
      awayAtBatTableData: [],
      awayAtBatSprayChartData: [],
      awayAtBatStrikeChartData: [],
      atBatLength: [],
      isPrinting: false,
      isDownloadImg: false
    };
  }

  componentDidMount() {
    let sorceid = document.location.href.split("/")[4];
    if (sorceid === undefined) window.location.href = "/";
    else this.homePitchersData("", false);
  }
  componentDidUpdate = () => {
    if (this.props.appState.awayHitterfilterFlag) {
      if (this.props.awayHitterFilter[0] !== this.state.hittersData[0]?.value) {
        let sorceid = document.location.href.split("/")[4];
        if (sorceid === undefined) window.location.href = "/";
        else this.homePitchersData("", true);
      }
    }
  }

  // fetch Pitch log data
  homePitchersData = (hitterName, flag) => {
    let data = {
      sourceId: document.location.href.split("/")[4],
      tab: "away_hitters",
      filter: {
        pitcher_name: "",
        batter_name: this.props.appState.awayHitterfilterFlag ? this.props.awayHitterFilter[0] : hitterName ? hitterName : this.props.appState.awayHitterName,
      },
      // printflag: this.props?.isPrint
    };
    // this.props.increment();
    this.props.fetchSummaryData(data, (res) => {
      this.setState({
        awayHitterSummaryTableData: res.data.result.hitter_summary_table_data,
        awayHitterPlateDisciplineTableData: res.data.result.hitter_plate_discipline_table_data,
        awayHitterBattedBallTableData: res.data.result.hitter_batted_balls_data,
        awayPitchersSequenceChart: res.data.result.hitter_pitch_sequence_chart_data,
        awayCountSequenceChart: res.data.result.hitter_count_sequencing_chart_data,
        awayResultSequenceChart: res.data.result.hitter_result_sequencing_chart_data,
        awayAtBatTableData: res.data.result.hitter_at_bats_table_data,
        awayAtBatSprayChartData: res.data.result.hitter_spray_chart_data,
        awayAtBatStrikeChartData: res.data.result.hitter_strike_zone_data,
        hitterDefault: this.props.appState.awayHitterfilterFlag ? this.props.awayHitterFilter[0] : hitterName ? hitterName : this.props.appState.awayHitterName
      });

      // set atbats length arrary for mapping
      if (this.state.awayAtBatStrikeChartData.length !== 0 && this.state.awayAtBatTableData.length !== 0 && this.state.awayAtBatSprayChartData.length !== 0) {
        let str = Object.keys(this.state.awayAtBatStrikeChartData);
        let tab = Object.keys(this.state.awayAtBatTableData);
        let spr = Object.keys(this.state.awayAtBatSprayChartData);

        // if (JSON.stringify(str) === JSON.stringify(tab) && JSON.stringify(tab)  === JSON.stringify(spr) && JSON.stringify(str) === JSON.stringify(spr)) {
        this.setState({
          atBatLength: str
        })
        // }
      }

      // for load hitter's wise data
      let hitterData = this.props.awayHitterFilter;

      let hittersSelectData = [];
      // hittersSelectData.push({ value: "", label: "All Hitters" })
      hitterData.map((data) => {
        hittersSelectData.push({
          value: data,
          label: data,
        });
      });
      this.setState({ hittersData: hittersSelectData });
      // this.props.decrement();
      // if (!this.props.isStopPrint && flag) {  
      //   this.props.reduceDataCount();
      // }
    });
  };

  handleCallback = (index) => {
    this.setState({
      tabs: index,
    });
  };

  print = async () => {
    await this.setState({ isPrinting: true })
    await printToPDFMultiple(`awayBatters`, [
      // 'ChartPrintAreaAwayBatter',
      'tablePrintAreaAwayHitter',
      ...this.state.atBatLength
    ]);
    this.setState({ isPrinting: false })
  }

  printAll = async () => {
    this.props.printAll();
    this.props.appState.awayHitterName = this.state.hitterDefault;
  }

  // change hitter wise data
  handleChange = (e) => {
    this.props.appState.awayHitterfilterFlag = false
    this.homePitchersData(e.value, true);
  };

  render() {
    const tabs = this.state.tabs;
    let { atBatLength, hittersData, awayHitterSummaryTableData, awayHitterPlateDisciplineTableData, awayHitterBattedBallTableData, awayPitchersSequenceChart, awayCountSequenceChart, awayResultSequenceChart, awayAtBatTableData, awayAtBatSprayChartData, awayAtBatStrikeChartData } = this.state;
    return (
      <>
        {this.state.isPrinting && <Loader />}
        {this.state.isDownloadImg && <Loader text={true} />}
        <section className="pb-0" id="ChartPrintAreaAwayBatter">
          <div className="container-fluid">
            <div className="common-title withsearch">
              <h2>Away Batters</h2>
              <div className="right-side">
                <div className="serch-box">
                  <Select
                    className="customize-select"
                    classNamePrefix="customize-select"
                    onChange={this.handleChange.bind(this)}
                    options={hittersData}
                    value={hittersData.find((obj) => obj.value === this.state.hitterDefault)}
                  // defaultValue={{
                  //   label: "All Hitters",
                  //   value: "",
                  // }}
                  />
                </div>
                {(!(this.state.isPrinting || this.props.isPrint)) && <div className="dropdown">
                  <span className="printer"><img src={imagesArr.printer} alt="print" /></span>
                  <div className="dropdown-content">
                    <ul>
                      <li onClick={() => this.print()}>Print</li>
                      {/* <li onClick={() => this.printAll()}>Print All</li> */}
                    </ul>
                  </div>
                </div>}
              </div>
            </div>
          </div>

          {/* hitter tables */}
          <div id="tablePrintAreaAwayHitter">
            {(this.state.isPrinting || this.props.isPrint) ? <h3>{this.state.hitterDefault}</h3> : ""}
            <div className="ss-hitter-summary-table">
              <AwaySummaryTable tableData={awayHitterSummaryTableData} />
            </div>
            <div className="ss-hitter-platediscipline-table">
              <AwayHitterPlateDiscipline tableData={awayHitterPlateDisciplineTableData} />
            </div>
            <div className="ss-hitter-battedball-table">
              <AwayHitterBattedBallTable tableData={awayHitterBattedBallTableData} />
            </div>

            {/* sequencuning chart */}
            <div className="container-fluid" id="sequencingChartPrintArea">
              <div className="common-charts">
                <div className={`row ${!(this.state.isPrinting || this.props.isPrint) ? 'singleDiv' : 'breakDiv'}`}>
                  <div className="w-33">
                    <AwayHitterPitchSequencingChart
                      chartData={awayPitchersSequenceChart}
                      id="PitchSequencingChartAwayHitter"
                      showTitle={true}
                      increment={this.props.increment}
                      decrement={this.props.decrement}
                      eventName={this.props.eventName}
                      pitcherDefault={this.state.hitterDefault}
                      profilepage={false}
                      isNotDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: false }))}
                      isDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: true }))}
                    />
                  </div>
                  <div className="w-33">
                    <AwayHitterCountSequencingChart
                      chartData={awayCountSequenceChart}
                      title="Count"
                      id="countSequencingChartAwayHitter"
                      showTitle={true}
                      increment={this.props.increment}
                      decrement={this.props.decrement}
                      eventName={this.props.eventName}
                      pitcherDefault={this.state.hitterDefault}
                      profilepage={false}
                      isNotDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: false }))}
                      isDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: true }))}
                    />
                  </div>
                  <div className="w-33">
                    <AwayHitterResultSequencingChart
                      chartData={awayResultSequenceChart}
                      title="Result"
                      id="resultSequencingChartAwayHitter"
                      showTitle={true}
                      increment={this.props.increment}
                      decrement={this.props.decrement}
                      eventName={this.props.eventName}
                      pitcherDefault={this.state.hitterDefault}
                      profilepage={false}
                      isNotDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: false }))}
                      isDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: true }))}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="ss-hitter-atbats-table">
            <h3 className="table-title">AT BATS</h3>
            {atBatLength.length !== 0 ?
              atBatLength.map((i) => {
                return <AwayAtBat
                  index={i}
                  atBatStrikeChartData={awayAtBatStrikeChartData}
                  atBatTableData={awayAtBatTableData}
                  atBatSprayChartData={awayAtBatSprayChartData}
                  isPrinting={this.state.isPrinting}
                  isPrint={this.props.isPrint}
                  type="away"
                  increment={this.props.increment}
                  decrement={this.props.decrement}
                  eventName={this.props.eventName}
                  pitcherDefault={this.state.hitterDefault}
                  profilepage={false}
                  isNotDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: false }))}
                  isDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: true }))}
                />
              })
              :
              <div className="container-fluid">
                <div className="common-charts text-center">There's no info yet!</div>
              </div>}
          </div>
        </section>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    teamData: state.teamData,
    appState: state.app
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchSummaryData: (data, next) => dispatch(getGameSummaryData(data, next)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AwayBatters);
