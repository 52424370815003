
// lib
import * as d3 from 'd3';
import { constant } from '../../../shared/constant';
// src
// import '../../../style/components/breakMovementChart.scss';
import { d3ChartdimentionCalculator, d3ChartXAxis, d3ChartYAxis, findCenter } from '../../../shared/function';

// var tooltipData;

const BreakMovementChart = (
  id,
  chartHeight,
  chartWidth,
  xAxisDomain,
  yAxisDomain,
  data,
  maxXAxisValue,
  xLabel,
  yLabel,
  form,
  m_top,
  m_bottom,
  Clusters,
  tooltipDatanew,
  setTooltipData,
  setBallrFlag,
  ballrFlag,
  // refview
) => {
  //for ballr iframe load
  // window.BreakMovementLoadBallR = function() {
  //   setTooltipData(tooltipData)
  //   setBallrFlag(true)
  // };
  // CONSTANTS AND FUNCTIONS
  const margin = { top: m_top, right: 30, bottom: m_bottom, left: 45 };
  const strokeWidth = 2;
  // set the dimensions and margins of the graph
  const width = d3ChartdimentionCalculator(
    chartWidth,
    margin.left,
    margin.right,
  );
  const height = d3ChartdimentionCalculator(
    chartHeight,
    margin.top,
    margin.bottom,
  );
  const xAxis = d3ChartXAxis(width, xAxisDomain);
  const yAxis = d3ChartYAxis(height, yAxisDomain);
  const formatXDegrees = d => `${d}"`;
  const formatYDegrees = d => `${d}"`;
  const innerCircleRadius = d => {
    const radX = Math.abs(xAxis(d.x2) - xAxis(d.x1)) / 2 - strokeWidth / 2;
    const radY = Math.abs(yAxis(d.y2) - yAxis(d.y1)) / 2 - strokeWidth / 2;
    if (radX <= radY && radX < 10) {
      if (radX < 1) {
        return 2.5;
      }
      return radX / 4;
    }
    if (radY < radX && radY < 10) {
      if (radY < 1) {
        return 2.5;
      }
      return radY / 4;
    }
    return 2.5;
  };

  // TOOLTIP
  d3.selectAll(`#${id} .bubbleChartSvg`).remove();
  d3.selectAll(`#${form + 'toolTip'}`).remove();

  const tooltip = d3
    .select('body')
    .append('div')
    .attr('id', `${form + 'toolTip'}`)
    .style('position', 'absolute')
    .style('display', 'none')
    .style('left', '1px')
    .style('top', '1px')
    .style('border-radius', '5px')
    .style('border', '1px solid #000000')
    .style('padding', '8px')
    .style('background-color', 'rgba(255, 255, 255, 0.9)');
  // D3 SVG CREATION STARTING
  const svg = d3
    .select(`#${id}`)
    .append('svg')
    .attr('class', 'bubbleChartSvg')
    .attr('width', width + margin.left + margin.right)
    .attr('height', height + margin.top + margin.bottom);
    // .on("mouseout", () => { setBallrFlag(false) });

  const chart = svg
    .append('g')
    .attr('transform', `translate(${margin.left},${margin.top})`);

  // BACKGROUND RECTANGLE
  chart
    .append('rect')
    .attr('x', xAxis(-maxXAxisValue))
    .attr('y', yAxis(0))
    .attr('width', xAxis(0))
    .attr('height', yAxis(90))
    .style('opacity', 1.0) // If stoke is applied reduce the width by half of the stroke
    .style('fill', '#f7f7f7');

  // Add X axis
  chart
    .append('g')
    .attr('class', 'axisGrey')
    .attr('transform', `translate(0,${yLabel === "Induced V Break" ? height : 0})`)
    .call(yLabel === "Induced V Break" ? constant.softball ? d3.axisBottom(xAxis).ticks(5).tickFormat(formatXDegrees) : d3.axisBottom(xAxis).tickFormat(formatXDegrees) : constant.softball ? d3.axisTop(xAxis).ticks(5).tickFormat(formatXDegrees) : d3.axisTop(xAxis).tickFormat(formatXDegrees));

  chart
    .append('text')
    .attr('transform', `translate(${width / 2} , ${yLabel === "Induced V Break" ? height + margin.top + 15 : -30})`)
    .style('text-anchor', 'middle')
    .style('font-size', '14px')
    .attr('class', 'axisLabel')
    .text(xLabel);

  // Add Y axis
  chart
    .append('g')
    .attr('class', 'axisGrey')
    .call(constant.softball ? d3.axisLeft(yAxis).ticks(5).tickFormat(formatYDegrees) : d3.axisLeft(yAxis).tickFormat(formatYDegrees));

  chart
    .append('text')
    .attr('transform', 'rotate(-90)')
    .attr('y', 0 - margin.left - 4)
    .attr('x', 0 - height / 2)
    .attr('dy', '1em')
    .style('text-anchor', 'middle')
    .style('font-size', '14px')
    .attr('class', 'axisLabel')
    .text(yLabel);

  chart.selectAll('ellipse').data(data);
  chart.selectAll('ellipse').attr('r', d => d.y1);

  // add light grey line
  chart
    .append('line')
    .attr('x1', xAxis(-maxXAxisValue))
    .attr('y1', yAxis(0))
    .attr('x2', xAxis(maxXAxisValue))
    .attr('y2', yAxis(0))
    .attr('stroke-width', 1)
    .attr('stroke', '#e5e5e5');

  chart
    .append('line')
    .attr('x1', xAxis(0))
    .attr('y1', yAxis(yAxisDomain[0]))
    .attr('x2', xAxis(0))
    .attr('y2', yAxis(yAxisDomain[1]))
    .attr('stroke-width', 1)
    .attr('stroke', '#e5e5e5');

  // Draw the Ellipse
  chart
    .selectAll('ellipse')
    .data(data)
    .enter()
    .append('ellipse')
    .attr('class', 'oval')
    .attr('cx', d => xAxis(findCenter(d.x1, d.x2)))
    .attr('cy', d => yAxis(findCenter(d.y1, d.y2)))
    .attr('rx', d => {
      const radiusXAxis =
        Math.abs(xAxis(d.x2) - xAxis(d.x1)) / 2 - strokeWidth / 2;
      if (radiusXAxis < 1) {
        return 2.5;
      }
      return radiusXAxis;
    })
    .attr('ry', d => {
      const radiusYAxis =
        Math.abs(yAxis(d.y2) - yAxis(d.y1)) / 2 - strokeWidth / 2;
      if (radiusYAxis < 1) {
        return 2.5;
      }
      return radiusYAxis;
    })
    .attr('stroke', d => d.color)
    .attr('stroke-width', strokeWidth)
    .style('fill-opacity', 0.4)
    .style('opacity', 0.8)
    .style('fill', d => d.color)
    .on('mouseover', (d, i) => {
      // tooltipData = d;
      setTooltipData(d)
      Clusters !== "Clusters" && setBallrFlag(true)
      // Clusters !== "Clusters" && ballrFlag && refview.current.GiveKeyToiframe(d);
      tooltip.transition().duration(0);
      tooltip.style('display', 'block');
      tooltip.style('border', `1px solid ${d.color}`);
      // tooltip.style('visibility', 'visible');
      tooltip
        .html(
          `<b>
            <small style="color: ${d.color}">${d.pitch_type}</small><br/>
            <small>Pitch No: ${d.hasOwnProperty('pitch_number') ? d.pitch_number !== null ? d.pitch_number : "-" : "-" || "-"}</small><br/>
            <small>Pitcher: ${d.hasOwnProperty('pitcher') ? d.pitcher !== null ? d.pitcher : "-" : "-" || "-"}</small><br/>
            <small>Batter: ${d.hasOwnProperty('batter') ? d.batter !== null ? d.batter : "-" : "-" || "-"}</small><br/>
            ${Clusters !== "Clusters" ? `<small>Result: ${d.hasOwnProperty('pitch_call') ? d.pitch_call !== null ? d.pitch_call : "-" : "-" || "-"}</small><br/>` : ""}
            <small>Velocity: ${d.hasOwnProperty('velocity') ? d.velocity !== null ? d.velocity.toString().includes(".") ? d.velocity.toFixed(1) : d.velocity : "-" : "-" || "-"}</small><br/>
            <small>Spin Rate: ${d.hasOwnProperty('spin_rate') ? d.spin_rate !== null ? Math.round(d.spin_rate) : "-" : "-" || "-"}</small><br/>
            <small>Tilt: ${d.hasOwnProperty('spin_tilt') ? d.spin_tilt !== null ? d.spin_tilt : "-" : "-" || "-"}</small><br/>
            <small>Axis: ${d.hasOwnProperty('spin_axis') ? d.spin_axis !== null ? Math.round(d.spin_axis) : "-" : "-" || "-"}</small><br/>
            <small>Efficiency: ${d.hasOwnProperty('efficiency') ? d.efficiency !== null ? Math.round(d.efficiency) + "%" : "-" : "-" || "-"}</small><br/>
            <small>Vert Break: ${d.hasOwnProperty('v_break') ? d.v_break !== null ? d.v_break.toString().includes(".") ? d.v_break.toFixed(1) : d.v_break : "-" : "-" || "-"}</small><br/>
            <small>Ind V Break: ${d.hasOwnProperty('ind_v_break') ? d.ind_v_break !== null ? d.ind_v_break.toString().includes(".") ? d.ind_v_break.toFixed(1) : d.ind_v_break : "-" : "-" || "-"}</small><br/>
            <small>H Break: ${d.hasOwnProperty('h_break') ? d.h_break !== null ? d.h_break.toString().includes(".") ? d.h_break.toFixed(1) : d.h_break : "-" : "-" || "-"}</small><br/>
            <small>V Approach: ${d.hasOwnProperty('vert_appr_angle') ? d.vert_appr_angle !== null ? d.vert_appr_angle.toString().includes(".") ? d.vert_appr_angle.toFixed(1) : d.vert_appr_angle : "-" : "-" || "-"}</small><br/>
            <small>Rel Height: ${d.hasOwnProperty('release_height') ? d.release_height !== null ? d.release_height.toString().includes(".") ? d.release_height.toFixed(1) : d.release_height : "-" : "-" || "-"}</small><br/>
            <small>Rel Side: ${d.hasOwnProperty('release_side') ? d.release_side !== null ? d.release_side.toString().includes(".") ? d.release_side.toFixed(1) : d.release_side : "-" : "-" || "-"}</small><br/>
            <small>Extension: ${d.hasOwnProperty('ext') ? d.ext !== null ? d.ext.toString().includes(".") ? d.ext.toFixed(1) : d.ext : "-" : "-" || "-"}</small><br/>
            <small>Exit Velocity: ${d.hasOwnProperty('exit_velocity') ? d.exit_velocity !== null ? d.exit_velocity.toString().includes(".") ? d.exit_velocity.toFixed(1) : d.exit_velocity : "-" : "-" || "-"}</small><br/>
            <small>Launch Angle: ${d.hasOwnProperty('launch_angle') ? d.launch_angle !== null ? d.launch_angle.toString().includes(".") ? d.launch_angle.toFixed(1) : d.launch_angle : "-" : "-" || "-"}</small><br/>
            <small>Distance: ${d.hasOwnProperty('distance') ? d.distance !== null ? Math.round(d.distance) : "-" : "-" || "-"}</small>
            <!-- <small>Velocity: ${d.velocity?.toFixed(2) || "-"}</small><br/>
            <small>Spin Rate: ${d.spin_rate?.toFixed(2) || "-"}</small><br/>
            <small>Ind V Break: ${d.ind_v_break?.toFixed(2) || "-"}</small><br/>
            <small>H Break: ${d.h_break?.toFixed(2) || "-"}</small> -->
            <!-- ${Clusters !== "Clusters" ? `<div style="color: #FF0000" onmouseover="BreakMovementLoadBallR()"><small>BallR</small></div>` : ""} -->
          </b>`,
        )
        .style('left', `${d3.event.pageX}px`)
        .style('top', `${d3.event.pageY - 60}px`);
    })
    .on('mousemove', function () {
      return tooltip
        .style('top', d3.event.pageY - 60 + 'px')
        .style('left', d3.event.pageX + 15 + 'px');
    })
    .on('mouseout', function (d, i) {
      tooltip.transition()
        .delay(500)
        .style('display', 'none');
    })
    // .on('mouseout', () => tooltip.style('visibility', 'hidden'));
  // Draw Ellipse center
  chart
    .selectAll('ovalCenter')
    .data(data)
    .enter()
    .append('ellipse')
    .attr('class', 'ovalCenter')
    .attr('cx', d => xAxis(findCenter(d.x1, d.x2)))
    .attr('cy', d => yAxis(findCenter(d.y1, d.y2)))
    .attr('rx', d => innerCircleRadius(d))
    .attr('ry', d => innerCircleRadius(d))
    .style('opacity', 0.8)
    .style('fill', d => d.color)
    .on('mouseover', (d, i) => {
      // tooltipData = d;
      setTooltipData(d)
      Clusters !== "Clusters" && setBallrFlag(true)
      // Clusters !== "Clusters" && ballrFlag && refview.current.GiveKeyToiframe(d);
      tooltip.transition().duration(0);
      tooltip.style('display', 'block');
      tooltip.style('border', `1px solid ${d.color}`);
      // tooltip.style('visibility', 'visible');
      tooltip
        .html(
          `<b>
            <small style="color: ${d.color}">${d.pitch_type}</small><br/>
            <small>Pitch No: ${d.hasOwnProperty('pitch_number') ? d.pitch_number !== null ? d.pitch_number : "-" : "-" || "-"}</small><br/>
            <small>Pitcher: ${d.hasOwnProperty('pitcher') ? d.pitcher !== null ? d.pitcher : "-" : "-" || "-"}</small><br/>
            <small>Batter: ${d.hasOwnProperty('batter') ? d.batter !== null ? d.batter : "-" : "-" || "-"}</small><br/>
            ${Clusters !== "Clusters" ? `<small>Result: ${d.hasOwnProperty('pitch_call') ? d.pitch_call !== null ? d.pitch_call : "-" : "-" || "-"}</small><br/>` : ""}
            <small>Velocity: ${d.hasOwnProperty('velocity') ? d.velocity !== null ? d.velocity.toString().includes(".") ? d.velocity.toFixed(1) : d.velocity : "-" : "-" || "-"}</small><br/>
            <small>Spin Rate: ${d.hasOwnProperty('spin_rate') ? d.spin_rate !== null ? Math.round(d.spin_rate) : "-" : "-" || "-"}</small><br/>
            <small>Tilt: ${d.hasOwnProperty('spin_tilt') ? d.spin_tilt !== null ? d.spin_tilt : "-" : "-" || "-"}</small><br/>
            <small>Axis: ${d.hasOwnProperty('spin_axis') ? d.spin_axis !== null ? Math.round(d.spin_axis) : "-" : "-" || "-"}</small><br/>
            <small>Efficiency: ${d.hasOwnProperty('efficiency') ? d.efficiency !== null ? Math.round(d.efficiency) + "%" : "-" : "-" || "-"}</small><br/>
            <small>Vert Break: ${d.hasOwnProperty('v_break') ? d.v_break !== null ? d.v_break.toString().includes(".") ? d.v_break.toFixed(1) : d.v_break : "-" : "-" || "-"}</small><br/>
            <small>Ind V Break: ${d.hasOwnProperty('ind_v_break') ? d.ind_v_break !== null ? d.ind_v_break.toString().includes(".") ? d.ind_v_break.toFixed(1) : d.ind_v_break : "-" : "-" || "-"}</small><br/>
            <small>H Break: ${d.hasOwnProperty('h_break') ? d.h_break !== null ? d.h_break.toString().includes(".") ? d.h_break.toFixed(1) : d.h_break : "-" : "-" || "-"}</small><br/>
            <small>V Approach: ${d.hasOwnProperty('vert_appr_angle') ? d.vert_appr_angle !== null ? d.vert_appr_angle.toString().includes(".") ? d.vert_appr_angle.toFixed(1) : d.vert_appr_angle : "-" : "-" || "-"}</small><br/>
            <small>Rel Height: ${d.hasOwnProperty('release_height') ? d.release_height !== null ? d.release_height.toString().includes(".") ? d.release_height.toFixed(1) : d.release_height : "-" : "-" || "-"}</small><br/>
            <small>Rel Side: ${d.hasOwnProperty('release_side') ? d.release_side !== null ? d.release_side.toString().includes(".") ? d.release_side.toFixed(1) : d.release_side : "-" : "-" || "-"}</small><br/>
            <small>Extension: ${d.hasOwnProperty('ext') ? d.ext !== null ? d.ext.toString().includes(".") ? d.ext.toFixed(1) : d.ext : "-" : "-" || "-"}</small><br/>
            <small>Exit Velocity: ${d.hasOwnProperty('exit_velocity') ? d.exit_velocity !== null ? d.exit_velocity.toString().includes(".") ? d.exit_velocity.toFixed(1) : d.exit_velocity : "-" : "-" || "-"}</small><br/>
            <small>Launch Angle: ${d.hasOwnProperty('launch_angle') ? d.launch_angle !== null ? d.launch_angle.toString().includes(".") ? d.launch_angle.toFixed(1) : d.launch_angle : "-" : "-" || "-"}</small><br/>
            <small>Distance: ${d.hasOwnProperty('distance') ? d.distance !== null ? Math.round(d.distance) : "-" : "-" || "-"}</small>
            <!-- <small>Velocity: ${d.velocity?.toFixed(2) || "-"}</small><br/>
            <small>Spin Rate: ${d.spin_rate?.toFixed(2) || "-"}</small><br/>
            <small>Ind V Break: ${d.ind_v_break?.toFixed(2) || "-"}</small><br/>
            <small>H Break: ${d.h_break?.toFixed(2) || "-"}</small> -->
            <!-- ${Clusters !== "Clusters" ? `<div style="color: #FF0000" onmouseover="BreakMovementLoadBallR()"><small>BallR</small></div>` : ""} -->
          </b>`,
        )
        .style('left', `${d3.event.pageX}px`)
        .style('top', `${d3.event.pageY - 60}px`);
    })
    .on('mousemove', function () {
      return tooltip
        .style('top', d3.event.pageY - 60 + 'px')
        .style('left', d3.event.pageX + 15 + 'px');
    })
    .on('mouseout', function (d, i) {
      tooltip.transition()
        .delay(500)
        .style('display', 'none');
    })
    // .on('mouseout', () => tooltip.style('visibility', 'hidden'));
};

export default BreakMovementChart;
