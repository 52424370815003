import React, { Component } from "react";
import Header from "../../containers/header";
import Footer from "../../containers/footer";
import Hittersummarysidebar from "../../containers/hittersummarysidebar";
import Teamsummary from "./../teamsummary/teamsummary";
import Development from "./../development/development";
import { imagesArr } from "../../assets/images";

import { getHitterSummaryData } from "../../store/actions";
import { connect } from "react-redux";
import Ryansmmons from "./component/ryansmmons";
import { printToPDFMultiple } from "../../shared/printReportUtils";
import HitterSummaryTable from "./component/hitterSummary";
import Loader from "../../shared/loader";

class hittersummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: "",
      battedBell: [],
      hardestBall: [],
      farthestBall: [],
      bpTable: [],
      sprayChartData: [],
      heatMapChartData: [],
      battedBallTable: [],
      isHitterSummay: true,
      keyName: "hittersummary",
      hitterIndex: null,
      isPrint: false,
      printAll: false,
      heat_map: "Avg EV",
      sessionAvg: [],
      isRender:false,
      pitchrIframeFlag: "",
      isDownloadImg: false
    };
  }

  componentDidMount() {
    let sorceid = document.location.href.split("/")[4];
    if (sorceid === undefined) window.location.href = "/";
    else this.summaryData(this.state.heat_map,false);
  }

  // fetch summary data
  summaryData = (heat_map,isRender) => {
    let data = {
      sourceId: document.location.href.split("/")[4],
      tab: "hitter_summary",
      heat_map: heat_map
    };
    this.props.fetchSummaryData(data, (res) => {
      let groupedPitcher = this.groupArrayOfObjects(
        res.data.result.spray_chart_data,
        "batter_id"
      );
      this.setState({
        battedBell: res.data.result.first_hitter_summary_table_data,
        hardestBall: res.data.result.second_hitter_summary_table_data,
        farthestBall: res.data.result.third_hitter_summary_table_data,
        bpTable: res.data.result.bp_table_data,
        sprayChartData: groupedPitcher,
        heatMapChartData: res.data.result.batted_ball_heat_maps,
        battedBallTable: res.data.result.batted_ball_table,
        keyName: this.state.keyName,
        isRender:isRender,
        eventName: res.data?.eventDetails?.event?.event_name
      });
    });
  };

  groupArrayOfObjects(list, key) {
    return list.reduce(function (rv, x) {
      if (x["batter_id"]) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
      }
      return rv;
    }, {});
  }

  handleCallback = (index) => {
    this.setState({
      tabs: index,
    });
  };

  handleSidbar = (keyName, isPitcher, index) => {
    this.setState({
      sessionAvg: [],
    }, () => {
      this.setState((prevState) => ({
        ...prevState,
        keyName: keyName,
        isHitterSummay: isPitcher,
        hitterIndex: index,
        sessionAvg: [...this.state.sessionAvg, this.state.bpTable[index], this.state.bpTable[this.state.bpTable.length - 1]],
        heat_map: "Avg EV",
        pitchrIframeFlag: "yes"
      }), () => {
        this.summaryData(this.state.heat_map,true)
      });
    })
  };

  print = async () => {
    this.setState({ isPrint: true });
    setTimeout(async () => {
      await printToPDFMultiple(`hitterSummary`, ["hitterSummaryPrintArea"]);
      this.setState({ isPrint: false });
    }, 2000);
  };

  printChart = async () => {
    this.setState({ isPrint: true });
    setTimeout(async () => {
      await printToPDFMultiple(`hitterSummary`, [
        "hitterSummaryChartPrintArea",
      ]);
      this.setState({ isPrint: false });
    }, 2000);
  };

  setLoader = (flg) => {
    this.props.startLoader(flg);
  };

  printAll = () => {
    this.setState({ printAll: true });
    let arr = this.state.bpTable.map((data) => { if (data._id !== "Session Average") return data._id })
    setTimeout(async () => {
      await printToPDFMultiple(`battingPracticeType`, [
        "hitter-summary",
        ...arr.filter(e => e)
      ]);
      this.setState({ printAll: false });
    }, 2000);
  };
  
  onChangeHeatMap = (e) => {
    const { name, value } = e.target
    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
    }), () => {
      this.summaryData(this.state.heat_map,true);
    });
  }

  hitterMergeAvg = (data) => {
    return [data, this.state.bpTable[this.state.bpTable.length - 1]]
  }

  getActive = () => {
    if (this.state.printAll) {
      return (
        <>
          <div className="main-div">
            {this.state.printAll && <Loader />}
            <section className="hittersummary-section" id="hitter-summary">
              <HitterSummaryTable
                print={() => this.print()}
                printAll={() => this.printAll()}
                isPrint={this.state.printAll}
                battedBell={this.state.battedBell}
                hardestBall={this.state.hardestBall}
                farthestBall={this.state.farthestBall}
              />
            </section>
            {this.state.bpTable.length !== 0 &&
              this.state.bpTable.map((data, index) => {
                return (
                  data._id !== "Session Average" &&
                  <section className="ryansmmons-section" id={data._id}>
                    <div className="container-fluid">
                      <div className="common-title">
                        <h2>{data.full_name}</h2>
                        {!this.state.printAll && (
                          <div className="dropdown">
                            <span className="printer">
                              <img src={imagesArr.printer} alt="print" />
                            </span>
                            <div className="dropdown-content">
                              <ul>
                                <li onClick={() => this.printChart()}>Print</li>
                                <li onClick={() => this.printAll()}>
                                  Print All
                                </li>
                              </ul>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <Ryansmmons
                      bpTable={this.hitterMergeAvg(data)}
                      index={index}
                      sprayChartData={this.state.sprayChartData}
                      heatMapChartData={this.state.heatMapChartData}
                      battedBallTable={this.state.battedBallTable}
                      heat_map={this.state.heat_map}
                      isRender={this.state.isRender}
                      notRender={this.notRender}
                      keyName={data.full_name}
                      setLoader={this.setLoader}
                      onChangeHeatMap={this.onChangeHeatMap}
                      pitchrIframeFlag={this.state.pitchrIframeFlag}
                    />
                  </section>
                );
              })}
          </div>
        </>
      );
    }
    if (this.state.isHitterSummay) {
      return (
        <div className="main-div">
          {this.state.isPrint && <Loader />}
          <section className="hittersummary-section">
            <HitterSummaryTable
              print={() => this.print()}
              printAll={() => this.printAll()}
              isPrint={this.state.isPrint}
              battedBell={this.state.battedBell}
              hardestBall={this.state.hardestBall}
              farthestBall={this.state.farthestBall}
            />
          </section>
        </div>
      );
    } else {
      return (
        <div className="main-div">
          {this.state.isPrint && <Loader />}
          {this.state.isDownloadImg && <Loader text={true}/>}
          <section className="ryansmmons-section">
            <div className="container-fluid">
              <div className="common-title">
                <h2>{this.state.bpTable[this.state.hitterIndex].full_name}</h2>
                {!this.state.isPrint && (
                  <div className="dropdown">
                    <span className="printer">
                      <img src={imagesArr.printer} alt="print" />
                    </span>
                    <div className="dropdown-content">
                      <ul>
                        <li onClick={() => this.printChart()}>Print</li>
                        <li onClick={() => this.printAll()}>Print All</li>
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <Ryansmmons
              bpTable={this.state.sessionAvg}
              sprayChartData={this.state.sprayChartData}
              heatMapChartData={this.state.heatMapChartData}
              battedBallTable={this.state.battedBallTable}
              heat_map={this.state.heat_map}
              isRender={this.state.isRender}
              notRender={this.notRender}
              keyName={this.state.keyName}
              setLoader={this.setLoader}
              onChangeHeatMap={this.onChangeHeatMap}
              pitchrIframeFlag={this.state.pitchrIframeFlag}
              closePitchrIframe={() => this.setState((prevState) => ({ ...prevState, pitchrIframeFlag: "" }))}
              isNotDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: false }))}
              isDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: true }))}
              eventName={this.state.eventName}
            />
          </section>
        </div>
      );
    }
  };

  notRender = () =>{
    this.setState((prevState)=>({
      ...prevState,
      isRender:false
    }))
  }
  render() {
    const tabs = this.state.tabs;
    return (
      <>
        <div className="d-flex hittersummary-sidebar">
          {/* {tabs !== 0 && tabs !== 1 && this.state.bpTable.length !== 0 && (
            <Hittersummarysidebar
              changeside={this.handleSidbar}
              sideData={this.state.bpTable}
              keyName={this.state.keyName}
            />
          )}
          {tabs !== 0 && tabs !== 1 && this.state.bpTable.length === 0 && (
            <Hittersummarysidebar
              changeside={this.handleSidbar}
              sideData={this.state.bpTable}
              keyName={this.state.keyName}
            />
          )} */}
          <div
            className={`page-content-wrapper ${tabs === 0 || tabs === 1 ? "tabactive" : ""
              }`}
          >
            <Header parentCallback={this.handleCallback} />
            {tabs === 0 && <Teamsummary />}
            {tabs === 1 && <Development />}
            {tabs !== 0 && tabs !== 1 && (
              <>
                <div className="sidebar-content">
                  {
                    this.state.bpTable.length !== 0 && (
                      <Hittersummarysidebar
                        changeside={this.handleSidbar}
                        sideData={this.state.bpTable}
                        keyName={this.state.keyName}
                      />
                    )}
                  {
                    this.state.bpTable.length === 0 && (
                      <Hittersummarysidebar
                        changeside={this.handleSidbar}
                        sideData={this.state.bpTable}
                        keyName={this.state.keyName}
                      />
                    )}
                  <div className="content">{this.getActive()}</div>
                </div>
              </>
            )}
          </div>
          {/* <Footer /> */}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    hitterSummaryData: state.hitterSummaryData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchSummaryData: (data, next) => dispatch(getHitterSummaryData(data, next)),
  startLoader: (data) => dispatch({ type: "LOADING", payload: data }),
});
export default connect(mapStateToProps, mapDispatchToProps)(hittersummary);
