import React, { useState } from 'react'
import { imagesArr } from '../assets/images'
import { constant } from './constant'
import AppliedFilter from '../../src/view/profiledashboard/common/appliedFilter'

function PrintImagePopup({ eventProfileData, id, sendReq, tabslist, profilepage, eventName, pitcherDefault, title }) {
    return (
        <>
            <div className="details">
                <div className="printpagemain" id={`printImgmain${id}`}>
                    {profilepage ?
                        <>
                        <div className="profile-logo">
                            {eventProfileData && (<><h3>{eventProfileData?.fname + " " + eventProfileData?.lname}</h3>
                                <img
                                    src={
                                        !eventProfileData.profileUrl.includes("user-default-icon.png")
                                            ? `${eventProfileData?.profileUrl}?v=${new Date().getTime()}`
                                            : `${JSON.parse(localStorage.getItem("teamData")).hasOwnProperty("teamLogo")}?v=${new Date().getTime()}`
                                                ? `${JSON.parse(localStorage.getItem("teamData")).teamLogo?.filePath}?v=${new Date().getTime()}`
                                                    ? `${JSON.parse(localStorage.getItem("teamData")).teamLogo?.filePath}?v=${new Date().getTime()}` : "" : ""
                                    }
                                    alt="profilelogo"
                                />
                            </>)}

                        </div>
                            <AppliedFilter
                                sendReq={sendReq}
                                tabslist={tabslist}
                                isPrinting={true} />
                        </> :
                        <>
                            {pitcherDefault && <h3>{pitcherDefault}</h3>}
                            {eventName && <h5>{eventName}</h5>}
                        </>}
                    {title && <h5>{title}</h5>}
                    <div id={`printImg${id}`}></div>
                    <img src={constant.softball ? imagesArr.sc_print : imagesArr.bc_print} className="logo" />
                </div>
            </div>
        </>
    )
}

export default PrintImagePopup
