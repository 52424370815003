import React, { Component, forwardRef } from 'react';
import { imagesArr } from "../../../assets/images";
import moment from "moment";
import DatePicker from "react-datepicker";
import SliderRange from "rc-slider";
import range from "../../profiledashboard/common/range.json";
import { constant } from '../../../shared/constant';

const { createSliderWithTooltip } = SliderRange;
const Range = createSliderWithTooltip(SliderRange.Range);

class filterForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openFcal: false,
            openTcal: false,
        };
    }

    handleDateChangeRaw = (e) => {
        e.preventDefault();
    };

    render() {
        const CustomInputFrom = forwardRef(({ value, onClick }, ref) => (
            <button className="form-control custom-date" onClick={onClick} ref={ref}>
                {value ? value : "From"}
            </button>
        ));

        const CustomInputTo = forwardRef(({ value, onClick }, ref) => (
            <button className="form-control custom-date" onClick={onClick} ref={ref}>
                {value ? value : "To"}
            </button>
        ));

        const { summaryData, seasonList, minDate, maxDate, bbeMax } = this.props;

        const years = Array.from(
            new Array(81),
            (val, index) => new Date().getFullYear() + 1 - index
        );
        const months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];
        return (
            <div className="filtersection">
                <div className="white-bg filterinfo">
                    <div className="select_box row">
                        <div className="select_box_inner w-25">
                            <label className="common-lable">Year</label>
                            <select
                                name="season"
                                value={summaryData.season}
                                className="custom-select"
                                onChange={this.props.handleChange.bind(this)}
                            >
                                <option value="" disabled>No Selected</option>
                                {
                                    seasonList &&
                                    seasonList.map((opt, i) => {
                                        return (
                                            <option value={opt.value}>{opt.label}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        {summaryData.tab !== "hitters_blast_motion" && <div className="select_box_inner w-25">
                            <label className="common-lable">
                                Fall/Spring
                            </label>
                            <select
                                name="fall_spring"
                                value={summaryData.fall_spring}
                                className="custom-select"
                                onChange={this.props.handleChange.bind(this)}
                            >
                                <option value="" disabled>No selected</option>
                                <option value="fall">Fall</option>
                                <option value="spring">Spring</option>
                                <option value="both">Both</option>
                            </select>
                        </div>}
                        <div className="select_box_inner w-50">
                            <label className="common-lable">
                                Date Range
                            </label>
                            <div className="d-inline-flex">
                                <div className="w-100 mr-15">
                                    <div className="form-group mb-0 d-grid">
                                        <div className="bc_relative">
                                            <DatePicker
                                                ref={(c) => (this._fcal = c)}
                                                renderCustomHeader={({
                                                    date,
                                                    changeYear,
                                                    changeMonth,
                                                    decreaseMonth,
                                                    increaseMonth,
                                                    prevMonthButtonDisabled,
                                                    nextMonthButtonDisabled,
                                                }) => (
                                                    <div
                                                        style={{
                                                            margin: 10,
                                                            display: "flex",
                                                            justifyContent: "center",
                                                        }}
                                                    >
                                                        <a
                                                            className="datearrow"
                                                            onClick={decreaseMonth}
                                                            disabled={
                                                                prevMonthButtonDisabled
                                                            }
                                                        >
                                                            {"<"}
                                                        </a>
                                                        <select
                                                            value={date.getFullYear()}
                                                            onChange={({
                                                                target: { value },
                                                            }) => changeYear(value)}
                                                        >
                                                            {years.map((option) => (
                                                                <option
                                                                    key={option}
                                                                    value={option}
                                                                >
                                                                    {option}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <select
                                                            value={months[date.getMonth()]}
                                                            onChange={({
                                                                target: { value },
                                                            }) =>
                                                                changeMonth(
                                                                    months.indexOf(value)
                                                                )
                                                            }
                                                        >
                                                            {months.map((option) => (
                                                                <option
                                                                    key={option}
                                                                    value={option}
                                                                >
                                                                    {option}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <a
                                                            className="datearrow"
                                                            onClick={increaseMonth}
                                                            disabled={
                                                                nextMonthButtonDisabled
                                                            }
                                                        >
                                                            {">"}
                                                        </a>
                                                    </div>
                                                )}
                                                selected={summaryData.date_from}
                                                minDate={moment(minDate).toDate()}
                                                maxDate={moment(maxDate).toDate()}
                                                onChangeRaw={(e) =>
                                                    this.handleDateChangeRaw(e)
                                                }
                                                onChange={(e) =>
                                                    this.props.handleDateChange(
                                                        e,
                                                        "date_from"
                                                    )
                                                }
                                                placeholderText="From"
                                                customInput={<CustomInputFrom />}
                                            />
                                            <img
                                                src={imagesArr.calendar}
                                                className="bc_datepicker-icon"
                                                alt="calendar"
                                                onClick={() => {
                                                    this.state.openFcal = !this.state.openFcal;
                                                    this._fcal.setOpen(this.state.openFcal);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="w-100">
                                    <div className="form-group mb-0 d-grid">
                                        <div className="bc_relative">
                                            <DatePicker
                                                ref={(c) => (this._tcal = c)}
                                                renderCustomHeader={({
                                                    date,
                                                    changeYear,
                                                    changeMonth,
                                                    decreaseMonth,
                                                    increaseMonth,
                                                    prevMonthButtonDisabled,
                                                    nextMonthButtonDisabled,
                                                }) => (
                                                    <div
                                                        style={{
                                                            margin: 10,
                                                            display: "flex",
                                                            justifyContent: "center",
                                                        }}
                                                    >
                                                        <a
                                                            className="datearrow"
                                                            onClick={decreaseMonth}
                                                            disabled={
                                                                prevMonthButtonDisabled
                                                            }
                                                        >
                                                            {"<"}
                                                        </a>
                                                        <select
                                                            value={date.getFullYear()}
                                                            onChange={({
                                                                target: { value },
                                                            }) => changeYear(value)}
                                                        >
                                                            {years.map((option) => (
                                                                <option
                                                                    key={option}
                                                                    value={option}
                                                                >
                                                                    {option}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <select
                                                            value={months[date.getMonth()]}
                                                            onChange={({
                                                                target: { value },
                                                            }) =>
                                                                changeMonth(
                                                                    months.indexOf(value)
                                                                )
                                                            }
                                                        >
                                                            {months.map((option) => (
                                                                <option
                                                                    key={option}
                                                                    value={option}
                                                                >
                                                                    {option}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <a
                                                            className="datearrow"
                                                            onClick={increaseMonth}
                                                            disabled={
                                                                nextMonthButtonDisabled
                                                            }
                                                        >
                                                            {">"}
                                                        </a>
                                                    </div>
                                                )}
                                                selected={summaryData.date_to}
                                                minDate={summaryData.date_from ? moment(summaryData.date_from)
                                                    .subtract(0, "days")
                                                    .toDate() : moment(minDate).toDate()}
                                                maxDate={moment(maxDate).toDate()}
                                                onChangeRaw={(e) =>
                                                    this.handleDateChangeRaw(e)
                                                }
                                                onChange={(e) =>
                                                    this.props.handleDateChange(e, "date_to")
                                                }
                                                placeholderText="To"
                                                customInput={<CustomInputTo />}
                                            />
                                            <img
                                                src={imagesArr.calendar}
                                                className="bc_datepicker-icon"
                                                alt="calendar"
                                                onClick={() => {
                                                    this.state.openTcal = !this.state.openTcal;
                                                    this._tcal.setOpen(this.state.openTcal);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {summaryData.tab !== "hitters_blast_motion" && <div className="select_box_inner w-25">
                            <label className="common-lable">
                                Event Type
                            </label>
                            <select
                                name="event_type"
                                className="custom-select"
                                value={summaryData.event_type}
                                onChange={this.props.handleChange.bind(this)}
                            >
                                <option value="" disabled>No selected</option>
                                <option value="Game">Game</option>
                                <option value="Scrimmage">Scrimmage</option>
                                <option value="Bullpen">Bullpen</option>
                                <option value="BP">BP</option>
                            </select>
                        </div>}
                        {summaryData.tab !== "hitters_blast_motion" && <div className="select_box_inner w-25">
                            <label className="common-lable">
                                Pitch Type
                            </label>
                            <select
                                name="pitch_type"
                                value={summaryData.pitch_type}
                                className="custom-select"
                                onChange={this.props.handleChange.bind(this)}
                            >
                                {constant.softball ?
                                    <>
                                        <option selected value="">No selected</option>
                                        <option value="Fastball">Fastball</option>
                                        <option value="Drop Ball">Drop Ball</option>
                                        <option value="Rise Ball">Rise Ball</option>
                                        <option value="Curveball">Curveball</option>
                                        <option value="Screw Ball">Screw Ball</option>
                                        <option value="Changeup">Changeup</option>
                                        <option value="Drop Curve">Drop Curve</option>
                                    </> :
                                    <>
                                        <option selected value="">No selected</option>
                                        <option value="Fastball">Fastball</option>
                                        <option value="Curveball">Curveball</option>
                                        <option value="Slider">Slider</option>
                                        <option value="Changeup">Changeup</option>
                                        <option value="Sinker">Sinker</option>
                                        <option value="Cutter">Cutter</option>
                                        <option value="Splitter">Splitter</option>
                                        <option value="Knuckleball">Knuckleball</option>
                                        <option value="Fastballs">Fastballs</option>
                                        <option value="Breaking">Breaking</option>
                                        <option value="Offspeed">Offspeed</option>
                                    </>}
                            </select>
                        </div>}
                        {summaryData.tab !== "hitters_blast_motion" && <div className="select_box_inner w-25">
                            <label className="common-lable">Count</label>
                            <select
                                name="count"
                                value={summaryData.count}
                                className="custom-select"
                                onChange={this.props.handleChange.bind(this)}
                            >
                                <option value="" disabled>No selected</option>
                                <option value="Hitter's Count">
                                    Hitter's Count
                                </option>
                                <option value="Pitcher's Count">
                                    Pitcher's Count
                                </option>
                                <option value="Two Strike Counts">Two Strike Counts</option>
                                <option value="0-0">0-0</option>
                                <option value="0-1">0-1</option>
                                <option value="0-2">0-2</option>
                                <option value="1-0">1-0</option>
                                <option value="1-1">1-1</option>
                                <option value="1-2">1-2</option>
                                <option value="2-0">2-0</option>
                                <option value="2-1">2-1</option>
                                <option value="2-2">2-2</option>
                                <option value="3-0">3-0</option>
                                <option value="3-1">3-1</option>
                                <option value="3-2">3-2</option>
                            </select>
                        </div>}
                        {/* <div className="select_box_inner w-25">
                            <label className="common-lable">
                                Home/Away
                                </label>
                            <select
                                name="home_away"
                                value={summaryData.home_away}
                                className="custom-select"
                                onChange={this.props.handleChange.bind(this)}
                            >
                                <option value="">No selected</option>
                                <option value="Home">Home</option>
                                <option value="Away">Away</option>
                            </select>
                        </div> */}
                        {summaryData.tab !== "hitters_blast_motion" && <div className="select_box_inner w-25">
                            <label className="common-lable">Handedness</label>
                            <select
                                name="hand"
                                value={summaryData.hand}
                                className="custom-select"
                                onChange={this.props.handleChange.bind(this)}
                            >
                                <option value="" disabled>No selected</option>
                                <option value="Left">Vs Lefty</option>
                                <option value="Right">Vs Righty</option>
                            </select>
                        </div>}
                        {summaryData.tab !== "hitters_blast_motion" && <div className="select_box_inner w-25">
                            <label className="common-lable">
                                Batted Ball Events
                            </label>
                            <Range
                                min={0}
                                max={bbeMax ? bbeMax : summaryData.bbe_to}
                                value={[
                                    summaryData.bbe_from || 0,
                                    summaryData.bbe_to || 0,
                                ]}
                                defaultValue={[0, 0]}
                                tipFormatter={(value) => `${value}`}
                                onChange={this.props.handleRangeChange.bind(this)}
                            />
                        </div>}
                        {summaryData.tab !== "hitters_blast_motion" && range.map((r, i) => {
                            return (
                                <>
                                    <div className="select_box_inner w-25" key={i}>
                                        <label className="common-lable">
                                            {r.name}
                                        </label>
                                        <Range
                                            min={r.min}
                                            max={r.max}
                                            value={[
                                                summaryData[r.from] || 0,
                                                summaryData[r.to] || 0,
                                            ]}
                                            defaultValue={[0, 0]}
                                            tipFormatter={(value) => `${value}`}
                                            onChange={(e) =>
                                                this.props.handleRangeChangeCustom(e, r.from, r.to)
                                            }
                                        />
                                    </div>
                                </>
                            )
                        })}
                    </div>
                    <div className="d-flex align-items-center justify-content-center formbutton">
                        <button
                            className="btnshadow btn blue mr-15"
                            onClick={this.props.applyFilter}
                        >
                            Apply
                        </button>
                        <button
                            className="btn white"
                            onClick={this.props.handleCancelToggle}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default filterForm;
