import React, { useEffect, useRef, useState } from 'react';
import { constant } from '../../../shared/constant';
import { Table } from "antd";
import { createColoredColumnObjCustomScale, getSortedValues } from "../../../shared/function";
import VideoPopup from '../../../shared/VideoPopup';

function BattedBallTable({ battedBallTable }) {
    const wrapperRef = useRef(null);
    const [isVideoShow, setIsVideoShow] = useState({
        videoFlag: false,
        videoData: null
    })

    var allRows = [];
        battedBallTable && battedBallTable[0]?.data.length !== 0 &&
        battedBallTable[0]?.data.map((list, i) =>
         allRows.push({
           key: i,
           pitch_number: list.pitch_number && list.pitch_number ? Number(list.pitch_number.toFixed(1)) : "—",
        //    pitch_number: list.videoData.length ? <div className="table-video-icon"><span onClick={() => list.videoData.length !== 0 && videoPlay(list.videoData)}><i class="fa fa-video" aria-hidden="true"></i></span>{list.pitch_number && list.pitch_number ? Number(list.pitch_number.toFixed(1)) : "—"}</div> : list.pitch_number && list.pitch_number ? Number(list.pitch_number.toFixed(1)) : "—",
           exit_velocity: list.exit_velocity && list.exit_velocity ? Number(list.exit_velocity.toFixed(1)) : "—",
           launch_angle: list.launch_angle && list.launch_angle ? Number(list.launch_angle.toFixed(1)) : "—",
           distance: list.distance && list.distance ? Math.round(Number(list.distance)) : "—",
         }))

    const columns = [
        {
            title: 'Batted Balls',
            children: [
                {
                    title: "Pitch #",
                    dataIndex: "pitch_number",
                    sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "pitch_number"),
                    sortDirections: ['descend', 'ascend'],
                    className: "tablegraybp",
                },
                createColoredColumnObjCustomScale("EV", "exit_velocity", allRows, false, {
                     sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "exit_velocity"),
                     sortDirections: ['descend', 'ascend'],
                     minRange: constant.softball ? 40 : 65,
                     className: "tablegraybp",
                }),
                {
                    title: "LA",
                    dataIndex: "launch_angle",
                    sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "launch_angle"),
                    sortDirections: ['descend', 'ascend'],
                    className: "tablegraybp",
                },
                {
                    title: "Distance",
                    dataIndex: "distance",
                    sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "distance"),
                    sortDirections: ['descend', 'ascend'],
                    className: "tablegraybp",
                },
                // {
                //     title: "xBA",
                //     dataIndex: "avg_la",
                //     sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "avg_la"),
                //     sortDirections: ['descend', 'ascend'],
                //     className: "tablegraybp",
                // }
            ],
        }
      ]

      const data = allRows;

    // useEffect(() => {
    //     document.addEventListener("click", handleClickOutside, false);
    //     return () => {
    //         document.removeEventListener("click", handleClickOutside, false);
    //     };
    // }, []);

    // const videoPlay = (videoData) => {
    //     console.log('list.videoData', videoData)
    //     setIsVideoShow({
    //         videoFlag: true,
    //         videoData: videoData
    //     })
    // }

    // const handleClickOutside = event => {
    //     if (wrapperRef.current && wrapperRef.current.contains(event.target)) {
    //         setIsVideoShow({
    //             videoFlag: false,
    //             videoData: null
    //         })
    //     }
    // };

    return (
        <>
            <div className="table-responsive-xl">
                <table className="table with-border">
                    <Table
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                    />
                </table>
            </div>

            {/* popup design for video */}
            {/* <VideoPopup
                isVideoShow={isVideoShow}
                setIsVideoShow={setIsVideoShow}
                wrapperRef={wrapperRef} /> */}
        </>
    );
}

export default BattedBallTable;