import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { imagesArr } from "../assets/images";

class Scrimmagesummarysidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: "scrimmage summary",
      sourceID: document.location.href.split("/")[4] || "",
      currentRoute: document.location.href.split("/")[3] || "",
      sidebarLists: [
        {
          sidebarTitle: "summary",
          sidebarKey: "scrimmage_summary",
          route: "scrimmagesummary",
        },
        {
          sidebarTitle: "Pitch Log",
          sidebarKey: "pitch_log",
          route: "s_pitchlog",
        },
        {
          sidebarTitle: "Pitchers",
          sidebarKey: "",
          route: "s_pitchers",
        },
        { sidebarTitle: "Hitters", sidebarKey: "", route: "s_hitters" },
        {
          sidebarTitle: "Umpire Analysis",
          sidebarKey: "",
          route: "s_umpireanylysis",
        },
      ],
      teamInfo: {
        name: "",
        logo: ""
      }
    };
  }

  componentDidMount() {
    if (localStorage.getItem("teamData") !== null) {
      this.setState({
        teamInfo: {
          name: JSON.parse(localStorage.getItem("teamData")).name,
          // logo: JSON.parse(localStorage.getItem("teamData")).hasOwnProperty('teamLogo') ? JSON.parse(localStorage.getItem("teamData")).teamLogo.filePath : ""
        }
      })
    }
    this.props.appState.SpitcherfilterFlag = true;
    this.props.appState.SpitcherName = "";
    this.props.appState.ShitterfilterFlag = true;
    this.props.appState.ShitterName = "";

    // for pitchriframe load
    Object.keys(this.props.appState).filter(key => key.includes("iframe_")).map(key => {
      this.props.appState[key] = false;
    });
  }

  render() {
    const { teamInfo } = this.state
    return (
      <>
        {/* sidebar-wrapper */}
        <div className="sidebar">
          {/* <div className="sidebar-logo">
            <Link to={'/'}>
              <img src={imagesArr.logo} alt="logo" />
            </Link>
            <p>{teamInfo.name}</p>
          </div> */}
          <ul className="list-group">
            {this.state.sidebarLists.map((list, i) => {
              return (
                <li
                  key={i}
                  className={`${this.state.currentRoute === list.route && "active"
                    }`}
                >
                  <Link to={`/${list.route}/${this.state.sourceID}`}>
                    {list.sidebarTitle}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
        {/* sidebar-wrapper */}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    appState: state.app
  };
};

export default withRouter(connect(mapStateToProps)(Scrimmagesummarysidebar));
