import React from "react";
import { Link } from "react-router-dom";
import {
    createColoredColumnObj,
    stringValueSort,
    getSortedValues,
    stringValueSortByfixTeam,
} from "../../shared/function";
import { Table } from "antd";
import ReactTooltip from "react-tooltip";
import { constant } from "../../shared/constant";
import PrintTable from "./common/printTable";
import { DownOutlined, UpOutlined,RightOutlined } from "@ant-design/icons";

function BattedballTable({ tableData, title, isPrinting }) {
    var allRows = [];
    tableData &&
        tableData.map((list, i) =>
            allRows.push({
                key: i,
                HittersName: title === "pitcher" ? list?.pitcher_name : list?.batter_name,
                name: { batterName: title === "pitcher" ? list?.pitcher_name : list?.batter_name, batterId: list?._id, batterProfile: list?.profileUrl },
                bats: title === "pitcher" ? list?.throws || "—" : list?.bats || "—",
                bbe: list?.bbe || "—",
                avg_exit_velo: list.avg_exit_velo && list.avg_exit_velo ? Number(list.avg_exit_velo.toFixed(1)) : "—",
                peak_exit_velo: list.peak_exit_velo && list.peak_exit_velo ? Number(list.peak_exit_velo.toFixed(1)) : "—",
                hard_hit: list.hard_hit && list.hard_hit ? Number(list.hard_hit.toFixed(1)) : "—",
                damage: list.damage && list.damage ? Number(list.damage.toFixed(1)) : "—",
                avg_launch_angle: list.avg_launch_angle && list.avg_launch_angle ? Number(list.avg_launch_angle.toFixed(1)) : "—",
                avg_distance: list.avg_distance && list.avg_distance ? Math.round(list.avg_distance) : "—",
                gb: list.gb && list.gb ? Number(list.gb.toFixed(1)) : '—',
                ld: list.ld && list.ld ? Number(list.ld.toFixed(1)) : '—',
                fb: list.fb && list.fb ? Number(list.fb.toFixed(1)) : '—',
                pu: list.pu && list.pu ? Number(list.pu.toFixed(1)) : '—',
                pull: list.pull && list.pull ? Number(list.pull.toFixed(1)) : '—',
                middle: list.middle && list.middle ? Number(list.middle.toFixed(1)) : '—',
                oppo: list.oppo && list.oppo ? Number(list.oppo.toFixed(1)) : '—',
                avg_ev_to_pull: list.avg_ev_to_pull && list.avg_ev_to_pull ? Number(list.avg_ev_to_pull.toFixed(1)) : "—",
                avg_ev_to_middle: list.avg_ev_to_middle && list.avg_ev_to_middle ? Number(list.avg_ev_to_middle.toFixed(1)) : "—",
                avg_ev_to_oppo: list.avg_ev_to_oppo && list.avg_ev_to_oppo ? Number(list.avg_ev_to_oppo.toFixed(1)) : "—",
                avg_la_to_pull: list.avg_la_to_pull && list.avg_la_to_pull ? Number(list.avg_la_to_pull.toFixed(1)) : "—",
                avg_la_to_middle: list.avg_la_to_middle && list.avg_la_to_middle ? Number(list.avg_la_to_middle.toFixed(1)) : "—",
                avg_la_to_oppo: list.avg_la_to_oppo && list.avg_la_to_oppo ? Number(list.avg_la_to_oppo.toFixed(1)) : "—",
                pitch_data : list.pitch_data,
            })
        );

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            // sorter: (a, b) => stringValueSort(a.name, b.name),
            sorter: (a, b) => stringValueSort(a["name"]?.batterName, b["name"]?.batterName),
            sortDirections: ['descend', 'ascend'],
            fixed: isPrinting ? '' :'left',
            render: (text, record) => {
                return {
                    props: {
                        style: { background: record?.HittersName === "Team" ? "LightGray" : "" }
                    },
                    children: <>
                        {record.HittersName === "Team" ?
                            <div>{text.batterName}</div> :
                            <Link to={record?.HittersName === "Team" ? "" : `/profiledashboard/${text?.batterId}`}>
                                {/* <img src={record.batterProfile} alt="icon" className="d-inline icon" /> */}
                                {text?.batterName}
                            </Link>
                        }
                    </>
                }
            },
        },
        Table.EXPAND_COLUMN,
        {
            title: title === "pitcher" ? "Throws" : "Bats",
            dataIndex: "bats",
            sorter: (a, b) => stringValueSortByfixTeam(a, b, "bats"),
            sortDirections: ['descend', 'ascend'],
            render(text, record) {
                return {
                    props: {
                        style: { background: record.HittersName === "Team" ? "LightGray" : "" }
                    },
                    children: <div>{text}</div>
                };
            }
        },
        {
            title: "BBE",
            dataIndex: "bbe",
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "bbe"),
            sortDirections: ['descend', 'ascend'],
            render(text, record) {
                return {
                    props: {
                        style: { background: record.HittersName === "Team" ? "LightGray" : "" }
                    },
                    children: <div>{text}</div>
                };
            }
        },
        createColoredColumnObj("Avg EV", "avg_exit_velo", allRows, false, {
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "avg_exit_velo"),
            sortDirections: ['descend', 'ascend'],
        }),
        createColoredColumnObj("Peak EV", "peak_exit_velo", allRows, false, {
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "peak_exit_velo"),
            sortDirections: ['descend', 'ascend'],
        }),
        createColoredColumnObj(
            () => {
                return (
                    <>
                        <span data-tip={constant.softball ? "EV ≥ 65" : "EV ≥ 90"}>
                            Hard %
                        </span>
                    </>
                );
            }, "hard_hit", allRows, true, {
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "hard_hit"),
            sortDirections: ['descend', 'ascend'],
        }),
        createColoredColumnObj(
            () => {
                return (
                    <>
                        <span data-tip={constant.softball ? " EV ≥ 65,LA ≥ 10" : "EV ≥ 90,LA ≥ 10"}>
                            <ReactTooltip
                                className="rb-tool-tip"
                                place="top"
                                type="dark"
                                effect="solid"
                                offset={{ top: -8, left: 0 }}
                            />
                            Damage %
                        </span>
                    </>
                );
            },
            "damage",
            allRows,
            true,
            {
                sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "damage"),
                sortDirections: ['descend', 'ascend'],
            }
        ),
        {
            title: "Avg LA",
            dataIndex: "avg_launch_angle",
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "avg_launch_angle"),
            sortDirections: ['descend', 'ascend'],
            render(text, record) {
                return {
                    props: {
                        style: { background: record.HittersName === "Team" ? "LightGray" : "" }
                    },
                    children: <div>{text}</div>
                };
            }
        },
        createColoredColumnObj("Avg Dist", "avg_distance", allRows, false, {
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "avg_distance"),
            sortDirections: ['descend', 'ascend'],
        }),
        createColoredColumnObj("GB %", "gb", allRows, true, {
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "gb"),
            sortDirections: ['descend', 'ascend'],
        }),
        createColoredColumnObj("LD %", "ld", allRows, true, {
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "ld"),
            sortDirections: ['descend', 'ascend'],
        }),
        createColoredColumnObj("FB %", "fb", allRows, true, {
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "fb"),
            sortDirections: ['descend', 'ascend'],
        }),
        createColoredColumnObj("PU %", "pu", allRows, true, {
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "pu"),
            sortDirections: ['descend', 'ascend'],
        }),
        createColoredColumnObj("Pull %", "pull", allRows, true, {
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "pull"),
            sortDirections: ['descend', 'ascend'],
        }),
        createColoredColumnObj("Mid %", "middle", allRows, true, {
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "middle"),
            sortDirections: ['descend', 'ascend'],
        }),
        createColoredColumnObj("Oppo %", "oppo", allRows, true, {
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "oppo"),
            sortDirections: ['descend', 'ascend'],
        }),
        createColoredColumnObj("Pull EV", "avg_ev_to_pull", allRows, false, {
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "avg_ev_to_pull"),
            sortDirections: ['descend', 'ascend'],
        }),
        createColoredColumnObj("Mid EV", "avg_ev_to_middle", allRows, false, {
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "avg_ev_to_middle"),
            sortDirections: ['descend', 'ascend'],
        }),
        createColoredColumnObj("Oppo EV", "avg_ev_to_oppo", allRows, false, {
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "avg_ev_to_oppo"),
            sortDirections: ['descend', 'ascend'],
        }),
        {
            title: "Pull LA",
            dataIndex: "avg_la_to_pull",
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "avg_la_to_pull"),
            sortDirections: ['descend', 'ascend'],
            render(text, record) {
                return {
                    props: {
                        style: { background: record.HittersName === "Team" ? "LightGray" : "" }
                    },
                    children: <div>{text}</div>
                };
            }
        },
        {
            title: "Mid LA",
            dataIndex: "avg_la_to_middle",
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "avg_la_to_middle"),
            sortDirections: ['descend', 'ascend'],
            render(text, record) {
                return {
                    props: {
                        style: { background: record.HittersName === "Team" ? "LightGray" : "" }
                    },
                    children: <div>{text}</div>
                };
            }
        },
        {
            title: "Oppo LA",
            dataIndex: "avg_la_to_oppo",
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "avg_la_to_oppo"),
            sortDirections: ['descend', 'ascend'],
            render(text, record) {
                return {
                    props: {
                        style: { background: record.HittersName === "Team" ? "LightGray" : "" }
                    },
                    children: <div>{text}</div>
                };
            }
        },

    ];
    const data = allRows;
    const renderInnerRows = (row) => {
        var childRows = [];
        row?.pitch_data && row?.pitch_data.map((items, j) => {
            childRows.push({
                key: j,
                id: items._id,
                bats: title === "pitcher" ? items?.throws || "—" : items?.bats || "—",
                bbe: items?.bbe || "—",
                avg_exit_velo: items.avg_exit_velo && items.avg_exit_velo ? Number(items.avg_exit_velo.toFixed(1)) : "—",
                peak_exit_velo: items.peak_exit_velo && items.peak_exit_velo ? Number(items.peak_exit_velo.toFixed(1)) : "—",
                hard_hit: items.hard_hit && items.hard_hit ? Number(items.hard_hit.toFixed(1)) : "—",
                damage: items.damage && items.damage ? Number(items.damage.toFixed(1)) : "—",
                avg_launch_angle: items.avg_launch_angle && items.avg_launch_angle ? Number(items.avg_launch_angle.toFixed(1)) : "—",
                avg_distance: items.avg_distance && items.avg_distance ? Math.round(items.avg_distance) : "—",
                gb: items.gb && items.gb ? Number(items.gb.toFixed(1)) : '—',
                ld: items.ld && items.ld ? Number(items.ld.toFixed(1)) : '—',
                fb: items.fb && items.fb ? Number(items.fb.toFixed(1)) : '—',
                pu: items.pu && items.pu ? Number(items.pu.toFixed(1)) : '—',
                pull: items.pull && items.pull ? Number(items.pull.toFixed(1)) : '—',
                middle: items.middle && items.middle ? Number(items.middle.toFixed(1)) : '—',
                oppo: items.oppo && items.oppo ? Number(items.oppo.toFixed(1)) : '—',
                avg_ev_to_pull: items.avg_ev_to_pull && items.avg_ev_to_pull ? Number(items.avg_ev_to_pull.toFixed(1)) : "—",
                avg_ev_to_middle: items.avg_ev_to_middle && items.avg_ev_to_middle ? Number(items.avg_ev_to_middle.toFixed(1)) : "—",
                avg_ev_to_oppo: items.avg_ev_to_oppo && items.avg_ev_to_oppo ? Number(items.avg_ev_to_oppo.toFixed(1)) : "—",
                avg_la_to_pull: items.avg_la_to_pull && items.avg_la_to_pull ? Number(items.avg_la_to_pull.toFixed(1)) : "—",
                avg_la_to_middle: items.avg_la_to_middle && items.avg_la_to_middle ? Number(items.avg_la_to_middle.toFixed(1)) : "—",
                avg_la_to_oppo: items.avg_la_to_oppo && items.avg_la_to_oppo ? Number(items.avg_la_to_oppo.toFixed(1)) : "—",
            })
        })

        const inner_columns = [
            {
                title: "PitchType",
                dataIndex: "id",
                render(text, record) {
                    return {
                      props: {
                        style: { color: constant.pitchTypeColor[text] }
                      },
                      children: <div>{text}</div>
                    };
                  }
            },
            {
                title: "",
                dataIndex: "",
            },
            {
                title: title === "pitcher" ? "Throws" : "Bats",
                dataIndex: title === "pitcher" ? "" : "bats",
            },
            {
                title: "BBE",
                dataIndex: "bbe",
            },
            createColoredColumnObj("Avg EV", "avg_exit_velo", allRows, false, {
                sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "avg_exit_velo"),
                sortDirections: ['descend', 'ascend'],
            }),
            createColoredColumnObj("Peak EV", "peak_exit_velo", allRows, false, {
                sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "peak_exit_velo"),
                sortDirections: ['descend', 'ascend'],
            }),
            createColoredColumnObj(
                () => {
                    return (
                        <>
                            <span data-tip={constant.softball ? "EV ≥ 65" : "EV ≥ 90"}>
                                Hard %
                            </span>
                        </>
                    );
                }, "hard_hit", allRows, true, {
                sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "hard_hit"),
                sortDirections: ['descend', 'ascend'],
            }),
            createColoredColumnObj(
                () => {
                    return (
                        <>
                            <span data-tip={constant.softball ? " EV ≥ 65,LA ≥ 10" : "EV ≥ 90,LA ≥ 10"}>
                                <ReactTooltip
                                    className="rb-tool-tip"
                                    place="top"
                                    type="dark"
                                    effect="solid"
                                    offset={{ top: -8, left: 0 }}
                                />
                                Damage %
                            </span>
                        </>
                    );
                },
                "damage",
                allRows,
                true,
                {
                    sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "damage"),
                    sortDirections: ['descend', 'ascend'],
                }
            ),
            {
                title: "Avg LA",
                dataIndex: "avg_launch_angle",
            },
            createColoredColumnObj("Avg Dist", "avg_distance", allRows, false, {
                sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "avg_distance"),
                sortDirections: ['descend', 'ascend'],
            }),
            createColoredColumnObj("GB %", "gb", allRows, true, {
                sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "gb"),
                sortDirections: ['descend', 'ascend'],
            }),
            createColoredColumnObj("LD %", "ld", allRows, true, {
                sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "ld"),
                sortDirections: ['descend', 'ascend'],
            }),
            createColoredColumnObj("FB %", "fb", allRows, true, {
                sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "fb"),
                sortDirections: ['descend', 'ascend'],
            }),
            createColoredColumnObj("PU %", "pu", allRows, true, {
                sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "pu"),
                sortDirections: ['descend', 'ascend'],
            }),
            createColoredColumnObj("Pull %", "pull", allRows, true, {
                sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "pull"),
                sortDirections: ['descend', 'ascend'],
            }),
            createColoredColumnObj("Mid %", "middle", allRows, true, {
                sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "middle"),
                sortDirections: ['descend', 'ascend'],
            }),
            createColoredColumnObj("Oppo %", "oppo", allRows, true, {
                sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "oppo"),
                sortDirections: ['descend', 'ascend'],
            }),
            createColoredColumnObj("Pull EV", "avg_ev_to_pull", allRows, false, {
                sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "avg_ev_to_pull"),
                sortDirections: ['descend', 'ascend'],
            }),
            createColoredColumnObj("Mid EV", "avg_ev_to_middle", allRows, false, {
                sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "avg_ev_to_middle"),
                sortDirections: ['descend', 'ascend'],
            }),
            createColoredColumnObj("Oppo EV", "avg_ev_to_oppo", allRows, false, {
                sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "avg_ev_to_oppo"),
                sortDirections: ['descend', 'ascend'],
            }),
            {
                title: "Pull LA",
                dataIndex: "avg_la_to_pull",
            },
            {
                title: "Mid LA",
                dataIndex: "avg_la_to_middle",
            },
            {
                title: "Oppo LA",
                dataIndex: "avg_la_to_oppo",
            },
        ];

        const inner_data = childRows;
        return (
            <Table
                className="nestedTable"
                columns={inner_columns}
                dataSource={inner_data}
                pagination={false}
                showHeader={false}
                // scroll={{ y: 430 }}
            />
        );
    }
    return (
        <>
            {(isPrinting && data.length > 10) ? <PrintTable columns={columns} dataSource={data} /> :
                <div>
                    {isPrinting ?
                        <div className="print--table--wrp">
                            <Table
                                className={"table with-border"}
                                columns={columns}
                                dataSource={data}
                                pagination={false}
                            />
                        </div>
                         :
                        <div className="table-responsive-xl teamsummary-table">
                            <table className="table with-border">
                                <div className="custom-header-fixed">
                                    <Table
                                        columns={columns}
                                        dataSource={data}
                                        pagination={false}
                                        className="parentTable team_sec"
                                        expandIconColumnIndex={0}
                                        expandIcon={({ expanded, onExpand, record }) =>
                                        record.pitch_data ? record.pitch_data.length ?
                                            expanded ? (
                                                <DownOutlined
                                                    style={{ color: "black" }}
                                                    className="sticky_td"
                                                    onClick={(e) => onExpand(record, e)}
                                                />
                                            ) : (
                                                <RightOutlined style={{ color: "black" }} onClick={(e) => onExpand(record, e)} />
                                            ):
                                            "":""
                                        }
                                        expandable={{
                                            expandedRowRender: (row) => (
                                                <p
                                                    style={{
                                                        margin: 0,
                                                    }}
                                                >
                                                    {renderInnerRows(row)}
                                                </p>
                                            ),
                                        }}
                                    />
                                </div>
                            </table>
                        </div>}
                </div>}
        </>
    );
}

export default BattedballTable;
