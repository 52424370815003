import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import { ViewAllChartData } from "../../shared/function";
import SprayChartRangeHeader from "../../view/hittersummary/component/SprayChartRangeHeader";
import domtoimage from 'dom-to-image';
import PrintImagePopup from "../../shared/PrintImagePopup";
import { downloadChartPrintImg } from "../../shared/printReportUtils";
import use3DView from "../../shared/use3DView";
import PitchRWebGL from "../../view/webGL/PitchRWebGL";
import Toggle from "../../shared/Toggle";

function StrikeZoneChart({ chartData, id, title, x_axis, y_axis, x_min, x_max, y_min, y_max, ViewColor, onChangeEvent, softball, cameraAngle,
   visualType, pitchLocationType, colorScheme, eventProfileData, sendReq, tabslist, profilepage, isNotDownloadImgLoader, isDownloadImgLoader, closePitchrIframe, pitchrIframeFlag }) {
  const [data3D, setData3D, toggle, setToggle, onClickToggleButton, flag] = use3DView(id);
  const [isChartImg, setIsChartImg] = useState(false);
  const [clickFlag, setClickFlag] = useState(false);
  const [ballrFlag, setBallrFlag] = useState(false);
  // const [tooltipData, setTooltipData] = useState(null);
  const [state, setState] = useState({
    chart: {
      type: "scatter",
      borderWidth: 0,
      marginTop: 100,
      borderColor: "#D8D8D8",
      borderRadius: "8px",
    },
    title: {
      text: "",
    },
    xAxis: {
      title: {
        enabled: true,
        text: x_axis,
        style: {
          color: "#777F8F",
          fontFamily: "Poppins, sans-serif",
          fontSize: "14px",
          fontWeight: "500",
        },
      },
      min: x_min,
      max: x_max,
      tickInterval: 1
    },
    yAxis: {
      title: {
        enabled: true,
        text: y_axis,
        style: {
          color: "#777F8F",
          fontFamily: "Poppins, sans-serif",
          fontSize: "14px",
          fontWeight: "500",
        },
      },
      min: y_min,
      max: y_max,
    },
    legend: {
      layout: "horizontal",
      align: "center",
      verticalAlign: "top",
    },
    credits: { enabled: false },
    plotOptions: {
      series: {
        stickyTracking: false,
        states: {
          inactive: {
            opacity: 1
          }
        }
      }
    },
    tooltip: {
      useHTML: true,
      hideDelay: 500,
      formatter: function () {
        // setTooltipData(this?.point);
        // ballrFlag && refview.current.GiveKeyToiframe(this?.point);
        // setBallrFlag(true)
        if (this.point.series.name === "Strikezone") return false;
        if (this.point.series.name === "Stadium") return false;
        if (this.point.series.name === "boxRight") return false;
        if (this.point.series.name === "boxLeft") return false;
        if (this.point.series.name === "boxRightInner") return false;
        if (this.point.series.name === "boxleftInner") return false;
        if (this.point.series.name === "Arrow1") return false;
        if (this.point.series.name === "Arrow2") return false;
        if (this.point.series.name === "Arrow3") return false;
        if (this.point.series.name === "lineDash1") return false;
        if (this.point.series.name === "lineDash2") return false;
        if (this.point.series.name === "lineDash3") return false;
        if (this.point.series.name === "lineDash4") return false;
        let tooltip = `<span style="color: ${this.point.color
          }; font-weight:bold;">${this.point.options.pitch_type}</span><br>
          <span>Batter: ${this.point.options.batter ? this.point.options.batter : "-"
          }</span><br>
          <span>Pitcher: ${this.point.options.pitcher ? this.point.options.pitcher : "-"
          }</span><br>
          <span>Velocity: ${this.point.options && this.point.options.velocity
            ? this.point.options.velocity.toFixed(1)
            : "-"
          }</span><br>
          <span>Spin Rate: ${this.point.options && this.point.options.spin_rate
            ? Math.round(this.point.options.spin_rate)
            : "-"
          }</span><br>
          <span>Exit Velocity: ${this.point.options && this.point.options.exit_velocity
            ? Math.round(this.point.options.exit_velocity)
            : "-"
          }</span><br>
          <span>Launch Angle: ${this.point.options && this.point.options.launch_angle
            ? Math.round(this.point.options.launch_angle)
            : "-"
          }</span><br>
          <span>Distance: ${this.point.options && this.point.options.distance
            ? Math.round(this.point.options.distance)
            : "-"
          }</span><br>
          <span>Play Result: ${this.point.options.pitch_call ? this.point.options.pitch_call : "-"
          }</span>
          <!-- <div style="color: red" onmouseover="StrikeLoadBallR()">BallR</div> -->
          </span>`;
        return tooltip;
      },
      // if hover or click functionality add in tooltip use pointerEvents
      style: {
        pointerEvents: 'auto'
      }
    },
    series: [],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
        },
      ],
    },
  });

  useEffect(() => {
    if (toggle.chartToggle === true) {
      if (chartData) {
        if (chartData.length !== 0) {
          const seriesData = ViewAllChartData(chartData, title, ViewColor, softball);
          state.series = seriesData;
          if (state.series) {
            state.series[state.series.length - 1].showInLegend = false
          }
          // exit velocity top space remove and other as usual
          state.chart.marginTop = ViewColor === "exit_velocity" ? 40 : 75
          Highcharts.chart(id, state);
        } else {
          state.series = [{ showInLegend: false }];
          Highcharts.chart(id, state);
        }
      }
    } else {
      if (chartData && chartData.length !== 0) {
        let arr = chartData.map(x => x?._id) || []
        setData3D(arr)
      }
    }
  }, [chartData, ViewColor, toggle]);

  useEffect(() => {
    if (pitchrIframeFlag === "yes") {
      setData3D(null)
      setToggle({ chartToggle: true });
      closePitchrIframe();
    }
  }, [pitchrIframeFlag])

  const chartImage = (id) => {
    isDownloadImgLoader();
    setClickFlag(true)
    if (document.getElementById(`img${id}`)) {
      document.getElementById(`img${id}`).remove()
    }
    setIsChartImg(true)
    downloadChartPrintImg(id, setIsChartImg, setClickFlag, isNotDownloadImgLoader)
  }

  return (
    <>
      <div className={`ground-chart ${ballrFlag ? "chart-ifream" : ""}`}>
        {chartData && chartData.length !== 0 ?
          <Toggle
            right={window.innerWidth < 575 ? "17px" : toggle.chartToggle === true ? title === "Catcher View" ? "140px" : "17px" : "17px"}
            // top={window.innerWidth < 575 ? toggle.chartToggle === true ? "27px" : "10px" : "10px"}
            toggle={toggle}
            onClickToggleButton={onClickToggleButton} />
          : ""}
        <div style={{ display: toggle.chartToggle === true ? 'block' : 'none' }}>
          <div className="spraychart-section">
            <div className="legends graph-select text-left">
              <div className="text-left d-inline">
                <h3 className="mb-0">{title}</h3>
              </div>
              {title === "Catcher View" && <div className="select-box ">
                <select name={id === "ContactCatcherViewChart" ? "ViewColor_1" : id === "PitchCatcherViewChart" ? "ViewColor_2" : id === "PlayCatcherViewChart" ? "ViewColor_3" : "ViewColor"} className="custom-select" value={ViewColor} onChange={onChangeEvent}>
                  <option value="exit_velocity">Exit Velocity</option>
                  <option value="pitch_type">Pitch Type</option>
                  <option value="hit_angle_type">Contact Type</option>
                  <option value="pitch_call">Play Result</option>
                </select>
              </div>}
            </div>
            {ViewColor === "exit_velocity" &&
              <div className="legends mt-15">
                <div className="sprayChartHead">
                  <SprayChartRangeHeader softball={softball} />
                </div>
              </div>}
          </div>
          {/* <div className="ifream-box">
          {ballrFlag && <BallrWebGL tooltipData={tooltipData} id={id} index={tooltipData?._id} />}
        </div> */}
          <div style={{ height: title === "Catcher View" ? ViewColor !== "exit_velocity" ? "425px" : "390px" : ViewColor !== "exit_velocity" ? "445px" : "410px" }} id={id} />
          {/* <button className="btn blue btn-view" onClick={() => chartImage(id)} disabled={clickFlag} >Download</button> */}
          <div class="chartdownload" onClick={() => chartImage(id)} disabled={clickFlag}><a class="btn white"><i class="fa fa-download" aria-hidden="true"></i></a></div>
        </div>
        {flag?.[`iframe_${id}`] === true && <div id="pitchR-iframe" className="Chart-iframe" style={{ display: toggle.chartToggle === false ? 'block' : 'none' }}>
          <PitchRWebGL
            data={data3D}
            id={id}
            cameraAngle={cameraAngle}
            visualType={visualType}
            Toggle={toggle.chartToggle}
            pitchLocationType={pitchLocationType}
            colorScheme={colorScheme} />
        </div>}
      </div>

      {/* chart visual image download */}
      {isChartImg && <PrintImagePopup
        title={title}
        eventProfileData={eventProfileData}
        id={id}
        sendReq={sendReq}
        tabslist={tabslist}
        profilepage={profilepage} />}
    </>
  );
}

export default StrikeZoneChart;
