import React, { Component } from "react";
import { Route, BrowserRouter } from "react-router-dom";
import history from "./History";

// Landing pages
import Dashborad from "./view/Dashborad";
import gamesummary from "./view/gamesummary/index";
import PitchLog from "./view/gamesummary/index";
import awayBatters from "./view/gamesummary/index";
import awayPitchers from "./view/gamesummary/index";
import homeBatters from "./view/gamesummary/index";
import homepitchers from "./view/gamesummary/index";
import umpireanylysis from "./view/gamesummary/index";
import pitcherssummary from "./view/pitcherssummary/index";
import hittersummary from "./view/hittersummary/index";
// import ryansmmons from "./view/hittersummary/ryansmmons";
import profiledashboard from "./view/profiledashboard";
import teamsummary from "./view/teamsummary/teamsummary";
import development from "./view/development/development";
import scrimmagesummary from "./view/scrimmagesummary/index";
import PitchLogs from "./view/scrimmagesummary/index";
import Pitchers from "./view/scrimmagesummary/index";
import Hitters from "./view/scrimmagesummary/index";
import Sumpireanylysis from "./view/scrimmagesummary/index";
import sessionsummary from "./view/sessionsummary/index";
import { connect } from "react-redux";

class Routers extends Component {
  render() {
    return (
      <React.Fragment>
        {
          this.props.loader && <div className="bc-loader">
            <svg xmlns="http://www.w3.org/2000/svg" width="64px" height="64px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
              <circle cx="50" cy="50" fill="none" stroke="#1d0e0b" strokeWidth="10" r="35" strokeDasharray="164.93361431346415 56.97787143782138">
                <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
              </circle>
            </svg>
          </div>
        }
        <BrowserRouter history={history}>
          <div className="main-container">
            <Route exact path="/" component={Dashborad} />
            <Route exact path="/gamesummary/:id" component={gamesummary} />
            <Route exact path="/teamsummary" component={teamsummary} />
            <Route exact path="/development" component={development} />
            <Route exact path="/pitchlog/:id" component={PitchLog} />
            <Route exact path="/homepitchers/:id" component={homepitchers} />
            <Route exact path="/awaypitchers/:id" component={awayPitchers} />
            <Route exact path="/homebatters/:id" component={homeBatters} />
            <Route exact path="/awaybatters/:id" component={awayBatters} />
            <Route
              exact
              path="/umpireanylysis/:id"
              component={umpireanylysis}
            />
            <Route exact path="/pitcherssummary/:id" component={pitcherssummary} />
            <Route exact path="/hittersummary/:id" component={hittersummary} />
            {/* <Route exact path="/ryansmmons" component={ryansmmons} /> */}
            <Route
              exact
              path="/profiledashboard/:id"
              component={profiledashboard}
            />
            {/* scrimmage summary */}
            <Route exact path="/scrimmagesummary/:id" component={scrimmagesummary} />
            <Route exact path="/s_pitchlog/:id" component={PitchLogs} />
            <Route exact path="/s_pitchers/:id" component={Pitchers} />
            <Route exact path="/s_hitters/:id" component={Hitters} />
            <Route exact path="/s_umpireanylysis/:id" component={Sumpireanylysis} />

            {/* blast session summary*/}
            <Route exact path="/sessionsummary/:id" component={sessionsummary} />
          </div>
        </BrowserRouter>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loader: state.loader.loader,
  };
};

export default connect(mapStateToProps)(Routers);
