import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';
import { constant } from '../../shared/constant';

function BallrWebGL({ tooltipData, id, index }){
    const [state, setState] = useState({
        webGLUrl: process.env.REACT_APP_WEBGL_LINK,
        loader: false,
        iframeWidth: 75,
        iframeHeight: 75,
        data: "",
        firstLoadIframe: false
    })

    const refData = useRef();

    useEffect(() => {
        // get iframe(webGL) to height to bcteam
        window.addEventListener("message", (event) => {
            setState({ ...state, iframeHeight: JSON.parse(event.data).height })
        }, false);
        
        setState({
            ...state,
            webGLUrl: process.env.REACT_APP_WEBGL_LINK, loader: true
        })
    }, [])
    
    useEffect(() => {
        if (state.firstLoadIframe) {
            if (tooltipData?._id !== state.data) {
                GiveKeyToiframe(tooltipData);
            }
        }
    }, [tooltipData])

    const GiveKeyToiframe = (tooltipData) => {
        // Create a json object containing the login key to send to the iframe. "webglToken" is case-sensitive.
        var json = {
            // "webglToken": process?.env.REACT_APP_WEBGL_LINK,
            "ballMaterialType": constant.softball === false ? "baseball" : "softball",
            "pitchID": tooltipData?._id || "",
            "viewFrom": "pitcher",
            "spinDirDeg": tooltipData?.spinDirDeg || 0,
            "gyroAngle": tooltipData?.gyroAngle || 0,
            "spinRate": tooltipData?.spin_rate || 0,
            "pitchType": tooltipData?.pitch_type || "",
            "yt_seam_lat": tooltipData?.yt_seam_lat || 0,
            "yt_seam_long": tooltipData?.yt_seam_long || 0,
        };

        if (refData.current) {
            if (state.firstLoadIframe) {
                // tooltip data changes for other render iframe ballr
                if (tooltipData?._id) {
                    setState({ ...state, loader: false, data: tooltipData?._id })
                    if (refData.current) {
                        refData.current.contentWindow.postMessage(json, "*");
                    }
                }
            } else {
                // first time render iframe ballr
                setTimeout(() => {
                    if (tooltipData?._id) {
                        setState({ ...state, loader: false, data: tooltipData?._id })
                        if (refData.current) {
                            refData.current.contentWindow.postMessage(json, "*");
                        }
                    }
                    setState({ ...state, firstLoadIframe: true, loader: false })
                }, 2500);
            }
        }
    }

    // useImperativeHandle(ref, () => {
    //     if (tooltipData?._id !== state.data) {
    //         GiveKeyToiframe(tooltipData);
    //     }
    // });

    return (
        <>
            <div className="bollr-webgl" style={{ textAlign: 'center' }}>
                <iframe ref={refData} onLoad={() => GiveKeyToiframe(tooltipData)} id={`bollr_iframe${id + index}`} frameBorder="0" src={state.webGLUrl} height="75" width="75" style={{ height: "75px" }} />
                {state.loader && <div className="loader-bg">
                    <div className="loader">
                    </div>
                </div>}
            </div>
        </>
    )
}
export default BallrWebGL
