import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { imagesArr } from '../assets/images';
import Select, { components } from 'react-select';
import { getAllPlayerData } from "../store/actions";
import { constant } from '../shared/constant';


class header extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tabs: '',
            isMobile: false,
            isActiveSearchBox: false,
            disablebtn: "",
            playerOption: [],
            profileList: [],
            teamName: ""
        }
    }

    componentDidMount() {
        this.handleMobileDevice()
        let currentPage = document.location.href
        if (currentPage.indexOf("teamsummary") !== -1) this.setState({ disablebtn: 'teamsummary' })
        else if (currentPage.indexOf("development") !== -1) this.setState({ disablebtn: 'development' })
        else this.setState({ disablebtn: '' });
        this.props.loader(true);
        this.getPlayer();
        if (this.props.teamInfo) {
            this.setState({
                teamName: this.props.teamInfo.name
            }, () => {
                localStorage.setItem("teamData", JSON.stringify(this.props.teamInfo));
            })
        } else {
            this.setState({
                teamName: JSON.parse(localStorage.getItem("teamData"))?.name
            })
        }
    }

    getPlayer = () => {

        this.props.fetchAllPlayerData({ q: "" }, (res) => {
            var newPlayer = [{
                value: "",
                label: "Search",
            }];
            let proData = res.result && res.result.team_profiles;
            proData.map((list, i) => {
                newPlayer.push({
                    value: list._id,
                    label: list.fname + " " + list.lname,
                    profileList: res.result.team_profiles
                })
            });
            this.setState({
                playerOption: newPlayer,
                profileList: res.result.team_profiles
            }, () => {
                // this.props.loader(false);
            })
        });
    }

    handleTabs = (e) => {
        document.body.classList.remove("show-menu")
        this.setState({
            tabs: e
        })
        this.props.parentCallback(e);
    }

    handleChange = (e) => {
        const { history } = this.props;
        if (parseInt(e.target.value) === 0) { this.setState({ disablebtn: 'teamsummary' }); return history.push('/teamsummary') };
        if (parseInt(e.target.value) === 1) { this.setState({ disablebtn: 'development' }); return history.push('/development') };
        if (!parseInt(e.target.value)) { this.setState({ disablebtn: '' }); return history.push('/') };
    }

    handleMobileDevice() {
        if (window.innerWidth <= 575) this.setState({ isMobile: true });
    }

    // responsive menu toggle class
    toggleMenuBar = () => {
        document.body.classList.toggle("show-menu")
    }

    searchOpen() {
        if (window.innerWidth <= 575) this.setState({ isActiveSearchBox: !this.state.isActiveSearchBox });
    }

    handleSearchChange = (e) => {
        // iframe close in search player
        if (this.props.page === "profile") this.props.PitchrIframeEvent();
        // for pitchriframe load flag to set false
        Object.keys(this.props.appState).filter(key => key.includes("iframe_")).map(key => {
            this.props.appState[key] = false;
        });

        document.body.classList.remove("show-menu")
        if (e.value) {
            let name = e.label.split(" ");
            let path = "/profiledashboard/" + e.value;
            let listId = e.profileList.find((obj) => obj._id == e.value);
            localStorage.setItem("activeType", listId.hasOwnProperty("position1") ? ((listId.position1.toLowerCase()) === "pitcher" ? "Pitching" : "Hitting") : "Hitting");
            this.props.history.push(path);
            if (this.props.match.path === "/profiledashboard/:id") {
                this.props.clearState();
                this.props.renderPageSummary()
            }
        }
    }

    render() {
        let url = new URL(document.location);
        console.log("url.pathname:---", url.pathname);
        const tabs = this.state.tabs;
        let { isMobile, isActiveSearchBox, disablebtn, teamName } = this.state;
        const DropdownIndicator = (props) => {
            return (
                <components.DropdownIndicator {...props}>
                    <img src={imagesArr.search} alt="search" />
                </components.DropdownIndicator>
            );
        };

        const customStyles = {
            control: (base, state) => ({
                ...base,
                background: "#F2F4F8",
                borderRadius: state.isFocused ? "8px" : 8,
                borderColor: state.isFocused ? "#F2F4F8" : "#F2F4F8",
                boxShadow: state.isFocused ? null : null,
                "&:hover": {
                    borderColor: state.isFocused ? null : null
                }
            }),
            menu: base => ({
                ...base,
                borderRadius: 0,
                marginTop: 0
            }),
            menuList: base => ({
                ...base,
                padding: 0
            })
        };
        return (
            <>
                <nav className="navbar_fixed">
                    <div className={`overlay`} onClick={this.toggleMenuBar.bind(this)} />
                    {/* {
                        (url.pathname !== "/teamsummary" && url.pathname.split("/")[1] !== "profiledashboard") &&
                        <div className="menu-toggle" onClick={this.toggleMenuBar.bind(this)}>
                            <div className="menu-icon"><span></span></div>
                        </div>
                    } */}
                    <div className="menu-group">
                        <div className="navbar-logo">
                            <Link to="" onClick={() => this.handleTabs('')}>
                                <img src={constant.softball ? imagesArr.softball_logo : imagesArr.logo} alt="logo" />
                            </Link>
                            <p>{teamName}</p>
                        </div>

                        {
                            isMobile ?
                                // <div className="tab-dropdown">
                                //     <select className="custom-select" onChange={(e) => this.handleChange(e)}>
                                //         <option value="">Select type</option>
                                //         <option value="0" selected={disablebtn === 'teamsummary' && true}>Team Summary</option>
                                //         <option value="1" selected={disablebtn === 'development' && true}>Development</option>
                                //     </select>
                                // </div>
                                <ul className="tab-view">
                                    <li className={`${tabs === 0 ? "active" : ""} ${disablebtn === 'teamsummary' && 'disabled active'}`} onClick={() => this.handleTabs(0)}>
                                        <Link to="/teamsummary">Team Summary</Link>
                                    </li>
                                    <li className={`${tabs === 1 ? "active" : ""} ${disablebtn === 'development' && 'disabled active'}`} onClick={() => this.handleTabs(1)}>
                                        <Link to="/development">Development</Link>
                                    </li>
                                </ul>
                                :
                                <ul className="tab-view">
                                    <li className={`${tabs === 0 ? "active" : ""} ${disablebtn === 'teamsummary' && 'disabled active'}`} onClick={() => this.handleTabs(0)}>
                                        <Link to="/teamsummary">Team Summary</Link>
                                    </li>
                                    <li className={`${tabs === 1 ? "active" : ""} ${disablebtn === 'development' && 'disabled active'}`} onClick={() => this.handleTabs(1)}>
                                        <Link to="/development">Development</Link>
                                    </li>
                                </ul>
                        }
                        {/* searchbox */}
                        <div className={`main-search ${isActiveSearchBox ? "active" : ""}`}>
                            {/* <span className="search-button" onClick={() => this.searchOpen()}><i className="fas fa-search"></i></span> */}
                            <div className="serch-box">
                                {/* <Link to="" className="serch-btn">
                                <img src={imagesArr.search} alt="search" />
                            </Link> */}

                                {/* <input type="text" name="search" className="form-control" placeholder="Search" /> */}

                                <Select
                                    className='customize-select'
                                    components={{ DropdownIndicator }}
                                    styles={customStyles}
                                    onChange={this.handleSearchChange.bind(this)}
                                    options={this.state.playerOption}
                                    placeholder="Search"
                                />
                            </div>
                        </div>
                    </div>
                </nav>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        allPlayerData: state.app.allPlayerData,
        appState: state.app
    };
};

const mapDispatchToProps = (dispatch) => ({
    fetchAllPlayerData: (data, next) => dispatch(getAllPlayerData(data, next)),
    loader: (data) => dispatch({ type: "LOADING", payload: data })
});

let myHeader = connect(mapStateToProps, mapDispatchToProps)(header);
export default withRouter(myHeader);
