import React from 'react'
import { constant } from '../../../shared/constant';

function CatcherTable({ tableData, title }) {
    return (
        <div className="container-fluid">
            <h3 className="table-title text-center">{title}</h3>
            <div className="homepitchers-table">
                <div className="table-responsive-xl">
                    <table className="table tabledashed">
                        <thead>
                            <tr>
                                <th className="tablerowblue fw-500">{constant.softball ? "Pitch Type" : "Pitch Groups"}</th>
                                <th className="tablerowblue fw-500">#</th>
                                <th className="tablerowblue fw-500">Stolen</th>
                                <th className="tablerowblue fw-500">Lost</th>
                                <th className="tablerowblue fw-500">+/-</th>
                                <th className="tablerowblue fw-500">Stolen %</th>
                                <th className="tablerowblue fw-500">Lost %</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                tableData && tableData.length !== 0 ?
                                    tableData.map(_ => {
                                        const rowColor = _._id === "All Pitches" ? "table-rowgrey80" : "table-rowwhite"
                                        return (
                                            <tr>
                                                <td className={`${rowColor}`} style={{ color: constant.pitchTypeColor[_._id] }}>{_ && _._id}</td>
                                                <td className={`${rowColor}`}>{_ && _?.count || "0"}</td>
                                                <td className={`${rowColor}`}>{_ && Math.round(_?.stolen) || "0"}</td>
                                                <td className={`${rowColor}`}>{_ && Math.round(_?.lost) || "0"}</td>
                                                <td className={`${rowColor}`}>{_ && Math.round(_?.diff) || "0"}</td>
                                                <td className={`${rowColor}`}>{_ && _?.stolen_perc ? _?.stolen_perc.toString().includes(".") ? _?.stolen_perc.toFixed(1) + "%" : _?.stolen_perc + "%" : '0%'}</td>
                                                <td className={`${rowColor}`}>{_ && _?.lost_perc ? _?.lost_perc.toString().includes(".") ? _?.lost_perc.toFixed(1) + "%" : _?.lost_perc + "%" : '0%'}</td>
                                            </tr>
                                        )
                                    })
                                    :
                                    <tr>
                                        <td className="table-rowwhite text-center" colSpan="7">There's no info yet!</td>
                                    </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default CatcherTable