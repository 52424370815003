import React from 'react'
import { constant } from '../../shared/constant';

function PlateDisciplineTable({ tableData }) {
    const renderList = () => {
        return tableData && tableData.length !== 0 ? (
            tableData.map((data, i) => {
                const rowColor = data._id === "Total" ? "table-rowgrey80" : "table-rowwhite"
                return (
                    <tr key={i}>
                        <td
                            className={`${rowColor}`}
                            style={{ color: constant.pitchTypeColor[data._id] }}
                        >
                            {data._id ? data._id : "-"}
                        </td>
                        <td className={`${rowColor}`}>{data.count ? data.count : "-"}</td>
                        <td className={`${rowColor}`}>
                            {data.swing ? data.swing.toString().includes(".") ? data.swing.toFixed(1) + "%" : data.swing + "%" : '0%'}
                        </td>
                        <td className={`${rowColor}`}>
                            {data.swinging_strike ? data.swinging_strike.toString().includes(".") ? data.swinging_strike.toFixed(1) + "%" : data.swinging_strike + "%" : '0%'}
                        </td>
                        <td className={`${rowColor}`}>
                            {data.whiff ? data.whiff.toString().includes(".") ? data.whiff.toFixed(1) + "%" : data.whiff + "%" : '0%'}
                        </td>
                        <td className={`${rowColor}`}>
                            {data.zone_swing ? data.zone_swing.toString().includes(".") ? data.zone_swing.toFixed(1) + "%" : data.zone_swing + "%" : '0%'}
                        </td>
                        <td className={`${rowColor}`}>
                            {data.zone_contact ? data.zone_contact.toString().includes(".") ? data.zone_contact.toFixed(1) + "%" : data.zone_contact + "%" : '0%'}
                        </td>
                        <td className={`${rowColor}`}>
                            {data.chase_swing ? data.chase_swing.toString().includes(".") ? data.chase_swing.toFixed(1) + "%" : data.chase_swing + "%" : '0%'}
                        </td>
                        <td className={`${rowColor}`}>
                            {data.chase_contact ? data.chase_contact.toString().includes(".") ? data.chase_contact.toFixed(1) + "%" : data.chase_contact + "%" : '0%'}
                        </td>
                        <td className={`${rowColor}`}>
                            {data.egde ? data.egde.toString().includes(".") ? data.egde.toFixed(1) + "%" : data.egde + "%" : '0%'}
                        </td>
                        <td className={`${rowColor}`}>
                            {data.strike ? data.strike.toString().includes(".") ? data.strike.toFixed(1) + "%" : data.strike + "%" : '0%'}
                        </td>
                    </tr>
                );
            })
        ) : (
            <tr>
                <td className="table-rowwhite text-center" colSpan="11">
                    There's no info yet!
                </td>
            </tr>
        );
    };

    return (
        <div className="container-fluid">
            <h3 className="table-title">Plate Discipline</h3>
            <div className="homepitchers-table">
                <div className="table-responsive-xl">
                    <table className="table tabledashed">
                        <tbody>
                            <tr>
                                <td className="tablerowblue fw-500">Pitch type</td>
                                <td className="tablerowblue fw-500">#</td>
                                <td className="tablerowblue fw-500">Swing %</td>
                                <td className="tablerowblue fw-500">SwStr %</td>
                                <td className="tablerowblue fw-500">Whiff %</td>
                                <td className="tablerowblue fw-500">Zone Swing %</td>
                                <td className="tablerowblue fw-500">Zone Contact %</td>
                                <td className="tablerowblue fw-500">Chase %</td>
                                <td className="tablerowblue fw-500">Chase Contact %</td>
                                <td className="tablerowblue fw-500">Edge %</td>
                                <td className="tablerowblue fw-500">Strike %</td>
                            </tr>
                            {renderList()}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default PlateDisciplineTable
