import React from 'react'
import { constant } from '../../shared/constant';

function PitchCharacteristics({ tableData }) {
    const renderList = () => {
        return tableData && tableData.length !== 0 ? (
            tableData.map((data, i) => {
                return (
                    <tr key={i}>
                        <td
                            className="table-rowwhite"
                            style={{ color: constant.pitchTypeColor[data._id] }}
                        >
                            {data._id ? data._id : "-"}
                        </td>
                        <td className="table-rowwhite">
                            {data.velocity_avg ? data.velocity_avg.toString().includes(".") ? data.velocity_avg.toFixed(1) : data.velocity_avg : '—'}
                        </td>
                        <td className="table-rowwhite">
                            {data.velocity_max ? data.velocity_max.toString().includes(".") ? data.velocity_max.toFixed(1) : data.velocity_max : '—'}
                        </td>
                        <td className="table-rowwhite">
                            {data.spin_rate_avg ? Math.round(data.spin_rate_avg) : '—'}
                        </td>
                        <td className="table-rowwhite">
                            {data.spin_rate_max ? Math.round(data.spin_rate_max) : '—'}
                        </td>
                        <td className="table-rowwhite">{data.spin_tilt ? data.spin_tilt : "-"}</td>
                        <td className="table-rowwhite">
                            {data.spin_axis ? Math.round(data.spin_axis) : '—'}
                        </td>
                        <td className="table-rowwhite">
                            {data.efficiency ? Math.round(data.efficiency) + "%" : '0%'}
                        </td>
                        <td className="table-rowwhite">
                            {data.vertical_break ? data.vertical_break.toString().includes(".") ? data.vertical_break.toFixed(1) : data.vertical_break : '—'}
                        </td>
                        <td className="table-rowwhite">
                            {data.ind_v_break ? data.ind_v_break.toString().includes(".") ? data.ind_v_break.toFixed(1) : data.ind_v_break : '—'}
                        </td>
                        <td className="table-rowwhite">
                            {data.horizontal_break ? data.horizontal_break.toString().includes(".") ? data.horizontal_break.toFixed(1) : data.horizontal_break : '—'}
                        </td>
                        <td className="table-rowwhite">
                            {data.v_approach ? data.v_approach.toString().includes(".") ? data.v_approach.toFixed(1) : data.v_approach : '—'}
                        </td>
                        <td className="table-rowwhite">
                            {data.rel_height ? data.rel_height.toString().includes(".") ? data.rel_height.toFixed(1) : data.rel_height : '—'}
                        </td>
                        <td className="table-rowwhite">
                            {data.extension ? data.extension.toString().includes(".") ? data.extension.toFixed(1) : data.extension : '—'}
                        </td>
                    </tr>
                );
            })
        ) : (
            <tr>
                <td className="table-rowwhite text-center" colSpan="14">
                    There's no info yet!
                </td>
            </tr>
        );
    };

    return (
        <div className="container-fluid">
            <h3 className="table-title">Pitch Characteristics</h3>
            <div className="homepitchers-table">
                <div className="table-responsive-xl">
                    <table className="table tabledashed">
                        <tbody>
                            <tr>
                                <td className="tablerowblue fw-500">Pitch type</td>
                                <td className="tablerowblue fw-500">Avg Velo</td>
                                <td className="tablerowblue fw-500">Peak Velo</td>
                                <td className="tablerowblue fw-500">Avg Spin</td>
                                <td className="tablerowblue fw-500">Peak Spin</td>
                                <td className="tablerowblue fw-500">Tilt</td>
                                <td className="tablerowblue fw-500">Axis</td>
                                <td className="tablerowblue fw-500">Efficiency</td>
                                <td className="tablerowblue fw-500">V Break</td>
                                <td className="tablerowblue fw-500">Ind V Break</td>
                                <td className="tablerowblue fw-500">H Break</td>
                                <td className="tablerowblue fw-500">V Approach</td>
                                <td className="tablerowblue fw-500">Avg Rel Height</td>
                                <td className="tablerowblue fw-500">Avg Extension</td>
                            </tr>
                            {renderList()}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default PitchCharacteristics
