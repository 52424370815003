import * as d3 from 'd3';
import moment from 'moment';
import { constant } from '../../../../../shared/constant';

function BasicSpray(container, softball = false) {
  this.colors = [
    '#2166ac',
    '#4393c3',
    '#92c5de',
    '#d1e5f0',
    '#fddbc7',
    '#f4a582',
    '#d6604d',
    '#b2182b',
  ];
  if (softball) {
    this.d0 = 230;
    this.d1 = 200;
    this.d2 = 91;
  } else {
    this.d0 = 400;
    this.d1 = 350;
    this.d2 = 160;
  }

  const rect = container.node().getBoundingClientRect();
  const margin = 5;
  const size = Math.min(rect.width, rect.height) - 2 * margin;
  const r0 = size / Math.sqrt(2);
  const r1 = (r0 * this.d1) / this.d0;
  const r2 = (r0 * this.d2) / this.d0;
  const center = {
    x: Math.floor(rect.width / 2),
    y: Math.floor(rect.height / 2 + size / 4),
  };

  this.dBase = r0 / this.d0;
  container.selectAll('svg').remove();
  const svg = container
    .append('svg')
    .attr('width', rect.width)
    .attr('height', rect.height)
    .style(
      'font-family',
      "'Lucida Grande', 'Lucida Sans Unicode', Arial, Helvetica, sans-serif",
    )
    .style('font-size', '12px');

  this.g0 = svg
    .append('g')
    .attr('transform', `translate(${center.x},${center.y})`)
    .style('opacity', 0);

  const tooltip = container
    .append('div')
    .attr('class', 'tooltip')
    .style('opacity', 0)
    .style('font-size', '14px')
    .style('color', '#494949')
    .style('border-radius', '5px')
    .style('border', '1px solid #000000')
    .style('padding', '8px')
    .style('background-color', 'rgba(255, 255, 255, 0.9)');

  this.arcs = [
    {
      innerRadius: 0,
      outerRadius: r2,
      innerD: 0,
      outerD: this.d2,
      startAngle: -Math.PI / 4,
      endAngle: -Math.PI / 12,
      rotate: -30,
    },
    {
      innerRadius: 0,
      outerRadius: r2,
      innerD: 0,
      outerD: this.d2,
      startAngle: -Math.PI / 12,
      endAngle: Math.PI / 12,
      rotate: 0,
    },
    {
      innerRadius: 0,
      outerRadius: r2,
      innerD: 0,
      outerD: this.d2,
      startAngle: Math.PI / 12,
      endAngle: Math.PI / 4,
      rotate: 30,
    },
    {
      innerRadius: r2,
      outerRadius: r1,
      innerD: this.d2,
      outerD: this.d1,
      startAngle: -Math.PI / 4,
      endAngle: -Math.PI / 12,
      rotate: -30,
    },
    {
      innerRadius: r2,
      outerRadius: r1,
      innerD: this.d2,
      outerD: this.d1,
      startAngle: -Math.PI / 12,
      endAngle: Math.PI / 12,
      rotate: 0,
    },
    {
      innerRadius: r2,
      outerRadius: r1,
      innerD: this.d2,
      outerD: this.d1,
      startAngle: Math.PI / 12,
      endAngle: Math.PI / 4,
      rotate: 30,
    },
  ];

  const arc = d3.arc();
  this.g0
    .selectAll('.arc')
    .data(this.arcs)
    .enter()
    .append('path')
    .attr('class', 'arc')
    .attr('d', arc);

  this.g0
    .selectAll('.arc-text')
    .data(this.arcs)
    .enter()
    .append('text')
    .attr('class', 'arc-text')
    .attr('x', 0)
    .attr('y', function(d) {
      return -d.outerRadius * 0.75;
    })
    .attr('transform', function(d) {
      return `rotate(${d.rotate})`;
    })
    .style('color', '#000000')
    .style('fill', '#000000')
    .style('font-size', '13px')
    .style('font-weight', 'bold');

  this.g2 = svg
    .append('g')
    .attr('transform', `translate(${center.x},${center.y})`)
    .style('opacity', 1);
  this.g2
    .append('rect')
    .attr('class', 'dots-panel')
    .attr('x', -center.x)
    .attr('y', -center.y)
    .attr('width', rect.width)
    .attr('height', rect.height)
    .style('opacity', '0.6')
    .style('fill', '#ffffff');

  this.g1 = svg
    .append('g')
    .attr(
      'transform',
      `translate(${Math.floor(rect.width / 2)},${Math.floor(
        rect.height / 2 + 20,
      )})`,
    )
    .style('opacity', 0);
  this.g1.append('text').style('text-anchor', 'middle');

  this.update = function(data) {
    const self = this;
    self.g0.style('opacity', 0);
    self.g1.style('opacity', 0);
    self.g2.selectAll('.dot').remove();

    if (data.length === 0) {
      self.g1
        .select('text')
        .text(`There's is no Info yet!`)
        .attr('class', 'sprayChartNoInfoText');
      self.g1.transition().style('opacity', 1);
      return;
    }

    self.g0
      .selectAll('.arc')
      .on('mouseover', function(d) {
        tooltip
          .html(
            `${Math.round((d.count * 100) / data.length)}% (${d.count} of ${
              d.total
            })`,
          )
          .style('opacity', 1);
      })
      .on('mousemove', function(d) {
        const pos = d3.mouse(this);
        if (pos[0] <= center.x * 0.3) {
          tooltip
            .style('right', null)
            .style('left', `${pos[0] + center.x + 8}px`)
            .style('top', `${pos[1] + center.y - 15}px`);
        } else {
          tooltip
            .style('left', null)
            .style('right', `${center.x - pos[0] + 4}px`)
            .style('top', `${pos[1] + center.y - 15}px`);
        }
      })
      .on('mouseout', function() {
        tooltip.style('opacity', 0);
      });

    self.arcs.forEach(function(arc) {
      arc.count = 0;
      arc.total = data.length;
    });

    data.forEach(function(d) {
      // color areas where there is a datapoint
      if (d.distance <= self.d2) {
        if (d.direction >= -45 && d.direction < -15) {
          self.arcs[0].count++;
        } else if (d.direction >= -15 && d.direction <= 15) {
          self.arcs[1].count++;
        } else if (d.direction > 15 && d.direction <= 45) {
          self.arcs[2].count++;
        }
      } else if (d.direction >= -45 && d.direction < -15) {
        self.arcs[3].count++;
      } else if (d.direction >= -15 && d.direction <= 15) {
        self.arcs[4].count++;
      } else if (d.direction > 15 && d.direction <= 45) {
        self.arcs[5].count++;
      }

      const rad = (d.direction * Math.PI) / 180;
      self.g2
        .append('circle')
        .attr('class', 'dot')
        .attr('r', 4)
        .attr('cx', Math.sin(rad) * d.distance * self.dBase)
        .attr('cy', -Math.cos(rad) * d.distance * self.dBase)
        .style('opacity', 1)
        .style('fill', constant.colors[d.pitch_call])
        .style('stroke', '#000000')
    });

    let tempSort = [];
    let i;
    for (i = 0; i < self.arcs.length; i++) {
      tempSort.push({ index: i, count: self.arcs[i].count });
    }

    tempSort.sort(function(a, b) {
      return a.count < b.count ? 1 : a.count > b.count ? -1 : 0;
    });
    let colorScale = d3
      .scaleQuantize()
      .domain([0, tempSort[0].count])
      .range(self.colors);
    for (i = 0; i < tempSort.length; i++) {
      self.arcs[tempSort[i].index].color = colorScale(tempSort[i].count);
    }

    self.g0
      .selectAll('.arc')
      .data(self.arcs)
      .style('fill', function(d) {
        return d.color;
      });
    self.g0
      .selectAll('.arc-text')
      .data(self.arcs)
      .text(function(d) {
        return `${Math.round((d.count * 100) / d.total)}%`;
      });

    self.g0
      .transition()
      .duration(500)
      .style('opacity', 1);
    self.g2
      .selectAll('.dot')
      .transition()
      .duration(500)
      .style('opacity', 1);
  };
}
export default BasicSpray;
