import React, { Component } from "react";
import Header from "../../containers/header";
import { imagesArr } from "../../assets/images";
import Sessionsummarysidebar from "../../containers/sessionsummarysidebar";
import Teamsummary from "../teamsummary/teamsummary";
import Development from "../development/development";
import { getBlastData } from "../../store/actions";
import { connect } from "react-redux";
import SessionPlayer from "./component/SessionPlayer";
import { printToPDFMultiple } from "../../shared/printReportUtils";
import { groupArrayOfObjects } from "../../shared/function";
import PitcherSummaryTable from "./component/PitcherSummary";
import Loader from "../../shared/loader";

let groupedPitcher,
  groupedSpline,
  groupedScatter;

class Sessionsummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: "",
      pitcherList: {},
      summaryTable: [],
      splineChartData: {},
      scatterList: {},
      isPitcherSummary: true,
      keyName: null,
      isPrint: false,
      printAll: false,
      backUP: null,
      spline_chart: "bat_speed",
      plot_f_x: "peak_hand_speed",
      plot_f_y: "bat_speed",
      plot_s_x: "connection_at_impact",
      plot_s_y: "peak_hand_speed",
      plot_t_x: "attack_angle",
      plot_t_y: "bat_speed",
      isDownloadImg: false
    };
  }

  componentDidMount() {
    let sourceid = document.location.href.split("/")[4];
    if (sourceid === undefined) window.location.href = "/";
    else this.SessionsummaryData();
  }

  // fetch sessionsummary data
  SessionsummaryData = () => {
    let data = {
      sourceId: document.location.href.split("/")[4],
      tab: "summary",
    };

    this.props.fetchBlastData(data, (res) => {
      groupedPitcher = groupArrayOfObjects(
        res.data.result.blast_session_summary_data,
        "_id"
      );
      groupedSpline = groupArrayOfObjects(
        res.data.result.blast_spline_chart_data,
        "_id"
      );
      groupedScatter = groupArrayOfObjects(
        res.data.result.blast_scatter_plot_data,
        "_id"
      );

      this.setState({
        pitcherList: groupedPitcher,
        summaryTable: res.data.result.blast_session_summary_data,
        splineChartData: groupedSpline,
        scatterList: groupedScatter,
        keyName: "sessionsummary",
        backUP: res.data.result,
        eventName: res.data?.eventDetails?.event?.event_name
      });
    });
  };

  handleCallback = (index) => {
    this.setState({
      tabs: index,
    });
  };

  // metric change y axis of rolling line chart
  metricHandleChange = (e) => {
    const { name, value } = e.target
    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  handleSidbar = (keyName, isPitcher) => {
    this.setState((prevState) => ({
      ...prevState,
      keyName: keyName,
      isPitcherSummary: isPitcher,
    }));
  };

  print = async () => {
    this.setState({ isPrint: true });
    setTimeout(async () => {
      await this.updatefontsize()
      await printToPDFMultiple(`blastPlayer`, ["sessionSummaryPrintArea"]);
      this.setState({ isPrint: false });
    }, 2000);
  };

  async updatefontsize(loopCount = 0) {
    const content = document.querySelector(".print--table--wrp .ant-table-content");
    const table = content.querySelector("table")
    const thList = document.querySelectorAll(".print--table--wrp .ant-table-thead > tr > th")
    const tdList = document.querySelectorAll(".print--table--wrp tbody td")
    await new Promise((resolve) => {
      setTimeout(() => resolve(true), 100);
    })
    if (content.offsetWidth < table.offsetWidth) {
      for (var i = 0; i < thList.length; i++) {
        thList[i].style.fontSize = `${14 - loopCount}px`;
        if (loopCount - 4 > 0) thList[i].style.padding = `${4 - loopCount}px`;
      }
      for (var i = 0; i < tdList.length; i++) {
        tdList[i].style.fontSize = `${14 - loopCount}px`;
        tdList[i].style.padding = `${16 - loopCount}px ${10 - loopCount}px`;
      }
      await this.updatefontsize(loopCount + 1)
    }
    return true
  }

  printChart = async () => {
    this.setState({
      isPrint: true,
      splineChartData: Object.assign(
        [],
        groupArrayOfObjects(this.state.backUP.blast_spline_chart_data, "_id")
      ),
      scatterList: Object.assign(
        [],
        groupArrayOfObjects(this.state.backUP.blast_scatter_plot_data, "_id")
      ),
    });
    setTimeout(async () => {
      await printToPDFMultiple(`sessionPlayer`, ["sessionSummaryChart"]);
      this.setState({
        isPrint: false,
        splineChartData: Object.assign([], groupedSpline),
        scatterList: Object.assign([], groupedScatter),
      });
    }, 2000);
  };

  printAll = () => {
    this.setState({ printAll: true });
    let arr = Object.keys(this.state.pitcherList).map((data, index) => { if (data !== "Session Average" && data !== "Team Average") return data })
    let a = arr.filter(e => e);
    a.unshift("sessionAllPlayerPrintArea");
    setTimeout(async () => {
      await printToPDFMultiple(`blastEvent`, a);
      this.setState({ printAll: false });
    }, 10000);
  };

  setLoader = (flg) => {
    this.props.startLoader(flg);
  };

  getActive = () => {
    if (this.state.printAll) {
      return (
        <>
          {this.state.printAll && <Loader />}
          <section id="sessionAllPlayerPrintArea">
            <PitcherSummaryTable
              print={() => this.print()}
              printAll={() => this.printAll()}
              isPrint={this.state.printAll}
              summaryTable={this.state.summaryTable}
            />
          </section>
          <div id="sessionPlayerPrintArea">
            {Object.keys(this.state.pitcherList).length !== 0 &&
              Object.keys(this.state.pitcherList).map((keyName, index) => {
                return (
                  (keyName !== "Session Average" && keyName !== "Team Average") &&
                  (<>
                    <div id={keyName}>
                      <section className="pb-0">
                        <div className="container-fluid">
                          <div className="common-title">
                            <h2>{keyName}</h2>
                            {/* {!this.state.printAll && (
                              <div className="dropdown">
                                <span className="printer">
                                  <img src={imagesArr.printer} alt="print" />
                                </span>
                                <div className="dropdown-content">
                                  <ul>
                                    <li onClick={() => this.printChart()}>
                                      Print
                                    </li>
                                    <li onClick={() => this.printAll()}>
                                      Print All
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            )} */}
                          </div>
                        </div>
                        <SessionPlayer
                          keyName={keyName}
                          pitcherList={this.state.pitcherList}
                          splineChartData={this.state.splineChartData}
                          spline_chart={this.state.spline_chart}
                          metricHandleChange={this.metricHandleChange}
                          scatterList={this.state.scatterList}
                          plot_f_x={this.state.plot_f_x}
                          plot_f_y={this.state.plot_f_y}
                          plot_s_x={this.state.plot_s_x}
                          plot_s_y={this.state.plot_s_y}
                          plot_t_x={this.state.plot_t_x}
                          plot_t_y={this.state.plot_t_y}
                          setLoader={this.setLoader}
                          index={index}
                          isprint={this.state.isPrint}
                          isprintAll={this.state.printAll}
                        />
                      </section>
                    </div>

                  </>)
                );
              })}
          </div>

        </>
      );
    }
    if (this.state.isPitcherSummary) {
      return (
        <>
          {this.state.isPrint && <Loader />}
          <PitcherSummaryTable
            print={() => this.print()}
            printAll={() => this.printAll()}
            isPrint={this.state.isPrint}
            summaryTable={this.state.summaryTable}
          />
        </>
      );
    } else {
      return (
        <>
          {this.state.isPrint && <Loader />}
          {this.state.isDownloadImg && <Loader text={true}/>}
          <div id="sessionSummaryChart">
            <section className="pb-0">
              <div className="container-fluid">
                <div className="common-title">
                  <h2>{this.state.keyName}</h2>
                  {/* {!this.state.isPrint && (
                    <div className="dropdown">
                      <span className="printer">
                        <img src={imagesArr.printer} alt="print" />
                      </span>
                      <div className="dropdown-content">
                        <ul>
                          <li onClick={() => this.printChart()}>Print</li>
                          <li onClick={() => this.printAll()}>Print All</li>
                        </ul>
                      </div>
                    </div>
                  )} */}
                </div>
              </div>
              <SessionPlayer
                keyName={this.state.keyName}
                pitcherList={this.state.pitcherList}
                splineChartData={this.state.splineChartData}
                spline_chart={this.state.spline_chart}
                metricHandleChange={this.metricHandleChange}
                scatterList={this.state.scatterList}
                plot_f_x={this.state.plot_f_x}
                plot_f_y={this.state.plot_f_y}
                plot_s_x={this.state.plot_s_x}
                plot_s_y={this.state.plot_s_y}
                plot_t_x={this.state.plot_t_x}
                plot_t_y={this.state.plot_t_y}
                setLoader={this.setLoader}
                isprint={this.state.isPrint}
                isprintAll={this.state.printAll}
                isNotDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: false }))}
                isDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: true }))}
                eventName={this.state.eventName}
              />
            </section>
          </div>
        </>
      );
    }
  };

  render() {
    const tabs = this.state.tabs;
    return (
      <>
        <div className="d-flex pitchers-summary">
          <div
            className={`page-content-wrapper ${tabs === 0 || tabs === 1 ? "tabactive" : ""
              }`}
          >
            <Header parentCallback={this.handleCallback} />
            {tabs === 0 && <Teamsummary />}
            {tabs === 1 && <Development />}
            {tabs !== 0 && tabs !== 1 && (
              <>
                <div className="main-div">
                  <div className="sidebar-content">
                    {Object.keys(this.state.pitcherList).length !== 0 && (
                      <Sessionsummarysidebar
                        changeside={this.handleSidbar}
                        sideData={this.state.pitcherList}
                        keyName={this.state.keyName}
                      />
                    )}
                    <div className="content">{this.getActive()}</div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    blastData: state.blastData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchBlastData: (data, next) => dispatch(getBlastData(data, next)),
  startLoader: (data) => dispatch({ type: "LOADING", payload: data }),
});
export default connect(mapStateToProps, mapDispatchToProps)(Sessionsummary);