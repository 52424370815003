import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import {
  heatMapDataXformer
} from "../../../../../shared/function";
import Select from 'react-select';
import { NoGraph } from "../../noInfo";


function HitterHeatMap({ heatMapA, heatMapB, onChange, reqA, reqB }) {
  const [s, setS] = useState({ chartToggle: true });
  const pitchType = [
    { value: '', label: 'All Pitches' },
    { value: 'Fastball', label: 'Fastball' },
    { value: 'Curveball', label: 'Curveball' },
    { value: 'Changeup', label: 'Changeup' },
    { value: 'Slider', label: 'Slider' },
  ]

  const sources = [
    { value: 'exit_velocity', label: 'Exit Velo' },
    { value: 'launch_angle', label: 'Avg Launch Angle' },
  ];


  const [state, setState] = useState({
    chart: {
      type: "heatmap",
      marginTop: 82,
      borderWidth: 0,
      spacingTop: 50,
      spacingBottom: 50,
      spacingLeft: 50,
      borderColor: "#D8D8D8",
      borderRadius: "8px",
    },
    credits: {
      enabled: false,
    },
    title: {
      text: "",
      layout: "horizontal",
      align: "left",
      verticalAlign: "left",
      y: 20,
      x: 10,
      style: {
        color: "#1B1B1B",
        fontFamily: "Poppins, sans-serif",
        fontSize: "18px",
        fontWeight: "bold",
      },
    },
    xAxis: {
      categories: [""],
      labels: {
        enabled: false,
      },
    },
    yAxis: {
      categories: [""],
      labels: {
        enabled: false,
      },
      title: null,
      reversed: true,
    },
    plotOptions: {
      series: {
        states: {
          inactive: {
            opacity: 1
          },
          hover: {
            enabled: false,
          },
        },
      },
    },
    colorAxis: {
      min: 60,
      max: 105,
      startOnTick: true,
      tickInterval: 10,
      stops: [
        [0, "#1707C7"],
        [0.3, "#8686FD"],
        [0.6, "#FF8465"],
        [0.9, "#850101"],
      ],
    },
    legend: {
      align: "right",
      layout: "vertical",
      margin: 0,
      verticalAlign: "top",
      y: 25,
      symbolHeight: 280,
    },
    tooltip: "",
    series: [],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          // chartOptions: {
          //     yAxis: {
          //         labels: {
          //             formatter: function () {
          //                 return this.value.charAt(0);
          //             }
          //         }
          //     }
          // }
        },
      ],
    },
  });

  useEffect(() => {
    if (window.innerWidth < 1366) {
      if (s.chartToggle === true) {
        if (heatMapA.length !== 0) {
          const heatMapChartData = heatMapDataXformer(heatMapA);
          let seriesData = [{
            name: '',
            borderWidth: 1,
            data: heatMapChartData,
            dataLabels: {
              enabled: true,
              style: {
                color: '#FFFFFF',
                textOutline: '0px contrast',
                fontSize: '15px',
              },
              formatter() {
                if (this.point.value != 0) {
                  return this.point.value;
                }
                return null;
              },
            }
          },
          {
            name: 'Heatmap',
            color: 'black',
            type: 'line',
            data: [
              [0.5, 0.5],
              [0.5, 3.5],
              [3.5, 3.5],
              [3.5, 0.5],
              [0.5, 0.5],
            ],
          }]
    
          let newTooltip = {
            enabled: true,
            formatter: function () {
              if (this.point.series.name === "Heatmap") return false
              var count = heatMapA[0][`zone${this.point.x}_${this.point.y}_count`];
              return `Batted Balls: ${count ? count : 0}`;
            },
          }
          state.tooltip = newTooltip;
          state.series = seriesData;
          state.title = {
            ...state.title,
            // text: 'Group A',
          }
            Highcharts.chart("pitcherHeatMapA", state);
        } else {
          state.series = [{showInLegend: false}];
          Highcharts.chart("pitcherHeatMapA", state);
        }
      } else {
        if (heatMapB.length !== 0) {
          const heatMapChartData = heatMapDataXformer(heatMapB);
          let seriesData = [{
            name: '',
            borderWidth: 1,
            data: heatMapChartData,
            dataLabels: {
              enabled: true,
              style: {
                color: '#FFFFFF',
                textOutline: '0px contrast',
                fontSize: '15px',
              },
              formatter() {
                if (this.point.value != 0) {
                  return this.point.value;
                }
                return null;
              },
            }
          },
          {
            name: 'Heatmap',
            color: 'black',
            type: 'line',
            data: [
              [0.5, 0.5],
              [0.5, 3.5],
              [3.5, 3.5],
              [3.5, 0.5],
              [0.5, 0.5],
            ],
          }]
    
          let newTooltip = {
            enabled: true,
            formatter: function () {
              if (this.point.series.name === "Heatmap") return false
              var count = heatMapB[0][`zone${this.point.x}_${this.point.y}_count`];
              return `Batted Balls: ${count ? count : 0}`;
            },
          }
          state.tooltip = newTooltip;
          state.series = seriesData;
          state.title = {
            ...state.title,
            // text: 'Group B',
          }
          Highcharts.chart("pitcherHeatMapB", state);
        } else {
          state.series = [{showInLegend: false}];
          Highcharts.chart("pitcherHeatMapB", state);
        }
      }
    } else {   
      if (heatMapA.length !== 0) {
        const heatMapChartData = heatMapDataXformer(heatMapA);
        let seriesData = [{
          name: '',
          borderWidth: 1,
          data: heatMapChartData,
          dataLabels: {
            enabled: true,
            style: {
              color: '#FFFFFF',
              textOutline: '0px contrast',
              fontSize: '15px',
            },
            formatter() {
              if (this.point.value != 0) {
                return this.point.value;
              }
              return null;
            },
          }
        },
        {
          name: 'Heatmap',
          color: 'black',
          type: 'line',
          data: [
            [0.5, 0.5],
            [0.5, 3.5],
            [3.5, 3.5],
            [3.5, 0.5],
            [0.5, 0.5],
          ],
        }]
  
        let newTooltip = {
          enabled: true,
          formatter: function () {
            if (this.point.series.name === "Heatmap") return false
            var count = heatMapA[0][`zone${this.point.x}_${this.point.y}_count`];
            return `Batted Balls: ${count ? count : 0}`;
          },
        }
        state.tooltip = newTooltip;
        state.series = seriesData;
        state.title = {
          ...state.title,
          // text: 'Group A',
        }
          Highcharts.chart("pitcherHeatMapA", state);
      } else {
        state.series = [{showInLegend: false}];
        Highcharts.chart("pitcherHeatMapA", state);
      }
  
      if (heatMapB.length !== 0) {
        const heatMapChartData = heatMapDataXformer(heatMapB);
        let seriesData = [{
          name: '',
          borderWidth: 1,
          data: heatMapChartData,
          dataLabels: {
            enabled: true,
            style: {
              color: '#FFFFFF',
              textOutline: '0px contrast',
              fontSize: '15px',
            },
            formatter() {
              if (this.point.value != 0) {
                return this.point.value;
              }
              return null;
            },
          }
        },
        {
          name: 'Heatmap',
          color: 'black',
          type: 'line',
          data: [
            [0.5, 0.5],
            [0.5, 3.5],
            [3.5, 3.5],
            [3.5, 0.5],
            [0.5, 0.5],
          ],
        }]
  
        let newTooltip = {
          enabled: true,
          formatter: function () {
            if (this.point.series.name === "Heatmap") return false
            var count = heatMapB[0][`zone${this.point.x}_${this.point.y}_count`];
            return `Batted Balls: ${count ? count : 0}`;
          },
        }
        state.tooltip = newTooltip;
        state.series = seriesData;
        state.title = {
          ...state.title,
          // text: 'Group B',
        }
        Highcharts.chart("pitcherHeatMapB", state);
      } else {
        state.series = [{showInLegend: false}];
        Highcharts.chart("pitcherHeatMapB", state);
      }
    }
  }, [heatMapA, heatMapB, s]);

  const onClickToggleButton = () => {
    setS({
      chartToggle: !s.chartToggle,
    });
  };

  return (
    <>
      {window.innerWidth < 1366 ? (
        <div className="row">
          {s.chartToggle === true ? (
            <div className="w-38">
              <div className="group-select-box">
                <h4>Group A</h4>
                <div className="group-select">
                  <Select
                    className="customize-select"
                    classNamePrefix="customize-select"
                    autosize
                    searchable={false}
                    clearable={false}
                    simpleValue
                    options={pitchType}
                    onChange={(e) =>
                      onChange(e, "developA", "pitcher_pitch_type")
                    }
                    // defaultValue={{ value: '', label: 'All Pitches' }}
                    value={pitchType.find(
                      (obj) => obj.value === reqA.pitcher_pitch_type
                    )}
                  />
                  <Select
                    className="customize-select"
                    classNamePrefix="customize-select"
                    autosize
                    searchable={false}
                    clearable={false}
                    simpleValue
                    options={sources}
                    onChange={(e) => onChange(e, "developA", "pitcher_source")}
                    value={sources.find(
                      (obj) => obj.value === reqA.pitcher_source
                    )}
                  />
                </div>
                <p className="chart-name-disc">Pitcher View</p>
              </div>
              <div className="heatmap">
                <div style={{ height: "492px" }} id="pitcherHeatMapA" />
              </div>
              {/* {heatMapA.length !== 0 ? (
            <div className="heatmap">
              <div style={{ height: "500px" }} id="pitcherHeatMapA" />
            </div>
          ) : (
            <NoGraph />
          )} */}
            </div>
          ) : (
            <div className="w-38">
              <div className="group-select-box">
                <h4>Group B</h4>
                <div className="group-select">
                  <Select
                    className="customize-select"
                    classNamePrefix="customize-select"
                    autosize
                    searchable={false}
                    clearable={false}
                    simpleValue
                    options={pitchType}
                    onChange={(e) =>
                      onChange(e, "developB", "pitcher_pitch_type")
                    }
                    // defaultValue={{ value: '', label: 'All Pitches' }}
                    value={pitchType.find(
                      (obj) => obj.value === reqB.pitcher_pitch_type
                    )}
                  />
                  <Select
                    className="customize-select"
                    classNamePrefix="customize-select"
                    autosize
                    searchable={false}
                    clearable={false}
                    simpleValue
                    options={sources}
                    onChange={(e) => onChange(e, "developB", "pitcher_source")}
                    value={sources.find(
                      (obj) => obj.value === reqB.pitcher_source
                    )}
                  />
                </div>
                <p className="chart-name-disc">Pitcher View</p>
              </div>
              <div className="heatmap">
                <div style={{ height: "492px" }} id="pitcherHeatMapB" />
              </div>
              {/* {heatMapB.length !== 0 ? (
            <div className="heatmap">
              <div style={{ height: "500px" }} id="pitcherHeatMapB" />
            </div>
          ) : (
            <NoGraph />
          )} */}
            </div>
          )}
          <div className="toggle-switch">
            <label className="switch">
              <input
                type="checkbox"
                id="togBtn"
                checked={s.chartToggle}
                onChange={onClickToggleButton}
              />
              <div className="slider round">
                <span className="on">Group A</span>
                <span className="off">Group B</span>
              </div>
            </label>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="w-38">
            <div className="group-select-box">
              <h4>Group A</h4>
              <div className="group-select">
                <Select
                  className="customize-select"
                  classNamePrefix="customize-select"
                  autosize
                  searchable={false}
                  clearable={false}
                  simpleValue
                  options={pitchType}
                  onChange={(e) =>
                    onChange(e, "developA", "pitcher_pitch_type")
                  }
                  // defaultValue={{ value: '', label: 'All Pitches' }}
                  value={pitchType.find(
                    (obj) => obj.value === reqA.pitcher_pitch_type
                  )}
                />
                <Select
                  className="customize-select"
                  classNamePrefix="customize-select"
                  autosize
                  searchable={false}
                  clearable={false}
                  simpleValue
                  options={sources}
                  onChange={(e) => onChange(e, "developA", "pitcher_source")}
                  value={sources.find(
                    (obj) => obj.value === reqA.pitcher_source
                  )}
                />
              </div>
              <p className="chart-name-disc">Pitcher View</p>
            </div>
            <div className="heatmap">
              <div style={{ height: "492px" }} id="pitcherHeatMapA" />
            </div>
            {/* {heatMapA.length !== 0 ? (
            <div className="heatmap">
              <div style={{ height: "500px" }} id="pitcherHeatMapA" />
            </div>
          ) : (
            <NoGraph />
          )} */}
          </div>
          <div className="w-38">
            <div className="group-select-box">
              <h4>Group B</h4>
              <div className="group-select">
                <Select
                  className="customize-select"
                  classNamePrefix="customize-select"
                  autosize
                  searchable={false}
                  clearable={false}
                  simpleValue
                  options={pitchType}
                  onChange={(e) =>
                    onChange(e, "developB", "pitcher_pitch_type")
                  }
                  // defaultValue={{ value: '', label: 'All Pitches' }}
                  value={pitchType.find(
                    (obj) => obj.value === reqB.pitcher_pitch_type
                  )}
                />
                <Select
                  className="customize-select"
                  classNamePrefix="customize-select"
                  autosize
                  searchable={false}
                  clearable={false}
                  simpleValue
                  options={sources}
                  onChange={(e) => onChange(e, "developB", "pitcher_source")}
                  value={sources.find(
                    (obj) => obj.value === reqB.pitcher_source
                  )}
                />
              </div>
              <p className="chart-name-disc">Pitcher View</p>
            </div>
            <div className="heatmap">
              <div style={{ height: "492px" }} id="pitcherHeatMapB" />
            </div>
            {/* {heatMapB.length !== 0 ? (
            <div className="heatmap">
              <div style={{ height: "500px" }} id="pitcherHeatMapB" />
            </div>
          ) : (
            <NoGraph />
          )} */}
          </div>
        </div>
      )}
    </>
  );
}

export default HitterHeatMap;
