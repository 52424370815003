import React, { Component } from "react";
import { imagesArr } from "../../../assets/images";
import { connect } from "react-redux";
import { getGameSummaryData } from "../../../store/actions";
import HomeBattedBallsTable from "../../../components/CommanTable/BattedBallsTable";
import HomePitchersMainChart from "../../../components/Charts/PitchersMainChart";
import HomePitcherReleasePointChart from "./../../../components/Charts/releasePointChart";
import HomePitcherStrikeZoneChart from "./../../../components/Charts/strikeZoneChart";
import HomePitcherSideReleaseChart from "../../../components/Charts/sideReleaseChart";
import HomePitcherPitchSequencingChart from "../../../components/Charts/pitchSequencingChart";
import HomePitcherCountSequencingChart from "../../../components/Charts/pitchSequencingChart";
import HomePitcherResultSequencingChart from "../../../components/Charts/pitchSequencingChart";
import Select from "react-select";
import { printToPDFMultiple } from "../../../shared/printReportUtils";
import Loader from "../../../shared/loader";
import ResultsTable from "../../../components/CommanTable/ResultsTable";
import SummaryTable from "../../../components/CommanTable/SummaryTable";
import PitchCharacteristicsTable from "../../../components/CommanTable/PitchCharacteristics";
import PlateDisciplineTable from "../../../components/CommanTable/PlateDisciplineTable";
import HomePitcherPolarChart from "../../../components/Charts/PolarChart";
import HomePitcherLongBreakMovementChart from './../../../components/Charts/breakMovementChart';
import HomePitcherShortBreakMovementChart from './../../../components/Charts/breakMovementChart';
import HomePitcherSwingsStrikeZoneChart from './../../../components/Charts/GSStrikeZoneChart';
import HomePitcherWhiffsStrikeZoneChart from './../../../components/Charts/GSStrikeZoneChart';
import HomePitcherBallsInPlayStrikeZoneChart from './../../../components/Charts/GSStrikeZoneChart';
import { xformBreakMovementChart } from "../../../shared/function";

class HomePitchers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: "",
      homeResultData: [],
      homePitchSummaryTableData: [],
      homeDisciplineTableData: [],
      homeCharacteristicTableData: [],
      homeSplineChartData: [],
      homeStrikeZoneData: [],
      homeReleasePointChartData: [],
      homeSideReleasePointChartData: [],
      homePitchersSequenceChart: [],
      homeCountSequenceChart: [],
      homeResultSequenceChart: [],
      homeLongBubbleBreakMovement: [],
      homeShortBubbleBreakMovement: [],
      homeLongDotBreakMovement: [],
      homeShortDotBreakMovement: [],
      homePolarChartData: [],
      homeBattedBallsTable: [],
      homeSwingsChartData: [],
      homeWhiffsChartData: [],
      homeBallsInPlayChartData: [],
      pitchersData: [],
      isPrinting: false,
      backUP: null,
      spline_chart: "velocity",
      isDownloadImg: false
    };
  }

  componentDidMount() {
    let sorceid = document.location.href.split("/")[4];
    if (sorceid === undefined) window.location.href = "/";
    else this.homePitchersData("home_pitchers", "", false);
  }

  componentDidUpdate = () => {
    if (this.props.appState.homePitcherfilterFlag) {
      if (this.props.homePitcherFilter[0] !== this.state.pitchersData[0]?.value) {
      let sorceid = document.location.href.split("/")[4];
      if (sorceid === undefined) window.location.href = "/";
      else this.homePitchersData("home_pitchers", "", true);
    }
    }
  }

  // fetch Pitch log data
  homePitchersData = (activeTab, pitchersName, flag) => {
    let data = {
      sourceId: document.location.href.split("/")[4],
      tab: activeTab,
      filter: {
        pitcher_name: this.props.appState.homePitcherfilterFlag ? this.props.homePitcherFilter[0] : pitchersName ? pitchersName : this.props.appState.homePitcherName,
        batter_name: "",
      },
      // printflag: this.props?.isPrint
    };
    // this.props.increment();
    this.props.fetchSummaryData(data, (res) => {
      this.setState({
        homeResultData: res.data.result.pitcher_result_data,
        homePitchSummaryTableData: res.data.result.pitcher_summary_table_data,
        homeDisciplineTableData: res.data.result.pitcher_plate_discipline_table_data,
        homeCharacteristicTableData: res.data.result.pitcher_characteristic_table_data,
        homeSplineChartData: res.data.result.pitcher_spline_chart_data,
        homeStrikeZoneData: res.data.result.pitchers_strike_zone_data,
        homeReleasePointChartData: res.data.result.pitcher_release_data,
        homeSideReleasePointChartData: res.data.result.pitcher_side_release_data,
        homePitchersSequenceChart: res.data.result.pitch_sequence_chart_data,
        homeCountSequenceChart: res.data.result.count_sequencing_chart_data,
        homeResultSequenceChart: res.data.result.result_sequencing_chart_data,
        homeLongDotBreakMovement: res.data.result.pitcher_dot_chart_long_data,
        homeShortDotBreakMovement: res.data.result.pitcher_dot_chart_short_data,
        homeLongBubbleBreakMovement: res.data.result.pitcher_break_movement_long_data,
        homeShortBubbleBreakMovement: res.data.result.pitcher_break_movement_short_data,
        homePolarChartData: res.data.result.pitcher_polar_movement_chart_data,
        homeBattedBallsTable: res.data.result.pitcher_batted_ball_data,
        homeSwingsChartData: res.data.result.pitchers_swing_strike_zone_data,
        homeWhiffsChartData: res.data.result.pitchers_whiff_strike_zone_data,
        homeBallsInPlayChartData: res.data.result.pitchers_balls_inplay_strike_zone_data,
        backUP: res.data.result,
        pitcherDefault: this.props.appState.homePitcherfilterFlag ? this.props.homePitcherFilter[0] : pitchersName ? pitchersName : this.props.appState.homePitcherName,
      });

      // for load piteher's wise data
      let pitcherData = this.props.homePitcherFilter;

      let pitchersSelectData = [];
      pitcherData.map((data) => {
        pitchersSelectData.push({
          value: data,
          label: data,
        });
      });
      this.setState({ pitchersData: pitchersSelectData });
      // this.props.decrement();
      // if (!this.props.isStopPrint && flag) { 
      //   this.props.reduceDataCount()
      // }
    });
  };

  // decrease = () => {
  //   if (!this.props.isStopPrint && this.props.isPrint) {
  //     this.props?.reduceDataCount()
  //   }
  // }

  handleCallback = (index) => {
    this.setState({
      tabs: index,
    });
  };

  // change pitchers wise data
  handleChange = (e) => {
    this.props.appState.homePitcherfilterFlag = false
    this.homePitchersData("home_pitchers", e.value, true);
  };

  // metric change y axis of rolling line chart
  metricHandleChange = (e) => {
    const { name, value } = e.target
    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  print = async () => {
    await this.setState({
      isPrinting: true,
      homePolarChartData: Object.assign([], this.state.backUP.pitcher_polar_movement_chart_data),
      homeLongDotBreakMovement: Object.assign([], this.state.backUP.pitcher_dot_chart_long_data),
      homeShortDotBreakMovement: Object.assign([], this.state.backUP.pitcher_dot_chart_short_data),
      homeLongBubbleBreakMovement: Object.assign([], this.state.backUP.pitcher_break_movement_long_data),
      homeShortBubbleBreakMovement: Object.assign([], this.state.backUP.pitcher_break_movement_short_data),
      homeCountSequenceChart: Object.assign([], this.state.backUP.count_sequencing_chart_data),
      homeResultSequenceChart: Object.assign([], this.state.backUP.result_sequencing_chart_data),
      homePitchersSequenceChart: Object.assign([], this.state.backUP.pitch_sequence_chart_data),
      homeSideReleasePointChartData: Object.assign([], this.state.backUP.pitcher_side_release_data),
      homeReleasePointChartData: Object.assign([], this.state.backUP.pitcher_release_data),
      homeStrikeZoneData: Object.assign([], this.state.backUP.pitchers_strike_zone_data),
      homeSplineChartData: Object.assign([], this.state.backUP.pitcher_spline_chart_data),
      homeSwingsChartData: Object.assign([], this.state.backUP.pitchers_swing_strike_zone_data),
      homeWhiffsChartData: Object.assign([], this.state.backUP.pitchers_whiff_strike_zone_data),
      homeBallsInPlayChartData: Object.assign([], this.state.backUP.pitchers_balls_inplay_strike_zone_data),
    })
    setTimeout(async () => {
      await printToPDFMultiple(`homePitchers`, [
        'tablePrintArea',
        'homePitchersMainChartPrintArea',
        'releaseStrikeChartPrintArea',
        'GameStrikeChartPrintArea',
        'sequencingChartPrintArea',
        'movementChartPrintArea',
        'homePitcherTable'
      ]);
      this.setState({
        isPrinting: false,
        homePolarChartData: Object.assign([], this.state.homePolarChartData),
        homeLongDotBreakMovement: Object.assign([], this.state.homeLongDotBreakMovement),
        homeShortDotBreakMovement: Object.assign([], this.state.homeShortDotBreakMovement),
        homeLongBubbleBreakMovement: Object.assign([], this.state.homeLongBubbleBreakMovement),
        homeShortBubbleBreakMovement: Object.assign([], this.state.homeShortBubbleBreakMovement),
        homeCountSequenceChart: Object.assign([], this.state.homeCountSequenceChart),
        homeResultSequenceChart: Object.assign([], this.state.homeResultSequenceChart),
        homePitchersSequenceChart: Object.assign([], this.state.homePitchersSequenceChart),
        homeSideReleasePointChartData: Object.assign([], this.state.homeSideReleasePointChartData),
        homeReleasePointChartData: Object.assign([], this.state.homeReleasePointChartData),
        homeStrikeZoneData: Object.assign([], this.state.homeStrikeZoneData),
        homeSplineChartData: Object.assign([], this.state.homeSplineChartData),
        homeSwingsChartData: Object.assign([], this.state.homeSwingsChartData),
        homeWhiffsChartData: Object.assign([], this.state.homeWhiffsChartData),
        homeBallsInPlayChartData: Object.assign([], this.state.homeBallsInPlayChartData),
      })
    }, 1000);
  }

  printAll = () => {
    this.props.printAll();
    this.props.appState.homePitcherName = this.state.pitcherDefault
  }

  render() {
    const tabs = this.state.tabs;
    let {
      homeResultData,
      homePitchSummaryTableData,
      homeDisciplineTableData,
      homeCharacteristicTableData,
      homeSplineChartData,
      homeStrikeZoneData,
      homeReleasePointChartData,
      homeSideReleasePointChartData,
      homeCountSequenceChart,
      homeResultSequenceChart,
      homePitchersSequenceChart,
      homePolarChartData,
      homeLongDotBreakMovement,
      homeShortDotBreakMovement,
      homeLongBubbleBreakMovement,
      homeShortBubbleBreakMovement,
      homeBattedBallsTable,
      pitchersData,
      homeSwingsChartData,
      homeWhiffsChartData,
      homeBallsInPlayChartData
    } = this.state;

    const bubbleChartDataShort =
      homeShortBubbleBreakMovement ? homeShortBubbleBreakMovement.length !== 0
        ? xformBreakMovementChart(homeShortBubbleBreakMovement, 'pitch_type')
        : [] : [];

    const bubbleChartDataLong =
      homeLongBubbleBreakMovement ? homeLongBubbleBreakMovement.length !== 0
        ? xformBreakMovementChart(homeLongBubbleBreakMovement, 'pitch_type')
        : [] : [];

    const dotChartDataShort =
      homeShortDotBreakMovement && homeShortDotBreakMovement.length !== 0
        ? xformBreakMovementChart(homeShortDotBreakMovement, 'pitch_type')
        : [];

    const dotChartDataLong =
      homeLongDotBreakMovement && homeLongDotBreakMovement.length !== 0
        ? xformBreakMovementChart(homeLongDotBreakMovement, 'pitch_type')
        : [];

    return (
      <>
        {this.state.isPrinting && <Loader />}
        {this.state.isDownloadImg && <Loader text={true}/>}
        <section className="pb-0">
          <div className="container-fluid">
            <div className="common-title withsearch">
              <h2>Home Pitchers</h2>
              <div className="right-side">
                <div className="serch-box">
                  <Select
                    className="customize-select"
                    classNamePrefix="customize-select"
                    onChange={this.handleChange.bind(this)}
                    options={pitchersData}
                    value={pitchersData.find((obj) => obj.value === this.state.pitcherDefault)}
                  // defaultValue={{
                  //   label: "All Pitchers",
                  //   value: "",
                  // }}
                  />
                </div>
                {!(this.state.isPrinting || this.props.isPrint) && <div className="dropdown">
                  <span className="printer"><img src={imagesArr.printer} alt="print" /></span>
                  <div className="dropdown-content">
                    <ul>
                      <li onClick={() => this.print()}>Print</li>
                      {/* <li onClick={() => this.printAll()}>Print All</li> */}
                    </ul>
                  </div>
                </div>}
              </div>
            </div>

            {/* pitcher tables */}
            <div id="tablePrintArea">
              {(this.state.isPrinting || this.props.isPrint) ? <h3>{this.state.pitcherDefault}</h3> : ""}
              <div>
                <ResultsTable tableData={homeResultData} />
              </div>
              <div>
                <div className="ss-pitcher-summary-table">
                  <SummaryTable tableData={homePitchSummaryTableData} />
                </div>
              </div>
              <div>
                <div className="ss-pitcher-platediscipline-table">
                  <PlateDisciplineTable tableData={homeDisciplineTableData} />
                </div>
              </div>
              <div>
                <div className="ss-pitcher-characteristics-table">
                  <PitchCharacteristicsTable tableData={homeCharacteristicTableData} />
                </div>
              </div>
            </div>

            {/* charts */}
            <div className="common-charts" id="homePitchersMainChartPrintArea">
              <HomePitchersMainChart id="homePitcherChart"
                printAll={this.props.isPrint}
                chartData={homeSplineChartData}
                metricHandleChange={this.metricHandleChange}
                spline_chart={this.state.spline_chart}
                // decreasegame={this.decrease}
                increment={this.props.increment}
                decrement={this.props.decrement}
                eventName={this.props.eventName}
                pitcherDefault={this.state.pitcherDefault}
                profilepage={false}
                isNotDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: false }))}
                isDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: true }))}
              />
            </div>
          </div>

          <div className="container-fluid" id="releaseStrikeChartPrintArea">
            <div className="common-charts">
              <div className={`row ${!(this.state.isPrinting || this.props.isPrint) ? 'singleDiv' : 'breakDiv'}`}>
                <div className="w-33">
                  <HomePitcherStrikeZoneChart
                    chartData={homeStrikeZoneData}
                    id="strikeZoneChartHomePitcher"
                    showTitle={true}
                    cameraAngle={["Strike Zone", "Catcher", "Home Top", "Home 3B Side", "Home 1B Side", "Press Box"]}
                    visualType="Pitching"
                    increment={this.props.increment}
                    decrement={this.props.decrement}
                    eventName={this.props.eventName}
                    pitcherDefault={this.state.pitcherDefault}
                    profilepage={false}
                    isNotDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: false }))}
                    isDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: true }))}
                  />
                </div>
                <div className="w-33">
                  <HomePitcherReleasePointChart
                    chartData={homeReleasePointChartData}
                    id="releasePointChartHomePitcher"
                    showTitle={true}
                    cameraAngle={["Pitcher", "Mound Top", "Mound 1B Side", "Mound 3B Side", "First Base", "Third Base"]}
                    visualType="Release" 
                    increment={this.props.increment} 
                    decrement={this.props.decrement}
                    eventName={this.props.eventName}
                    pitcherDefault={this.state.pitcherDefault}
                    profilepage={false}
                    isNotDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: false }))}
                    isDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: true }))}
                  />
                </div>
                <div className="w-33">
                  <HomePitcherSideReleaseChart
                    chartData={homeSideReleasePointChartData}
                    id="sideReleasePointChartHomePitcher"
                    showTitle={true}
                    cameraAngle={["Mound 3B Side", "Mound 1B Side", "Mound Top", "Pitcher", "Third Base", "First Base"]}
                    visualType="Release"
                    increment={this.props.increment}
                    decrement={this.props.decrement}
                    eventName={this.props.eventName}
                    pitcherDefault={this.state.pitcherDefault}
                    profilepage={false}
                    isNotDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: false }))}
                    isDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: true }))}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid" id="GameStrikeChartPrintArea">
            <div className="common-charts">
              <div className={`row ${!(this.state.isPrinting || this.props.isPrint) ? 'singleDiv' : 'breakDiv'}`}>
                <div className="w-33">
                  <HomePitcherSwingsStrikeZoneChart
                    chartData={homeSwingsChartData}
                    id="strikeZoneChartHomePitcherSwings"
                    title="Swings"
                    showTitle={true}
                    cameraAngle={["Strike Zone", "Catcher", "Home Top", "Home 3B Side", "Home 1B Side", "Press Box"]}
                    visualType="Pitching"
                    increment={this.props.increment}
                    decrement={this.props.decrement}
                    eventName={this.props.eventName}
                    pitcherDefault={this.state.pitcherDefault}
                    profilepage={false}
                    isNotDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: false }))}
                    isDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: true }))}
                  />
                </div>
                <div className="w-33">
                  <HomePitcherWhiffsStrikeZoneChart
                    chartData={homeWhiffsChartData}
                    id="strikeZoneChartHomePitcherWhiffs"
                    title="Whiffs"
                    showTitle={true}
                    cameraAngle={["Strike Zone", "Catcher", "Home Top", "Home 3B Side", "Home 1B Side", "Press Box"]}
                    visualType="Pitching"
                    increment={this.props.increment}
                    decrement={this.props.decrement}
                    eventName={this.props.eventName}
                    pitcherDefault={this.state.pitcherDefault}
                    profilepage={false}
                    isNotDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: false }))}
                    isDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: true }))}
                  />
                </div>
                <div className="w-33">
                  <HomePitcherBallsInPlayStrikeZoneChart
                    chartData={homeBallsInPlayChartData}
                    id="strikeZoneChartHomePitcherBallsInPlay"
                    title="Balls In Play"
                    showTitle={true}
                    cameraAngle={["Strike Zone", "Catcher", "Home Top", "Home 3B Side", "Home 1B Side", "Press Box"]}
                    visualType="Pitching"
                    increment={this.props.increment}
                    decrement={this.props.decrement}
                    eventName={this.props.eventName}
                    pitcherDefault={this.state.pitcherDefault}
                    profilepage={false}
                    isNotDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: false }))}
                    isDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: true }))}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid" id="sequencingChartPrintArea">
            <div className="common-charts">
              <div className={`row ${!(this.state.isPrinting || this.props.isPrint) ? 'singleDiv' : 'breakDiv'}`}>
                <div className="w-33">
                  <HomePitcherPitchSequencingChart
                    chartData={homePitchersSequenceChart}
                    id="PitchSequencingChartHomePitcher"
                    showTitle={true}
                    increment={this.props.increment}
                    decrement={this.props.decrement}
                    eventName={this.props.eventName}
                    pitcherDefault={this.state.pitcherDefault}
                    profilepage={false}
                    isNotDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: false }))}
                    isDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: true }))}
                  />
                </div>
                <div className="w-33">
                  <HomePitcherCountSequencingChart
                    chartData={homeCountSequenceChart}
                    title="Count"
                    id="countSequencingChartHomePitcher"
                    showTitle={true}
                    increment={this.props.increment}
                    decrement={this.props.decrement}
                    eventName={this.props.eventName}
                    pitcherDefault={this.state.pitcherDefault}
                    profilepage={false}
                    isNotDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: false }))}
                    isDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: true }))}
                  />
                </div>
                <div className="w-33">
                  <HomePitcherResultSequencingChart
                    chartData={homeResultSequenceChart}
                    title="Result"
                    id="resultSequencingChartHomePitcher"
                    showTitle={true}
                    increment={this.props.increment}
                    decrement={this.props.decrement}
                    eventName={this.props.eventName}
                    pitcherDefault={this.state.pitcherDefault}
                    profilepage={false}
                    isNotDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: false }))}
                    isDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: true }))}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid" id="movementChartPrintArea">
            <div className="common-charts">
              <div className={`row ${!(this.state.isPrinting || this.props.isPrint) ? 'singleDiv' : 'breakDiv'}`}>
                <div className="w-33">
                  <HomePitcherLongBreakMovementChart
                    id="longBreakMovementChartHomePitcher"
                    form="Long-Form" top={55}
                    bottom={15}
                    className="breakMovementChart"
                    bubbleChartData={bubbleChartDataLong}
                    dotChartData={dotChartDataLong}
                    increment={this.props.increment}
                    decrement={this.props.decrement}
                    eventName={this.props.eventName}
                    pitcherDefault={this.state.pitcherDefault}
                    profilepage={false}
                    isNotDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: false }))}
                    isDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: true }))}
                  />
                </div>
                <div className="w-33">
                  <HomePitcherShortBreakMovementChart
                    id="shortBreakMovementChartHomePitcher"
                    form="Short-Form" top={30} bottom={55}
                    className="breakMovementChart"
                    bubbleChartData={bubbleChartDataShort}
                    dotChartData={dotChartDataShort}
                    increment={this.props.increment}
                    decrement={this.props.decrement}
                    eventName={this.props.eventName}
                    pitcherDefault={this.state.pitcherDefault}
                    profilepage={false}
                    isNotDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: false }))}
                    isDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: true }))}
                  />
                </div>
                <div className="w-33">
                  <HomePitcherPolarChart
                    chartData={homePolarChartData}
                    id="polarChartchartHomePitcher"
                    increment={this.props.increment}
                    decrement={this.props.decrement}
                    eventName={this.props.eventName}
                    pitcherDefault={this.state.pitcherDefault}
                    profilepage={false}
                    isNotDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: false }))}
                    isDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: true }))}
                  />
                </div>
              </div>
            </div>
          </div>

          <div id="homePitcherTable">
            <div className="ss-pitcher-battedballs-table">
              <HomeBattedBallsTable tableData={homeBattedBallsTable} />
            </div>
          </div>
        </section>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    summaryData: state.summaryData,
    appState: state.app
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchSummaryData: (data, next) => dispatch(getGameSummaryData(data, next)),
});
export default connect(mapStateToProps, mapDispatchToProps)(HomePitchers);
