import React, { useState } from "react";
import PropTypes from "prop-types";
import { constant } from "../../../../shared/constant";

function EdgeAccuracyTable({ tableData }) {
  return (
    <div className="container-fluid">
      <h3 className="table-title text-center">Edge Accuracy</h3>
      <div className="homepitchers-table">
        <div className="table-responsive-xl">
          <table className="table tabledashed">
            <thead>
              <tr>
                <th className="tablerowblue fw-500">Pitch Type</th>
                <th className="tablerowblue fw-500">Called Edge</th>
                <th className="tablerowblue fw-500">Called Strikes</th>
                <th className="tablerowblue fw-500">Edge Strike%</th>
              </tr>
            </thead>
            <tbody>
              {
                tableData && tableData.length !== 0 ?
                  tableData.map(_ => {
                    const rowColor = _._id === "Total" ? "table-rowgrey80" : "table-rowwhite"
                    return (
                      <tr>
                        <td className={`${rowColor}`} style={{ color: constant.pitchTypeColor[_._id] }}>{_ && _._id}</td>
                        <td className={`${rowColor}`}>{_ && _.called_edge}</td>
                        <td className={`${rowColor}`}>{_ && _.called_strikes}</td>
                        <td className={`${rowColor}`}>{_ && _.edge_strikes && _.edge_strikes.toFixed(1) + "%"}</td>
                      </tr>
                    )
                  })
                  :
                  <tr>
                    <td className="table-rowwhite text-center" colSpan="4">There's no info yet!</td>
                  </tr>
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

EdgeAccuracyTable.propTypes = {};

export default EdgeAccuracyTable;
