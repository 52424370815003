import React from "react";
import { Link } from "react-router-dom";
import { stringValueSort, getSortedValues, createPercentageColumnObj, stringValueSortByfixTeam } from "../../shared/function";
import { Table } from "antd";
import PrintTable from "./common/printTable";
import { constant } from "../../shared/constant";
import { DownOutlined, UpOutlined,RightOutlined } from "@ant-design/icons";

function PitchersPitchmetrics({ tableData, isPrinting }) {
    var allRows = [];
    tableData &&
        tableData.map((list, i) =>
            allRows.push({
                key: i,
                HittersName: list.pitcher_name,
                name: { batterName: list?.pitcher_name, batterId: list?._id },
                throws: list?.throws || "—",
                bbe: list?.bbe || "—",
                avg_velocity: list.avg_velocity && list.avg_velocity ? Number(list.avg_velocity.toFixed(1)) : "—",
                peak_velocity: list.peak_velocity && list.peak_velocity ? Number(list.peak_velocity.toFixed(1)) : "—",
                spin_rate: list.spin_rate && list.spin_rate ? Math.round(list.spin_rate) : "—",
                efficiency: list.efficiency && list.efficiency ? Math.round(list.efficiency) : "—",
                tilt: list?.spin_tilt || "—",
                spin_axis: list.spin_axis && list.spin_axis ? Math.round(list.spin_axis) : "—",
                v_break: list.v_break && list.v_break ? Number(list.v_break.toFixed(1)) : "—",
                ind_v_break: list.ind_v_break && list.ind_v_break ? Number(list.ind_v_break.toFixed(1)) : "—",
                h_break: list.h_break && list.h_break ? Number(list.h_break.toFixed(1)) : "—",
                vert_appr_angle: list.vert_appr_angle && list.vert_appr_angle ? Number(list.vert_appr_angle.toFixed(1)) : "—",
                horz_approach_angle: list.horz_approach_angle && list.horz_approach_angle ? Number(list.horz_approach_angle.toFixed(1)) : "—",
                pitch_data : list.pitch_data
            })
        );

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            sorter: (a, b) => stringValueSort(a["name"].batterName, b["name"].batterName),
            sortDirections: ['descend', 'ascend'],
            fixed: isPrinting ? '' :'left',
            render: (text, record) => {
                return {
                    props: {
                        style: { background: record.HittersName === "Team" ? "LightGray" : "" }
                    },
                    children: <>
                        {record.HittersName === "Team" ?
                            <div>{text.batterName}</div> :
                            <Link to={record.HittersName === "Team" ? "" : `/profiledashboard/${text.batterId}`}>
                                {/* <img src={record.batterProfile} alt="icon" className="d-inline icon" /> */}
                                {text.batterName}
                            </Link>
                        }
                    </>
                }
            }
        },
        Table.EXPAND_COLUMN,
        {
            title: "Throws",
            dataIndex: "throws",
            sorter: (a, b) => stringValueSortByfixTeam(a, b, "throws"),
            sortDirections: ['descend', 'ascend'],
            render(text, record) {
                return {
                    props: {
                        style: { background: record.HittersName === "Team" ? "LightGray" : "" }
                    },
                    children: <div>{text}</div>
                };
            }
        },
        {
            title: "#",
            dataIndex: "bbe",
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "bbe"),
            sortDirections: ['descend', 'ascend'],
            render(text, record) {
                return {
                    props: {
                        style: { background: record.HittersName === "Team" ? "LightGray" : "" }
                    },
                    children: <div>{text}</div>
                };
            }
        },
        {
            title: "Avg Velo",
            dataIndex: "avg_velocity",
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "avg_velocity"),
            sortDirections: ['descend', 'ascend'],
            render(text, record) {
                return {
                    props: {
                        style: { background: record.HittersName === "Team" ? "LightGray" : "" }
                    },
                    children: <div>{text}</div>
                };
            }
        },
        {
            title: "Peak Velo",
            dataIndex: "peak_velocity",
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "peak_velocity"),
            sortDirections: ['descend', 'ascend'],
            render(text, record) {
                return {
                    props: {
                        style: { background: record.HittersName === "Team" ? "LightGray" : "" }
                    },
                    children: <div>{text}</div>
                };
            }
        },
        {
            title: "Avg Spin",
            dataIndex: "spin_rate",
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "spin_rate"),
            sortDirections: ['descend', 'ascend'],
            render(text, record) {
                return {
                    props: {
                        style: { background: record.HittersName === "Team" ? "LightGray" : "" }
                    },
                    children: <div>{text}</div>
                };
            }
        },
        createPercentageColumnObj('Efficiency', 'efficiency', allRows, true, {
            className: "tablegraybp",
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "efficiency"),
            sortDirections: ['descend', 'ascend'],
        }),
        {
            title: 'Tilt',
            dataIndex: 'tilt',
            sorter: (a, b) => {
                if (!b.tilt) return -1;
                a = (a.tilt.split(":")[0] * 360) / 12 + (a.tilt.split(":")[1] * 360) / (12 * 60)
                b = (b.tilt.split(":")[0] * 360) / 12 + (b.tilt.split(":")[1] * 360) / (12 * 60)
                return a > b ? 1 : -1
            },
            sortDirections: ['descend', 'ascend'],
            render(text, record) {
                return {
                    props: {
                        style: { background: record.HittersName === "Team" ? "LightGray" : "" }
                    },
                    children: <div>{text}</div>
                };
            }
        },
        {
            title: "Axis",
            dataIndex: "spin_axis",
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "spin_axis"),
            sortDirections: ['descend', 'ascend'],
            render(text, record) {
                return {
                    props: {
                        style: { background: record.HittersName === "Team" ? "LightGray" : "" }
                    },
                    children: <div>{text}</div>
                };
            }
        },
        {
            title: "V Break",
            dataIndex: "v_break",
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "v_break"),
            sortDirections: ['descend', 'ascend'],
            render(text, record) {
                return {
                    props: {
                        style: { background: record.HittersName === "Team" ? "LightGray" : "" }
                    },
                    children: <div>{text}</div>
                };
            }
        },
        {
            title: "Induced V",
            dataIndex: "ind_v_break",
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "ind_v_break"),
            sortDirections: ['descend', 'ascend'],
            render(text, record) {
                return {
                    props: {
                        style: { background: record.HittersName === "Team" ? "LightGray" : "" }
                    },
                    children: <div>{text}</div>
                };
            }
        },
        {
            title: "H Break",
            dataIndex: "h_break",
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "h_break"),
            sortDirections: ['descend', 'ascend'],
            render(text, record) {
                return {
                    props: {
                        style: { background: record.HittersName === "Team" ? "LightGray" : "" }
                    },
                    children: <div>{text}</div>
                };
            }
        },
        {
            title: "V Approach",
            dataIndex: "vert_appr_angle",
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "vert_appr_angle"),
            sortDirections: ['descend', 'ascend'],
            render(text, record) {
                return {
                    props: {
                        style: { background: record.HittersName === "Team" ? "LightGray" : "" }
                    },
                    children: <div>{text}</div>
                };
            }
        },
        {
            title: "H Approach",
            dataIndex: "horz_approach_angle",
            sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "horz_approach_angle"),
            sortDirections: ['descend', 'ascend'],
            render(text, record) {
                return {
                    props: {
                        style: { background: record.HittersName === "Team" ? "LightGray" : "" }
                    },
                    children: <div>{text}</div>
                };
            }
        }
    ]

    const data = allRows;
    const renderInnerRows = (row) => {
        var childRows = [];
        row?.pitch_data && row?.pitch_data.map((items, j) => {
            childRows.push({
                key: j,
                id: items._id,
                HittersName: items.pitcher_name,
                name: { batterName: items?.pitcher_name, batterId: items?._id },
                throws: items?.throws || "—",
                bbe: items?.bbe || "—",
                avg_velocity: items.avg_velocity && items.avg_velocity ? Number(items.avg_velocity.toFixed(1)) : "—",
                peak_velocity: items.peak_velocity && items.peak_velocity ? Number(items.peak_velocity.toFixed(1)) : "—",
                spin_rate: items.spin_rate && items.spin_rate ? Math.round(items.spin_rate) : "—",
                efficiency: items.efficiency && items.efficiency ? Math.round(items.efficiency) : "—",
                tilt: items?.spin_tilt || "—",
                spin_axis: items.spin_axis && items.spin_axis ? Math.round(items.spin_axis) : "—",
                v_break: items.v_break && items.v_break ? Number(items.v_break.toFixed(1)) : "—",
                ind_v_break: items.ind_v_break && items.ind_v_break ? Number(items.ind_v_break.toFixed(1)) : "—",
                h_break: items.h_break && items.h_break ? Number(items.h_break.toFixed(1)) : "—",
                vert_appr_angle: items.vert_appr_angle && items.vert_appr_angle ? Number(items.vert_appr_angle.toFixed(1)) : "—",
                horz_approach_angle: items.horz_approach_angle && items.horz_approach_angle ? Number(items.horz_approach_angle.toFixed(1)) : "—",
            })
        })

        const inner_columns = [
            {
                title: "PitchType",
                dataIndex: "id",
                render(text, record) {
                    return {
                      props: {
                        style: { color: constant.pitchTypeColor[text] }
                      },
                      children: <div>{text}</div>
                    };
                  }
            },
            {
                title: "",
                dataIndex: "",
            },
            {
                title: "",
                dataIndex:"",
            },
            {
                title: "#",
                dataIndex: "bbe",
                sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "bbe"),
                sortDirections: ['descend', 'ascend'],
                render(text, record) {
                    return {
                        props: {
                            style: { background: record.HittersName === "Team" ? "LightGray" : "" }
                        },
                        children: <div>{text}</div>
                    };
                }
            },
            {
                title: "Avg Velo",
                dataIndex: "avg_velocity",
                sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "avg_velocity"),
                sortDirections: ['descend', 'ascend'],
                render(text, record) {
                    return {
                        props: {
                            style: { background: record.HittersName === "Team" ? "LightGray" : "" }
                        },
                        children: <div>{text}</div>
                    };
                }
            },
            {
                title: "Peak Velo",
                dataIndex: "peak_velocity",
                sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "peak_velocity"),
                sortDirections: ['descend', 'ascend'],
                render(text, record) {
                    return {
                        props: {
                            style: { background: record.HittersName === "Team" ? "LightGray" : "" }
                        },
                        children: <div>{text}</div>
                    };
                }
            },
            {
                title: "Avg Spin",
                dataIndex: "spin_rate",
                sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "spin_rate"),
                sortDirections: ['descend', 'ascend'],
                render(text, record) {
                    return {
                        props: {
                            style: { background: record.HittersName === "Team" ? "LightGray" : "" }
                        },
                        children: <div>{text}</div>
                    };
                }
            },
            createPercentageColumnObj('Efficiency', 'efficiency', allRows, true, {
                className: "tablegraybp",
                sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "efficiency"),
                sortDirections: ['descend', 'ascend'],
            }),
            {
                title: 'Tilt',
                dataIndex: 'tilt',
                sorter: (a, b) => {
                    if (!b.tilt) return -1;
                    a = (a.tilt.split(":")[0] * 360) / 12 + (a.tilt.split(":")[1] * 360) / (12 * 60)
                    b = (b.tilt.split(":")[0] * 360) / 12 + (b.tilt.split(":")[1] * 360) / (12 * 60)
                    return a > b ? 1 : -1
                },
                sortDirections: ['descend', 'ascend'],
                render(text, record) {
                    return {
                        props: {
                            style: { background: record.HittersName === "Team" ? "LightGray" : "" }
                        },
                        children: <div>{text}</div>
                    };
                }
            },
            {
                title: "Axis",
                dataIndex: "spin_axis",
                sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "spin_axis"),
                sortDirections: ['descend', 'ascend'],
                render(text, record) {
                    return {
                        props: {
                            style: { background: record.HittersName === "Team" ? "LightGray" : "" }
                        },
                        children: <div>{text}</div>
                    };
                }
            },
            {
                title: "V Break",
                dataIndex: "v_break",
                sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "v_break"),
                sortDirections: ['descend', 'ascend'],
                render(text, record) {
                    return {
                        props: {
                            style: { background: record.HittersName === "Team" ? "LightGray" : "" }
                        },
                        children: <div>{text}</div>
                    };
                }
            },
            {
                title: "Induced V",
                dataIndex: "ind_v_break",
                sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "ind_v_break"),
                sortDirections: ['descend', 'ascend'],
                render(text, record) {
                    return {
                        props: {
                            style: { background: record.HittersName === "Team" ? "LightGray" : "" }
                        },
                        children: <div>{text}</div>
                    };
                }
            },
            {
                title: "H Break",
                dataIndex: "h_break",
                sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "h_break"),
                sortDirections: ['descend', 'ascend'],
                render(text, record) {
                    return {
                        props: {
                            style: { background: record.HittersName === "Team" ? "LightGray" : "" }
                        },
                        children: <div>{text}</div>
                    };
                }
            },
            {
                title: "V Approach",
                dataIndex: "vert_appr_angle",
                sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "vert_appr_angle"),
                sortDirections: ['descend', 'ascend'],
                render(text, record) {
                    return {
                        props: {
                            style: { background: record.HittersName === "Team" ? "LightGray" : "" }
                        },
                        children: <div>{text}</div>
                    };
                }
            },
            {
                title: "H Approach",
                dataIndex: "horz_approach_angle",
                sorter: (a, b, sortOrder) => getSortedValues(a, b, sortOrder, "horz_approach_angle"),
                sortDirections: ['descend', 'ascend'],
                render(text, record) {
                    return {
                        props: {
                            style: { background: record.HittersName === "Team" ? "LightGray" : "" }
                        },
                        children: <div>{text}</div>
                    };
                }
            }
        ]
        const inner_data = childRows;
        return (
            <Table
                className="nestedTable"
                columns={inner_columns}
                dataSource={inner_data}
                pagination={false}
                showHeader={false}
                // scroll={{ y: 430 }}
            />
        );
    }
    return (
        <>
            {(isPrinting && data.length > 10) ? <PrintTable columns={columns} dataSource={data} /> :
                <div>
                    {isPrinting ?
                        <div className="print--table--wrp">
                            <Table
                                className={"table with-border"}
                                columns={columns}
                                dataSource={data}
                                pagination={false}
                            />
                        </div>
                         :
                        <div className="table-responsive-xl teamsummary-table">
                            <table className="table with-border">
                                <div className="custom-header-fixed print--table--wrp">
                                    <Table
                                        columns={columns}
                                        dataSource={data}
                                        pagination={false}
                                        className="parentTable_pitch_metrics"
                                        expandIcon={({ expanded, onExpand, record }) =>
                                            expanded ? (
                                                <DownOutlined
                                                    style={{ color: "black" }}
                                                    onClick={(e) => onExpand(record, e)}
                                                />
                                            ) : (
                                                <RightOutlined style={{ color: "black" }} onClick={(e) => onExpand(record, e)} />
                                            )
                                        }
                                        expandable={{
                                            expandedRowRender: (row) => (
                                                <p
                                                    style={{
                                                        margin: 0,
                                                    }}
                                                >
                                                    {renderInnerRows(row)}
                                                </p>
                                            ),
                                        }}
                                    />
                                </div>
                            </table>
                        </div>}
                </div>}
        </>
    );
}

export default PitchersPitchmetrics;
