import { imagesArr } from "../../../assets/images";
import BattedBallProfile from "./battedBallProfile";
import HardestHitBall from "./hardestHitBall";
import FartherHitBall from "./farthestHitBall";

function HitterSummaryTable({ print, battedBell, hardestBall, farthestBall, printAll, isPrint }) {
    return (
        <>
            <div className="container-fluid">
                <div className="common-title">
                    <h2>Hitter Summary</h2>
                    {!isPrint && <div className="dropdown">
                        <span className="printer"><img src={imagesArr.printer} alt="print" /></span>
                        <div className="dropdown-content">
                            <ul>
                                <li onClick={() => print()}>Print</li>
                                <li onClick={() => printAll()}>Print All</li>
                            </ul>
                        </div>
                    </div>}
                </div>
            </div>
            <div className="container-fluid" id="hitterSummaryPrintArea" >
                <div className="hittersummary-table">
                    <BattedBallProfile
                        battedTable={battedBell}
                        type="hitterSummary"
                    />
                </div>
                <div className="row">
                    <HardestHitBall
                        hardestTable={hardestBall}
                    />
                    <FartherHitBall
                        farthestTable={farthestBall}
                    />
                </div>
            </div>
        </>
    )
}

export default HitterSummaryTable;