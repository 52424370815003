import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
// images
import { imagesArr } from "../../assets/images";
// components
import moment from "moment";
import Slider from "react-slick";
// redux
import { getDashboardData } from "../../store/actions";
import SidebarListData from "../SidebarListData";
import TeamInsights from "./TeamInsights/TeamInsights";

class development extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTeam: "hitters",
      sessionReports: [],
      teamProfiles: [],
      tempTeamProfiles: [],
      season: moment().year().toString(),
    };
  }

  componentDidMount() {
    localStorage.setItem("activeType", "Hitting");
    localStorage.setItem("activeSeason", this.state.season);
    this.setEvent(this.props?.teamProfiles);
  }

  componentDidUpdate = () => {
    if (this.props?.teamProfiles) {
      if (this.props?.teamProfiles.length !== this.state.tempTeamProfiles.length) {
        this.setEvent(this.props?.teamProfiles);
      }
    }
  }

  setEvent = (teamProfiles) => {
    let events = [];
    if (teamProfiles && teamProfiles.length !== 0) {
      teamProfiles.map(profile => {
        return profile.event && profile.event.filter(item => {
          if (!events.find(obj => obj.source_id === item.source_id)) {
            const newEvent = item;
            newEvent.profile = profile;
            events.push(newEvent);
          }
          return null;
        });
      });
      events = events.sort((a, b) => {
        const keyA = moment(a.event_date, 'MM-DD-YYYY');
        const keyB = moment(b.event_date, 'MM-DD-YYYY');
        if (keyA > keyB) return -1;
        if (keyA < keyB) return 1;
        return 0;
      });

      this.setState({
        sessionReports: events,
        allevent: events
      })
    }
    this.setState((prevState) => ({
      ...prevState,
      tempTeamProfiles: teamProfiles
    }))
  }

  // change team type
  changeTeamType = (e) => {
    localStorage.setItem(
      "activeType",
      e === "hitters" ? "Hitting" : "Pitching"
    );
    this.setState({ activeTeam: e });
    let data = {
      count: 0,
      offset: 0,
      type: e,
      season: this.state.season,
    };
    this.props.loader(true);
    this.props.fetchDashboardData(data, (res) => {
      this.setState({
        teamProfiles: res.team_profiles,
      }, () => {
        this.setEvent(this.state.teamProfiles);
        this.props.userTypeData(this.state.teamProfiles);
        this.props.loader(false);
      });
    });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState(
      (prevState) => ({
        ...prevState,
        [name]: value ? value : this.state.season,
      }),
      () => {
        localStorage.setItem("activeSeason", value ? value : this.state.season);
        let type =
          localStorage.getItem("activeType") === "Hitting"
            ? "hitters"
            : "pitchers";
        let yearEvent = [];
        yearEvent = this.state.allevent.filter(item => { if (item.year == this.state.season) return item })
        this.setState((prevState) => ({
          ...prevState,
          sessionReports: value ? yearEvent : this.state.allevent,
        }))
      }
    );
  };

  render() {
    var settings = {
      dots: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 4,
      infinite: false,
      initialSlide: 0,
      arrows: true,
      // autoplay: true,
      variableWidth: true,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 1,
            variableWidth: true,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            variableWidth: true,
            slidesToScroll: 2,
          },
        },
      ],
    };
    let { activeTeam } = this.state;
    const { seasonList, teamProfiles } = this.props;
    return (
      <>
        <div className="main-div">
          <div className="button_hitters">
            <div className="d-flex">
              <a
                onClick={this.changeTeamType.bind(this, "hitters")}
                className={`btn ${activeTeam === "hitters" ? "blue" : "trans"
                  }`}
              >
                Hitters
              </a>
              <a
                onClick={this.changeTeamType.bind(this, "pitchers")}
                // className="btn blue trans mr-0"
                className={`btn mr-0 ${activeTeam === "pitchers" ? "blue" : "trans"
                  }`}
              >
                Pitchers
              </a>
            </div>

            <div className="select-box">
              <select
                name="season"
                // value={this.state.season}
                onChange={this.handleChange}
                className="custom-select"
              >
                <option value="" defaultValue>All Time</option>
                {seasonList &&
                  seasonList.map((opt, i) => {
                    return <option value={opt}>{opt}</option>;
                  })}
              </select>
            </div>
          </div>

          <div className="sidebar-content">
            <div className="sidebar">
              {<SidebarListData data={teamProfiles} />}
            </div>
            <div className="content">
              <section>
                <div className="white-bg">
                  <div className="slider_card">
                    <div className="slick-list d-flex">
                      {this.state.sessionReports && this.state.sessionReports.length !== 0 ? this.state.sessionReports.map((data, i) => {
                        return (
                          <div className="slider-card" key={i}>
                            <Link
                              // to={`/${data.event_type === "BP"
                              //   ? "hittersummary"
                              //   : data.event_type === "Bullpen"
                              //     ? "pitcherssummary"
                              //     : "gamesummary"
                              //   }/${data.source_id}`}
                              to={`/${data.file_type === "Blast" ? "sessionsummary" : data.event_type === "BP" ? "hittersummary" : data.event_type === "Bullpen" ?
                                "pitcherssummary" : data.event_type === "Scrimmage" ?
                                  "scrimmagesummary" : "gamesummary"
                                }/${data.source_id}`}
                            >
                              <div className="price-title">{data.event_type}</div>
                              <div className="d-flex">
                                <span>
                                  {moment(data.event_date, "MM-DD-YYYY").format(
                                    "MMM"
                                  )}
                                </span>
                                <h5>
                                  {moment(data.event_date, "MM-DD-YYYY").format(
                                    "DD"
                                  )}
                                </h5>
                                <span>
                                  {moment(data.event_date, "MM-DD-YYYY").format(
                                    "YYYY"
                                  )}
                                </span>
                              </div>
                              <p>{data.event_name}</p>
                              <div className="major_logo">
                                {data.file_type === "Yakkertech" && (
                                  <img
                                    src={imagesArr.yakkertech}
                                    alt="major_logo"
                                  />
                                )}
                                {data.file_type === "FlightScope" && (
                                  <img
                                    src={imagesArr.flightScope}
                                    alt="major_logo"
                                  />
                                )}
                                {data.file_type.includes("TrackMan") && (
                                  <img src={imagesArr.trackMan} alt="major_logo" />
                                )}
                                {data.file_type === "Blast" && (
                                  <img src={imagesArr.Blast} alt="major_logo" />
                                )}
                                {data.file_type.includes("Rapsodo") && (
                                  <img src={imagesArr.rapsodo} alt="major_logo" />
                                )}
                                {data.file_type === "Hittrax" && (
                                  <img src={imagesArr.Hittrax} alt="major_logo" />
                                )}
                                {data.file_type.includes("Savant") && (
                                  <img src={imagesArr.Savant} alt="major_logo" />
                                )}
                              </div>
                            </Link>
                          </div>
                        );
                      }) :
                        <p className="text-center">
                          No data currently linked to this.
                        </p>}
                    </div>
                  </div>
                </div>
                <div className="container-fluid">
                  <div className="dashboard_feature white-bg">
                    <TeamInsights />
                  </div>
                  {/* <div className="table-responsive-xl dashboard_feature">
                    <table className="white-bg">
                      <tbody>
                        <tr>
                          <td>
                            <p className="mb-0 active">
                              To view player profiles, select a name from your
                              roster or use the search bar above.
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p className="mb-0">
                              Use the drop down menu(s) above the roster to view
                              historical seasons or subteams, if applicable.
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p className="mb-0">
                              Click on the sessions above to view practice and game
                              reports.
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p className="mb-0">
                              Use 'Team Summary' to view and create custom
                              leaderboards for various metrics.
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p className="mb-0">
                              Go to 'Development' to compare individual sessions or
                              groups of sessions side-by-side.
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p className="mb-0">
                              Click your email address in the top right to navigate
                              to external uploader and settings page.
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p className="mb-0">
                              Clicking the 'BCTeam' logo in the top left will bring
                              you back to the dashboard.
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p className="mb-0">
                              Use the icons in the bottom right to visit the
                              BaseballCloud research blog and social media.
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div> */}
                </div>
              </section>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    teamData: state.teamData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchDashboardData: (data, next) => dispatch(getDashboardData(data, next)),
  loader: (data) => dispatch({ type: "LOADING", payload: data })
});
export default connect(mapStateToProps, mapDispatchToProps)(development);
