import React, { Component } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official';
import Bubble from 'highcharts/highcharts-more.js';
import { constant } from '../../../shared/constant';
import SwingSummaryTable from '../../sessionsummary/component/SwingSummaryTable';
import RollingMainChart from '../../../components/Charts/RollingMainChart';
import PlotChartFirst from '../../../components/Charts/PlotChart';
import PlotChartSecond from '../../../components/Charts/PlotChart';
import PlotChartThird from '../../../components/Charts/PlotChart';

class SwingDataBlast extends Component {
    constructor(props) {
        super(props)
        this.state = {
            spline_chart: "bat_speed",
            plot_f_x: "peak_hand_speed",
            plot_f_y: "bat_speed",
            plot_s_x: "connection_at_impact",
            plot_s_y: "peak_hand_speed",
            plot_t_x: "attack_angle",
            plot_t_y: "bat_speed",
        }
    }

    renderTable = () => {
        let batted_ball_table_Data = [...(this.props.filterRes && this.props.filterRes.bbp_pitch_by_field_table_data_query || []), ...(this.props.filterRes && this.props.filterRes.bbp_by_field_table_data_query || [])]
        return (
            batted_ball_table_Data.length !== 0 &&
            batted_ball_table_Data.map((list, i) => {

                let rowColor = ""
                if (list.season === "Player") {
                    rowColor = "table-rowoasis"
                } else if (list.season === "D1 Average") {
                    rowColor = "table-rowgrey80"
                } else if (list.season === "Team Average") {
                    rowColor = "table-rowwhitesmoke"
                } else {
                    rowColor = "table-rowwhite"
                }

                return (
                    <tr key={i}>
                        <td className={`${rowColor}`} style={{ color: constant.pitchTypeColor[list._id] }}>{list.hasOwnProperty('_id') ? list._id ? list._id : "—" : list.season !== null ? list.season : "—"}</td>
                        <td className={`${rowColor}`}>{this.getExitVelocity(list.season ? list.season : list._id) ? this.getExitVelocity(list.season ? list.season : list._id).avg_ev ? this.getExitVelocity(list.season ? list.season : list._id).avg_ev.toString().includes(".") ? this.getExitVelocity(list.season ? list.season : list._id).avg_ev.toFixed(1) : this.getExitVelocity(list.season ? list.season : list._id).avg_ev : "—" : "—"}</td>
                        <td className={`${rowColor}`}>{this.getExitVelocity(list.season ? list.season : list._id) ? this.getExitVelocity(list.season ? list.season : list._id).peak_ev ? this.getExitVelocity(list.season ? list.season : list._id).peak_ev.toString().includes(".") ? this.getExitVelocity(list.season ? list.season : list._id).peak_ev.toFixed(1) : this.getExitVelocity(list.season ? list.season : list._id).peak_ev : "—" : "—"}</td>
                        <td className={`${rowColor}`}>{this.getExitVelocity(list.season ? list.season : list._id) ? this.getExitVelocity(list.season ? list.season : list._id).avg_la ? this.getExitVelocity(list.season ? list.season : list._id).avg_la.toString().includes(".") ? this.getExitVelocity(list.season ? list.season : list._id).avg_la.toFixed(1) : this.getExitVelocity(list.season ? list.season : list._id).avg_la : "—" : "—"}</td>
                        <td className={`${rowColor}`}>{this.getExitVelocity(list.season ? list.season : list._id) ? this.getExitVelocity(list.season ? list.season : list._id).peak_distance ? Math.round(this.getExitVelocity(list.season ? list.season : list._id).peak_distance) : "—" : "—"}</td>
                        <td className={`${rowColor}`}>{this.getExitVelocity(list.season ? list.season : list._id) ? this.getExitVelocity(list.season ? list.season : list._id).hard_hit ? this.getExitVelocity(list.season ? list.season : list._id).hard_hit.toString().includes(".") ? this.getExitVelocity(list.season ? list.season : list._id).hard_hit.toFixed(1) + "%" : this.getExitVelocity(list.season ? list.season : list._id).hard_hit + "%" : "—" : "—"}</td>
                        <td className={`${rowColor}`}>{this.getExitVelocity(list.season ? list.season : list._id) ? this.getExitVelocity(list.season ? list.season : list._id).damage ? this.getExitVelocity(list.season ? list.season : list._id).damage.toString().includes(".") ? this.getExitVelocity(list.season ? list.season : list._id).damage.toFixed(1) + "%" : this.getExitVelocity(list.season ? list.season : list._id).damage + "%" : "—" : "—"}</td>
                        <td className={`${rowColor}`}>{list.gb ? list.gb.toString().includes(".") ? list.gb.toFixed(1) + "%" : list.gb + "%" : "—"}</td>
                        <td className={`${rowColor}`}>{list.ld ? list.ld.toString().includes(".") ? list.ld.toFixed(1) + "%" : list.ld + "%" : "—"}</td>
                        <td className={`${rowColor}`}>{list.fb ? list.fb.toString().includes(".") ? list.fb.toFixed(1) + "%" : list.fb + "%" : "—"}</td>
                        <td className={`${rowColor}`}>{list.pull ? list.pull.toString().includes(".") ? list.pull.toFixed(1) + "%" : list.pull + "%" : "—"}</td>
                        <td className={`${rowColor}`}>{list.avg_ev_pull ? list.avg_ev_pull.toString().includes(".") ? list.avg_ev_pull.toFixed(1) : list.avg_ev_pull : "—"}</td>
                        <td className={`${rowColor}`}>{list.avg_la_pull ? list.avg_la_pull.toString().includes(".") ? list.avg_la_pull.toFixed(1) : list.avg_la_pull : "—"}</td>
                        <td className={`${rowColor}`}>{list.middle ? list.middle.toString().includes(".") ? list.middle.toFixed(1) + "%" : list.middle + "%" : "—"}</td>
                        <td className={`${rowColor}`}>{list.avg_ev_middle ? list.avg_ev_middle.toString().includes(".") ? list.avg_ev_middle.toFixed(1) : list.avg_ev_middle : "—"}</td>
                        <td className={`${rowColor}`}>{list.avg_la_middle ? list.avg_la_middle.toString().includes(".") ? list.avg_la_middle.toFixed(1) : list.avg_la_middle : "—"}</td>
                        <td className={`${rowColor}`}>{list.oppo ? list.oppo.toString().includes(".") ? list.oppo.toFixed(1) + "%" : list.oppo + "%" : "—"}</td>
                        <td className={`${rowColor}`}>{list.avg_ev_oppo ? list.avg_ev_oppo.toString().includes(".") ? list.avg_ev_oppo.toFixed(1) : list.avg_ev_oppo : "—"}</td>
                        <td className={`${rowColor}`}>{list.avg_la_oppo ? list.avg_la_oppo.toString().includes(".") ? list.avg_la_oppo.toFixed(1) : list.avg_la_oppo : "—"}</td>
                    </tr>
                )
            })
        )
    }

    getExitVelocity = (type) => {
        let exitVeloData = [...(this.props.filterRes && this.props.filterRes.bbp_pitch_table_data_query || []), ...(this.props.filterRes && this.props.filterRes.bbp_table_data_query || [])]
        var isData = "";
        exitVeloData.length > 0 &&
            exitVeloData.forEach((data) => {
                if (data.season === type || data._id === type) return (isData = data)
            });
        return isData;
    }

    // metric change y axis of rolling line chart
    metricHandleChange = (e) => {
        const { name, value } = e.target
        this.setState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    render() {
        return (
            <>
                <div className="d-flex">
                    <div className="w-100">
                        <div className="container-fluid">
                            {/* swing summary */}
                            <div className="text-center">
                                <h3 className="table-title">SWING SUMMARY</h3>
                            </div>
                            <div className="blast-summary-result-table">
                                <div className="table-responsive-xl">
                                    <SwingSummaryTable
                                        tableData={[...this.props.filterRes.average_swing_summary_data || [], ...this.props.filterRes.peak_swing_summary_data || [], ...this.props.filterRes.team_average_swing_summary_data || []]}
                                        type="profileSwingSummary"
                                    />
                                </div>
                            </div>
                            {/* polt chart */}
                            <div className="text-center">
                                <h3 className="table-title">PLOT CHART</h3>
                            </div>
                            <div className="common-charts">
                                <div className={`row ${!this.props.isPrinting ? 'singleDiv' : 'breakDiv'}`}>
                                    <div className="w-33">
                                        <div className="ground-chart">
                                            <PlotChartFirst
                                                chartData={this.props.filterRes.swing_chart_data || []}
                                                metricHandleChange={this.metricHandleChange}
                                                plot_x={this.state.plot_f_x}
                                                plot_y={this.state.plot_f_y}
                                                id="strikeZoneChartfirst"
                                                type="poltFirst"
                                                showTitle={true}
                                                eventProfileData={this.props.eventProfileData}
                                                sendReq={this.props?.sendReq}
                                                tabslist={this.props?.tabslist}
                                                profilepage={true}
                                                isNotDownloadImgLoader={this.props.isNotDownloadImgLoader}
                                                isDownloadImgLoader={this.props.isDownloadImgLoader} />
                                        </div>
                                    </div>
                                    <div className="w-33">
                                        <div className="ground-chart">
                                            <PlotChartSecond
                                                chartData={this.props.filterRes.swing_chart_data || []}
                                                metricHandleChange={this.metricHandleChange}
                                                plot_x={this.state.plot_s_x}
                                                plot_y={this.state.plot_s_y}
                                                id="strikeZoneChartsecond"
                                                type="poltSecond"
                                                showTitle={true}
                                                eventProfileData={this.props.eventProfileData}
                                                sendReq={this.props?.sendReq}
                                                tabslist={this.props?.tabslist}
                                                profilepage={true}
                                                isNotDownloadImgLoader={this.props.isNotDownloadImgLoader}
                                                isDownloadImgLoader={this.props.isDownloadImgLoader} />
                                        </div>
                                    </div>
                                    <div className="w-33">
                                        <div className="ground-chart">
                                            <PlotChartThird
                                                chartData={this.props.filterRes.swing_chart_data || []}
                                                metricHandleChange={this.metricHandleChange}
                                                plot_x={this.state.plot_t_x}
                                                plot_y={this.state.plot_t_y}
                                                id="strikeZoneChartthird"
                                                type="poltThird"
                                                showTitle={true}
                                                eventProfileData={this.props.eventProfileData}
                                                sendReq={this.props?.sendReq}
                                                tabslist={this.props?.tabslist}
                                                profilepage={true}
                                                isNotDownloadImgLoader={this.props.isNotDownloadImgLoader}
                                                isDownloadImgLoader={this.props.isDownloadImgLoader} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* chart */}
                            <div className="text-center">
                                <h3 className="table-title">ROLLING CHART</h3>
                            </div>
                            <div className="common-charts">
                                <RollingMainChart
                                    id="rollingBlastChart"
                                    chartData={this.props.filterRes.swing_chart_data || []}
                                    metricHandleChange={this.metricHandleChange}
                                    spline_chart={this.state.spline_chart}
                                    eventProfileData={this.props.eventProfileData}
                                    sendReq={this.props?.sendReq}
                                    tabslist={this.props?.tabslist}
                                    profilepage={true}
                                    isNotDownloadImgLoader={this.props.isNotDownloadImgLoader}
                                    isDownloadImgLoader={this.props.isDownloadImgLoader} />
                            </div>
                            {/* batted ball table */}
                            <div className="table-responsive-xl">
                                <table className="table tabledashed">
                                    <thead>
                                        <tr>
                                            <th colSpan="10" className="text-center">Batted Ball Profile</th>
                                            <th colSpan="9" className="text-center">Batted Ball Profile By Field</th>
                                            {/* <th colSpan="3" className="text-center">Launch Angle</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="tablerowgray fw-500">Type</td>
                                            <td className="tablerowgray fw-500">Avg EV</td>
                                            <td className="tablerowgray fw-500">Peak EV</td>
                                            <td className="tablerowgray fw-500">Avg LA</td>
                                            <td className="tablerowgray fw-500">Peak Dist</td>
                                            <td className="tablerowgray fw-500">Hard Hit %</td>
                                            <td className="tablerowgray fw-500">Damage %</td>
                                            <td className="tablerowgray fw-500">GB %</td>
                                            <td className="tablerowgray fw-500">LD %</td>
                                            <td className="tablerowgray fw-500">FB %</td>
                                            <td className="tablerowgray fw-500">Pull %</td>
                                            <td className="tablerowgray fw-500">AEV Pull</td>
                                            <td className="tablerowgray fw-500">ALA Pull</td>
                                            <td className="tablerowgray fw-500">Mid %</td>
                                            <td className="tablerowgray fw-500">AEV Mid</td>
                                            <td className="tablerowgray fw-500">ALA Mid</td>
                                            <td className="tablerowgray fw-500">Oppo %</td>
                                            <td className="tablerowgray fw-500">AEV Oppo</td>
                                            <td className="tablerowgray fw-500">ALA Oppo</td>
                                        </tr>
                                        {this.renderTable()}
                                        {/* {
                                            this.props.filterRes &&
                                            this.props.filterRes.bbp_by_field_table_data_query &&
                                            this.props.filterRes.bbp_by_field_table_data_query.length !== 0 &&
                                            <tr>
                                                <td className="table-rowwhite">D1 Average</td>
                                                <td className="table-rowwhite">—</td>
                                                <td className="table-rowwhite">—</td>
                                                <td className="table-rowwhite">—</td>
                                                <td className="table-rowwhite">—</td>
                                                <td className="table-rowwhite">—</td>
                                                <td className="table-rowwhite">—</td>
                                                <td className="table-rowwhite">—</td>
                                                <td className="table-rowwhite">—</td>
                                                <td className="table-rowwhite">—</td>
                                                <td className="table-rowwhite">—</td>
                                                <td className="table-rowwhite">—</td>
                                                <td className="table-rowwhite">—</td>
                                                <td className="table-rowwhite">—</td>
                                                <td className="table-rowwhite">—</td>
                                                <td className="table-rowwhite">—</td>
                                                <td className="table-rowwhite">—</td>
                                                <td className="table-rowwhite">—</td>
                                                <td className="table-rowwhite">—</td>
                                            </tr>
                                        } */}
                                        {
                                            this.props.filterRes &&
                                            this.props.filterRes.bbp_by_field_table_data_query &&
                                            this.props.filterRes.bbp_by_field_table_data_query.length === 0 &&
                                            <tr>
                                                <td colSpan="19" className="text-center table-rowwhite">There's is no Info yet!</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default SwingDataBlast;
