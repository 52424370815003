import React from 'react';
import { imagesArr } from '../assets/images';

function Toggle({ toggle, onClickToggleButton, right, top, toggleStrikeZone,onClickToggleChart,chartStrike,chartContour,onClickToggle }) {
    return (
        <>
        {
                toggleStrikeZone ?
                    <div className='time-line-toggle'>
                        <div class="time-line">
                            <div class="timeline-scoll">
                                <div class="timeline-inner">
                                    <div class={chartStrike ? !toggle.chartToggle ? "active" : "select" : "active" }>
                                        <a onClick={() => onClickToggleChart("Pitches")}></a><p className='img'><img src={imagesArr.pText} /></p>
                                    </div>
                                    <div class={chartContour ? !toggle.chartToggle ? "active" : "select" : !toggle.chartToggle ? "active" : ""}><a onClick={() => onClickToggleChart("Contour")}></a><p className='img'><img src={imagesArr.cText} /></p>
                                    </div>
                                    <div class={!toggle.chartToggle ? "select" : ""}>
                                        <a onClick={onClickToggle}></a><p>3D</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="toggle-switch pitchar-toggle" style={{ right: right ? right : "17px", top: top ? top : "10px", left: "unset" }}>
                        {/* <div className='toggle-icon'><img src={imagesArr.toggle_icon} /></div> */}
                        <div className='toggle-icon'>2D</div>
                        <label className="switch">
                            <input
                                type="checkbox"
                                id="togBtn"
                                checked={!toggle.chartToggle}
                                onChange={onClickToggleButton}
                            />
                            <div className={`slider ${toggle.chartToggle ? "active" : ""} round`}>
                                <span className="on"></span>
                                <span className="off"></span>
                            </div>
                        </label>
                        <div className='toggle-icon'>3D</div>
                    </div>
            }
        </>
        
            
      
      
    );
}

export default Toggle;
