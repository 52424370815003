import React, { Component } from 'react';
import { imagesArr } from '../../assets/images';

class noSpreyChart extends Component {
    render() {
        return (
            <div className={`ground-chart contactTabSpray1 ${this.props.tabName === "contactTab" ? "fixed-h" : ""}`}>
                <div className="fixed-s-l"></div>
                <div style={{ height: this.props.title === "atbatSpray" || this.props.tabName === "contactTab" ? this.props.height : "468px", display: "flex", alignItems: "center", width: "86%", margin: "auto" }}>
                    {/* <svg width="1563" height="424" >
                    <g transform="translate(781,315)" style={{ opacity: '1' }}>
                        <path className="arc" d="M-82.79999999999998,-82.8A117.09688296449225,117.09688296449225,0,0,1,-30.3069034333515,-113.1069034333515L0,0Z" style={{ fill: 'rgb(178, 24, 43)' }}></path>
                        <path className="arc" d="M-30.3069034333515,-113.1069034333515A117.09688296449225,117.09688296449225,0,0,1,30.30690343335151,-113.1069034333515L0,0Z" style={{ fill: 'rgb(178, 24, 43)' }}></path>
                        <path className="arc" d="M30.30690343335151,-113.1069034333515A117.09688296449225,117.09688296449225,0,0,1,82.8,-82.79999999999998L0,0Z" style={{ fill: 'rgb(178, 24, 43)' }}></path>
                        <path className="arc" d="M-181.12499999999997,-181.125A256.14943148482683,256.14943148482683,0,0,1,-66.29635126045642,-247.42135126045645L-30.3069034333515,-113.1069034333515A117.09688296449225,117.09688296449225,0,0,0,-82.79999999999998,-82.8Z" style={{ fill: 'rgb(178, 24, 43)' }}></path>
                        <path className="arc" d="M-66.29635126045642,-247.42135126045645A256.14943148482683,256.14943148482683,0,0,1,66.29635126045645,-247.42135126045645L30.30690343335151,-113.1069034333515A117.09688296449225,117.09688296449225,0,0,0,-30.3069034333515,-113.1069034333515Z" style={{ fill: 'rgb(178, 24, 43)' }}></path>
                        <path className="arc" d="M66.29635126045645,-247.42135126045645A256.14943148482683,256.14943148482683,0,0,1,181.125,-181.12499999999997L82.8,-82.79999999999998A117.09688296449225,117.09688296449225,0,0,0,30.30690343335151,-113.1069034333515Z" style={{ fill: 'rgb(178, 24, 43)' }}></path>
                        <text className="arc-text" x="0" y="-87.82266222336919" transform="rotate(-30)" style={{ color: 'rgb(0, 0, 0)', fill: 'rgb(0, 0, 0)', fontSize: '13px', fontWeight: 'bold' }}>0%</text>
                        <text className="arc-text" x="0" y="-87.82266222336919" transform="rotate(0)" style={{ color: 'rgb(0, 0, 0)', fill: 'rgb(0, 0, 0)', fontSize: '13px', fontWeight: 'bold' }}>0%</text>
                        <text className="arc-text" x="0" y="-87.82266222336919" transform="rotate(30)" style={{ color: 'rgb(0, 0, 0)', fill: 'rgb(0, 0, 0)', fontSize: '13px', fontWeight: 'bold' }}>0%</text>
                        <text className="arc-text" x="0" y="-192.1120736136201" transform="rotate(-30)" style={{ color: 'rgb(0, 0, 0)', fill: 'rgb(0, 0, 0)', fontSize: '13px', fontWeight: 'bold' }}>0%</text>
                        <text className="arc-text" x="0" y="-192.1120736136201" transform="rotate(0)" style={{ color: 'rgb(0, 0, 0)', fill: 'rgb(0, 0, 0)', fontSize: '13px', fontWeight: 'bold' }}>0%</text>
                        <text className="arc-text" x="0" y="-192.1120736136201" transform="rotate(30)" style={{ color: 'rgb(0, 0, 0)', fill: 'rgb(0, 0, 0)', fontSize: '13px', fontWeight: 'bold' }}>0%</text>
                    </g>
                    <g transform="translate(781,315)" style={{ opacity: '1' }}>
                        <rect className="dots-panel" x="-781" y="-315" width="1563" height="424" style={{ opacity: '0.6', fill: 'rgb(255, 255, 255)' }}></rect>
                    </g>
                    <g transform="translate(781,232)" style={{ opacity: '0' }}>
                        <text style={{ textAnchor: 'middle' }}></text>
                    </g>
                </svg> */}
                    <img src={imagesArr.nograph} />
                </div>
            </div>
        );
    }
}

export default noSpreyChart;
