import { constant } from "../../../../shared/constant";

function PlateDiscTable({ title, subTitle, data }) {
    return (
        <>
            <div className="d-flex">
                <h3>{title}</h3>
                <h3 className="subtext">{subTitle}</h3>
            </div>
            <div className="table-responsive-xl">
                <table className="table tabledashed">
                    <thead>
                        <tr>
                            <th>Pitch Count</th>
                            <th>Pitch Type</th>
                            <th>O Zone Swings</th>
                            <th>O Zone pitches</th>
                            <th>Z Zone Swings</th>
                            <th>Z Zone pitches</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.map((item,i)=>{
                                return (
                                    <tr>
                                        <td className="table-rowwhite">{item.pitch_count ? item.pitch_count : "-"}</td>
                                        <td className="table-rowwhite" style={{ color: constant.pitchTypeColor[item._id] }}>{item._id ? item._id : "-"}</td>
                                        <td className="table-rowwhite">{item.o_zone_swings ? item.o_zone_swings.toString().includes(".") ? item.o_zone_swings.toFixed(1) : item.o_zone_swings : '—'}</td>
                                        <td className="table-rowwhite">{item.o_zone_pitches ? item.o_zone_pitches.toString().includes(".") ? item.o_zone_pitches.toFixed(1) : item.o_zone_pitches : '—'}</td>
                                        <td className="table-rowwhite">{item.z_zone_swings ? item.z_zone_swings.toString().includes(".") ? item.z_zone_swings.toFixed(1) : item.z_zone_swings : '—'}</td>
                                        <td className="table-rowwhite">{item.z_zone_pitches ? item.z_zone_pitches.toString().includes(".") ? item.z_zone_pitches.toFixed(1) : item.z_zone_pitches : '—'}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default PlateDiscTable;