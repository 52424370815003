import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { imagesArr } from "../../../assets/images";

class profileEvent extends Component {
    render() {
        const { eventProfileData, teamInfo, events } = this.props;
        return (
            <div className="container-fluid">
                <div className="d-flex profileinfo">
                    <div className="white-bg w-15 profilecard">
                        <div className="profile">
                            <div className="profilelogo">
                                <img
                                    src={
                                        eventProfileData &&
                                            !eventProfileData.profileUrl.includes("user-default-icon.png")
                                            ? eventProfileData.profileUrl
                                            : teamInfo.logo
                                    }
                                    alt="profilelogo"
                                />
                            </div>
                            <a className="editicon">
                                <img
                                    src={imagesArr.editicon}
                                    alt="edit"
                                    className=""
                                />
                            </a>
                        </div>
                        {eventProfileData && (
                            <>
                                <h3>
                                    {eventProfileData.fname}{" "}
                                    {eventProfileData.lname}
                                </h3>
                                {
                                    eventProfileData.hasOwnProperty('position1') ?
                                        <h4>
                                            Pos: {eventProfileData.position1}
                                        </h4>
                                        : ""
                                }
                                {/* {
                                    eventProfileData.hasOwnProperty('age') ?
                                        <h4>Age: {eventProfileData.age}</h4>
                                        : ""
                                } */}
                                {
                                    eventProfileData.hasOwnProperty('height') ?
                                        <h4>Height: {eventProfileData.height}</h4>
                                        : ""
                                }
                                {
                                    eventProfileData.hasOwnProperty('weight') ?
                                        <h4>Weight: {eventProfileData.weight}</h4>
                                        : ""
                                }
                                {
                                    eventProfileData.hasOwnProperty('school') ?
                                        <h4>School: {eventProfileData.school}</h4>
                                        : ""
                                }
                            </>
                        )}
                    </div>
                    <div className="white-bg w-85 d-flex align-items-center">
                        <div className="slider_card">
                            <div className="slick-list d-flex">
                                {this.props.getEventReport()}
                                {events.length === 0 && (
                                    <p className="text-center">
                                        No data currently linked to this.
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default profileEvent;
