import { Link } from "react-router-dom";
import { NoEvent } from "./noInfo";
import { imagesArr } from '../../../assets/images';


function EventListB({ eventList, handleEvent, BList, isshowb, handleToggleB, handleToggleMenuB, iscareticon, isshowtypeb, eventType, handleEventFilter, handleToggleDateB, iscareticondate, isshowdateb, date, handleAllEvent, selectAllB }) {
    return (
        <>
            <div className={isshowb ? 'group2 w-100' : 'group2 w-100 groupactive'}>
                <div className="grouptitle d-flex">
                    <h4>Group B</h4>
                    <span onClick={handleToggleB} className={isshowb ? eventList.length === 0 ? "disabled" : "" : 'active'}><i className="fa fa-filter ifilter"></i></span>
                </div>
                {eventList.length !== 0 && !isshowb &&
                    <>
                        <div className="filterdropdown grouptitle ">
                            <div className="d-flex">
                                <div className="select_box row w-100">
                                    <ul className="select_box_inner">
                                        <a onClick={handleToggleMenuB} >
                                            <li className="custom-select align-items-center">
                                                <a>Type</a>
                                                {!iscareticon
                                                    ? <i className="fa fa-chevron-down"></i>
                                                    : <i className="fa fa-chevron-up"></i>
                                                }
                                                {!isshowtypeb &&
                                                    <ul className="sub-menu">
                                                        <li className={` ${eventType === "Game" && "active"}`} onClick={() => handleEventFilter("filterB", "eventType", "Game")} ><a>Game</a></li>
                                                        <li className={` ${eventType === "Scrimmage" && "active"}`} onClick={() => handleEventFilter("filterB", "eventType", "Scrimmage")} ><a>Scrimmage</a></li>
                                                        <li className={` ${eventType === "Bullpen" && "active"}`} onClick={() => handleEventFilter("filterB", "eventType", "Bullpen")} ><a>Bullpen</a></li>
                                                        <li className={` ${eventType === "BP" && "active"}`} onClick={() => handleEventFilter("filterB", "eventType", "BP")} ><a>BP</a></li>
                                                    </ul>
                                                }
                                            </li>
                                        </a>
                                    </ul>

                                    <ul className="select_box_inner pr-0">
                                        <a onClick={handleToggleDateB}>
                                            <li className="custom-select align-items-center">
                                                <a>Date</a>
                                                {!iscareticondate
                                                    ? <i className="fa fa-chevron-down"></i>
                                                    : <i className="fa fa-chevron-up"></i>
                                                }
                                                {!isshowdateb &&
                                                    <ul className="sub-menu">
                                                        <li className={` ${date === "Last Week" && "active"}`} onClick={() => handleEventFilter("filterB", "date", "Last Week")}><a>Last Week</a></li>
                                                        <li className={` ${date === "Last Month" && "active"}`} onClick={() => handleEventFilter("filterB", "date", "Last Month")}><a>Last Month</a></li>
                                                    </ul>
                                                }
                                            </li>
                                        </a>
                                    </ul>
                                </div>
                            </div>
                            <div className="custom-checkbox">
                                <label>
                                    <input type="checkbox" onChange={() => handleAllEvent("groupB")} checked={selectAllB} />Select All
                                                            <span className="checkmark"></span>
                                </label>
                            </div>
                        </div>
                    </>
                }
                <div className="listscroll">
                    {eventList.length != 0 ?

                        eventList.map((data, i) => {
                            return (
                                <div className={`listgroup ${!!BList.find(item => item === data.source_id) && 'selected'}`} onClick={() => handleEvent("groupB", data.source_id)}>
                                    <div className="listdesc">
                                        <div className="list-logos">
                                            <div>
                                                <span>{data.event_type}</span>
                                            </div>
                                            <div className="major_logo">
                                                {
                                                    data.file_type === "Yakkertech" &&
                                                    <img src={imagesArr.yakkertech} alt="major_logo" style={{ width: "25px" }} />
                                                }
                                                {
                                                    data.file_type === "FlightScope" &&
                                                    <img src={imagesArr.flightScope} alt="major_logo" style={{ width: "25px" }} />
                                                }
                                                {
                                                    data.file_type.includes("TrackMan") &&
                                                    <img src={imagesArr.trackMan} alt="major_logo" style={{ width: "25px" }} />
                                                }
                                                {
                                                    data.file_type === "Blast" &&
                                                    <img src={imagesArr.Blast} alt="major_logo" style={{ width: "25px" }} />
                                                }
                                                {
                                                    data.file_type.includes("Rapsodo") &&
                                                    <img src={imagesArr.rapsodo} alt="major_logo" style={{ width: "25px" }} />
                                                }
                                                {
                                                    data.file_type === "Hittrax" &&
                                                    <img src={imagesArr.Hittrax} alt="major_logo" style={{ width: "25px" }} />
                                                }
                                                {
                                                    data.file_type.includes("Savant") &&
                                                    <img src={imagesArr.Savant} alt="major_logo" style={{ width: "25px" }} />
                                                }
                                                {/* <img src={imagesArr.major_logo} alt="major_logo" /> */}
                                            </div>
                                        </div>
                                        <h4>{data.date}</h4>
                                        <p>{data.event_name}</p>
                                       
                                    </div>
                                </div>
                            )
                        })
                        : <NoEvent />
                    }
                </div>
            </div>
        </>
    )
}
export default EventListB;