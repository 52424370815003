import React, { Component } from "react";
import { imagesArr } from "../../../assets/images";
import AwayBattedBallsTable from "../../../components/CommanTable/BattedBallsTable";
import AwayPitchersMainChart from "../../../components/Charts/PitchersMainChart";
import AwayPitcherReleasePointChart from "./../../../components/Charts/releasePointChart";
import AwayPitcherStrikeZoneChart from "./../../../components/Charts/strikeZoneChart";
import AwayPitcherSideReleaseChart from "../../../components/Charts/sideReleaseChart";
import AwayPitcherPitchSequencingChart from "../../../components/Charts/pitchSequencingChart";
import AwayPitcherCountSequencingChart from "../../../components/Charts/pitchSequencingChart";
import AwayPitcherResultSequencingChart from "../../../components/Charts/pitchSequencingChart";
import { connect } from "react-redux";
import { getGameSummaryData } from "../../../store/actions";
import Select from "react-select";
import { printToPDFMultiple } from "../../../shared/printReportUtils";
import Loader from "../../../shared/loader";
import ResultsTable from "../../../components/CommanTable/ResultsTable";
import SummaryTable from "../../../components/CommanTable/SummaryTable";
import PitchCharacteristicsTable from "../../../components/CommanTable/PitchCharacteristics";
import PlateDisciplineTable from "../../../components/CommanTable/PlateDisciplineTable";
import AwayPitcherPolarChart from "../../../components/Charts/PolarChart";
import AwayPitcherLongBreakMovementChart from './../../../components/Charts/breakMovementChart';
import AwayPitcherShortBreakMovementChart from './../../../components/Charts/breakMovementChart';
import AwayPitcherSwingsStrikeZoneChart from './../../../components/Charts/GSStrikeZoneChart';
import AwayPitcherWhiffsStrikeZoneChart from './../../../components/Charts/GSStrikeZoneChart';
import AwayPitcherBallsInPlayStrikeZoneChart from './../../../components/Charts/GSStrikeZoneChart';
import { xformBreakMovementChart } from "../../../shared/function";

class AwayPitchers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: "",
      awayResultData: [],
      awayPitchSummaryTableData: [],
      awayDisciplineTableData: [],
      awayCharacteristicTableData: [],
      awaySplineChartData: [],
      awayStrikeZoneData: [],
      awayReleasePointChartData: [],
      awaySideReleasePointChartData: [],
      awayPitchersSequenceChart: [],
      awayCountSequenceChart: [],
      awayResultSequenceChart: [],
      awayLongBubbleBreakMovement: [],
      awayShortBubbleBreakMovement: [],
      awayLongDotBreakMovement: [],
      awayShortDotBreakMovement: [],
      awayPolarChartData: [],
      awayBattedBallsTable: [],
      awaySwingsChartData: [],
      awayWhiffsChartData: [],
      awayBallsInPlayChartData: [],
      pitchersData: [],
      isPrinting: false,
      backUP: null,
      spline_chart: "velocity",
      isDownloadImg: false
    };
  }

  componentDidMount() {
    let sorceid = document.location.href.split("/")[4];
    if (sorceid === undefined) window.location.href = "/";
    else this.homePitchersData("away_pitchers", "", false);
  }

  componentDidUpdate = () => {
    if (this.props.appState.awayPitcherfilterFlag) {
      if (this.props.awayPitcherFilter[0] !== this.state.pitchersData[0]?.value) {
        let sorceid = document.location.href.split("/")[4];
        if (sorceid === undefined) window.location.href = "/";
        else this.homePitchersData("away_pitchers", "", true);
      }
    }
  }

  // fetch Pitch log data
  homePitchersData = (activeTab, pitchersName, flag) => {
    let data = {
      sourceId: document.location.href.split("/")[4],
      tab: activeTab,
      filter: {
        pitcher_name: this.props.appState.awayPitcherfilterFlag ? this.props.awayPitcherFilter[0] : pitchersName ? pitchersName : this.props.appState.awayPitcherName,
        batter_name: "",
      },
      // printflag: this.props?.isPrint
    };
    // this.props.increment();
    this.props.fetchSummaryData(data, (res) => {
      this.setState({
        awayResultData: res.data.result.pitcher_result_data,
        awayPitchSummaryTableData: res.data.result.pitcher_summary_table_data,
        awayDisciplineTableData: res.data.result.pitcher_plate_discipline_table_data,
        awayCharacteristicTableData: res.data.result.pitcher_characteristic_table_data,
        awaySplineChartData: res.data.result.pitcher_spline_chart_data,
        awayStrikeZoneData: res.data.result.pitchers_strike_zone_data,
        awayReleasePointChartData: res.data.result.pitcher_release_data,
        awaySideReleasePointChartData: res.data.result.pitcher_side_release_data,
        awayPitchersSequenceChart: res.data.result.pitch_sequence_chart_data,
        awayCountSequenceChart: res.data.result.count_sequencing_chart_data,
        awayResultSequenceChart: res.data.result.result_sequencing_chart_data,
        awayLongDotBreakMovement: res.data.result.pitcher_dot_chart_long_data,
        awayShortDotBreakMovement: res.data.result.pitcher_dot_chart_short_data,
        awayLongBubbleBreakMovement: res.data.result.pitcher_break_movement_long_data,
        awayShortBubbleBreakMovement: res.data.result.pitcher_break_movement_short_data,
        awayPolarChartData: res.data.result.pitcher_polar_movement_chart_data,
        awayBattedBallsTable: res.data.result.pitcher_batted_ball_data,
        awaySwingsChartData: res.data.result.pitchers_swing_strike_zone_data,
        awayWhiffsChartData: res.data.result.pitchers_whiff_strike_zone_data,
        awayBallsInPlayChartData: res.data.result.pitchers_balls_inplay_strike_zone_data,
        backUP: res.data.result,
        pitcherDefault: this.props.appState.awayPitcherfilterFlag ? this.props.awayPitcherFilter[0] : pitchersName ? pitchersName : this.props.appState.awayPitcherName
      });

      // for load piteher's wise data
      let pitcherData = this.props.awayPitcherFilter;

      let pitchersSelectData = [];
      // pitchersSelectData.push({ value: "", label: "All Pitchers" })
      pitcherData.map((data) => {
        pitchersSelectData.push({
          value: data,
          label: data,
        });
      });
      this.setState({ pitchersData: pitchersSelectData });
      // this.props.decrement();
      // if (!this.props.isStopPrint && flag) {  
      //   this.props.reduceDataCount()
      // }
    });
  };

  // decrease = () => {
  //   if (!this.props.isStopPrint && this.props.isPrint) {
  //     this.props?.reduceDataCount()
  //   }
  // }

  handleCallback = (index) => {
    this.setState({
      tabs: index,
    });
  };

  // change pitchers wise data
  handleChange = (e) => {
    this.props.appState.awayPitcherfilterFlag = false
    this.homePitchersData("away_pitchers", e.value, true);
  };

  // metric change y axis of rolling line chart
  metricHandleChange = (e) => {
    const { name, value } = e.target
    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  print = async () => {
    await this.setState({
      isPrinting: true,
      awayPolarChartData: Object.assign([], this.state.backUP.pitcher_polar_movement_chart_data),
      awayLongDotBreakMovement: Object.assign([], this.state.backUP.pitcher_dot_chart_long_data),
      awayShortDotBreakMovement: Object.assign([], this.state.backUP.pitcher_dot_chart_short_data),
      awayLongBubbleBreakMovement: Object.assign([], this.state.backUP.pitcher_break_movement_long_data),
      awayShortBubbleBreakMovement: Object.assign([], this.state.backUP.pitcher_break_movement_short_data),
      awayCountSequenceChart: Object.assign([], this.state.backUP.count_sequencing_chart_data),
      awayResultSequenceChart: Object.assign([], this.state.backUP.result_sequencing_chart_data),
      awayPitchersSequenceChart: Object.assign([], this.state.backUP.pitch_sequence_chart_data),
      awaySideReleasePointChartData: Object.assign([], this.state.backUP.pitcher_side_release_data),
      awayReleasePointChartData: Object.assign([], this.state.backUP.pitcher_release_data),
      awayStrikeZoneData: Object.assign([], this.state.backUP.pitchers_strike_zone_data),
      awaySplineChartData: Object.assign([], this.state.backUP.pitcher_spline_chart_data),
      awaySwingsChartData: Object.assign([], this.state.backUP.pitchers_swing_strike_zone_data),
      awayWhiffsChartData: Object.assign([], this.state.backUP.pitchers_whiff_strike_zone_data),
      awayBallsInPlayChartData: Object.assign([], this.state.backUP.pitchers_balls_inplay_strike_zone_data),
    });
    setTimeout(async () => {
      await printToPDFMultiple(`awayPitchers`, [
        'tablePrintArea',
        'awayPitchersMainChartPrintArea',
        'releaseStrikeChartPrintArea',
        'GameStrikeChartPrintArea',
        'sequencingChartPrintArea',
        'movementChartPrintArea',
        'awayPitchTable'
      ]);
      this.setState({
        isPrinting: false,
        awayPolarChartData: Object.assign([], this.state.awayPolarChartData),
        awayLongDotBreakMovement: Object.assign([], this.state.awayLongDotBreakMovement),
        awayShortDotBreakMovement: Object.assign([], this.state.awayShortDotBreakMovement),
        awayLongBubbleBreakMovement: Object.assign([], this.state.awayLongBubbleBreakMovement),
        awayShortBubbleBreakMovement: Object.assign([], this.state.awayShortBubbleBreakMovement),
        awayCountSequenceChart: Object.assign([], this.state.awayCountSequenceChart),
        awayResultSequenceChart: Object.assign([], this.state.awayResultSequenceChart),
        awayPitchersSequenceChart: Object.assign([], this.state.awayPitchersSequenceChart),
        awaySideReleasePointChartData: Object.assign([], this.state.awaySideReleasePointChartData),
        awayReleasePointChartData: Object.assign([], this.state.awayReleasePointChartData),
        awayStrikeZoneData: Object.assign([], this.state.awayStrikeZoneData),
        awaySplineChartData: Object.assign([], this.state.awaySplineChartData),
        awaySwingsChartData: Object.assign([], this.state.awaySwingsChartData),
        awayWhiffsChartData: Object.assign([], this.state.awayWhiffsChartData),
        awayBallsInPlayChartData: Object.assign([], this.state.awayBallsInPlayChartData),
      })
    }, 1000);
  }

  printAll = () => {
    this.props.printAll();
    this.props.appState.awayPitcherName = this.state.pitcherDefault;
  }

  render() {
    const tabs = this.state.tabs;
    let {
      awayResultData,
      awayPitchSummaryTableData,
      awayDisciplineTableData,
      awayCharacteristicTableData,
      awaySplineChartData,
      awayStrikeZoneData,
      awayReleasePointChartData,
      awaySideReleasePointChartData,
      awayCountSequenceChart,
      awayResultSequenceChart,
      awayPitchersSequenceChart,
      awayPolarChartData,
      awayLongDotBreakMovement,
      awayShortDotBreakMovement,
      awayLongBubbleBreakMovement,
      awayShortBubbleBreakMovement,
      awayBattedBallsTable,
      pitchersData,
      awaySwingsChartData,
      awayWhiffsChartData,
      awayBallsInPlayChartData
    } = this.state;

    const bubbleChartDataShort =
      awayShortBubbleBreakMovement ? awayShortBubbleBreakMovement.length !== 0
        ? xformBreakMovementChart(awayShortBubbleBreakMovement, 'pitch_type')
        : [] : [];

    const bubbleChartDataLong =
      awayLongBubbleBreakMovement ? awayLongBubbleBreakMovement.length !== 0
        ? xformBreakMovementChart(awayLongBubbleBreakMovement, 'pitch_type')
        : [] : [];

    const dotChartDataShort =
      awayShortDotBreakMovement && awayShortDotBreakMovement.length !== 0
        ? xformBreakMovementChart(awayShortDotBreakMovement, 'pitch_type')
        : [];

    const dotChartDataLong =
      awayLongDotBreakMovement && awayLongDotBreakMovement.length !== 0
        ? xformBreakMovementChart(awayLongDotBreakMovement, 'pitch_type')
        : [];

    return (
      <>
        {this.state.isPrinting && <Loader />}
        {this.state.isDownloadImg && <Loader text={true}/>}
        <section className="pb-0">
          <div className="container-fluid">
            <div className="common-title withsearch">
              <h2>Away Pitchers</h2>
              <div className="right-side">
                <div className="serch-box">
                  <Select
                    className="customize-select"
                    classNamePrefix="customize-select"
                    onChange={this.handleChange.bind(this)}
                    options={pitchersData}
                    value={pitchersData.find((obj) => obj.value === this.state.pitcherDefault)}
                  // defaultValue={{
                  //   label: "All Pitchers",
                  //   value: "",
                  // }}
                  />
                </div>
                {!(this.state.isPrinting || this.props.isPrint) && <div className="dropdown">
                  <span className="printer"><img src={imagesArr.printer} alt="print" /></span>
                  <div className="dropdown-content">
                    <ul>
                      <li onClick={() => this.print()}>Print</li>
                      {/* <li onClick={() => this.printAll()}>Print All</li> */}
                    </ul>
                  </div>
                </div>}
              </div>
            </div>

            {/* pitcher tables */}
            <div id="tablePrintArea">
              {(this.state.isPrinting || this.props.isPrint) ? <h3>{this.state.pitcherDefault}</h3> : ""}
              <div>
                <ResultsTable tableData={awayResultData} />
              </div>
              <div>
                <div className="ss-pitcher-summary-table">
                  <SummaryTable tableData={awayPitchSummaryTableData} />
                </div>
              </div>
              <div>
                <div className="ss-pitcher-platediscipline-table">
                  <PlateDisciplineTable tableData={awayDisciplineTableData} />
                </div>
              </div>
              <div>
                <div className="ss-pitcher-characteristics-table">
                  <PitchCharacteristicsTable tableData={awayCharacteristicTableData} />
                </div>
              </div>
            </div>

            {/* charts */}
            <div className="common-charts" id="awayPitchersMainChartPrintArea">
              <AwayPitchersMainChart
                id="awayPitcherChart"
                printAll={this.props.isPrint}
                chartData={awaySplineChartData}
                metricHandleChange={this.metricHandleChange}
                spline_chart={this.state.spline_chart}
                decreasegame={this.decrease}
                type="game"
                increment={this.props.increment}
                decrement={this.props.decrement}
                eventName={this.props.eventName}
                pitcherDefault={this.state.pitcherDefault}
                profilepage={false}
                isNotDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: false }))}
                isDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: true }))}
              />
            </div>
          </div>

          <div className="container-fluid" id="releaseStrikeChartPrintArea">
            <div className="common-charts">
              <div className={`row ${!(this.state.isPrinting || this.props.isPrint) ? 'singleDiv' : 'breakDiv'}`}>
                <div className="w-33">
                  <AwayPitcherStrikeZoneChart
                    chartData={awayStrikeZoneData}
                    id="strikeZoneChartAwayPitcher"
                    showTitle={true}
                    cameraAngle={["Strike Zone", "Catcher", "Home Top", "Home 3B Side", "Home 1B Side", "Press Box"]}
                    visualType="Pitching"
                    increment={this.props.increment}
                    decrement={this.props.decrement}
                    eventName={this.props.eventName}
                    pitcherDefault={this.state.pitcherDefault}
                    profilepage={false}
                    isNotDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: false }))}
                    isDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: true }))}
                  />
                </div>
                <div className="w-33">
                  <AwayPitcherReleasePointChart
                    chartData={awayReleasePointChartData}
                    id="releasePointChartAwayPitcher"
                    showTitle={true}
                    cameraAngle={["Pitcher", "Mound Top", "Mound 1B Side", "Mound 3B Side", "First Base", "Third Base"]}
                    visualType="Release"
                    increment={this.props.increment}
                    decrement={this.props.decrement}
                    eventName={this.props.eventName}
                    pitcherDefault={this.state.pitcherDefault}
                    profilepage={false}
                    isNotDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: false }))}
                    isDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: true }))}
                  />
                </div>
                <div className="w-33">
                  <AwayPitcherSideReleaseChart
                    chartData={awaySideReleasePointChartData}
                    id="sideReleasePointChartAwayPitcher"
                    showTitle={true}
                    cameraAngle={["Mound 3B Side", "Mound 1B Side", "Mound Top", "Pitcher", "Third Base", "First Base"]}
                    visualType="Release"
                    increment={this.props.increment}
                    decrement={this.props.decrement}
                    eventName={this.props.eventName}
                    pitcherDefault={this.state.pitcherDefault}
                    profilepage={false}
                    isNotDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: false }))}
                    isDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: true }))}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid" id="GameStrikeChartPrintArea">
            <div className="common-charts">
              <div className={`row ${!(this.state.isPrinting || this.props.isPrint) ? 'singleDiv' : 'breakDiv'}`}>
                <div className="w-33">
                  <AwayPitcherSwingsStrikeZoneChart
                    chartData={awaySwingsChartData}
                    id="strikeZoneChartAwayPitcherSwings"
                    title="Swings"
                    showTitle={true}
                    cameraAngle={["Strike Zone", "Catcher", "Home Top", "Home 3B Side", "Home 1B Side", "Press Box"]}
                    visualType="Pitching"
                    increment={this.props.increment}
                    decrement={this.props.decrement}
                    eventName={this.props.eventName}
                    pitcherDefault={this.state.pitcherDefault}
                    profilepage={false}
                    isNotDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: false }))}
                    isDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: true }))}
                  />
                </div>
                <div className="w-33">
                  <AwayPitcherWhiffsStrikeZoneChart
                    chartData={awayWhiffsChartData}
                    id="strikeZoneChartAwayPitcherWhiffs"
                    title="Whiffs"
                    showTitle={true}
                    cameraAngle={["Strike Zone", "Catcher", "Home Top", "Home 3B Side", "Home 1B Side", "Press Box"]}
                    visualType="Pitching"
                    increment={this.props.increment}
                    decrement={this.props.decrement}
                    eventName={this.props.eventName}
                    pitcherDefault={this.state.pitcherDefault}
                    profilepage={false}
                    isNotDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: false }))}
                    isDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: true }))}
                  />
                </div>
                <div className="w-33">
                  <AwayPitcherBallsInPlayStrikeZoneChart
                    chartData={awayBallsInPlayChartData}
                    id="strikeZoneChartAwayPitcherBallsInPlay"
                    title="Balls In Play"
                    showTitle={true}
                    cameraAngle={["Strike Zone", "Catcher", "Home Top", "Home 3B Side", "Home 1B Side", "Press Box"]}
                    visualType="Pitching"
                    increment={this.props.increment}
                    decrement={this.props.decrement}
                    eventName={this.props.eventName}
                    pitcherDefault={this.state.pitcherDefault}
                    profilepage={false}
                    isNotDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: false }))}
                    isDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: true }))}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid" id="sequencingChartPrintArea">
            <div className="common-charts">
              <div className={`row ${!(this.state.isPrinting || this.props.isPrint) ? 'singleDiv' : 'breakDiv'}`}>
                <div className="w-33">
                  <AwayPitcherPitchSequencingChart
                    chartData={awayPitchersSequenceChart}
                    id="PitchSequencingChartAwayPitcher"
                    showTitle={true}
                    increment={this.props.increment}
                    decrement={this.props.decrement}
                    eventName={this.props.eventName}
                    pitcherDefault={this.state.pitcherDefault}
                    profilepage={false}
                    isNotDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: false }))}
                    isDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: true }))}
                  />
                </div>
                <div className="w-33">
                  <AwayPitcherCountSequencingChart
                    chartData={awayCountSequenceChart}
                    title="Count"
                    id="countSequencingChartAwayPitcher"
                    showTitle={true}
                    increment={this.props.increment}
                    decrement={this.props.decrement}
                    eventName={this.props.eventName}
                    pitcherDefault={this.state.pitcherDefault}
                    profilepage={false}
                    isNotDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: false }))}
                    isDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: true }))}
                  />
                </div>
                <div className="w-33">
                  <AwayPitcherResultSequencingChart
                    chartData={awayResultSequenceChart}
                    title="Result"
                    id="resultSequencingChartAwayPitcher"
                    showTitle={true}
                    increment={this.props.increment}
                    decrement={this.props.decrement}
                    eventName={this.props.eventName}
                    pitcherDefault={this.state.pitcherDefault}
                    profilepage={false}
                    isNotDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: false }))}
                    isDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: true }))}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid" id="movementChartPrintArea">
            <div className="common-charts">
              <div className={`row ${!(this.state.isPrinting || this.props.isPrint) ? 'singleDiv' : 'breakDiv'}`}>
                <div className="w-33">
                  <AwayPitcherLongBreakMovementChart
                    id="longBreakMovementChartAwayPitcher"
                    form="Long-Form" top={55} bottom={15}
                    className="breakMovementChart"
                    bubbleChartData={bubbleChartDataLong}
                    dotChartData={dotChartDataLong}
                    increment={this.props.increment}
                    decrement={this.props.decrement}
                    eventName={this.props.eventName}
                    pitcherDefault={this.state.pitcherDefault}
                    profilepage={false}
                    isNotDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: false }))}
                    isDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: true }))}
                  />
                </div>
                <div className="w-33">
                  <AwayPitcherShortBreakMovementChart
                    id="shortBreakMovementChartAwayPitcher"
                    form="Short-Form" top={30} bottom={55}
                    className="breakMovementChart"
                    bubbleChartData={bubbleChartDataShort}
                    dotChartData={dotChartDataShort}
                    increment={this.props.increment}
                    decrement={this.props.decrement}
                    eventName={this.props.eventName}
                    pitcherDefault={this.state.pitcherDefault}
                    profilepage={false}
                    isNotDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: false }))}
                    isDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: true }))}
                  />
                </div>
                <div className="w-33">
                  <AwayPitcherPolarChart
                    chartData={awayPolarChartData}
                    id="polarChartchartAwayPitcher"
                    increment={this.props.increment}
                    decrement={this.props.decrement}
                    eventName={this.props.eventName}
                    pitcherDefault={this.state.pitcherDefault}
                    profilepage={false}
                    isNotDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: false }))}
                    isDownloadImgLoader={() => this.setState((prevState) => ({ ...prevState, isDownloadImg: true }))}
                  />
                </div>
              </div>
            </div>
          </div>

        <div id="awayPitchTable">
          <div className="ss-pitcher-battedballs-table">
              <AwayBattedBallsTable tableData={awayBattedBallsTable} />
            </div>
        </div>
        </section>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    teamData: state.teamData,
    appState: state.app
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchSummaryData: (data, next) => dispatch(getGameSummaryData(data, next)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AwayPitchers);
