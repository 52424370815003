import React, { Component } from "react";
import BattedBallProfile from "./battedBallProfile";
import HitterSpyayChart from "./hitterSpyayChart";
import { getSprayChartRangeColor } from "../../../shared/function";
import { connect } from "react-redux";
import { getDashboardData } from "../../../store/actions";
import { constant } from "../../../shared/constant";
import HeatMapChart from "./heatMapChart";
import HBDotRangeSpyayChart from "./hitterDotRangeSpyayChart";
import BattedBallTable from "./BattedBallTable";

class ryansmmons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: "",
      sprayChartData: [],
      heatMapChartData: [],
      dotRangeSprayChartData: [],
      tableData: [],
      index: "",
    };
  }

  handleCallback = (index) => {
    this.setState({
      tabs: index,
    });
  };

  componentDidMount = () => {
    this.renderChart();
  };

  renderChart = () => {
    this.setState({
      sprayChartData: getSprayChartRangeColor(constant.softball, this.props.sprayChartData[this.props.bpTable[0].batter_id] || [], "exit_velocity"),
      heatMapChartData: [this.props.heatMapChartData.find(h => h._id === this.props.bpTable[0].batter_id) || []],
      dotRangeSprayChartData: this.props.sprayChartData[this.props.bpTable[0].batter_id] || [],
      tableData: [this.props.battedBallTable.find(h => h._id == this.props.bpTable[0].batter_id) || []],
      index: this.props.keyName,
    });
    this.props.notRender()
  };

  componentDidUpdate = () => {
    if (!this.state.index) this.setState({ index: this.props.keyName });
    else if (this.state.index !== this.props.keyName) this.renderChart();

    if (this.props.isRender) this.renderChart();
  };

  render() {
    const { sprayChartData, heatMapChartData, dotRangeSprayChartData, tableData } = this.state;

    return (
      <>
        <div id="hitterSummaryChartPrintArea">
          <div className="container-fluid" id="chartPrintArea">
            <div className="common-charts">
              <div className="row">
                <div className="w-33">
                  <HeatMapChart
                    id={`HeatMapChart${this.props.index || ""}`}
                    chartData={heatMapChartData}
                    heat_map={this.props.heat_map}
                    onchange={this.props.onChangeHeatMap}
                    pitcherDefault={this.props.keyName}
                    profilepage={false}
                    eventName={this.props.eventName}
                    isNotDownloadImgLoader={this.props.isNotDownloadImgLoader}
                    isDownloadImgLoader={this.props.isDownloadImgLoader} />
                </div>
                <div className="w-33">
                  <HitterSpyayChart
                    index={this.props.index}
                    chartData={sprayChartData}
                    softball={constant.softball}
                    cameraAngle={["Press Box", "Spray Chart", "First Base", "Third Base"]}
                    visualType="Hitting"
                    pitchrIframeFlag={this.props.pitchrIframeFlag}
                    closePitchrIframe={this.props.closePitchrIframe}
                    pitcherDefault={this.props.keyName}
                    profilepage={false}
                    eventName={this.props.eventName}
                    isNotDownloadImgLoader={this.props.isNotDownloadImgLoader}
                    isDownloadImgLoader={this.props.isDownloadImgLoader} />
                </div>
                <div className="w-33">
                  <HBDotRangeSpyayChart
                    index={this.props.index}
                    chartData={dotRangeSprayChartData}
                    softball={constant.softball}
                    cameraAngle={["Press Box", "Spray Chart", "First Base", "Third Base"]}
                    visualType="Hitting"
                    pitchrIframeFlag={this.props.pitchrIframeFlag}
                    closePitchrIframe={this.props.closePitchrIframe}
                    pitcherDefault={this.props.keyName}
                    profilepage={false}
                    eventName={this.props.eventName}
                    isNotDownloadImgLoader={this.props.isNotDownloadImgLoader}
                    isDownloadImgLoader={this.props.isDownloadImgLoader} />
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid" id="tablePrintArea">
            <div className="ryansmmons-table">
              {this.props.bpTable && (
                <BattedBallProfile
                  battedTable={this.props.bpTable}
                  type="ryansmmons"
                />
              )}
            </div>
            <div className="ryansmmons-table">
              {tableData && (
                <BattedBallTable
                  battedBallTable={tableData}
                />
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    teamData: state.app.teamData
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchDashboardData: (data, next) => dispatch(getDashboardData(data, next)),
  loader: (data) => dispatch({ type: "LOADING", payload: data })
});
export default connect(mapStateToProps, mapDispatchToProps)(ryansmmons);
