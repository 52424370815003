import React from 'react';
import PitchPercHeatMap from '../../../components/Charts/HeatMapChart';
import PitchCountHeatMap from '../../../components/Charts/HeatMapChart';
import SwingPercHeatMap from '../../../components/Charts/HeatMapChart';
import SwingCountHeatMap from '../../../components/Charts/HeatMapChart';
import WhiffPercHeatMap from '../../../components/Charts/HeatMapChart';
import WhiffCountHeatMap from '../../../components/Charts/HeatMapChart';
import BattedBallsHeatMap from '../../../components/Charts/HeatMapChart';
import AvgEVHeatMap from '../../../components/Charts/HeatMapChart';
import HardHitPercHeatMap from '../../../components/Charts/HeatMapChart';
import HardHitCountHeatMap from '../../../components/Charts/HeatMapChart';
import DamagePercHeatMap from '../../../components/Charts/HeatMapChart';
import DamageCountHeatMap from '../../../components/Charts/HeatMapChart';
import GBPercHeatMap from '../../../components/Charts/HeatMapChart';
import LDPercHeatMap from '../../../components/Charts/HeatMapChart';
import FBPercHeatMap from '../../../components/Charts/HeatMapChart';
import PUPercHeatMap from '../../../components/Charts/HeatMapChart';
import AvgVelocityHeatMap from '../../../components/Charts/HeatMapChart';
import AvgSpinRateHeatMap from '../../../components/Charts/HeatMapChart';
import AvgTiltHeatMap from '../../../components/Charts/HeatMapChart';
import AvgLAHeatMap from '../../../components/Charts/HeatMapChart';
import EfficiencyHeatMap from '../../../components/Charts/HeatMapChart';
import VBreakHeatMap from '../../../components/Charts/HeatMapChart';
import InducedVBreakHeatMap from '../../../components/Charts/HeatMapChart';
import HBreakHeatMap from '../../../components/Charts/HeatMapChart';
import VApproachHeatMap from '../../../components/Charts/HeatMapChart';
import HApproachHeatMap from '../../../components/Charts/HeatMapChart';
import VReleaseAngleHeatMap from '../../../components/Charts/HeatMapChart';
import HReleaseAngleHeatMap from '../../../components/Charts/HeatMapChart';
import { constant } from '../../../shared/constant';

function HeatMapTab({ filterRes, eventProfileData, sendReq, tabslist, isNotDownloadImgLoader, isDownloadImgLoader }) {
    return (
        <>
            <div className="d-flex">
                <div className="w-100">
                    <div className="container-fluid">
                        <div className="common-charts">
                            <div className="row HeatMap-row">
                                <div className="w-25">
                                    <PitchPercHeatMap
                                        chartData={filterRes?.pitch_perc_heat_map || []}
                                        heat_map="Pitch"
                                        id="PitchPercHeatMapChart"
                                        title="Pitch %"
                                        eventProfileData={eventProfileData}
                                        sendReq={sendReq}
                                        tabslist={tabslist}
                                        profilepage={true}
                                        isNotDownloadImgLoader={isNotDownloadImgLoader}
                                        isDownloadImgLoader={isDownloadImgLoader} />
                                </div>
                                <div className="w-25">
                                    <PitchCountHeatMap
                                        chartData={filterRes?.pitch_count_heat_map || []}
                                        heat_map="Pitch"
                                        id="PitchCountHeatMapChart"
                                        title="Pitch #"
                                        eventProfileData={eventProfileData}
                                        sendReq={sendReq}
                                        tabslist={tabslist}
                                        profilepage={true}
                                        isNotDownloadImgLoader={isNotDownloadImgLoader}
                                        isDownloadImgLoader={isDownloadImgLoader} />
                                </div>
                                <div className="w-25">
                                    <SwingPercHeatMap
                                        chartData={filterRes?.swing_perc_heat_map || []}
                                        heat_map="Swing"
                                        id="SwingPercHeatMapChart"
                                        title="Swing %"
                                        eventProfileData={eventProfileData}
                                        sendReq={sendReq}
                                        tabslist={tabslist}
                                        profilepage={true}
                                        isNotDownloadImgLoader={isNotDownloadImgLoader}
                                        isDownloadImgLoader={isDownloadImgLoader} />
                                </div>
                                <div className="w-25">
                                    <SwingCountHeatMap
                                        chartData={filterRes?.swing_count_heat_map || []}
                                        heat_map="Swing"
                                        id="SwingCountHeatMapChart"
                                        title="Swing #"
                                        eventProfileData={eventProfileData}
                                        sendReq={sendReq}
                                        tabslist={tabslist}
                                        profilepage={true}
                                        isNotDownloadImgLoader={isNotDownloadImgLoader}
                                        isDownloadImgLoader={isDownloadImgLoader} />
                                </div>
                            </div>
                        </div>
                        <div className="common-charts">
                            <div className="row HeatMap-row">
                                <div className="w-25">
                                    <WhiffPercHeatMap
                                        chartData={filterRes?.whiff_perc_heat_map || []}
                                        heat_map="Whiff"
                                        id="WhiffPercHeatMapChart"
                                        title="Whiff %"
                                        eventProfileData={eventProfileData}
                                        sendReq={sendReq}
                                        tabslist={tabslist}
                                        profilepage={true}
                                        isNotDownloadImgLoader={isNotDownloadImgLoader}
                                        isDownloadImgLoader={isDownloadImgLoader} />
                                </div>
                                <div className="w-25">
                                    <WhiffCountHeatMap
                                        chartData={filterRes?.whiff_count_heat_map || []}
                                        heat_map="Whiff"
                                        id="WhiffCountHeatMapChart"
                                        title="Whiff #"
                                        eventProfileData={eventProfileData}
                                        sendReq={sendReq}
                                        tabslist={tabslist}
                                        profilepage={true}
                                        isNotDownloadImgLoader={isNotDownloadImgLoader}
                                        isDownloadImgLoader={isDownloadImgLoader} />
                                </div>
                                <div className="w-25">
                                    <BattedBallsHeatMap
                                        chartData={filterRes?.batted_balls_heat_map || []}
                                        heat_map="Batted Balls"
                                        id="BattedBallsHeatMapChart"
                                        title="Batted Balls"
                                        eventProfileData={eventProfileData}
                                        sendReq={sendReq}
                                        tabslist={tabslist}
                                        profilepage={true}
                                        isNotDownloadImgLoader={isNotDownloadImgLoader}
                                        isDownloadImgLoader={isDownloadImgLoader} />
                                </div>
                                <div className="w-25">
                                    <AvgEVHeatMap
                                        chartData={filterRes?.avg_ev_heat_map || []}
                                        heat_map="Avg EV"
                                        id="AvgEVHeatMapChart"
                                        title="Avg EV"
                                        eventProfileData={eventProfileData}
                                        sendReq={sendReq}
                                        tabslist={tabslist}
                                        profilepage={true}
                                        isNotDownloadImgLoader={isNotDownloadImgLoader}
                                        isDownloadImgLoader={isDownloadImgLoader} />
                                </div>
                            </div>
                        </div>
                        <div className="common-charts">
                            <div className="row HeatMap-row">
                                <div className="w-25">
                                    <HardHitPercHeatMap
                                        chartData={filterRes?.hard_hit_perc_heat_map || []}
                                        heat_map="Hard Hit"
                                        id="HardHitPercHeatMapChart"
                                        title="Hard Hit %"
                                        eventProfileData={eventProfileData}
                                        sendReq={sendReq}
                                        tabslist={tabslist}
                                        profilepage={true}
                                        isNotDownloadImgLoader={isNotDownloadImgLoader}
                                        isDownloadImgLoader={isDownloadImgLoader} />
                                </div>
                                <div className="w-25">
                                    <HardHitCountHeatMap
                                        chartData={filterRes?.hard_hit_count_heat_map || []}
                                        heat_map="Hard Hit"
                                        id="HardHitCountHeatMapChart"
                                        title="Hard Hit #"
                                        eventProfileData={eventProfileData}
                                        sendReq={sendReq}
                                        tabslist={tabslist}
                                        profilepage={true}
                                        isNotDownloadImgLoader={isNotDownloadImgLoader}
                                        isDownloadImgLoader={isDownloadImgLoader} />
                                </div>
                                <div className="w-25">
                                    <DamagePercHeatMap
                                        chartData={filterRes?.damage_perc_heat_map || []}
                                        heat_map="Damage"
                                        id="DamagePercHeatMapChart"
                                        title="Damage %"
                                        eventProfileData={eventProfileData}
                                        sendReq={sendReq}
                                        tabslist={tabslist}
                                        profilepage={true}
                                        isNotDownloadImgLoader={isNotDownloadImgLoader}
                                        isDownloadImgLoader={isDownloadImgLoader} />
                                </div>
                                <div className="w-25">
                                    <DamageCountHeatMap
                                        chartData={filterRes?.damage_count_heat_map || []}
                                        heat_map="Damage"
                                        id="DamageCountHeatMapChart"
                                        title="Damage #"
                                        eventProfileData={eventProfileData}
                                        sendReq={sendReq}
                                        tabslist={tabslist}
                                        profilepage={true}
                                        isNotDownloadImgLoader={isNotDownloadImgLoader}
                                        isDownloadImgLoader={isDownloadImgLoader} />
                                </div>
                            </div>
                        </div>
                        <div className="common-charts">
                            <div className="row HeatMap-row">
                                <div className="w-25">
                                    <GBPercHeatMap
                                        chartData={filterRes?.gb_heat_map || []}
                                        heat_map="GB"
                                        id="GBPercHeatMapChart"
                                        title="GB %"
                                        eventProfileData={eventProfileData}
                                        sendReq={sendReq}
                                        tabslist={tabslist}
                                        profilepage={true}
                                        isNotDownloadImgLoader={isNotDownloadImgLoader}
                                        isDownloadImgLoader={isDownloadImgLoader} />
                                </div>
                                <div className="w-25">
                                    <LDPercHeatMap
                                        chartData={filterRes?.ld_heat_map || []}
                                        heat_map="LD"
                                        id="LDPercHeatMapChart"
                                        title="LD %"
                                        eventProfileData={eventProfileData}
                                        sendReq={sendReq}
                                        tabslist={tabslist}
                                        profilepage={true}
                                        isNotDownloadImgLoader={isNotDownloadImgLoader}
                                        isDownloadImgLoader={isDownloadImgLoader} />
                                </div>
                                <div className="w-25">
                                    <FBPercHeatMap
                                        chartData={filterRes?.fb_heat_map || []}
                                        heat_map="FB"
                                        id="FBPercHeatMapChart"
                                        title="FB %"
                                        eventProfileData={eventProfileData}
                                        sendReq={sendReq}
                                        tabslist={tabslist}
                                        profilepage={true}
                                        isNotDownloadImgLoader={isNotDownloadImgLoader}
                                        isDownloadImgLoader={isDownloadImgLoader} />
                                </div>
                                <div className="w-25">
                                    <PUPercHeatMap
                                        chartData={filterRes?.pu_heat_map || []}
                                        heat_map="PU"
                                        id="PUPercHeatMapChart"
                                        title="PU %"
                                        eventProfileData={eventProfileData}
                                        sendReq={sendReq}
                                        tabslist={tabslist}
                                        profilepage={true}
                                        isNotDownloadImgLoader={isNotDownloadImgLoader}
                                        isDownloadImgLoader={isDownloadImgLoader} />
                                </div>
                            </div>
                        </div>
                        <div className="common-charts">
                            <div className="row HeatMap-row">
                                <div className="w-25">
                                    <AvgVelocityHeatMap
                                        chartData={filterRes?.velocity_heat_map || []}
                                        heat_map="Avg Velocity"
                                        id="AvgVelocityHeatMapChart"
                                        title="Avg Velocity"
                                        min={constant.softball ? 30 : 50}
                                        max={constant.softball ? 80 : 100}
                                        Interval={10}
                                        eventProfileData={eventProfileData}
                                        sendReq={sendReq}
                                        tabslist={tabslist}
                                        profilepage={true}
                                        isNotDownloadImgLoader={isNotDownloadImgLoader}
                                        isDownloadImgLoader={isDownloadImgLoader} />
                                </div>
                                <div className="w-25">
                                    <AvgSpinRateHeatMap
                                        chartData={filterRes?.spin_rate_heat_map || []}
                                        heat_map="Avg Spin Rate"
                                        id="AvgSpinRateHeatMapChart"
                                        title="Avg Spin Rate"
                                        min={500}
                                        max={constant.softball ? 1750 : 3000}
                                        Interval={constant.softball ? 250 : 500}
                                        eventProfileData={eventProfileData}
                                        sendReq={sendReq}
                                        tabslist={tabslist}
                                        profilepage={true}
                                        isNotDownloadImgLoader={isNotDownloadImgLoader}
                                        isDownloadImgLoader={isDownloadImgLoader} />
                                </div>
                                <div className="w-25">
                                    <AvgTiltHeatMap
                                        chartData={filterRes?.tilt_heat_map || []}
                                        heat_map="Avg Tilt"
                                        id="AvgTiltHeatMapChart"
                                        title="Avg Tilt"
                                        eventProfileData={eventProfileData}
                                        sendReq={sendReq}
                                        tabslist={tabslist}
                                        profilepage={true}
                                        isNotDownloadImgLoader={isNotDownloadImgLoader}
                                        isDownloadImgLoader={isDownloadImgLoader} />
                                </div>
                                <div className="w-25">
                                    <AvgLAHeatMap
                                        chartData={filterRes?.avg_la_heat_map || []}
                                        heat_map="Avg LA"
                                        id="AvgLAHeatMapChart"
                                        title="Avg LA"
                                        eventProfileData={eventProfileData}
                                        sendReq={sendReq}
                                        tabslist={tabslist}
                                        profilepage={true}
                                        isNotDownloadImgLoader={isNotDownloadImgLoader}
                                        isDownloadImgLoader={isDownloadImgLoader} />
                                </div>
                            </div>
                        </div>
                        <div className="common-charts">
                            <div className="row HeatMap-row">
                                <div className="w-25">
                                    <EfficiencyHeatMap
                                        chartData={filterRes?.efficiency_heat_map || []}
                                        heat_map="Efficiency"
                                        id="EfficiencyHeatMapChart"
                                        title="Efficiency"
                                        eventProfileData={eventProfileData}
                                        sendReq={sendReq}
                                        tabslist={tabslist}
                                        profilepage={true}
                                        isNotDownloadImgLoader={isNotDownloadImgLoader}
                                        isDownloadImgLoader={isDownloadImgLoader} />
                                </div>
                                <div className="w-25">
                                    <VBreakHeatMap
                                        chartData={filterRes?.v_break_heat_map || []}
                                        heat_map="V Break"
                                        id="VBreakHeatMapChart"
                                        title="V Break"
                                        min={constant.softball ? -100 : -75}
                                        max={constant.softball ? 0 : 0}
                                        Interval={constant.softball ? 20 : 15}
                                        eventProfileData={eventProfileData}
                                        sendReq={sendReq}
                                        tabslist={tabslist}
                                        profilepage={true}
                                        isNotDownloadImgLoader={isNotDownloadImgLoader}
                                        isDownloadImgLoader={isDownloadImgLoader} />
                                </div>
                                <div className="w-25">
                                    <InducedVBreakHeatMap
                                        chartData={filterRes?.ind_v_break_heat_map || []}
                                        heat_map="Induced V Break"
                                        id="InducedVBreakHeatMapChart"
                                        title="Induced V Break"
                                        min={constant.softball ? -15 : -20}
                                        max={constant.softball ? 10 : 30}
                                        Interval={constant.softball ? 5 : 10}
                                        eventProfileData={eventProfileData}
                                        sendReq={sendReq}
                                        tabslist={tabslist}
                                        profilepage={true}
                                        isNotDownloadImgLoader={isNotDownloadImgLoader}
                                        isDownloadImgLoader={isDownloadImgLoader} />
                                </div>
                                <div className="w-25">
                                    <HBreakHeatMap
                                        chartData={filterRes?.h_break_heat_map || []}
                                        heat_map="H Break"
                                        id="HBreakHeatMapChart"
                                        title="H Break"
                                        min={-20}
                                        max={20}
                                        Interval={10}
                                        eventProfileData={eventProfileData}
                                        sendReq={sendReq}
                                        tabslist={tabslist}
                                        profilepage={true}
                                        isNotDownloadImgLoader={isNotDownloadImgLoader}
                                        isDownloadImgLoader={isDownloadImgLoader} />
                                </div>
                            </div>
                        </div>
                        <div className="common-charts">
                            <div className="row HeatMap-row">
                                <div className="w-25">
                                    <VApproachHeatMap
                                        chartData={filterRes?.v_approach_heat_map || []}
                                        heat_map="V Approach"
                                        id="VApproachHeatMapChart"
                                        title="V Approach"
                                        min={constant.softball ? -10 : -12}
                                        max={constant.softball ? 0 : -2}
                                        Interval={2}
                                        eventProfileData={eventProfileData}
                                        sendReq={sendReq}
                                        tabslist={tabslist}
                                        profilepage={true}
                                        isNotDownloadImgLoader={isNotDownloadImgLoader}
                                        isDownloadImgLoader={isDownloadImgLoader} />
                                </div>
                                <div className="w-25">
                                    <HApproachHeatMap
                                        chartData={filterRes?.h_approach_heat_map || []}
                                        heat_map="H Approach"
                                        id="HApproachHeatMapChart"
                                        title="H Approach"
                                        min={-4}
                                        max={4}
                                        Interval={2}
                                        eventProfileData={eventProfileData}
                                        sendReq={sendReq}
                                        tabslist={tabslist}
                                        profilepage={true}
                                        isNotDownloadImgLoader={isNotDownloadImgLoader}
                                        isDownloadImgLoader={isDownloadImgLoader} />
                                </div>
                                <div className="w-25">
                                    <VReleaseAngleHeatMap
                                        chartData={filterRes?.v_release_heat_map || []}
                                        heat_map="V Release Angle"
                                        id="VReleaseAngleHeatMapChart"
                                        title="V Release Angle"
                                        min={-4}
                                        max={4}
                                        Interval={2}
                                        eventProfileData={eventProfileData}
                                        sendReq={sendReq}
                                        tabslist={tabslist}
                                        profilepage={true}
                                        isNotDownloadImgLoader={isNotDownloadImgLoader}
                                        isDownloadImgLoader={isDownloadImgLoader} />
                                </div>
                                <div className="w-25">
                                    <HReleaseAngleHeatMap
                                        chartData={filterRes?.h_release_heat_map || []}
                                        heat_map="H Release Angle"
                                        id="HReleaseAngleHeatMapChart"
                                        title="H Release Angle"
                                        min={-6}
                                        max={6}
                                        Interval={3}
                                        eventProfileData={eventProfileData}
                                        sendReq={sendReq}
                                        tabslist={tabslist}
                                        profilepage={true}
                                        isNotDownloadImgLoader={isNotDownloadImgLoader}
                                        isDownloadImgLoader={isDownloadImgLoader} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HeatMapTab