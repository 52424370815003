import React from 'react'
import { constant } from '../../../../shared/constant';

function SummaryTable({ tableData, title }) {
    const renderList = () => {
        return tableData && tableData.length !== 0 ? (
            tableData.map((data, i) => {
                const rowColor = data._id === "Total" ? "table-rowgrey80" : "table-rowwhite"
                return (
                    <tr key={i}>
                        <td
                            className={`${rowColor}`}
                            style={{ color: constant.pitchTypeColor[data._id] }}
                        >
                            {data._id ? data._id : "-"}
                        </td>
                        <td className={`${rowColor}`}>{data.count ? data.count : "-"}</td>
                        <td className={`${rowColor}`}>
                            {data.pitch_perc ? data.pitch_perc.toString().includes(".") ? data.pitch_perc.toFixed(1) + "%" : data.pitch_perc + "%" : '0%'}
                        </td>
                        <td className={`${rowColor}`}>
                            {data.rhp ? data.rhp.toString().includes(".") ? data.rhp.toFixed(1) + "%" : data.rhp + "%" : '0%'}
                        </td>
                        <td className={`${rowColor}`}>
                            {data.lhp ? data.lhp.toString().includes(".") ? data.lhp.toFixed(1) + "%" : data.lhp + "%" : '0%'}
                        </td>
                        <td className={`${rowColor}`}>
                            {data.egde ? data.egde.toString().includes(".") ? data.egde.toFixed(1) + "%" : data.egde + "%" : '0%'}
                        </td>
                        <td className={`${rowColor}`}>
                            {data.takes ? data.takes.toString().includes(".") ? data.takes.toFixed(1) : data.takes : '—'}
                        </td>
                        <td className={`${rowColor}`}>
                            {data.called_strikes ? data.called_strikes.toString().includes(".") ? data.called_strikes.toFixed(1) : data.called_strikes : '—'}
                        </td>
                        <td className={`${rowColor}`}>
                            {data.swings ? data.swings.toString().includes(".") ? data.swings.toFixed(1) : data.swings : '—'}
                        </td>
                        <td className={`${rowColor}`}>
                            {data.whiffs ? data.whiffs.toString().includes(".") ? data.whiffs.toFixed(1) : data.whiffs : '—'}
                        </td>
                        <td className={`${rowColor}`}>
                            {data.csw ? data.csw.toString().includes(".") ? data.csw.toFixed(1) + "%" : data.csw + "%" : '0%'}
                        </td>
                        <td className={`${rowColor}`}>
                            {data.fouls ? data.fouls.toString().includes(".") ? data.fouls.toFixed(1) : data.fouls : '—'}
                        </td>
                        <td className={`${rowColor}`}>
                            {data.hasOwnProperty("bip") ? data.bip !== null ? data.bip.toString().includes(".") ? data.bip.toFixed(1) : data.bip : '—' : '—'}
                        </td>
                    </tr>
                );
            })
        ) : (
            <tr>
                <td className="table-rowwhite text-center" colSpan="13">
                    There's no info yet!
                </td>
            </tr>
        );
    };

    return (
        <div className="w-100">
            {title === false ? "" : <h3 className="table-title">Summary Table</h3>}
            <div className="homepitchers-table">
                <div className="table-responsive-xl">
                    <table className="table tabledashed">
                        <tbody>
                            <tr>
                                <td className="tablerowblue fw-500">Pitch Type</td>
                                <td className="tablerowblue fw-500">#</td>
                                <td className="tablerowblue fw-500">%</td>
                                <td className="tablerowblue fw-500">%RHP</td>
                                <td className="tablerowblue fw-500">%LHP</td>
                                <td className="tablerowblue fw-500">Edge %</td>
                                <td className="tablerowblue fw-500">Takes</td>
                                <td className="tablerowblue fw-500">Called Strikes</td>
                                <td className="tablerowblue fw-500">Swings</td>
                                <td className="tablerowblue fw-500">Whiffs</td>
                                <td className="tablerowblue fw-500">CSW %</td>
                                <td className="tablerowblue fw-500">Fouls</td>
                                <td className="tablerowblue fw-500">BIP</td>
                            </tr>
                            {renderList()}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default SummaryTable
