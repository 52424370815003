import React, { useEffect, useState } from "react";
import * as d3 from "d3";
import {
  removeNullEntryFromArrayOfObjects,
  sortByPlayResult,
  uniqueEntriesFromArray,
} from "../../../shared/function";
import NoSpreyChart from "../commonChartSettings/noSpreyChart";
import BasicSpray from "../../../shared/SprayChartd3";
import { constant } from "../../../shared/constant";
import domtoimage from 'dom-to-image';
import PrintImagePopup from "../../../shared/PrintImagePopup";
import { downloadChartPrintImg } from "../../../shared/printReportUtils";
import use3DView from "../../../shared/use3DView";
import PitchRWebGL from "../../webGL/PitchRWebGL";
import Toggle from "../../../shared/Toggle";

function HBSpyayChart({ chartData, cameraAngle, visualType, pitchLocationType, colorScheme, eventProfileData, sendReq, tabslist, profilepage, isNotDownloadImgLoader, isDownloadImgLoader, closePitchrIframe, pitchrIframeFlag }) {
  const [data3D, setData3D, toggle, setToggle, onClickToggleButton, flag] = use3DView("groundChart1");
  const [isChartImg, setIsChartImg] = useState(false);
  const [clickFlag, setClickFlag] = useState(false);
  const [chartLegendData, setChartLegendData] = useState([]);

  useEffect(() => {
    let arr = chartData && chartData.length !== 0 && chartData.map(x => x?._id) || []
    setData3D(arr)
    const removeNullEntriesFromChartData = removeNullEntryFromArrayOfObjects(
      chartData
    );
    const uniqueNames = uniqueEntriesFromArray(
      removeNullEntriesFromChartData,
      "pitch_call"
    );
    let includeArr = ['Out', 'Single', 'Double', 'Triple', 'Home Run', 'Error', 'Foul', 'Sacrifice']
    for (let i = 0; i < uniqueNames.length; i++) {
      if (!includeArr.includes(uniqueNames[i])) {
        includeArr.push(uniqueNames[i])
      }
    }
    setChartLegendData(sortByPlayResult(uniqueNames, includeArr));
    renderChart(chartData);
  }, [chartData, toggle]);

  const renderChart = (data) => {
    if (data) {
      const selection = d3.select("#groundChart1");
      if (!selection.empty()) {
        const sprayChart = new BasicSpray(selection, constant.softball);
        sprayChart.update(data);
      }
    }
  };

  useEffect(() => {
    if (pitchrIframeFlag === "yes") {
      setData3D(null)
      setToggle({ chartToggle: true });
      closePitchrIframe();
    }
  }, [pitchrIframeFlag])

  const chartImage = (id) => {
    isDownloadImgLoader();
    setClickFlag(true)
    if (document.getElementById(`img${id}`)) {
      document.getElementById(`img${id}`).remove()
    }
    setIsChartImg(true)
    downloadChartPrintImg(id, setIsChartImg, setClickFlag, isNotDownloadImgLoader)
  }

  return (
    <>
      {chartData && chartData.length !== 0 ? (
        <div className="ground-chart sprayChartCls contactTabSpray1 fixed-h">
          {chartData && chartData.length !== 0 ? <Toggle toggle={toggle} onClickToggleButton={onClickToggleButton} /> : ""}
          <div style={{ display: toggle.chartToggle === true ? 'block' : 'none' }}>
            <div className="spraychart-section">
              <div className="legends text-left fixed-s-l">
                <div className="text-left d-inline">
                  <h3 className="mb-0">Spray Chart</h3>
                  {chartLegendData.map((pitch, i) => {
                    return (
                      <div key={i} className="legend">
                        <span
                          className="legendCircle"
                          style={{
                            backgroundColor: constant.colors[pitch],
                          }}
                        />
                        <span className="legendText">{pitch || "None"}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div id="groundChart1" className="groundChart"></div>
              {/* <button className="btn blue btn-view" onClick={() => chartImage("groundChart")} disabled={clickFlag} >Download</button> */}
              <div class="chartdownload" onClick={() => chartImage("groundChart1")} disabled={clickFlag}><a class="btn white"><i class="fa fa-download" aria-hidden="true"></i></a></div>
            </div>
          </div>
          {flag?.["iframe_groundChart1"] === true && <div id="pitchR-iframe" className="Chart-iframe" style={{ display: toggle.chartToggle === false ? 'block' : 'none' }}>
            <PitchRWebGL
              data={data3D}
              id="groundChart1"
              cameraAngle={cameraAngle}
              visualType={visualType}
              Toggle={toggle.chartToggle}
              pitchLocationType={pitchLocationType}
              colorScheme={colorScheme} />
          </div>}
        </div>
      ) : (
        <>
          <div className="ground-chart contactTabSpray1 fixed-h">
            <div className="legends text-left fixed-s-l">
              <div className="text-left d-inline">
                <h3 className="mb-0">Spray Chart</h3>
              </div>
            </div>
            <NoSpreyChart tabName="contactTab" />
          </div>
        </>
      )}
      
      {/* chart visual image download */}
      {isChartImg && <PrintImagePopup
        title="Spray Chart"
        eventProfileData={eventProfileData}
        id="groundChart1"
        sendReq={sendReq}
        tabslist={tabslist}
        profilepage={profilepage} />}
    </>
  );
}

export default HBSpyayChart;
