import React, { useEffect, useState } from 'react'
import { constant } from '../../../../shared/constant';

function Zone({ zone_data, zone, home_away_teams }) {
    const [zoneData, setZoneData] = useState({})

    useEffect(() => {
        let obj = {};
        if (zone_data && zone_data.length !== 0) {
            zone_data.map(e => {
                e.data.map(p => {
                    if (!obj[p.pitch_type]) obj[p.pitch_type] = {}
                    obj[p.pitch_type][p.batter_team] = p
                })
            })
            setZoneData(obj)
        }
    }, [zone_data])

    return (
        <>
            <div className="w-50">
                <div className="gamesummary-table m-0">
                    <div className="table-responsive-xl">
                        <table className="table tabledashed">
                            <thead>
                                <tr>
                                    {home_away_teams?.home_team === home_away_teams?.away_team ? "" : <th className="one-child text-center">{home_away_teams?.home_team}</th>}
                                    <th className="one-child text-center">{zone}</th>
                                    <th className="one-child text-center">{home_away_teams?.away_team}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    zoneData && zoneData.length !== 0 ?
                                        <>
                                            {Object.keys(zoneData).map((z, i) => {
                                                return (
                                                    <>
                                                        <tr key={i}>
                                                            {home_away_teams?.home_team === home_away_teams?.away_team ? "" : <td className="table-rowwhitesmoke"></td>}
                                                            <td style={{ color: constant.pitchTypeColor[z] }} className="table-rowwhitesmoke">{z}</td>
                                                            <td className="table-rowwhitesmoke"></td>
                                                        </tr>
                                                        <tr>
                                                            {home_away_teams?.home_team === home_away_teams?.away_team ? "" : <td className="table-rowwhite">{zoneData[z][home_away_teams?.home_team]?.swing || "-"}</td>}
                                                            <td className="table-rowwhite">Swings</td>
                                                            <td className="table-rowwhite">{zoneData[z][home_away_teams?.away_team]?.swing || "-"}</td>
                                                        </tr>
                                                        <tr>
                                                            {home_away_teams?.home_team === home_away_teams?.away_team ? "" : <td className="table-rowwhite">{zoneData[z][home_away_teams?.home_team]?.takes || "-"}</td>}
                                                            <td className="table-rowwhite">Takes</td>
                                                            <td className="table-rowwhite">{zoneData[z][home_away_teams?.away_team]?.takes || "-"}</td>
                                                        </tr>
                                                    </>
                                                );
                                            })}
                                            <tr>
                                                {home_away_teams?.home_team === home_away_teams?.away_team ? "" : <td className="table-rowgrey80">{zone_data?.find(z => z._id.batter_team === home_away_teams?.home_team)?.total_swing || "-"}</td>}
                                                <td className="table-rowgrey80">Total Swings</td>
                                                <td className="table-rowgrey80">{zone_data?.find(z => z._id.batter_team === home_away_teams?.away_team)?.total_swing || "-"}</td>
                                            </tr>
                                            <tr>
                                                {home_away_teams?.home_team === home_away_teams?.away_team ? "" : <td className="table-rowgrey80">{zone_data?.find(z => z._id.batter_team === home_away_teams?.home_team)?.total_takes || "-"}</td>}
                                                <td className="table-rowgrey80">Total Takes</td>
                                                <td className="table-rowgrey80">{zone_data?.find(z => z._id.batter_team === home_away_teams?.away_team)?.total_takes || "-"}</td>
                                            </tr>
                                        </>
                                        :
                                        <tr>
                                            <td className="table-rowwhite text-center" colSpan="3">
                                                There's no info yet!
                                            </td>
                                        </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Zone
