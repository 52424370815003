import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

function use3DView(id) {
    const flag = useSelector(state => state.app)
    const dispatch = useDispatch()
    const [data3D, setData3D] = useState(null);
    const [toggle, setToggle] = useState({ chartToggle: true });

    const onClickToggleButton = () => {
        setToggle({
            chartToggle: !toggle.chartToggle,
        });
        dispatch({
            type: "PITCHAR_IFRAME_LOAD",
            variable: `iframe_${id}`,
            payload: true
        })
    };

    const onClickToggle = () => {
        setToggle({
            chartToggle: false,
        });
        dispatch({
            type: "PITCHAR_IFRAME_LOAD",
            variable: `iframe_${id}`,
            payload: true
        })
    };

    return [data3D, setData3D, toggle, setToggle, onClickToggleButton, flag,onClickToggle]
}

export default use3DView
