import React, { Component } from 'react';

class peakExitVelocityLeaders extends Component {

    renderList = () => {
        return (
            this.props.data &&
                this.props.data.length !== 0 ?
                this.props.data.map((list, i) => {
                    return (
                        <tr key={i}>
                            <td className="table-rowwhite">
                                {list.player_name ? list.player_name : "-"}
                            </td>
                            <td className="table-rowwhite">
                                {list.exit_velocity.length !== 0 ? list.exit_velocity.toString().includes(".") ? list.exit_velocity.toFixed(1) : list.exit_velocity : "-"}
                            </td>
                        </tr>
                    );
                })
                :
                <tr>
                    <td className="table-rowwhite text-center" colSpan="2">
                        There's no info yet!
                    </td>
                </tr>
        )
    }

    render() {
        return (
            <>
                <div className="w-50">
                    <div className="gamesummary-table">
                        <div className="table-responsive-xl">
                            <table className="table tabledashed">
                                <thead>
                                    <tr>
                                        <th colSpan="2" className="one-child text-center">
                                            Peak Exit Velocity Leaders
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="tablerowgray fw-500">Name</td>
                                        <td className="tablerowgray fw-500">Exit Velocity</td>
                                    </tr>
                                    {this.renderList()}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default peakExitVelocityLeaders;
