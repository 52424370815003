import React, { Component } from 'react'
import { Link } from "react-router-dom";
import Summary from './component/summary/summary';
import Charts from './component/charts/charts';
import Platedisc from './component/platedisc/platedisc';
import { imagesArr } from '../../assets/images';
// components
import Header from "../../containers/header";
import { getDevelopmentPlayerData, getDevelopmentSearch, getDevelopmentSummary } from '../../store/actions';
import { connect } from 'react-redux';
import Select, { components } from "react-select";
import EventListA from './component/eventListA';
import EventListB from './component/eventListB';
import { printToPDFMultiple } from '../../shared/printReportUtils';
import { NoInfo } from './component/noInfo';


class development extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tabs: 0,
            isshow: true,
            isshowtype: true,
            isshowdate: true,
            isshowb: true,
            isshowtypeb: true,
            isshowdateb: true,
            iscareticon: false,
            iscareticondate: false,
            iscareticondateA: false,
            iscareticontype: false,
            selectAll: {
                A: false,
                B: false
            },
            search: {
                q: ""
            },
            filterA: {
                playerId: "",
                date: "",
                eventType: ""
            },
            filterB: {
                playerId: "",
                date: "",
                eventType: ""
            },
            developA: {
                tab: "summary",
                profileId: "",
                eventIdArr: [],
                pitcher_source: "exit_velocity",
                pitcher_pitch_type: "",
                hitter_pitch_type: ""
            },
            developB: {
                tab: "summary",
                profileId: "",
                eventIdArr: [],
                pitcher_source: "exit_velocity",
                pitcher_pitch_type: "",
                hitter_pitch_type: ""
            },
            teamProfileName: [],
            eventAList: [],
            eventBList: [],
            //selected Event
            groupAList: [],
            groupBList: [],
            profileId: null
        }
        this.handleToggle = this.handleToggle.bind(this);
        this.handleToggleMenu = this.handleToggleMenu.bind(this);
        this.handleToggleDate = this.handleToggleDate.bind(this);
        this.handleToggleB = this.handleToggleB.bind(this);
        this.handleToggleMenuB = this.handleToggleMenuB.bind(this);
        this.handleToggleDateB = this.handleToggleDateB.bind(this);
    }
    componentDidMount() {
        document.body.classList.add('developmentlist');
        let sorceid = document.location.href.split('/')[3];
        if (sorceid === undefined) window.location.href = '/';
        else this.summaryData();
    }

    // fetch summary data
    summaryData = () => {
        let data = {
            q: "",
        }
        this.props.fetchSummaryData(data, (res) => {
            if (res.flag) {
                let teamProfile = [];
                for (let i = 0; i < res.result.team_profiles.length; i++) {
                    teamProfile.push({
                        value: res.result.team_profiles[i]._id,
                        label: res.result.team_profiles[i].fname + " " + res.result.team_profiles[i].lname,
                    });
                }
                this.setState((prevState) => ({
                    ...prevState,
                    teamProfileName: teamProfile
                }))
            }
        });
    };

    handleTabs = (e) => {
        this.setState((prevState) => ({
            ...prevState,
            tabs: e,
            developA: {
                ...prevState.developA,
                tab: e === 0 ? "summary" : e === 1 ? "charts" : "plate_disc",
            },
            developB: {
                ...prevState.developB,
                tab: e === 0 ? "summary" : e === 1 ? "charts" : "plate_disc",
            },
        }))
    }

    handleToggle() {
        this.setState({
            isshow: !this.state.isshow
        })
    }
    handleToggleB() {
        this.setState({
            isshowb: !this.state.isshowb
        })
    }
    handleToggleMenu() {
        this.setState({
            isshowtype: !this.state.isshowtype,
            iscareticontype: !this.state.iscareticontype,
            isshowdate: true,
            iscareticondateA: true,
            isshowdateb: true,
            iscareticondate: true,
            isshowtypeb: true,
            iscareticon: true,
        })
    }
    handleToggleDate() {
        this.setState({
            isshowdate: !this.state.isshowdate,
            iscareticondateA: !this.state.iscareticondateA,
            isshowtype: true,
            iscareticontype: true,
            isshowdateb: true,
            iscareticondate: true,
            isshowtypeb: true,
            iscareticon: true,
        })
    }
    handleToggleMenuB() {
        this.setState({
            isshowtypeb: !this.state.isshowtypeb,
            iscareticon: !this.state.iscareticon,
            isshowdateb: true,
            iscareticondate: true,
            isshowdate: true,
            iscareticondateA: true,
            isshowtype: true,
            iscareticontype: true,
        })
    }
    handleToggleDateB() {
        this.setState({
            isshowdateb: !this.state.isshowdateb,
            iscareticondate: !this.state.iscareticondate,
            isshowtypeb: true,
            iscareticon: true,
            isshowdate: true,
            iscareticondateA: true,
            isshowtype: true,
            iscareticontype: true,
        })
    }
    // responsive menu toggle class
    // toggleMenuBar = () => {
    //     document.body.classList.toggle("show-menu")
    // }
    handleCallback = (index) => {
        this.setState({
            tabs: index,
        });
    };


    handleChange = (e) => {
        this.setState((prevState) => ({
          ...prevState,
          filterA: {
            ...prevState.filterA,
            playerId: e.value,
          },
          developA: {
            ...prevState.developA,
            profileId: e.value,
            eventIdArr: [],
          },
          filterB: {
            ...prevState.filterB,
            playerId: e.value,
          },
          developB: {
            ...prevState.developB,
            profileId: e.value,
            eventIdArr: [],
          },
          selectAll: {
            A: false,
            B: false,
          },
          isshow: true,
          isshowb: true,
        }));
        let data = {
            playerId: e.value,
            date: "",
            eventType: ""
        }
        this.props.fetchEventList(data, (res) => {
            this.setState(() => ({
                eventAList: res.result.Player_data.length !== 0 ? res.result.Player_data : [],
                eventBList: res.result.Player_data.length !== 0 ? res.result.Player_data : []
            }))
        });
    };

    // handleInputChange = (newValue) => {
    //     const inputValue = newValue.replace(/\W/g, '');
    //     this.setState((prevState) => ({
    //         ...prevState,
    //         search: {
    //             q: newValue
    //         }
    //     }));
    //   };

    handleEventFilter = (filter, name, type) => {
        this.setState((prevState) => ({
            ...prevState,
            [filter]: {
                ...prevState[filter],
                [name]: type
            }
        }), () => {
            this.props.fetchEventList(this.state[filter], (res) => {
                if (filter === "filterA") {
                    this.setState((prevState) => ({
                        ...prevState,
                        eventAList: res.result.Player_data.length !== 0 ? res.result.Player_data : [],
                        developA: {
                            ...prevState.developA,
                            eventIdArr: res.result.Player_data.length !== 0 ? this.state.developA.eventIdArr.filter((s) => res.result.Player_data.some((e) => e.source_id === s)) : [],
                        }
                    }))
                }
                else {
                    this.setState((prevState) => ({
                        ...prevState,
                        eventBList: res.result.Player_data.length !== 0 ? res.result.Player_data : [],
                        developB: {
                            ...prevState.developB,
                            eventIdArr: res.result.Player_data.length !== 0 ? this.state.developB.eventIdArr.filter((s) => res.result.Player_data.some((e) => e.source_id === s)) : [],
                        }
                    }))
                }
            });
        });
    }

    handleEvent = (group, id) => {
        if (group === "groupA") {
            this.setState((prevState) => ({
                ...prevState,
                selectAll: {
                    ...prevState.selectAll,
                    A: !this.state.selectAll.A
                },
                developA: {
                    ...prevState.developA,
                    eventIdArr: !!this.state.developA.eventIdArr.find((item, i) => item === id) ? this.state.developA.eventIdArr.filter(item => item !== id) : [...prevState.developA.eventIdArr, id]
                }
            }), () => {
                this.setState((prevState) => ({
                    ...prevState,
                    selectAll: {
                        ...prevState.selectAll,
                        A: this.state.developA.eventIdArr.length === this.state.eventAList.length ? true : false
                    },
                }))
            })
        }
        if (group === "groupB") {
            this.setState((prevState) => ({
                ...prevState,
                developB: {
                    ...prevState.developB,
                    eventIdArr: !!this.state.developB.eventIdArr.find((item, i) => item === id) ? this.state.developB.eventIdArr.filter(item => item !== id) : [...prevState.developB.eventIdArr, id]
                }

            }), () => {
                this.setState((prevState) => ({
                    ...prevState,
                    selectAll: {
                        ...prevState.selectAll,
                        B: this.state.developB.eventIdArr.length === this.state.eventBList.length ? true : false
                    },
                }))
            })

        }
    }

    handleAllEvent = (group) => {
        if (group === "groupA") {
            this.setState((prevState) => ({
                ...prevState,
                selectAll: {
                    ...prevState.selectAll,
                    A: !this.state.selectAll.A
                },
                developA: {
                    ...prevState.developA,
                    eventIdArr: !this.state.selectAll.A ? this.state.eventAList.map((k) => { return k.source_id }) : []
                }
            }))
        }
        if (group === "groupB") {
            this.setState((prevState) => ({
                ...prevState,
                selectAll: {
                    ...prevState.selectAll,
                    B: !this.state.selectAll.B
                },
                developB: {
                    ...prevState.developB,
                    eventIdArr: !this.state.selectAll.B ? this.state.eventBList.map((k) => { return k.source_id }) : []
                }
            }))
        }
    }

   

    print = async () => {
        await this.props.startLoader(true);
        await printToPDFMultiple(`developmentSummary`, [
            'developmentPrintArea'
        ]);
        await this.props.startLoader(false);
      }
      
    render() {
        const tabs = this.state.tabs;
        const DropdownIndicator = props => {
            return (
                <components.DropdownIndicator {...props}>
                    <img src={imagesArr.search} alt="search" />
                </components.DropdownIndicator>
            );
        };
        return (
            <>
                <div className={`page-content-wrapper ml-0 w-100 ${tabs === 0 || tabs === 1 ? "tabactive" : ""}`}>
                    <Header parentCallback={this.handleCallback} />
                    <div className="development without_footer">
                        <div className="main-div">
                            {/* sidebar-wrapper */}
                            <div className="search-sidebar">
                                <div className="sidebartext">
                                    {/* searchbox */}
                                    <div className="serch-box">
                                        <Select
                                            className="customize-select"
                                            classNamePrefix="customize-select"
                                            components={{ DropdownIndicator }}
                                            onChange={this.handleChange.bind(this)}
                                            name="dev_search"
                                            options={this.state.teamProfileName}
                                            placeholder="Select name...."
                                        />
                                    </div>
                                    {window.innerWidth > 991 && <div className="d-flex groupfilter">
                                        {<EventListA
                                            eventList={this.state.eventAList}
                                            handleEvent={this.handleEvent}
                                            AList={this.state.developA.eventIdArr}
                                            isshow={this.state.isshow}
                                            handleToggle={this.handleToggle}
                                            handleToggleMenu={this.handleToggleMenu}
                                            iscareticontype={this.state.iscareticontype}
                                            isshowtype={this.state.isshowtype}
                                            eventType={this.state.filterA.eventType}
                                            handleEventFilter={this.handleEventFilter}
                                            handleToggleDate={this.handleToggleDate}
                                            iscareticondateA={this.state.iscareticondateA}
                                            isshowdate={this.state.isshowdate}
                                            date={this.state.filterA.date}
                                            handleAllEvent={this.handleAllEvent}
                                            selectAllA={this.state.selectAll.A} />}

                                        <EventListB
                                            eventList={this.state.eventBList}
                                            handleEvent={this.handleEvent}
                                            BList={this.state.developB.eventIdArr}
                                            isshowb={this.state.isshowb}
                                            handleToggleB={this.handleToggleB}
                                            handleToggleMenuB={this.handleToggleMenuB}
                                            iscareticon={this.state.iscareticon}
                                            isshowtypeb={this.state.isshowtypeb}
                                            eventType={this.state.filterB.eventType}
                                            handleEventFilter={this.handleEventFilter}
                                            handleToggleDateB={this.handleToggleDateB}
                                            iscareticondate={this.state.iscareticondate}
                                            isshowdateb={this.state.isshowdateb}
                                            date={this.state.filterB.date}
                                            handleAllEvent={this.handleAllEvent}
                                            selectAllB={this.state.selectAll.B} />
                                    </div>}
                                    {(this.state.eventAList.length !== 0 || this.state.eventBList.length !== 0) && window.innerWidth <= 991 && <div className="d-flex groupfilter">
                                        {<EventListA
                                            eventList={this.state.eventAList}
                                            handleEvent={this.handleEvent}
                                            AList={this.state.developA.eventIdArr}
                                            isshow={this.state.isshow}
                                            handleToggle={this.handleToggle}
                                            handleToggleMenu={this.handleToggleMenu}
                                            iscareticontype={this.state.iscareticontype}
                                            isshowtype={this.state.isshowtype}
                                            eventType={this.state.filterA.eventType}
                                            handleEventFilter={this.handleEventFilter}
                                            handleToggleDate={this.handleToggleDate}
                                            iscareticondateA={this.state.iscareticondateA}
                                            isshowdate={this.state.isshowdate}
                                            date={this.state.filterA.date}
                                            handleAllEvent={this.handleAllEvent}
                                            selectAllA={this.state.selectAll.A} />}

                                        <EventListB
                                            eventList={this.state.eventBList}
                                            handleEvent={this.handleEvent}
                                            BList={this.state.developB.eventIdArr}
                                            isshowb={this.state.isshowb}
                                            handleToggleB={this.handleToggleB}
                                            handleToggleMenuB={this.handleToggleMenuB}
                                            iscareticon={this.state.iscareticon}
                                            isshowtypeb={this.state.isshowtypeb}
                                            eventType={this.state.filterB.eventType}
                                            handleEventFilter={this.handleEventFilter}
                                            handleToggleDateB={this.handleToggleDateB}
                                            iscareticondate={this.state.iscareticondate}
                                            isshowdateb={this.state.isshowdateb}
                                            date={this.state.filterB.date}
                                            handleAllEvent={this.handleAllEvent}
                                            selectAllB={this.state.selectAll.B} />
                                    </div>}
                                    {/* <div className="mobilebutton p-15 p-sm-0">
                                        <span className="btn blue mt-15 w-100" onClick={this.toggleMenuBar.bind(this)}> Apply </span>
                                        <span className="btn trans mt-15 w-100" onClick={this.toggleMenuBar.bind(this)}> Cancel </span>
                                    </div> */}

                                </div>
                            </div>
                            {/* sidebar-wrapper */}
                            {(this.state.eventAList.length !== 0 || this.state.eventBList.length !== 0) ?
                                <section className="show-dashboard">
                                    <div className="container-fluid">
                                        <div className="filtersection">
                                            <div className="d-flex">
                                                <ul className="tabviewbtn d-flex">
                                                    <li className={`${tabs === 0 ? "active" : ""}`} onClick={() => this.handleTabs(0)}>
                                                        <span className="btnhead">Summary</span>
                                                    </li>
                                                    <li className={`${tabs === 1 ? "active" : ""}`} onClick={() => this.handleTabs(1)}>
                                                        <span className="btnhead">Charts</span>
                                                    </li>
                                                    <li className={`${tabs === 2 ? "active" : ""}`} onClick={() => this.handleTabs(2)}>
                                                        <span className="btnhead">Plate Disc</span>
                                                    </li>
                                                </ul>
                                                <div className="btnprint">
                                                    <a className="btn white"><span> <img src={imagesArr.printer} alt="print" onClick={() => this.print()}/></span> </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="developmentPrintArea">
                                            {tabs === 0 && < Summary developA={this.state.developA} developB={this.state.developB} />}
                                            {tabs === 1 && < Charts developA={this.state.developA} developB={this.state.developB} />}
                                            {tabs === 2 && < Platedisc developA={this.state.developA} developB={this.state.developB} />}
                                        </div>
                                    </div>
                                </section>:<NoInfo/>}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        developmentSearchData: state.developmentSearchData,
        developmentPlayerData: state.developmentPlayerData,
        developmentSummaryData: state.developmentPlayerData,
    };
};

const mapDispatchToProps = (dispatch) => ({
    fetchSummaryData: (data, next) => dispatch(getDevelopmentSearch(data, next)),
    fetchEventList: (data, next) => dispatch(getDevelopmentPlayerData(data, next)),
    developmentSummary: (data, next) => dispatch(getDevelopmentSummary(data, next)),
    startLoader: (data) => dispatch({ type: "LOADING", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(development);

